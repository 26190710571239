import React from 'react';
// import { Prompt } from 'react-router-dom';
import { Form, Select, Option, Button, Input, Icon, Modal, List, Notify, Table, Href, Tooltip, Empty, RadioGroup, Flat, Book, Page } from 'cypd';
import { withRouter } from 'react-router-dom';

// import ScenarioConfigPage from '../scenario/scenario_config/config';
import { CsxUI, CsxFeature, CsxEventSystem, CsxUtil, CsxDesc } from '../../csx';
import ScenarioPage from '../scenario/scenario_config/config';
import {
    CYPActionModalContent,
    TCPActionModalContent,
    UDPActionModalContent,
    RS232ActionModalContent,
} from '../automation/automation_draw/modal';
import * as Topology from '../automation/topology/node';
import { CsxUserPermissionLevel, csxUserPermissionSatisfy } from '../../csx/manager';
import { IconSelector } from '../icon/page';
import automationShortcutDefaultImage from '../../images/automation.svg';
import fallbackEmptyImage from '../../images/fallback_image.svg';   // avoid fallback icon flicker when arranging list
// const DEFAULT_MISSION_CMD_EXPRESSION: { [s in 'video' | 'volume' | 'light']: string } = {
//     video: 'set out a route $VAR$',
//     volume: 'set audio out c volume $VAR$',
//     light: '',
// }

type RoomEditModalType = 'add' | 'edit';

type ViewState = {
    popup?: RoomEditModalType;
    new_room_name: string;
    row_limit: number;
    page_index: number;
    keyword_filter: string;
    create_mode: 'exist' | 'new';
    create_from: string;
    disable_create_room_from_exist: boolean;
}

const ROOM_TABLE_ROW_LIMITS_LOCAL_KEY = 'ROOM_TABLE_ROW_LIMITS_LOCAL_KEY';
const ROOM_TABLE_KEY_FILTER_LOCAL_KEY = 'ROOM_TABLE_KEY_FILTER_LOCAL_KEY';
const { getText } = CsxDesc;


class RoomOverview extends CsxUI.IRQComponent<any> {
    state: ViewState;
    importTmpRoom?: CsxEventSystem.CsxRoomJson;
    instance: CsxFeature.CsxEventSystemDevice;
    roomFileSelector: HTMLInputElement | null | undefined;
    room_s: string = '';
    constructor(props: any) {
        super(props);
        const local_store_row_limit = window.localStorage.getItem(ROOM_TABLE_ROW_LIMITS_LOCAL_KEY);
        const local_store_key_filter = window.localStorage.getItem(ROOM_TABLE_KEY_FILTER_LOCAL_KEY);
        this.state = {
            new_room_name: '',
            row_limit: parseInt((local_store_row_limit) ? local_store_row_limit : '10'),
            page_index: 0,
            keyword_filter: (local_store_key_filter) ? local_store_key_filter : '',
            create_from: '',
            create_mode: 'new',
            disable_create_room_from_exist: false,
        };
        this.IRQIndex = 'EventSystem';
        this.instance = new CsxFeature.CsxEventSystemDevice();
    }
    pop = (modal?: RoomEditModalType) => {
        const select_room = this.instance.Room(this.room_s);
        let default_name = '';
        if (modal === 'add') {
            if (this.instance.RoomSize() >= this.instance.RoomSizeLimit()) {
                Notify({ title: getText('NOTIFY_TITLE_CREATE_ROOM'), context: `${getText('NOTIFY_MSG_ROOM_SIZE_LIMIT')} ${this.instance.RoomSizeLimit()}.`, type: 'error', timeout: 10000 });
                return;
            }
            default_name = (this.importTmpRoom) ? this.importTmpRoom.name : '';
        } else if (modal === 'edit') {
            default_name = (select_room) ? select_room.NAME : '';
        } else if (!modal) {
            this.room_s = '';
            this.importTmpRoom = undefined;
        }
        this.setState({ popup: modal, new_room_name: default_name });
    }
    onChangeNewRoomName = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ new_room_name: e.target.value }); }
    addRoom = async () => {
        let new_room = this.instance.NewRoom();
        if (this.importTmpRoom) {
            /* check referenced automation */
            await this.instance.RoomAutomationSnapshotProcess(this.importTmpRoom);
            /* check referenced scenario */
            await this.instance.RoomScenarioSnapshotProcess(this.importTmpRoom);
            new_room = new CsxEventSystem.CsxRoom('0', this.importTmpRoom);
        }
        new_room.NAME = this.state.new_room_name;
        this.instance.SetRoom(new_room);
        this.pop(undefined);
        this.setState({ disable_create_room_from_exist: false, page_index: -1 });
    }
    delRoom = (room_id: string) => {
        window.userConfirm(getText('CONFIRM_REMOVE_ROOM'), (ok: boolean) => {
            if (ok)
                this.instance.DeleteRoom([room_id]);
        });
    }
    triggerRoomFileSelector = () => {
        if (this.roomFileSelector) {
            this.roomFileSelector.click();
        } else {
            window.alert(getText('ALERT_OP_UNSUPPORT_NATIVE_OUT'));
        }
    }
    roomUpdate = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const notify_title = getText('NOTIFY_TITLE_IMPORT_ROOM');
        const json_file = (event.target.files) ? event.target.files[0] : undefined;
        if (json_file) {
            CsxUtil.importHandler({
                file: json_file,
                validateFilename: (fn) => (fn.split('.').slice(-2).join('.').toLowerCase() === 'room.json'),
                onConfirm: (parseJson: any) => {
                    if (CsxEventSystem.isCsxRoomJson(parseJson)) {
                        this.importTmpRoom = parseJson;
                        this.setState({ disable_create_room_from_exist: true });
                        this.pop('add');
                    } else {
                        Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_FILE_FORMAT_INVALID'), timeout: 10000 });
                    }
                },
                onError: (msg, timeout) => {
                    Notify({ type: 'error', title: notify_title, context: msg, timeout });
                }
            })
        }
        event.target.value = '';
    }
    onSaveRoomName = (room_id: string) => {
        const { new_room_name } = this.state;
        const room = this.instance.Room(room_id);
        if (room) {
            room.NAME = new_room_name;
            this.instance.SetRoom(room);
            this.pop(undefined);
            this.setState({ new_room_name: '' });
        }
    }
    onClickEditName = (room_id: string) => {
        this.room_s = room_id;
        this.pop('edit');
    }
    onDownloadRoom = async (room_id: string) => {
        const room = this.instance.Room(room_id);
        if (room) {
            const room_json = room.toJson();
            // await room.loadJsonImage(room_json);
            room_json.automationSnapshot = room.takeAutomationSnapshot();
            room_json.scenarioSnapshot = room.takeScenarioSnapshot();
            room_json.cypDeviceSnapshot = room.takeDeviceSnapshot();
            CsxUtil.downloadAsJson(room.FILENAME, JSON.stringify(room_json, null, 4));
        } else {
            Notify({ type: 'error', title: 'Fail', context: 'Configuration not found.' });
        }
    }
    onChangeCreateMode = (value: string) => { this.importTmpRoom = undefined; this.setState({ create_mode: value, create_from: '' }); }
    onChangeCreateFrom = (value: string) => {
        const room = this.instance.Room(value);
        if (room) {
            this.importTmpRoom = room.toJson();
            this.setState({ create_from: value, new_room_name: room.NAME });
        }
    }
    onChangeRowLimit = (value: string) => { this.setState({ row_limit: parseInt(value) }); window.localStorage.setItem(ROOM_TABLE_ROW_LIMITS_LOCAL_KEY, value); }
    onChangePage = (value: number) => { this.setState({ page_index: value }); }
    onChangeKeywordFilter = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ keyword_filter: e.target.value }); window.localStorage.setItem(ROOM_TABLE_KEY_FILTER_LOCAL_KEY, e.target.value); }
    render() {
        const { popup, new_room_name, row_limit, page_index, keyword_filter, disable_create_room_from_exist, create_from, create_mode } = this.state;
        const room_arr = Array.from(this.instance.RoomSet());
        const room_edit_permission = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getPermission('room_edit') : CsxUserPermissionLevel.NoAccess;
        let name_valid = true;
        let name_error = '';

        const modal_title: { [s in RoomEditModalType]: string } = {
            add: 'Create Room',
            edit: 'Edit Room Name',
        };
        const span_style: React.CSSProperties = {
            position: 'relative',
            // marginBottom: '10px',
            whiteSpace: 'nowrap',
            color: '#707070',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'stretch',
            alignItems: 'center',
            height: '100%',
            flexGrow: 2,
            maxWidth: `calc(50% - 10px)`,
        };
        const row_limit_opt = [10, 20, 40];
        /* Modal Definition */
        if (new_room_name.length === 0 ||
            CsxUtil.hasChinese(new_room_name) ||
            this.instance.CheckRoomNameExist(new_room_name)) {
            name_valid = false;
            name_error = getText('HINT_DUPLICATE_NAME');
        }
        const modal_render: { [type in RoomEditModalType]: React.ReactElement } = {
            add: (
                <Form.Form>
                    <Form.Item>
                        <RadioGroup 
                            options={[
                                { label: getText('ROOM_OVERVIEW_CREATE_NEW'), value: 'new' },
                                { label: getText('ROOM_OVERVIEW_CREATE_FROM_EXIST'), value: 'exist', disabled: disable_create_room_from_exist },
                            ]}
                            value={create_mode}
                            onChange={this.onChangeCreateMode}
                        />
                    </Form.Item>
                    <Form.Item label={getText('FROM')} style={CsxUI.getHiddenStyle(create_mode === 'exist')}>
                        <Select value={create_from} placeholder="Existing room" onChange={this.onChangeCreateFrom}>
                            {room_arr.map(room_id => {
                                const room = this.instance.Room(room_id);
                                if (room) {
                                    return (
                                        <Option key={`create_from_exist_${room_id}`} value={room_id}>{room.NAME}</Option>
                                    );
                                } else {
                                    return null;
                                }
                            }).filter(node => !!node)}
                        </Select>
                    </Form.Item>
                    <Form.Item error={name_error}>
                        <Input
                            placeholder='Room Name'
                            className='room_add_input'
                            value={new_room_name}
                            onChange={this.onChangeNewRoomName}
                        />
                        <Button type='primary' onClick={this.addRoom} disabled={!name_valid}>{getText('ADD')}</Button>
                    </Form.Item>
                    {(this.importTmpRoom) ? <Form.Item style={{ wordBreak: 'break-word' }}>
                        {`Contains ${this.importTmpRoom.scenes.length} scenarios in this configuration`}
                    </Form.Item> : undefined}
                </Form.Form>
            ),
            edit: (
                <Form.Form>
                    <Form.Item error={name_error}>
                        <Input
                            placeholder='Room Name'
                            className='automation_add_input'
                            value={new_room_name}
                            onChange={this.onChangeNewRoomName}
                        />
                        <Button type='primary' onClick={() => { this.onSaveRoomName(this.room_s); }} disabled={!name_valid}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
            ),
        };

        return (
            <Flat.Section title={getText('ROOM_OVERVIEW_ALL')} disableCollapsed style={{ width: 'calc(100% - 100px)', maxWidth: 'none' }} titleStyle={{ backgroundColor: 'white', color: '#707070', fontSize: '18px', padding: '10px' }}>
                {(csxUserPermissionSatisfy(room_edit_permission, CsxUserPermissionLevel.FullAccess)) ? <Form.Item style={{ justifyContent: 'flex-end', maxWidth: '600px', flexShrink: 0 }}>
                    <Button icon='upload' onClick={this.triggerRoomFileSelector}>{getText('UPLOAD')}</Button>
                    <Button style={{ marginRight: '0' }} onClick={() => { this.pop('add') }} icon='plus' type='primary'>{getText('ADD')}</Button>
                </Form.Item> : undefined}
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', maxWidth: '600px', marginBottom: '10px' }}>
                    <span style={span_style}>
                        Show items
                        <Select value={row_limit} size='small' style={{ margin: '0 5px' }} onChange={this.onChangeRowLimit}>{row_limit_opt.map(value => <Option key={`row_limit_${value}`} value={value}>{value}</Option>)}</Select>
                    </span>
                    <span style={span_style}>
                        Search
                        <Input value={keyword_filter} placeholder='Name' size='small' onChange={this.onChangeKeywordFilter} style={{ flexGrow: 2, marginLeft: '5px' }}/>
                    </span>
                </div>
                {!window.APP_ON_HDMI ? <input type='file' style={{ display: 'none' }} ref={inst => { this.roomFileSelector = inst; }} onChange={this.roomUpdate} accept='.json' /> : undefined}
                {/* {!window.APP_ON_HDMI ? <input type='file' style={{ display: 'none' }} ref={inst => { this.imageFileSelector = inst; }} onChange={this.imageUpdate} accept='.jpg,.jpeg' /> : undefined} */}
                <Table
                    headers={["ID", getText('NAME'), getText('ROOM_OVERVIEW_ROOM_OPERATION')]}
                    columnWidth={[60, NaN, 120]}
                    rows={room_arr.map(room_id => {
                        const room = this.instance.Room(room_id);
                        const Linker = withRouter(({ history }) => (
                            <Href to={() => { history.push(`/management/es/room/?room_id=${room_id}`); }}>{(room ? room.NAME : '')}</Href>
                        ));
                        if (room) {
                            return (keyword_filter.length > 0 && room.NAME.toLowerCase().indexOf(keyword_filter.toLowerCase()) < 0) ? [] : [
                                room_id,
                                <Linker />,
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {(csxUserPermissionSatisfy(room_edit_permission, CsxUserPermissionLevel.EditAssigned)) ? <Tooltip text={getText('TOOLTIP_EDIT_NAME')}><Icon style={{ marginRight: '5px' }} type='write' color='rgb(0,138,158)' onClick={() => { this.onClickEditName(room_id); }} /></Tooltip> : undefined}
                                    <Tooltip text={getText('TOOLTIP_EXPORT_ROOM')}><Icon style={{ marginRight: '3px' }} color='rgb(0,138,158)' type='download' onClick={() => { this.onDownloadRoom(room_id); }} /></Tooltip>
                                    {(csxUserPermissionSatisfy(room_edit_permission, CsxUserPermissionLevel.FullAccess)) ? <Tooltip text={getText('TOOLTIP_DELETE_ROOM')}><Icon type='trashcan' color='#d12b60' onClick={() => { this.delRoom(room_id); }} /></Tooltip> : undefined}
                                </div>
                            ];
                        } else {
                            return [];
                        }
                    }).filter(row => (row.length > 0))}
                    bodyStyle={{ width: '600px' }}
                    responsive='shorten'
                    shortenProps={{
                        layout: {
                            topLeft: -1,
                            bottomLeft: 0,
                            topRight: [],
                            bottomRight: [1],
                        }
                    }}
                    pagination={true}
                    rowLimit={row_limit}
                    page={page_index}
                    onPageChange={this.onChangePage}
                ></Table>
                <Modal
                    title={(popup) ? modal_title[popup] : ''}
                    visible={typeof popup !== 'undefined'}
                    onClose={() => { this.pop(undefined); }}
                >
                    {(popup) ? modal_render[popup] : undefined}
                </Modal>
            </Flat.Section>
        );
    }
}

class Breadcrum extends CsxUI.IRQComponent<any> {
    instance: CsxFeature.CsxEventSystemDevice;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'EventSystem';
        this.instance = new CsxFeature.CsxEventSystemDevice();
    }
    render() {
        const curURLParameters = this.getURLParameter();
        const room_id = curURLParameters.get('room_id');
        const room = (room_id) ? this.instance.Room(room_id) : undefined;
        const Component = withRouter(({history}) => (
            <div className='breadcrum'>
                <Href className='breadcrum-item' to={() => { history.push(`/management/es/room`); }}>{getText('APP_NAV_ROOM')}</Href>
                {room?<Href className='breadcrum-item' to={() => { history.push(`/management/es/room/?room_id=${room_id}`); }}>{room.NAME}</Href>:undefined}
            </div>
        ));
        return <Component />;
    }
}

declare type RoomPageModalType = 'none' | 'edit_scenario' | 'edit_command' | 'edit_scenario_icon' | 'edit_automation_icon' | 'edit_command_icon';

type ConfigState = {
    scenario_select: string;
    scenario_edit: string;
    scenario_icon_select?: string;
    automation_select: string;
    automation_edit: string;
    automation_icon_select?: string;
    command_edit?: CsxEventSystem.CsxFastAction;
    command_icon_select?: string;
    popup: RoomPageModalType;
    form_page: number;
}

class ConfigurationPage extends CsxUI.IRQComponent<any> {
    ACTION_OPTION: { [s in CsxEventSystem.CsxActionType]: string } = {
        NullAction : 'NullAction',
        // TCPDeviceAction : 'TCPDeviceAction',
        CYPAction : CsxDesc.getText('ACTION_LABEL_CYP'),
        TCPAction : CsxDesc.getText('ACTION_LABEL_TCP'),
        UDPAction : CsxDesc.getText('ACTION_LABEL_UDP'),
        RS232Action : CsxDesc.getText('ACTION_LABEL_RS232'),
    };
    DEFAULT_DATA_TABLE: { [s in CsxEventSystem.CsxActionType]: CsxEventSystem.CsxActionParameter } = {
        NullAction:                 { name: 'NULL ACTION', lockOnCanvas: false, priority: 0, delay: 0 },
        CYPAction:                  { name: CsxDesc.getText('ACTION_BOX_TITLE_CYP', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, priority: 0, delay: 0, CYPDevice: { command: '', device: '' } },
        TCPAction:                  { name: CsxDesc.getText('ACTION_BOX_TITLE_TCP', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, priority: 0, delay: 0, TCP: { ipaddr: '', port: 23, commandRef: '', hexCommand: '', command: '', endchar: CsxEventSystem.CsxTrailType.NA, nic: 'eth0' } },
        UDPAction:                  { name: CsxDesc.getText('ACTION_BOX_TITLE_UDP', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, priority: 0, delay: 0, UDP: { ipaddr: '', port: 9, commandRef: '', hexCommand: '', command: '', endchar: CsxEventSystem.CsxTrailType.NA, nic: 'eth0' } },
        RS232Action:                { name: CsxDesc.getText('ACTION_BOX_TITLE_RS232', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, priority: 0, delay: 0, Control: { command: '', commandRef: '', hexCommand: '', endchar: CsxEventSystem.CsxTrailType.NA } },
        // TCPDeviceAction:            { name: 'TCPDeviceAction', lockOnCanvas: false, priority: 0, delay: 0, TCPDevice: { device: '', command: '', commandRef: '', hexCommand: '', endchar: CsxEventSystem.CsxTrailType.NA } },
    };
    ACTION_POP_COMPONENT: { [s in CsxEventSystem.CsxActionType]: React.ReactNode } = {
        CYPAction: <CYPActionModalContent />,
        TCPAction: <TCPActionModalContent />,
        UDPAction: <UDPActionModalContent />, // avoid form content reference to TCP because not s new mounted
        RS232Action: <RS232ActionModalContent />,
        // TCPDeviceAction: <div />,
        NullAction: <div />,
    }
    state: ConfigState;
    instance: CsxFeature.CsxEventSystemDevice;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'EventSystem';
        this.instance = new CsxFeature.CsxEventSystemDevice();
        this.state = {
            scenario_select: '',
            scenario_edit: '',
            automation_select: '',
            automation_edit: '',
            popup: 'none',
            form_page: 0,
        }
        window.routeScenario = {
            FOCUS_SCENARIO: () => {
                if (window.FOCUS_SCENARIO_ID) {
                    /**
                     *  For UI usage if no url available
                     */
                    return this.instance.Scenario(window.FOCUS_SCENARIO_ID);
                } else {
                    const curURLParameters = this.getURLParameter();
                    const id = curURLParameters.get('scenario_id');
                    return (id) ? this.instance.Scenario(id) : undefined;
                }
            }
        };
    }
    implementAdd = () => {
        // this function is just to satisfy window.canvas interface, leave it empty
    }
    implementSetPort = () => {
        // this function is just to satisfy window.canvas interface, leave it empty
    }
    implementGetNode = (nid: number): Topology.Node => {
        const { command_edit } = this.state;
        const return_action = (command_edit) ? command_edit : new CsxEventSystem.CsxActionNode(null);
        return {
            nid: nid, type: return_action.TYPE, x: 0, y: 0,
            fields: { in: [], out: [] },
            data: return_action.PARAM,
            parents: new Set(),
        };
    }
    implementSetNodeData = (_: number, data: any) => {
        const { command_edit } = this.state;
        const cur_room = this.curRoom();
        if (cur_room && command_edit && CsxEventSystem.isCsxActionParameter(data)) {
            const data_json = CsxEventSystem.CsxActionParameterToJson(data);
            command_edit.setJsonParam(command_edit.TYPE, data_json);
            cur_room.setCommond(command_edit);
            this.instance.SetRoom(cur_room);
        } else {
            Notify({ title: getText('NOTIFY_TITLE_WARNING'), context: getText('NOTIFY_MSG_UNEXPECT_ERROR'), timeout: 10000, type: 'warning' });
        }
        this.onCloseModal();
    }
    prepareCanvas = () => { window.canvas = { addNode: this.implementAdd, setNodePort: this.implementSetPort, curNode: -1, getNode: this.implementGetNode, setNodeData: this.implementSetNodeData, zoom: 1 }; }
    curRoom = () => {
        const curURLParameters = this.getURLParameter();
        const id = curURLParameters.get('room_id');
        return (id) ? this.instance.Room(id) : undefined;
    }
    onChangeActionType = (value: string) => {
        const { command_edit } = this.state;
        if (command_edit && CsxEventSystem.isCsxActionType(value)) {
            // const default_data = CsxUtil.nestedAssign(DEFAULT_DATA_TABLE[value]);
            const default_data = CsxUtil.nestedClone(this.DEFAULT_DATA_TABLE[value]);
            command_edit.TYPE = value;
            command_edit.setJsonParam(value, default_data); // initial param
            this.setState({ command_edit });
        }
    }
    onOpenCommand = (temp_command?: CsxEventSystem.CsxFastAction) => {
        this.prepareCanvas();
        if (temp_command) { // edit exist action
            const clone = temp_command.clone();
            this.setState({ command_edit: clone, form_page: 1 });
        } else { // create new action
            const cur_room = this.curRoom();
            if (cur_room) {
                const new_command = cur_room.newCommand();
                this.setState({ command_edit: new_command, form_page: 0 });
            }
        }
    }
    onPrevPage = () => {
        this.setState((prevState: ConfigState) => {
            let dest_page = prevState.form_page - 1;
            if (dest_page < 0) dest_page = 0;
            return { form_page: dest_page };
        });
    }
    onNextPage = () => {
        this.setState((prevState: ConfigState) => {
            const max_page = 1;
            let dest_page = prevState.form_page + 1;
            if (dest_page > max_page) dest_page = max_page;
            return { form_page: dest_page };
        });
    }
    onSelectScenario = (v: string) => { this.setState({ scenario_select: v }); }
    onAddScenario = () => {
        const { scenario_select } = this.state;
        const cur_room = this.curRoom();
        if (scenario_select.length > 0 && cur_room) {
            cur_room.addScenario(scenario_select);
            this.instance.SetRoom(cur_room);
        }
    }
    onRemoveScenario = (room_id: string) => {
        window.userConfirm(getText('CONFIRM_REMOVE_SCENARIO_FROM_ROOM'), ok => {
            const cur_room = this.curRoom();
            if (cur_room && ok) {
                cur_room.removeScenario(room_id);
                this.instance.SetRoom(cur_room);
            }
        });
    }
    onArrangeScenario = (list: Array<string>) => {
        const cur_room = this.curRoom();
        if (cur_room) {
            cur_room.scenes = list.slice();
            this.instance.SetRoom(cur_room);
        }
    }
    onSelectAutomation = (v: string) => { this.setState({ automation_select: v }); }
    onAddAutomation = () => {
        const { automation_select } = this.state;
        const cur_room = this.curRoom();
        if (automation_select.length > 0 && cur_room) {
            cur_room.addFastAutomation(automation_select);
            this.instance.SetRoom(cur_room);
        }
    }
    onRemoveAutomation = (automation_id: string) => {
        window.userConfirm(getText('CONFIRM_REMOVE_AUTOMATION_FROM_ROOM'), ok => {
            const cur_room = this.curRoom();
            if (cur_room && ok) {
                cur_room.removeFastAutomation(automation_id);
                this.instance.SetRoom(cur_room);
            }
        });
    }
    onArrangeAutomation = (list: Array<string>) => {
        const cur_room = this.curRoom();
        if (cur_room) {
            cur_room.fastAutomations = list.slice();
            this.instance.SetRoom(cur_room);
        }
    }
    onAddCommand = () => {
        this.prepareCanvas();
        const cur_room = this.curRoom();
        if (cur_room) {
            const new_command = cur_room.newCommand();
            this.setState({ popup: 'edit_command', command_edit: new_command, command_select: undefined, form_page: 0 });
        }
    }
    onEditCommand = (temp_command: CsxEventSystem.CsxFastAction) => {
        this.prepareCanvas(); 
        const clone = temp_command.clone();
        this.setState({ popup: 'edit_command', command_edit: clone, form_page: 1 });
    }
    onRemoveCommand = (command_id: string) => {
        window.userConfirm(getText('CONFIRM_REMOVE_COMMAND_FROM_ROOM'), ok => {
            const cur_room = this.curRoom();
            if (cur_room && ok) {
                cur_room.removeCommand(command_id);
                this.instance.SetRoom(cur_room);
            }
        });
    }
    onArrangeCommand = (list: Array<string>) => {
        const cur_room = this.curRoom();
        const new_cmds: Array<CsxEventSystem.CsxFastAction> = [];
        if (cur_room) {
            for (let i = 0; i < list.length; i++) {
                const cmd = cur_room.getCommandByID(list[i]);
                if (cmd) {
                    new_cmds.push(cmd.clone());
                }
            }
            cur_room.cmds = new_cmds;
            this.instance.SetRoom(cur_room);
        }
    }
    onChangeScenarioSelectIcon = (icons: Array<string>) => { this.setState({ scenario_icon_select: icons[0] }); }
    onSaveScenarioIcon = () => {
        const { scenario_icon_select, scenario_edit } = this.state;
        const scenario_select = this.instance.Scenario(scenario_edit);
        if (scenario_select) {
            const new_scenario = scenario_select.clone();
            new_scenario.IMAGE_ID = scenario_icon_select;
            this.instance.SetScenario(new_scenario);
        }
        this.onCloseModal();
    }
    onChangeAutomationSelectIcon = (icons: Array<string>) => { this.setState({ automation_icon_select: icons[0] }); }
    onSaveAutomationIcon = () => {
        const { automation_icon_select, automation_edit } = this.state;
        const automation_select = this.instance.Automation(automation_edit);
        if (automation_select) {
            const new_automation = automation_select.clone();
            new_automation.ICON = automation_icon_select;
            this.instance.SetAutomation(new_automation);
        }
        this.onCloseModal();
    }
    onChangeCommandSelectIcon = (icons: Array<string>) => { this.setState({ command_icon_select: icons[0] }); }
    onSaveCommandIcon = () => {
        const { command_icon_select, command_edit } = this.state;
        const cur_room = this.curRoom();
        if (cur_room && command_edit) {
            command_edit.ICON = command_icon_select;
            cur_room.setCommond(command_edit);
            this.instance.SetRoom(cur_room);
        }
        this.onCloseModal();
    }
    onCloseModal = () => {
        window.FOCUS_SCENARIO_ID = undefined;
        this.setState({
            scenario_edit: '',
            popup: 'none',
            automation_icon_select: undefined,
            automation_edit: '',
            command_edit: undefined,
            form_page: 0,
            command_select: undefined,
            command_icon_select: undefined,
        });
    }
    listHeader = () => {
        const label_obj =
            <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                <span style={{ width: '40px', textAlign: 'center', marginRight: '10px' }}>ID</span>
                <span style={{ width: '40px', textAlign: 'center', marginRight: '10px' }}></span>
                <span style={{ marginLeft: '10px' }}>{getText('NAME')}</span>
            </div>
        const deco_obj =
            <div style={{ width: '70px', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end', marginRight: '10px' }}>
                <span>{getText('OPERATION')}</span>
            </div>
        const header =
            <div style={{ transform: 'translateY(20px)', padding: '10px 20px 10px 20px', cursor: 'default' }}>
                <div style={{ paddingBottom:'10px', borderBottom: '1px solid #00839E'}}>
                    <div style={{
                        position: 'relative',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        textAlign: 'start',
                        paddingLeft: '10px',
                        color: '#3C3C3C',
                        fontWeight: 'bold'
                    }}>
                        {label_obj}
                        {deco_obj}
                    </div>
                </div>
            </div>
        return header;
    }
    render() {
        const {
            scenario_select,
            scenario_edit,
            automation_select,
            command_edit,
            automation_edit,
            scenario_icon_select,
            automation_icon_select,
            command_icon_select,
            form_page,
            popup
        } = this.state;
        const cur_room = this.curRoom();
        const scenario_set = this.instance.ScenarioSet();
        const automation_set = this.instance.AutomationSet();
        const room_scenes = (cur_room) ? cur_room.scenes : [];
        const room_automations = (cur_room) ? cur_room.fastAutomations : [];
        const room_command = (cur_room) ? cur_room.cmds : [];
        const automation_edit_obj = this.instance.Automation(automation_edit);
        const scenario_edit_obj = this.instance.Scenario(scenario_edit);
        const scenario_edit_permission = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getPermission('scenario_edit') : CsxUserPermissionLevel.NoAccess;
        const automation_edit_permission = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getPermission('automation_edit') : CsxUserPermissionLevel.NoAccess;
        const room_edit_permission = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getPermission('room_edit') : CsxUserPermissionLevel.NoAccess;
        const temp_action_type: CsxEventSystem.CsxActionType = (command_edit) ? command_edit.TYPE : 'NullAction';

        /* Modal definition */
        const action_select_form = (
            <Form.Form>
                <Form.Item label={getText('SCENARIO_CONF_SCRIPT_ACTION_TYPE_SELECT')} labelStyle={{ width: '150px' }}>
                    <Select value={temp_action_type} onChange={this.onChangeActionType} placeholder='Unknown Type'>
                        {CsxEventSystem.CsxActionTypeList.map((type, idx) => {
                            return (type !== 'NullAction') ? <Option key={`action_opt_${idx}`} value={type}>{this.ACTION_OPTION[type]}</Option> : null;
                        }).filter(opt => (opt !== null))}
                    </Select>
                </Form.Item>
                <Form.Item style={{ justifyContent: 'flex-end' }}>
                    <Button className='next_button' onClick={this.onNextPage} type='primary' disabled={temp_action_type === 'NullAction'}>{getText('SCENARIO_CONF_SCRIPT_NEXT_SET_ACTION')}</Button>
                </Form.Item>
            </Form.Form>
        );
        const action_configuration_form = (
            <div>
                {this.ACTION_POP_COMPONENT[temp_action_type]}
                <Form.Item style={{ position: 'absolute', bottom: 0, left: 0, width: '50%' }}>
                    <Button className='prev_button' icon='arrow-left' onClick={this.onPrevPage}>{getText('SCENARIO_CONF_SCRIPT_BACK')}</Button>
                </Form.Item>
            </div>
        );
        const page_list: Array<{ className: string; content: JSX.Element }> = [
            { className: 'action_select', content: action_select_form },
            { className: 'action_config', content: action_configuration_form },
        ];
        const modal_title: { [s in RoomPageModalType]: string } = {
            none: '',
            edit_scenario: `Edit ${(scenario_edit_obj) ? scenario_edit_obj.NAME : '???'}`,
            edit_command: `Edit ${(command_edit) ? command_edit.NAME : '???'}`,
            edit_automation_icon: `Edit ${(automation_edit_obj) ? automation_edit_obj.NAME  : '???'}'s Icon`,
            edit_command_icon: `Edit ${(command_edit) ? command_edit.NAME  : '???'}'s Icon`,
            edit_scenario_icon: `Edit ${(scenario_edit_obj) ? scenario_edit_obj.NAME  : '???'}'s Icon`,
        };
        const modal_content: { [s in RoomPageModalType]: React.ReactNode } = {
            none: undefined,
            edit_scenario: <ScenarioPage />,
            edit_command: (
                <Book page={form_page}>
                    {page_list.map((page, idx) => <Page key={`form_page_${idx}`} className={page.className}>{page.content}</Page>)}
                </Book>
            ),
            edit_scenario_icon: (
                <Form.Form labelStyle={{ width: '100px' }}>
                    <IconSelector
                        defaultSelected={(scenario_icon_select) ? [scenario_icon_select] : []}
                        selectType='single'
                        framesInRow={3}
                        width={340}
                        height={300}
                        onChange={this.onChangeScenarioSelectIcon}
                    />
                    <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Button type='primary' onClick={this.onSaveScenarioIcon}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
            ),
            edit_automation_icon: (
                <Form.Form labelStyle={{ width: '100px' }}>
                    <IconSelector
                        defaultSelected={(automation_icon_select) ? [automation_icon_select] : []}
                        selectType='single'
                        framesInRow={3}
                        width={340}
                        height={300}
                        onChange={this.onChangeAutomationSelectIcon}
                    />
                    <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Button type='primary' onClick={this.onSaveAutomationIcon}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
            ),
            edit_command_icon: (
                <Form.Form labelStyle={{ width: '100px' }}>
                    <IconSelector
                        defaultSelected={(command_icon_select) ? [command_icon_select] : []}
                        selectType='single'
                        framesInRow={3}
                        width={340}
                        height={300}
                        onChange={this.onChangeCommandSelectIcon}
                    />
                    <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Button type='primary' onClick={this.onSaveCommandIcon}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
            ),
        };
        return (
            <Flat.Playground hasExtendToggler contextStyle={{ height: 'auto' }}>
                <Flat.Section title={getText('ROOM_CONF_SCENARIO_LABEL')} defaultExtend>
                    <Form.Form labelStyle={{ width: '100px' }}>
                        {(csxUserPermissionSatisfy(room_edit_permission, CsxUserPermissionLevel.EditAssigned)) ? <Form.Item label={getText('ROOM_CONF_SCENARIO_ADD_SELECT')}>
                            <Select value={scenario_select} onChange={this.onSelectScenario} placeholder="Select Scenario">{
                                Array.from(scenario_set).map(scenario_id => {
                                    const scenario = this.instance.Scenario(scenario_id);
                                    return (scenario) ? <Option key={`scene_opt_${scenario_id}`} value={scenario_id}>{scenario.NAME}</Option> : undefined;
                                }).filter(option => !!option)
                            }</Select>
                            <Button icon='plus' type='primary' disabled={scenario_select.length === 0} onClick={this.onAddScenario}>{getText('ADD')}</Button>
                        </Form.Item> : undefined}
                        {this.listHeader()}
                        {/* add random key to make sure it keep latest state */}
                        {(room_scenes.length > 0) ? <List key={Math.random()} style={{ maxHeight: '400px' }} draggable={(csxUserPermissionSatisfy(room_edit_permission, CsxUserPermissionLevel.EditAssigned))} items={room_scenes.map(scenario_id => {
                            const scenario = this.instance.Scenario(scenario_id);
                            const scenario_name = (scenario) ? scenario.NAME : '';
                            const icon_url = (scenario && scenario.IMAGE_ID) ? scenario.getImageUrl() : fallbackEmptyImage;
                            const label_obj = (
                                <div style={{ position: 'relative', display: 'grid', gridTemplateColumns: 'auto auto 1fr', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <span style={{ width: '40px', marginRight: '10px', textAlign: 'center' }}>{scenario_id}</span>
                                    <div
                                        style={{ position: 'relative', overflow: 'hidden', height: '40px', width: '40px', marginRight: '10px', border: '1px solid transparent', borderRadius: '50%' }}
                                        onClick={() => { this.setState({ scenario_edit: scenario_id, scenario_icon_select: (scenario ? scenario.IMAGE_ID : undefined), popup: 'edit_scenario_icon' }); }} 
                                    >
                                        <CsxUI.FallbackImage src={icon_url} width='100%' height='100%'/>
                                    </div>
                                    <span style={{ marginLeft: '10px' }}>{scenario_name}</span>
                                </div>
                            );
                            const scenario_deco = (
                                <div style={{ width: '45px' }}>
                                    {(csxUserPermissionSatisfy(scenario_edit_permission, CsxUserPermissionLevel.EditAssigned)) ? <Icon type='write' style={{ marginRight: '4px' }} color='#008aab' onClick={() => { window.FOCUS_SCENARIO_ID = scenario_id; this.setState({ scenario_edit: scenario_id, popup: 'edit_scenario' }); }} /> : undefined}
                                    {(csxUserPermissionSatisfy(scenario_edit_permission, CsxUserPermissionLevel.FullAccess)) ? <Icon type='trashcan' color='#b92454' onClick={() => { this.onRemoveScenario(scenario_id); }} /> : undefined}
                                </div>
                            );
                            return (scenario) ? { label: label_obj, decoration: scenario_deco, index: scenario_id } : { label: 'Scenario not found', decoration: scenario_deco, index: scenario_id };
                        })} onAfterDrag={this.onArrangeScenario} /> : <Empty text='No scenario'/>}
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('ROOM_CONF_COMMAND_LABEL')}>
                    <Form.Form labelStyle={{ width: '100px' }}>
                        {(csxUserPermissionSatisfy(room_edit_permission, CsxUserPermissionLevel.EditAssigned)) ? <Form.Item style={{ justifyContent: 'flex-end' }}>
                            <Button icon='plus' type='primary' onClick={this.onAddCommand}>{getText('ADD')}</Button>
                        </Form.Item> : undefined}
                        {this.listHeader()}
                        {/* add random key to make sure it keep latest state */}
                        {(room_command.length > 0) ? <List key={Math.random()} style={{ maxHeight: '400px' }} draggable={(csxUserPermissionSatisfy(room_edit_permission, CsxUserPermissionLevel.EditAssigned))} items={room_command.map((command, index) => {
                            const icon_url = (command.ICON) ? command.getImageUrl() : fallbackEmptyImage;
                            const label_obj = (
                                <div style={{ position: 'relative', display: 'grid', gridTemplateColumns: 'auto auto 1fr', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <span style={{ width: '40px', marginRight: '10px', textAlign: 'center' }}>{index+1}</span>
                                    <div
                                        style={{ position: 'relative', overflow: 'hidden', height: '40px', width: '40px', marginRight: '10px', border: '1px solid transparent', borderRadius: '50%' }}
                                        onClick={() => { this.setState({ command_edit: command.clone(), command_icon_select: command.ICON, popup: 'edit_command_icon' }); }} 
                                    >
                                        <CsxUI.FallbackImage src={icon_url} width='100%' height='100%' />
                                    </div>
                                    <span style={{ marginLeft: '10px' }}>{command.NAME}</span>
                                </div>
                            );
                            const command_deco = (
                                <div style={{ width: '45px' }}>
                                    {(csxUserPermissionSatisfy(room_edit_permission, CsxUserPermissionLevel.EditAssigned)) ? <Icon type='write' style={{ marginRight: '4px' }} color='#008aab' onClick={() => { this.onEditCommand(command); }} /> : undefined}
                                    {(csxUserPermissionSatisfy(room_edit_permission, CsxUserPermissionLevel.FullAccess)) ? <Icon type='trashcan' color='#b92454' onClick={() => { this.onRemoveCommand(command.ID); }} /> : undefined}
                                </div>
                            );
                            return { label: label_obj, decoration: command_deco, index: command.ID };
                        })} onAfterDrag={this.onArrangeCommand} /> : <Empty text='No Command'/>}
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('ROOM_CONF_AUTOMATION_LABEL')}>
                    <Form.Form labelStyle={{ width: '100px' }}>
                        {(csxUserPermissionSatisfy(room_edit_permission, CsxUserPermissionLevel.EditAssigned)) ? <Form.Item label={getText('ROOM_CONF_AUTOMATION_ADD_SELECT')}>
                            <Select value={automation_select} onChange={this.onSelectAutomation} placeholder="Select Automation">{
                                Array.from(automation_set).map(automation_id => {
                                    const automation = this.instance.Automation(automation_id);
                                    return (automation) ? <Option key={`automation_opt_${automation_id}`} value={automation_id}>{automation.NAME}</Option> : undefined;
                                }).filter(option => !!option)
                            }</Select>
                            <Button icon='plus' type='primary' disabled={automation_select.length === 0} onClick={this.onAddAutomation}>{getText('ADD')}</Button>
                        </Form.Item> : undefined}
                        {this.listHeader()}
                        {/* add random key to make sure it keep latest state */}
                        {(room_automations.length > 0) ? <List key={Math.random()} style={{ maxHeight: '400px' }} draggable={(csxUserPermissionSatisfy(room_edit_permission, CsxUserPermissionLevel.EditAssigned))} items={room_automations.map(automation_id => {
                            const automation = this.instance.Automation(automation_id);
                            const automation_name = (automation) ? automation.NAME : '';
                            // const icon_url = (automation && automation.ICON) ? automation.getImageUrl() : fallbackEmptyImage;
                            const label_obj = (
                                <div style={{ position: 'relative', display: 'grid', gridTemplateColumns: 'auto auto 1fr', alignItems: 'center', justifyContent: 'flex-start' }}>
                                    <span style={{ width: '40px', marginRight: '10px', textAlign: 'center' }}>{automation_id}</span>
                                    <div style={{ position: 'relative', overflow: 'hidden', height: '40px', width: '40px', marginRight: '10px', border: '1px solid transparent', borderRadius: '50%' }}>
                                        <CsxUI.FallbackImage src={automationShortcutDefaultImage} width='100%' height='100%'/>
                                    </div>
                                    {/* <div
                                        style={{ position: 'relative', overflow: 'hidden', height: '40px', width: '40px', border: '1px solid transparent', borderRadius: '50%' }}
                                        onClick={() => { this.setState({ automation_edit: automation_id, automation_icon_select: (automation ? automation.ICON : undefined), popup: 'edit_automation_icon' }); }} 
                                    >
                                        <CsxUI.FallbackImage src={icon_url} width='100%' height='100%'/>
                                    </div> */}
                                    <span style={{ marginLeft: '10px' }}>{automation_name}</span>
                                </div>
                            );
                            const AutomationDeco = withRouter(({ history }) => (
                                <div style={{ width: '45px' }}>
                                    {(csxUserPermissionSatisfy(automation_edit_permission, CsxUserPermissionLevel.EditAssigned)) ? <Icon type='write' style={{ marginRight: '4px' }} color='#008aab' onClick={() => { history.push(`/management/es/automation/?aid=${automation_id}`); }} /> : undefined}
                                    {(csxUserPermissionSatisfy(automation_edit_permission, CsxUserPermissionLevel.FullAccess)) ? <Icon type='trashcan' color='#b92454' onClick={() => { this.onRemoveAutomation(automation_id); }} /> : undefined}
                                </div>
                            ));
                            return (automation) ? { label: label_obj, decoration: <AutomationDeco />, index: automation_id } : { label: 'Automation not found', decoration: <AutomationDeco />, index: automation_id };
                            // return (automation) ? { label: label_obj, index: automation_id } : { label: 'Automation not found', index: automation_id };
                        })} onAfterDrag={this.onArrangeAutomation} /> : <Empty text='No Automation'/>}
                    </Form.Form>
                </Flat.Section>
                <Modal
                    visible={(popup !== 'none')}
                    title={modal_title[popup]}
                    onClose={this.onCloseModal}
                >
                    {modal_content[popup]}
                </Modal>
            </Flat.Playground>
        );
    }
}

export default class RoomPage extends React.Component {
    render() {
        const curURLParameters = new URLSearchParams(window.location.search);
        const room_id = curURLParameters.get('room_id');
        let center = <RoomOverview />;
        if (room_id)
            center = <ConfigurationPage />;
        return <Flat.Playground style={{ height: '100%' }} contextStyle={{ height: 'auto' }}>
            <Breadcrum />
            {center}
        </Flat.Playground>;
    }
}