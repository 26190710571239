import React from 'react';
import { Table, Href, Icon, Select, Option, Input, Flat } from 'cypd';
import { withRouter } from 'react-router-dom';

import { CsxUI, CsxUtil, CsxControlSystem } from '../../csx';
import Breadcrum from './breadcrum';
import FeatureDisplay from './feature_draw/feature';
import FunctionDisplay from './function_draw/draw';

import './page.css'
import { getText } from '../../csx/desc';


declare global {
    interface Window {
        devices: {
            BREADCRUM_TITLE?: string;
            SUP_LIST_WIDTH: number;
        };
    }
}

declare type DeviceOverviewState = {
    row_limit: number;
    status_filter: 'ready' | 'error' | 'offline' | 'warning' | 'updating' | 'every';
    keyword_filter: string;
}

const DEVICE_TABLE_ROW_LIMITS_LOCAL_KEY = 'DEVICE_TABLE_ROW_LIMITS_LOCAL_KEY';
const DEVICE_TABLE_KEY_FILTER_LOCAL_KEY = 'DEVICE_TABLE_KEY_FILTER_LOCAL_KEY';

class DeviceOverview extends CsxUI.IRQComponent<any> {
    state: DeviceOverviewState;
    constructor(props: any) {
        super(props);
        const local_store_row_limit = window.localStorage.getItem(DEVICE_TABLE_ROW_LIMITS_LOCAL_KEY);
        const local_store_key_filter = window.localStorage.getItem(DEVICE_TABLE_KEY_FILTER_LOCAL_KEY);
        this.IRQIndex = 'Dashboard';
        this.MIN_RENDER_INTERVAL = 5000;
        this.state = {
            row_limit: parseInt((local_store_row_limit) ? local_store_row_limit : '10'),
            keyword_filter: (local_store_key_filter) ? local_store_key_filter : '',
            status_filter: 'every',
        }
    }
    onChangeRowLimit = (value: string) => { this.setState({ row_limit: parseInt(value) }); window.localStorage.setItem(DEVICE_TABLE_ROW_LIMITS_LOCAL_KEY, value); }
    onChangeStatusFilter = (value: string) => { this.setState({ status_filter: value }); }
    onChangeKeywordFilter = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ keyword_filter: e.target.value }); window.localStorage.setItem(DEVICE_TABLE_KEY_FILTER_LOCAL_KEY, e.target.value); }
    render() {
        const { status_filter, row_limit, keyword_filter } = this.state;
        const rows = (window.FOCUS_GATEWAY) ? Array.from(window.FOCUS_GATEWAY.DEVICES).map(device_id => {
            const dev_inst = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(device_id) : undefined;
            if (dev_inst) {
                const discovery_info = dev_inst.DISCOVERY_INFO;
                let c_status
                // let c_status_tip = ''
                c_status = <Icon type='edit' />;
                switch (dev_inst.SYS_STA) {
                    case CsxUtil.SYS_STA_TYPE.Sync:
                        if (status_filter !== 'every' && status_filter !== 'ready') { return []; }
                        c_status = <Icon type='led-green' />;
                        break;
                    case CsxUtil.SYS_STA_TYPE.A17_Connect_Error:
                    case CsxUtil.SYS_STA_TYPE.A17_Connect:
                    case CsxUtil.SYS_STA_TYPE.A13_Connect:
                    case CsxUtil.SYS_STA_TYPE.A18_Connect:
                    case CsxUtil.SYS_STA_TYPE.All_Connect:
                        if (status_filter !== 'every' && status_filter !== 'warning') { return []; }
                        c_status = <Icon type='led-yellow' />;
                        break;
                    case CsxUtil.SYS_STA_TYPE.TCP_Connect:
                    case CsxUtil.SYS_STA_TYPE.TCP_Connect_Error:
                        if (status_filter !== 'every' && status_filter !== 'error') { return []; }
                        c_status = <Icon type='led-red' />;
                        break;
                    case CsxUtil.SYS_STA_TYPE.Offline:
                        if (status_filter !== 'every' && status_filter !== 'offline') { return []; }
                        c_status = <Icon type='led-gray' />;
                        break;
                    case CsxUtil.SYS_STA_TYPE.Firmware_Update:
                        if (status_filter !== 'every' && status_filter !== 'updating') { return []; }
                        c_status = <Icon type='led-blue' />;
                        break;
                    default: // skip unadded device
                        return [];
                }
                const dev_name = (dev_inst.NICKNAME) ? dev_inst.NICKNAME : `Unknown(${dev_inst.ID.slice(9)})`;
                const ip = (dev_inst.STA.A82) ? Object.keys(dev_inst.STA.A82).map(key => dev_inst.STA.A82[key]).join('/') : dev_inst.STA.A46;
                const Linker = withRouter(({ history }) => <Href to={() => { history.push(`/management/devices/?dev=${dev_inst.ID.split(':').join('')}`); }}>{dev_name}</Href>);
                return (keyword_filter.length > 0 && dev_name.toLowerCase().indexOf(keyword_filter.toLowerCase()) < 0) ? [] : [c_status, <Linker />, ((typeof ip === 'string' && ip.length > 0) ? ip : discovery_info.IPAddress ),];
            } else {
                return [];
            }
        }).filter(row => row.length) : [];
        const row_limit_opt = [10, 20, 40];
        const status_opt = [
            { value: 'every', icon: '', text: 'Every' },
            { value: 'ready', icon: 'led-green', text: 'Ready' },
            { value: 'warning', icon: 'led-yellow', text: 'Warn' },
            { value: 'error', icon: 'led-red', text: 'Error' },
            { value: 'updating', icon: 'led-blue', text: 'Update' },
            { value: 'offline', icon: 'led-gray', text: 'Offline' },
        ];
        // const span_style: React.CSSProperties = { marginBottom: '10px', whiteSpace: 'nowrap', color: '#707070' };
        const span_style: React.CSSProperties = {
            position: 'relative',
            marginBottom: '10px',
            whiteSpace: 'nowrap',
            color: '#707070',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'stretch',
            alignItems: 'center',
            height: '100%',
            flexGrow: 2,
            flexBasis: 1,
            // maxWidth: `${(container_width / 2) - 10}px`,
            // maxWidth: `calc(33% - 10px)`,
        };
        const show_devs_part = { ...span_style };
        show_devs_part.marginRight = '5px';
        return (
            <Flat.Section title={getText('DEVICE_OVERVIEW_ALL')} disableCollapsed style={{ width: 'calc(100% - 100px)', maxWidth: 'none' }} titleStyle={{ backgroundColor: 'white', color: '#707070', fontSize: '18px', padding: '10px' }}>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%', maxWidth: '600px' }}>
                    <span style={show_devs_part}>
                        Show 
                        <Select value={row_limit} style={{ margin: '0 5px' }} size='small' onChange={this.onChangeRowLimit}>{row_limit_opt.map(value => <Option key={`row_limit_${value}`} value={value}>{value}</Option>)}</Select>
                        Devices
                    </span>
                    <span style={span_style}>
                        Show
                        <Select value={status_filter} style={{ margin: '0 5px' }} size='small' onChange={this.onChangeStatusFilter}>{status_opt.map(option => <Option key={`status_${option.value}`} value={option.value}><span><Icon type={option.icon} style={{ transform: 'scale(0.8) translateY(3px)', marginRight: '10px' }} />{option.text}</span></Option>)}</Select>
                    </span>
                    <span style={span_style}>
                        Search
                        <Input value={keyword_filter} placeholder='Name' style={{ marginLeft: '5px', flexGrow: 2 }} size='small' onChange={this.onChangeKeywordFilter}/>
                    </span>
                </div>
                <Table
                    headers={[getText('DEVICE_OVERVIEW_STATUS'), getText('DEVICE_OVERVIEW_DEV_NAME'), getText('DEVICE_OVERVIEW_IPADDR')]}
                    columnWidth={[120, NaN, NaN]}
                    rows={rows}
                    pagination={true}
                    rowLimit={row_limit}
                    responsive='shorten'
                    bodyStyle={{ width: '600px', maxWidth: '100%' }}
                    shortenProps={{ 
                        layout: {
                            bottomLeft: 1,
                            topLeft: -1,
                            bottomRight: [0],
                            topRight: [2]
                        }
                    }}
                />
            </Flat.Section>
        );
    }
}

export default class DevicePage extends CsxUI.IRQComponent<any> {
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'NoUpdate';
        this.state = { sidebarVisible: (window.innerWidth > 800) };
        window.devices = { SUP_LIST_WIDTH: 350 };
    }
    parseURLDeviceId = (dev_id: string | null): string => {
        if (!!dev_id && /^([0-9A-Fa-f]){12}$/.test(dev_id))
            return `${dev_id.slice(0, 2)}:${dev_id.slice(2, 4)}:${dev_id.slice(4, 6)}:${dev_id.slice(6, 8)}:${dev_id.slice(8, 10)}:${dev_id.slice(10, 12)}`
        else
            return '';
    }
    render() {
        const curURLParameters = this.getURLParameter();
        const dev_id_url = curURLParameters.get('dev');
        const focus_feature = curURLParameters.get('page');
        const dev_mac = this.parseURLDeviceId(dev_id_url);
        const search_dev = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(dev_mac) : undefined;
        window.FOCUS_DEVICE = (search_dev) ? search_dev : new CsxControlSystem.CsxDevice({ id: dev_mac });
        let center = <DeviceOverview />;
        if (dev_mac)
            center = <FeatureDisplay />;
        if (!!dev_mac && !!focus_feature)
            center = <FunctionDisplay />;
        // return <Flat.Playground className='device' style={{ height: '100%', overflow: 'hidden' }} contextStyle={{ maxHeight: '100%' }}>
        return <Flat.Playground className='device' style={{ height: '100%' }} contextStyle={{ height: (CsxUtil.detectIEEdge()) ? '100%' :'auto' }}>
            <Breadcrum />
            {center}
        </Flat.Playground>
    }
}
