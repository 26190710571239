
import React from 'react';
import { Form, Button, Flat } from 'cypd';

import { CsxFeature, CsxUI } from '../../csx';
import { CSX_NAME } from '../../csx/manager';
// const { Playground } = CsxUI;


export default class FactoryPage extends CsxUI.IRQComponent<any> {
    state: { run_basic: boolean; run_regex: boolean; run_opt: boolean; run_audio: boolean; console_in: string }
    instance: CsxFeature.CsxDebugDevice;
    vcBuffer: Array<string> = [];
    vcHeader = -1;
    shellRef?: HTMLDivElement | null;
    constructor(props: any) {
        super(props);
        this.state = { run_basic: true, run_regex: true, run_opt: true, run_audio: false, console_in: '' };
        this.instance = new CsxFeature.CsxDebugDevice(window.FOCUS_GATEWAY);
        this.IRQIndex = 'GatewayMessageRealTime';
        global.setTimeout(() => { 
            this.instance.StartDebug([false, false, false, false, true]);
        }, 1000);
    }
    componentDidUpdate() {
        if (this.shellRef && this.shellRef.scrollTo)
            this.shellRef.scrollTo(0, this.shellRef.scrollHeight);
    }
    shellRow(msg: string) {
        return <div className='shell_row'
            key={Math.random()}
            dangerouslySetInnerHTML={{ __html: msg }}
        />;
    }
    onChangeRunBasic = (event: React.ChangeEvent<HTMLInputElement>): void => { this.setState({ run_basic: event.target.checked }); }
    onChangeRunRegex = (event: React.ChangeEvent<HTMLInputElement>): void => { this.setState({ run_regex: event.target.checked }); }
    onChangeRunOption = (event: React.ChangeEvent<HTMLInputElement>): void => { this.setState({ run_opt: event.target.checked }); }
    onChangeRunAudio = (event: React.ChangeEvent<HTMLInputElement>): void => { this.setState({ run_audio: event.target.checked }); }
    onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => { this.setState({ console_in: event.target.value }); }
    onInputEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        const { console_in } = this.state;
        if (event.keyCode === 13) {
            this.instance.VirtualConsoleSend(console_in);
            if (console_in.length > 0)
                this.vcBuffer.splice(0, 0, console_in);
            this.vcHeader = -1;
            this.setState({ console_in: '' });
        } else if (event.keyCode === 38) { // up button
            if (this.vcBuffer.length === 0) return;
            this.vcHeader = (this.vcHeader < this.vcBuffer.length - 1) ? this.vcHeader + 1 : this.vcHeader;
            this.setState({ console_in: this.vcBuffer[this.vcHeader] });
        } else if (event.keyCode === 40) { // down button
            if (this.vcBuffer.length === 0) return;
            this.vcHeader = (this.vcHeader >= 0) ? this.vcHeader - 1 : this.vcHeader;
            this.setState({ console_in: ((this.vcHeader >= 0) ? this.vcBuffer[this.vcHeader] : '') });
        }
    }
    render() {
        // const { run_basic, run_regex, run_opt, run_audio } = this.state;
        return <Flat.Playground style={{ maxWidth: '800px' }}>
            <div className='terminal_container'>
                <Form.Item>
                    <Button type='primary' onClick={() => {
                        this.MessageBuffer.free();
                        this.instance.StartDebug([false, false, false, false, true]);
                    }}>Start Debug</Button>
                </Form.Item>
                <div className='shell_field' ref={(inst) => { this.shellRef = inst; }}>
                    {this.MessageBuffer.messages.map(msg => this.shellRow(msg))}
                </div>
                <div className='input_field'>
                    <span style={{ fontSize: '12px' }}>{`${CSX_NAME} Debug Shell`}</span>
                    <div className='prefix' >
                        <input value={this.state.console_in} onChange={this.onInputChange} onKeyUp={this.onInputEnter} />
                    </div>
                </div>
            </div>
        </Flat.Playground>;
    }
}