import { CsxUtil } from '.';
// import { toAppLangType, stringLitArray, APP_LANG_TYPE, CsxFileGrabber } from './util';
import { stringLitArray, APP_LANG_TYPE, CsxFileGrabber } from './util';
import EnglishLanguagePack from '../languages/en-us.json';
import ChineseTraditionalLanguagePack from '../languages/ch-traditional.json';
import JapaneseLanguagePack from '../languages/jp.json';
import LanguageMetadataJson from '../languages/metadata.json';


let CUR_LANG = localStorage.getItem('APP_LANGUAGE');
if (CUR_LANG === null) CUR_LANG = APP_LANG_TYPE.ENGLISH;
window.APP_LANGUAGE = CUR_LANG;
window.APP_LANGUAGE_METADATA = [];
const text_position_type = stringLitArray([
    'RESTORE',
    'SAVE',
    'ADD',
    'DELETE',
    'UPLOAD',
    'DOWNLOAD',
    'ARRANGE',
    'ENABLE',
    'DISABLE',
    'ON',
    'OFF',
    'REBOOT',
    'STANDBY',
    'FW_VER',
    'CMD_VER',
    'UPDATE',
    'NEW_NAME',
    'OVERWRITE',
    'SKIP',
    'CONFIRM',
    'CREATE',
    'STATUS',
    'USER',
    'OPERATION',
    'DEFAULT',
    'TOTAL',
    'DEVICE',
    'EDIT',
    'SEARCH',
    'SHOW',
    'ERROR',
    'IMPORT',
    'NAME',
    'TIME',
    'DATE',
    'EVERY',
    'DAY',
    'WEEK',
    'MONTH',
    'YEAR',
    'TIRGGER_INTERVAL',
    'SECOND',
    'MINUTE',
    'HOUR',
    'PROTOCOL',
    'COMMAND',
    'ENDCHAR',
    'INPUT_TYPE',
    'PLAIN',
    'HEX',
    'SCOPE',
    'COMMAND_LIST',
    'KEYWORD',
    'IP_PORT',
    'KEYCODE',
    'POLLING',
    'DETECT',
    'PRIORITY',
    'DELAY',
    'DELAY_SEC',
    'WAIT',
    'TEST',
    'NIC',
    'FROM',
    'DESCRIPTION',
    'TRAILING',
    'TRIGGER',
    'SEND',
    'POLL_KEY',
    'TCP_KEY',
    'RS232_KEY',
    'SRC_IP',
    'CONTINUE',
    'LOGIN',
    'APPLY',
    'CHOOSE_FILE',
    'SIMPLE_MONDAY',
    'SIMPLE_TUESDAY',
    'SIMPLE_WEDNESDAY',
    'SIMPLE_THURSDAY',
    'SIMPLE_FRIDAY',
    'SIMPLE_SATURDAY',
    'SIMPLE_SUNDAY',
    'SIMPLE_JANUARY',
    'SIMPLE_FEBRUARY',
    'SIMPLE_MARCH',
    'SIMPLE_APRIL',
    'SIMPLE_MAY',
    'SIMPLE_JUNE',
    'SIMPLE_JULY',
    'SIMPLE_AUGUST',
    'SIMPLE_SEPTEMBER',
    'SIMPLE_OCTOBER',
    'SIMPLE_NOVEMBER',
    'SIMPLE_DECEMBER',
    'NAV_LANG_SELECT_LABEL',
    'EVENT_SYS_AUTOMATION_ALL_LABEL',
    'EVENT_SYS_AUTOMATION_ADD_LABEL',
    'AUTOMATION_OVERVIEW_CREATE_NEW',
    'AUTOMATION_OVERVIEW_CREATE_FROM_EXIST',
    'AUTOMATION_ADD_MODAL_HEADER',
    'AUTOMATION_EDIT_MODAL_HEADER',
    'AUTOMATION_ADD_MODAL_FI_LABEL',
    'AUTOMATION_ADD_MODAL_FI_BUTTON',
    'AUTOMATION_DRAW_SIDER_BACK_LABEL',
    'AUTOMATION_CANVAS_MODAL_EDIT_EVENT',
    'AUTOMATION_CANVAS_MODAL_EDIT_ACTION',
    'EVENT_LABEL',
    'EVENT_LABEL_ONCE',
    'EVENT_LABEL_REPEAT',
    'EVENT_LABEL_CYPD',
    'EVENT_LABEL_EXT_TCP',
    'EVENT_LABEL_EXT_UDP',
    'EVENT_LABEL_EXT_RS232',
    'EVENT_LABEL_KEYPAD',
    'EVENT_LABEL_POLLING',
    'EVENT_LABEL_SYSTEM',
    'EVENT_LABEL_CUSTOM',
    'EVENT_BOX_TITLE_ONCE',
    'EVENT_BOX_TITLE_REPEAT',
    'EVENT_BOX_TITLE_CYPD',
    'EVENT_BOX_TITLE_EXT_TCP',
    'EVENT_BOX_TITLE_EXT_UDP',
    'EVENT_BOX_TITLE_EXT_RS232',
    'EVENT_BOX_TITLE_KEYPAD',
    'EVENT_BOX_TITLE_POLLING',
    'EVENT_BOX_TITLE_SYSTEM',
    'EVENT_BOX_TITLE_CUSTOM',
    'MACRO_LABEL',
    'MACRO_LABEL_BASIC',
    'MACRO_BOX_TITLE_BASIC',
    'LOGIC_LABEL',
    'LOGIC_LABEL_AND',
    'LOGIC_LABEL_OR',
    'LOGIC_LABEL_NOT',
    'ACTION_LABEL',
    'ACTION_LABEL_CYP',
    'ACTION_LABEL_TCP',
    'ACTION_LABEL_UDP',
    'ACTION_LABEL_RS232',
    'ACTION_LABEL_TOOLTIP_CYP',
    'ACTION_LABEL_TOOLTIP_TCP',
    'ACTION_LABEL_TOOLTIP_UDP',
    'ACTION_LABEL_TOOLTIP_RS232',
    'ACTION_BOX_TITLE_CYP',
    'ACTION_BOX_TITLE_TCP',
    'ACTION_BOX_TITLE_UDP',
    'ACTION_BOX_TITLE_RS232',
    'APP_NAV_DASHBOARD',
    'APP_NAV_DEVICES',
    'APP_NAV_EVENT_SYSTEM',
    'APP_NAV_ROOM',
    'APP_NAV_SCENARIO',
    'APP_NAV_AUTOMATION',
    'APP_NAV_ICON',
    'APP_NAV_SETTINGS',
    'APP_NAV_SYSTEM',
    'APP_NAV_USER_MANAGE',
    'APP_NAV_TOGGLER',
    'APP_FOOTER_OFFLINE',
    'APP_FOOTER_LINKING',
    'APP_FOOTER_FW_UPDATING',
    'APP_OOPS',
    'APP_OOPS_MSG',
    'APP_DIALOG_OK',
    'APP_DIALOG_CANCEL',
    'SETTING_SYSTEM_IP_CONFIG_LABEL',
    'SETTING_SYSTEM_IP_CONFIG_LAN',
    'SETTING_SYSTEM_IP_CONFIG_MAC',
    'SETTING_SYSTEM_IP_CONFIG_DHCP',
    'SETTING_SYSTEM_IP_CONFIG_IPADDR',
    'SETTING_SYSTEM_IP_CONFIG_GW',
    'SETTING_SYSTEM_IP_CONFIG_NM',
    'SETTING_SYSTEM_DEV_NAME_LABEL',
    'SETTING_SYSTEM_DEV_NAME',
    'SETTING_SYSTEM_TIME_LABEL',
    'SETTING_SYSTEM_TIME_NTP_SYNC',
    'SETTING_SYSTEM_TIME_NTP_SERVER',
    'SETTING_SYSTEM_TIME_NTP_TIMEZONE',
    'SETTING_SYSTEM_TIME_NTP_DATE_TIME',
    'SETTING_SYSTEM_SYSTEM_LABEL',
    'SETTING_SYSTEM_SYSTEM_POWER',
    'SETTING_SYSTEM_SYSTEM_REBOOT',
    'SETTING_SYSTEM_SYSTEM_ON',
    'SETTING_SYSTEM_SYSTEM_FAC_DEFAULT',
    'SETTING_SYSTEM_SYSTEM_HOSTNAME',
    'SETTING_SYSTEM_SYSTEM_BUTTIP_REVERT',
    'SETTING_SYSTEM_SYSTEM_WEB_TIMEOUT',
    'SETTING_SYSTEM_UART_LABEL',
    'SETTING_SYSTEM_UART_PORT',
    'SETTING_SYSTEM_UART_BAUDRATE',
    'SETTING_SYSTEM_UART_STOPBIT',
    'SETTING_SYSTEM_UART_DATABIT',
    'SETTING_SYSTEM_UART_PARITY',
    'SETTING_SYSTEM_UART_MODE',
    'SETTING_SYSTEM_BACKUP_LABEL',
    'SETTING_SYSTEM_ICON_IMPORT_SETTING_LABEL',
    'SETTING_SYSTEM_VERSION_LABEL',
    'SETTING_SYSTEM_AUTOMATION_INFO',
    'SETTING_SYSTEM_ROOM_INFO',
    'SETTING_SYSTEM_SCENARIO_INFO',
    'SETTING_SYSTEM_ICON_INFO',
    'SETTING_SYSTEM_AUTOMATION_CONF',
    'SETTING_SYSTEM_SCENARIO_CONF',
    'SETTING_SYSTEM_ROOM_CONF',
    'SETTING_SYSTEM_ICON_CONF',
    'SETTING_USR_MANAGE_USERNAME',
    'SETTING_USR_MANAGE_PASSWORD',
    'SETTING_USR_MANAGE_DASHBD_VIEW',
    'SETTING_USR_MANAGE_BATCH_UPDATE',
    'SETTING_USR_MANAGE_ADDREMOVE_DEV',
    'SETTING_USR_MANAGE_DEV_VIEW',
    'SETTING_USR_MANAGE_DEV_CONTROL',
    'SETTING_USR_MANAGE_ES_VIEW',
    'SETTING_USR_MANAGE_ES_ROOM_EDIT',
    'SETTING_USR_MANAGE_ES_SCENE_EDIT',
    'SETTING_USR_MANAGE_ES_AUTO_EDIT',
    'SETTING_USR_MANAGE_USRMNG_VIEW',
    'SETTING_USR_MANAGE_USR_EDIT',
    'SETTING_USR_MANAGE_SYSTEM_PAGE',
    'SETTING_USR_MANAGE_SYS_VIEW',
    'SETTING_USR_MANAGE_GW_CONTROL',
    'SETTING_USR_MANAGE_LABEL',
    'SETTING_USR_MANAGE_ALLOW_USER_FUNC',
    'SETTING_USR_MANAGE_CREATE_USER',
    'SETTING_USR_MANAGE_GUEST_PERMISSION',
    'SETTING_USR_MANAGE_ASSIGN_ROOM_PERM',
    'SETTING_USR_MANAGE_ASSIGN_ROOM',
    'SETTING_USR_MANAGE_GUEST_HOME_LABEL',
    'SETTING_USR_MANAGE_LAST_LOGIN_OPTION',
    'SETTING_USR_MANAGE_NO_ROOM_OPTION',
    'SETTING_USR_MANAGE_DEFAULT_USR',
    'SETTING_USR_MANAGE_DEFAULT_SI',
    'SETTING_USR_MANAGE_DEFAULT_ENDUSR',
    'SETTING_PERMISSION_CREATE_DELETE',
    'SETTING_PERMISSION_CREATE_DELETE_DEV',
    'SETTING_PERMISSION_CREATE_DELETE_USR',
    'SETTING_PERMISSION_EDIT',
    'SETTING_PERMISSION_VIEW_ALL',
    'SETTING_PERMISSION_VIEW_ASSIGNED',
    'SETTING_PERMISSION_VIEW_ASSIGNED_EP',
    'SETTING_PERMISSION_VIEW_ASSIGNED_HP',
    'SETTING_PERMISSION_ASSIGNE_ROOM',
    'SETTING_PERMISSION_UNIT_FWUP',
    'SETTING_PERMISSION_OPT_NO_CTRL',
    'SETTING_PERMISSION_OPT_ASSIGN_ONLY',
    'SETTING_PERMISSION_OPT_EDIT_ONLY',
    'SETTING_PERMISSION_OPT_VIEW_ONLY',
    'SETTING_PERMISSION_OPT_VIEW_ALL',
    'SETTING_PERMISSION_OPT_FULL_CTRL',
    'SETTING_PERMISSION_OPT_DISABLE_VIEW',
    'SETTING_PERMISSION_OPT_FROM_ASSIGN',
    'SETTING_PERMISSION_OPT_CTRLABLE',
    'SETTING_PERMISSION_OPT_NOT_ALLOW',
    'SETTING_PERMISSION_OPT_ALLOW',
    'DASHBOARD_IP_CONFIG_LABEL',
    'DASHBOARD_IP_CONFIG_MAC',
    'DASHBOARD_IP_CONFIG_IPMODE',
    'DASHBOARD_IP_CONFIG_IPADDR',
    'DASHBOARD_IP_CONFIG_NM',
    'DASHBOARD_IP_CONFIG_GW',
    'DASHBOARD_IP_FILTER_NONE',
    'DASHBOARD_IP_FILTER_PID',
    'DASHBOARD_IP_FILTER_PNAME',
    'DASHBOARD_IP_FILTER_MAC',
    'DASHBOARD_HEALTH_MANAGE_LABEL',
    'DASHBOARD_SCAN_DEV_LABEL',
    'DASHBOARD_SCAN_DEV_EMPTY',
    'DASHBOARD_DEV_LABEL',
    'DASHBOARD_DEV_STATUS',
    'DASHBOARD_DEV_NAME',
    'DASHBOARD_DEV_IPADDR',
    'DASHBOARD_DEV_ERROR_STATUS',
    'DASHBOARD_DEV_READY_STATUS',
    'DASHBOARD_DEV_PREPARE_STATUS',
    'DASHBOARD_DEV_OFFLINE_STATUS',
    'DASHBOARD_DEV_UPDATING_STATUS',
    'DEVICE_OVERVIEW_ALL',
    'DEVICE_OVERVIEW_STATUS',
    'DEVICE_OVERVIEW_DEV_NAME',
    'DEVICE_OVERVIEW_IPADDR',
    'DEVICE_FEATURE_LABEL',
    'DEVICE_FEATURE_ROUTING',
    'DEVICE_FEATURE_AUDIO',
    'DEVICE_FEATURE_OSD',
    'DEVICE_FEATURE_SCALER',
    'DEVICE_FEATURE_VIDEOWALL',
    'DEVICE_FEATURE_AUTOMATION',
    'DEVICE_FEATURE_STREAMING',
    'DEVICE_FEATURE_RECORD',
    'DEVICE_FEATURE_CEC',
    'DEVICE_FEATURE_EDID',
    'DEVICE_FEATURE_HDCP',
    'DEVICE_FEATURE_GENERAL',
    'DEVICE_FEATURE_IO',
    'DEVICE_FEATURE_POWER_MONITOR',
    'DEVICE_FEATURE_FW_UPDATE',
    'DEVICE_FEATURE_CABLE_TEST',
    'DEVICE_FEATURE_DEBUG',
    'DEVICE_FEATURE_DANTE',
    'DEVICE_FEATURE_TIME',
    'DEVICE_FUNC_IO_IR_LABEL',
    'DEVICE_FUNC_IO_IR_IN_CHANNEL',
    'DEVICE_FUNC_IO_IR_CUST_CODE',
    'DEVICE_FUNC_IO_USB_LABEL',
    'DEVICE_FUNC_IO_USB_PORT',
    'DEVICE_FUNC_IO_USB_CTRL_MODE',
    'DEVICE_FUNC_IO_USB_PS',
    'DEVICE_FUNC_IO_USB_HOST_ROUTE',
    'DEVICE_FUNC_IO_UART_LABEL',
    'DEVICE_FUNC_IO_UART_PORT',
    'DEVICE_FUNC_IO_UART_BAUDRATE',
    'DEVICE_FUNC_IO_UART_STOPBIT',
    'DEVICE_FUNC_IO_UART_DATABIT',
    'DEVICE_FUNC_IO_UART_PARITY',
    'DEVICE_FUNC_IO_UART_MODE',
    'DEVICE_FUNC_VW_LAYOUT_LABEL',
    'DEVICE_FUNC_VW_LAYOUT',
    'DEVICE_FUNC_VW_LAYOUT_BEZEL_MODE',
    'DEVICE_FUNC_VW_LAYOUT_BEZEL_H',
    'DEVICE_FUNC_VW_LAYOUT_BEZEL_V',
    'DEVICE_FUNC_VW_LAYOUT_START_PXL_H',
    'DEVICE_FUNC_VW_LAYOUT_START_PXL_V',
    'DEVICE_FUNC_VW_LAYOUT_SIZE_H',
    'DEVICE_FUNC_VW_LAYOUT_SIZE_V',
    'DEVICE_FUNC_VW_PRESET_LABEL',
    'DEVICE_FUNC_VW_PRESET',
    'DEVICE_FUNC_VW_SRC_CONF_LABEL',
    'DEVICE_FUNC_VW_SRC_START_PXL_H',
    'DEVICE_FUNC_VW_SRC_START_PXL_V',
    'DEVICE_FUNC_VW_SRC_SIZE_H',
    'DEVICE_FUNC_VW_SRC_SIZE_V',
    'DEVICE_FUNC_VW_DETAIL_BEZEL_LABEL',
    'DEVICE_FUNC_VW_DETAIL_BEZEL_TOP',
    'DEVICE_FUNC_VW_DETAIL_BEZEL_BOTTOM',
    'DEVICE_FUNC_VW_DETAIL_BEZEL_LEFT',
    'DEVICE_FUNC_VW_DETAIL_BEZEL_RIGHT',
    'DEVICE_FUNC_VW_WARP_LABEL',
    'DEVICE_FUNC_VW_WARP_MODE',
    'DEVICE_FUNC_VW_WARP_KEEP_AR',
    'DEVICE_FUNC_VW_WARP_ANGLE',
    'DEVICE_FUNC_VW_WARP_IN_LABEL',
    'DEVICE_FUNC_VW_WARP_IN_PORT',
    'DEVICE_FUNC_VW_WARP_IN_HEIGHT',
    'DEVICE_FUNC_VW_WARP_IN_WIDTH',
    'DEVICE_FUNC_VW_WARP_IN_START_PXL_H',
    'DEVICE_FUNC_VW_WARP_IN_START_PXL_V',
    'DEVICE_FUNC_VW_WARP_OUT_LABEL',
    'DEVICE_FUNC_VW_ROTATE_LABEL',
    'DEVICE_FUNC_VW_ROTATE_MODE',
    'DEVICE_FUNC_VW_ROTATE_ANGLE',
    'DEVICE_FUNC_OSD_OUT_LABEL',
    'DEVICE_FUNC_OSD_OUT_PORT',
    'DEVICE_FUNC_OSD_OUT_ON',
    'DEVICE_FUNC_OSD_OUT_TEXT',
    'DEVICE_FUNC_OSD_OUT_BG_COLOR',
    'DEVICE_FUNC_OSD_OUT_TIMEOUT',
    'DEVICE_FUNC_OSD_OUT_TRANSPARENCY',
    'DEVICE_FUNC_OSD_OUT_POS_H',
    'DEVICE_FUNC_OSD_OUT_POS_V',
    'DEVICE_FUNC_OSD_OUT_DISP_INFO_LABEL',
    'DEVICE_FUNC_OSD_OUT_DISP_INFO_ON',
    'DEVICE_FUNC_OSD_OUT_DISP_INFO_TIMEOUT',
    'DEVICE_FUNC_OSD_OUT_BANNER_LABEL',
    'DEVICE_FUNC_OSD_OUT_BANNER_SRC',
    'DEVICE_FUNC_OSD_OUT_BANNER_LOC',
    'DEVICE_FUNC_OSD_OUT_BANNER_FONT_SIZE',
    'DEVICE_FUNC_OSD_OUT_BANNER_FONT_CLR',
    'DEVICE_FUNC_OSD_OUT_BANNER_FONT_TRANS',
    'DEVICE_FUNC_OSD_OUT_LOGO_LABEL',
    'DEVICE_FUNC_OSD_OUT_LOGO_ON',
    'DEVICE_FUNC_OSD_OUT_LOGO_POS_H',
    'DEVICE_FUNC_OSD_OUT_LOGO_POS_V',
    'DEVICE_FUNC_OSD_OUT_COUNTDOWN_LABEL',
    'DEVICE_FUNC_OSD_OUT_COUNTDOWN_TIMER',
    'DEVICE_FUNC_CABEL_IN_ANALYZ_LABEL',
    'DEVICE_FUNC_CABEL_IN_ANALYZ_START',
    'DEVICE_FUNC_CABEL_IN_ANALYZ_TIMEOUT',
    'DEVICE_FUNC_CABEL_IN_MSE_LABEL',
    'DEVICE_FUNC_CABEL_IN_MAXERR_LABEL',
    'DEVICE_FUNC_CABEL_OUT_ANALYZ_LABEL',
    'DEVICE_FUNC_CABEL_OUT_ANALYZ_START',
    'DEVICE_FUNC_CABEL_OUT_ANALYZ_TIMEOUT',
    'DEVICE_FUNC_CABEL_OUT_MSE_LABEL',
    'DEVICE_FUNC_CABEL_OUT_MAXERR_LABEL',
    'DEVICE_FUNC_CABEL_MSE_LABEL',
    'DEVICE_FUNC_CABEL_MAXERR_LABEL',
    'DEVICE_FUNC_STREAM_LIVE_LABEL',
    'DEVICE_FUNC_STREAM_LIVE_START',
    'DEVICE_FUNC_STREAM_LIVECONF_LABEL',
    'DEVICE_FUNC_STREAM_LIVECONF_PLAT',
    'DEVICE_FUNC_STREAM_LIVECONF_URL',
    'DEVICE_FUNC_STREAM_LIVECONF_KEY',
    'DEVICE_FUNC_STREAM_LIVECONF_RES',
    'DEVICE_FUNC_STREAM_LIVECONF_BR',
    'DEVICE_FUNC_STREAM_LIVECONF_FR',
    'DEVICE_FUNC_STREAM_ENC_LABEL',
    'DEVICE_FUNC_STREAM_ENC_CHANNEL',
    'DEVICE_FUNC_STREAM_ENC_PROFILE',
    'DEVICE_FUNC_STREAM_ENCCONF_LABEL',
    'DEVICE_FUNC_STREAM_ENCCONF_RES',
    'DEVICE_FUNC_STREAM_ENCCONF_BR',
    'DEVICE_FUNC_STREAM_ENCCONF_FR',
    'DEVICE_FUNC_REC_LABEL',
    'DEVICE_FUNC_REC_START',
    'DEVICE_FUNC_REC_OVERWRITE',
    'DEVICE_FUNC_REC_CONF_LABEL',
    'DEVICE_FUNC_REC_CONF_STORAGE',
    'DEVICE_FUNC_REC_CONF_PROFILE',
    'DEVICE_FUNC_REC_CONF_RES',
    'DEVICE_FUNC_REC_CONF_BR',
    'DEVICE_FUNC_REC_CONF_FR',
    'DEVICE_FUNC_REC_SCHDULE_LABEL',
    'DEVICE_FUNC_REC_SCHDULE_MODE',
    'DEVICE_FUNC_PM_POWER_LABEL',
    'DEVICE_FUNC_PM_TEMPERATIRE_LABEL',
    'DEVICE_FUNC_CEC_LABEL',
    'DEVICE_FUNC_CEC_IN_LABEL',
    'DEVICE_FUNC_CEC_IN_PORT',
    'DEVICE_FUNC_CEC_IN_AUTO_HEXENC',
    'DEVICE_FUNC_CEC_IN_DATA',
    'DEVICE_FUNC_CEC_OUT_LABEL',
    'DEVICE_FUNC_CEC_OUT_PORT',
    'DEVICE_FUNC_CEC_OUT_AUTO_HEXENC',
    'DEVICE_FUNC_CEC_OUT_DATA',
    'DEVICE_FUNC_FW_LABEL',
    'DEVICE_FUNC_FW_AUTH',
    'DEVICE_FUNC_FW_UPDATE',
    'DEVICE_FUNC_FW_AUTH_USERNAME',
    'DEVICE_FUNC_FW_AUTH_PASSWORD',
    'DEVICE_FUNC_ROUTE_SWITCH_LABEL',
    'DEVICE_FUNC_ROUTE_RENAME_LABEL',
    'DEVICE_FUNC_ROUTE_IN_LABEL',
    'DEVICE_FUNC_ROUTE_OUT_LABEL',
    'DEVICE_FUNC_ROUTE_PRESET_LABEL',
    'DEVICE_FUNC_ROUTE_OUT_MASK_LABEL',
    'DEVICE_FUNC_EDID_SRC_SWITCH_LABEL',
    'DEVICE_FUNC_EDID_SRC_MODE',
    'DEVICE_FUNC_EDID_SRC_ALL',
    'DEVICE_FUNC_EDID_UPDOWN_LOAD_LABEL',
    'DEVICE_FUNC_EDID_INFO_LABEL',
    'DEVICE_FUNC_EDID_INFO_SELECT',
    'DEVICE_FUNC_EDID_INFO_NAME',
    'DEVICE_FUNC_EDID_INFO_BLOCK1',
    'DEVICE_FUNC_EDID_INFO_MF_NAME',
    'DEVICE_FUNC_EDID_INFO_1_DETAIL_TIMING',
    'DEVICE_FUNC_EDID_INFO_BLOCK2',
    'DEVICE_FUNC_EDID_INFO_AUDIO',
    'DEVICE_FUNC_EDID_INFO_VIDEO',
    'DEVICE_FUNC_EDID_INFO_VIDEO_TIMING',
    'DEVICE_FUNC_HDCP_OVERVIEW_LABEL',
    'DEVICE_FUNC_HDCP_TABLE_IN',
    'DEVICE_FUNC_HDCP_TABLE_OUT',
    'DEVICE_FUNC_HDCP_TABLE_CURRENT',
    'DEVICE_FUNC_HDCP_TABLE_ABILITY',
    'DEVICE_FUNC_HDCP_IN_CONF_LABEL',
    'DEVICE_FUNC_HDCP_MULTIVIEW_LABEL',
    'DEVICE_FUNC_HDCP_MULTIVIEW_SRC',
    'DEVICE_FUNC_HDCP_MULTIVIEW_MODE',
    'DEVICE_FUNC_HDCP_MUTE_LABEL',
    'DEVICE_FUNC_HDCP_MUTE_OUT',
    'DEVICE_FUNC_HDCP_MUTE_MODE',
    'DEVICE_FUNC_GEN_IP_CONF_LABEL',
    'DEVICE_FUNC_GEN_IP_CONF_LAN',
    'DEVICE_FUNC_GEN_IP_CONF_MAC',
    'DEVICE_FUNC_GEN_IP_CONF_DHCP',
    'DEVICE_FUNC_GEN_IP_CONF_IPADDR',
    'DEVICE_FUNC_GEN_IP_CONF_GW',
    'DEVICE_FUNC_GEN_IP_CONF_NM',
    'DEVICE_FUNC_GEN_DEV_NAME_LABEL',
    'DEVICE_FUNC_GEN_DEV_NAME',
    'DEVICE_FUNC_GEN_FAN_CTRL_LABEL',
    'DEVICE_FUNC_GEN_FAN_CTRL_SELECT',
    'DEVICE_FUNC_GEN_FAN_CTRL_MODE',
    'DEVICE_FUNC_GEN_FAN_CTRL_SPEED',
    'DEVICE_FUNC_GEN_SYS_LABEL',
    'DEVICE_FUNC_GEN_SYS_RESTORE',
    'DEVICE_FUNC_GEN_SYS_HOSTNAME',
    'DEVICE_FUNC_GEN_FRONT_PANEL_LABEL',
    'DEVICE_FUNC_GEN_FRONT_PANEL_KEYLOCK',
    'DEVICE_FUNC_AUDIO_OUT_LABEL',
    'DEVICE_FUNC_AUDIO_OUT_PORT',
    'DEVICE_FUNC_AUDIO_OUT_SRC',
    'DEVICE_FUNC_AUDIO_OUT_FORMAT',
    'DEVICE_FUNC_AUDIO_OUT_MUTE',
    'DEVICE_FUNC_AUDIO_OUT_VOL',
    'DEVICE_FUNC_AUDIO_OUT_TREBLE',
    'DEVICE_FUNC_AUDIO_OUT_BASS',
    'DEVICE_FUNC_AUDIO_OUT_DELAY',
    'DEVICE_FUNC_AUDIO_OUT_NAME',
    'DEVICE_FUNC_AUDIO_OUT_DSP_MODE',
    'DEVICE_FUNC_AUDIO_IN_LABEL',
    'DEVICE_FUNC_AUDIO_IN_PORT',
    'DEVICE_FUNC_AUDIO_IN_MUTE',
    'DEVICE_FUNC_AUDIO_IN_PREGAIN',
    'DEVICE_FUNC_AUDIO_IN_POWER_MODE',
    'DEVICE_FUNC_AUDIO_KNOB_LABEL',
    'DEVICE_FUNC_AUDIO_KNOB_LOCK',
    'DEVICE_FUNC_AUDIO_EQ_LABEL',
    'DEVICE_FUNC_AUDIO_EQ_OUT_SELECT',
    'DEVICE_FUNC_AUDIO_EQ_FREQ',
    'DEVICE_FUNC_AUDIO_EQ_LEVEL',
    'DEVICE_FUNC_AUDIO_MIXER_LABEL',
    'DEVICE_FUNC_AUDIO_MIXER_ON',
    'DEVICE_FUNC_AUDIO_MIXER_IN_LABEL',
    'DEVICE_FUNC_AUDIO_MIXER_IN_SELECT',
    'DEVICE_FUNC_AUDIO_MIXER_IN_VOL',
    'DEVICE_FUNC_AUDIO_MIXER_OUT_LABEL',
    'DEVICE_FUNC_AUDIO_MIXER_OUT_SELECT',
    'DEVICE_FUNC_AUDIO_MIXER_OUT_VOL',
    'DEVICE_FUNC_AUDIO_MIXER_OUT_SRC',
    'DEVICE_FUNC_AUDIO_TALKOVER_LABEL',
    'DEVICE_FUNC_AUDIO_TALKOVER_SELECT',
    'DEVICE_FUNC_AUDIO_TALKOVER_ON',
    'DEVICE_FUNC_AUDIO_TALKOVER_TRG_TIME',
    'DEVICE_FUNC_AUDIO_TALKOVER_ATK_TIME',
    'DEVICE_FUNC_AUDIO_TALKOVER_HLD_TIME',
    'DEVICE_FUNC_AUDIO_TALKOVER_RLS_TIME',
    'DEVICE_FUNC_AUDIO_TALKOVER_DPT',
    'DEVICE_FUNC_AUDIO_TALKOVER_THD',
    'DEVICE_FUNC_SCALER_OUT_LABEL',
    'DEVICE_FUNC_SCALER_OUT_SELECT',
    'DEVICE_FUNC_SCALER_OUT_TIMING',
    'DEVICE_FUNC_SCALER_OUT_AR',
    'DEVICE_FUNC_SCALER_OUT_CONTRAST',
    'DEVICE_FUNC_SCALER_OUT_BRIGHTNESS',
    'DEVICE_FUNC_SCALER_OUT_SATURATION',
    'DEVICE_FUNC_SCALER_OUT_HUE',
    'DEVICE_FUNC_SCALER_OUT_SHARP',
    'DEVICE_FUNC_SCALER_OUT_RGAIN',
    'DEVICE_FUNC_SCALER_OUT_GGAIN',
    'DEVICE_FUNC_SCALER_OUT_BGAIN',
    'DEVICE_FUNC_SCALER_OUT_4KDOWNSCALE',
    'DEVICE_FUNC_SCALER_OUT_4KUPSCALE',
    'DEVICE_FUNC_SCALER_WIN_LABEL',
    'DEVICE_FUNC_SCALER_WIN_LAYOUT_MODE',
    'DEVICE_FUNC_SCALER_WIN_CONF_LABEL',
    'DEVICE_FUNC_SCALER_WIN_CONF_SELECT',
    'DEVICE_FUNC_SCALER_WIN_CONF_SRC',
    'DEVICE_FUNC_SCALER_WIN_CONF_BRD_CLR',
    'DEVICE_FUNC_SCALER_WIN_CONF_BRD_SIZE',
    'DEVICE_FUNC_SCALER_PIP_LABEL',
    'DEVICE_FUNC_SCALER_PIP_DISP_MODE',
    'DEVICE_FUNC_SCALER_PIP_SIZE_H',
    'DEVICE_FUNC_SCALER_PIP_SIZE_V',
    'DEVICE_FUNC_SCALER_PIP_POS_H',
    'DEVICE_FUNC_SCALER_PIP_POS_V',
    'DEVICE_FUNC_SCALER_IN_TIMING_LABEL',
    'DEVICE_FUNC_DANTE_BASIC_LABEL',
    'DEVICE_FUNC_DANTE_BASIC_NAME',
    'DEVICE_FUNC_DANTE_BASIC_OP',
    'DEVICE_FUNC_DANTE_ROUTE_LABEL',
    'DEVICE_FUNC_DANTE_BASIC_ROUTE',
    'DEVICE_FUNC_DANTE_STEREO_ROUTE',
    'DEVICE_FUNC_DANTE_TX_LABEL',
    'DEVICE_FUNC_DANTE_RX_LABEL',
    'DEVICE_FUNC_DANTE_ROUTE_CHANNEL_NAME',
    'DEVICE_FUNC_DANTE_NETWORK_LABEL',
    'DEVICE_FUNC_DANTE_CH_NAMING_LABEL',
    'DEVICE_FUNC_DANTE_TRANSMITTER',
    'DEVICE_FUNC_DANTE_EDIT_RX_CH_NAME',
    'DEVICE_FUNC_DANTE_RX_CH_NAME',
    'DEVICE_FUNC_DANTE_DISCOVER',
    'DEVICE_FUNC_DANTE_FAC_DEFAULT',
    'DEVICE_FUNC_DANTE_FACDEF_IP_ALSO',
    'DEVICE_FUNC_TIME_NTP_LABEL',
    'DEVICE_FUNC_TIME_NTP_ON',
    'DEVICE_FUNC_TIME_NTP_SERVER',
    'DEVICE_FUNC_TIME_LOCAL_LABEL',
    'DEVICE_FUNC_TIME_LOCAL_TIMEZONE',
    'DEVICE_FUNC_TIME_LOCAL_DATE_TIME',
    'DEVICE_MODAL_EDIT_ROUTE_OUT_NAME',
    'DEVICE_MODAL_EDIT_ROUTE_PRESET_NAME',
    'ROOM_OVERVIEW_ALL',
    'ROOM_OVERVIEW_ROOM_NAME',
    'ROOM_OVERVIEW_ROOM_OPERATION',
    'ROOM_CONF_SCENARIO_LABEL',
    'ROOM_CONF_AUTOMATION_LABEL',
    'ROOM_CONF_COMMAND_LABEL',
    'ROOM_CONF_SCENARIO_ADD_SELECT',
    'ROOM_CONF_AUTOMATION_ADD_SELECT',
    'ROOM_CONF_COMMAND_ADD_SELECT',
    'ROOM_OVERVIEW_CREATE_NEW',
    'ROOM_OVERVIEW_CREATE_FROM_EXIST',
    'SCENARIO_OVERVIEW_ALL',
    'SCENARIO_OVERVIEW_SHOW_ITEMS',
    'SCENARIO_OVERVIEW_ICON',
    'SCENARIO_OVERVIEW_NAME',
    'SCENARIO_OVERVIEW_OPERATION',
    'SCENARIO_CONF_SCRIPT_LABEL',
    'SCENARIO_CONF_SCRIPT_ACTION_TYPE_SELECT',
    'SCENARIO_CONF_ADVANCED_LABEL',
    'SCENARIO_CONF_AUTOMATION_SELECT',
    'SCENARIO_CONF_SCRIPT_NEXT_SET_ACTION',
    'SCENARIO_CONF_SCRIPT_BACK',
    'SCENARIO_TITLE_EDIT_SCENARIO',
    'SCENARIO_TITLE_SELECT_ICON',
    'SCENARIO_OVERVIEW_CREATE_NEW',
    'SCENARIO_OVERVIEW_CREATE_FROM_EXIST',
    'AUTOMATION_OVERVIEW_ALL',
    'AUTOMATION_OVERVIEW_NAME',
    'AUTOMATION_OVERVIEW_OPERATION',
    'AUTOMATION_BUTTONTIP_CANVAS_IMPORT',
    'AUTOMATION_BUTTONTIP_CANVAS_EXPORT',
    'AUTOMATION_BUTTONTIP_CANVAS_EXIT_CUT',
    'AUTOMATION_BUTTONTIP_CANVAS_CUT',
    'AUTOMATION_BUTTONTIP_CANVAS_EXIT_MOVE',
    'AUTOMATION_BUTTONTIP_CANVAS_MOVE',
    'AUTOMATION_BUTTONTIP_CANVAS_REVERT',
    'AUTOMATION_BUTTONTIP_CANVAS_SAVE',
    'AUTOMATION_CUST_CMD_DESC1',
    'AUTOMATION_CUST_CMD_DESC2',
    'ICON_OVERVIEW_ALL',
    'ICON_OVERVIEW_SORT_BY',
    'ICON_OVERVIEW_SEARCH_BY',
    'ICON_MODAL_ADD_INFO_TITLE',
    'ICON_MODAL_UPLOAD_TITLE',
    'ICON_MODAL_EDIT_TITLE',
    'ICON_MODAL_ADD_NAME_LABEL',
    'ICON_MODAL_ADD_FILE_LABEL',
    'ICON_MODAL_EDIT_NAME_LABEL',
    'ICON_MODAL_EDIT_FILE_LABEL',
    'ICON_MODAL_UPLOAD_INFO_TITLE',
    'ICON_MODAL_UPLOAD_INFO_CUR_NAME',
    'ICON_MODAL_UPLOAD_INFO_FORMAT',
    'ICON_MODAL_UPLOAD_NEW_NAME',
    'ICON_IMPORT_BEHAVIOR_DESC_AUTO_RENAME',
    'ICON_IMPORT_BEHAVIOR_DESC_OVERWRITE',
    'ICON_IMPORT_BEHAVIOR_DESC_SKIP',
    'ICON_IMPORT_BEHAVIOR_REMEMBER_OPTION',
    'HOME_ROOM_SELECT',
    'HINT_FORMAT_INCORRECT',
    'HINT_DUPLICATE_NAME',
    'HINT_FORMAT_NOT_MATCH',
    'HINT_IP_FORMAT_NOT_MATCH',
    'HINT_DONT_CUT_OFF_POWER',
    'HINT_NAME_CANNOT_NULL',
    'HINT_CONTANS_INVALID_CHAR',
    'HINT_LAN_IP_INVALID',
    'HINT_PORT_INVALID',
    'HINT_DEVICE_CANNOT_NULL',
    'HINT_CMD_CANNOT_NULL',
    'HINT_KEYWORD_CANNOT_NULL',
    'HINT_POLLING_CANNOT_NULL',
    'HINT_DETECT_CANNOT_NULL',
    'HINT_HEX_FORMAT_INCORRECT',
    'HINT_FILE_FORMAT_INCORRECT',
    'HINT_ORDER_INVALID',
    'HINT_DELAY_INVALID',
    'HINT_DELAY_MUST_POSITIVE',
    'HINT_INTERVAL_INVALID',
    'HINT_AT_LEAST_ONE_WEEKDAY',
    'HINT_DATES_CONFLICT',
    'HINT_NO_SCENARIO',
    'HINT_NO_FAST_AUTOMATION',
    'HINT_NO_FAST_COMMAND',
    'HINT_PLEASE_LOGIN',
    'HINT_ICON_FORMAT',
    'HINT_ICON_PREFERRED',
    'CONFIRM_REMOVE_DEVICE',
    'CONFIRM_REMOVE_ICON',
    'CONFIRM_REMOVE_AUTOMATION',
    'CONFIRM_REMOVE_ROOM',
    'CONFIRM_REMOVE_SCENARIO_FROM_ROOM',
    'CONFIRM_REMOVE_AUTOMATION_FROM_ROOM',
    'CONFIRM_REMOVE_COMMAND_FROM_ROOM',
    'CONFIRM_REMOVE_SCENARIO',
    'CONFIRM_REMOVE_SCRIPT_FROM_SCENARIO',
    'CONFIRM_EDIT_USER',
    'CONFIRM_REMOVE_USER',
    'CONFIRM_REBOOT',
    'CONFIRM_FACTORY_DEFAULT',
    'CONFIRM_UNSAVE',
    'CONFIRM_START_SCENARIO',
    'CONFIRM_STOP_SCENARIO',
    'CONFIRM_START_AUTOMATION',
    'CONFIRM_STOP_AUTOMATION',
    'CONFIRM_REVERT_AUTOMATION_CANVAS',
    'CONFIRM_IMPORT_AUTOMATION_CANVAS',
    'CONFIRM_FWUP_DASHBOARD',
    'CONFIRM_FWUP_SINGLE_DEVICE',
    'CONFIRM_RESET_DEV_VW_SETUP',
    'CONFIRM_RESET_DEV_EDID_SETUP',
    'CONFIRM_UPLOAD_ICONS',
    'CONFIRM_IMPORT_SCENARIO',
    'CONFIRM_RESET_HOSTNAME',
    'CONFIRM_IMPORT_ROOM_BACKUP',
    'CONFIRM_IMPORT_SCENARIO_BACKUP',
    'CONFIRM_IMPORT_AUTOMATION_BACKUP',
    'CONFIRM_IMPORT_ICON_BACKUP',
    'NOTIFY_TITLE_SUCCESS',
    'NOTIFY_TITLE_ERROR',
    'NOTIFY_TITLE_FAIL',
    'NOTIFY_TITLE_WARNING',
    'NOTIFY_TITLE_UNAUTHORIZED',
    'NOTIFY_TITLE_CREATE_AUTOMATION',
    'NOTIFY_TITLE_IMPORT_AUTOMATION',
    'NOTIFY_TITLE_CREATE_SCENARIO',
    'NOTIFY_TITLE_IMPORT_SCENARIO',
    'NOTIFY_TITLE_CREATE_ROOM',
    'NOTIFY_TITLE_IMPORT_ROOM',
    'NOTIFY_TITLE_CREATE_ICON',
    'NOTIFY_TITLE_IMPORT_ICON',
    'NOTIFY_TITLE_CLIENT_ERROR',
    'NOTIFY_TITLE_DOWNLOAD_ERROR',
    'NOTIFY_TITLE_TEST_ACTION',
    'NOTIFY_TITLE_CONNECTION_ERROR',
    'NOTIFY_TITLE_TOPOLOGY_WARNING',
    'NOTIFY_TITLE_LOGIC_WARNING',
    'NOTIFY_TITLE_FWUP',
    'NOTIFY_TITLE_LANG_PACK_UPDATE',
    'NOTIFY_TITLE_START',
    'NOTIFY_TITLE_STOP',
    'NOTIFY_MSG_LOGIN_SUCCESS',
    'NOTIFY_MSG_CONNECT_SUCCESS',
    'NOTIFY_MSG_SUCCESS',
    'NOTIFY_MSG_TIMEOUT',
    'NOTIFY_MSG_UNAUTHORIZED_SHORT',
    'NOTIFY_MSG_UNAUTHORIZED',
    'NOTIFY_MSG_AUTOMATION_SIZE_LIMIT',
    'NOTIFY_MSG_ROOM_SIZE_LIMIT',
    'NOTIFY_MSG_SCENARIO_SIZE_LIMIT',
    'NOTIFY_MSG_ICON_SIZE_LIMIT',
    'NOTIFY_MSG_UPLOAD_SUCCESS',
    'NOTIFY_MSG_DEV_NO_RESPONSE',
    'NOTIFY_MSG_NETWORK_ERROR',
    'NOTIFY_MSG_FILENAME_INVALID',
    'NOTIFY_MSG_DEPRECATE_FORMAT',
    'NOTIFY_MSG_DATA_INVALID',
    'NOTIFY_MSG_FILE_FORMAT_INVALID',
    'NOTIFY_MSG_CHECK_CIRCULAR_ERR',
    'NOTIFY_MSG_CIRCULAR_EXIST',
    'NOTIFY_MSG_AUTOMATION_SAVE',
    'NOTIFY_MSG_NOT_GATE_SINGLE_SRC',
    'NOTIFY_MSG_CONFIG_NOT_FOUND',
    'NOTIFY_MSG_USERNAME_USED',
    'NOTIFY_MSG_USERNAME_EMPTY',
    'NOTIFY_MSG_USER_SIZE_LIMIT',
    'NOTIFY_MSG_TRY_RECONNECT',
    'NOTIFY_MSG_IMG_FORMAT_UNSUPPORT',
    'NOTIFY_MSG_FILE_SIZE_LIMIT',
    'NOTIFY_MSG_UNEXPECT_ERROR',
    'NOTIFY_MSG_PARTIAL_IMPORT',
    'NOTIFY_MSG_NOTHING_IMPORT',
    'TOOLTIP_LANGUAGE',
    'TOOLTIP_USER_LOGIN',
    'TOOLTIP_USER_LOGOUT',
    'TOOLTIP_HOME_PAGE',
    'TOOLTIP_DASHBOARD_PAGE',
    'TOOLTIP_DELETE_OBJECT',
    'TOOLTIP_LOCK',
    'TOOLTIP_UNLOCK',
    'TOOLTIP_TEST_ACTION',
    'TOOLTIP_NODE_CONFIG',
    'TOOLTIP_NOT_GATE',
    'TOOLTIP_AND_GATE',
    'TOOLTIP_OR_GATE',
    'TOOLTIP_EDIT_NAME',
    'TOOLTIP_EXPORT_ROOM',
    'TOOLTIP_DELETE_ROOM',
    'TOOLTIP_EXPORT_AUTOMATION',
    'TOOLTIP_DELETE_AUTOMATION',
    'TOOLTIP_EXPORT_SCENARIO',
    'TOOLTIP_EXPORT_CHANGE_ICON',
    'TOOLTIP_DELETE_SCENARIO',
    'TOOLTIP_DASHBOARD_DEV_SYNC',
    'TOOLTIP_DASHBOARD_DEV_SYNCING',
    'TOOLTIP_DASHBOARD_DEV_NOT_SUPPORT',
    'TOOLTIP_DASHBOARD_DEV_CONNECTING',
    'TOOLTIP_DASHBOARD_DEV_CONNECT_FAIL',
    'TOOLTIP_DASHBOARD_DEV_OFFLINE',
    'TOOLTIP_DASHBOARD_DEV_FWUP',
    'TOOLTIP_USER_ASSIGNED_ROOM_EDIT',
    'TOOLTIP_USER_PERMISSION_EDIT',
    'TOOLTIP_USER_REMOVE',
    'ALERT_OP_UNSUPPORT_NATIVE_OUT',
]);
export declare type TextPosition = (typeof text_position_type)[number];

declare type LanguagePack = {
    code: string;
    position: {[s in TextPosition]?: string};
}
export declare type LanguageMetadata = {
    code: string;
    path: string;
    localDescription: string;
}
function isLanguagePack(x: any): x is LanguagePack {
    if (x && typeof x.code === 'string' && x.position) {
        if (typeof x.position !== 'object') {
            return false;
        }
    }
    return true;
}
function isLanguageMetadata(x: any): x is LanguageMetadata {
    if (x && typeof x.code === 'string' && x.path && x.localDescription) {
        if (typeof x.path !== 'string') {
            return false;
        }
        if (typeof x.localDescription !== 'string') {
            return false;
        }
    }
    return true;
}
declare type ContextAccessor = { [s in TextPosition]?: { [s in string]?: string } };

const PackageReducer = (accumulator: ContextAccessor, curValue: LanguagePack): ContextAccessor => {
    const lang_pack = curValue;
    text_position_type.forEach(text_position => {
        const position_context = lang_pack.position[text_position];
        if (typeof position_context !== 'undefined') {
            const buf: { [s in string]?: string } = {};
            if (typeof accumulator[text_position] !== 'undefined')
                Object.assign(buf, accumulator[text_position]);
            buf[lang_pack.code] = position_context;
            accumulator[text_position] = buf;
        }
    });
    return accumulator;
}
let FILLIN: { [s in TextPosition]?: { [s in string]?: string } } = {};

export async function languagePackageLoad() {
    const Packages: Array<LanguagePack> = [];
    window.APP_LANGUAGE_METADATA = [];
    if (window.APP_DEV_MODE) {
        for (let i = 0; i < LanguageMetadataJson.list.length; i++) {
            const data = LanguageMetadataJson.list[i];
            if (isLanguageMetadata(data)) {
                window.APP_LANGUAGE_METADATA.push(data);
            }
        }
        if (isLanguagePack(EnglishLanguagePack)) {
            Packages.push(EnglishLanguagePack);
        }
        if (isLanguagePack(ChineseTraditionalLanguagePack)) {
            Packages.push(ChineseTraditionalLanguagePack);
        }
        if (isLanguagePack(JapaneseLanguagePack)) {
            Packages.push(JapaneseLanguagePack);
        }
    } else {
        const cur_time = new Date();
        const metaPath = `/languages/metadata.json?t=${cur_time.getTime()}`;
        const metadata = new CsxFileGrabber(metaPath);
        await metadata.load({ allowContentType: ['application/json'] });
        const dynamicLangMetatdata = JSON.parse(metadata.text);
        let englishPackIsLoaded = false;
        if (typeof dynamicLangMetatdata === 'object' && Array.isArray(dynamicLangMetatdata.list)) {
            for (let i = 0; i < dynamicLangMetatdata.list.length; i++) {
                const data = dynamicLangMetatdata.list[i];
                if (isLanguageMetadata(data)) {
                    window.APP_LANGUAGE_METADATA.push(data);
                    if (data.code === window.APP_LANGUAGE) {
                        const langPath = `/languages/${data.path}?t=${cur_time.getTime()}`;
                        const fileData = new CsxFileGrabber(langPath);
                        await fileData.load({ allowContentType: ['application/json'] });
                        const json = JSON.parse(fileData.text);
                        if (isLanguagePack(json)) {
                            Packages.push(json);
                            if (data.code === APP_LANG_TYPE.ENGLISH) {
                                englishPackIsLoaded = true;
                            }
                        }
                    }
                }
            }
        }
        if (!englishPackIsLoaded) {
            if (isLanguagePack(EnglishLanguagePack)) {
                Packages.push(EnglishLanguagePack);
            }
        }
    }
    FILLIN = Packages.reduce(PackageReducer, {});
}

export function getText(position: TextPosition, lang?: string): string {
    const position_context = FILLIN[position];
    if (position_context) {
        let default_ret = CsxUtil.defaultValue(position_context[APP_LANG_TYPE.ENGLISH], null, '');
        const text_ret = (lang) ? position_context[lang] : position_context[window.APP_LANGUAGE];
        return (text_ret) ? text_ret : default_ret;
    }
    return '';
}