import React, { CSSProperties } from 'react';

import * as CsxFeature from './feature';
import { RenderFieldType, CsxMessageBuffer } from './manager';


import './csx.css';

export interface SignalAbstract {
    ID: string;
    IRQIndex: RenderFieldType;
    irqHandler: (param?: any) => void;
    componentDidIRQUpdate?: (...args: Array<any>) => void;
}

function newMessageBuffer(max_size?: number) {
    return new CsxMessageBuffer(max_size);
}

export abstract class IRQComponent<P> extends React.Component<P> implements SignalAbstract {
    private __MIN_RENDER_INTERVAL?: number // ms
    private __last_render_timestamp =  0
    private __remember_render_timeout?: NodeJS.Timeout
    readonly ID = Math.random().toString().slice(2);
    IRQIndex: RenderFieldType = 'NoUpdate';
    MessageBuffer: CsxMessageBuffer = newMessageBuffer();
    RenderID?: string;
    readonly componentDidMount = () => { window.RENDER_LIST[this.IRQIndex][this.ID] = this.irqHandler; this.componentFinishMount(); }
    readonly componentWillUnmount = () => { this.setState = () => {}; delete window.RENDER_LIST[this.IRQIndex][this.ID]; this.__irqRender = () => {}; this.componentBeforeUmount(); }
    readonly getURLParameter = () => { return new URLSearchParams(window.location.search); }
    private __irqRender = () => {
        const cur_time = new Date().getTime();
        this.__last_render_timestamp = cur_time;
        this.forceUpdate();
    }
    /* args: [ timestamp, message, renderID ] */
    readonly irqHandler = (...args: Array<any>) => {
        if ((this.IRQIndex === 'MessageRealTime' || this.IRQIndex === 'GatewayMessageRealTime') &&
            typeof args[0] === 'number' && typeof args[1] === 'string') {
            if (args[1].trim().length === 0) { return; }
            this.MessageBuffer.push(args[0], args[1]);
        }

        /* If render ID is defined, we don't render it untill a corresponding rendering call */
        if (this.RenderID) {
            if (!args || args[2] !== this.RenderID) {
                return;
            }
        }
        /* This is to prevent UI blocked by frequently message informed */
        const timelapse_from_latest_render = (new Date().getTime()) - this.__last_render_timestamp; // get time in millisecond
        if ( (typeof this.__MIN_RENDER_INTERVAL === 'undefined') || (timelapse_from_latest_render >= this.__MIN_RENDER_INTERVAL) ) {
            if (typeof this.__remember_render_timeout === 'undefined')
                this.__irqRender();
        } else {
            if (typeof this.__remember_render_timeout === 'undefined') {
                this.__remember_render_timeout = global.setTimeout(() => {
                    if (this.__remember_render_timeout)
                        global.clearTimeout(this.__remember_render_timeout);
                    this.__remember_render_timeout = undefined;
                    this.__irqRender();
                }, (this.__MIN_RENDER_INTERVAL - timelapse_from_latest_render));
            }
        }
        this.componentDidIRQUpdate(...args);
    }
    validateString = (str: string, param: CsxFeature.STRING_PARAM): boolean => {
        let pass_regex = !(param.regex);

        if (param.regex)
            pass_regex = param.regex.test(str);

        if (pass_regex)
            return (str.length >= param.length.min && str.length <= param.length.max);
        else
            return false;
    }
    validateRange = (str: string, param: CsxFeature.RANGE_PARAM): boolean => {
        const parse = parseInt(str, (param.radix) ? param.radix : 10);
        if (isNaN(parse)) {
            return false;
        } else {
            if (parse >= param.range.min && parse <= param.range.max && (parse - param.range.min) % param.range.step === 0) {
                return (param.fixed) ? (param.fixed === str.length) : true;
            } else {
                return false;
            }
        }
    }
    componentDidIRQUpdate(..._: Array<any>) {}
    componentFinishMount() {}
    componentBeforeUmount() {}
    set MIN_RENDER_INTERVAL(millisecond: number) { this.__MIN_RENDER_INTERVAL = millisecond; }
}

export function getHiddenStyle(cond: boolean): CSSProperties { return { display: ((cond)?undefined:'none') }; }
// export function getHiddenStyle(cond: boolean): CSSProperties { return { display: ((cond || window.APP_DEV_MODE)?undefined:'none') }; }

export class FallbackImage extends React.Component<{ src?: string, width?: string, height?: string, onClick?: React.MouseEventHandler<HTMLImageElement> }> {
    state: { error: boolean } = { error: false };
    timestamp = new Date().getTime();
    handleFallback = () => { this.setState({ error: true }); }
    handleLoad = () => { this.setState({ error: false }); }
    // componentDidUpdate() { this.timestamp = new Date().getTime(); }
    render() {
        const { error } = this.state;
        const { src, width, height, onClick } = this.props;
        const alt = (src) ? src : 'default';
        const src_url = `${src}`;
        return (
            <div style={{
                    width: (width?width:'100%'),
                    height: (height?height:'100%'),
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    // borderRadius: '20px',
                    overflow: 'hidden',
                    paddingTop: '100%',
                }}
                className={error ? 'csx_fbimg_container fallback_empty' : 'csx_fbimg_container'}
                onClick={onClick}
            >
                <img
                    width='100%'
                    height='100%'
                    style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        color: 'transparent',
                    }}
                    src={src_url}
                    draggable={false}
                    onError={this.handleFallback}
                    onLoad={this.handleLoad}
                    alt={alt}
                />
            </div>
        );
    }
}

declare type StaticMatrixProps = {
    className?: string;
    style?: React.CSSProperties;
    frameDescription: {
        row: number;
        column: number;
        render: ((x: number, y: number) => React.ReactNode);
    }
}

declare type DynamicMatrixProps<T> = {
    className?: string;
    style?: React.CSSProperties;
    width?: number;
    height?: number;
    frameDescription: {
        array: Array<T>;
        /*
          * Prevent node being rendered incorrectly because of the same node key.
          * The length of this array must the same as basic array
        */
        indexArray?: Array<string>; 
        render: ((item: T) => React.ReactNode | null) | ((item: T, idx: number) => React.ReactNode | null);
        columnLimit?: number;
        marginBetween?: number; 
    }
}

export class StaticMatrix extends React.Component<StaticMatrixProps> {
    id: string = Math.random().toFixed().slice(2);
    render() {
        const { frameDescription, className, style } = this.props;
        const { row, column } = frameDescription;

        const frame_wp = 100 / row;
        const frame_hp = 100 / column;
        return (
            <div
                className={`csx_static_matrix_container ${className?className:''}`}
                style={style}
            >
                {Array.from(Array(row * column).keys()).map(idx => {
                    const x = idx % row;
                    const y = Math.floor(idx / row);
                    let classname = 'csx_static_matrix_cell';
                    if (x === 0)
                        classname += ' first-column';
                    if (y === 0)
                        classname += ' first-row';
                    if (x === row - 1)
                        classname += ' last-column';
                    if (y === column - 1)
                        classname += ' last-row';
                    return (
                        <div
                            key={`cell_${this.id}_${idx}`}
                            className={classname}
                            style={{ width: `${frame_wp}%`, height: `${frame_hp}%` }}
                        >
                            {frameDescription.render(x, y)}
                        </div>
                    );
                })}
            </div>
        );
    }
}

export class DynamicMatrix<T> extends React.Component<DynamicMatrixProps<T>> {
    id: string = Math.random().toFixed().slice(2);
    parentWidth: number = 0;
    /* get parent width */
    componentDidMount() { this.forceUpdate(); }
    getParentWidth = (inst: HTMLDivElement | null) => {
        if (inst) {
            const parent = inst.parentElement;
            if (parent) {
                const parentRect = parent.getBoundingClientRect();
                this.parentWidth = parentRect.width;
            }
        }
    }
    render() {
        const { width, height, frameDescription, className, style } = this.props;
        /**
         * Constants:                       Formula:
         *  y: Max frame in one row         w - m = y(m + x)
         *  w: container width              w - m = ym + yx
         *  m: margin size                  yx = w - m - ym
         *                                  x = (w - (y + 1)m) / y
         * We want:                         
         *  x: frame width                  
         */
         const max_section_size = (width) ? width : (this.parentWidth ? this.parentWidth : 600); // default
         const row_max_frame = (frameDescription.columnLimit) ? frameDescription.columnLimit : 5; // max frame count in one row (y)
         const frame_margin = (typeof frameDescription.marginBetween === 'number') ? frameDescription.marginBetween : 3; // px (m)
         const frame_size = (max_section_size - ((3 + row_max_frame) * frame_margin)) / row_max_frame; // px (x)

         const use_stlye: React.CSSProperties = (style) ? style : {};
         use_stlye.maxWidth = `${max_section_size}px`;
         use_stlye.maxHeight = (height ? `${height}px` : `calc(100% - ${frame_margin}px)`);
         use_stlye.padding = `${frame_margin}px 0 0 ${frame_margin}px`
         return (
            <div 
                className={`csx_dynamic_matrix_container ${className?className:''}`}
                style={use_stlye}
                ref={this.getParentWidth}
            >
                {frameDescription.array.map((item, idx) => {
                    const robj = frameDescription.render(item, idx);
                    const key = frameDescription.indexArray ? `csx_dm_item_${frameDescription.indexArray[idx]}` : `csx_dm_item_${idx}`;
                    return (robj) ? (
                        <div
                            key={key}
                            className='csx_dm_item'
                            style={{
                                minWidth: `${frame_size}px`,
                                height: `auto`,
                                margin: `0 ${frame_margin}px ${frame_margin}px 0`,
                            }}
                        >
                            {robj}
                        </div>
                    ) : null;
                }).filter(obj => !!obj)}
                {/* filling empty space, !!!!important */}
                {new Array(row_max_frame - 1).fill(undefined).map((_, idx) => idx).map((_, idx) => (
                    <div key={`icon_empty_space_${idx}`} className='csx_icon_empty_space' style={{ minWidth: `${frame_size}px`, margin: `0 ${frame_margin}px ${frame_margin}px 0`, }}></div>
                ))}
            </div>
         );
    }
}
