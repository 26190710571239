import { AESDecrypt, APP_SRC_TYPE } from './util';


declare type MessageHandler = (err: string) => void;

export default class CsxWs {

    private _request_url: string;
    private _client: WebSocket | undefined = undefined;
    private _ready = false;
    private _error: string | undefined = undefined;
    private _ping_interval?: NodeJS.Timeout;
    private _onrecv: MessageHandler | undefined = undefined;
    private _onerror: MessageHandler | undefined = undefined;
    private _ping_keyword: string = '00000000';
    private _unsubscribe_flag = false;

    constructor(port: string | number, origin: string, onrecv: MessageHandler, onerror: MessageHandler) {
        const auth = window.localStorage.getItem('auth');
        this._request_url = origin.replace('https', 'ws').replace('http', 'ws') + ':' + port;
        if (auth) {
            const [username, password] = atob(auth).split(':');
            this._request_url += `?user=${username}&pass=${password}`;
        }
        this._onrecv = onrecv;
        this._onerror = onerror;
        this.setup();
    }

    get CLIENT_READY() { return (this._ready === true); }
    get CLIENT_ERROR() { return (this._error) ? this._error.slice() : this._error; }
    setup = () => {
        // console.log('try connecting to :>> ', this._request_url);
        this.pureClose();
        this._client = new WebSocket(this._request_url);
        this._client.onmessage = this.onMessageRecv;
        this._client.onclose = this.onConnClose;
        this._client.onerror = this.pureClose;
    }
    ping = () => { if (window.APP_SRC === APP_SRC_TYPE.LOCAL) this.publish(this._ping_keyword); }
    private pureClose = () => { if (this._client) { this._client.close(); } }
    unsubscribe = () => { this._unsubscribe_flag = true; if (this._client) { this._client.close(); } }
    publish = (msg: string) => { if (this._client) { this._client.send(msg); } }
    messageHandler = (msg: string) => { if (this._onrecv) this._onrecv(msg); }
    onMessageRecv = (event: any) => { 
        if (!this._ready) {
            if (this._onerror) {
                if (AESDecrypt(event.data) === 'USERNAME_PASSWORD_ERROR') {
                    this._error = 'Username or password error!';
                    window.localStorage.removeItem('auth');
                    this._onerror(this._error);
                } else {
                    this._error = undefined;
                }
            }
        }
        if (!this._error) {
            if (!this._ready)
                this._ping_interval = global.setInterval(this.ping, 5000);
            this._ready = true;
            this.messageHandler(event.data);
        }
    }
    onConnClose = (event: any) => {
        const ecode = event.code;
        this._ready = false;
        if ( (ecode >= 0 && ecode <= 999) || ecode === 1004 || ecode >= 1016 )
            this._error = 'Connection closed: Unknown reason.';
        else if (ecode === 1001)
            this._error = 'The endpoint is going away, either because of a server failure or because the browser is navigating away from the page that opened the connection.';
        else if (ecode === 1002)
            this._error = 'The endpoint is terminating the connection due to a protocol error.';
        else if (ecode === 1002)
            this._error = 'The endpoint is terminating the connection due to a protocol error.';
        else if (ecode === 1003)
            this._error = 'The connection is being terminated because the endpoint received data of a type it cannot accept (for example, a text-only endpoint received binary data).';
        else if (ecode === 1005)
            this._error = 'Connection closed: No status received.';
        else if (ecode === 1006)
            this._error = 'Connection closed: Abnormal Closure.';
        else if (ecode === 1007)
            this._error = 'The endpoint is terminating the connection because a message was received that contained inconsistent data (e.g., non-UTF-8 data within a text message).';
        else if (ecode === 1008)
            this._error = 'The endpoint is terminating the connection because it received a message that violates its policy.'; //  This is a generic status code, used when codes 1003 and 1009 are not suitable.
        else if (ecode === 1009)
            this._error = 'The endpoint is terminating the connection because a data frame was received that is too large.';
        else if (ecode === 1010)
            this._error = 'The client is terminating the connection because it expected the server to negotiate one or more extension, but the server didn\'t.';
        else if (ecode === 1011)
            this._error = 'The server is terminating the connection because it encountered an unexpected condition that prevented it from fulfilling the request.';
        else if (ecode === 1012)
            this._error = 'The server is terminating the connection because it is restarting.';
        else if (ecode === 1013)
            this._error = 'The server is terminating the connection due to a temporary condition, e.g. it is overloaded and is casting off some of its clients.';
        else if (ecode === 1014)
            this._error = 'The server was acting as a gateway or proxy and received an invalid response from the upstream server.'; // This is similar to 502 HTTP Status Code.
        else if (ecode === 1015)
            this._error = 'Connection closed: TLS handshake.';

        if (this._ping_interval) {
            global.clearInterval(this._ping_interval);
        }
        if (this._error && this._onerror) {
            this._onerror(this._error);
        }
        if (this._error && !this._unsubscribe_flag) {
            global.setTimeout(this.setup, 1000);
        }
    }
}