import React from 'react';
import Draggable, { DraggableEvent } from 'react-draggable';

import { Icon, Tooltip } from 'cypd';
import { CsxEventSystem } from '../../../csx';
import { getText } from '../../../csx/desc';

export type NodePort = {
    name: string;
    displayName?: string;
    linkable?: boolean;
}

export type Edge = {
    from_node: number;
    to_node: number;
    from: string;
    to: string;
}

export type Node = {
    nid: number; type: CsxEventSystem.CsxNodeType; x: number; y: number;
    fields: { in: Array<NodePort>; out: Array<NodePort> };
    complete?: boolean;
    parents: Set<string>;
    data: CsxEventSystem.CsxActionParameter | CsxEventSystem.CsxEventParameter | CsxEventSystem.CsxLogicParameter;
}

export type DraggableProperties = {
    handle: string;
    disabled: boolean;
}

export interface NodeProperties {
    key?: string;
    node: Node;
    inputEnable: { [s: string]: Array<boolean> };
    outputEnable: { [s: string]: Array<boolean> };
    handler: {
        pinHandler?: {
            // onEnterPin: (event: DraggableEvent) => void;
            onPress: (nid: number, pid: number, event: DraggableEvent) => void;
            onLongPress: (nid: number, pid: number) => void;
        };
        nodeHandler?: {
            onRelease: (nid: number, pid: number, event: DraggableEvent) => void;
        };
        draggableHandler: {
            onStart: (nid: number, event: DraggableEvent) => void;
            onDrag: (nid: number, event: DraggableEvent) => void;
            onStop: (nid: number, event: DraggableEvent) => void;
            onDelete?: (nid: number) => void;
            onEdit?: (nid: number, data: any) => void;
            onRunTest?: (nid: number) => void;
            onOpen?: (nid: number) => void;
            onFixed?: (nid: number) => void;
        };
    };
    draggableProps: DraggableProperties;
}

export const DEFAULT_BOX_WIDTH = 280;
export const calc_h = (v: number) => (50 + (30 * Math.floor(v / 2) + 20 * Math.ceil(v / 2)));

class BasicNode extends React.Component<{ type: 'event' | 'action'; props: NodeProperties }> {
    state: { __internal_disable_drag: boolean } = { __internal_disable_drag: false };
    __ping_long_press_timer?: NodeJS.Timeout;
    componentWillUnmount() { if (this.__ping_long_press_timer) global.clearTimeout(this.__ping_long_press_timer); }
    handleAttachNode = (e: DraggableEvent) => {
        const { props } = this.props;
        const { node, handler } = props;
        const { nodeHandler } = handler;
        const { onRelease } = (nodeHandler) ? nodeHandler : { onRelease: () => { } };
        onRelease(node.nid, 0, e);
    }
    onMouseEnterNode = () => {
        document.addEventListener('mouseup', this.handleAttachNode, false);
        // document.addEventListener('touchend', this.handleAttachNode, false); // touch not support enter an leave event
    }
    onMouseLeaveNode = () => {
        document.removeEventListener('mouseup', this.handleAttachNode, false);
        // document.removeEventListener('touchend', this.handleAttachNode, false); // touch not support enter an leave event
    }
    onTouchReleaseOnNode = (e: DraggableEvent) => {
        const { props } = this.props;
        const { node, handler } = props;
        const { nodeHandler } = handler;
        const { onRelease } = (nodeHandler) ? nodeHandler : { onRelease: () => { } };
        onRelease(node.nid, 0, e);
    }
    onMouseEnterPin = () => { this.setState({ __internal_disable_drag: true }); }
    onMouseLeavePin = () => { this.setState({ __internal_disable_drag: false }); }
    render() {
        const { __internal_disable_drag } = this.state;
        const { type, props } = this.props;
        const { node, inputEnable, outputEnable, draggableProps, handler } = props;
        const { pinHandler, draggableHandler } = handler;
        const { onPress, onLongPress } = (pinHandler) ? pinHandler : { onPress: () => { }, onLongPress: () => { } };
        const { onDrag, onStart, onStop, onDelete, onOpen, onRunTest, onFixed } = draggableHandler;
        const { data, fields } = node;
        const in_size = fields.in.length;
        const out_size = fields.out.length;
        const estimate_h = (in_size > out_size) ? calc_h(in_size) : calc_h(out_size);
        // const title = props.node.data.split(',')[0];
        const title = (CsxEventSystem.isCsxActionParameter(data) || CsxEventSystem.isCsxEventParameter(data)) ? data.name : '';
        let boxClass = 'handle draggable-box';
        if (type === 'action')
            boxClass += ' yellow';
        else if (type === 'event')
            boxClass += ' blue';
        return (
            <Draggable
                key={(props.key) ? props.key : Math.random()}
                axis='both'
                grid={[1, 1]}
                position={{ x: node.x, y: node.y }}
                onDrag={(e) => { onDrag(node.nid, e); }}
                onStart={(e) => { this.onTouchReleaseOnNode(e); onStart(node.nid, e); }}
                onStop={(e) => { onStop(node.nid, e); }}
                handle={draggableProps.handle}
                disabled={(draggableProps.disabled || __internal_disable_drag || data.lockOnCanvas)}
            >
                <div
                    className={boxClass}
                    style={{ width: `${DEFAULT_BOX_WIDTH}px`, height: `${estimate_h + 10}px` }}
                    onPointerEnter={this.onMouseEnterNode}
                    onPointerLeave={this.onMouseLeaveNode}
                >
                    <div className='title'> 
                        <div style={{ position: 'absolute', width: '100%', height: '100%' }} onDoubleClick={onOpen ? () => { onOpen(node.nid); } : undefined}>{title}</div>
                        {(onDelete) ? <Tooltip text={getText('TOOLTIP_DELETE_OBJECT')}><Icon type='cancel' color='rgb(200,200,200)' style={{ transform: 'scale(0.5)' }} onClick={() => { onDelete(node.nid); }}/></Tooltip> : undefined}
                        <div className='toolbar' style={{ position: 'absolute', top: '20px', paddingRight: '3px', right: 0, height: '20px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', alignItems: 'center' }}>
                            {(onFixed) ? <Tooltip text={data.lockOnCanvas ? getText('TOOLTIP_UNLOCK') : getText('TOOLTIP_LOCK')}><Icon type={data.lockOnCanvas ? 'forbid' : 'pushpin'} color='rgb(200,200,200)' style={{ transform: 'scale(0.7)' }} onClick={() => { onFixed(node.nid); }} /></Tooltip> : undefined}
                            {(onRunTest) ? <Tooltip text={getText('TOOLTIP_TEST_ACTION')}><Icon type='solid-right' color='rgb(200,200,200)' style={{ transform: 'scale(0.7)' }} onClick={() => { onRunTest(node.nid); }} /></Tooltip> : undefined}
                            <Tooltip text={getText('TOOLTIP_NODE_CONFIG')}><Icon type='setting' color='rgb(200,200,200)' style={{ transform: 'scale(0.6)' }} onClick={onOpen ? () => { onOpen(node.nid); } : undefined} /></Tooltip>
                        </div>
                    </div>
                    <div className='boxbody' onDoubleClick={onOpen ? () => { onOpen(node.nid); } : undefined}>
                        {node.fields.in.map((input: NodePort, idx: number) => <div style={{ position: 'relative', top: `${calc_h(idx) - 40}px` }} key={`${node.nid}-in-${idx}`}>
                            <div className='dot left' style={{ display: ((input.linkable) ? undefined : 'none') }} ><div className={'dot-insight' + (inputEnable[node.nid][idx] ? ' visible' : '')} /></div>
                            <abbr title={(input.displayName) ? input.displayName : input.name} className='dot-label left'>{(input.displayName) ? input.displayName : input.name}</abbr>
                        </div>)} 
                        {node.fields.out.map((output: NodePort, idx: number) => <div style={{ position: 'relative', top: `${calc_h(idx) - 40}px` }} key={`${node.nid}-out-${idx}`}>
                            <div
                                className='dot right'
                                style={{ display: ((output.linkable) ? undefined : 'none') }}
                                onPointerEnter={this.onMouseEnterPin}
                                onPointerLeave={this.onMouseLeavePin}
                                // onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => { this.__ping_long_press_timer = global.setTimeout(() => { onLongPress(node.nid, idx); }, 2000); }}
                                // onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => { onPress(node.nid, idx, e); }}
                                onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => { onPress(node.nid, idx, e); }}
                                onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => { this.__ping_long_press_timer = global.setTimeout(() => { onLongPress(node.nid, idx); }, 1000); }}
                                onTouchEnd={() => { if (this.__ping_long_press_timer) global.clearTimeout(this.__ping_long_press_timer); }}
                            ><div className={'dot-insight' + (outputEnable[node.nid][idx] ? ' visible' : '')} /></div>
                            <abbr title={(output.displayName) ? output.displayName : output.name} className='dot-label right'>{(output.displayName) ? output.displayName : output.name}</abbr>
                        </div>)}
                    </div>
                </div>
            </Draggable>
        );
    }
}

export const BasicEventNode = (props: NodeProperties) => {
    return <BasicNode type='event' props={props} key={props.key}/>;
}

export const BasicActionNode = (props: NodeProperties) => {
    return <BasicNode type='action' props={props} key={props.key}/>;
}

const LogicTooltip: { [type in CsxEventSystem.CsxLogicType]: string } = {
    NotLogic: getText('TOOLTIP_NOT_GATE'),
    AndLogic: getText('TOOLTIP_AND_GATE'),
    OrLogic: getText('TOOLTIP_OR_GATE'),
    NullLogic: '',
}

class CircleNode extends React.Component<{ props: NodeProperties }> {
    state: { __internal_disable_drag: boolean } = { __internal_disable_drag: false };
    __ping_long_press_timer?: NodeJS.Timeout;
    componentWillUnmount() { if (this.__ping_long_press_timer) global.clearTimeout(this.__ping_long_press_timer); }
    handleAttachNode = (e: DraggableEvent) => {
        const { props } = this.props;
        const { node, handler } = props;
        const { nodeHandler } = handler;
        const { onRelease } = (nodeHandler) ? nodeHandler : { onRelease: () => { } };
        onRelease(node.nid, 0, e);
    }
    onMouseEnterNode = () => {
        document.addEventListener('mouseup', this.handleAttachNode, false);
        // document.addEventListener('touchend', this.handleAttachNode, false);
    }
    onMouseLeaveNode = () => {
        document.removeEventListener('mouseup', this.handleAttachNode, false);
        // document.removeEventListener('touchend', this.handleAttachNode, false);
    }
    onTouchReleaseOnNode = (e: DraggableEvent) => {
        const { props } = this.props;
        const { node, handler } = props;
        const { nodeHandler } = handler;
        const { onRelease } = (nodeHandler) ? nodeHandler : { onRelease: () => { } };
        onRelease(node.nid, 0, e);
    }
    onMouseEnterPin = () => { this.setState({ __internal_disable_drag: true }); }
    onMouseLeavePin = () => { this.setState({ __internal_disable_drag: false }); }
    render() {
        const { __internal_disable_drag } = this.state;
        const { node, inputEnable, outputEnable, draggableProps, handler, key } = this.props.props;
        const { pinHandler, draggableHandler } = handler;
        const { onPress, onLongPress } = (pinHandler) ? pinHandler : { onPress: () => { }, onLongPress: () => { } };
        const { onDrag, onStart, onStop, onDelete } = draggableHandler;
        const { data } = node;
        return (
            <Draggable
                key={(key) ? key : Math.random()}
                axis='both'
                grid={[1, 1]}
                position={{ x: node.x, y: node.y }}
                onDrag={(e) => { onDrag(node.nid, e); }}
                onStart={(e) => { this.onTouchReleaseOnNode(e); onStart(node.nid, e); }}
                onStop={(e) => { onStop(node.nid, e); }}
                handle={draggableProps.handle}
                disabled={(draggableProps.disabled || __internal_disable_drag || data.lockOnCanvas)}
            >
                <div
                    className={'handle draggable-box ball ' + node.type}
                    onPointerEnter={this.onMouseEnterNode}
                    onPointerLeave={this.onMouseLeaveNode}
                >
                    <div className='dot left' ><div className={'dot-insight' + (inputEnable[node.nid][0] ? ' visible' : '')} /></div>
                    <div
                        className='dot right'
                        onPointerEnter={this.onMouseEnterPin}
                        onPointerLeave={this.onMouseLeavePin}
                        onMouseDown={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => { onPress(node.nid, 0, e); }}
                        // onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => { onPress(node.nid, 0, e); }}
                        onTouchStart={(e: React.TouchEvent<HTMLDivElement>) => { this.__ping_long_press_timer = global.setTimeout(() => { onLongPress(node.nid, 0); }, 1000); }}
                        onTouchEnd={() => { if (this.__ping_long_press_timer) global.clearTimeout(this.__ping_long_press_timer); }}
                    ><div className={'dot-insight' + (outputEnable[node.nid][0] ? ' visible' : '')} /></div>
                    <Tooltip text={(CsxEventSystem.isCsxLogicType(node.type)?LogicTooltip[node.type]:'')} fillinOutside/>
                    {(onDelete) ? <div className='remove' onClick={() => { onDelete(node.nid); }}></div> : undefined}
                </div>
            </Draggable>
        );
    }
}

export const LogicNode = (props: NodeProperties) => {
    return <CircleNode props={props} key={props.key}/>;
}
