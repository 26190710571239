import CryptoJS from 'crypto-js';

// eCypress_Device_Wait_Discovery = 0,
// eCypress_Device_Wait_Add,
// eCypress_Device_TCP_Connect,
// eCypress_Device_A17_Connect,
// eCypress_Device_A18_Connect,
// eCypress_Device_Command_Receive,
// eCypress_Device_Sync,
// eCypress_Device_Firmware_Update,
// eCypress_Device_TCP_Connect_Error,
// eCypress_Device_A17_Connect_Error,
// eCypress_Device_Command_Receive_Error,
// eCypress_Device_Offline,
export const stringLitArray = <L extends string>(arr: L[]) => arr;
export enum SYS_STA_TYPE { Wait_Discovery = 0, Wait_Add, TCP_Connect, A17_Connect, A18_Connect, All_Connect, A13_Connect, Sync, Firmware_Update, TCP_Connect_Error, A17_Connect_Error, Offline, Bad_Status }

export function isCsxSystemStateConnected(type: SYS_STA_TYPE) {
    switch (type) {
        case SYS_STA_TYPE.Wait_Add:
        case SYS_STA_TYPE.Wait_Discovery:
        case SYS_STA_TYPE.Bad_Status:
        case SYS_STA_TYPE.TCP_Connect:
        case SYS_STA_TYPE.TCP_Connect_Error:
        case SYS_STA_TYPE.Offline:
            return false;
        default:
            return true;
    }
}

export function isCsxSystemStateSync(type: SYS_STA_TYPE) {
    return (type === SYS_STA_TYPE.Sync);
}

export enum APP_SRC_TYPE { DYNAMODB = 'dynamodb', LOCAL = 'websocket' }
export enum APP_LANG_TYPE { CHINESE = 'ch-traditional', ENGLISH = 'en-us', JAPANESE = 'jp' }
// 'H30',
// 'H31',
// 'H32',
// 'H33',
// 'H34',
// 'H35',
// 'H36',
// 'H37',
// 'H38',
// 'H39',
const cypstd_cmd_type = stringLitArray([
    'Z1','Z2','Z3','Z4','Z5','Z6','Z7','Z8','Z9','Z10','Z10','Z11','Z12','Z13','Z14','Z15','Z16','Z17','Z18','Z19','Z20','Z21','Z22','Z23','Z24','Z25','Z26','Z27','Z28','Z29','Z30','Z31','Z32','Z33','Z34','Z35',
    'A1','A2','A3','A4','A5','A6','A7','A8','A9','A10','A11','A12','A13','A14','A15','A16','A17','A18','A19','A20','A21','A22','A23','A24','A25','A26','A27','A28','A29','A30','A31','A32','A33','A34','A35','A36','A37','A38','A39','A40','A41','A42','A43','A44','A45','A46','A47','A48','A49','A50','A51','A52','A53','A54','A55','A56','A57','A58','A59','A60','A61','A62','A63','A64','A65','A66','A67','A68','A69','A70','A71','A72','A73','A74','A75','A76','A77','A78','A79','A80','A81','A82','A83','A84','A85','A86','A87','A88','A89','A90','A91','A92','A93','A94','A95','A96','A97','A98','A99','A100','A101','A102','A103','A104','A105','A106','A107','A108','A109','A110','A111','A112','A113','A114','A115','A116','A117','A118','A119','A120','A121','A122','A123','A124','A125','A126','A127','A128','A129','A130','A131','A132','A133','A134','A135','A136','A137','A138','A139','A140','A141','A142','A143','A144','A145','A146','A147','A148','A149','A150','A151',
    'B1','B2','B3','B4','B5','B6','B7','B8','B9','B10','B11','B12','B13','B14','B15','B16','B17','B18','B19','B20','B21','B22','B23','B24','B25','B26','B27','B28', 'B29', 'B30', 'B31', 'B32', 'B33', 'B34', 'B35', 'B36', 'B37', 'B38', 'B39', 'B40', 'B41', 'B42', 'B43', 'B44', 'B45', 'B46', 'B47', 'B48', 'B49', 'B50', 'B51', 'B52', 'B53', 'B54', 'B55', 'B56', 'B57', 'B58', 'B59', 'B60', 'B61', 'B62', 'B63', 'B64', 'B65', 'B66', 'B67', 'B68', 'B69',
    'C1','C2','C3','C4','C5','C6','C7','C8','C9','C10','C11','C12','C13','C14','C15','C16','C17',
    'D1','D2','D3','D4','D5','D6','D7','D8','D9','D10','D11','D12','D13','D14','D15','D16','D17','D18','D19','D20','D21','D22','D23','D24','D25','D26','D27','D28','D29','D30','D31','D32','D33','D34','D35','D36','D37','D38','D39','D40','D41','D42','D43','D44','D45','D46','D47','D48','D49','D50','D51','D52','D53','D54','D55','D56','D57','D58','D59','D60','D61','D62','D63','D64','D65','D66','D67','D68','D69','D70','D71','D72','D73','D74','D75','D76','D77','D78','D79','D80','D81','D82','D83','D84','D85','D86','D87','D88','D89', 'D90', 'D91', 'D92', 'D93', 'D94', 'D95', 'D96', 'D97', 'D98', 'D99', 'D100', 'D101', 'D102', 'D103', 'D104', 'D105', 'D106', 'D107', 'D108', 'D109', 'D110', 'D111', 'D112', 'D113', 'D114', 'D115', 'D116', 'D117', 'D118', 'D119', 'D120', 'D121', 'D122', 'D123', 'D124', 'D125', 'D126', 'D127', 'D128', 'D129', 'D130', 'D131', 'D132', 'D133', 'D134', 'D135', 'D136', 'D137', 'D138', 'D139', 'D140', 'D141', 'D142', 'D143', 'D144', 'D145', 'D146', 'D147', 'D148', 'D149', 'D150', 'D151', 'D152', 'D153', 'D154', 'D155', 'D156', 'D157', 'D158', 'D159',
    'D160', 'D161', 'D162', 'D163', 'D164', 'D165', 'D166', 'D167', 'D168', 'D169', 'D170', 'D171', 'D172', 'D173', 'D174', 'D175', 'D176', 'D177', 'D178', 'D179', 'D180', 'D181', 'D182', 'D183', 'D184', 'D185', 'D186', 'D187', 'D188', 'D189', 'D190', 'D191', 'D192', 'D193', 'D194', 'D195', 'D196', 'D197', 'D198', 'D199',
    'E1','E2','E3','E4','E5','E6','E7','E8','E9','E10','E11','E12','E13','E14','E15','E16','E17','E18','E19','E20','E21','E22','E23','E24','E25','E26','E27','E28','E29','E30','E31','E32','E33','E34','E35','E36','E37','E38','E39','E40','E41','E42','E43','E44','E45','E46','E47','E48','E49','E50','E51','E52','E53','E54','E55','E56','E57','E58','E59','E60','E61','E62','E63','E64','E65','E66','E67','E68','E69','E70','E71','E72','E73','E74','E75','E76','E77','E78','E79','E80','E81','E82','E83','E84','E85','E86','E87','E88','E89',
    'F1','F2','F3','F4','F5','F6',
    'G1','G2','G3','G4','G5','G6','G7','G8','G9','G10','G11','G12','G13','G14','G15','G16','G17','G18','G19','G20','G21','G22','G23','G24','G25','G26','G27','G28','G29','G30','G31','G32','G33','G34','G35','G36','G37','G38','G39', 'G40', 'G41', 'G42', 'G43', 'G44', 'G45', 'G46', 'G47', 'G48', 'G49', 'G50', 'G51', 'G52', 'G53', 'G54', 'G55', 'G56', 'G57', 'G58', 'G59',
    'H1','H2','H3','H4','H5','H6','H7','H8','H9','H10','H11','H12','H13','H14','H15','H16','H17','H18','H19','H20','H21','H22','H23','H24','H25','H26','H27','H28','H29','H30','H31','H32','H33','H34','H35','H36','H37','H38','H39','H40','H41','H42','H43','H44','H45','H46','H47','H48','H49','H50','H51','H52','H53','H54','H55','H56','H57','H58','H59','H60','H61','H62','H63','H64','H65','H66','H67','H68','H69',
    'I1','I2','I3','I4','I5','I6','I7','I8','I9','I10','I11','I12','I13','I14','I15','I16','I17','I18','I19', 'I20', 'I21', 'I22', 'I23', 'I24', 'I25', 'I26', 'I27', 'I28', 'I29',
    'J0','J1','J2','J3','J4','J5','J6','J7','J8','J9','J10',
    'K1','K2','K3','K4','K5','K6','K7','K8','K9',
    'L1','L2','L3','L4','L5','L6','L7','L8','L9','L10','L11','L12','L13','L14','L15','L16',
    'M1','M2','M3','M4','M5','M6','M7','M8','M9','M10','M11','M12','M13','M14','M15','M16','M17','M18','M19','M20','M21','M22','M23','M24','M25','M26','M27','M28','M29','M30','M31','M32','M33','M34','M35','M36','M37','M38','M39','M40','M41','M42','M43','M44','M45','M46','M47','M48','M49','M50','M51','M52','M53','M54','M55','M56','M57','M58','M59','M60','M61','M62','M63','M64','M65','M66','M67','M68','M69','M70','M71','M72','M73','M74','M75','M76','M77','M78','M79','M80','M81','M82','M83','M84','M85','M86','M87','M88','M89','M90','M91','M92','M93','M94','M95','M96','M97','M98','M99','M100','M101','M102','M103','M104','M105','M106','M107','M108','M109','M110','M111','M112','M113','M114','M115','M116','M117','M118','M119',
    'N1','N2','N3','N4','N5','N6','N7','N8','N9','N10','N11','N12','N13','N14','N15','N16','N17','N18','N19',
    'O1','O2','O3','O4','O5','O6','O7','O8','O9','O10','O11','O12','O13','O14','O15','O16','O17','O18','O19','O20','O21','O22','O23','O24','O25','O26','O27','O28','O29',
    'P1','P2','P3','P4','P5','P6','P7','P8','P9','P10','P11','P12','P13','P14','P15','P16','P17','P18','P19',
    'Q1','Q2','Q3','Q4','Q5','Q6','Q7','Q8','Q9','Q10','Q11','Q12','Q13','Q14','Q15','Q16','Q17','Q18','Q19','Q20','Q21','Q22','Q23','Q24','Q25','Q26','Q27','Q28','Q29','Q30','Q31','Q32','Q33','Q34','Q35','Q36','Q37','Q38','Q39','Q40','Q41','Q42','Q43','Q44','Q45','Q46','Q47','Q48','Q49','Q50','Q51','Q52','Q53','Q54','Q55','Q56','Q57','Q58','Q59','Q60','Q61','Q62','Q63','Q64','Q65','Q66','Q67','Q68','Q69','Q70','Q71','Q72','Q73','Q74','Q75','Q76','Q77','Q78','Q79','Q80','Q81','Q82','Q83','Q84','Q85','Q86','Q87','Q88','Q89','Q90','Q91','Q92','Q93','Q94','Q95','Q96','Q97','Q98','Q99',
    'R1','R2','R3','R4','R5','R6','R7','R8','R9','R10','R11','R12','R13','R14','R15','R16','R17','R18','R19',
    'S1','S2','S3','S4','S5','S6','S7','S8','S9','S10','S11','S12','S13','S14','S15','S16','S17','S18','S19','S20','S21','S22','S23','S24','S25','S26','S27','S28','S29','S30','S31','S32','S33','S34','S35','S36','S37','S38','S39','S40','S41','S42','S43','S44','S45','S46','S47','S48','S49',
    'T1','T2','T3','T4','T5','T6','T7','T8','T9','T10','T11','T12','T13','T14','T15','T16','T17','T18','T19','T20','T21','T22','T23','T24','T25','T26','T27','T28','T29',
    'ZZ1','ZZ2',
]);


const CYP_FCSTAB = [
    0x0000, 0x1189, 0x2312, 0x329B, 0x4624, 0x57AD, 0x6536, 0x74BF,
    0x8C48, 0x9DC1, 0xAF5A, 0xBED3, 0xCA6C, 0xDBE5, 0xE97E, 0xF8F7,
    0x1081, 0x0108, 0x3393, 0x221A, 0x56A5, 0x472C, 0x75B7, 0x643E,
    0x9CC9, 0x8D40, 0xBFDB, 0xAE52, 0xDAED, 0xCB64, 0xF9FF, 0xE876,
    0x2102, 0x308B, 0x0210, 0x1399, 0x6726, 0x76AF, 0x4434, 0x55BD,
    0xAD4A, 0xBCC3, 0x8E58, 0x9FD1, 0xEB6E, 0xFAE7, 0xC87C, 0xD9F5,
    0x3183, 0x200A, 0x1291, 0x0318, 0x77A7, 0x662E, 0x54B5, 0x453C,
    0xBDCB, 0xAC42, 0x9ED9, 0x8F50, 0xFBEF, 0xEA66, 0xD8FD, 0xC974,
    0x4204, 0x538D, 0x6116, 0x709F, 0x0420, 0x15A9, 0x2732, 0x36BB,
    0xCE4C, 0xDFC5, 0xED5E, 0xFCD7, 0x8868, 0x99E1, 0xAB7A, 0xBAF3,
    0x5285, 0x430C, 0x7197, 0x601E, 0x14A1, 0x0528, 0x37B3, 0x263A,
    0xDECD, 0xCF44, 0xFDDF, 0xEC56, 0x98E9, 0x8960, 0xBBFB, 0xAA72,
    0x6306, 0x728F, 0x4014, 0x519D, 0x2522, 0x34AB, 0x0630, 0x17B9,
    0xEF4E, 0xFEC7, 0xCC5C, 0xDDD5, 0xA96A, 0xB8E3, 0x8A78, 0x9BF1,
    0x7387, 0x620E, 0x5095, 0x411C, 0x35A3, 0x242A, 0x16B1, 0x0738,
    0xFFCF, 0xEE46, 0xDCDD, 0xCD54, 0xB9EB, 0xA862, 0x9AF9, 0x8B70,
    0x8408, 0x9581, 0xA71A, 0xB693, 0xC22C, 0xD3A5, 0xE13E, 0xF0B7,
    0x0840, 0x19C9, 0x2B52, 0x3ADB, 0x4E64, 0x5FED, 0x6D76, 0x7CFF,
    0x9489, 0x8500, 0xB79B, 0xA612, 0xD2AD, 0xC324, 0xF1BF, 0xE036,
    0x18C1, 0x0948, 0x3BD3, 0x2A5A, 0x5EE5, 0x4F6C, 0x7DF7, 0x6C7E,
    0xA50A, 0xB483, 0x8618, 0x9791, 0xE32E, 0xF2A7, 0xC03C, 0xD1B5,
    0x2942, 0x38CB, 0x0A50, 0x1BD9, 0x6F66, 0x7EEF, 0x4C74, 0x5DFD,
    0xB58B, 0xA402, 0x9699, 0x8710, 0xF3AF, 0xE226, 0xD0BD, 0xC134,
    0x39C3, 0x284A, 0x1AD1, 0x0B58, 0x7FE7, 0x6E6E, 0x5CF5, 0x4D7C,
    0xC60C, 0xD785, 0xE51E, 0xF497, 0x8028, 0x91A1, 0xA33A, 0xB2B3,
    0x4A44, 0x5BCD, 0x6956, 0x78DF, 0x0C60, 0x1DE9, 0x2F72, 0x3EFB,
    0xD68D, 0xC704, 0xF59F, 0xE416, 0x90A9, 0x8120, 0xB3BB, 0xA232,
    0x5AC5, 0x4B4C, 0x79D7, 0x685E, 0x1CE1, 0x0D68, 0x3FF3, 0x2E7A,
    0xE70E, 0xF687, 0xC41C, 0xD595, 0xA12A, 0xB0A3, 0x8238, 0x93B1,
    0x6B46, 0x7ACF, 0x4854, 0x59DD, 0x2D62, 0x3CEB, 0x0E70, 0x1FF9,
    0xF78F, 0xE606, 0xD49D, 0xC514, 0xB1AB, 0xA022, 0x92B9, 0x8330,
    0x7BC7, 0x6A4E, 0x58D5, 0x495C, 0x3DE3, 0x2C6A, 0x1EF1, 0x0F78
]

export function CRC16(fcs: number, data: Array<number>) {
    let checksum = fcs;
    data.forEach(value => {
        checksum = (checksum >> 8) ^ CYP_FCSTAB[(checksum ^ value) & 0xff];
    });
    return checksum;
}

declare type Resolution = { h: number; v: number; interlaced: boolean; refresh: number; ar: '4:3' | '16:9' | '64:27' | '256:135'; clock: number; native?: boolean }
const VIC_TABLE: { [s: number]: Resolution } = {
    1: { h: 640, v: 480, interlaced: false, refresh: 60, ar: '4:3', clock: 25.175 },
    2: { h: 720, v: 480, interlaced: false, refresh: 60, ar: '4:3', clock: 27 },
    3: { h: 640, v: 480, interlaced: false, refresh: 60, ar: '16:9', clock: 27 },
    4: { h: 1280, v: 720, interlaced: false, refresh: 60, ar: '16:9', clock: 74.25 },
    5: { h: 1920, v: 1080, interlaced: true, refresh: 60, ar: '16:9', clock: 74.25 },
    6: { h: 1440, v: 480, interlaced: true, refresh: 60, ar: '4:3', clock: 27 },
    7: { h: 1440, v: 480, interlaced: true, refresh: 60, ar: '16:9', clock: 27 },
    8: { h: 1440, v: 240, interlaced: false, refresh: 60, ar: '4:3', clock: 27 },
    9: { h: 1440, v: 240, interlaced: false, refresh: 60, ar: '16:9', clock: 27 },
    10: { h: 2880, v: 480, interlaced: true, refresh: 60, ar: '4:3', clock: 54 },
    11: { h: 2880, v: 480, interlaced: true, refresh: 60, ar: '16:9', clock: 54 },
    12: { h: 2880, v: 240, interlaced: false, refresh: 60, ar: '4:3', clock: 54 },
    13: { h: 2880, v: 240, interlaced: false, refresh: 60, ar: '16:9', clock: 54 },
    14: { h: 1440, v: 480, interlaced: false, refresh: 60, ar: '4:3', clock: 54 },
    15: { h: 1440, v: 480, interlaced: false, refresh: 60, ar: '16:9', clock: 54 },
    16: { h: 1920, v: 1080, interlaced: false, refresh: 60, ar: '16:9', clock: 148.5 },
    17: { h: 720, v: 576, interlaced: false, refresh: 50, ar: '4:3', clock: 27 },
    18: { h: 720, v: 576, interlaced: false, refresh: 50, ar: '16:9', clock: 27 },
    19: { h: 1280, v: 720, interlaced: false, refresh: 50, ar: '16:9', clock: 74.25 },
    20: { h: 1920, v: 1080, interlaced: true, refresh: 50, ar: '16:9', clock: 74.25 },
    21: { h: 1440, v: 576, interlaced: true, refresh: 50, ar: '4:3', clock: 27 },
    22: { h: 1440, v: 576, interlaced: true, refresh: 50, ar: '16:9', clock: 27 },
    23: { h: 1440, v: 288, interlaced: false, refresh: 50, ar: '4:3', clock: 27 },
    24: { h: 1440, v: 288, interlaced: false, refresh: 50, ar: '16:9', clock: 27 },
    25: { h: 2880, v: 576, interlaced: true, refresh: 50, ar: '4:3', clock: 54 },
    26: { h: 2880, v: 576, interlaced: true, refresh: 50, ar: '16:9', clock: 54 },
    27: { h: 2880, v: 288, interlaced: false, refresh: 50, ar: '4:3', clock: 54 },
    28: { h: 2880, v: 288, interlaced: false, refresh: 50, ar: '16:9', clock: 54 },
    29: { h: 1440, v: 576, interlaced: false, refresh: 50, ar: '4:3', clock: 54 },
    30: { h: 1440, v: 576, interlaced: false, refresh: 50, ar: '16:9', clock: 54 },
    31: { h: 1920, v: 1080, interlaced: false, refresh: 50, ar: '16:9', clock: 148.5 },
    32: { h: 1920, v: 1080, interlaced: false, refresh: 24, ar: '16:9', clock: 74.25 },
    33: { h: 1920, v: 1080, interlaced: false, refresh: 25, ar: '16:9', clock: 74.25 },
    34: { h: 1920, v: 1080, interlaced: false, refresh: 30, ar: '16:9', clock: 74.25 },
    35: { h: 2880, v: 480, interlaced: false, refresh: 60, ar: '4:3', clock: 108 },
    36: { h: 2880, v: 480, interlaced: false, refresh: 60, ar: '16:9', clock: 108 },
    37: { h: 2880, v: 576, interlaced: false, refresh: 50, ar: '4:3', clock: 108 },
    38: { h: 2880, v: 576, interlaced: false, refresh: 50, ar: '16:9', clock: 108 },
    39: { h: 1920, v: 1080, interlaced: true, refresh: 50, ar: '16:9', clock: 72 },
    40: { h: 1920, v: 1080, interlaced: true, refresh: 100, ar: '16:9', clock: 148.5 },
    41: { h: 1280, v: 720, interlaced: false, refresh: 100, ar: '16:9', clock: 148.5 },
    42: { h: 720, v: 576, interlaced: false, refresh: 100, ar: '4:3', clock: 54 },
    43: { h: 720, v: 576, interlaced: false, refresh: 100, ar: '16:9', clock: 54 },
    44: { h: 1440, v: 576, interlaced: true, refresh: 100, ar: '4:3', clock: 54 },
    45: { h: 1440, v: 576, interlaced: true, refresh: 100, ar: '16:9', clock: 54 },
    46: { h: 1920, v: 1080, interlaced: true, refresh: 120, ar: '16:9', clock: 148.5 },
    47: { h: 1280, v: 720, interlaced: false, refresh: 120, ar: '16:9', clock: 148.5 },
    48: { h: 720, v: 480, interlaced: false, refresh: 120, ar: '4:3', clock: 148.5 },
    49: { h: 720, v: 480, interlaced: false, refresh: 120, ar: '16:9', clock: 148.5 },
    50: { h: 1440, v: 480, interlaced: true, refresh: 120, ar: '4:3', clock: 148.5 },
    51: { h: 1440, v: 480, interlaced: true, refresh: 120, ar: '16:9', clock: 148.5 },
    52: { h: 720, v: 576, interlaced: false, refresh: 200, ar: '4:3', clock: 108 },
    53: { h: 720, v: 576, interlaced: false, refresh: 200, ar: '16:9', clock: 108 },
    54: { h: 1440, v: 576, interlaced: true, refresh: 200, ar: '4:3', clock: 108 },
    55: { h: 1440, v: 576, interlaced: true, refresh: 200, ar: '16:9', clock: 108 },
    56: { h: 720, v: 480, interlaced: false, refresh: 240, ar: '4:3', clock: 108 },
    57: { h: 720, v: 480, interlaced: false, refresh: 240, ar: '16:9', clock: 108 },
    58: { h: 1440, v: 480, interlaced: true, refresh: 240, ar: '4:3', clock: 108 },
    59: { h: 1440, v: 480, interlaced: true, refresh: 240, ar: '16:9', clock: 108 },
    60: { h: 1280, v: 720, interlaced: false, refresh: 24, ar: '16:9', clock: 59.4 },
    61: { h: 1280, v: 720, interlaced: false, refresh: 25, ar: '16:9', clock: 74.25 },
    62: { h: 1280, v: 720, interlaced: false, refresh: 30, ar: '16:9', clock: 74.25 },
    63: { h: 1920, v: 1080, interlaced: false, refresh: 120, ar: '16:9', clock: 297 },
    64: { h: 1920, v: 1080, interlaced: false, refresh: 100, ar: '16:9', clock: 297 },
    65: { h: 1280, v: 720, interlaced: false, refresh: 24, ar: '64:27', clock: 59 },
    66: { h: 1280, v: 720, interlaced: false, refresh: 25, ar: '64:27', clock: 74.25 },
    67: { h: 1280, v: 720, interlaced: false, refresh: 30, ar: '64:27', clock: 74.25 },
    68: { h: 1280, v: 720, interlaced: false, refresh: 50, ar: '64:27', clock: 74.25 },
    69: { h: 1280, v: 720, interlaced: false, refresh: 60, ar: '64:27', clock: 74.25 },
    70: { h: 1280, v: 720, interlaced: false, refresh: 100, ar: '64:27', clock: 148.5 },
    71: { h: 1280, v: 720, interlaced: false, refresh: 120, ar: '64:27', clock: 148.5 },
    72: { h: 1920, v: 1080, interlaced: false, refresh: 24, ar: '64:27', clock: 74.25 },
    73: { h: 1920, v: 1080, interlaced: false, refresh: 25, ar: '64:27', clock: 74.25 },
    74: { h: 1920, v: 1080, interlaced: false, refresh: 30, ar: '64:27', clock: 74.25 },
    75: { h: 1920, v: 1080, interlaced: false, refresh: 50, ar: '64:27', clock: 148.5 },
    76: { h: 1920, v: 1080, interlaced: false, refresh: 60, ar: '64:27', clock: 148.5 },
    77: { h: 1920, v: 1080, interlaced: false, refresh: 100, ar: '64:27', clock: 297 },
    78: { h: 1920, v: 1080, interlaced: false, refresh: 120, ar: '64:27', clock: 297 },
    79: { h: 1680, v: 720, interlaced: false, refresh: 24, ar: '64:27', clock: 59.4 },
    80: { h: 1680, v: 720, interlaced: false, refresh: 25, ar: '64:27', clock: 59.4 },
    81: { h: 1680, v: 720, interlaced: false, refresh: 30, ar: '64:27', clock: 59.4 },
    82: { h: 1680, v: 720, interlaced: false, refresh: 50, ar: '64:27', clock: 82.5 },
    83: { h: 1680, v: 720, interlaced: false, refresh: 60, ar: '64:27', clock: 99 },
    84: { h: 1680, v: 720, interlaced: false, refresh: 100, ar: '64:27', clock: 165 },
    85: { h: 1680, v: 720, interlaced: false, refresh: 120, ar: '64:27', clock: 198 },
    86: { h: 2560, v: 1080, interlaced: false, refresh: 24, ar: '64:27', clock: 99 },
    87: { h: 2560, v: 1080, interlaced: false, refresh: 25, ar: '64:27', clock: 90 },
    88: { h: 2560, v: 1080, interlaced: false, refresh: 30, ar: '64:27', clock: 118.8 },
    89: { h: 2560, v: 1080, interlaced: false, refresh: 50, ar: '64:27', clock: 185.625 },
    90: { h: 2560, v: 1080, interlaced: false, refresh: 60, ar: '64:27', clock: 198 },
    91: { h: 2560, v: 1080, interlaced: false, refresh: 100, ar: '64:27', clock: 371.25 },
    92: { h: 2560, v: 1080, interlaced: false, refresh: 120, ar: '64:27', clock: 495 },
    93: { h: 3840, v: 2160, interlaced: false, refresh: 24, ar: '16:9', clock: 297 },
    94: { h: 3840, v: 2160, interlaced: false, refresh: 25, ar: '16:9', clock: 297 },
    95: { h: 3840, v: 2160, interlaced: false, refresh: 30, ar: '16:9', clock: 297 },
    96: { h: 3840, v: 2160, interlaced: false, refresh: 50, ar: '16:9', clock: 594 },
    97: { h: 3840, v: 2160, interlaced: false, refresh: 60, ar: '16:9', clock: 594 },
    98: { h: 4096, v: 2160, interlaced: false, refresh: 24, ar: '256:135', clock: 297 },
    99: { h: 4096, v: 2160, interlaced: false, refresh: 25, ar: '256:135', clock: 297 },
    100: { h: 4096, v: 2160, interlaced: false, refresh: 30, ar: '256:135', clock: 297 },
    101: { h: 4096, v: 2160, interlaced: false, refresh: 50, ar: '256:135', clock: 594 },
    102: { h: 4096, v: 2160, interlaced: false, refresh: 60, ar: '256:135', clock: 594 },
    103: { h: 3840, v: 2160, interlaced: false, refresh: 24, ar: '64:27', clock: 297 },
    104: { h: 3840, v: 2160, interlaced: false, refresh: 25, ar: '64:27', clock: 297 },
    105: { h: 3840, v: 2160, interlaced: false, refresh: 30, ar: '64:27', clock: 297 },
    106: { h: 3840, v: 2160, interlaced: false, refresh: 50, ar: '64:27', clock: 594 },
    107: { h: 3840, v: 2160, interlaced: false, refresh: 60, ar: '64:27', clock: 594 },
    108: { h: 1280, v: 720, interlaced: false, refresh: 48, ar: '16:9', clock: 90 },
    109: { h: 1280, v: 720, interlaced: false, refresh: 48, ar: '64:27', clock: 90 },
    110: { h: 1680, v: 720, interlaced: false, refresh: 48, ar: '64:27', clock: 99 },
    111: { h: 1920, v: 1080, interlaced: false, refresh: 48, ar: '16:9', clock: 148.5 },
    112: { h: 1920, v: 1080, interlaced: false, refresh: 48, ar: '64:27', clock: 148.5 },
    113: { h: 2560, v: 1080, interlaced: false, refresh: 48, ar: '64:27', clock: 198 },
    114: { h: 3840, v: 2160, interlaced: false, refresh: 48, ar: '16:9', clock: 594 },
    115: { h: 4096, v: 2160, interlaced: false, refresh: 48, ar: '256:135', clock: 594 },
    116: { h: 3840, v: 2160, interlaced: false, refresh: 48, ar: '64:27', clock: 594 },
    117: { h: 3840, v: 2160, interlaced: false, refresh: 100, ar: '16:9', clock: 1188 },
    118: { h: 3840, v: 2160, interlaced: false, refresh: 120, ar: '16:9', clock: 1188 },
    119: { h: 3840, v: 2160, interlaced: false, refresh: 100, ar: '64:27', clock: 1188 },
    120: { h: 3840, v: 2160, interlaced: false, refresh: 120, ar: '64:27', clock: 1188 },
    121: { h: 5120, v: 2160, interlaced: false, refresh: 24, ar: '64:27', clock: 396 },
    122: { h: 5120, v: 2160, interlaced: false, refresh: 25, ar: '64:27', clock: 396 },
    123: { h: 5120, v: 2160, interlaced: false, refresh: 30, ar: '64:27', clock: 396 },
    124: { h: 5120, v: 2160, interlaced: false, refresh: 48, ar: '64:27', clock: 742.5 },
    125: { h: 5120, v: 2160, interlaced: false, refresh: 50, ar: '64:27', clock: 742.5 },
    126: { h: 5120, v: 2160, interlaced: false, refresh: 60, ar: '64:27', clock: 742.5 },
    127: { h: 5120, v: 2160, interlaced: false, refresh: 100, ar: '64:27', clock: 1485 },
    193: { h: 5120, v: 2160, interlaced: false, refresh: 120, ar: '64:27', clock: 1485 },
    194: { h: 7680, v: 4320, interlaced: false, refresh: 24, ar: '16:9', clock: 1188 },
    195: { h: 7680, v: 4320, interlaced: false, refresh: 25, ar: '16:9', clock: 1188 },
    196: { h: 7680, v: 4320, interlaced: false, refresh: 30, ar: '16:9', clock: 1188 },
    197: { h: 7680, v: 4320, interlaced: false, refresh: 48, ar: '16:9', clock: 2376 },
    198: { h: 7680, v: 4320, interlaced: false, refresh: 50, ar: '16:9', clock: 2376 },
    199: { h: 7680, v: 4320, interlaced: false, refresh: 60, ar: '16:9', clock: 2376 },
    200: { h: 7680, v: 4320, interlaced: false, refresh: 100, ar: '16:9', clock: 4752 },
    201: { h: 7680, v: 4320, interlaced: false, refresh: 120, ar: '16:9', clock: 4752 },
    202: { h: 7680, v: 4320, interlaced: false, refresh: 24, ar: '64:27', clock: 1188 },
    203: { h: 7680, v: 4320, interlaced: false, refresh: 25, ar: '64:27', clock: 1188 },
    204: { h: 7680, v: 4320, interlaced: false, refresh: 30, ar: '64:27', clock: 1188 },
    205: { h: 7680, v: 4320, interlaced: false, refresh: 48, ar: '64:27', clock: 2376 },
    206: { h: 7680, v: 4320, interlaced: false, refresh: 50, ar: '64:27', clock: 2376 },
    207: { h: 7680, v: 4320, interlaced: false, refresh: 60, ar: '64:27', clock: 2376 },
    208: { h: 7680, v: 4320, interlaced: false, refresh: 100, ar: '64:27', clock: 4752 },
    209: { h: 7680, v: 4320, interlaced: false, refresh: 120, ar: '64:27', clock: 4752 },
    210: { h: 10240, v: 4320, interlaced: false, refresh: 24, ar: '64:27', clock: 1485 },
    211: { h: 10240, v: 4320, interlaced: false, refresh: 25, ar: '64:27', clock: 1485 },
    212: { h: 10240, v: 4320, interlaced: false, refresh: 30, ar: '64:27', clock: 1485 },
    213: { h: 10240, v: 4320, interlaced: false, refresh: 48, ar: '64:27', clock: 2970 },
    214: { h: 10240, v: 4320, interlaced: false, refresh: 50, ar: '64:27', clock: 2970 },
    215: { h: 10240, v: 4320, interlaced: false, refresh: 60, ar: '64:27', clock: 2970 },
    216: { h: 10240, v: 4320, interlaced: false, refresh: 100, ar: '64:27', clock: 5940 },
    217: { h: 10240, v: 4320, interlaced: false, refresh: 120, ar: '64:27', clock: 5940 },
    218: { h: 4096, v: 2160, interlaced: false, refresh: 100, ar: '256:135', clock: 1188 },
    219: { h: 4096, v: 2160, interlaced: false, refresh: 120, ar: '256:135', clock: 1188 },
}

declare type StandardTiming = {
    pixel: number;
    ar: '16:10' | '4:3' | '5:4' | '16:9';
    refresh: number;
    enabled: boolean;
}

declare type DetailTiming = {
    descriptor?: {
        tag: number;
        serialNumber?: string;
        dataString?: string;
        rangeLimit?: {
            verticalRate: { min: number; max: number };
            horizontalRate: { min: number; max: number };
            maxPixelClock: number;
            videoTiming: number;
            GTF2ndCurve?: { startBreakFrequency: number; Cx2: number; M: number; K: number; Jx2: number };
        };
        productName?: string;
    };
    timing?: {
        pixelClock: number;
        addressablePixel: { h: number; v: number };
        blankingPixel: { h: number; v: number };
        frontPorchPixel: { h: number; v: number };
        syncPulsePixel: { h: number; v: number };
        borderPixel: { h: number; v: number };
        addressableImageSize: { h: number; v: number };
        isInterlaced: boolean;
        stereoView: number;
        syncSignal: number;
        refreshRate: string;
    };
}

export function Fixed(num_str: string, length: number): string {
    let r = num_str.slice();
    if (isNaN(parseFloat(num_str))) {
        return r;
    } else {
        while (r.length < length) { r = '0' + r; }
        if (r.length > length) { r = r.slice(r.length-4, r.length); }
        return r;
    }
} 

export class EDIDElement {
    private readonly hex: string;
    private readonly raw: Array<number>;
    private readonly debug_level: number;
    isValid: boolean;
    manufacturerName: string;
    productId: string;
    serialNumber: number;
    manufacturerYear: number;
    modelYear?: number;
    manufacturerWeek?: number;
    edidVersion: string;
    videoInputDefinition: {
        analog?: {
            signalLevelStandard: string;
            videoSetup: boolean;
            seperateSync: boolean;
            compositeSync: boolean;
            syncOnGreen: boolean;
            serration: boolean;
            displayColorType: string;
        };
        digital?: {
            colorBitDepth: string;
            standardSupport: string;
            supportColorEncoding: string;
        };
    };
    AR: {
        rect?: { h: number; v: number };
        landscape?: number;
        portrait?: number;
    };
    gamma?: number;
    featureSupport: {
        standbyMode: boolean;
        suspendMode: boolean;
        activeOff: boolean;
        sRGBDefaultColorSpace: boolean;
        preferredTimingMode: boolean;
        continuousFrequency: boolean;
    };
    chromaticity: {
        red: { x: number; y: number };
        green: { x: number; y: number };
        blue: { x: number; y: number };
        white: { x: number; y: number };
    };
    establishTimingTable: string;
    stdTimingTable: Array<StandardTiming>;
    detailTimingTable: Array<DetailTiming>;
    extension: {
        blockTag: number;
        revisionVersion: number;
        descriptorOffset: number;
        supportUnderScan: boolean;
        supportBasicAudio: boolean;
        supportYCbCr444: boolean;
        supportYCbCr422: boolean;
        audio?: {
            LPCM?: {
                maxChannels: number;
                supportFrequency: Array<number>;
                support24Bit: boolean;
                support20Bit: boolean;
                support16Bit: boolean;
            };
            bitstream?: {
                maxChannels: number;
                supportFrequency: Array<number>;
                maxBitRate: number;
            };
            HBR?: {
                maxChannels: number;
                supportFrequency: Array<number>;
            };
            WMAPro?: {
                maxChannels: number;
                supportFrequency: Array<number>;
            };
        };
        video?: Array<Resolution>;
        venderSpec?: {
            HDMIForumVSDB?: {
                support340Mcsc: boolean;
                supportSCDC: boolean;
                Y420DeepColor: boolean;
                Y420ColorDepth?: number;
            };
            HDMIV14bVSDB?: {
                supportDeepColor: boolean;
                colorDepth?: number;
                support4K3G?: boolean;
                support3D?: boolean;
            };
            DolbyVisionSupport?: boolean,
            HDR10PlusSupoort?: boolean,
        };
        speakerAllocation?: {};
        VESA?: {};
        extended?: {
            supportHDRBlock?: boolean;
            HDRStaticMetadata?: {
                type: number;
                supportSDR?: boolean,
                supportHDR?: boolean,
                supportEOTF?: boolean, // Electro-Optical Transfer Function
                supportHLG?: boolean,
                supportHDR10?: boolean,
                desiredMaxLuminance?: number,
                desiredAverageLuminance?: number,
                desiredMinLuminance?: number,
            }
            supportY420Block?: boolean;
            YUV420CapabilityMap?: string;
        };
    };
    get maxResolution() {
        let max_pixel_clock = 0, max_h = 0, max_v = 0, max_resolution_s = 'Unknown';
        for (let i = 0; i < this.detailTimingTable.length; i++) {
            const timing = this.detailTimingTable[i].timing;
            if (timing && max_pixel_clock < timing.pixelClock) {
                max_pixel_clock = timing.pixelClock;
                max_h = timing.addressablePixel.h;
                max_v = timing.addressablePixel.v;
                max_resolution_s = `${max_pixel_clock}M ${max_h}x${max_v}${timing.isInterlaced?'i':'p'} ${timing.refreshRate}`;
            }
        }
        if (this.extension.video) {
            for (let i = 0; i < this.extension.video.length; i++) {
                const { clock, h, v, interlaced, refresh } = this.extension.video[i];
                if ((max_pixel_clock < clock) || 
                    (max_pixel_clock === clock && max_h < h) ||
                    (max_pixel_clock === clock && max_h === h && max_v < v)) {
                        max_pixel_clock = clock;
                        max_h = h;
                        max_v = v;
                        max_resolution_s = `${max_pixel_clock}M ${max_h}x${max_v}${interlaced?'i':'p'} ${refresh}`;
                        if (this.extension.extended && this.extension.extended.YUV420CapabilityMap && this.extension.extended.YUV420CapabilityMap.charAt(i) === '1')
                            max_resolution_s += `(YCbCr 4:2:0)`;
                        // console.log('max_resolution_s :>> ', max_resolution_s);
                    }
            }
        }
        return max_resolution_s;
    }
    get firstDetailTiming() {
        let max_pixel_clock = 0, max_h = 0, max_v = 0, resolution_s = 'Unknown';
        for (let i = 0; i < this.detailTimingTable.length; i++) {
            const timing = this.detailTimingTable[i].timing;
            if (timing && max_pixel_clock < timing.pixelClock) {
                max_pixel_clock = timing.pixelClock;
                max_h = timing.addressablePixel.h;
                max_v = timing.addressablePixel.v;
                resolution_s = `${max_pixel_clock}M ${max_h}x${max_v}${timing.isInterlaced?'i':'p'} ${timing.refreshRate}`;
                break;
            }
        }
        return resolution_s;
    }
    get timingList() {
        const list: Array<string> = [];
        let max_pixel_clock = 0, max_h = 0, max_v = 0;
        for (let i = 0; i < this.detailTimingTable.length; i++) {
            const timing = this.detailTimingTable[i].timing;
            if (timing && max_pixel_clock < timing.pixelClock) {
                max_pixel_clock = timing.pixelClock;
                max_h = timing.addressablePixel.h;
                max_v = timing.addressablePixel.v;
                list.push(`${max_pixel_clock}M ${max_h}x${max_v}${timing.isInterlaced?'i':'p'} ${timing.refreshRate}`);
            }
        }
        if (this.extension.video) {
            for (let i = 0; i < this.extension.video.length; i++) {
                const { clock, h, v, interlaced, refresh } = this.extension.video[i];
                if ((max_pixel_clock < clock) || 
                    (max_pixel_clock === clock && max_h < h) ||
                    (max_pixel_clock === clock && max_h === h && max_v < v)) {
                        max_pixel_clock = clock;
                        max_h = h;
                        max_v = v;
                        let resolution_s = `${max_pixel_clock}M ${max_h}x${max_v}${interlaced?'i':'p'} ${refresh}`;
                        if (this.extension.extended && this.extension.extended.YUV420CapabilityMap && this.extension.extended.YUV420CapabilityMap.charAt(i) === '1')
                            resolution_s += `(YCbCr 4:2:0)`;
                        list.push(resolution_s);    
                    }
            }
        }
        return list;
    }
    get descriptorProductName() {
        let r = 'Unknown';
        for (let i = 0; i < this.detailTimingTable.length; i++) {
            const { descriptor } = this.detailTimingTable[i];
            if (descriptor && descriptor.productName)
                r = descriptor.productName;
        }
        return r;
    }
    get hasBlock2() {
        return (this.raw[0x7e] > 0);
    }

    get debugLevel() { return Number(this.debug_level); }

    private debug(...msgs: Array<any>) { if (this.debugLevel >= 3) console.log("[EDID debug] ", ...msgs); }
    private warn(...msgs: Array<any>) { if (this.debugLevel >= 2) console.log("[EDID warn] ", ...msgs); }
    private info(...msgs: Array<any>) { if (this.debugLevel >= 1) console.log("[EDID info] ", ...msgs); }
    private error(...msgs: Array<any>) { console.log("[EDID error] ", ...msgs); }

    constructor(props: string, debugLevel?: number) {
        this.hex = props.slice();
        this.raw = Array(256).fill(0, 0);
        this.debug_level = (debugLevel) ? debugLevel : 0;
        this.isValid = (this.hex.length > 0);
        for (let index = 0; index < this.hex.length; index += 2) { this.raw[index / 2] = parseInt(this.hex.substr(index, 2), 16); }
        // manufacturer name
        const mftr_bitstream = this.pad(this.edid_slice(0x8, 0xA).toString(2), 16);
        this.manufacturerName = this.b2ca(mftr_bitstream.slice(1, 6)) + this.b2ca(mftr_bitstream.slice(6, 11)) + this.b2ca(mftr_bitstream.slice(11));
        // product id
        const pid_hex = this.pad(this.edid_slice(0xA, 0xC).toString(16), 4);
        this.productId = pid_hex.slice(2, 4) + pid_hex.slice(0, 2);
        // serial number
        this.serialNumber = this.raw[0xC] + (256 * this.raw[0xD]) + (256 * 256 * this.raw[0xE]) + (256 * 256 * 256 * this.raw[0xF]);
        // manufacturer year or model year
        // if 11h is 255, model year flag is true
        this.manufacturerYear = this.raw[0x11] - 10; // this value must range in 6 -> 245, means from 2006 to 2245 year
        // manufacturer week
        this[(this.manufacturerYear === 255) ? 'modelYear' : 'manufacturerWeek'] = this.raw[0x10]; // this value must range in 1 -> 54
        // EDID version
        this.edidVersion = this.raw[0x12] + '.' + this.raw[0x13]; // readOnly
        // video input definition
        const vin_def_bitstream = this.pad(this.raw[0x14].toString(2), 8);
        const feature_sup_bitstream = this.pad(this.raw[0x18].toString(2), 8);
        this.videoInputDefinition = {};
        if (vin_def_bitstream.charAt(0) === '0') {  // analog
            this.videoInputDefinition.analog = {
                signalLevelStandard: vin_def_bitstream.slice(1, 3),
                videoSetup: (vin_def_bitstream.slice(3, 4) === '1'), // false : 'Blank level = Black level', true : 'Blank-to-Black setup or predestal'
                seperateSync: (vin_def_bitstream.slice(4, 5) === '1'), // false : not supported, true : supported
                compositeSync: (vin_def_bitstream.slice(5, 6) === '1'), // false : not supported, true : supported
                syncOnGreen: (vin_def_bitstream.slice(6, 7) === '1'), // false : not supported, true : supported
                serration: (vin_def_bitstream.slice(7, 8) === '1'), // false : not supported, true : supported
                displayColorType: feature_sup_bitstream.slice(3, 5),
            };
        } else { // digital
            this.videoInputDefinition.digital = {
                colorBitDepth: vin_def_bitstream.slice(1, 4),
                standardSupport: vin_def_bitstream.slice(5, 8),
                supportColorEncoding: feature_sup_bitstream.slice(3, 5),
            }
        }
        const h_size = this.raw[0x15], v_size = this.raw[0x16];
        this.AR = {};
        if (h_size && v_size) { // both horizontal & vertical size not zero
            this.AR.rect = { h: h_size, v: v_size }; // in cm
        } else if (h_size && !v_size) { // aspect ratio (Landscape)
            this.AR.landscape = (h_size + 99) / 100;
        } else if (!h_size && v_size) { // aspect ratio (Portrait)
            this.AR.portrait = 100 / (h_size + 99);
        }
        // display transfer characteristics
        this.gamma = (this.raw[0x17] !== 0xFF) ? ((this.raw[0x17] + 100) / 100) : undefined;
        this.featureSupport = {
            standbyMode: (feature_sup_bitstream.slice(0, 1) === '1'), // false :  not supported, true : supported
            suspendMode: (feature_sup_bitstream.slice(1, 2) === '1'), // false :  not supported, true : supported
            activeOff: (feature_sup_bitstream.slice(2, 3) === '1'), // false :  not supported, true : supported
            sRGBDefaultColorSpace: (feature_sup_bitstream.slice(5, 6) === '1'),
            preferredTimingMode: (feature_sup_bitstream.slice(6, 7) === '1'), // includes (not not) the native pixel format and preferred refresh rate of the display device
            continuousFrequency: (feature_sup_bitstream.slice(7, 8) === '1'), // for edid 1.3, indicates support for GTF
        }
        // display x,y chromaticity
        const rgbw_overflow_bitstream = this.pad(this.edid_slice(0x19, 0x1B).toString(2), 16);
        const red_x_bitstream = this.pad(this.raw[0x1B].toString(2), 8) + rgbw_overflow_bitstream.slice(0, 2);
        const red_y_bitstream = this.pad(this.raw[0x1C].toString(2), 8) + rgbw_overflow_bitstream.slice(2, 4);
        const green_x_bitstream = this.pad(this.raw[0x1D].toString(2), 8) + rgbw_overflow_bitstream.slice(4, 6);
        const green_y_bitstream = this.pad(this.raw[0x1E].toString(2), 8) + rgbw_overflow_bitstream.slice(6, 8);
        const blue_x_bitstream = this.pad(this.raw[0x1F].toString(2), 8) + rgbw_overflow_bitstream.slice(8, 10);
        const blue_y_bitstream = this.pad(this.raw[0x20].toString(2), 8) + rgbw_overflow_bitstream.slice(10, 12);
        const white_x_bitstream = this.pad(this.raw[0x21].toString(2), 8) + rgbw_overflow_bitstream.slice(12, 14);
        const white_y_bitstream = this.pad(this.raw[0x22].toString(2), 8) + rgbw_overflow_bitstream.slice(14, 16);
        this.chromaticity = {
            red: { x: this.parse_chromatic_float(red_x_bitstream), y: this.parse_chromatic_float(red_y_bitstream) },
            green: { x: this.parse_chromatic_float(green_x_bitstream), y: this.parse_chromatic_float(green_y_bitstream) },
            blue: { x: this.parse_chromatic_float(blue_x_bitstream), y: this.parse_chromatic_float(blue_y_bitstream) },
            white: { x: this.parse_chromatic_float(white_x_bitstream), y: this.parse_chromatic_float(white_y_bitstream) },
        }
        // established timing table
        const establish_timing_bitstream = this.pad(this.edid_slice(0x23, 0x26).toString(2), 24);
        this.establishTimingTable = establish_timing_bitstream.slice(0, 17);
        // standart timing 1-8
        const ar_map: { [s: string]: '16:10' | '4:3' | '5:4' | '16:9' } = { '00': '16:10', '01': '4:3', '10': '5:4', '11': '16:9' }; // aspect ratio mapping
        this.stdTimingTable = [0,1,2,3,4,5,6,7].map((i): StandardTiming => {
            const h_addressable_pixel_addr = 0x26 + (i * 2);
            const timing_def_bitstream = this.pad(this.raw[h_addressable_pixel_addr + 1].toString(2), 8);
            const pixel = 8 * (this.raw[h_addressable_pixel_addr] + 31); // range in 256~2288
            const ar = ar_map[timing_def_bitstream.slice(0, 2)];
            const refresh = 60 + parseInt(timing_def_bitstream.slice(2, 8), 2);
            const enabled = (pixel === 256 && refresh === 61) ? false : true;
            return { pixel, ar, refresh, enabled };
        });
        let block_offset;
        this.detailTimingTable = [0,1,2,3].map((i): DetailTiming => {
            block_offset = 0x36 + 18 * i;
            if (this.edid_slice(block_offset, block_offset + 2) === 0) {
                const tag = this.raw[block_offset + 3];
                return {
                    descriptor: {
                        tag,
                        serialNumber: (tag === 255) ? this.h2a(this.edid_search(block_offset + 5, 13)) : undefined, // max len 13
                        dataString: (tag === 254) ? this.h2a(this.edid_search(block_offset + 5, 13)) : undefined, // max len 13
                        rangeLimit: (tag !== 253) ? undefined : {
                            verticalRate: { 
                                min: (((this.raw[block_offset + 4] & 0x01) === 1) ? 255 : 0) + this.raw[block_offset + 5],
                                max: (((this.raw[block_offset + 4] & 0x02) === 2) ? 255 : 0) + this.raw[block_offset + 6]
                            },
                            horizontalRate: {
                                min: (((this.raw[block_offset + 4] & 0x04) === 4) ? 255 : 0) + this.raw[block_offset + 7], 
                                max: (((this.raw[block_offset + 4] & 0x08) === 8) ? 255 : 0) + this.raw[block_offset + 8],
                            },
                            maxPixelClock: 10 * this.raw[block_offset + 9], // MHz,
                            videoTiming: this.raw[block_offset + 10], // range in 0~2, and 4
                            GTF2ndCurve: (this.raw[block_offset + 10] !== 2) ? undefined : {
                                startBreakFrequency: 2 * this.raw[block_offset + 12], // kHz
                                Cx2: this.raw[block_offset + 13], // C in 0~127,
                                M: 256 * this.raw[block_offset + 15] + this.raw[block_offset + 14], // M in 0~65535,
                                K: this.raw[block_offset + 16], // K in 0~255,
                                Jx2: this.raw[block_offset + 17], // J in 0~127,
                            },
                        },
                        productName: (tag === 252) ? this.h2a(this.edid_search(block_offset + 5, 13)) : undefined,
                    }
                }
            } else {
                const pixelClock = (this.raw[block_offset + 1] * 256 + this.raw[block_offset + 0]) / 100; // MHz
                const hAddressablePixel = 256 * (this.raw[block_offset + 4] >> 4) + this.raw[block_offset + 2];
                const hBlankingPixel  = 256 * (this.raw[block_offset + 4] & 0x0F) + this.raw[block_offset + 3];
                const vAddressableLine = 256 * (this.raw[block_offset + 7] >> 4) + this.raw[block_offset + 5];
                const vBlankingLine = 256 * (this.raw[block_offset + 7] & 0x0F) + this.raw[block_offset + 6];
                const sum_pixel = hAddressablePixel + hBlankingPixel;
                const sum_line = vAddressableLine + vBlankingLine;
                return {
                    timing: {
                        pixelClock,
                        addressablePixel: { h: hAddressablePixel, v: vAddressableLine, },
                        blankingPixel: { h: hBlankingPixel, v: vBlankingLine, },
                        frontPorchPixel: {
                            h: 256 * (this.raw[block_offset + 11] >> 6) + this.raw[block_offset + 8],
                            v: 256 * ((this.raw[block_offset + 11] & 0x0C) >> 2) + (this.raw[block_offset + 10] >> 4),
                        },
                        syncPulsePixel: {
                            h: 256 * ((this.raw[block_offset + 11] & 0x30) >> 4) + this.raw[block_offset + 9],
                            v: 256 * (this.raw[block_offset + 11] & 0x03) + (this.raw[block_offset + 10] & 0x0F),
                        },
                        addressableImageSize: {
                            h: 256 * (this.raw[block_offset + 14] >> 4) + this.raw[block_offset + 12], // mm
                            v: 256 * (this.raw[block_offset + 14] & 0x0F) + this.raw[block_offset + 13], // mm
                        },
                        borderPixel: {
                            h: this.raw[block_offset + 15],
                            v: this.raw[block_offset + 16],
                        },
                        isInterlaced: (((this.raw[block_offset + 17] & 0x80) >> 7) === 1),
                        stereoView: ((this.raw[block_offset + 17] & 0x60) >> 4) + (this.raw[block_offset + 17] & 0x01),
                        syncSignal: (this.raw[block_offset + 17] & 0x1E) >> 1,
                        refreshRate: ((pixelClock * 1000000) / (sum_pixel * sum_line)).toFixed(2)
                    }
                }
            }
        });
        const ext_product_sup_bitstream = this.pad(this.raw[0x83].toString(2), 8);
        this.extension = {
            blockTag: this.raw[0x80],
            revisionVersion: this.raw[0x81],
            descriptorOffset: this.raw[0x82],
            supportUnderScan: (ext_product_sup_bitstream.slice(0, 1) === '1'),
            supportBasicAudio: (ext_product_sup_bitstream.slice(1, 2) === '1'),
            supportYCbCr444: (ext_product_sup_bitstream.slice(2, 3) === '1'),
            supportYCbCr422: (ext_product_sup_bitstream.slice(3, 4) === '1'),
        }
        if (this.extension.descriptorOffset >= 4) {
            let db_offset, db_idx; // data block offset
            let dtd_offset; // detail timing descriptor offset
            let db_header, db_tag, db_len;
            let i = 0;
            for (db_offset = 4; db_offset < this.extension.descriptorOffset && db_offset < 0x7f; db_offset += db_len) {
                db_idx = 0x80 + db_offset;
                db_header = this.pad(this.raw[db_idx].toString(2), 8);
                // this.debug(`extension header(0x${(db_idx).toString(16)}) :>> `, db_header, `=0x${this.raw[db_idx].toString(16)}`);
                db_tag = parseInt(db_header.slice(0, 3), 2);
                // this.debug('header tag :>> ', db_header.slice(0, 3), `=${db_tag}`);
                db_len = parseInt(db_header.slice(3), 2) + 1;
                // this.debug('block size :>> ', db_header.slice(3), `+1=${db_len}`);
                // console.log('db_idx :', db_idx.toString(16));
                // console.log('db_header :', db_header);
                // console.log('db_tag :', db_tag);
                // console.log('db_len :', db_len);
                if (db_tag === 0) { // reserved
                } else if (db_tag === 1) { // audio data block
                    // this.extension.audio = (this.extension.audio) ? this.extension.audio : { maxChannel: 0, LPCM: false, bitstream: false, HBR: false };
                    this.extension.audio = (this.extension.audio) ? this.extension.audio : {};
                    for (i = 0; i < db_len - 1; i += 3) {
                        const afc = (this.raw[db_idx + i + 1] & 0x78) >> 3; // Audio Format Code
                        const max_channel_n = (this.raw[db_idx + i + 1] & 0x07) + 1; // Max Number of Channels
                        this.debug('check bytes :>> ', '0x' + (db_idx + i + 1).toString(16), this.pad(this.raw[db_idx + i + 1].toString(2), 8));
                        this.debug('max_channel_n :>> ', max_channel_n);
                        // this.extension.audio.maxChannel = (max_channel_n > this.extension.audio.maxChannel) ? max_channel_n : this.extension.audio.maxChannel;
                        if (afc === 1) {
                            const support_f_map = this.pad(this.raw[db_idx + i + 2].toString(2), 8).slice(1).split('').map(bit => (bit === '1'));
                            const support_b_map = this.pad(this.raw[db_idx + i + 3].toString(2), 8).slice(5).split('').map(bit => (bit === '1'));
                            this.extension.audio.LPCM = {
                                maxChannels: max_channel_n,
                                supportFrequency: [192, 176.4, 96, 88.2, 48, 44.1, 32].filter((_, i) => (support_f_map[i] === true)),
                                support16Bit: support_b_map[2],
                                support20Bit: support_b_map[1],
                                support24Bit: support_b_map[0],
                            }
                        }
                        if (afc >= 2 && afc <= 8) {
                            const support_f_map = this.pad(this.raw[db_idx + i + 2].toString(2), 8).slice(1).split('').map(bit => (bit === '1'));
                            const max_bitrate = this.raw[db_idx + i + 3] * 8; // divided by 8kHz
                            this.extension.audio.bitstream = {
                                maxChannels: max_channel_n,
                                supportFrequency: [192, 176.4, 96, 88.2, 48, 44.1, 32].filter((_, i) => (support_f_map[i] === true)),
                                maxBitRate: max_bitrate,
                            }
                        }
                        if (afc >= 9 && afc <= 13) {
                            const support_f_map = this.pad(this.raw[db_idx + i + 2].toString(2), 8).slice(1).split('').map(bit => (bit === '1'));
                            this.extension.audio.HBR = {
                                maxChannels: max_channel_n,
                                supportFrequency: [192, 176.4, 96, 88.2, 48, 44.1, 32].filter((_, i) => (support_f_map[i] === true)),
                            }
                        }
                    }
                } else if (db_tag === 2) { // video data block
                    if (!this.extension.video) this.extension.video = [];
                    for (let i = 0;i < db_len - 1; i ++) {
                        const svd = this.raw[db_idx + i + 1];
                        let data;
                        if (svd >= 1 && svd <= 64) {
                            data = Object.assign({}, VIC_TABLE[svd]);
                            data.native = false;
                            this.extension.video.push(data);
                        } else if (svd >= 65 && svd <= 127) {
                            data = Object.assign({}, VIC_TABLE[svd]);
                            this.extension.video.push(data);
                        } else if (svd >= 129 && svd <= 192) {
                            data = Object.assign({}, VIC_TABLE[svd & 0x7F]);
                            data.native = true;
                            this.extension.video.push(data);
                        } else if (svd >= 193 && svd <= 253) {
                            data = Object.assign({}, VIC_TABLE[svd]);
                            this.extension.video.push(data);
                        } else {
                            // reserved
                        }
                    }
                } else if (db_tag === 3) { // vender-specific data block
                    // compare IEEE OUI
                    if (!this.extension.venderSpec) this.extension.venderSpec = {};
                    const ieeeoui_3rd = this.raw[db_idx + 1];
                    const ieeeoui_2nd = this.raw[db_idx + 2];
                    const ieeeoui_1st = this.raw[db_idx + 3];
                    if ( ieeeoui_3rd === 0xD8 && ieeeoui_2nd === 0x5D && ieeeoui_1st === 0xC4 ) { // HDMI forum VSDB
                        if (!this.extension.venderSpec.HDMIForumVSDB) this.extension.venderSpec.HDMIForumVSDB = { support340Mcsc: false, supportSCDC: false, Y420DeepColor: false,  };
                        this.extension.venderSpec.HDMIForumVSDB.support340Mcsc = (((this.raw[db_idx + 6] & 0x08) >> 3) === 1);
                        this.extension.venderSpec.HDMIForumVSDB.supportSCDC = (((this.raw[db_idx + 6] & 0x80) >> 7) === 1);
                        this.extension.venderSpec.HDMIForumVSDB.Y420DeepColor = ((this.raw[db_idx + 7] & 0x07) !== 0);
                        if ((this.raw[db_idx + 7] & 0x01) !== 0) this.extension.venderSpec.HDMIForumVSDB.Y420ColorDepth = 10;
                        if ((this.raw[db_idx + 7] & 0x02) !== 0) this.extension.venderSpec.HDMIForumVSDB.Y420ColorDepth = 12;
                        if ((this.raw[db_idx + 7] & 0x04) !== 0) this.extension.venderSpec.HDMIForumVSDB.Y420ColorDepth = 16;
                    } else if ( ieeeoui_3rd === 0x03 && ieeeoui_2nd === 0x0C && ieeeoui_1st === 0x00 ) { // HDMI v1.4b VSDB
                        if (!this.extension.venderSpec.HDMIV14bVSDB) this.extension.venderSpec.HDMIV14bVSDB = { supportDeepColor: false, support3D: false, support4K3G: false };
                        // this.extension_Hv14bVSDB_Support = true;
                        if (db_len - 1 >= 6) {
                            if ((( this.raw[db_idx + 6] & 0x70 ) >> 4) !== 0) this.extension.venderSpec.HDMIV14bVSDB.supportDeepColor = true;
                            if ((( this.raw[db_idx + 6] & 0x10 ) >> 4) !== 0) this.extension.venderSpec.HDMIV14bVSDB.colorDepth = 10;
                            if ((( this.raw[db_idx + 6] & 0x20 ) >> 4) !== 0) this.extension.venderSpec.HDMIV14bVSDB.colorDepth = 12;
                            if ((( this.raw[db_idx + 6] & 0x40 ) >> 4) !== 0) this.extension.venderSpec.HDMIV14bVSDB.colorDepth = 16;
                        }
                        if (db_len - 1 >= 7) {
                            this.extension.venderSpec.HDMIV14bVSDB.support4K3G = ( this.raw[db_idx + 7] >= 60 );
                        }
                        if (db_len - 1 >= 13) {
                            this.extension.venderSpec.HDMIV14bVSDB.support3D = ((( this.raw[db_idx + 13] & 0x80 ) >> 7) === 1);
                        }
                    }
                } else if (db_tag === 4) { // speaker allocation data block
                } else if (db_tag === 5) { // VESA display transfer characteristic data block
                } else if (db_tag === 6) { // reserved
                } else if (db_tag === 7) { // use extended tag
                    if (!this.extension.extended) this.extension.extended = {};
                    const ext_tag = this.raw[db_idx + 1];
                    if (ext_tag === 1) { // vender-specific video data block
                        // compare IEEE OUI
                        const ieeeoui_3rd = this.raw[db_idx + 2];
                        const ieeeoui_2nd = this.raw[db_idx + 3];
                        const ieeeoui_1st = this.raw[db_idx + 4];
                        if (!this.extension.venderSpec) this.extension.venderSpec = {};
                        if ( ieeeoui_3rd === 0x46 && ieeeoui_2nd === 0xD0 && ieeeoui_1st === 0x00 ) {
                            this.extension.venderSpec.DolbyVisionSupport = true;
                        } else if ( ieeeoui_3rd === 0x8B && ieeeoui_2nd === 0x84 && ieeeoui_1st === 0x90 ) {
                            this.extension.venderSpec.HDR10PlusSupoort = true;
                        }
                    } else if (ext_tag >= 8 && ext_tag <= 12) { // reserved for video-related blocks
                    } else if (ext_tag >= 21 && ext_tag <= 31) { // reserved for audio-related blocks
                    } else if (ext_tag >= 33) { // reserved
                    } else if (ext_tag >=6 && ext_tag <= 7) {
                        this.extension.extended.supportHDRBlock = true;
                        if (ext_tag === 6) {
                            this.extension.extended.HDRStaticMetadata = { type: (this.raw[db_idx + 3] & 0x01) };
                            this.extension.extended.HDRStaticMetadata.supportSDR = ((this.raw[db_idx + 2] & 0x01) !== 0); 
                            this.extension.extended.HDRStaticMetadata.supportHDR = ((this.raw[db_idx + 2] & 0x02) !== 0); 
                            this.extension.extended.HDRStaticMetadata.supportHDR10 = ((this.raw[db_idx + 2] & 0x04) !== 0); 
                            this.extension.extended.HDRStaticMetadata.supportHLG = ((this.raw[db_idx + 2] & 0x08) !== 0); 
                            if (this.extension.extended.HDRStaticMetadata.type === 1) { // support for Static Metadata Type 1
                                if (db_len >= 4) { // max luminance present
                                    this.extension.extended.HDRStaticMetadata.desiredMaxLuminance = 50 * Math.pow(2, (this.raw[db_idx + 4] / 32));
                                    if (db_len >= 6) // min luminance present
                                        this.extension.extended.HDRStaticMetadata.desiredMinLuminance = this.extension.extended.HDRStaticMetadata.desiredMaxLuminance * (Math.pow((this.raw[db_idx + 6] / 255), 2) / 100);
                                }
                                if (db_len >= 5) // average luminance present
                                    this.extension.extended.HDRStaticMetadata.desiredAverageLuminance = 50 * Math.pow(2, (this.raw[db_idx + 5] / 32));
                            }
                        } else if (ext_tag === 7) {

                        }
                    } else if (ext_tag >= 14 && ext_tag <= 15) {
                        this.extension.extended.supportY420Block = true;
                        if (ext_tag === 15) {
                            this.extension.extended.YUV420CapabilityMap = this.edid_slice(db_idx + 2, db_idx + db_len + 1).toString(2);
                        }
                    }
                }
            }
            // start detail timing descriptor
            for (dtd_offset = this.extension.descriptorOffset; dtd_offset < 0x7F; dtd_offset += 18) {
                block_offset = 0x80 + dtd_offset;
                i = 4 + Math.floor(dtd_offset / 18);
                if (this.edid_slice(block_offset, block_offset + 2) !== 0) {
                    const pixelClock = (this.raw[block_offset + 1] * 256 + this.raw[block_offset + 0]) / 100; // MHz
                    const hAddressablePixel = 256 * (this.raw[block_offset + 4] >> 4) + this.raw[block_offset + 2];
                    const hBlankingPixel  = 256 * (this.raw[block_offset + 4] & 0x0F) + this.raw[block_offset + 3];
                    const vAddressableLine = 256 * (this.raw[block_offset + 7] >> 4) + this.raw[block_offset + 5];
                    const vBlankingLine = 256 * (this.raw[block_offset + 7] & 0x0F) + this.raw[block_offset + 6];
                    const sum_pixel = hAddressablePixel + hBlankingPixel;
                    const sum_line = vAddressableLine + vBlankingLine;
                    this.detailTimingTable.push({
                        timing: {
                            pixelClock,
                            addressablePixel: { h: hAddressablePixel, v: vAddressableLine, },
                            blankingPixel: { h: hBlankingPixel, v: vBlankingLine, },
                            frontPorchPixel: {
                                h: 256 * (this.raw[block_offset + 11] >> 6) + this.raw[block_offset + 8],
                                v: 256 * ((this.raw[block_offset + 11] & 0x0C) >> 2) + (this.raw[block_offset + 10] >> 4),
                            },
                            syncPulsePixel: {
                                h: 256 * ((this.raw[block_offset + 11] & 0x30) >> 4) + this.raw[block_offset + 9],
                                v: 256 * (this.raw[block_offset + 11] & 0x03) + (this.raw[block_offset + 10] & 0x0F),
                            },
                            addressableImageSize: {
                                h: 256 * (this.raw[block_offset + 14] >> 4) + this.raw[block_offset + 12], // mm
                                v: 256 * (this.raw[block_offset + 14] & 0x0F) + this.raw[block_offset + 13], // mm
                            },
                            borderPixel: {
                                h: this.raw[block_offset + 15],
                                v: this.raw[block_offset + 16],
                            },
                            isInterlaced: (((this.raw[block_offset + 17] & 0x80) >> 7) === 1),
                            stereoView: ((this.raw[block_offset + 17] & 0x60) >> 4) + (this.raw[block_offset + 17] & 0x01),
                            syncSignal: (this.raw[block_offset + 17] & 0x1E) >> 1,
                            refreshRate: ((pixelClock * 1000000) / (sum_pixel * sum_line)).toFixed(2)
                        }
                    })
                } else {
                    break;
                }
            }
        } else { // both extension and extra detail timing not exist
        }
    }
    private parse_chromatic_float(bitstream: string) {
        let sum = 0;
        for (let i = 0; i < bitstream.length; i++) {
            if (bitstream[i] === '1') {
                sum += Math.pow(0.5, i + 1);
            }
        }
        return (Math.round(sum * 1000)) / 1000;
    }
    private b2ca(bitstream: string) { /* bit string to compress ascii code */
        return String.fromCharCode(parseInt(bitstream, 2) + 64);
    }
    private h2a(hexstring: string) { /* hex string to alphanumeric characters */
        let buf = '';
        for (let i = 0; i < hexstring.length; i += 2) {
            buf += String.fromCharCode(parseInt(hexstring.slice(i, i + 2), 16));
        }
        return buf;
    }
    private pad(str: string, size: number) { /* add 0 to satisfy string length */
        let s = str.slice();
        while (s.length < size) s = '0' + s;
        return s
    }
    private edid_search(hexaddr: number, len: number) { /* search hex string in edid hex with address */
        return this.hex.slice(hexaddr * 2, (hexaddr + len) * 2);
    }
    private edid_slice(start: number, end: number) {
        const list = this.raw.slice(start, end);
        let buf = 0;
        for (let i = 0; i < list.length; i++) {
            buf = buf * 256;
            buf = buf + list[i];
        }
        return buf;
    }
}

export class HDBTAnalyze {
    HeadID: string
    TypeName: string
    ChipName: string
    FWVersion: string
    CableLength: string
    TMDSClock: string
    Status: string
    ErrorStatus: string
    MaxError: { A: number; B: number; C: number; D: number; Threshold: number }
    // TotalBER: string
    // TotalBERThreshold: string
    // RetransRate: string
    // RetransRateThreshold: string
    // OpModeName: string
    // CheckSum: string
    TX?: { MAE: { A: number; B: number; C: number; D: number; Threshold: number } }
    RX?: { MSE: { A: number; B: number; C: number; D: number; Threshold: number } }

    constructor(props: string) {
        const hbdt_str = Fixed(props,192);
        this.HeadID = hbdt_str.slice(0,4);
        this.TypeName = hbdt_str.slice(4,8);
        this.ChipName = hbdt_str.slice(8,20);
        this.FWVersion = hbdt_str.slice(20,36);
        this.CableLength = hbdt_str.slice(36,40);
        this.TMDSClock = hbdt_str.slice(40,48);
        this.Status = hbdt_str.slice(48,52);
        this.ErrorStatus = hbdt_str.slice(52,56);
        if (this.TypeName === 'TX') {
            this.TX = { MAE: {
                A: parseInt(hbdt_str.slice(56,64)),
                B: parseInt(hbdt_str.slice(64,72)),
                C: parseInt(hbdt_str.slice(72,80)),
                D: parseInt(hbdt_str.slice(80,88)),
                Threshold: parseInt(hbdt_str.slice(88,96)),
            } }
        } else {
            this.RX = { MSE: {
                A: parseInt(hbdt_str.slice(56,64)),
                B: parseInt(hbdt_str.slice(64,72)),
                C: parseInt(hbdt_str.slice(72,80)),
                D: parseInt(hbdt_str.slice(80,88)),
                Threshold: parseInt(hbdt_str.slice(88,96)),
            } }
        }
        this.MaxError = {
            A: parseInt(hbdt_str.slice(96,104)),
            B: parseInt(hbdt_str.slice()),
            C: parseInt(hbdt_str.slice()),
            D: parseInt(hbdt_str.slice()),
            Threshold: parseInt(hbdt_str.slice(88,96)),
        };
    }
}

// export const CsxImageList = stringLitArray(['jpg','jpeg']);
// export type CsxImageType = (typeof CsxImageList)[number];
// export const isCsxImageType = (x: any): x is CsxImageType => CsxImageList.includes(x);

export type CsxFileGrabberFilter = {
    allowContentType?:('audio/aac'|'application/x-abiword'|'application/x-freearc'|'video/x-msvideo'|'application/vnd.amazon.ebook'
        |'application/octet-stream'|'image/bmp'|'application/x-bzip'|'application/x-bzip2'|'application/x-csh'|'text/css'|'text/csv'
        |'application/msword'|'application/vnd.openxmlformats-officedocument.wordprocessingml.document'|'application/vnd.ms-fontobject'
        |'application/epub+zip'|'application/gzip'|'image/gif'|'text/html'|'image/vnd.microsoft.icon'|'text/calendar'
        |'application/java-archive'|'image/jpeg'|'text/javascript'|'application/json'|'application/ld+json'|'audio/midi audio/x-midi'
        |'text/javascript'|'audio/mpeg'|'video/mpeg'|'application/vnd.apple.installer+xml'|'application/vnd.oasis.opendocument.presentation'
        |'application/vnd.oasis.opendocument.spreadsheet'|'application/vnd.oasis.opendocument.text'|'audio/ogg'|'video/ogg'|'application/ogg'
        |'audio/opus'|'font/otf'|'image/png'|'application/pdf'|'application/x-httpd-php'|'application/vnd.ms-powerpoint'
        |'application/vnd.openxmlformats-officedocument.presentationml.presentation'|'application/vnd.rar'|'application/rtf'
        |'application/x-sh'|'image/svg+xml'|'application/x-shockwave-flash'|'application/x-tar'|'image/tiff'|'video/mp2t'|'font/ttf'
        |'text/plain'|'application/vnd.visio'|'audio/wav'|'audio/webm'|'video/webm'|'image/webp'|'font/woff'|'font/woff2'
        |'application/xhtml+xml'|'application/vnd.ms-excel'|'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
        |'application/xml'|'text/xml'|'application/vnd.mozilla.xul+xml'|'application/zip'|'audio/video'|'video/3gpp'|'audio/3gpp'
        |'audio/video container'|'video/3gpp2'|'audio/3gpp2'|'application/x-7z-compressed')[];
}

function concatUint8Arrays(a: Uint8Array, b: Uint8Array) {
    let c = new Uint8Array(a.length + b.length);
    c.set(a, 0);
    c.set(b, a.length);
    return c;
}

export class CsxFileGrabber {
    private _url: string;
    private _buffer: Uint8Array;

    constructor(url: string) {
        this._buffer = new Uint8Array();
        this._url = url.slice();
    }
    get base64() {
        const wa: Array<number> = [];
        for (let i = 0; i < this._buffer.length; i++) {
            wa[(i/4) | 0] |= this._buffer[i] << (24 - 8 * i);
        }
        const t_wa = CryptoJS.lib.WordArray.create(wa);
        const b64 = CryptoJS.enc.Base64.stringify(t_wa);
        return b64;
    }
    get text() { return new TextDecoder().decode(this._buffer); }
    load = (filter?: CsxFileGrabberFilter): Promise<any> => {
        return new Promise((resolve, reject) => {
            window.fetch(this._url).then(response => {
                const rb = response.body;
                const content_type = response.headers.get('Content-Type');

                if (filter && filter.allowContentType) {
                    const allow_type_set: Set<string> = new Set(filter.allowContentType);
                    if (!content_type) {
                        reject({ message: `Content-Type unknown. Expect: ${filter.allowContentType}.` });
                        return null;
                    }
                    if (!allow_type_set.has(content_type)) {
                        reject({ message: `Type incorrect. Expect: ${filter.allowContentType}, Get: ${content_type}` });
                        return null;
                    }
                }
                if (rb) {
                    const reader = rb.getReader();
                    const processImage = (args: { done: boolean, value?: Uint8Array }): Promise<any> | undefined => {
                        const { done, value } = args;
                        if (done) {
                            resolve(null);
                            return;
                        }
                        if (value) {
                            this._buffer = concatUint8Arrays(this._buffer, value);
                        }
                        return reader.read().then(processImage);
                    }

                    this._buffer = new Uint8Array(0);
                    reader.read().then(processImage).catch(e => {
                        console.log('CsxImageGrabber load error :>> ', e);
                        reject(e);
                    });
                }
            }).catch(e => {
                console.log('CsxImageGrabber fetch error :>> ', e);
                reject(e);
            });
        });
    }
}

export function shellstring(str: string, style: string) {
    return `<span style="${style}">${str}</span>`
}

export declare type CYP_STD_CMD_TYPE = (typeof cypstd_cmd_type)[number]
export declare type CSX_STD_STA = { [s in CYP_STD_CMD_TYPE]?: any };
export const isCypStdCmdType = (x: any): x is CYP_STD_CMD_TYPE => cypstd_cmd_type.includes(x);

export const CMD_GET_TO_SET: { [s in CYP_STD_CMD_TYPE]?: CYP_STD_CMD_TYPE } = {
    A39: 'A38', A42: 'A41', A44: 'A43', A46: 'A45', A70: 'A69', A72: 'A71', A74: 'A73', A113: 'A112', A115: 'A114', A117: 'A116', A151: 'A150', 
    D4: 'D3', D29: 'D28', D31: 'D30', D33: 'D32', D35: 'D34', D37: 'D36', D39: 'D38', D41: 'D40', D46: 'D45', D48: 'D47', D50: 'D49',
    J2: 'J1', I2: 'I1', I5: 'I4', I7: 'I6',
    M4: 'M3', M6: 'M5', M8: 'M7', M11: 'M10', M17: 'M16', M29: 'M28', M31: 'M30', M33: 'M32', M35: 'M34', M37: 'M36', M47: 'M46', M49: 'M48', M51: 'M50', M54: 'M53', M56: 'M55', M58: 'M57', M75: 'M74', M77: 'M76', M103: 'M102', M108: 'M107', M110: 'M109',
}

export const Asc2Hex = (str: string) => {
    const arr = [];
    for (let n = 0; n < str.length; n++) {
        const hex = Number(str.charCodeAt(n)).toString(16);
        arr.push((hex.length > 1) ? hex : '0' + hex);        
    }
    return arr.join('');
}   

export const Hex2Asc = (hex: string) => {
    let str = '';
    for (let i = 0; i < hex.length; i += 2) str += String.fromCharCode(parseInt(hex.substr(i, 2), 16));
    return str;
}

// export const toAppLangType = (tar: string): APP_LANG_TYPE => {
//     for (const type in APP_LANG_TYPE) {
//         if (tar === APP_LANG_TYPE[type as keyof typeof APP_LANG_TYPE])
//             return tar;
//     }
//     return APP_LANG_TYPE.ENGLISH;
// }

// export const AppLanguageList = () => {
//     const r: Array<APP_LANG_TYPE> = [];
//     r.push(APP_LANG_TYPE.ENGLISH);
//     r.push(APP_LANG_TYPE.CHINESE);
//     // r.push(APP_LANG_TYPE.JAPANESE);
//     return r;
// }

export function nestedClone<T>(tar: T): T {
    let cobj: any = {};
    if (Array.isArray(tar)) {
        cobj = tar.map((o: any) => nestedClone(o));
    } else {
        for (const attr in tar) {
            const elem = tar[attr];
            cobj[attr] = (typeof elem === 'object') ? nestedClone(elem) : elem;
        }
    }
    return cobj;
}

export function nestedAssign(tar: any): any {
    // let copy = { new(): T };
    if (typeof tar === 'string') {
        return tar.slice();
    } else if (typeof tar === 'boolean') {
        return Boolean(tar);
    } else if (typeof tar === 'number') {
        return Number(tar);
    } else if (Array.isArray(tar)) {
        return tar.map(o => nestedAssign(o));
    } else if (typeof tar === 'object') {
        const ret: any = {};
        for (const key in tar) { if (Object.prototype.hasOwnProperty.call(tar, key)) ret[key] = nestedAssign(tar[key]); }
        return ret;
    }
    return null;
}

export const nestedGet = (tar: any, keypath: string): any => {
    const keys_walk = keypath.split('.');
    if (typeof tar === 'undefined' || tar === null) return;
    if (keys_walk.length > 1) {
        return (Object.prototype.hasOwnProperty.call(tar, keys_walk[0])) ? nestedGet(tar[keys_walk[0]], keys_walk.slice(1).join('.')) : undefined;
    } else {
        return tar[keypath];
    }
}

export const nestedSet = (tar: any, keypath: string, value: any) => {
    const keys_walk = keypath.split('.');
    if (typeof tar === 'undefined' || tar === null) return;
    if (keys_walk.length > 1) {
        if (!Object.prototype.hasOwnProperty.call(tar, keys_walk[0])) 
            tar[keys_walk[0]] = {};
        nestedSet(tar[keys_walk[0]], keys_walk.slice(1).join('.'), value);
    } else {
        tar[keypath] = value;
    }
}

export const nestedDelete = (tar: any, keypath: string) => {
    const keys_walk = keypath.split('.');
    if (tar) {
        if (keypath.length > 1) {
            nestedDelete(tar[keys_walk[0]], keys_walk.slice(1).join('.'));
        } else {
            delete tar[keypath];
        }
    }
}

export const stringBecomeObject = (str: string, spliter?: string) => {
    const spltr = (typeof spliter === 'undefined') ? ',' : spliter;
    const path = str.split(spltr);
    if (path.length === 1) {
        return str;
    } else {
        const nested: any = {};
        nested[path[0]] = stringBecomeObject(path.slice(1).join(spltr));
        return nested;
    }
}

export const objectBecomeStringArray = (obj: any): Array<string> => {
    let buf = [];
    if (typeof obj === 'object' && obj !== null) {
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const strs = objectBecomeStringArray(obj[key]);
                if (strs.length) {
                    for (let i = 0; i < strs.length; i++) {
                        buf.push(`${key}\r${strs[i]}`);
                    }
                }
            }
        }
    } else { buf = (obj || obj === 0) ? [obj] : []; }
    return buf;
}

export const keyCodeGetBecomeSet = (sta: any) => {
    const buf: any = {};
    for (const key in sta) {
        if (Object.prototype.hasOwnProperty.call(sta, key) && isCypStdCmdType(key)) {
            const dbcmd = CMD_GET_TO_SET[key];
            if (typeof sta[key] === 'object' && sta[key] !== null) {
                const strs = objectBecomeStringArray(sta[key]);
                for (let i = 0; i < strs.length; i++) {
                    const _walk = strs[i].split('\r')
                    buf[`${dbcmd}_${_walk.slice(0, -1).join('_')}`] = _walk[_walk.length-1];
                }
            } else {
                if (dbcmd)
                    buf[dbcmd] = sta[key];
                else
                    buf[key] = sta[key];
            }
        }
    }
    return buf;
}

export const isMAC = (str: string): boolean => (/^([0-9A-Fa-f]{2}[:]){5}([0-9A-Fa-f]{2})$/.test(str));
export const isLANIP = (str: string): boolean => (/^(192\.168\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]))$/.test(str))
export const isIP = (str: string): boolean => (/^(([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5])\.([01]?[0-9]?[0-9]|2[0-4][0-9]|25[0-5]))$/.test(str))

export function defaultValue<T>(tar: T | undefined | null, comparator: ((v: T) => boolean) | null, default_v: T): T {
    if (tar !== undefined && tar !== null) {
        if (comparator) {
            return comparator(tar) ? tar : default_v;
        } else {
            return tar;
        }
    } else {
        return default_v;
    }
}

export function RealizeInterface<T>(defaults?: Partial<T>) {
    return class {
        constructor() { Object.assign(this, defaults || {}); }
    } as new () => T
}

export function uploadFile(file: File, dest: string) {
    return new Promise(async (resolve: (r: Response) => void, reject) => {
        const fd = new FormData();
        fd.append('file', file);
        try {
            const res = await fetch(dest, { method: 'POST', body: fd, mode: (window.APP_DEV_MODE)?'no-cors':'cors' });
            resolve(res);
        } catch (err) {
            reject(err);
        }
    });
}

export function uploadForm(form: FormData, dest: string) {
    return new Promise(async (resolve: (r: Response) => void, reject) => {
        try {
            const res = await fetch(dest, { method: 'POST', body: form, mode: (window.APP_DEV_MODE)?'no-cors':'cors' });
            resolve(res);
        } catch (err) {
            reject(err);
        }
    });
}

export function downloadAsText(filename: string, text: string) {
    // filename : download file as a filename
    // text     : donwload file content
    if (window.APP_ON_HDMI) {
        window.alert("Operation is not supported on native output.");
        return;
    }
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export function downloadAsJson(filename: string, text: string) {
    // filename : download file as a filename
    // text     : donwload file content
    if (window.APP_ON_HDMI) {
        window.alert("Operation is not supported on native output.");
        return;
    }
    const element = document.createElement('a');
    element.setAttribute('href', 'data:text/json;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
}

export function downloadAsEDID(filename: string, text: string) {
    if (window.APP_ON_HDMI) {
        window.alert("Operation is not supported on native output.");
        return;
    }
    const array = Uint8Array.from(text.split(',').map(hex => parseInt(hex, 16)));
    const blob = new Blob([array], { type: 'application/octet-stream' });
    const url = window.URL.createObjectURL(blob)
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(element);
}

export function downloadBlob(filename: string, blob: Blob) {
    if (window.APP_ON_HDMI) {
        window.alert("Operation is not supported on native output.");
        return;
    }
    const url = window.URL.createObjectURL(blob)
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(element);
}

export function downloadFromURL(
    filename: string,
    url: string,
    onprogress: (((ev: ProgressEvent<EventTarget>, startTime: number) => void) | null),
    headers: Array<{ key: string, value: string }>,
    content: any) {
    const startTime = new Date().getTime();
    const request = new XMLHttpRequest();

    request.responseType = "blob";
    if (typeof content === 'undefined') {
        request.open("GET", url);
        request.send();
    } else {
        request.open("POST", url);
        for (let i = 0; i < headers.length; i++) {
            request.setRequestHeader(headers[i].key, headers[i].value);
        }
        request.send(content);
    }

    request.onreadystatechange = function() {
        if (this.readyState === 4 && this.status === 200) {
            const blobURL = window.URL.createObjectURL(this.response);
            const anchor = document.createElement("a");
            anchor.href = blobURL;
            anchor.download = filename;
            document.body.appendChild(anchor);
            anchor.click();
            window.URL.revokeObjectURL(blobURL);
            document.body.removeChild(anchor);
        }
    }

    if (onprogress) {
        request.onprogress = function(e) {
            onprogress(e, startTime);
        };
    }
}

export const hexgen = (len: number) => {
    const map = '0123456789abcdef';
    let buf = '';
    for (let i = 0; i < len; i++)
        buf += (map.charAt(Math.floor(Math.random()*15)));
    return buf;
}

export function AESEncrypt(message: string, enc_key?: string): string {
    if (enc_key) {
        // enc_key is hex string
        const key = CryptoJS.enc.Utf8.parse(enc_key);
        const encrypt = CryptoJS.AES.encrypt(message, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        // return hex string
        return encrypt.ciphertext.toString();
    } else if (window.APP_CORE_INDEX) {
        // window.APP_AES_KEY is hex string
        const key = CryptoJS.enc.Utf8.parse(window.APP_CORE_INDEX);
        const encrypt = CryptoJS.AES.encrypt(message, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        // return hex string
        return encrypt.ciphertext.toString();
    } else {
        return '';
    }
}

export function AESDecrypt(cypher: string, enc_key?: string): string {
    if (enc_key) {
        // enc_key is hex string
        // cypher is hex string
        const key = CryptoJS.enc.Utf8.parse(enc_key);
        const parseHex = CryptoJS.enc.Hex.parse(cypher);
        const parseBase64 = CryptoJS.enc.Base64.stringify(parseHex);
        const decrypt = CryptoJS.AES.decrypt(parseBase64, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return decrypt.toString(CryptoJS.enc.Utf8);
    } else if (window.APP_CORE_INDEX) {
        // window.APP_AES_KEY is hex string
        // cypher is hex string
        const key = CryptoJS.enc.Utf8.parse(window.APP_CORE_INDEX);
        const parseHex = CryptoJS.enc.Hex.parse(cypher);
        const parseBase64 = CryptoJS.enc.Base64.stringify(parseHex);
        const decrypt = CryptoJS.AES.decrypt(parseBase64, key, { mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7 });
        return decrypt.toString(CryptoJS.enc.Utf8);
    } else {
        return '';
    }
}

export function hasChinese(str: string) {
    return !!str.match(/[\u3040-\u30ff\u3400-\u4dbf\u4e00-\u9fff\uf900-\ufaff\uff66-\uff9f]/);
}

export function isEmptyObject(x: object) {
    try {
        return JSON.stringify(x) === '{}';
    } catch (err) {
        return false;
    }
}

export function ArrayReverse<T>(arr: Array<T>): Array<T> {
    const ret = [];
    for (let i = (arr.length - 1); i >= 0; i--)
        ret.push(arr[i]);        
    return ret;
}

export function detectIEEdge() {
    var ua = window.navigator.userAgent;

    var msie = ua.indexOf('MSIE ');
    if (msie > 0) {
        // IE 10 or older => return version number
        // return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        return true;
    }

    var trident = ua.indexOf('Trident/');
    if (trident > 0) {
        // IE 11 => return version number
        // var rv = ua.indexOf('rv:');
        // return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        return true;
    }

    var edge = ua.indexOf('Edge/');
    if (edge > 0) {
        // Edge => return version number
        // return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        return true;
    }

    // other browser
    return false;
}

export function signout() {
    window.localStorage.removeItem('auth');
    window.location.href = "/?t=" + (new Date()).getTime()
}

export function isJsonString(str: string) {
    try {
        const obj = JSON.parse(str);
        return obj;
    } catch (_) {
        return null;
    }
}

export function mapFilename(str: string) {
    let filename = str.slice();
    filename = filename.split(' ').join('-');
    filename.split('/').join('-');
    filename.split(',').join('-');
    return filename.toLowerCase();
}

export class ArrayBatcher<T> extends Array<T> {
    private _lag: number = 100;
    private _force: boolean = false;
    private _status: 'terminate' | 'running' = 'terminate';
    private _stop_flag: boolean = false;

    get isForce() { return Boolean(this._force); }
    get lag() { return Number(this._lag); }
    set lag(v: number) { this._lag = v; }
    get status() { return this._status; }
    set status(s: 'terminate' | 'running') { this._status = s; }

    checkpoint?: ((obj: any) => 'stop' | 'continue');
    onFinish: (() => void) = () => {};
    routine: ((obj: T) => void) = () => {}

    readonly stop = () => {
        if (this.status === 'running')
            this._stop_flag = true;
    }

    readonly run = () => {
        const proc = this[0];

        if (this._stop_flag) {
            this.status = 'terminate';
            this._stop_flag = false;
            return;
        }

        if (proc) {
            this.status = 'running';
            if (this.checkpoint) {
                const check_result = this.checkpoint(proc);
                if (check_result === 'continue') {
                    this._force = false;
                    this.routine(proc);
                    this._force = false;
                    this.shift();
                    global.setTimeout(this.run, this.lag);
                } else {
                    this.status = 'terminate';
                    this._stop_flag = false;
                }
            } else {
                this._force = false;
                this.routine(proc);
                this._force = false;
                this.shift();
                global.setTimeout(this.run, this.lag);
            }
        } else {
            this.onFinish();
            this.status = 'terminate';
            this._stop_flag = false;
        }
    }

    readonly forceRun = () => {
        const proc = this[0];
        if (proc) {
            this._force = true;
            this.routine(proc);
            this._force = false;
            this.shift();
            global.setTimeout(this.run, this.lag);
        } else {
            this.onFinish();
        }
    }

    readonly skip = () => {
        this.shift();
        this.run();
    }
}

export function importHandler(option: {
    file: File,
    userConfirm?: string,
    validateFilename?: (fn: string) => boolean,
    onConfirm: (context: any) => void,
    onCancel?: () => void,
    onError?: (msg: string, timeout_ms: number) => void,
}) {
    const { file, userConfirm, validateFilename, onConfirm, onCancel, onError } = option;
    const fr = new FileReader();
    const filename = file.name;
    fr.onloadend = () => {
        const context = fr.result;
        // const ext = filename.split('.').slice(-3).join('.').toLowerCase();
        const filename_valid = (validateFilename) ? validateFilename(filename) : true;
        if (typeof context === 'string' && filename_valid) {
            try {
                const parseJson = JSON.parse(context);
                if (parseJson) {
                    if (userConfirm) {
                        window.userConfirm(userConfirm, (ok) => {
                            if (ok) { onConfirm(parseJson); }
                            else { if (onCancel) onCancel(); }
                        });
                    } else {
                        onConfirm(parseJson);
                    }
                } else { if (onError) onError('Format incorrect', 10000); }
            } catch (error) { if (onError) onError(`Error: ${error}`, 10000); }
        } else { if (onError) onError(`Format incorrect`, 10000); }
    }
    fr.readAsText(file);
}