import React from 'react';
import { withRouter } from 'react-router-dom';

import { CsxUI } from '../../csx';
import { Href } from 'cypd';
import { getText } from '../../csx/desc';


export default class Breadcrum extends CsxUI.IRQComponent<any> {
    FEATURE_LABEL_TABLE: { [url: string]: string } = {
        'software':     getText('DEVICE_FEATURE_FW_UPDATE'),
        'routing':      getText('DEVICE_FEATURE_ROUTING'),
        'edid':         getText('DEVICE_FEATURE_EDID'),
        'hdcp':         getText('DEVICE_FEATURE_HDCP'),
        'general':      getText('DEVICE_FEATURE_GENERAL'),
        'audio':        getText('DEVICE_FEATURE_AUDIO'),
        'scaler':       getText('DEVICE_FEATURE_SCALER'),
        'io':           getText('DEVICE_FEATURE_IO'),
        'videowall':    getText('DEVICE_FEATURE_VIDEOWALL'),
        'osd':          getText('DEVICE_FEATURE_OSD'),
        'cabletest':    getText('DEVICE_FEATURE_CABLE_TEST'),
        'auto':         getText('DEVICE_FEATURE_AUTOMATION'),
        'streaming':    getText('DEVICE_FEATURE_STREAMING'),
        'record':       getText('DEVICE_FEATURE_RECORD'),
        'cec':          getText('DEVICE_FEATURE_CEC'),
        'pmonitor':     getText('DEVICE_FEATURE_POWER_MONITOR'),
        'debug':        getText('DEVICE_FEATURE_DEBUG'),
        'dante':        getText('DEVICE_FEATURE_DANTE'),
        'time':         getText('DEVICE_FEATURE_TIME'),
    }
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'DeviceRealTime';
    }
    render() {
        const curURLParameters = this.getURLParameter();
        const dev_id_url = curURLParameters.get('dev');
        const focus_feature = curURLParameters.get('page');
        const Component = withRouter(({history}) => (
            <div className='breadcrum'>
                <Href className='breadcrum-item' to={() => { history.push(`/management/devices`); }}>{getText('APP_NAV_DEVICES')}</Href>
                <Href className='breadcrum-item' to={() => { history.push(`/management/devices/?dev=${dev_id_url}`); }}>{(window.FOCUS_DEVICE)?window.FOCUS_DEVICE.NICKNAME:'Unknown Device'}</Href>
                {focus_feature?<Href className='breadcrum-item' to={() => { history.push(`/management/devices/?dev=${dev_id_url}&page=${focus_feature}`); }}>{this.FEATURE_LABEL_TABLE[focus_feature]}</Href>:undefined}
            </div>
        ));
        return <Component />;
    }
}