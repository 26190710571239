import { DateTime } from 'cypd';

import * as CsxUtil from './util';
import { DEV_TARGET_IP } from './manager';


const stringLitArray = <L extends string>(arr: L[]) => arr;
// export const CsxEventTypeList = stringLitArray(['NullEvent', 'OneTimeEvent', 'RepeatEvent', 'CYPDeviceEvent', 'CustomCmdEvent', 'ExternalTCPEvent', 'PollingEvent', 'ExternalRS232Event', 'TriggerInEvent', 'SystemEvent']);
// export const CsxEventTypeList = stringLitArray(['NullEvent', 'OneTimeEvent', 'RepeatEvent', 'CYPDeviceEvent', 'CustomCmdEvent', 'ExternalTCPEvent', 'PollingEvent', 'ExternalRS232Event', 'TriggerInEvent', 'TCPDeviceEvent']);
export const CsxEventTypeList = stringLitArray(['NullEvent', 'OneTimeEvent', 'RepeatEvent', 'CYPDeviceEvent', 'CustomCmdEvent', 'ExternalTCPEvent', 'PollingEvent', 'ExternalRS232Event', 'TriggerInEvent']);
export const CsxLogicTypeList = stringLitArray(['NullLogic', 'AndLogic', 'OrLogic', 'NotLogic']);
// export const CsxActionTypeList = stringLitArray(['NullAction', 'CYPAction', 'TCPAction', 'UDPAction', 'RS232Action', 'TCPDeviceAction']);
export const CsxActionTypeList = stringLitArray(['NullAction', 'CYPAction', 'TCPAction', 'UDPAction', 'RS232Action']);

export type CsxEventType = (typeof CsxEventTypeList)[number];
export type CsxLogicType = (typeof CsxLogicTypeList)[number];
export type CsxActionType = (typeof CsxActionTypeList)[number];
export const isCsxEventType = (x: any): x is CsxEventType => CsxEventTypeList.includes(x);
export const isCsxLogicType = (x: any): x is CsxLogicType => CsxLogicTypeList.includes(x);
export const isCsxActionType = (x: any): x is CsxActionType => CsxActionTypeList.includes(x);
export type CsxPosition = { x: number; y: number }
export type CsxBasicEdge = { from: string; to: string };
export type CsxNodeType = CsxEventType | CsxLogicType | CsxActionType;
export const csxEventTypeIndex = (x: CsxEventType): number => CsxEventTypeList.indexOf(x);
export const csxLogicTypeIndex = (x: CsxLogicType): number => CsxLogicTypeList.indexOf(x);
export const csxActionTypeIndex = (x: CsxActionType): number => CsxActionTypeList.indexOf(x);
export const isCsxPosition = (x: any): x is CsxPosition => { return (!!x && typeof x.x === 'number' && typeof x.y === 'number'); };

export enum CsxTrailType {
    NA = 'N/A',
    CR = 'CR',
    LF = 'LF',
    CRLF = 'CR/LF',
    SPACE = 'Space',
    STX = 'STX',
    ETX = 'ETX',
}
export function isCsxTrailType(x: string): x is CsxTrailType {
    const set: Set<string> = new Set(Object.values(CsxTrailType));
    return set.has(x);
}
const CYP_TRAIL_MAP: { [s in CsxTrailType]: number } = { 'N/A': 1, 'CR': 2, 'LF': 3,'CR/LF': 4,'Space': 5,'STX': 6,'ETX': 7 };
const CYP_TRAIL_MAP_REVERSE: { [s: string]: CsxTrailType } = {
    1: CsxTrailType.NA,
    2: CsxTrailType.CR,
    3: CsxTrailType.LF,
    4: CsxTrailType.CRLF,
    5: CsxTrailType.SPACE,
    6: CsxTrailType.STX,
    7: CsxTrailType.ETX,
};

export type CsxEventParameter = {
    name: string;
    lockOnCanvas: boolean;
    OneTime?: {
        time: number;
    };
    Repeat?: {
        fromTime: number;
        toTime: number;
        fromDate: number;
        toDate: number;
        eventExpression: string;
        triggerExpression: string;
    };
    CYPDevice?: {
        device: string;
        command: string;
    };
    TriggerIn?: {
        keycode: string;
    };
    TCP?: {
        nic: string;
        ipaddr: string;
        port: number;
        endchar: CsxTrailType;
        command: string;
        hexCommand: string;
    };
    TCPDevice?: {
        device: string;
        endchar: CsxTrailType;
        command: string;
        hexCommand: string;
    };
    Control?: {
        endchar: CsxTrailType;
        command: string;
        hexCommand: string;
    };
    Polling?: {
        ipaddr: string;
        port: number;
        pollingRef: string;
        polling: string;
        hexPolling: string;
        pollingEndchar: string;
        detect: string;
        hexDetect: string;
        detectEndchar: string;
        nic: string;
    };
    Customized?: {
        networkInterface: string;
        ipaddr: string;
        port: number;
        command: string;
        hexCommand: string;
        endchar: CsxTrailType;
        nic: string;
    };
}
export const isCsxEventParameter = (x: any): x is CsxEventParameter => { return (!!x && typeof x.name === 'string') };

export type CsxLogicParameter = {
    lockOnCanvas: boolean;
}

export const isCsxLogicParameter = (x: any): x is CsxLogicParameter => { return (!!x) };

export type CsxActionParameter = {
    name: string;
    lockOnCanvas: boolean;
    delay: number;
    priority: number;
    TCP?: {
        nic: string;
        ipaddr: string;
        port: number;
        endchar: CsxTrailType;
        command: string;
        hexCommand: string;
        commandRef: string;
    };
    TCPDevice?: {
        device: string;
        endchar: CsxTrailType;
        command: string;
        hexCommand: string;
        commandRef: string;
    };
    Control?: {
        endchar: CsxTrailType;
        command: string;
        hexCommand: string;
        commandRef: string;
    };
    UDP?: {
        nic: string;
        ipaddr: string;
        port: number;
        endchar: CsxTrailType;
        command: string;
        hexCommand: string;
        commandRef: string;
    };
    CYPDevice?: {
        device: string;
        command: string;
    }
}
export const isCsxActionParameter = (x: any): x is CsxActionParameter => { return (!!x && typeof x.name === 'string' && typeof x.priority === 'number') };

export class CsxBasicNode {
    protected _id: string = '';
    protected _position: CsxPosition = { x: 0, y: 0 };
    // constructor(props: [string, string]) {
    //     const pos_walk = CsxUtil.defaultValue(props[1], null, '0,0').split(',');
    //     this._id = props[0];
    //     this._position = { x: parseInt(pos_walk[0]), y: parseInt(pos_walk[1]) };
    // }
    get ID() { return this._id.slice(); }
    set ID(value: string) { this._id = value.slice(); }
    get POSITION() { return Object.assign({}, this._position); }
    set POSITION(pos: CsxPosition) { this._position = { ...pos }; }
}

export function CsxEventParameterToJson(param: CsxEventParameter): any {
    const interface_map: { [s: string]: number } = { UDP: 1, TCP: 2 }; 
    const param_struct = CsxUtil.nestedAssign(param);
    const build_param: any = { name: param_struct.name, lockOnCanvas: param_struct.lockOnCanvas };
    if (param_struct.CYPDevice) {
        build_param.device = param_struct.CYPDevice.device;
        build_param.command = param_struct.CYPDevice.command;
    } else if (param_struct.TCP) {
        const { ipaddr, endchar, nic, port, command, hexCommand } = param_struct.TCP;
        const endchar_value = (isCsxTrailType(endchar)) ? CYP_TRAIL_MAP[endchar] : CYP_TRAIL_MAP[CsxTrailType.NA];
        build_param.ipaddr = ipaddr;
        build_param.nic = nic;
        build_param.port = port;
        build_param.command = command;
        build_param.hexCommand = hexCommand;
        build_param.endchar = (typeof endchar_value === 'number') ? endchar_value : 1;
    } else if (param_struct.Customized) {
        const { ipaddr, port, endchar, networkInterface, command, hexCommand, nic } = param_struct.Customized;
        const endchar_value = (isCsxTrailType(endchar)) ? CYP_TRAIL_MAP[endchar] : CYP_TRAIL_MAP[CsxTrailType.NA];
        const interface_value = interface_map[networkInterface];
        build_param.ipaddr = ipaddr;
        build_param.port = port;
        build_param.networkInterface = (typeof interface_value === 'number') ? interface_value : 1;
        build_param.command = command;
        build_param.hexCommand = hexCommand;
        build_param.nic = nic;
        build_param.endchar = (typeof endchar_value === 'number') ? endchar_value : 1;
    } else if (param_struct.Control) {
        const { endchar, command, hexCommand } = param_struct.Control;
        const endchar_value = (isCsxTrailType(endchar)) ? CYP_TRAIL_MAP[endchar] : CYP_TRAIL_MAP[CsxTrailType.NA];
        build_param.command = command;
        build_param.hexCommand = hexCommand;
        build_param.endchar = (typeof endchar_value === 'number') ? endchar_value : 1;
    } else if (param_struct.TriggerIn) {
        build_param.keycode = param_struct.TriggerIn.keycode;
    } else if (param_struct.OneTime) {
        const time = new Date(param_struct.OneTime.time);
        build_param.time = DateTime.FormatDateTime(time, 'YYYY-MM-DD HH:mm:ss');
    } else if (param_struct.Repeat) {
        const from_date = new Date(param_struct.Repeat.fromDate);
        const to_date = new Date(param_struct.Repeat.toDate);
        const from_time = new Date(param_struct.Repeat.fromTime);
        const to_time = new Date(param_struct.Repeat.toTime);
        build_param.fromDate = DateTime.FormatDateTime(from_date, 'YYYY-MM-DD');
        build_param.toDate = DateTime.FormatDateTime(to_date, 'YYYY-MM-DD');
        build_param.fromTime = DateTime.FormatDateTime(from_time, 'HH:mm:ss', '24-hour');
        build_param.toTime = DateTime.FormatDateTime(to_time, 'HH:mm:ss', '24-hour');
        build_param.eventExpression = param_struct.Repeat.eventExpression;
        build_param.triggerExpression = param_struct.Repeat.triggerExpression;
    } else if (param_struct.Polling) {
        const { pollingEndchar, detectEndchar, polling, hexPolling, detect, hexDetect,ipaddr, port, nic } = param_struct.Polling;
        const polling_endchar_value = (isCsxTrailType(pollingEndchar)) ? CYP_TRAIL_MAP[pollingEndchar] : CYP_TRAIL_MAP[CsxTrailType.NA];
        const detect_endchar_value = (isCsxTrailType(detectEndchar)) ? CYP_TRAIL_MAP[detectEndchar] : CYP_TRAIL_MAP[CsxTrailType.NA];
        build_param.polling = polling;
        build_param.hexPolling = hexPolling;
        build_param.pollingEndchar = (typeof polling_endchar_value === 'number') ? polling_endchar_value : 1;
        build_param.detect = detect;
        build_param.hexDetect = hexDetect;
        build_param.detectEndchar = (typeof detect_endchar_value === 'number') ? detect_endchar_value : 1;
        build_param.ipaddr = ipaddr;
        build_param.port = port;
        build_param.nic = nic;
    }
    return build_param;
}

export class CsxEventNode extends CsxBasicNode {
    protected _type: number = 0;
    // protected _param: string;
    protected _json_param: CsxEventParameter = { name: 'NullEvent', lockOnCanvas: false };
    // constructor(props: [string, string, string, string]) {
    //     super([props[0], props[3]]);
    //     this._type = parseInt(props[1]);
    //     this._type = (isNaN(this._type))?0:this._type;
    //     this._param = props[2];
    // }
    constructor(data: any) {
        super();
        if (data) {
            const { id, type, param, coordinate, lockOnCanvas } = data;
            if (typeof id === 'string')
                this.ID = id;
            if (typeof lockOnCanvas === 'boolean')
                this._json_param.lockOnCanvas = lockOnCanvas;
            if (isCsxPosition(coordinate))
                this.POSITION = coordinate;
            if (typeof type === 'number') {
                this.TYPE = CsxEventTypeList[type];
                this.setJsonParam(this.TYPE, param);
            }
        }
    }
    get TYPE() { return CsxEventTypeList[this._type]; }
    set TYPE(v: CsxEventType) { this._type = CsxEventTypeList.indexOf(v); }
    // get PARAM() { return this._param.slice(); }
    // set PARAM(v: string) { this._param = v.slice(); }
    get PARAM() { return CsxUtil.nestedAssign(this._json_param); }
    // set PARAM(param: any) { this.setJsonParam(this.TYPE, param); }
    get PARAM_DESC() { return ''; }
    Old_construct = (data: any) => {
        if (!data) return;
        const CYP_TRAIL_MAP: { [s: string]: string } = { 1: 'N/A', 2: 'CR', 3: 'LF', 4: 'CR/LF', 5: 'Space', 6: 'STX', 7: 'ETX' };
        const { id, type, param, coordinate } = data;
        const param_walk = (typeof param === 'string') ? param.split(',') : [];
        const rebuild_param: any = { name: param_walk[0] };
        // const rebuild_event = new CsxEventNode(cur);

        if (typeof id === 'string')
            this.ID = id;
        if (isCsxPosition(coordinate))
            this.POSITION = coordinate;
        if (typeof type === 'number')
            this.TYPE = CsxEventTypeList[type];
        switch (type) {
            case 1: // one time
                const utc_date = Date.parse(CsxUtil.defaultValue(param_walk[1], null, '').replace(' ', 'T'));
                rebuild_param.time = (isNaN(utc_date) ? new Date().getTime() : utc_date);
                break;
            case 2: // repeat
                const param0_parse = Date.parse(param_walk[1]);
                const param1_parse = Date.parse(param_walk[2]);
                const param2_parse = Date.parse(`${param_walk[1]}T${param_walk[3]}`); // param_walk[1]+T is meaningless, just to satisify the parse function format
                const param3_parse = Date.parse(`${param_walk[2]}T${param_walk[4]}`); // param_walk[2]+T is meaningless, just to satisify the parse function format
                rebuild_param.fromDate = isNaN(param0_parse) ? new Date().getTime() : param0_parse;
                rebuild_param.toDate = isNaN(param1_parse) ? new Date().getTime() : param1_parse;
                rebuild_param.fromTime = isNaN(param2_parse) ? new Date().getTime() : param2_parse;
                rebuild_param.toTime = isNaN(param3_parse) ? new Date().getTime() : param3_parse;
                rebuild_param.triggerExpression = `${param_walk[5]},sec`;
                rebuild_param.eventExpression = param_walk.slice(6).join(',');
                break;
            case 3: // CYP device
                rebuild_param.device = CsxUtil.defaultValue(param_walk[1], null, '');
                rebuild_param.command = param_walk.slice(2).join(',');
                break;
            case 4: // Customized Command
                rebuild_param.networkInterface = (param_walk[1] === '1') ? 'UDP' : 'TCP';
                rebuild_param.ipaddr = CsxUtil.defaultValue(param_walk[2], null, '');
                rebuild_param.endchar = CsxUtil.defaultValue(CYP_TRAIL_MAP[`${param_walk[3]}`], null, '');
                rebuild_param.command = param_walk.slice(4).join(',');
                break;
            case 5: // TCP
                rebuild_param.port = isNaN(parseInt(param_walk[2])) ? 0 : parseInt(param_walk[2]);
                rebuild_param.ipaddr = CsxUtil.defaultValue(param_walk[1], null, '');
                rebuild_param.endchar = CsxUtil.defaultValue(CYP_TRAIL_MAP[`${param_walk[3]}`], null, '');
                rebuild_param.command = param_walk.slice(4).join(',');
                break;
            case 6: // polling
                const polling_detect = (param_walk[5]) ? param_walk[5] : '\x06';
                const polling_detect_walk = polling_detect.split('\x06');
                rebuild_param.ipaddr = CsxUtil.defaultValue(param_walk[1], null, '');
                rebuild_param.port = isNaN(parseInt(param_walk[2])) ? 0 : parseInt(param_walk[2]);
                rebuild_param.pollingEndchar = CsxUtil.defaultValue(CYP_TRAIL_MAP[`${param_walk[3]}`], null, '');
                rebuild_param.detectEndchar = CsxUtil.defaultValue(CYP_TRAIL_MAP[`${param_walk[4]}`], null, '');
                rebuild_param.polling = CsxUtil.defaultValue(polling_detect_walk[0], null, '');
                rebuild_param.detect = CsxUtil.defaultValue(polling_detect_walk[1], null, '');
                break;
            case 7: // polling
                rebuild_param.endchar = param_walk[2];
                rebuild_param.command = param_walk.slice(3).join(',');
                break;
            case 8: // trigger in
                rebuild_param.keycode = param_walk[1];
                break;
            default:
                break;
        }
        this.setJsonParam(this.TYPE, rebuild_param);
    }
    setJsonParam = (type: CsxEventType, param: any) => {
        const interface_map: { [s: number]: string } = { 1: 'UDP', 2: 'TCP' };
        if (param) {
            const { name, lockOnCanvas } = param;
            if (typeof name === 'string')
                this._json_param.name = name.slice();
            if (typeof lockOnCanvas === 'boolean')
                this._json_param.lockOnCanvas = lockOnCanvas;
            if (type === 'CYPDeviceEvent') {
                const { command, device } = param;
                this._json_param.CYPDevice = { device: '', command: '' };
                if (typeof command === 'string')
                    this._json_param.CYPDevice.command = command.slice();
                if (typeof device === 'string')
                    this._json_param.CYPDevice.device = device.slice();
            } else if (type === 'CustomCmdEvent') {
                const { networkInterface, ipaddr, port, command, hexCommand, endchar, nic } = param;
                this._json_param.Customized = { networkInterface: 'TCP', ipaddr: '', port: 5000, command: '', hexCommand: '', endchar: CsxTrailType.NA, nic: 'eth0' };
                if (typeof networkInterface === 'number')
                    this._json_param.Customized.networkInterface = interface_map[networkInterface];
                if (typeof nic === 'string')
                    this._json_param.Customized.nic = nic.slice();
                if (typeof ipaddr === 'string')
                    this._json_param.Customized.ipaddr = ipaddr.slice();
                if (typeof port === 'number')
                    this._json_param.Customized.port = port;
                if (typeof command === 'string')
                    this._json_param.Customized.command = command.slice();
                if (typeof hexCommand === 'string')
                    this._json_param.Customized.hexCommand = hexCommand.slice();
                if (typeof endchar === 'number')
                    this._json_param.Customized.endchar = CYP_TRAIL_MAP_REVERSE[endchar];
            } else if (type === 'ExternalRS232Event') {
                const { command, hexCommand, endchar } = param;
                this._json_param.Control = { command: '', hexCommand: '', endchar: CsxTrailType.NA };
                if (typeof command === 'string')
                    this._json_param.Control.command = command.slice();
                if (typeof hexCommand === 'string')
                    this._json_param.Control.hexCommand = hexCommand.slice();
                if (typeof endchar === 'number')
                    this._json_param.Control.endchar = CYP_TRAIL_MAP_REVERSE[endchar];
            } else if (type === 'ExternalTCPEvent') {
                const { command, hexCommand, endchar, ipaddr, port, nic } = param;
                this._json_param.TCP = { command: '', hexCommand: '', endchar: CsxTrailType.NA, ipaddr: '', port: 23, nic: 'eth0' };
                if (typeof command === 'string')
                    this._json_param.TCP.command = command.slice();
                if (typeof hexCommand === 'string')
                    this._json_param.TCP.hexCommand = hexCommand.slice();
                if (typeof nic === 'string')
                    this._json_param.TCP.nic = nic.slice();
                if (typeof endchar === 'number')
                    this._json_param.TCP.endchar = CYP_TRAIL_MAP_REVERSE[endchar];
                if (typeof ipaddr === 'string')
                    this._json_param.TCP.ipaddr = ipaddr.slice();
                if (typeof port === 'number')
                    this._json_param.TCP.port = port;
            } else if (type === 'PollingEvent') {
                const { ipaddr, port, polling, pollingRef, hexPolling, pollingEndchar, detect, hexDetect, detectEndchar, nic } = param;
                this._json_param.Polling = { ipaddr: '', pollingRef: '', port: 23, polling: '', hexPolling: '', pollingEndchar: '', detect: '', hexDetect: '', detectEndchar: '', nic: 'eth0' };
                if (typeof nic === 'string')
                    this._json_param.Polling.nic = nic.slice();
                if (typeof polling === 'string')
                    this._json_param.Polling.polling = polling.slice();
                if (typeof pollingRef === 'string')
                    this._json_param.Polling.pollingRef = pollingRef.slice();
                if (typeof hexPolling === 'string')
                    this._json_param.Polling.hexPolling = hexPolling.slice();
                if (typeof pollingEndchar === 'number')
                    this._json_param.Polling.pollingEndchar = CYP_TRAIL_MAP_REVERSE[pollingEndchar];
                if (typeof detect === 'string')
                    this._json_param.Polling.detect = detect.slice();
                if (typeof hexDetect === 'string')
                    this._json_param.Polling.hexDetect = hexDetect.slice();
                if (typeof detectEndchar === 'number')
                    this._json_param.Polling.detectEndchar = CYP_TRAIL_MAP_REVERSE[detectEndchar];
                if (typeof ipaddr === 'string')
                    this._json_param.Polling.ipaddr = ipaddr.slice();
                if (typeof port === 'number')
                    this._json_param.Polling.port = port;
            } else if (type === 'TriggerInEvent') {
                const { keycode } = param;
                this._json_param.TriggerIn = { keycode: '' };
                if (typeof keycode === 'string')
                    this._json_param.TriggerIn.keycode = keycode.slice();
            } else if (type === 'OneTimeEvent') {
                const { time } = param;
                this._json_param.OneTime = { time: 0 };
                if (typeof time === 'string') {
                    const utc_date = Date.parse(CsxUtil.defaultValue(time, null, '').replace(' ', 'T'));
                    this._json_param.OneTime.time = utc_date;
                }
            } else if (type === 'RepeatEvent') {
                const { fromTime, fromDate, toTime, toDate, eventExpression, triggerExpression } = param;
                this._json_param.Repeat = { fromTime: 0, fromDate: 0, toTime: 0, toDate: 0, eventExpression: '', triggerExpression: '' };
                if (typeof fromDate === 'string') {
                    this._json_param.Repeat.fromDate = Date.parse(fromDate);
                    if (typeof fromTime === 'string')
                        this._json_param.Repeat.fromTime = Date.parse(`${fromDate}T${fromTime}`);
                }
                if (typeof toDate === 'string') {
                    this._json_param.Repeat.toDate = Date.parse(toDate);
                    if (typeof toTime === 'string')
                        this._json_param.Repeat.toTime = Date.parse(`${toDate}T${toTime}`);
                }
                if (typeof eventExpression === 'string')
                    this._json_param.Repeat.eventExpression = eventExpression.slice();
                if (typeof triggerExpression === 'string')
                    this._json_param.Repeat.triggerExpression = triggerExpression.slice();
            }
        }
    }
    // toLines = (): [string, string, string, string] => [this.ID, this._type.toString(), this.PARAM, `${this.POSITION.x},${this.POSITION.y}`];
    toJson = (): NodeJson => {
        return {
            id: this.ID,
            type: this._type,
            param: CsxEventParameterToJson(this.PARAM),
            coordinate: { ...this.POSITION },
        };
    }
}

export function CsxLogicParameterToJson(param: CsxLogicParameter): any {
    const param_struct = CsxUtil.nestedAssign(param);
    const build_param: any = { lockOnCanvas: param_struct.lockOnCanvas };
    return build_param;
}

export class CsxLogicNode extends CsxBasicNode {
    protected _type: number = 0;
    // protected _param: string;
    protected _json_param: CsxLogicParameter = { lockOnCanvas: false };
    // constructor(props: [string, string, string, string]) {
    //     super([props[0], props[3]]);
    //     this._type = parseInt(props[1]);
    //     this._type = (isNaN(this._type))?0:this._type;
    //     this._param = props[2];
    // }
    constructor(data: any) {
        super();
        if (data) {
            const { id, type, param, coordinate, lockOnCanvas } = data;
            if (typeof id === 'string')
                this.ID = id;
            if (typeof lockOnCanvas === 'boolean')
                this._json_param.lockOnCanvas = lockOnCanvas;
            if (isCsxPosition(coordinate))
                this.POSITION = coordinate;
            if (typeof type === 'number') {
                this.TYPE = CsxLogicTypeList[type];
                this.setJsonParam(this.TYPE, param);
            }
        }
    }
    get TYPE() { return CsxLogicTypeList[this._type]; }
    set TYPE(v: CsxLogicType) { this._type = CsxLogicTypeList.indexOf(v); }
    // get PARAM() { return this._param.slice(); }
    // set PARAM(v: string) { this._param = v.slice(); } 
    get PARAM() { return CsxUtil.nestedAssign(this._json_param); }
    // set PARAM(param: any) { this.setJsonParam(this.TYPE, param); }
    get PARAM_DESC() { return ''; }
    Old_construct(data: any) {
        if (!data) return;
        // const { id, type, param, coordinate } = data;
        const { id, type, coordinate } = data;
        // const param_walk = param.split(',');

        const rebuild_param: any = {};
        if (typeof id === 'string')
            this.ID = id;
        if (isCsxPosition(coordinate))
            this.POSITION = coordinate;
        if (typeof type === 'number') {
            this.TYPE = CsxLogicTypeList[type];
        }
        this.setJsonParam(this.TYPE, rebuild_param);
    }
    setJsonParam = (_: CsxLogicType, param: any) => {
        const { lockOnCanvas } = param;
        if (typeof lockOnCanvas === 'boolean')
            this._json_param.lockOnCanvas = lockOnCanvas;
    }
    // toLines = (): [string, string, string, string] => [this.ID, this._type.toString(), this.PARAM, `${this.POSITION.x},${this.POSITION.y}`];
    toJson = (): NodeJson => {
        return {
            id: this.ID,
            type: this._type,
            param: CsxLogicParameterToJson(this.PARAM),
            coordinate: { ...this.POSITION },
        };
    }
}

export function CsxActionParameterToJson(param: CsxActionParameter): any {
    const param_struct = CsxUtil.nestedAssign(param);
    const build_param: any = { name: param_struct.name, priority: param_struct.priority, lockOnCanvas: param_struct.lockOnCanvas, delay: param_struct.delay };
    if (param_struct.CYPDevice) {
        const { device, command } = param_struct.CYPDevice;
        build_param.device = device;
        build_param.command = command;
    } else if (param_struct.TCP) {
        const { ipaddr, nic, port, command, hexCommand, endchar } = param_struct.TCP;
        const endchar_value = (isCsxTrailType(endchar)) ? CYP_TRAIL_MAP[endchar] : CYP_TRAIL_MAP[CsxTrailType.NA];
        build_param.ipaddr = ipaddr;
        build_param.nic = nic;
        build_param.port = port;
        build_param.command = command;
        build_param.hexCommand = hexCommand;
        build_param.endchar = (typeof endchar_value === 'number') ? endchar_value : 1;
    } else if (param_struct.UDP) {
        const { ipaddr, nic, port, command, hexCommand, endchar } = param_struct.UDP;
        const endchar_value = (isCsxTrailType(endchar)) ? CYP_TRAIL_MAP[endchar] : CYP_TRAIL_MAP[CsxTrailType.NA];
        build_param.ipaddr = ipaddr;
        build_param.nic = nic;
        build_param.port = port;
        build_param.command = command;
        build_param.hexCommand = hexCommand;
        build_param.endchar = (typeof endchar_value === 'number') ? endchar_value : 1;
    } else if (param_struct.Control) {
        const { command, hexCommand, endchar } = param_struct.Control;
        const endchar_value = (isCsxTrailType(endchar)) ? CYP_TRAIL_MAP[endchar] : CYP_TRAIL_MAP[CsxTrailType.NA];
        build_param.command = command;
        build_param.hexCommand = hexCommand;
        build_param.endchar = (typeof endchar_value === 'number') ? endchar_value : 1;
    }
    return build_param;
}

export class CsxActionNode extends CsxBasicNode {
    protected _type: number = 0;
    // protected _param: string;
    protected _json_param: CsxActionParameter = { name: 'NullEvent', priority: 0, lockOnCanvas: false, delay: 0 };
    // constructor(props: [string, string, string, string]) {
    //     super([props[0], props[3]]);
    //     this._type = parseInt(props[1]);
    //     this._type = (isNaN(this._type))?0:this._type;
    //     this._param = props[2];
    // }
    constructor(data: any) {
        super();
        if (data) {
            const { id, type, param, coordinate, lockOnCanvas } = data;
            if (typeof id === 'string')
                this.ID = id;
            if (typeof lockOnCanvas === 'boolean')
                this._json_param.lockOnCanvas = lockOnCanvas;
            if (isCsxPosition(coordinate))
                this.POSITION = coordinate;
            if (typeof type === 'number') {
                this.TYPE = CsxActionTypeList[type];
                this.setJsonParam(this.TYPE, param);
            }
        }
    }
    get TYPE() { return CsxActionTypeList[this._type]; }
    set TYPE(v: CsxActionType) { this._type = CsxActionTypeList.indexOf(v); }
    get TYPE_NUMBER() { return Number(this._type); }
    // get PARAM() { return this._param.slice(); }
    // set PARAM(v: string) { this._param = v.slice(); } 
    get PARAM() { return CsxUtil.nestedAssign(this._json_param); }
    // set PARAM(param: any) { this.setJsonParam(this.TYPE, param); }
    get PARAM_DESC() { return ''; }
    Old_construct = (data: any) => {
        if (!data) return;
        const CYP_TRAIL_MAP: { [s: string]: string } = { 1: 'N/A', 2: 'CR', 3: 'LF', 4: 'CR/LF', 5: 'Space', 6: 'STX', 7: 'ETX' };
        const { id, type, param, coordinate } = data;
        const param_walk = (typeof param === 'string') ? param.split(',') : [];
        const rebuild_param: any = { name: param_walk[0] };
        // const rebuild_event = new CsxEventNode(cur);

        if (typeof id === 'string')
            this.ID = id;
        if (isCsxPosition(coordinate))
            this.POSITION = coordinate;
        if (typeof type === 'number')
            this.TYPE = CsxActionTypeList[type];
        switch (type) {
            case 1: // CYP Action
                rebuild_param.device = CsxUtil.defaultValue(param_walk[2], null, '');
                rebuild_param.command = param_walk.slice(3).join(',');
                break;
            case 2: // TCP
                rebuild_param.ipaddr = CsxUtil.defaultValue(param_walk[2], null, '');
                rebuild_param.port = isNaN(parseInt(param_walk[3])) ? 0 : parseInt(param_walk[3]);
                rebuild_param.endchar = CsxUtil.defaultValue(CYP_TRAIL_MAP[`${param_walk[4]}`], null, '');
                rebuild_param.command = param_walk.slice(5).join(',');
                break;
            case 3: // UDP
                rebuild_param.ipaddr = `${param_walk[2]}`;
                rebuild_param.port = isNaN(parseInt(param_walk[3])) ? 0 : parseInt(param_walk[3]);
                rebuild_param.endchar = CsxUtil.defaultValue(CYP_TRAIL_MAP[`${param_walk[4]}`], null, '');
                rebuild_param.command = param_walk.slice(5).join(',');
                break;
            case 4: // control (rs232)
                rebuild_param.endchar = CsxUtil.defaultValue(CYP_TRAIL_MAP[`${param_walk[2]}`], null, '');
                rebuild_param.command = param_walk.slice(3).join(',');
                break;
            default:
                break;
        }
        this.setJsonParam(this.TYPE, rebuild_param);
    }
    setJsonParam = (type: CsxActionType, param: any) => {
        // const CYP_TRAIL_MAP: { [s: string]: string } = { 1: 'N/A', 2: 'CR', 3: 'LF', 4: 'CR/LF', 5: 'Space', 6: 'STX', 7: 'ETX' };
        if (param) {
            const { name, priority, lockOnCanvas, delay } = param;
            this._json_param = { name: 'NullEvent', priority: 0, lockOnCanvas: false, delay: 0 };
            if (typeof name === 'string')
                this._json_param.name = name.slice();
            if (typeof priority === 'number')
                this._json_param.priority = priority;
            if (typeof delay === 'number')
                this._json_param.delay = delay;
            if (typeof lockOnCanvas === 'boolean')
                this._json_param.lockOnCanvas = lockOnCanvas;
            if (type === 'CYPAction') {
                const { command, device } = param;
                this._json_param.CYPDevice = { device: '', command: '' };
                if (typeof command === 'string')
                    this._json_param.CYPDevice.command = command.slice();
                if (typeof device === 'string')
                    this._json_param.CYPDevice.device = device.slice();
            } else if (type === 'TCPAction') {
                const { command, commandRef, hexCommand, endchar, ipaddr, port, nic } = param;
                this._json_param.TCP = { command: '', commandRef: '', hexCommand: '', endchar: CsxTrailType.NA, ipaddr: '', port: 23, nic: 'eth0' };
                if (typeof command === 'string')
                    this._json_param.TCP.command = command.slice();
                if (typeof commandRef === 'string')
                    this._json_param.TCP.commandRef = commandRef.slice();
                if (typeof hexCommand === 'string')
                    this._json_param.TCP.hexCommand = hexCommand.slice();
                if (typeof nic === 'string')
                    this._json_param.TCP.nic = nic.slice();
                if (typeof endchar === 'number')
                    this._json_param.TCP.endchar = CYP_TRAIL_MAP_REVERSE[endchar];
                if (typeof ipaddr === 'string')
                    this._json_param.TCP.ipaddr = ipaddr.slice();
                if (typeof port === 'number')
                    this._json_param.TCP.port = port;
            } else if (type === 'RS232Action') {
                const { command, commandRef, hexCommand, endchar } = param;
                this._json_param.Control = { command: '', commandRef: '', hexCommand: '', endchar: CsxTrailType.NA };
                if (typeof commandRef === 'string')
                    this._json_param.Control.commandRef = commandRef.slice();
                if (typeof command === 'string')
                    this._json_param.Control.command = command.slice();
                if (typeof hexCommand === 'string')
                    this._json_param.Control.hexCommand = hexCommand.slice();
                if (typeof endchar === 'number')
                    this._json_param.Control.endchar = CYP_TRAIL_MAP_REVERSE[endchar];
            } else if (type === 'UDPAction') {
                const { command, commandRef, hexCommand, endchar, ipaddr, port, nic } = param;
                this._json_param.UDP = { command: '', commandRef: '', hexCommand: '', endchar: CsxTrailType.NA, ipaddr: '', port: 23, nic: 'eth0' };
                if (typeof commandRef === 'string')
                    this._json_param.UDP.commandRef = commandRef.slice();
                if (typeof command === 'string')
                    this._json_param.UDP.command = command.slice();
                if (typeof hexCommand === 'string')
                    this._json_param.UDP.hexCommand = hexCommand.slice();
                if (typeof nic === 'string')
                    this._json_param.UDP.nic = nic.slice();
                if (typeof endchar === 'number')
                    this._json_param.UDP.endchar = CYP_TRAIL_MAP_REVERSE[endchar];
                if (typeof ipaddr === 'string')
                    this._json_param.UDP.ipaddr = ipaddr.slice();
                if (typeof port === 'number')
                    this._json_param.UDP.port = port;
            }
        }
    }
    // toLines = (): [string, string, string, string] => [this.ID, this._type.toString(), this.PARAM, `${this.POSITION.x},${this.POSITION.y}`];
    toJson = (): NodeJson => {
        return {
            id: this.ID,
            type: this._type,
            param: CsxActionParameterToJson(this.PARAM),
            coordinate: { ...this.POSITION },
        };
    }
}

type Old_NodeJson = {
    id: string;
    type: number;
    param: string;
    coordinate: { x: number; y: number };
}

export type Old_CsxAutomationJson = {
    name: string;
    // enabled: boolean;
    events: Array<Old_NodeJson>;
    logics: Array<Old_NodeJson>;
    actions: Array<Old_NodeJson>;
    edges: Array<{
        fromNode: string;
        toNode: string;
    }>;
}

function Old_isNodeJson(x: any): x is Old_NodeJson {
    return (x && (typeof x.id === 'string') && (typeof x.type === 'number') && (typeof x.param === 'string')
        && (x.coordinate && (typeof x.coordinate.x === 'number') && (typeof x.coordinate.y === 'number')));
}

export function Old_isCsxAutomationJson(x: any): x is Old_CsxAutomationJson {
    // const yes: boolean = ((typeof x.name === 'string') && (typeof x.enabled === 'boolean') 
    const yes: boolean = (x && (typeof x.name === 'string')
        && Array.isArray(x.events) && Array.isArray(x.logics) && Array.isArray(x.actions) && Array.isArray(x.edges));
    if (yes) {
        for (let i = 0; i < x.events.length; i++) { if (!Old_isNodeJson(x.events[i])) return false; }
        for (let i = 0; i < x.logics.length; i++) { if (!Old_isNodeJson(x.logics[i])) return false; }
        for (let i = 0; i < x.actions.length; i++) { if (!Old_isNodeJson(x.actions[i])) return false; }
        for (let i = 0; i < x.edges.length; i++) { if (typeof x.edges[i].fromNode !== 'string' || typeof x.edges[i].toNode !== 'string') return false; }
    }
    return yes;
}

export type CsxDeviceSnapshotJson = {
    id: string;
    name: string;
    taskDescription: string;
}

export function isCsxDeviceSnapshotJson(x: any): x is CsxDeviceSnapshotJson {
    let yes: boolean = (x && (typeof x.id === 'string') && (typeof x.taskDescription === 'string'));
    if (x.productName) {
        yes = (typeof x.productName === 'string')
    }
    return yes;
}

type NodeJson = {
    id: string;
    type: number;
    param: any;
    coordinate: { x: number; y: number };
}

export type CsxAutomationJson = {
    name: string;
    icon?: string;
    enabled?: boolean;
    events: Array<NodeJson>;
    logics: Array<NodeJson>;
    actions: Array<NodeJson>;
    edges: Array<{
        fromNode: string;
        toNode: string;
    }>;
    cypDeviceSnapshot?: { [ did: string ]: CsxDeviceSnapshotJson };
}

function isNodeJson(x: any): x is NodeJson {
    return (x && (typeof x.id === 'string') && (typeof x.type === 'number') && (typeof x.param === 'object')
        && (x.coordinate && (typeof x.coordinate.x === 'number') && (typeof x.coordinate.y === 'number')));
}

export function isCsxAutomationJson(x: any): x is CsxAutomationJson {
    const yes: boolean = (x && (typeof x.name === 'string')
        && Array.isArray(x.events) && Array.isArray(x.logics) && Array.isArray(x.actions) && Array.isArray(x.edges));
    if (yes) {
        if (typeof x.enable !== 'undefined' && typeof x.enable !== 'boolean')
            return false;
        if (typeof x.icon !== 'undefined' && typeof x.icon !== 'string')
            return false;
        for (let i = 0; i < x.events.length; i++) { if (!isNodeJson(x.events[i])) return false; }
        for (let i = 0; i < x.logics.length; i++) { if (!isNodeJson(x.logics[i])) return false; }
        for (let i = 0; i < x.actions.length; i++) { if (!isNodeJson(x.actions[i])) return false; }
        for (let i = 0; i < x.edges.length; i++) { if (typeof x.edges[i].fromNode !== 'string' || typeof x.edges[i].toNode !== 'string') return false; }
        if (x.cypDeviceSnapshot) {
            const device_list = Object.keys(x.cypDeviceSnapshot);
            for (let i = 0; i < device_list.length; i++) {
                if (!isCsxDeviceSnapshotJson(x.cypDeviceSnapshot[device_list[i]]))
                    return false;
            }
        }
    }
    return yes;
}

export class CsxAutomation {
    protected _id: string;
    protected _name: string = 'Unknown Automation';
    protected _icon?: string;
    protected _enabled?: boolean;
    protected _event_dict: { [s: string]: CsxEventNode } = {};
    protected _logic_dict: { [s: string]: CsxLogicNode } = {};
    protected _action_dict: { [s: string]: CsxActionNode } = {};
    protected _edge_arr: Array<CsxBasicEdge> = [];
    protected _event_cnt: number = 0;
    protected _logic_cnt: number = 0;
    protected _action_cnt: number = 0;
    protected _edge_cnt: number = 0;
    // protected _device_snapshot: { [did: string]: CsxDeviceSnapshotJson } = {};
    constructor(id: string, parse: any) {
        this._id = id;
        // console.log('isCsxAutomationJson(parse) :>> ', isCsxAutomationJson(parse));
        if (isCsxAutomationJson(parse)) {
            this._name = parse.name;
            this._enabled = parse.enabled;
            this._icon = parse.icon;
            this._event_dict = {};
            this._logic_dict = {};
            this._action_dict = {};
            this._edge_arr = parse.edges.map(edge => ({ from: edge.fromNode, to: edge.toNode }));
            this._event_cnt = parse.events.length;
            this._logic_cnt = parse.logics.length;
            this._action_cnt = parse.actions.length;
            this._edge_cnt = this._edge_arr.length;
            for (let i = 0; i < parse.events.length; i++) {
                const cur = parse.events[i];
                this._event_dict[cur.id] = new CsxEventNode(cur);
                // this._event_dict[cur.id].setJsonParam(CsxEventTypeList[cur.type], cur.param);
            }
            for (let i = 0; i < parse.logics.length; i++) {
                const cur = parse.logics[i];
                this._logic_dict[cur.id] = new CsxLogicNode(cur);
                // this._logic_dict[cur.id].setJsonParam(CsxLogicTypeList[cur.type], cur.param);
            }
            for (let i = 0; i < parse.actions.length; i++) {
                const cur = parse.actions[i];
                this._action_dict[cur.id] = new CsxActionNode(cur);
                // this._action_dict[cur.id].setJsonParam(CsxActionTypeList[cur.type], cur.param);
            }
            // if (parse.cypDeviceSnapshot) {
            //     this._device_snapshot = parse.cypDeviceSnapshot;
            // }
        } else if (Old_isCsxAutomationJson(parse)) {
            // console.log('Old_isCsxAutomationJson');
            // const CYP_TRAIL_MAP: { [s: string]: string } = { 1: 'N/A', 2: 'CR', 3: 'LF', 4: 'CR/LF', 5: 'Space', 6: 'STX', 7: 'ETX' };
            this._name = parse.name;
            // this._enabled = parse.enabled;
            this._event_dict = {};
            this._logic_dict = {};
            this._action_dict = {};
            this._edge_arr = parse.edges.map(edge => ({ from: edge.fromNode, to: edge.toNode }));
            this._event_cnt = parse.events.length;
            this._logic_cnt = parse.logics.length;
            this._action_cnt = parse.actions.length;
            this._edge_cnt = this._edge_arr.length;
            for (let i = 0; i < parse.events.length; i++) {
                const cur = parse.events[i];
                const rebuild_event = new CsxEventNode(null);
                rebuild_event.Old_construct(cur);
                this._event_dict[rebuild_event.ID] = rebuild_event;
            }
            for (let i = 0; i < parse.logics.length; i++) {
                const cur = parse.logics[i];
                const rebuild_logic = new CsxLogicNode(null);
                rebuild_logic.Old_construct(cur);
                this._logic_dict[rebuild_logic.ID] = rebuild_logic;
            }
            for (let i = 0; i < parse.actions.length; i++) {
                const cur = parse.actions[i];
                const rebuild_action = new CsxActionNode(null);
                rebuild_action.Old_construct(cur);
                this._action_dict[rebuild_action.ID] = rebuild_action;
            }
        }
    }
    get ID() { return this._id.slice(); }
    set ID(value: string) { this._id = value.slice(); }
    get NAME() { return this._name.slice(); }
    set NAME(v: string) { this._name = v.slice(); }
    get ICON() { return (this._icon) ? this._icon.slice() : undefined; }
    set ICON(v: string | undefined) { this._icon = (v) ? v.slice() : undefined; }
    get ACTIVE() { return Boolean(this._enabled); }
    set ACTIVE(v: boolean) { this._enabled = v; }
    get EVENT_CNT() { return Number(this._event_cnt); }
    get LOGIC_CNT() { return Number(this._logic_cnt); }
    get ACTION_CNT() { return Number(this._action_cnt); }
    get EDGE_CNT() { return Number(this._edge_cnt); }
    get EVENT_SET() { return new Set(Object.keys(this._event_dict)); }
    get LOGIC_SET() { return new Set(Object.keys(this._logic_dict)); }
    get ACTION_SET() { return new Set(Object.keys(this._action_dict)); }
    get EDGES() { return this._edge_arr.slice(); }
    get FILENAME() {
        const time = new Date().getTime();
        return `automation-${CsxUtil.mapFilename(this.NAME)}-${time}.auto.json`;
    }
    // get DEV_SNAPSHOT_SET() { return new Set(Object.keys(this._device_snapshot)); }
    getEvent = (event_id: string): CsxEventNode | undefined => { return this._event_dict[event_id]; }
    getLogic = (logic_id: string): CsxLogicNode | undefined => { return this._logic_dict[logic_id]; }
    getAction = (action_id: string): CsxActionNode | undefined => { return this._action_dict[action_id]; }
    getEdge = (edge_idx: number): CsxBasicEdge | undefined => { return this._edge_arr[edge_idx]; }
    getAllDevices = (): Set<string> => {
        const devs: Set<string> = new Set();
        const action_set = this.ACTION_SET;
        const event_set = this.EVENT_SET;
        
        action_set.forEach(nid => {
            const action = this.getAction(nid);
            
            if (action && action.TYPE === 'CYPAction') {
                const param = action.PARAM;
                const device = CsxUtil.nestedGet(param, 'CYPDevice.device');
                devs.add(device);
            }
        });
        event_set.forEach(eid => {
            const event = this.getEvent(eid);

            if (event && event.TYPE === 'CYPDeviceEvent') {
                const param = event.PARAM;
                const device = CsxUtil.nestedGet(param, 'CYPDevice.device');
                devs.add(device);
            }
        });

        return devs;
    }
    takeDeviceSnapshot = (): { [did: string]: CsxDeviceSnapshotJson } => {
        const action_set = this.ACTION_SET;
        const event_set = this.EVENT_SET;

        const snapshot: { [did: string]: CsxDeviceSnapshotJson } = {};
        action_set.forEach(nid => {
            const action = this.getAction(nid);

            if (action && action.TYPE === 'CYPAction') {
                const param = action.PARAM;
                const device = CsxUtil.nestedGet(param, 'CYPDevice.device');
                const command = CsxUtil.nestedGet(param, 'CYPDevice.command');

                if (typeof device === 'string' && typeof command === 'string') {
                    if (typeof snapshot[device] === 'undefined') {
                        const devinst = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(device) : undefined;

                        if (devinst) {
                            snapshot[device] = {
                                id: device,
                                name: devinst.NICKNAME,
                                taskDescription: `Send "${command}" to ${devinst.NICKNAME}`,
                            };
                        } else {
                            console.log(`[CsxAutomation:takeDeviceSnapshot] device not found in action automation:${this.NAME},device:${device}`);
                        }
                    }
                } else {
                    console.log(`[CsxAutomation:takeDeviceSnapshot] Action's device param abnormal. automation:${this.NAME},device:${device}`);
                }
            }
        });
        event_set.forEach(eid => {
            const event = this.getEvent(eid);

            if (event && event.TYPE === 'CYPDeviceEvent') {
                const param = event.PARAM;
                const device = CsxUtil.nestedGet(param, 'CYPDevice.device');
                const command = CsxUtil.nestedGet(param, 'CYPDevice.command');

                if (typeof device === 'string' && typeof command === 'string') {
                    if (typeof snapshot[device] === 'undefined') {
                        const devinst = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(device) : undefined;

                        if (devinst) {
                            snapshot[device] = {
                                id: device,
                                name: devinst.NICKNAME,
                                taskDescription: `Detect "${command}" event from ${devinst.NICKNAME}`,
                            };
                        } else {
                            // console.log(`[CsxAutomation:takeDeviceSnapshot] device not found in event automation:${this.NAME},device:${device}`);
                        }
                    }
                } else {
                    // console.log(`[CsxAutomation:takeDeviceSnapshot] Event's device param abnormal. automation:${this.NAME},device:${device}`);
                }
            }
        });
        return snapshot;
    }
    clone = (): CsxAutomation => { return new CsxAutomation(this.ID, this.toJson()); }
    getImageUrl = () => {
        let icon_format = `jpg`;
        let icon_url = `http://${((window.APP_DEV_MODE) ? DEV_TARGET_IP : window.location.host)}/image/${this.ICON}.${icon_format}`;
        if (window.FOCUS_GATEWAY) {
            const image_id = (this._icon) ? this._icon : `Scenario_${this.ID}`;
            const icon = window.FOCUS_GATEWAY.getIcon(image_id);
            icon_url = (icon) ? icon.URL : icon_url;
        }
        return icon_url;
    }
    toJson = (): CsxAutomationJson => {
        this.takeDeviceSnapshot();
        return {
            name: this.NAME,
            icon: this.ICON,
            enabled: this.ACTIVE,
            events: Array.from(this.EVENT_SET).map((event_id): NodeJson => this._event_dict[event_id].toJson()),
            logics: Array.from(this.LOGIC_SET).map((logic_id): NodeJson => this._logic_dict[logic_id].toJson()),
            actions: Array.from(this.ACTION_SET).map((action_id): NodeJson => this._action_dict[action_id].toJson()),
            edges: this.EDGES.map(edge => ({ fromNode: edge.from, toNode: edge.to })),
        };
    }
}

export type CsxAutomationSetJson = {
    list: Array<CsxAutomationJson>;
}

export function isCsxAutomationSetJson(x: any): x is CsxAutomationSetJson {
    const yes: boolean = x && Array.isArray(x.list);
    if (yes)
        for (let i = 0; i < x.list.length; i++) { if (!isCsxAutomationJson(x.list[i])) return false; }
    return yes;
}

export class CsxAutomationSet {
    protected _automation_dict: { [s: string]: CsxAutomation } = {};
    protected _automation_set: Set<string> = new Set();
    constructor(parse?: CsxAutomationSetJson) {
        if (parse) {
            parse.list.forEach((automation_json, idx) => {
                const automation = new CsxAutomation(idx.toString(), automation_json);
                this.add(automation);
            });
        }
    }
    get ID_SET(): Set<string> { return new Set(this._automation_set); }
    get SIZE(): number { return this._automation_set.size; }
    get SIZE_LIMIT(): number { return 256; }
    get FILENAME() {
        const time = new Date().getTime();
        if (window.FOCUS_GATEWAY)
            return `automation-backup-${CsxUtil.mapFilename(window.FOCUS_GATEWAY.NICKNAME)}-${time}.auto.grp.json`;
        else
            return `automation-backup-unknown-gateway-${time}.auto.grp.json`;
    }
    private _generate_random_id = (): string => {
        let random_id = Math.floor(Math.random() * 10000).toString();
        while (typeof this._automation_dict[random_id] !== 'undefined')
            random_id = Math.floor(Math.random() * 10000).toString();
        return random_id;
    }
    add = (automation: CsxAutomation) => {
        this._automation_dict[automation.ID] = automation;
        this._automation_set.add(automation.ID);
    }
    remove = (automation_id: string) => {
        this._automation_set.delete(automation_id);
        delete this._automation_dict[automation_id];
    }
    get = (automation_id: string): CsxAutomation | undefined => { return this._automation_dict[automation_id]; }
    newAutomation = (): CsxAutomation => {
        const new_automation_id = this._generate_random_id();
        const new_automation = new CsxAutomation(new_automation_id, null);
        return new_automation;
    }
    checkAutomationName = (name: string): string | undefined => {
        const automation_list = Array.from(this._automation_set);
        for (let i = 0; i < automation_list.length; i++) {
            const aid = automation_list[i];
            if (this._automation_dict[aid].NAME === name) {
                return aid;
            }
        }
        return undefined;
    }
    toJson = (): CsxAutomationSetJson => {
        const buf: CsxAutomationSetJson = { list: [] };
        this._automation_set.forEach(automation_id => {
            const automation = this.get(automation_id);
            if (automation)
                buf.list.push(automation.toJson());
        });
        return buf;
    }
}


export type CsxScenarioJson = {
    id?: string;
    name: string;
    enabled: boolean;
    actions: Array<NodeJson>;
    automations: Array<string>;
    triggers: Array<string>;
    automationSnapshot?: { [ aid: string ]: CsxAutomationJson };
    cypDeviceSnapshot?: { [ did: string ]: CsxDeviceSnapshotJson };
    image?: string;
    imageSnapshot?: CsxIconJson;
}

export function isCsxScenarioJson(x: any): x is CsxScenarioJson {
    const yes: boolean = (x && (typeof x.name === 'string') && (typeof x.enabled === 'boolean')
        && Array.isArray(x.actions) && Array.isArray(x.automations));
    if (yes) {
        for (let i = 0; i < x.actions.length; i++) { if (!isNodeJson(x.actions[i])) return false; }
        for (let i = 0; i < x.automations.length; i++) { if (typeof x.automations[i] !== 'string') return false; }
        for (let i = 0; i < x.triggers.length; i++) { if (typeof x.triggers[i] !== 'string') return false; }
        if (x.image) { if (typeof x.image !== 'string') { return false; } }
        if (x.imageSnapshot) { if (!isCsxIconJson(x.imageSnapshot)) return false; }
    }
    return yes;
}

export function Old_isCsxScenarioJson(x: any): x is CsxScenarioJson {
    const yes: boolean = ((typeof x.name === 'string') && (typeof x.enabled === 'boolean')
        && Array.isArray(x.actions) && Array.isArray(x.automations));
    if (yes) {
        for (let i = 0; i < x.actions.length; i++) { if (!Old_isNodeJson(x.actions[i])) return false; }
        for (let i = 0; i < x.automations.length; i++) { if (typeof x.automations[i] !== 'string') return false; }
        for (let i = 0; i < x.triggers.length; i++) { if (typeof x.triggers[i] !== 'string') return false; }
    }
    return yes;
}

export class CsxScenario {
    protected _id: string;
    protected _name: string = '';
    protected _enabled: boolean = false;
    protected _action_dict: { [s: string]: CsxActionNode } = {};
    protected _automation_set: Set<string> = new Set();
    protected _action_cnt: number = 0;
    protected _trigger_set: Set<string> = new Set();
    // protected _automation_snapshot: { [aid: string]: CsxAutomation } = {};
    // protected _device_snapshot: { [did: string]: CsxDeviceSnapshotJson } = {};
    protected _image_id?: string;
    constructor(id: string, parse: any) {
        this._id = id.slice();
        // this._image_id = `Scenario_${this._id}`;
        if (parse) {
            const { name, enabled, actions, automations, image } = parse;
            if (typeof name === 'string')
                this.NAME = name;
            if (typeof image === 'string')
                this.IMAGE_ID = image;
            if (typeof enabled === 'boolean')
                this.ACTIVE = enabled;
            if (Array.isArray(actions)) {
                actions.forEach(action_data => {
                    if (isNodeJson(action_data)) {
                        const new_action = new CsxActionNode(action_data);
                        this._action_dict[new_action.ID] = new_action;
                        this._action_cnt++;
                    }
                    if (Old_isNodeJson(action_data)) {
                        const new_action = new CsxActionNode(null);
                        new_action.Old_construct(action_data);
                        this._action_dict[new_action.ID] = new_action;
                        this._action_cnt++;
                    }
                });
            }
            if (Array.isArray(automations)) {
                automations.forEach(automation_id => { this._automation_set.add(automation_id); });
            }
        }
    }
    get ID() { return this._id.slice(); }
    set ID(value: string) { this._id = value.slice(); }
    get NAME() { return this._name.slice(); }
    set NAME(v: string) { this._name = v.slice(); }
    get ACTIVE() { return Boolean(this._enabled); }
    set ACTIVE(v: boolean) { this._enabled = v; }
    get ACTION_CNT() { return Object.keys(this._action_dict).length; }
    get AUTOMATION_CNT() { return this._automation_set.size; }
    get TRIGGER_CNT() { return this._trigger_set.size; }
    get ACTION_SET() { return new Set(Object.keys(this._action_dict)); }
    get ACTION_SIZE_LIMIT() { return 256; }
    get AUTOMATION_SET() { return new Set(this._automation_set); }
    get TRIGGER_SET() { return new Set(this._trigger_set); }
    // get DEV_SNAPSHOT_SET() { return new Set(Object.keys(this._device_snapshot)); }
    // get AUTOMATION_SNAPSHOT_SET() { return new Set(Object.keys(this._automation_snapshot)); }
    get FILENAME() {
        const time = new Date().getTime();
        return `scenario-${CsxUtil.mapFilename(this.NAME)}-${time}.scene.json`;
    }
    get IMAGE_ID(): string | undefined { return (this._image_id) ? this._image_id.slice() : undefined }
    set IMAGE_ID(s: string | undefined) { this._image_id = (s) ? s.slice() : undefined; }
    
    private _generate_random_id = (): string => {
        let random_id = Math.floor(Math.random() * 10000).toString();
        while (typeof this._action_dict[random_id] !== 'undefined')
            random_id = Math.floor(Math.random() * 10000).toString();
        return random_id;
    }
    clone = (): CsxScenario => { return new CsxScenario(this.ID, this.toJson()); }
    getAction = (action_id: string): CsxActionNode | undefined => { return this._action_dict[action_id]; }
    setAction = (action: CsxActionNode) => { this._action_dict[action.ID] = action; }
    removeAction = (action_id: string) => { delete this._action_dict[action_id]; }
    newAction = (): CsxActionNode => {
        const new_action_id = this._generate_random_id();
        const new_action = new CsxActionNode({ id: new_action_id });
        return new_action;
    }
    addAutomation = (automation_id: string) => { this._automation_set.add(automation_id); }
    removeAutomation = (automation_id: string) => { this._automation_set.delete(automation_id); }
    addTrigger = (trigger_id: string) => { this._trigger_set.add(trigger_id); }
    removeTrigger = (trigger_id: string) => { this._trigger_set.delete(trigger_id); }
    takeAutomationSnapshot = (): { [aid: string]: CsxAutomationJson } => {
        const snapshot: { [aid: string]: CsxAutomationJson } = {};
        this._automation_set.forEach(aid => {
            if (window.FOCUS_GATEWAY) {
                const automation = window.FOCUS_GATEWAY.getAutomation(aid);
                if (automation) {
                    snapshot[aid] = automation.toJson();
                }
            }
        });
        return snapshot;
    }
    getAllDevices = (): Set<string> => {
        const devs: Set<string> = new Set();
        const action_set = this.ACTION_SET;
        
        action_set.forEach(nid => {
            const action = this.getAction(nid);

            if (action && action.TYPE === 'CYPAction') {
                const param = action.PARAM;
                const device = CsxUtil.nestedGet(param, 'CYPDevice.device');
                devs.add(device);
            }
        });
        this._automation_set.forEach(aid => {
            if (window.FOCUS_GATEWAY) {
                const automation = window.FOCUS_GATEWAY.getAutomation(aid);
                if (automation)
                    automation.getAllDevices().forEach(did => { devs.add(did); });
            }
        });

        return devs;
    }
    takeDeviceSnapshot = (): { [did: string]: CsxDeviceSnapshotJson } => {
        const action_set = this.ACTION_SET;
        const snapshot: { [did: string]: CsxDeviceSnapshotJson } = {};

        action_set.forEach(nid => {
            const action = this.getAction(nid);

            if (action && action.TYPE === 'CYPAction') {
                const param = action.PARAM;
                const device = CsxUtil.nestedGet(param, 'CYPDevice.device');
                const command = CsxUtil.nestedGet(param, 'CYPDevice.command');

                if (typeof device === 'string' && typeof command === 'string') {
                    if (typeof snapshot[device] === 'undefined') {
                        const devinst = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(device) : undefined;
                        if (devinst) {
                            snapshot[device] = {
                                id: device,
                                name: devinst.NICKNAME,
                                taskDescription: `Send "${command}" to ${devinst.NICKNAME}`,
                            };
                        } else {
                            // console.log(`[CsxScenario:takeDeviceSnapshot] device not found scene:${this.NAME},device:${device}`);
                        }
                    }
                } else {
                    // console.log(`[CsxScenario:takeDeviceSnapshot] Action's device param abnormal scene:${this.NAME},device:${device}`);
                }
            }
        });
        
        this._automation_set.forEach(aid => {
            if (window.FOCUS_GATEWAY) {
                const automation = window.FOCUS_GATEWAY.getAutomation(aid);
                if (automation) {
                    const a_devsnapshot = automation.takeDeviceSnapshot();
                    Object.keys(a_devsnapshot).forEach(did => {
                        if (typeof snapshot[did] === 'undefined') {
                            snapshot[did] = a_devsnapshot[did];
                        }
                    });
                }
            }
        });

        return snapshot;
    }
    getImageUrl = () => {
        let icon_format = `jpg`;
        let icon_url = `http://${((window.APP_DEV_MODE) ? DEV_TARGET_IP : window.location.host)}/image/${this._image_id}.${icon_format}`;
        if (window.FOCUS_GATEWAY) {
            const image_id = (this._image_id) ? this._image_id : `Scenario_${this.ID}`;
            const icon = window.FOCUS_GATEWAY.getIcon(image_id);
            icon_url = (icon) ? icon.URL : icon_url;
        }
        return icon_url;
    }
    getImageBase64 = (): Promise<string> => {
        return new Promise((resolve) => {
            const image = new CsxUtil.CsxFileGrabber(this.getImageUrl() + `?tag=${new Date().getTime()}`);

            image.load({
                allowContentType: ['image/jpeg', 'image/svg+xml', 'image/bmp', 'image/gif', 'image/png']
            }).then(err => {
                if (!err) {
                    resolve(image.base64);
                } else {
                    resolve('');
                }
            }).catch(_ => {
                resolve('');
            });
        });
    }
    getImageText = (): Promise<string> => {
        return new Promise((resolve) => {
            const image = new CsxUtil.CsxFileGrabber(this.getImageUrl() + `?tag=${new Date().getTime()}`);

            image.load({
                allowContentType: ['image/jpeg', 'image/svg+xml', 'image/bmp', 'image/gif', 'image/png']
            }).then(err => {
                if (!err) {
                    resolve(image.text);
                } else {
                    resolve('');
                }
            }).catch(_ => {
                resolve('');
            });
        });
    }
    loadJsonImage = (json: CsxScenarioJson): Promise<boolean> => {
        return new Promise(async (resolve) => {
            if (window.FOCUS_GATEWAY) {
                const image_id = (this._image_id) ? this._image_id : `Scenario_${this.ID}`;
                const icon = window.FOCUS_GATEWAY.getIcon(image_id);
                const b64img = await this.getImageBase64();
                // console.log('b64img :>> ', b64img);
                if (icon) {
                    if (b64img.length > 0) {
                        json.image = icon.ID;
                        json.imageSnapshot = {
                            id: icon.ID,
                            format: icon.FORMAT,
                            base64: b64img,
                            name: icon.NAME,
                            isLiked: icon.LIKED,
                        };
                    }
                } else {
                    if (b64img.length > 0) {
                        json.image = image_id;
                        json.imageSnapshot = {
                            id: image_id,
                            format: 'jpg',
                            base64: b64img,
                            name: `${this.NAME}-icon`,
                            isLiked: false,
                        };
                    }
                }
                resolve(true);
            } else {
                resolve(false);
            }
        });
    }
    toJson = (): CsxScenarioJson => {
        return ({
            id: this.ID,
            name: this.NAME,
            enabled: this.ACTIVE,
            actions: Array.from(this.ACTION_SET).map((action_id): NodeJson => {
                const node = this._action_dict[action_id];
                return {
                    id: node.ID,
                    type: csxActionTypeIndex(node.TYPE),
                    param: CsxActionParameterToJson(node.PARAM),
                    coordinate: { x: node.POSITION.x, y: node.POSITION.y }
                }
            }),
            automations: Array.from(this.AUTOMATION_SET),
            triggers: Array.from(this.TRIGGER_SET),
            image: this.IMAGE_ID,
        });
    }
}

export type CsxScenarioSetJson = {
    list: Array<CsxScenarioJson>;
}

export function isCsxScenarioSetJson(x: any): x is CsxScenarioSetJson {
    const yes: boolean = x && Array.isArray(x.list);
    if (yes)
        for (let i = 0; i < x.list.length; i++) { if (!isCsxScenarioJson(x.list[i])) return false; }
    return yes;
}

export class CsxScenarioSet {
    protected _scenario_dict: { [s: string]: CsxScenario } = {};
    protected _scenario_set: Set<string> = new Set();
    constructor(parse?: CsxScenarioSetJson) {
        if (parse) {
            parse.list.forEach((scenario_json, idx) => {
                const scenario = new CsxScenario(idx.toString(), scenario_json);
                this.add(scenario);
            });
        }
    }
    get ID_SET(): Set<string> { return new Set(this._scenario_set); }
    get SIZE(): number { return this._scenario_set.size; }
    get SIZE_LIMIT(): number { return 256; }
    get FILENAME() {
        const time = new Date().getTime();
        if (window.FOCUS_GATEWAY)
            return `scenario-backup-${CsxUtil.mapFilename(window.FOCUS_GATEWAY.NICKNAME)}-${time}.scene.grp.json`;
        else
            return `scenario-backup-unknown-gateway-${time}.scene.grp.json`;
    }
    private _generate_random_id = (): string => {
        let random_id = Math.floor(Math.random() * 10000).toString();
        while (typeof this._scenario_dict[random_id] !== 'undefined')
            random_id = Math.floor(Math.random() * 10000).toString();
        return random_id;
    }
    add = (scenario: CsxScenario) => {
        this._scenario_dict[scenario.ID] = scenario;
        this._scenario_set.add(scenario.ID);
    }
    remove = (scenario_id: string) => {
        this._scenario_set.delete(scenario_id);
        delete this._scenario_dict[scenario_id];
    }
    get = (scenario_id: string): CsxScenario | undefined => { return this._scenario_dict[scenario_id]; }
    newScenario = (): CsxScenario => {
        const new_scenario_id = this._generate_random_id();
        const new_scenario = new CsxScenario(new_scenario_id, '');
        return new_scenario;
    }
    checkScenarioName = (name: string): string | undefined => {
        const scenario_list = Array.from(this._scenario_set);
        for (let i = 0; i < scenario_list.length; i++) {
            const sid = scenario_list[i];
            if (this._scenario_dict[sid].NAME === name)
                return sid;
        }
        return undefined;
    }
    toJson = (): CsxScenarioSetJson => {
        const buf: CsxScenarioSetJson = { list: [] };
        this._scenario_set.forEach((scenario_id) => {
            const scenario = this.get(scenario_id);
            if (scenario)
                buf.list.push(scenario.toJson());
        });
        return (buf);
        // return new Promise((resolve, reject) => {
        // });
    }
}

/**
 * Home Page Panel Function
 * --------------------------
 */
export declare type MissionDescriptionJson = {
    deviceList: Array<string>;
    commandExpress: string;
}
export function isMissionDescriptionJson(x: any): x is MissionDescriptionJson {
    if (x && typeof x.commandExpress === 'string' && Array.isArray(x.deviceList)) {
        for (let i = 0; i < x.deviceList.length; i++) { if (typeof x.deviceList[i] !== 'string') return false; }
        return true;
    } else {
        return false;
    }
}
export declare type PanelConfigurationJson = {
    video: { mission: MissionDescriptionJson };
    volume: { mission: MissionDescriptionJson };
    light: { mission: MissionDescriptionJson };
}
export function isPanelConfigurationJson(x: any): x is PanelConfigurationJson {
    return (x && x.video && x.volume && x.light &&
        isMissionDescriptionJson(x.video.mission) &&
        isMissionDescriptionJson(x.volume.mission) &&
        isMissionDescriptionJson(x.light.mission));
}
export function clonePanelConfiguration(x: PanelConfigurationJson): PanelConfigurationJson {
    const new_panel: PanelConfigurationJson = {
        video: { mission: { deviceList: [], commandExpress: '' } },
        volume: { mission: { deviceList: [], commandExpress: '' } },
        light: { mission: { deviceList: [], commandExpress: '' } },
    };
    if (x) {
        new_panel.video.mission.commandExpress = x.video.mission.commandExpress.slice();
        new_panel.video.mission.deviceList = x.video.mission.deviceList.slice();
        new_panel.volume.mission.commandExpress = x.volume.mission.commandExpress.slice();
        new_panel.volume.mission.deviceList = x.volume.mission.deviceList.slice();
        new_panel.light.mission.commandExpress = x.light.mission.commandExpress.slice();
        new_panel.light.mission.deviceList = x.light.mission.deviceList.slice();
    }
    return new_panel;
}

export declare type CsxRoomJson = {
    scenes: Array<string>; // scenario_id
    fastAutomations?: Array<string>; // automation_id
    commands?: Array<CsxFastActionJson>;
    panel: { [scenario_id: string]: PanelConfigurationJson };
    // images: { [scene_id: string]: string }; // images url on CDPS-CS9
    id: string;
    name: string;
    scenarioSnapshot?: { [sid: string]: CsxScenarioJson };
    automationSnapshot?: { [aid: string]: CsxAutomationJson };
    cypDeviceSnapshot?: { [did: string]: CsxDeviceSnapshotJson };
    imageSnapshot?: { [iid: string]: CsxIconJson };
}

export function isCsxRoomJson(x: any): x is CsxRoomJson {
    if (x && Array.isArray(x.scenes) && x.panel && (typeof x.name === 'string') && (typeof x.id === 'string')) {
        const scenario_list = Object.keys(x.panel);
        // const image_list = Object.keys(x.images);
        for (let i = 0; i < scenario_list.length; i++) { if (!isPanelConfigurationJson(x.panel[scenario_list[i]])) return false; }
        for (let i = 0; i < x.scenes.length; i++) { if (typeof x.scenes[i] !== 'string') return false; }
        // for (let i = 0; i < image_list.length; i++) { if (typeof x.images[image_list[i]] !== 'string') return false; }
        if (x.scenarioSnapshot) {
            const snapshot_sid_list = Object.keys(x.scenarioSnapshot);
            for (let i = 0; i < snapshot_sid_list.length; i++) {
                const sid = snapshot_sid_list[i];
                const object = x.scenarioSnapshot[sid];
                if (!isCsxScenarioJson(object)) { 
                    return false;
                }
            }
        }
        if (x.cypDeviceSnapshot) {
            const snapshot_did_list = Object.keys(x.cypDeviceSnapshot);
            for (let i = 0; i < snapshot_did_list.length; i++) {
                const did = snapshot_did_list[i];
                const object = x.cypDeviceSnapshot[did];
                if (!isCsxDeviceSnapshotJson(object)) { 
                    return false;
                }
            }
        }
        if (x.imageSnapshot) {
            const snapshot_iid_list = Object.keys(x.imageSnapshot);
            for (let i = 0; i < snapshot_iid_list.length; i++) {
                const iid = snapshot_iid_list[i];
                const object = x.snapshot_iid_list[iid];
                if (!isCsxIconJson(object)) { 
                    return false;
                }
            }
        }
        return true;
    } else {
        return false;
    }
}

// function isJson(str: string) {
//     try {
//         JSON.parse(str);
//     } catch (e) {
//         return false;
//     }
//     return true;
// }

export type CsxFastActionJson = NodeJson & { icon?: string };

function isFastActionJson(x: any): x is CsxFastActionJson {
    return isNodeJson(x);
}

export class CsxFastAction extends CsxActionNode {
    icon: string | undefined;
    // name: string;

    get ICON() { return this.icon; }
    set ICON(icon_id: string | undefined) { this.icon = (icon_id) ? icon_id.slice() : undefined; }
    get NAME() { return this._json_param.name; }
    set NAME(str: string) { this._json_param.name = str.slice(); }
    constructor(data: any) {
        super(data);
        if (data) {
            const { icon } = data;
            if (typeof icon === 'string') {
                this.ICON = icon;
            }
        }
    }

    getImageUrl = () => {
        let icon_format = `jpg`;
        let icon_url = `http://${((window.APP_DEV_MODE) ? DEV_TARGET_IP : window.location.host)}/image/${this.icon}.${icon_format}`;
        if (window.FOCUS_GATEWAY) {
            const icon_id = this.ICON;
            const image_id = (icon_id) ? icon_id : `Scenario_${this.ID}`;
            const icon = window.FOCUS_GATEWAY.getIcon(image_id);
            icon_url = (icon) ? icon.URL : icon_url;
        }
        return icon_url;
    }

    toFastActionJson = (): CsxFastActionJson => {
        const parentJson = this.toJson();
        const nodeJson: CsxFastActionJson = { ...parentJson };
        nodeJson.icon = this.icon;
        return nodeJson;
    }

    clone = (): CsxFastAction => { return (new CsxFastAction(this.toFastActionJson())); }
}

export class CsxRoom {
    private _id: string;
    private _scenes: Array<string> = []; // scenario_id
    private _fast_automations: Array<string> = [];
    private _cmds: Array<CsxFastAction> = [];
    private _panel: { [id: string]: PanelConfigurationJson } = {};
    // private _scenario_snapshot: { [sid: string]: CsxScenario } = {};
    // private _device_snapshot: { [sid: string]: CsxDeviceSnapshotJson } = {};
    // private _image: { [s: string]: string };
    private _name = 'Unnamed Room';
    constructor(id: string, parse: any) {
        this._id = id;
        if (parse) {
            const { name, scenes, panel, fastAutomations, commands } = parse
            if (typeof name === 'string')
                this._name = name.slice();
            if (Array.isArray(scenes))
                this._scenes = CsxUtil.nestedAssign(scenes);
            Object.keys(panel).forEach(scenario_id => { this._panel[scenario_id] = clonePanelConfiguration(panel[scenario_id]); });

            if (Array.isArray(fastAutomations)) {
                this._fast_automations = CsxUtil.nestedAssign(fastAutomations);
            }
            if (Array.isArray(commands)) {
                for (let i = 0; i < commands.length; i++) {
                    const cmd = commands[i];
                    if (isFastActionJson(cmd)) {
                        const newcmd = new CsxFastAction(cmd);
                        this._cmds.push(newcmd);
                    }
                }
            }
            // if (scenarioSnapshot) {
            //     Object.keys(scenarioSnapshot).forEach(sid => {
            //         const sjson = scenarioSnapshot[sid];
            //         if (isCsxScenarioJson(sjson)) {
            //             this._scenario_snapshot[sid] = new CsxScenario(sid, sjson);
            //         }
            //     });
            // }
            // if (cypDeviceSnapshot) {
            //     Object.keys(cypDeviceSnapshot).forEach(did => {
            //         const djson = cypDeviceSnapshot[did];
            //         if (isCsxDeviceSnapshotJson(djson)) {
            //             this._device_snapshot[did] = djson;
            //         }
            //     });
            // }
        }
    }
    get ID(): string { return this._id.slice(); }
    get NAME(): string { return this._name.slice(); }
    set NAME(n: string) { this._name = n.slice(); }
    // get SCENARIO_SNAPSHOT_SET() { return new Set(Object.keys(this._scenario_snapshot)); }
    // get DEV_SNAPSHOT_SET() { return new Set(Object.keys(this._device_snapshot)); }
    get FILENAME() {
        const time = new Date().getTime();
        return `room-${CsxUtil.mapFilename(this.NAME)}-${time}.room.json`;
    }
    get scenes(): Array<string> { return this._scenes.slice(); }
    set scenes(s: Array<string>) { this._scenes = s.slice(); }
    get fastAutomations(): Array<string> { return this._fast_automations.slice(); }
    set fastAutomations(s: Array<string>) { this._fast_automations = s.slice(); }
    get cmds(): Array<CsxFastAction> { return this._cmds.map(node => (new CsxFastAction(node.toFastActionJson()))); }
    set cmds(s: Array<CsxFastAction>) { this._cmds = s; }
    get clone(): CsxRoom {
        const new_room = new CsxRoom(this.ID, this.toJson());
        return new_room;
    }
    private _generate_random_id = (): string => {
        let random_id = Math.floor(Math.random() * 10000).toString();
        while (typeof this.getCommandByID(random_id) !== 'undefined')
            random_id = Math.floor(Math.random() * 10000).toString();
        return random_id;
    }
    addScenario = (scenario_id: string) => { if (this._scenes.indexOf(scenario_id) < 0) this._scenes.push(scenario_id); }
    removeScenario = (scenario_id: string) => {
        const scene_idx = this._scenes.indexOf(scenario_id);
        if (scene_idx >= 0)
            this._scenes.splice(scene_idx, 1);
    }
    addFastAutomation = (automation_id: string) => { if (this._fast_automations.indexOf(automation_id) < 0) this._fast_automations.push(automation_id); }
    removeFastAutomation = (automation_id: string) => {
        const automation_idx = this._fast_automations.indexOf(automation_id);
        if (automation_idx >= 0)
            this._fast_automations.splice(automation_idx, 1);
    }
    newCommand = () => {
        return new CsxFastAction({ id: this._generate_random_id() });
    }
    setCommond = (tar: CsxFastAction) => {
        let is_new = true;
        for (let i = 0; i < this._cmds.length; i++) {
            const cmd = this._cmds[i];
            if (cmd.ID === tar.ID) {
                this._cmds[i] = tar.clone();
                is_new = false;
                break;
            }
        }
        if (is_new) {
            this._cmds.push(tar); 
        }
    }
    removeCommand = (cmd_id: string) => {
        for (let i = 0; i < this._cmds.length; i++) {
            const cmd = this._cmds[i];
            if (cmd.ID === cmd_id) {
                this._cmds.splice(i, 1);
                break;
            }
        }
    }
    getCommandByID = (cmd_id: string) => {
        for (let i = 0; i < this._cmds.length; i++) {
            const cmd = this._cmds[i];
            if (cmd.ID === cmd_id) {
                return cmd;
            }
        }
        return undefined;
    }
    getScenarioPanel = (scenario_id: string): PanelConfigurationJson | undefined => {
        return clonePanelConfiguration(this._panel[scenario_id]);
    }
    setScenarioPanel = (scenario_id: string, panelJson: PanelConfigurationJson) => { this._panel[scenario_id] = clonePanelConfiguration(panelJson); }
    getScenarioImages = (scenario_id: string): string | undefined => {
        if (window.FOCUS_GATEWAY) {
            const scenario = window.FOCUS_GATEWAY.getScenario(scenario_id);
            return (scenario) ? scenario.getImageUrl() : undefined;
        } else {
            return undefined;
        }
    }
    takeScenarioSnapshot = (): { [sid: string]: CsxScenarioJson } => {
        const snapshot: { [sid: string]: CsxScenarioJson } = {};
        this._scenes.forEach(sid => {
            if (window.FOCUS_GATEWAY) {
                const scenario = window.FOCUS_GATEWAY.getScenario(sid);
                if (scenario) {
                    snapshot[sid] = scenario.toJson();
                } else {
                    console.log('scenario not found. id = ', sid);
                }
            }
        });
        return snapshot;
    }
    getAllDevices = (): Set<string> => {
        const devs: Set<string> = new Set();

        Object.keys(this._panel).forEach(pid => {
            const panel = this._panel[pid];
            panel.video.mission.deviceList.forEach(did => { devs.add(did); });
            panel.volume.mission.deviceList.forEach(did => { devs.add(did); });
        });
        /* Merge with children scenarios */
        this._scenes.forEach(sid => {
            if (window.FOCUS_GATEWAY) {
                const scenario = window.FOCUS_GATEWAY.getScenario(sid);
                if (scenario) {
                    scenario.getAllDevices().forEach(did => { devs.add(did); });
                }
            }
        });
        /* Merge with children fast automations */
        this._fast_automations.forEach(aid => {
            if (window.FOCUS_GATEWAY) {
                const automation = window.FOCUS_GATEWAY.getAutomation(aid);
                if (automation)
                    automation.getAllDevices().forEach(did => { devs.add(did); });
            }
        });

        return devs;
    }
    takeDeviceSnapshot = (): { [did: string]: CsxDeviceSnapshotJson } => {
        const snapshot: { [did: string]: CsxDeviceSnapshotJson } = {};
        Object.keys(this._panel).forEach(pid => {
            const panel = this._panel[pid];

            panel.video.mission.deviceList.forEach(did => {
                if (typeof snapshot[did] === 'undefined') {
                    const devinst = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(did) : undefined;

                    if (devinst) {
                        snapshot[did] = {
                            id: did,
                            name: devinst.NICKNAME,
                            taskDescription: `Handle source switch for ${devinst.NICKNAME}`,
                        };
                    } else {
                        console.log(`[CsxRoom:takeDeviceSnapshot] device not found room:${this.NAME},device:${did}`);
                    }
                }
            });
            panel.volume.mission.deviceList.forEach(did => {
                if (typeof snapshot[did] === 'undefined') {
                    const devinst = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(did) : undefined;

                    if (devinst) {
                        snapshot[did] = {
                            id: did,
                            name: devinst.NICKNAME,
                            taskDescription: `Handle volume for ${devinst.NICKNAME}`,
                        };
                    } else {
                        // console.log(`[CsxRoom:takeDeviceSnapshot] device not found room:${this.NAME},device:${did}`);
                    }
                }
            });
        });

        /* Merge with children scenarios */
        this._scenes.forEach(sid => {
            if (window.FOCUS_GATEWAY) {
                const scenario = window.FOCUS_GATEWAY.getScenario(sid);
                if (scenario) {
                    const s_devsnapshot = scenario.takeDeviceSnapshot();
                    Object.keys(s_devsnapshot).forEach(did => {
                        if (typeof snapshot[did] === 'undefined') {
                            snapshot[did] = s_devsnapshot[did];
                        }
                    });
                } else {
                    console.log('scenario not found. id = ', sid);
                }
            }
        });

        return snapshot;
    }
    getAllAutomations = (): Set<string> => {
        const autos: Set<string> = new Set(this.fastAutomations);

        this._scenes.forEach(sid => {
            if (window.FOCUS_GATEWAY) {
                const scenario = window.FOCUS_GATEWAY.getScenario(sid);
                if (scenario) {
                    scenario.AUTOMATION_SET.forEach(aid => { autos.add(aid); });
                }
            }
        });

        return autos;
    }
    takeAutomationSnapshot = (): { [aid: string]: CsxAutomationJson } => {
        const snapshot: { [aid: string]: CsxAutomationJson } = {};

        /* Merge with children scenarios */
        this._scenes.forEach(sid => {
            if (window.FOCUS_GATEWAY) {
                const scenario = window.FOCUS_GATEWAY.getScenario(sid);
                if (scenario) {
                    const s_automation_snapshot = scenario.takeAutomationSnapshot();
                    Object.keys(s_automation_snapshot).forEach(aid => {
                        if (typeof snapshot[aid] === 'undefined') {
                            snapshot[aid] = s_automation_snapshot[aid];
                        }
                    });
                } else {
                    console.log('scenario not found. id = ', sid);
                }
            }
        });

        /* Merge with fast automation */
        this._fast_automations.forEach(aid => {
            if (window.FOCUS_GATEWAY) {
                if (typeof snapshot[aid] === 'undefined') {
                    const automation = window.FOCUS_GATEWAY.getAutomation(aid);
                    if (automation) {
                        snapshot[aid] = automation.toJson();
                    } else {
                        console.log('automation not found. id = ', aid);
                    }
                }
            }
        });

        return snapshot;
    }
    getAllIcons = (): Set<string> => {
        const icons: Set<string> = new Set();

        this._scenes.forEach(sid => {
            if (window.FOCUS_GATEWAY) {
                const scenario = window.FOCUS_GATEWAY.getScenario(sid);
                if (scenario) {
                    const icon_id = scenario.IMAGE_ID;
                    if (icon_id) {
                        icons.add(icon_id);
                    }
                }
            }
        });
        this._fast_automations.forEach(aid => {
            if (window.FOCUS_GATEWAY) {
                const automation = window.FOCUS_GATEWAY.getAutomation(aid);
                if (automation) {
                    const icon_id = automation.ICON;
                    if (icon_id) {
                        icons.add(icon_id);
                    }
                }
            }
        });
        this._cmds.forEach(cmd => {
            const icon_id = cmd.ICON;
            if (icon_id) {
                icons.add(icon_id);
            }
        });

        return icons;
    }

    loadJsonImage = (json: CsxRoomJson): Promise<boolean> => {
        return new Promise(async (resolve) => {
            if (json.scenarioSnapshot && window.FOCUS_GATEWAY) {
                const scenario_list = Object.keys(json.scenarioSnapshot);
                for (let i = 0; i < scenario_list.length; i++) {
                    const sid = scenario_list[i];
                    const scenario = window.FOCUS_GATEWAY.getScenario(sid);
                    if (scenario) {
                        const b64img = await scenario.getImageBase64();
                        if (b64img.length > 0)
                            json.scenarioSnapshot[sid].image = b64img;
                    }
                }
                resolve(true);
            } else {
                resolve(true);
            }
        });
    }

    toJson = (): CsxRoomJson => {
        const json: CsxRoomJson = {
            id: this._id,
            commands: this._cmds.map(cmd => cmd.toFastActionJson()),
            scenes: this.scenes,
            fastAutomations: this.fastAutomations,
            panel: {},
            name: this._name,
        };

        Object.keys(this._panel).forEach(scenario_id => { json.panel[scenario_id] = clonePanelConfiguration(this._panel[scenario_id]); });

        return json;
    }
}

export type CsxRoomSetJson = {
    list: Array<CsxRoomJson>;
}

export function isCsxRoomSetJson(x: any): x is CsxRoomSetJson {
    const yes: boolean = x && Array.isArray(x.list);
    if (yes)
        for (let i = 0; i < x.list.length; i++) { if (!isCsxRoomJson(x.list[i])) return false; }
    return yes;
}

export class CsxRoomSet {
    protected _config_dict: { [s: string]: CsxRoom } = {};
    protected _config_set: Set<string> = new Set();
    get ID_SET(): Set<string> { return new Set(this._config_set); }
    get SIZE(): number { return this._config_set.size; }
    get SIZE_LIMIT(): number { return 256; }
    get FILENAME() {
        const time = new Date().getTime();
        if (window.FOCUS_GATEWAY)
            return `room-backup-${CsxUtil.mapFilename(window.FOCUS_GATEWAY.NICKNAME)}-${time}.room.grp.json`;
        else
            return `room-backup-unknown-gateway-${time}.room.grp.json`;
    }
    // private _generate_random_id = (): string => {
    //     let random_id = Math.floor(Math.random() * 10000).toString();
    //     while (typeof this._config_dict[random_id] !== 'undefined')
    //         random_id = Math.floor(Math.random() * 10000).toString();
    //     return random_id;
    // }
    add = (config: CsxRoom) => {
        this._config_dict[config.ID] = config;
        this._config_set.add(config.ID);
    }
    remove = (config_id: string) => {
        this._config_set.delete(config_id);
        delete this._config_dict[config_id];
    }
    get = (config_id: string): CsxRoom | undefined => { return this._config_dict[config_id]; }
    newRoom = (): CsxRoom => {
        // const new_config_id = this._generate_random_id();
        const new_config = new CsxRoom('0', '');
        return new_config;
    }
    checkRoomName = (name: string): string | undefined => {
        const room_list = Array.from(this._config_set);
        for (let i = 0; i < room_list.length; i++) {
            const rid = room_list[i];
            if (this._config_dict[rid].NAME === name) {
                return rid;
            }
        }
        return undefined;
    }
    toJson = (): CsxRoomSetJson => {
        const buf: CsxRoomSetJson = { list: [] };
        this._config_set.forEach(room_id => {
            const room = this.get(room_id);
            if (room)
                buf.list.push(room.toJson());
        });
        return buf;
    }
}

export declare type CsxIconJson = {
    id: string;
    format: string;
    name: string;
    isLiked: boolean;
    base64?: string;
    textRaw?: string;
}

export function isCsxIconJson(x: any): x is CsxIconJson {
    if (x && (typeof x.isLiked === 'boolean') && (typeof x.name === 'string') &&
        (typeof x.id === 'string') && (typeof x.format === 'string')) {
        return true;
    } else {
        return false;
    }
}

export class CsxIcon {
    private _id: string;
    private _name: string;
    private _isLiked: boolean = false;
    private _format: string = 'jpg';
    constructor(id: string, parse: any) {
        this._id = id;
        this._name = `Icon${this._id}`;
        if (parse) {
            const { name, isLiked, format } = parse
            // if (typeof id === 'string')
            //     this._id = id.slice();
            if (typeof name === 'string')
                this._name = name.slice();
            if (typeof format === 'string')
                this._format = format.slice();
            if (typeof isLiked === 'boolean')
                this._isLiked = isLiked;
        }
    }
    get ID(): string { return this._id.slice(); }
    get NAME(): string { return this._name.slice(); }
    set NAME(n: string) { this._name = n.slice(); }
    get FORMAT(): string { return this._format; }
    set FORMAT(n: string) { this._format = n; }
    get LIKED(): boolean { return this._isLiked.valueOf(); }
    set LIKED(v: boolean) { this._isLiked = v.valueOf(); }
    get URL() { return `http://${((window.APP_DEV_MODE) ? DEV_TARGET_IP : window.location.host)}/image/${this.ID}.${this.FORMAT}`; }
    get clone(): CsxIcon {
        const new_icon = new CsxIcon(this.ID, this.toJson());
        return new_icon;
    }
    base64Encode = (): Promise<string> => {
        return new Promise((resolve: (_: string) => void) => {
            const image = new CsxUtil.CsxFileGrabber(this.URL + `?tag=${new Date().getTime()}`);
            image.load({
                allowContentType: ['image/jpeg', 'image/svg+xml', 'image/bmp', 'image/gif', 'image/png']
            }).then(err => {
                if (!err) {
                    resolve(image.base64);
                } else {
                    console.error('base64Encode error :>> ', err);
                    resolve('');
                }
            }).catch(error => {
                console.error('base64Encode error :>> ', error);
                resolve('');
            });
        });
    }
    rawText = (): Promise<string> => {
        return new Promise((resolve: (_: string) => void) => {
            const image = new CsxUtil.CsxFileGrabber(this.URL + `?tag=${new Date().getTime()}`);
            image.load({
                allowContentType: ['image/jpeg', 'image/svg+xml', 'image/bmp', 'image/gif', 'image/png']
            }).then(err => {
                if (!err) {
                    resolve(image.text);
                } else {
                    console.error('base64Encode error :>> ', err);
                    resolve('');
                }
            }).catch(error => {
                console.error('base64Encode error :>> ', error);
                resolve('');
            });
        });
    }
    toJson = (): CsxIconJson => {
        const json: CsxIconJson = {
            id: this._id,
            name: this._name,
            isLiked: this._isLiked,
            format: this._format,
        };
        return json;
    }
}

export type CsxIconSetJson = {
    list: Array<CsxIconJson>;
}

export function isCsxIconSetJson(x: any): x is CsxIconSetJson {
    const yes: boolean = x && Array.isArray(x.list);
    if (yes)
        for (let i = 0; i < x.list.length; i++) { if (!isCsxIconJson(x.list[i])) return false; }
    return yes;
}

export class CsxIconSet {
    protected _icon_dict: { [s: string]: CsxIcon } = {};
    protected _icon_set: Set<string> = new Set();
    get ID_SET(): Set<string> { return new Set(this._icon_set); }
    get SIZE(): number { return this._icon_set.size; }
    get SIZE_LIMIT(): number { return 256; }
    get FILENAME() {
        const time = new Date().getTime();
        if (window.FOCUS_GATEWAY)
            return `icon-backup-${CsxUtil.mapFilename(window.FOCUS_GATEWAY.NICKNAME)}-${time}.icon.tgz`;
        else
            return `icon-backup-unknown-gateway-${time}.icon.tgz`;
    }
    add = (icon: CsxIcon) => {
        this._icon_dict[icon.ID] = icon;
        this._icon_set.add(icon.ID);
    }
    remove = (icon_id: string) => {
        this._icon_set.delete(icon_id);
        delete this._icon_dict[icon_id];
    }
    get = (icon_id: string): CsxIcon | undefined => { return this._icon_dict[icon_id]; }
    newIcon = (): CsxIcon => {
        // const new_config_id = this._generate_random_id();
        const new_icon = new CsxIcon('0', {});
        return new_icon;
    }
    checkIconName = (name: string): string | undefined => {
        const icon_list = Array.from(this._icon_set);
        for (let i = 0; i < icon_list.length; i++) {
            const icon_id = icon_list[i];
            if (this._icon_dict[icon_id].NAME === name) {
                return icon_id;
            }
        }
        return undefined;
    }
    toJson = (): CsxIconSetJson => {
        const buf: CsxIconSetJson = { list: [] };
        this._icon_set.forEach(icon_id => {
            const icon = this.get(icon_id);
            if (icon)
                buf.list.push(icon.toJson());
        });
        return buf;
    }
}

export type CsxCommandJson = {
    name: string;
    icon?: string;
    command: string;
    hexCommand: string;
    endchar: number;
};

export function isCsxCommandJson(x: any): x is CsxCommandJson {
    if (x && (typeof x.name === 'string') &&
        (typeof x.command === 'string') &&
        (typeof x.hexCommand === 'string') &&
        (typeof x.endchar === 'number')) {
        return true;
    } else {
        return false;
    }
}

export function cypHexStringToPureHexString(strin: string): string {
    return strin.split('\\x').join('').toLowerCase();
}

export function pureHexStringToCypHexString(strin: string): string {
    let buffer = '';

    for (let i = 0; i < strin.length; i += 2) {
        buffer += `\\x${strin.substr(i, 2)}`;
    }
    return buffer
}

export function stringToCypHexString(strin: string): string {
    let buffer = '';

    for (let i = 0; i < strin.length; i++) {
        const ccode = strin.charCodeAt(i);
        let ccode_hexstr = ccode.toString(16);
        while (ccode_hexstr.length < 2) {
            ccode_hexstr = '0' + ccode_hexstr;
        }
        buffer += `\\x${ccode_hexstr}`;
    }
    return buffer;
}

export const CYP_HEX_STRING_CHECK_PATTERN = '^(?:\\\\x([0-9a-fA-F]{2}))+$'
const CYP_HEX_STRING_MATCH_PATTERN = '\\\\x([0-9a-fA-F]{2})'

export function cypHexStringToString(strin: string): string {
    const matcher = new RegExp(CYP_HEX_STRING_MATCH_PATTERN, 'g');
    // const result = strin.match(matcher);
    let result = matcher.exec(strin);

    let buffer = '';
    while (result) {
        const iter = Array.from(result);
        const hexstr = iter[1];
        buffer += String.fromCharCode(parseInt(hexstr, 16));
        result = matcher.exec(strin);
    }
    return buffer;
}

export class CsxCommand {
    private _id: string;
    private _name: string = 'New Command';
    private _icon?: string;
    private _cmd: string = '';
    private _hexcmd: string = '';
    private _endchar: CsxTrailType = CsxTrailType.NA;
    constructor(id: string, data: any) {
        this._id = id.slice();
        if (data) {
            const { name, command, hexCommand, icon, endchar } = data;
            if (typeof name === 'string') {
                this.NAME = name;
            }
            if (typeof command === 'string') {
                this.CMD = command;
            }
            if (typeof hexCommand === 'string') {
                this.HEXCMD = hexCommand;
            }
            if (typeof icon === 'string') {
                this.ICON = icon;
            }
            if (typeof endchar === 'number') {
                this._endchar = CYP_TRAIL_MAP_REVERSE[endchar];
            }
        }
    }
    get ID(): string { return this._id.slice(); }
    set ID(n: string) { this._id = n.slice(); }
    get NAME(): string { return this._name.slice(); }
    set NAME(n: string) { this._name = n.slice(); }
    get ICON() { return (this._icon) ? this._icon.slice() : undefined; }
    set ICON(v: string | undefined) { this._icon = (v) ? v.slice() : undefined; }
    get CMD(): string { return this._cmd.slice(); }
    set CMD(str: string) {
        this._cmd = str.slice();
        this._hexcmd = stringToCypHexString(this._cmd);
    }
    get HEXCMD(): string { return this._hexcmd.slice(); }
    set HEXCMD(str: string) {
        const hexMatcher = new RegExp(CYP_HEX_STRING_CHECK_PATTERN);
        this._hexcmd = str.slice();
        if (this._hexcmd.length > 0) {
            if (hexMatcher.test(this._hexcmd)) {
                this._cmd = cypHexStringToString(this._hexcmd);
            } else {
                this._cmd = '';
            }
        }
    }
    get ENDCHAR(): CsxTrailType {
        const ret = this._endchar.slice();
        return isCsxTrailType(ret) ? ret : CsxTrailType.NA;
    }
    set ENDCHAR(str: CsxTrailType) {
        const value = str.slice();
        if (isCsxTrailType(value)) {
            this._endchar = value;
        }
    }
    clone = (): CsxCommand => { return (new CsxCommand(this.ID, this.toJson())); }
    toJson = (): CsxCommandJson => {
        const json: CsxCommandJson = {
            name: this.NAME,
            command: this.CMD,
            hexCommand: this.HEXCMD,
            endchar: CYP_TRAIL_MAP[this.ENDCHAR],
            icon: this.ICON,
        };
        return json;
    }
}

export type CsxCommandSetJson = {
    list: Array<CsxCommandJson>;
}

export function isCsxCommandSetJson(x: any): x is CsxCommandSetJson {
    const yes: boolean = x && Array.isArray(x.list);
    if (yes)
        for (let i = 0; i < x.list.length; i++) { if (!isCsxCommandJson(x.list[i])) return false; }
    return yes;
}

export class CsxCommandSet {
    protected _cmd_dict: { [s: string]: CsxCommand } = {};
    protected _cmd_set: Set<string> = new Set();
    get ID_SET(): Set<string> { return new Set(this._cmd_set); }
    get SIZE(): number { return this._cmd_set.size; }
    get SIZE_LIMIT(): number { return 256; }
    get FILENAME() {
        const time = new Date().getTime();
        if (window.FOCUS_GATEWAY)
            return `command-backup-${CsxUtil.mapFilename(window.FOCUS_GATEWAY.NICKNAME)}-${time}.cmd.grp.json`;
        else
            return `command-backup-unknown-gateway-${time}.cmd.grp.json`;
    }
    add = (cmd: CsxCommand) => {
        this._cmd_dict[cmd.ID] = cmd;
        this._cmd_set.add(cmd.ID);
    }
    remove = (cmd_id: string) => {
        this._cmd_set.delete(cmd_id);
        delete this._cmd_dict[cmd_id];
    }
    get = (cmd_id: string): CsxCommand | undefined => { return this._cmd_dict[cmd_id]; }
    newCommand = (): CsxCommand => {
        // const new_config_id = this._generate_random_id();
        const new_cmd = new CsxCommand('0', {});
        return new_cmd;
    }
    checkCommandName = (name: string): string | undefined => {
        const cmd_list = Array.from(this._cmd_set);
        for (let i = 0; i < cmd_list.length; i++) {
            const cmd_id = cmd_list[i];
            if (this._cmd_dict[cmd_id].NAME === name) {
                return cmd_id;
            }
        }
        return undefined;
    }
    toJson = (): CsxCommandSetJson => {
        const buf: CsxCommandSetJson = { list: [] };
        this._cmd_set.forEach(cmd_id => {
            const cmd = this.get(cmd_id);
            if (cmd)
                buf.list.push(cmd.toJson());
        });
        return buf;
    }
}

export type TcpDeviceJson = {
    ip: string;
    port: number;
};

export function isTcpDeviceJson(x: any): x is TcpDeviceJson {
    return (x && (typeof x.ip === 'string') && (typeof x.port === 'number'));
}

export class TcpDevice {
    protected _id: string;
    protected _ip: string = '';
    protected _port: number = -1;
    constructor(id: string, parse: any) {
        this._id = id;

        if (parse) {
            const { ip, port } = parse;
            if (typeof ip === 'string') {
                this._ip = ip.slice();
            }
            if (typeof port === 'number') {
                this._port = port;
            }
        }
    }
    get ID() { return this._id.slice(); }
    set ID(str: string) { this._id = str.slice(); }
    get IP() { return this._ip.slice(); }
    set IP(str: string) { this._ip = str.slice(); }
    get PORT() { return this._port; }
    set PORT(val: number) { this._port = val; }
    toJson = (): TcpDeviceJson => {
        const json: TcpDeviceJson = {
            ip: this.IP,
            port: this.PORT,
        };
        return json;
    }
}

export type TcpDeviceSetJson = {
    list: Array<TcpDeviceJson>;
}

export function isTcpDeviceSetJson(x: any): x is TcpDeviceSetJson {
    const yes: boolean = x && Array.isArray(x.list);
    if (yes)
        for (let i = 0; i < x.list.length; i++) { if (!isTcpDeviceJson(x.list[i])) return false; }
    return yes;
}

export class TcpDeviceSet {
    protected _tcpdev_dict: { [s: string]: TcpDevice } = {};
    protected _tcpdev_set: Set<string> = new Set();
    get ID_SET(): Set<string> { return new Set(this._tcpdev_set); }
    get SIZE(): number { return this._tcpdev_set.size; }
    get SIZE_LIMIT(): number { return 256; }
    get FILENAME() {
        const time = new Date().getTime();
        if (window.FOCUS_GATEWAY)
            return `tcpdev-backup-${CsxUtil.mapFilename(window.FOCUS_GATEWAY.NICKNAME)}-${time}.tcpdev.grp.json`;
        else
            return `tcpdev-backup-unknown-gateway-${time}.tcpdev.grp.json`;
    }
    add = (tcpdev: TcpDevice) => {
        this._tcpdev_dict[tcpdev.ID] = tcpdev;
        this._tcpdev_set.add(tcpdev.ID);
    }
    remove = (tcpdev_id: string) => {
        this._tcpdev_set.delete(tcpdev_id);
        delete this._tcpdev_dict[tcpdev_id];
    }
    get = (tcpdev_id: string): TcpDevice | undefined => { return this._tcpdev_dict[tcpdev_id]; }
    newTcpDevice = (): TcpDevice => {
        // const new_config_id = this._generate_random_id();
        const new_tcpdev = new TcpDevice('0', {});
        return new_tcpdev;
    }
    checkIPPort = (ip: string, port: number): string | undefined => {
        const tcpdev_list = Array.from(this._tcpdev_set);
        for (let i = 0; i < tcpdev_list.length; i++) {
            const tcpdev = this._tcpdev_dict[tcpdev_list[i]];
            if (tcpdev.IP === ip && tcpdev.PORT === port) {
                return tcpdev.ID;
            }
        }
        return undefined;
    }
    toJson = (): TcpDeviceSetJson => {
        const buf: TcpDeviceSetJson = { list: [] };
        this._tcpdev_set.forEach(tcpdev_id => {
            const tcpdev = this.get(tcpdev_id);
            if (tcpdev)
                buf.list.push(tcpdev.toJson());
        });
        return buf;
    }
}