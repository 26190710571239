import React from 'react';
import { Layout, Empty, Button, Notify } from 'cypd';

import { CsxUI, CsxFeature, CsxEventSystem, CsxUtil, CsxDesc } from '../../csx';
import './page.css';
// import { CsxUserPermissionLevel, csxUserPermissionSatisfy } from '../../csx/manager';
import automationShortcutDefaultImage from '../../images/automation.svg';

const { getText } = CsxDesc;

// const SLIDER_CONTROL_ANIMATION_INTERVAL = 500; // ms
declare global {
    interface Window {
        routeHome: {
            FOCUS_SCENARIO: CsxEventSystem.CsxScenario | undefined;
        };
    }
}

declare type HomePage2State = {
    pageno: number;
    room_selector_visible: boolean;
    display_mode: 'theater' | 'matrix';
    // room?: CsxEventSystem.CsxRoom;
}

function preferColumnFromSize(size: number) {
    const prefer_frame_size = 130;
    return Math.floor((size) / prefer_frame_size);
}

export default class HomePage extends CsxUI.IRQComponent<any> {
    state: HomePage2State;
    instance: CsxFeature.CsxEventSystemDevice;
    room_selector: HTMLInputElement | null | undefined;
    fileSelector: HTMLInputElement | null | undefined;
    fileFor?: string;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'EventSystem';
        this.MIN_RENDER_INTERVAL = 3000;

        this.instance = new CsxFeature.CsxEventSystemDevice();
        const init_state: HomePage2State = { pageno: 0, room_selector_visible: false, display_mode: this.localDisplayMode }
        this.state = init_state;
    }
    get localRoom() {
        const local_room_id = window.localStorage.getItem('room_config');
        if (local_room_id) {
            const local_room = this.instance.Room(CsxUtil.AESDecrypt(local_room_id));
            if (local_room) {
                return local_room.clone
            }
        }
        return undefined;
    }
    get localDisplayMode() {
        const mode = window.localStorage.getItem('room_disp_mode');
        if (mode === 'matrix' || mode === 'theater')
            return mode;
        return 'theater';
    }
    get scenarioList() {
        const room = this.localRoom;
        return room ? room.scenes.filter(scenario_id => !!this.instance.Scenario(scenario_id)) : [];
    }
    get automationList(): Array<string> {
        const room = this.localRoom;
        return room ? room.fastAutomations.filter(automation_id => !!this.instance.Automation(automation_id)) : [];
    }
    onPrevPage = () => {
        this.setState((prevState: HomePage2State) => {
            let dest_page = prevState.pageno - 1;
            if (dest_page < 0) dest_page = 0;
            return { pageno: dest_page };
        });
    }
    onNextPage = () => {
        this.setState((prevState: HomePage2State) => {
            const max_page = this.scenarioList.length - 1;
            let dest_page = prevState.pageno + 1;
            if (dest_page > max_page) dest_page = max_page;
            return { pageno: dest_page };
        });
    }
    toPage = (page: number) => {
        this.setState({ pageno: page });
    }
    onStopScenario = (scenario_id: string) => {
        this.instance.StopScenario(scenario_id);
    }
    onLaunchScenario = (scenario_id: string) => {
        const scenario_list = this.scenarioList;
        scenario_list.forEach(sid => {
            const scenario = this.instance.Scenario(sid);
            if (scenario && scenario.ACTIVE)
                this.onStopScenario(sid);
        });
        this.instance.RunScenario(scenario_id);
    }
    onSelectRoom = (room_id: string) => {
        const install_room = this.instance.Room(room_id);
        if (install_room) {
            // window.localStorage.setItem('room_config', room_id);
            window.localStorage.setItem('room_config', CsxUtil.AESEncrypt(room_id));
            this.setState({ room: install_room.clone, pageno: 0 }, () => {
                if (this.room_selector)
                    this.room_selector.click();
            });
        }
    }
    onChangeDisplayMode = (mode: string) => {
        if (mode === 'matrix' || mode === 'theater') {
            window.localStorage.setItem('room_disp_mode', mode);
            this.setState({ display_mode: mode });
        }
    }
    onTriggerScenarion = (focusto: number, scenario_id: string, active: boolean) => {
        const scene = this.instance.Scenario(scenario_id);
        if (active) {
            this.toPage(focusto);
            this.onLaunchScenario(scenario_id);
            Notify({ type: 'success', context: `${(scene) ? scene.NAME : ''}`, title: getText('NOTIFY_TITLE_START') });
        } else {
            this.onStopScenario(scenario_id);
            Notify({ type: 'success', context: `${(scene) ? scene.NAME : ''}`, title: getText('NOTIFY_TITLE_STOP') });
        }
    }
    onTriggerRoomSelector = () => {
        this.setState((prevState: HomePage2State) => {
            return { room_selector_visible: !prevState.room_selector_visible };
        });
    }
    onTriggerAutomation = (automation_id: string, active: boolean) => {
        const automation = this.instance.Automation(automation_id);
        if (active) {
            this.instance.EnableAutomation(automation_id);
            Notify({ type: 'success', context: `${(automation) ? automation.NAME : ''}`, title: getText('NOTIFY_TITLE_START') });
        } else {
            this.instance.DisableAutomation(automation_id);
            Notify({ type: 'success', context: `${(automation) ? automation.NAME : ''}`, title: getText('NOTIFY_TITLE_STOP') });
        }
    }
    render() {
        const { pageno, room_selector_visible } = this.state;
        const room = this.localRoom;
        const block_space = 9;
        const scenario_list = this.scenarioList;
        const container_size = (window.innerWidth > 870) ? 750 : (window.innerWidth - 120);
        const disable_signout_room_control = (
            typeof window.CSX_CUR_USER === 'undefined' && 
            window.CSX_CUR_AUTH &&
            window.CSX_CUR_AUTH.get('disable_signout_room_control') === 'y'
        ) ? true : false;
        const command_list = (room) ? room.cmds : [];
        const automation_list = this.automationList;
        const focus_scenario = this.instance.Scenario(scenario_list[pageno]); 
        const allow_select_room = (window.CSX_CUR_USER && window.CSX_CUR_AUTH);
        // const room_view_priviledge = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getPermission('room_view') : CsxUserPermissionLevel.NoAccess;
        let room_list: Array<string> = [];

        if (window.CSX_CUR_AUTH) {
            room_list = Array.from(window.CSX_CUR_AUTH.getAssignedRoom());
        }

        return (
            <Layout.Body>
                <Layout.Center>
                    <div className='app_homepage_container'>
                        <div className={`title ${(room) ? 'has-prefix' : ''}`}>
                            <span style={{ fontSize: '18px', width: 'calc(100% - 130px)' }}>{(room) ? (focus_scenario ? `${room.NAME} / ${focus_scenario.NAME}` : room.NAME) : ''}</span>
                            {(allow_select_room) ? <Button size='small' type='primary' style={{ position: 'absolute', right: '0px' }} onClick={() => { if (this.room_selector) this.room_selector.click(); }}>{getText('HOME_ROOM_SELECT')}</Button> : undefined}
                        </div>
                        {disable_signout_room_control ? <Empty style={{ height: '100%' }} text={getText('HINT_PLEASE_LOGIN')} /> : undefined}
                        <div className='view' style={CsxUI.getHiddenStyle(!disable_signout_room_control)}>
                            <div className='matrix'>
                                <div style={{ height: '20px', width: '100%', borderTop: '1px solid #00839E' }}>
                                    <div style={{ display: 'inline-block', height: '20px', lineHeight: '20px', color: 'white', backgroundColor: '#00839E', padding: '0 5px 0 5px' }}>{getText('APP_NAV_SCENARIO')}</div>
                                </div>
                                {(scenario_list.length > 0) ? <CsxUI.DynamicMatrix
                                    className='app_container'
                                    frameDescription={{
                                        array: scenario_list,
                                        columnLimit: preferColumnFromSize(container_size),
                                        marginBetween: block_space,
                                        render: (scenario_id, idx) => {
                                            const scenario = this.instance.Scenario(scenario_id);
                                            const is_active = (scenario && scenario.ACTIVE);
                                            const img_url = (room) ? room.getScenarioImages(scenario_id) : '';
                                            let show_name = (scenario ? scenario.NAME : '???');
                                            if (show_name.length > 20) {
                                                show_name = show_name.slice(0, 20) + '...';
                                            }
                                            return (
                                                <div
                                                    className={is_active ? 'active_app' : 'inactive_app'}
                                                    style={{ position: 'relative', width: '100%', padding: '0 10px 10px 10px', border: '1px solid transparent', borderRadius: '20px', cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}
                                                    onClick={() => { this.onTriggerScenarion(idx, scenario_id, !is_active); }}
                                                >
                                                    <CsxUI.FallbackImage
                                                        src={img_url}
                                                        width='100%'
                                                        height='auto'
                                                    />
                                                    <div
                                                        className='name_row'
                                                        style={{
                                                            display: '-webkit-box',
                                                            position: 'relative',
                                                            textAlign: 'center',
                                                            maxHeight: `45px`,
                                                            lineHeight: `15px`,
                                                            width: '100%',
                                                            overflow: 'hidden',
                                                            WebkitLineClamp: 3,
                                                            WebkitBoxOrient: 'vertical',
                                                            marginRight: '5px',
                                                            color: '#3d4c4c',
                                                            wordBreak: 'break-word',
                                                            paddingBottom: '3px',
                                                        }}
                                                    >
                                                        {show_name}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }}
                                /> : <Empty style={{ height: '100%' }} text={getText('HINT_NO_SCENARIO')} />}
                            </div>
                            <div className='matrix'>
                                <div style={{ height: '20px', width: '100%', borderTop: '1px solid #00839E' }}>
                                    <div style={{ display: 'inline-block', height: '20px', lineHeight: '20px', color: 'white', backgroundColor: '#00839E', padding: '0 5px 0 5px' }}>{getText('COMMAND')}</div>
                                </div>
                                {(command_list.length > 0) ? <CsxUI.DynamicMatrix
                                    className='app_container'
                                    frameDescription={{
                                        array: command_list,
                                        columnLimit: preferColumnFromSize(container_size),
                                        marginBetween: block_space,
                                        render: (command: CsxEventSystem.CsxFastAction) => {
                                            const img_url = command.getImageUrl();
                                            return (
                                                <div
                                                    className='reactive_app'
                                                    style={{ position: 'relative', width: '100%', padding: '0 10px 10px 10px', border: '1px solid transparent', borderRadius: '20px', cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}
                                                    onMouseUp={() => { this.instance.RunActionTest(command.toJson()); }}
                                                >
                                                    <CsxUI.FallbackImage
                                                        src={img_url}
                                                        width='100%'
                                                        height='auto'
                                                    />
                                                    <div
                                                        className='name_row'
                                                        style={{
                                                            display: '-webkit-box',
                                                            position: 'relative',
                                                            textAlign: 'center',
                                                            maxHeight: `45px`,
                                                            lineHeight: `15px`,
                                                            width: '100%',
                                                            overflow: 'hidden',
                                                            WebkitLineClamp: 3,
                                                            WebkitBoxOrient: 'vertical',
                                                            marginRight: '5px',
                                                            color: '#3d4c4c',
                                                            wordBreak: 'break-word',
                                                            paddingBottom: '3px',
                                                        }}
                                                    >
                                                        {command.NAME}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }}
                                /> : <Empty style={{ height: 'calc(100% - 20px)' }} text={getText('HINT_NO_FAST_COMMAND')} />}
                            </div>
                            <div className='matrix'>
                                <div style={{ height: '20px', width: '100%', borderTop: '1px solid #00839E' }}>
                                    <div style={{ display: 'inline-block', height: '20px', lineHeight: '20px', color: 'white', backgroundColor: '#00839E', padding: '0 5px 0 5px' }}>{getText('APP_NAV_AUTOMATION')}</div>
                                </div>
                                {(automation_list.length > 0) ? <CsxUI.DynamicMatrix
                                    className='app_container'
                                    frameDescription={{
                                        array: automation_list,
                                        columnLimit: preferColumnFromSize(container_size),
                                        marginBetween: block_space,
                                        render: (automation_id: string) => {
                                            const automation = this.instance.Automation(automation_id);
                                            const is_active = (automation && automation.ACTIVE);
                                            // const img_url = (automation) ? automation.getImageUrl() : '';
                                            let show_name = (automation ? automation.NAME : '???');
                                            if (show_name.length > 22) {
                                                show_name = show_name.slice(0, 22) + '...';
                                            }
                                            return (
                                                <div
                                                    className={is_active ? 'active_app' : 'inactive_app'}
                                                    style={{ position: 'relative', width: '100%', padding: '0 10px 10px 10px', border: '1px solid transparent', borderRadius: '20px', cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}
                                                    onClick={() => { this.onTriggerAutomation(automation_id, !is_active); }}
                                                >
                                                    <CsxUI.FallbackImage
                                                        src={automationShortcutDefaultImage}
                                                        width='100%'
                                                        height='auto'
                                                    />
                                                    <div
                                                        className='name_row'
                                                        style={{
                                                            display: '-webkit-box',
                                                            position: 'relative',
                                                            textAlign: 'center',
                                                            maxHeight: `45px`,
                                                            lineHeight: `15px`,
                                                            width: '100%',
                                                            overflow: 'hidden',
                                                            WebkitLineClamp: 3,
                                                            WebkitBoxOrient: 'vertical',
                                                            marginRight: '5px',
                                                            color: '#3d4c4c',
                                                            wordBreak: 'break-word',
                                                            paddingBottom: '3px',
                                                        }}
                                                    >
                                                        {show_name}
                                                    </div>
                                                </div>
                                            );
                                        }
                                    }}
                                /> : <Empty style={{ height: 'calc(100% - 20px)' }} text={getText('HINT_NO_FAST_AUTOMATION')} />} 
                            </div>
                        </div>
                    </div>
                    <input className='room_selector_toggler' ref={inst => { this.room_selector = inst; }} onChange={this.onTriggerRoomSelector} id='toggler' checked={room_selector_visible} type='checkbox' />
                    <div className='room_selector'>
                        <div className='list_view'>
                            <div className='header'><div className='title'>{getText('HOME_ROOM_SELECT')}</div><div style={{ display: (room) ? undefined : 'none' }} className='close' onClick={() => { if (this.room_selector) this.room_selector.click(); }} /></div>
                            <div className='group'>
                                {room_list.map((room_id, idx) => {
                                    const room_inst = this.instance.Room(room_id);
                                    return (room_inst) ? <div className='item' key={`room_opt_${idx}`} onClick={() => { this.onSelectRoom(room_id); }}>{room_inst.NAME}</div> : undefined;
                                })}
                            </div>
                        </div>
                    </div>
                </Layout.Center>
            </Layout.Body>
        );
    }
}

// class TriggerPanel extends CsxUI.IRQComponent<any> {
//     panel?: CsxEventSystem.PanelConfigurationJson;
//     instance: CsxFeature.CsxEventSystemDevice;
//     default_volume_out?: string;
//     default_video_out?: string;
//     video_instance?: CsxFeature.CsxRoutableDevice;
//     volume_instance?: CsxFeature.CsxAudioDevice;
//     light_instance?: undefined;
//     constructor(props: any) {
//         super(props);
//         this.IRQIndex = 'Dashboard';
//         this.instance = new CsxFeature.CsxEventSystemDevice();
//     }
//     get localRoom() {
//         const local_room_id = window.localStorage.getItem('room_config');
//         if (local_room_id) {
//             // const local_room = this.instance.Room(local_room_id);
//             const local_room = this.instance.Room(CsxUtil.AESDecrypt(local_room_id));
//             if (local_room) {
//                 return local_room.clone
//             }
//         }
//         return undefined;
//     }
//     get scenarioList() {
//         const room = this.localRoom;
//         return room ? room.scenes : [];
//     }
//     getPanel = () => {
//         const room = this.localRoom;
//         const scenario_list = this.scenarioList;
//         let focus_scenario: CsxEventSystem.CsxScenario | undefined;
//         scenario_list.forEach(scenario_id => {
//             const scenario = this.instance.Scenario(scenario_id);
//             if (scenario && scenario.ACTIVE)
//                 focus_scenario = scenario;
//         });
//         this.panel = (focus_scenario && room) ? room.getScenarioPanel(focus_scenario.ID) : undefined;
//         const video_device = (window.FOCUS_GATEWAY && this.panel) ? window.FOCUS_GATEWAY.getDevice(this.panel.video.mission.deviceList[0]) : undefined;
//         const volume_device = (window.FOCUS_GATEWAY && this.panel) ? window.FOCUS_GATEWAY.getDevice(this.panel.volume.mission.deviceList[0]) : undefined;
//         this.video_instance = (video_device) ? new CsxFeature.CsxRoutableDevice(video_device) : undefined;
//         this.volume_instance = (volume_device) ? new CsxFeature.CsxAudioDevice(volume_device) : undefined;
//         const volume_cmd_pattern = /set audio out (.*) volume/;
//         const match_volume_port = (this.panel) ? this.panel.volume.mission.commandExpress.match(volume_cmd_pattern) : undefined;
//         const video_cmd_pattern = /set out (.*) route/;
//         const match_video_port = (this.panel) ? this.panel.video.mission.commandExpress.match(video_cmd_pattern) : undefined;
//         this.default_volume_out = (match_volume_port) ? match_volume_port[1] : undefined;
//         this.default_video_out = (match_video_port) ? match_video_port[1] : undefined;
//     }
//     onChangeVideoSource = (src_id: string) => {
//         if (window.FOCUS_GATEWAY && this.default_video_out && this.panel) {
//             // window.FOCUS_GATEWAY.handleRequest(this.panel.video.mission.deviceList.map(device_id => `?101:${device_id},S5.${this.default_video_out}=${src_id}`).join('\n') + '\n');
//             for (let i = 0; i < this.panel.video.mission.deviceList.length; i++) {
//                 const dev_id = this.panel.video.mission.deviceList[i];
//                 const instance = window.FOCUS_GATEWAY.getDevice(dev_id);
//                 const debugInstance = new CsxFeature.CsxDebugDevice(instance);
//                 debugInstance.VirtualConsoleSend(this.panel.video.mission.commandExpress.replace(/\$VAR\$/, src_id));
//             }
//         }
//     }
//     onChangeVolume = (volume: number) => {
//         if (window.FOCUS_GATEWAY && this.panel && this.default_volume_out) {
//             // window.FOCUS_GATEWAY.handleRequest(this.panel.volume.mission.deviceList.map(device_id => `?101:${device_id},H6.${this.default_volume_out}=${volume}`).join('\n') + '\n');
//             for (let i = 0; i < this.panel.volume.mission.deviceList.length; i++) {
//                 const dev_id = this.panel.volume.mission.deviceList[i];
//                 const instance = window.FOCUS_GATEWAY.getDevice(dev_id);
//                 const debugInstance = new CsxFeature.CsxDebugDevice(instance);
//                 debugInstance.VirtualConsoleSend(this.panel.volume.mission.commandExpress.replace(/\$VAR\$/, volume.toString()));
//             }
//         }
//     }
//     render() {
//         this.getPanel();
//         const video_source_param = (this.video_instance) ? this.video_instance.OutRouteSrcParam() : undefined;
//         const volume_param = (this.volume_instance) ? this.volume_instance.OutAudioVolumeParam() : undefined;
//         const cur_volume = (this.volume_instance && this.default_volume_out) ? parseInt(this.volume_instance.OutAudioVolume(this.default_volume_out)) : undefined;
//         const cur_video_source = (this.video_instance && this.default_video_out) ? this.video_instance.OutRouteSrc(this.default_video_out) : undefined;
//         const video_source_opt = (video_source_param && video_source_param.n1.isOption) ? video_source_param.n1.isOption.option : [];
//         const video_source_desc = (video_source_param && video_source_param.n1.isOption) ? video_source_param.n1.isOption.desc : [];
//         const volume_limit = (volume_param && volume_param.n1.isRange) ? { ...volume_param.n1.isRange.range } : undefined;
//         return (
//             <div className='home_trigger_panel'>
//                 <div className='trigger_video_src'>
//                     <div className='title'>Video Source</div>
//                     <div className='operation'>
//                         <div className='option_wrapper'>
//                             {<RadioGroup value={cur_video_source} layout='vertical' options={video_source_opt.map((vsrc, idx) => {
//                                 return { label: (video_source_desc ? video_source_desc[idx] : vsrc), value: vsrc };
//                             })} onChange={this.onChangeVideoSource} />}
//                         </div>
//                     </div>
//                 </div>
//                 <div className='trigger_volumn'>
//                     <div className='title'>Volume</div>
//                     {(typeof cur_volume !== 'undefined') ? <div className='operation'>
//                         <Form.Item>
//                             <Icon type='volumn' color='white' />
//                             <Slider onAfterChange={this.onChangeVolume}
//                                 value={cur_volume}
//                                 max={(volume_limit) ? volume_limit.max : undefined}
//                                 min={(volume_limit) ? volume_limit.min : undefined}
//                                 step={(volume_limit) ? volume_limit.step : undefined}
//                             />
//                             <Icon type='minus' onClick={() => {
//                                 if (typeof cur_volume === 'number' && volume_limit) {
//                                     if ((cur_volume - volume_limit.step) >= volume_limit.min)
//                                         this.onChangeVolume(cur_volume - volume_limit.step);
//                                 }
//                             }} />
//                             <Icon type='plus' onClick={() => {
//                                 if (typeof cur_volume === 'number' && volume_limit) {
//                                     if ((cur_volume + volume_limit.step) <= volume_limit.max)
//                                         this.onChangeVolume(cur_volume + volume_limit.step);
//                                 }
//                             }} />
//                         </Form.Item>
//                     </div> : undefined}
//                 </div>
//                 <div className='trigger_light'>
//                     {/* <div className='title'>Light</div> */}
//                     <div className='operation' style={{ display: 'flex', flexDirection: 'column', alignContent: 'center' }}>
//                         {/* <Form.Item>
//                             <Icon type='idea' color='white' />
//                             <Slider />
//                             <Icon type='minus' />
//                             <Icon type='plus' />
//                         </Form.Item> */}
//                         <Cone color='white' backgroundColor='transparent' />
//                     </div>
//                 </div>
//             </div>
//         );
//     }
//     // </div> : <Empty textColor='white' style={{ minHeight: '100px', height: '100px', width: 'calc(100% - 60px)', transform: 'translateY(-30px)', position: 'absolute' }} />}
// }