import React from 'react';
import { withRouter } from 'react-router-dom';

import { CsxUI, CsxFeature, CsxUtil } from '../../../csx';
import '../page.css';
import { getText } from '../../../csx/desc';
import { Flat } from 'cypd';


declare type FeatureUserInterfaceDef = {
    label: string;
    url: string;
    className: string;
}

class FeatureList extends CsxUI.IRQComponent<any> {
    FEATURE_GENERATE_MAP: { [s in CsxFeature.FeatureSupportType]: FeatureUserInterfaceDef } = {
        'Routing':      { url: 'routing',   className: 'routing',           label: getText('DEVICE_FEATURE_ROUTING'),           },
        'Audio':        { url: 'audio',     className: 'audio',             label: getText('DEVICE_FEATURE_AUDIO'),             },
        'OSD':          { url: 'osd',       className: 'osd',               label: getText('DEVICE_FEATURE_OSD'),               },
        'Scaler':       { url: 'scaler',    className: 'scaler',            label: getText('DEVICE_FEATURE_SCALER'),            },
        'VideoWall':    { url: 'videowall', className: 'videowall',         label: getText('DEVICE_FEATURE_VIDEOWALL'),         },
        'Automation':   { url: 'auto',      className: 'automation',        label: getText('DEVICE_FEATURE_AUTOMATION'),        },
        'Streaming':    { url: 'streaming', className: 'streaming',         label: getText('DEVICE_FEATURE_STREAMING'),         },
        'Record':       { url: 'record',    className: 'record',            label: getText('DEVICE_FEATURE_RECORD'),            },
        'CEC':          { url: 'cec',       className: 'cec',               label: getText('DEVICE_FEATURE_CEC'),               },
        'EDID':         { url: 'edid',      className: 'edid',              label: getText('DEVICE_FEATURE_EDID'),              },
        'HDCP':         { url: 'hdcp',      className: 'hdcp',              label: getText('DEVICE_FEATURE_HDCP'),              },
        'General':      { url: 'general',   className: 'general',           label: getText('DEVICE_FEATURE_GENERAL'),           },
        'IO':           { url: 'io',        className: 'io',                label: getText('DEVICE_FEATURE_IO'),                },
        'PowerMonitor': { url: 'pmonitor',  className: 'pmonitor',          label: getText('DEVICE_FEATURE_POWER_MONITOR'),     },
        'FWUpdate':     { url: 'software',  className: 'software-update',   label: getText('DEVICE_FEATURE_FW_UPDATE'),         },
        'CableTest':    { url: 'cabletest', className: 'cabletest',         label: getText('DEVICE_FEATURE_CABLE_TEST'),        },
        'Debug':        { url: 'debug',     className: 'debug',             label: getText('DEVICE_FEATURE_DEBUG'),             },
        'Dante':        { url: 'dante',     className: 'dante',             label: getText('DEVICE_FEATURE_DANTE'),             },
        'Time':         { url: 'time',      className: 'time',              label: getText('DEVICE_FEATURE_TIME'),              },
    }
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'DeviceRealTime';
        this.MIN_RENDER_INTERVAL = 3000;
    }
    render() {
        const dev = new CsxFeature.CsxFeatureDevice(window.FOCUS_DEVICE);
        const cur_dev_id = this.getURLParameter().get('dev');
        const List = withRouter(({ history }) => (
            <Flat.Section title={getText('DEVICE_FEATURE_LABEL')} disableCollapsed style={{ width: 'calc(100% - 100px)', maxWidth: 'none' }} titleStyle={{ backgroundColor: 'white', color: '#707070', fontSize: '18px', padding: '10px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', justifyContent: 'center' }}>
                    {CsxFeature.FEATURE_SUP_LIST.filter((key) => {
                        return dev.hasFeature(key);
                    }).map(key => {
                        if (CsxFeature.isFeatureSupportType(key)) {
                            const { className, url, label } = this.FEATURE_GENERATE_MAP[key];
                            return (
                                <div className={'app_feature_block ' + className} key={Math.random()} onClick={() => {
                                    window.devices.BREADCRUM_TITLE = label;
                                    history.push(`/management/devices/?dev=${cur_dev_id}&page=${url}`);
                                }}><p>{label}</p></div>
                            );
                        } else { return <div key={Math.random()}/>; }
                    })}
                </div>
            </Flat.Section>
        ));
        return <List />;
    }
}

export default class FeatureShow extends CsxUI.IRQComponent<any> {
    constructor(props: any) { super(props); this.IRQIndex = 'DeviceSystemStatus'; }
    render() {
        const loading = (window.FOCUS_DEVICE && !CsxUtil.isCsxSystemStateConnected(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground contextStyle={{ height: 'auto' }} loading={loading}>
                <FeatureList />
            </Flat.Playground>
        );
    }
}
