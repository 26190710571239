import React from 'react';

import { Table, Icon, Href, Tooltip, Checkbox, Form, Button, Empty, Select, Option, Input, SwitchButton, Modal } from 'cypd';
import { withRouter } from 'react-router-dom';

import { CsxUtil, CsxUI, CsxDesc } from '../../csx';
import { DeviceStatusChart } from '../../chart';
import './page.css';
import { CsxUserPermissionLevel, csxUserPermissionSatisfy } from '../../csx/manager';


declare type DashbooardState = {
    checked_devices: Set<string>;
}

declare type DiscoveryEditForm = {
    macaddr: string;
    ipmode: string;
    ipaddr: string;
    gateway: string;
    netmask: string;
    // static_ipaddr: string;
    // static_gateway: string;
    // static_netmask: string;
}
const { getText } = CsxDesc;

class ScanView extends CsxUI.IRQComponent<{ enabled?: boolean }> {
    state: { cond_s: 'pid' | 'pname' | 'mac' | 'none'; filter_key: string; discovery_form?: DiscoveryEditForm };
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'Dashboard';
        this.MIN_RENDER_INTERVAL = 10000;
        this.state = { cond_s: 'none', filter_key: '' };
    }
    checked_device: Array<string> = [];
    onCheckedDevice = (dev_id: string) => {
        const dev_id_idx = this.checked_device.indexOf(dev_id);
        if (dev_id_idx < 0)
            this.checked_device.push(dev_id);
        else
            this.checked_device.splice(dev_id_idx, 1);
    }
    onAddDevice = () => {
        if (this.checked_device.length > 0 && window.FOCUS_GATEWAY) {
            window.FOCUS_GATEWAY.deviceSynchronize(this.checked_device);
            this.checked_device = [];
        }
    }
    onChangeFilterCondition = (v: string) => { this.setState({ cond_s: v }); }
    onChangeFilterExpression = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ filter_key: e.target.value }); }
    onChangeDeviceIPAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { discovery_form } = this.state;
        if (discovery_form) {
            discovery_form.ipaddr = e.target.value;
            this.setState({ discovery_form });
        }
    }
    onChangeDeviceNetmask = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { discovery_form } = this.state;
        if (discovery_form) {
            discovery_form.netmask = e.target.value;
            this.setState({ discovery_form });
        }
    }
    onChangeDeviceGateway = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { discovery_form } = this.state;
        if (discovery_form) {
            discovery_form.gateway = e.target.value;
            this.setState({ discovery_form });
        }
    }
    onChangeDeviceIPMode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { discovery_form } = this.state;
        if (discovery_form) {
            discovery_form.ipmode = (e.target.checked) ? 'DHCP' : 'STATIC';
            this.setState({ discovery_form });
        }
    }
    onOpenEditDiscovery = (form: DiscoveryEditForm) => { this.setState({ discovery_form: Object.assign({}, form) }); }
    onCloseEditDiscovery = () => { this.setState({ discovery_form: undefined }); }
    onSaveDiscoveryForm = () => {
        const { discovery_form } = this.state;
        if (discovery_form && window.FOCUS_GATEWAY) {
            window.FOCUS_GATEWAY.sendDiscoveryByte(discovery_form.macaddr, [
                `0206${discovery_form.macaddr.split(':').join('')}`,
                `0701${(discovery_form.ipmode === 'DHCP') ? '01' : '00'}`,
                `0701${(discovery_form.ipmode === 'DHCP') ? '01' : '00'}`,
                `0304${discovery_form.ipaddr.split('.').map(v => ((parseInt(v) < 16) ? `0${parseInt(v).toString(16)}` : parseInt(v).toString(16))).join('')}`,
                `0404${discovery_form.netmask.split('.').map(v => ((parseInt(v) < 16) ? `0${parseInt(v).toString(16)}` : parseInt(v).toString(16))).join('')}`,
                `0504${discovery_form.gateway.split('.').map(v => ((parseInt(v) < 16) ? `0${parseInt(v).toString(16)}` : parseInt(v).toString(16))).join('')}`,
            ]);
        }
    }
    render() {
        const { cond_s, filter_key, discovery_form } = this.state;
        const scaned_devices = (window.FOCUS_GATEWAY) ? Array.from(window.FOCUS_GATEWAY.SCANS) : [];
        const assgined_room = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getAttribute('assigned_room') :'';
        const dashboard_permission = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getPermission('dashboard_permission') : CsxUserPermissionLevel.NoAccess;
        const filter_list = scaned_devices.map(device_id => {
            const dev_inst = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(device_id) : undefined;
            if (dev_inst) {
                const discovery_info = dev_inst.DISCOVERY_INFO;
                const { MacAddress, ProductDescription, ProductID, ProductName, IPAddress, IPAddressSource, SubnetMask, Gateway, VendorID } = discovery_info
                if (IPAddress === '0.0.0.0') {
                    return null;
                }
                if (filter_key.length > 0) {
                    if (cond_s === 'pid' && ProductID.indexOf(filter_key) < 0)
                        return null;
                    if (cond_s === 'pname' && ProductName.indexOf(filter_key) < 0)
                        return null;
                    if (cond_s === 'mac' && MacAddress.indexOf(filter_key) < 0)
                        return null;
                }
                const show_name = (ProductName.length > 0) ? ProductName : 'Unknown';
                const show_ip = (IPAddress.length > 0) ? IPAddress : 'Unknwon';
                const desc = `Vender ID : ${VendorID}\nProduct ID : ${ProductID}\nDescription :\n${ProductDescription}\nMAC : ${MacAddress}`;
                // const dhcp_mode_switcher = <SwitchButton style={{ marginLeft: '10px' }} defaultChecked={(IPAddressSource === 'DHCP')} label={[ 'DHCP', 'STATIC' ]}/>;
                const label = <Tooltip direction='top' text={desc}>
                    {`${show_name} (${show_ip})`}
                    {(window.CSX_CUR_USER && window.CSX_CUR_USER.ID === '999') ? <Icon 
                        type='setting' 
                        color='gray' 
                        style={{ transform: 'scale(0.8) translateY(4px)', marginLeft: '10px' }} 
                        // style={{ transform: 'scale(0.8) translateY(4px)', marginLeft: '10px' }} 
                        onClick={() => {
                            this.onOpenEditDiscovery({
                                macaddr: MacAddress,
                                ipaddr: IPAddress,
                                ipmode: IPAddressSource,
                                gateway: Gateway,
                                netmask: SubnetMask
                            });
                        }}
                    /> : undefined}
                </Tooltip>
                return <Checkbox key={`scan_dev_${device_id}`} label={label} onChange={() => { this.onCheckedDevice(device_id); }} />
            } else {
                return null;
            }
        }).filter(checkbox => (checkbox !== null));
        let is_support_add_remove_device = false;
        let ipconfig_valid = true;
        let show_scan_content: React.ReactNode | Array<React.ReactNode> = <Empty text={getText('DASHBOARD_SCAN_DEV_EMPTY')}/>;
        if (window.CSX_CUR_AUTH) {
            is_support_add_remove_device = csxUserPermissionSatisfy(dashboard_permission, CsxUserPermissionLevel.FullAccess);
        }
        if (discovery_form) {
            if (!(CsxUtil.isIP(discovery_form.ipaddr) && CsxUtil.isIP(discovery_form.netmask) && CsxUtil.isIP(discovery_form.gateway)))
                ipconfig_valid = false;
        }
        if (filter_list.length > 0) {
            show_scan_content = filter_list;
        }
        if (!csxUserPermissionSatisfy(dashboard_permission, CsxUserPermissionLevel.FullAccess) || assgined_room !== 'all') {
            show_scan_content = <Empty text={getText('NOTIFY_MSG_UNAUTHORIZED_SHORT')}/>;
        }
        return (
            <div className={'scan_container' + (this.props.enabled ? '' : ' hidden')} style={{ maxHeight: '400px' }}>
                <Form.Item>
                    <Select value={cond_s} onChange={this.onChangeFilterCondition}>
                        <Option value='none'>{getText('DASHBOARD_IP_FILTER_NONE')}</Option>
                        <Option value='pid'>{getText('DASHBOARD_IP_FILTER_PID')}</Option>
                        <Option value='pname'>{getText('DASHBOARD_IP_FILTER_PNAME')}</Option>
                        <Option value='mac'>{getText('DASHBOARD_IP_FILTER_MAC')}</Option>
                    </Select>
                    <Button style={CsxUI.getHiddenStyle(is_support_add_remove_device)} type='primary' icon='plus' onClick={this.onAddDevice}>{getText('ADD')}</Button>
                </Form.Item>
                <Form.Item style={CsxUI.getHiddenStyle(cond_s !== 'none')}><Input value={filter_key} placeholder='Filter Expression' onChange={this.onChangeFilterExpression}/></Form.Item>
                <div className='option_wrapper' style={{ marginBottom: '20px' }}>
                    {show_scan_content}
                </div>
                <Modal
                    title={getText('DASHBOARD_IP_CONFIG_LABEL')}
                    visible={!!discovery_form}
                    onClose={this.onCloseEditDiscovery}
                >
                    <Form.Form labelStyle={{ width: '100px' }}> 
                        <Form.Item label={getText('DASHBOARD_IP_CONFIG_MAC')}><span style={{ textIndent: '5px' }}>{discovery_form ? discovery_form.macaddr : ''}</span></Form.Item>
                        <Form.Item label={getText('DASHBOARD_IP_CONFIG_IPMODE')}><SwitchButton onChange={this.onChangeDeviceIPMode} checked={(discovery_form ? (discovery_form.ipmode === 'DHCP') : false)} label={[ 'DHCP', 'STATIC' ]}/></Form.Item>
                        <Form.Item label={getText('DASHBOARD_IP_CONFIG_IPADDR')}><Input onChange={this.onChangeDeviceIPAddress} disabled={(discovery_form ? (discovery_form.ipmode === 'DHCP') : false)} value={discovery_form ? discovery_form.ipaddr : ''}/></Form.Item>
                        <Form.Item label={getText('DASHBOARD_IP_CONFIG_NM')}><Input onChange={this.onChangeDeviceNetmask} disabled={(discovery_form ? (discovery_form.ipmode === 'DHCP') : false)} value={discovery_form ? discovery_form.netmask : ''}/></Form.Item>
                        <Form.Item label={getText('DASHBOARD_IP_CONFIG_GW')}><Input onChange={this.onChangeDeviceGateway} disabled={(discovery_form ? (discovery_form.ipmode === 'DHCP') : false)} value={discovery_form ? discovery_form.gateway : ''}/></Form.Item>
                        <Form.Item error={(!ipconfig_valid ? 'IP configure format error' : '')} style={{ justifyContent: 'flex-end' }}><Button disabled={!ipconfig_valid} onClick={this.onSaveDiscoveryForm}>{getText('SAVE')}</Button></Form.Item>
                    </Form.Form>
                </Modal>
            </div>
        );
    }
}

class DeviceInfoCard extends CsxUI.IRQComponent<any> {
    state: DashbooardState = { checked_devices: new Set() };
    fileSelector?: HTMLInputElement | null;
    constructor(props: any) { super(props); this.IRQIndex = 'Dashboard'; this.MIN_RENDER_INTERVAL = 1000; }
    triggerFileSelector = () => {
        if (this.fileSelector)
            this.fileSelector.click();
        else
            window.alert(getText('ALERT_OP_UNSUPPORT_NATIVE_OUT'));
    }
    fwUpdate = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const fw_file = (event.target.files) ? event.target.files[0] : undefined;
        const { checked_devices } = this.state;
        if (fw_file && checked_devices.size > 0) {
            window.userConfirm(getText('CONFIRM_FWUP_DASHBOARD'), async (ok) => {
                if (ok && window.FOCUS_GATEWAY) {
                    try {
                        await window.FOCUS_GATEWAY.deviceFirmwareUpdate(fw_file, Array.from(checked_devices));
                    } catch (error) {
                        console.log('[dashboard->page.tsx->DashboardPage->fwUpdate] error :>> ', error);
                    }
                }
            });
        }
        event.target.value = '';
    }
    deleteDevice = () => {
        const { checked_devices } = this.state;
        if (checked_devices.size > 0) {
            window.userConfirm(getText('CONFIRM_REMOVE_DEVICE'), (ok) => {
                if (ok && window.FOCUS_GATEWAY) {
                    const request = Array.from(checked_devices).map(dev_id => `?002:${dev_id}`).join('\n') + '\n';
                    window.FOCUS_GATEWAY.handleRequest(request);
                    this.setState({ checked_devices: new Set() });
                }
            });
        }
    }
    onCheckDevice = (row_idx: number, checked: boolean) => {
        const { checked_devices } = this.state;
        const dev_list = (window.FOCUS_GATEWAY) ? Array.from(window.FOCUS_GATEWAY.DEVICES) : [];
        const new_checked = new Set(checked_devices);
        if (checked)
            new_checked.add(dev_list[row_idx]);
        else
            new_checked.delete(dev_list[row_idx]);
        this.setState({ checked_devices: new_checked });
    }
    onCheckAll = () => {
        const { checked_devices } = this.state;
        const dev_list = (window.FOCUS_GATEWAY) ? Array.from(window.FOCUS_GATEWAY.DEVICES) : [];
        const full_checked: Set<string> = new Set();

        dev_list.forEach((device_id) => { full_checked.add(device_id); })
        if (checked_devices.size < full_checked.size)
            this.setState({ checked_devices: full_checked });
        else
            this.setState({ checked_devices: new Set() });
    }
    render() {
        const { checked_devices } = this.state;
        const fields = [getText('DASHBOARD_DEV_STATUS'), getText('DASHBOARD_DEV_NAME'), getText('DASHBOARD_DEV_IPADDR'),];
        // const fields = [<div style={{ cursor: 'pointer' }} onClick={this.onCheckAll}>Select All</div>, 'Device Name', 'IP Address', 'State',];
        const dev_list = (window.FOCUS_GATEWAY) ? Array.from(window.FOCUS_GATEWAY.DEVICES) : [];
        const rows = dev_list.map(device_id => {
            const dev_inst = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(device_id) : undefined;
            if (dev_inst) {
                const discovery_info = dev_inst.DISCOVERY_INFO;
                let c_status
                let c_status_tip = ''
                c_status = <Icon type='edit' />;
                switch (dev_inst.SYS_STA) {
                    case CsxUtil.SYS_STA_TYPE.Sync:
                        c_status_tip = getText('TOOLTIP_DASHBOARD_DEV_SYNC');
                        c_status = <Icon type='led-green' />;
                        break;
                    case CsxUtil.SYS_STA_TYPE.A17_Connect_Error:
                        c_status_tip = getText('TOOLTIP_DASHBOARD_DEV_NOT_SUPPORT');
                        c_status = <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><Icon type='led-yellow' />0%</div>;
                        break;
                    case CsxUtil.SYS_STA_TYPE.A17_Connect:
                    case CsxUtil.SYS_STA_TYPE.A13_Connect:
                    case CsxUtil.SYS_STA_TYPE.A18_Connect:
                    case CsxUtil.SYS_STA_TYPE.All_Connect:
                        c_status_tip = getText('TOOLTIP_DASHBOARD_DEV_SYNCING');
                        c_status = <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}><Icon type='led-yellow' />{`${(discovery_info.DeviceSync.length > 0) ? discovery_info.DeviceSync : 0}%`}</div>;
                        break;
                    case CsxUtil.SYS_STA_TYPE.TCP_Connect:
                        c_status_tip = getText('TOOLTIP_DASHBOARD_DEV_CONNECTING');
                        c_status = <Icon type='led-red' />;
                        break;
                    case CsxUtil.SYS_STA_TYPE.TCP_Connect_Error:
                        c_status_tip = getText('TOOLTIP_DASHBOARD_DEV_CONNECT_FAIL');
                        c_status = <Icon type='led-red' />;
                        break;
                    case CsxUtil.SYS_STA_TYPE.Offline:
                        c_status_tip = getText('TOOLTIP_DASHBOARD_DEV_OFFLINE');
                        c_status = <Icon type='led-gray' />;
                        break;
                    case CsxUtil.SYS_STA_TYPE.Firmware_Update:
                        c_status_tip = getText('TOOLTIP_DASHBOARD_DEV_FWUP');
                        c_status = <Icon type='led-blue' />;
                        break;
                    default: // skip unadded device
                        return [];
                }
                const dev_name = (dev_inst.NICKNAME) ? dev_inst.NICKNAME : `Unknown(${dev_inst.ID.slice(9)})`;
                const ip = (dev_inst.STA.A82) ? Object.keys(dev_inst.STA.A82).map(key => dev_inst.STA.A82[key]).join(' ') : dev_inst.STA.A46;
                const Linker = withRouter(({ history }) => (
                    <abbr title={dev_name}>
                        <Href to={() => { history.push(`/management/devices/?dev=${dev_inst.ID.split(':').join('')}`); }}>{dev_name}</Href>
                    </abbr>
                ));
                return [
                    <Tooltip direction='left' text={c_status_tip}>
                        {c_status}
                    </Tooltip>,
                    <div style={{ position: 'relative', width: '100%', height: '100%', overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
                        <Linker />
                    </div>,
                    (typeof ip === 'string' && ip.length > 0) ? <div style={{ whiteSpace: 'pre-line', textAlign: 'center' }}>{ip}</div> : discovery_info.IPAddress, 
                ];
            } else {
                return [];
            }
        }).filter(row => row.length);

        let is_support_batch_fwupdate = false;
        let is_support_add_remove_device = false;
        if (window.CSX_CUR_AUTH) {
            is_support_add_remove_device = csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('dashboard_permission'), CsxUserPermissionLevel.FullAccess);
            is_support_batch_fwupdate = csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('dashboard_permission'), CsxUserPermissionLevel.FullAccess);
        }

        return (
            <Card label={getText('DASHBOARD_DEV_LABEL')}>
                <Form.Item style={{ justifyContent: 'center' }}>
                    {!window.APP_ON_HDMI ? <input type='file' ref={(inst) => { this.fileSelector = inst; }} onChange={this.fwUpdate} style={{ display: 'none' }} /> : undefined}
                    <Button icon='upload' style={CsxUI.getHiddenStyle(is_support_batch_fwupdate)} onClick={this.triggerFileSelector} disabled={checked_devices.size === 0}>Batch Upgrade</Button>
                    <Button type='danger' style={CsxUI.getHiddenStyle(is_support_add_remove_device)} disabled={(checked_devices.size === 0)} onClick={this.deleteDevice}>Remove</Button>
                </Form.Item>
                <Table
                    headers={fields}
                    rows={rows}
                    pagination={true}
                    rowLimit={6}
                    columnWidth={[80, 150, 140]}
                    responsive='shorten'
                    shortenProps={{ 
                        layout: {
                            bottomLeft: 1,
                            topLeft: -1,
                            bottomRight: [0],
                            topRight: [2]
                        }
                    }}
                    checkable
                    checkList={Array.from(checked_devices).map(device_id => dev_list.indexOf(device_id))}
                    onCheck={this.onCheckDevice}
                />
            </Card>
        );
    }
}

export default class DashboardPage extends React.Component {
    render() {
        return (
            <div className='app_dashboard_container'>
                <Grid>
                    <Row>
                        <Card label={getText('DASHBOARD_HEALTH_MANAGE_LABEL')}><DeviceStatusChart /></Card>
                    </Row>
                    <Row>
                        <DeviceInfoCard />
                        <Card label={getText('DASHBOARD_SCAN_DEV_LABEL')}><ScanView enabled={true} /></Card>
                    </Row>
                </Grid>
            </div>
        );
    }
}

class Grid extends React.Component {
    render() {
        return (
            <div className='cypd-grid'>{this.props.children}</div>
        );
    }
}

class Row extends React.Component {
    render() {
        return (
            <div className='cypd-grid-row'>{this.props.children}</div>
        );
    }
}

declare type CardProps = {
    label: React.ReactNode;
    className?: string;
    style?: React.CSSProperties;
    contentStyle?: React.CSSProperties;
    titleStyle?: React.CSSProperties;
}

class Card extends React.Component<CardProps> {
    render() {
        const { label, className, style, contentStyle, titleStyle } = this.props;
        let cardClass = 'cypd-grid-card';
        if (className)
            cardClass += ` ${className}`;
        return (
            <div className='cypd-grid-card-wrapper'>
                <div className={cardClass} style={style}>
                    <div className='title' style={titleStyle}>{label}</div>
                    <div className='content' style={contentStyle}>{this.props.children}</div>
                </div>
            </div>
        )
    }
}

