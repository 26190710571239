import React from 'react';
import { Modal, Input, Form, Button, Href, Icon, Table, Notify, Select, Option, RadioGroup, Flat, Tooltip } from 'cypd';
import { withRouter } from 'react-router-dom';

import { CsxEventSystem, CsxUI, CsxFeature, CsxDesc, CsxUtil } from '../../csx';
import AutomationDrawCanvas from './automation_draw/canvas';

import './page.css'
import { CsxUserPermissionLevel, csxUserPermissionSatisfy } from '../../csx/manager';

declare global {
    interface Window {
        routeEvent: {
            FOCUS_AUTOMATION: () => CsxEventSystem.CsxAutomation | undefined;
        };
    }
}

declare type AutomationHomeState = {
    popup?: 'create' | 'edit';
    new_automation_name: string;
    list_draggable: boolean;
    new_automation_order?: Array<string>;
    row_limit: number;
    page_index: number;
    keyword_filter: string;
    create_mode: 'exist' | 'new';
    create_from: string;
}

const AUTOMATION_TABLE_ROW_LIMITS_LOCAL_KEY = 'AUTOMATION_TABLE_ROW_LIMITS_LOCAL_KEY';
const AUTOMATION_TABLE_KEY_FILTER_LOCAL_KEY = 'AUTOMATION_TABLE_KEY_FILTER_LOCAL_KEY';
const { getText } = CsxDesc;

class Breadcrum extends CsxUI.IRQComponent<any> {
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'NoUpdate';
    }
    render() {
        const Component = withRouter(({ history }) => (
            <div className='breadcrum'>
                <Href className='breadcrum-item' to={() => { history.push(`/management/es/automation`); }}>{getText('APP_NAV_AUTOMATION')}</Href>
            </div>
        ));
        return <Component />;
    }
}

class AutomationHome extends CsxUI.IRQComponent<any> {
    state: AutomationHomeState;
    instance: CsxFeature.CsxEventSystemDevice;
    automation_s = '';
    automationFileSelector: HTMLInputElement | null | undefined;
    importTmpAutomation?: CsxEventSystem.CsxAutomation;
    constructor(props: any) {
        super(props);
        const local_store_row_limit = window.localStorage.getItem(AUTOMATION_TABLE_ROW_LIMITS_LOCAL_KEY);
        const local_store_key_filter = window.localStorage.getItem(AUTOMATION_TABLE_KEY_FILTER_LOCAL_KEY);
        this.state = {
            new_automation_name: '',
            list_draggable: false,
            row_limit: parseInt((local_store_row_limit) ? local_store_row_limit : '10'),
            page_index: 0,
            keyword_filter: (local_store_key_filter) ? local_store_key_filter : '',
            create_from: '',
            create_mode: 'new',
        };
        this.IRQIndex = 'EventSystem';
        this.instance = new CsxFeature.CsxEventSystemDevice();
    }
    pop = (modal?: 'create' | 'edit') => {
        const select_automation = this.instance.Automation(this.automation_s);
        let default_name = '';
        if (modal === 'create') {
            if (this.instance.AutomationSize() >= this.instance.AutomationSizeLimit()) {
                Notify({ title: getText('NOTIFY_TITLE_CREATE_AUTOMATION'), context: `${getText('NOTIFY_MSG_AUTOMATION_SIZE_LIMIT')} ${this.instance.AutomationSizeLimit()}.`, type: 'error', timeout: 10000 });
                return;
            }
            default_name = (this.importTmpAutomation) ? this.importTmpAutomation.NAME : '';
        } else if (modal === 'edit') {
            default_name = (select_automation) ? select_automation.NAME : '';
        } else {
            this.automation_s = '';
            this.importTmpAutomation = undefined;
        }
        this.setState({ popup: modal, new_automation_name: default_name });
    }
    onChangeNewAutomationName = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ new_automation_name: e.target.value }); }
    addAutomation = () => {
        const { new_automation_name, create_from, create_mode } = this.state;
        const create_from_automation = this.instance.Automation(create_from);
        const create_import = (create_mode === 'exist' && create_from_automation) ? create_from_automation.toJson() : null;
        const new_automation = (this.importTmpAutomation) ? this.importTmpAutomation : new CsxEventSystem.CsxAutomation('0', create_import);
        new_automation.NAME = new_automation_name;
        this.instance.SetAutomation(new_automation);
        this.pop();
        this.setState({ page_index: -1 });
    }
    delAutomation = (automation_id: string) => {
        window.userConfirm(getText('CONFIRM_REMOVE_AUTOMATION'), (ok: boolean) => {
            if (ok)
                this.instance.DeleteAutomation([automation_id]);
        });
    }
    onSaveAutomationName = (automation_id: string) => {
        const { new_automation_name } = this.state;
        const automation = this.instance.Automation(automation_id);
        if (automation) {
            automation.NAME = new_automation_name;
            this.instance.SetAutomation(automation);
            this.pop(undefined);
            this.setState({ new_automation_name: '' });
        }
    }
    onClickEditName = (automation_id: string) => {
        const automation = this.instance.Automation(automation_id);
        if (automation) {
            this.automation_s = automation_id;
            this.setState({ new_automation_name: automation.NAME });
            this.pop('edit');
        }
    }
    disableArrange = () => { this.setState({ list_draggable: false, new_automation_order: undefined }); }
    enableArrange = () => { this.setState({ list_draggable: true }); }
    handleArrangeSave = () => {
        const { new_automation_order } = this.state;
        if (new_automation_order) {
            this.instance.ArrangeAutomation(new_automation_order);
            this.setState({ list_draggable: false, new_automation_order: undefined });
        }
    }
    triggerAuotmationFileSelector = () => {
        if (this.automationFileSelector) {
            this.automationFileSelector.click();
        } else {
            window.alert(getText('ALERT_OP_UNSUPPORT_NATIVE_OUT'));
        }
    }
    automationUpdate = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const notify_title = getText('NOTIFY_TITLE_IMPORT_AUTOMATION');
        const json_file = (event.target.files) ? event.target.files[0] : undefined;
        if (json_file) {
            CsxUtil.importHandler({
                file: json_file,
                validateFilename: (fn) => (fn.split('.').slice(-2).join('.').toLowerCase() === 'auto.json'),
                onConfirm: (parseJson) => {
                    if (CsxEventSystem.isCsxAutomationJson(parseJson) || CsxEventSystem.Old_isCsxAutomationJson(parseJson)) {
                        if (!CsxEventSystem.isCsxAutomationJson(parseJson) && CsxEventSystem.Old_isCsxAutomationJson(parseJson))
                            Notify({ type: 'warning', title: notify_title, context: getText('NOTIFY_MSG_DEPRECATE_FORMAT'), timeout: 5000 });
                        this.importTmpAutomation = new CsxEventSystem.CsxAutomation('0', parseJson);
                        // console.log('this.importTmpAutomation.NAME :>> ', this.importTmpAutomation.NAME);
                        this.pop('create');
                    } else {
                        Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_FILE_FORMAT_INVALID'), timeout: 10000 });
                    }
                },
                onError: (msg, timeout) => {
                    Notify({ type: 'error', title: notify_title, context: msg, timeout });
                }
            });
        }
        event.target.value = '';
    }
    onDownloadAutomation = (automation_id: string) => {
        const automation = this.instance.Automation(automation_id);
        if (automation) {
            CsxUtil.downloadAsJson(automation.FILENAME, JSON.stringify(automation.toJson(), null, 4));
        } else {
            Notify({ type: 'error', title: getText('NOTIFY_TITLE_FAIL'), context: getText('NOTIFY_MSG_CONFIG_NOT_FOUND') });
        }
    }
    onChangeCreateMode = (value: string) => { this.setState({ create_mode: value, create_from: '' }); }
    onChangeCreateFrom = (value: string) => {
        const automation = this.instance.Automation(value);
        if (automation) {
            this.setState({ create_from: value, new_automation_name: automation.NAME });
        }
    }
    onChangeRowLimit = (value: string) => { this.setState({ row_limit: parseInt(value) }); window.localStorage.setItem(AUTOMATION_TABLE_ROW_LIMITS_LOCAL_KEY, value); }
    onChangePage = (value: number) => { this.setState({ page_index: value }); }
    onChangeKeywordFilter = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ keyword_filter: e.target.value }); window.localStorage.setItem(AUTOMATION_TABLE_KEY_FILTER_LOCAL_KEY, e.target.value); }
    render() {
        // const { popup, new_automation_name, list_draggable, new_automation_order, row_limit, keyword_filter } = this.state;
        const { popup, new_automation_name, list_draggable, row_limit, page_index, keyword_filter, create_mode, create_from } = this.state;
        const automation_arr = CsxUtil.ArrayReverse(Array.from(this.instance.AutomationSet()));
        const automation_edit_permission = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getPermission('automation_edit') : CsxUserPermissionLevel.NoAccess;
        let name_valid = true;
        let name_error = '';

        const modal_title: { [s in ('create' | 'edit')]: string } = {
            create: CsxDesc.getText('AUTOMATION_ADD_MODAL_HEADER'),
            edit: CsxDesc.getText('AUTOMATION_ADD_MODAL_HEADER'),
        }
        const span_style: React.CSSProperties = {
            position: 'relative',
            // marginBottom: '10px',
            whiteSpace: 'nowrap',
            color: '#707070',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'stretch',
            alignItems: 'center',
            height: '100%',
            flexGrow: 2,
            // maxWidth: `${(container_width / 2) - 10}px`,
            maxWidth: `calc(50% - 10px)`,
        };
        const row_limit_opt = [10, 20, 40];

        if (new_automation_name.length === 0 ||
            CsxUtil.hasChinese(new_automation_name) ||
            this.instance.CheckAutomationNameExist(new_automation_name)) {
            name_valid = false;
            name_error = getText('HINT_DUPLICATE_NAME');
        }

        return (
            <Flat.Playground style={{ height: '100%' }} contextStyle={{ height: 'auto' }}>
                <Breadcrum />
                <Flat.Section title={getText('AUTOMATION_OVERVIEW_ALL')} disableCollapsed style={{ width: 'calc(100% - 100px)', maxWidth: 'none' }} titleStyle={{ backgroundColor: 'white', color: '#707070', fontSize: '18px', padding: '10px' }}>
                    {(csxUserPermissionSatisfy(automation_edit_permission, CsxUserPermissionLevel.FullAccess)) ? <Form.Item style={{ justifyContent: 'flex-end', maxWidth: '600px', flexShrink: 0 }}>
                        <Button icon='upload' onClick={this.triggerAuotmationFileSelector}>{getText('UPLOAD')}</Button>
                        <Button style={{ marginRight: '0' }} disabled={list_draggable} icon='plus' type='primary' onClick={() => { this.pop('create'); }}>{CsxDesc.getText('EVENT_SYS_AUTOMATION_ADD_LABEL')}</Button>
                        {!window.APP_ON_HDMI ? <input type='file' style={{ display: 'none' }} ref={inst => { this.automationFileSelector = inst; }} onChange={this.automationUpdate} accept='.json' /> : undefined}
                    </Form.Item> : undefined}
                    <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', maxWidth: '600px', marginBottom: '10px' }}>
                        <span style={span_style}>
                            Show items
                            <Select value={row_limit} size='small' style={{ margin: '0 5px' }} onChange={this.onChangeRowLimit}>{row_limit_opt.map(value => <Option key={`row_limit_${value}`} value={value}>{value}</Option>)}</Select>
                        </span>
                        <span style={span_style}>
                            Search
                            <Input value={keyword_filter} placeholder='Automation Name' size='small' onChange={this.onChangeKeywordFilter} style={{ flexGrow: 2, marginLeft: '5px' }} />
                        </span>
                    </div>
                    <Table
                        headers={["ID", getText('NAME'), getText('AUTOMATION_OVERVIEW_OPERATION')]}
                        columnWidth={[60, NaN, 120]}
                        rows={CsxUtil.ArrayReverse(automation_arr).map(automation_id => {
                            const automation = this.instance.Automation(automation_id);
                            if (automation) {
                                const automation_name = (automation) ? automation.NAME : 'scenario_not_found';
                                const Linker = withRouter(({ history }) => (
                                    <Href to={() => { history.push(`/management/es/automation/?aid=${automation_id}`); }}>{automation_name}</Href>
                                ));
                                return (keyword_filter.length > 0 && automation.NAME.toLowerCase().indexOf(keyword_filter.toLowerCase()) < 0) ? [] : [
                                    automation_id,
                                    <Linker />,
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        {(csxUserPermissionSatisfy(automation_edit_permission, CsxUserPermissionLevel.EditAssigned)) ? <Tooltip text={getText('TOOLTIP_EDIT_NAME')}><Icon style={{ marginRight: '3px' }} type='write' color='rgb(0,138,158)' onClick={() => { this.onClickEditName(automation_id); }} /></Tooltip> : undefined}
                                        <Tooltip text={getText('TOOLTIP_EXPORT_AUTOMATION')}><Icon style={{ marginRight: '3px' }} color='rgb(0,138,158)' type='download' onClick={() => { this.onDownloadAutomation(automation_id); }} /></Tooltip>
                                        {(csxUserPermissionSatisfy(automation_edit_permission, CsxUserPermissionLevel.FullAccess)) ? <Tooltip text={getText('TOOLTIP_DELETE_AUTOMATION')}><Icon type='trashcan' color='#b92454' onClick={() => { this.delAutomation(automation_id); }} /></Tooltip> : undefined}
                                    </div>
                                ];
                            } else {
                                return [];
                            }
                        }).filter(row => (row.length > 0))}
                        bodyStyle={{ width: '600px' }}
                        responsive='shorten'
                        shortenProps={{
                            layout: {
                                topLeft: -1,
                                bottomLeft: 0,
                                topRight: [],
                                bottomRight: [1],
                            }
                        }}
                        pagination={true}
                        rowLimit={row_limit}
                        page={page_index}
                        onPageChange={this.onChangePage}
                    ></Table>
                    <Modal
                        title={(popup) ? modal_title[popup] : ''}
                        visible={typeof popup !== 'undefined'}
                        onClose={() => { this.pop(undefined); }}
                        style={{ maxWidth: '500px' }}
                    >
                        <Form.Form style={CsxUI.getHiddenStyle(popup === 'create')}>
                            <Form.Item>
                                <RadioGroup 
                                    options={[
                                        { label: getText('AUTOMATION_OVERVIEW_CREATE_NEW'), value: 'new' },
                                        { label: getText('AUTOMATION_OVERVIEW_CREATE_FROM_EXIST'), value: 'exist' },
                                    ]}
                                    value={create_mode}
                                    onChange={this.onChangeCreateMode}
                                />
                            </Form.Item>
                            <Form.Item label={getText('FROM')} style={CsxUI.getHiddenStyle(create_mode === 'exist')}>
                                <Select value={create_from} placeholder="Existing group" onChange={this.onChangeCreateFrom}>
                                    {automation_arr.map(automation_id => {
                                        const auotmation = this.instance.Automation(automation_id);
                                        if (auotmation) {
                                            return (
                                                <Option key={`create_from_exist_${automation_id}`} value={automation_id}>{auotmation.NAME}</Option>
                                            );
                                        } else {
                                            return null;
                                        }
                                    }).filter(node => !!node)}
                                </Select>
                            </Form.Item>
                            <Form.Item error={name_error}>
                                <Input
                                    placeholder={CsxDesc.getText('AUTOMATION_ADD_MODAL_FI_LABEL')}
                                    className='automation_add_input'
                                    value={new_automation_name}
                                    onChange={this.onChangeNewAutomationName}
                                />
                                <Button type='primary' onClick={this.addAutomation} disabled={!name_valid}>{CsxDesc.getText('AUTOMATION_ADD_MODAL_FI_BUTTON')}</Button>
                            </Form.Item>
                            {(this.importTmpAutomation) ? <Form.Item style={{ wordBreak: 'break-word' }}>{`Contains ${this.importTmpAutomation.EVENT_CNT} events, ${this.importTmpAutomation.LOGIC_CNT} logics, ${this.importTmpAutomation.ACTION_CNT} actions, and ${this.importTmpAutomation.EDGE_CNT} connections in this configuration`}</Form.Item> : undefined}
                        </Form.Form>
                        <Form.Form style={CsxUI.getHiddenStyle(popup === 'edit')}>
                            <Form.Item error={name_error}>
                                <Input
                                    placeholder={CsxDesc.getText('AUTOMATION_ADD_MODAL_FI_LABEL')}
                                    className='automation_add_input'
                                    value={new_automation_name}
                                    onChange={this.onChangeNewAutomationName}
                                />
                                <Button type='primary' onClick={() => { this.onSaveAutomationName(this.automation_s); }} disabled={!name_valid}>{getText('SAVE')}</Button>
                            </Form.Item>
                        </Form.Form>
                    </Modal>
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

export default class AutomationPage extends CsxUI.IRQComponent<any> {
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'NoUpdate';
        window.routeEvent = {
            FOCUS_AUTOMATION: () => {
                const aid = this.getURLParameter().get('aid');
                const gw_inst = window.FOCUS_GATEWAY;
                return (aid && gw_inst) ? gw_inst.getAutomation(aid) : undefined;
            }
        };
    }
    render() {
        const automation_id = this.getURLParameter().get('aid');
        const center = (automation_id) ? <AutomationDrawCanvas /> : <AutomationHome />;
        return center;
    }
}
