import React from 'react';
import { Route } from 'react-router-dom';
import { Form, Select, SelectNative, Option, SwitchButton, Button, Input, Notify, ProgressBar, Icon, Table, Modal, Checkbox, DateTime, Tree, RadioGroup, Tooltip, Spin, Flat } from 'cypd';

import { CsxUI, CsxFeature, CsxControlSystem, CsxUtil, CsxEventSystem, CsxDesc } from '../../csx';

import './page.css';
import { UserFunctionType, UserAttributeType, UserPermissionType, CsxUserPermissionLevel, isCsxUserPermissionLevel, csxUserPermissionSatisfy } from '../../csx/manager';
// import CryptoJS from 'crypto-js';


declare type USER_DEFAULT_CONFIG_TYPE = 'user' | 'power-user' | 'administrator';
declare type UserManageModalType = 'none' | 'create' | 'edit' | 'assign_room' | 'guest';
// declare type BackupModalType = 'none' | 'icon' | 'automation' | 'scenario' | 'room';
declare type BackupModalType = 'none' | 'automation' | 'scenario' | 'room' | 'icon_import_option';

const { getText } = CsxDesc;

const tree_node_label = (text: string, children: Array<React.ReactNode>, labelWidth?: string) => {
    return (
        <div className='edid_node_label'>
            <div className='node_text' style={{ width: labelWidth }}>{text}</div>
            {children}
        </div>
    );
}

class UserManagementBlock extends CsxUI.IRQComponent<any> {
    state: {
        usr_select: string;
        modal: UserManageModalType;
        temp_user_auth: CsxControlSystem.CsxAuthorization;
        use_default_config?: USER_DEFAULT_CONFIG_TYPE;
    }
    instance: CsxFeature.CsxUserManagementDevice;
    eventInstance: CsxFeature.CsxEventSystemDevice;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'AuthenticationUpdate';
        this.instance = new CsxFeature.CsxUserManagementDevice();
        this.eventInstance = new CsxFeature.CsxEventSystemDevice();
        this.state = {
            usr_select: '',
            modal: 'none',
            temp_user_auth: new CsxControlSystem.CsxAuthorization(),
        };
    }
    onSaveUserAuth = () => {
        const { temp_user_auth, usr_select } = this.state;

        if (usr_select.length >= 0 && window.CSX_CUR_USER) {
            const temp_username = temp_user_auth.getAttribute('username');
            const exist_username_list = window.CSX_CUR_USER.LOCAL_USER_LIST.map((usr_id): string => {
                if (window.CSX_CUR_USER && usr_id !== usr_select) {
                    const usr_auth = window.CSX_CUR_USER.getLocalUserAuth(usr_id);
                    if (usr_auth) {
                        const username = usr_auth.getAttribute('username');
                        return (username) ? username : '';
                    }
                }
                return '';
            }).filter(username => (username.length > 0));
            if (exist_username_list.indexOf(temp_username) >= 0) {
                Notify({ type: 'error', title: getText('NOTIFY_TITLE_FAIL'), context: getText('NOTIFY_MSG_USERNAME_USED') });
                return;
            }
            window.userConfirm(getText('CONFIRM_EDIT_USER'), ok => {
                if (ok) {
                    this.instance.SetUserAuthorization(usr_select, temp_user_auth);
                    this.onCloseModal();
                }
            });
        }
    }
    onCreateUser = () => {
        const { temp_user_auth } = this.state;
        if (window.CSX_CUR_USER) {
            const temp_username = temp_user_auth.getAttribute('username');
            const exist_username_list = window.CSX_CUR_USER.LOCAL_USER_LIST.map((usr_id): string => {
                if (window.CSX_CUR_USER) {
                    const usr_auth = window.CSX_CUR_USER.getLocalUserAuth(usr_id);
                    if (usr_auth) {
                        const username = usr_auth.getAttribute('username');
                        return (username) ? username : '';
                    }
                }
                return '';
            });
            console.log('user length', exist_username_list.length)
            if (exist_username_list.length >= this.instance.UserSizeLimit() + 2) {
                Notify({ type: 'error', title: getText('NOTIFY_TITLE_FAIL'), context: `${getText('NOTIFY_MSG_USER_SIZE_LIMIT')} ${this.instance.UserSizeLimit()}` });
            }
            if (temp_username && temp_username.length > 0) {
                if (exist_username_list.indexOf(temp_username) < 0) {
                    this.instance.SetUserAuthorization('0', temp_user_auth);
                    this.onCloseModal();
                } else {
                    Notify({ type: 'error', title: getText('NOTIFY_TITLE_FAIL'), context: getText('NOTIFY_MSG_USERNAME_USED') });
                }
            } else {
                Notify({ type: 'error', title: getText('NOTIFY_TITLE_FAIL'), context: getText('NOTIFY_MSG_USERNAME_EMPTY') });
            }
        }
    }
    onChangeRoomAssignAll = (checked: boolean) => {
        const { temp_user_auth } = this.state;
        const new_auth = temp_user_auth.clone();
        if (checked) {
            new_auth.setAttribute('assigned_room', 'all');
        } else {
            new_auth.setAttribute('assigned_room', '');
        }
        this.setState({ temp_user_auth: new_auth });
    }
    onChangeRoomAssign = (room_id: string, checked: boolean) => {
        const { temp_user_auth } = this.state;
        const new_auth = temp_user_auth.clone();
        const new_room_assign_set = new_auth.getAssignedRoom();
        if (checked) {
            new_room_assign_set.add(room_id);
        } else {
            new_room_assign_set.delete(room_id);
        }
        new_auth.setAttribute('assigned_room', Array.from(new_room_assign_set).join(','));
        this.setState({ temp_user_auth: new_auth });
    }
    onChangeTempUserSupport = (func_id: UserFunctionType, value: string) => {
        const { temp_user_auth } = this.state;
        const new_auth = temp_user_auth.clone();
        new_auth.set(func_id, value);
        this.setState({ temp_user_auth: new_auth });
    }
    onChangeTempUserAttr = (attr_id: UserAttributeType, value: string) => {
        const { temp_user_auth } = this.state;
        let attrValue = value.slice(); 
        const new_auth = temp_user_auth.clone();
        new_auth.setAttribute(attr_id, attrValue);
        this.setState({ temp_user_auth: new_auth });
    }
    onChangeTempUserPms = (pms_id: UserPermissionType, value: string) => {
        const { temp_user_auth } = this.state;
        const new_auth = temp_user_auth.clone();

        if (isCsxUserPermissionLevel(value)) {
            new_auth.setPermission(pms_id, value);
            this.setState({ temp_user_auth: new_auth });
        }
    }
    onOpenEditGuest = () => { this.setState({ usr_select: '1', modal: 'guest', temp_user_auth: this.instance.UserAuthorization('1') }); }
    onOpenEditUser = (usr_id: string) => { this.setState({ usr_select: usr_id, modal: 'edit', temp_user_auth: this.instance.UserAuthorization(usr_id) }); }
    onOpenAssignRoom = (usr_id: string) => {
        const temp_user_auth = this.instance.UserAuthorization(usr_id);
        if (temp_user_auth) {
            this.setState({
                usr_select: usr_id,
                modal: 'assign_room',
                temp_user_auth: temp_user_auth.clone(),
            });
        }
    }
    onOpenCreateUser = () => { const new_auth = new CsxControlSystem.CsxAuthorization(); new_auth.setDefaultUser(); this.setState({ usr_select: '', modal: 'create', temp_user_auth: new_auth, use_default_config: 'user' }); }
    onCloseModal = () => { this.setState({ usr_select: '', modal: 'none', temp_user_auth: new CsxControlSystem.CsxAuthorization() }); }
    onUseDefaultConfig = (config?: string) => {
        const { temp_user_auth } = this.state;
        if (config) {
            const new_user_auth = temp_user_auth.clone();
            //'user' | 'power-user' | 'administrator'
            if (config === 'power-user') {
                new_user_auth.setDefaultPowerUser();
            } else if (config === 'user') {
                new_user_auth.setDefaultUser();
            } else if (config === 'administrator') {
                console.log('set admin');
                new_user_auth.setDefaultAdmin();
            }
            const sysperm = new_user_auth.getPermission('system_permission');
            console.log('sysperm :>> ', sysperm);
            this.setState({ use_default_config: config, temp_user_auth: new_user_auth });
        }
    }
    onDeleteUser = (user_id: string) => {
        const user_auth = this.instance.UserAuthorization(user_id);
        if (user_auth) {
            window.userConfirm(getText('CONFIRM_REMOVE_USER'), ok => {
                if (ok) {
                    this.instance.DeleteUser(user_id);
                }
            });
        }
    }
    createPermissionTree = () => {
        const { temp_user_auth } = this.state;
        const temp_permission_dict: { [s in UserPermissionType]: CsxUserPermissionLevel } = {
            device_permission: temp_user_auth.getPermission('device_permission'),
            dashboard_permission: temp_user_auth.getPermission('dashboard_permission'),
            routing_control: temp_user_auth.getPermission('routing_control'),
            audio_control: temp_user_auth.getPermission('audio_control'),
            osd_control: temp_user_auth.getPermission('osd_control'),
            scaler_control: temp_user_auth.getPermission('scaler_control'),
            videoWall_control: temp_user_auth.getPermission('videoWall_control'),
            automation_control: temp_user_auth.getPermission('automation_control'),
            streaming_control: temp_user_auth.getPermission('streaming_control'),
            record_control: temp_user_auth.getPermission('record_control'),
            cec_control: temp_user_auth.getPermission('cec_control'),
            edid_control: temp_user_auth.getPermission('edid_control'),
            hdcp_control: temp_user_auth.getPermission('hdcp_control'),
            general_control: temp_user_auth.getPermission('general_control'),
            io_control: temp_user_auth.getPermission('io_control'),
            pm_control: temp_user_auth.getPermission('pm_control'),
            fwup_control: temp_user_auth.getPermission('fwup_control'),
            ct_control: temp_user_auth.getPermission('ct_control'),
            debug_control: temp_user_auth.getPermission('debug_control'),
            dante_control: temp_user_auth.getPermission('debug_control'),
            time_control: temp_user_auth.getPermission('time_control'),
            room_view: temp_user_auth.getPermission('room_view'),
            scenario_view: temp_user_auth.getPermission('scenario_view'),
            automation_view: temp_user_auth.getPermission('automation_view'),
            icon_view: temp_user_auth.getPermission('icon_view'),
            room_edit: temp_user_auth.getPermission('room_edit'),
            scenario_edit: temp_user_auth.getPermission('scenario_edit'),
            automation_edit: temp_user_auth.getPermission('automation_edit'),
            icon_edit: temp_user_auth.getPermission('icon_edit'),
            system_permission: temp_user_auth.getPermission('system_permission'),
            unit_fwup: temp_user_auth.getPermission('unit_fwup'),
            user_view: temp_user_auth.getPermission('user_view'),
            user_edit: temp_user_auth.getPermission('user_edit'),
            assign_room_permission: temp_user_auth.getPermission('assign_room_permission'),
        };
        const devctrl_permission_selector = (attr: UserPermissionType) => (
            <SelectNative key={`devctrl_${attr}`} size='small' style={{ width: '120px' }} value={temp_permission_dict[attr]} placeholder='Required' onChange={(v) => { this.onChangeTempUserPms(attr, v); }}>
                <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_DISABLE_VIEW')}</option>
                <option value={CsxUserPermissionLevel.ViewOnly}>{getText('SETTING_PERMISSION_OPT_VIEW_ONLY')}</option>
                <option value={CsxUserPermissionLevel.FullAccess}>{getText('SETTING_PERMISSION_OPT_CTRLABLE')}</option>
            </SelectNative>
        );
        const label_width_calc = 'calc(100% - 250px)';
        return (
            <div className='permission_wrapper' style={{
                maxHeight: '400px',
                overflow: 'auto',
            }}>
                <Tree.Tree color='black' collapsable>
                    <Tree.Node
                        label={tree_node_label(getText('APP_NAV_DASHBOARD'), [
                            <SelectNative key='dashboard_permission' size='small' style={{ width: '120px' }} value={temp_permission_dict['dashboard_permission']} placeholder='Required' onChange={(v) => { this.onChangeTempUserPms('dashboard_permission', v); }}>
                                <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_NO_CTRL')}</option>
                                <option value={CsxUserPermissionLevel.ViewOnly}>{getText('SETTING_PERMISSION_OPT_VIEW_ALL')}</option>
                                <option value={CsxUserPermissionLevel.FullAccess}>{getText('SETTING_PERMISSION_OPT_FULL_CTRL')}</option>
                            </SelectNative>,
                        ], label_width_calc)}
                    />
                    <Tree.Node defaultClose label={tree_node_label(getText('DEVICE_FEATURE_LABEL'), [
                        <SelectNative key='device_permission' size='small' style={{ width: '120px' }} value={temp_permission_dict['device_permission']} placeholder='Required' onChange={(v) => { this.onChangeTempUserPms('device_permission', v); }}>
                            <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_DISABLE_VIEW')}</option>
                                <option value={CsxUserPermissionLevel.ViewAssigned}>{getText('SETTING_PERMISSION_OPT_FROM_ASSIGN')}</option>
                            <option value={CsxUserPermissionLevel.FullAccess}>{getText('SETTING_PERMISSION_OPT_VIEW_ALL')}</option>
                        </SelectNative>
                    ], label_width_calc)}>
                        {(temp_permission_dict['device_permission'].length > 0 && temp_permission_dict['device_permission'] !== CsxUserPermissionLevel.NoAccess) ? [
                            <Tree.Node key='routing_control_node' label={tree_node_label(getText('DEVICE_FEATURE_ROUTING'), [devctrl_permission_selector('routing_control')], label_width_calc)}/>,
                            <Tree.Node key='audio_control_node' label={tree_node_label(getText('DEVICE_FEATURE_AUDIO'), [devctrl_permission_selector('audio_control')], label_width_calc)}/>,
                            <Tree.Node key='osd_control_node' label={tree_node_label(getText('DEVICE_FEATURE_OSD'), [devctrl_permission_selector('osd_control')], label_width_calc)}/>,
                            <Tree.Node key='scaler_control_node' label={tree_node_label(getText('DEVICE_FEATURE_SCALER'), [devctrl_permission_selector('scaler_control')], label_width_calc)}/>,
                            <Tree.Node key='videoWall_control_node' label={tree_node_label(getText('DEVICE_FEATURE_VIDEOWALL'), [devctrl_permission_selector('videoWall_control')], label_width_calc)}/>,
                            <Tree.Node key='automation_control_node' label={tree_node_label(getText('DEVICE_FEATURE_AUTOMATION'), [devctrl_permission_selector('automation_control')], label_width_calc)}/>,
                            <Tree.Node key='streaming_control_node' label={tree_node_label(getText('DEVICE_FEATURE_STREAMING'), [devctrl_permission_selector('streaming_control')], label_width_calc)}/>,
                            <Tree.Node key='record_control_node' label={tree_node_label(getText('DEVICE_FEATURE_RECORD'), [devctrl_permission_selector('record_control')], label_width_calc)}/>,
                            <Tree.Node key='cec_control_node' label={tree_node_label(getText('DEVICE_FEATURE_CEC'), [devctrl_permission_selector('cec_control')], label_width_calc)}/>,
                            <Tree.Node key='edid_control_node' label={tree_node_label(getText('DEVICE_FEATURE_EDID'), [devctrl_permission_selector('edid_control')], label_width_calc)}/>,
                            <Tree.Node key='hdcp_control_node' label={tree_node_label(getText('DEVICE_FEATURE_HDCP'), [devctrl_permission_selector('hdcp_control')], label_width_calc)}/>,
                            <Tree.Node key='general_control_node' label={tree_node_label(getText('DEVICE_FEATURE_GENERAL'), [devctrl_permission_selector('general_control')], label_width_calc)}/>,
                            <Tree.Node key='io_control_node' label={tree_node_label(getText('DEVICE_FEATURE_IO'), [devctrl_permission_selector('io_control')], label_width_calc)}/>,
                            <Tree.Node key='pm_control_node' label={tree_node_label(getText('DEVICE_FEATURE_POWER_MONITOR'), [devctrl_permission_selector('pm_control')], label_width_calc)}/>,
                            <Tree.Node key='fwup_control_node' label={tree_node_label(getText('DEVICE_FEATURE_FW_UPDATE'), [devctrl_permission_selector('fwup_control')], label_width_calc)}/>,
                            <Tree.Node key='ct_control_node' label={tree_node_label(getText('DEVICE_FEATURE_CABLE_TEST'), [devctrl_permission_selector('ct_control')], label_width_calc)}/>,
                            <Tree.Node key='debug_control_node' label={tree_node_label(getText('DEVICE_FEATURE_DEBUG'), [devctrl_permission_selector('debug_control')], label_width_calc)}/>,
                            <Tree.Node key='time_control_node' label={tree_node_label(getText('DEVICE_FEATURE_TIME'), [devctrl_permission_selector('time_control')], label_width_calc)}/>,
                        ] : undefined}
                        {/* <Tree.Node key='dante_control_node' label={tree_node_label(getText('DEVICE_FEATURE_DANTE'), [devctrl_permission_selector('dante_control')], label_width_calc)}/> */}
                    </Tree.Node>
                    <Tree.Node label={tree_node_label(getText('EVENT_LABEL'), [])}>
                        <Tree.Node label={tree_node_label(getText('APP_NAV_ROOM'), [
                            <SelectNative key='room_view_permision' size='small' style={{ width: '120px', marginRight: '10px' }} value={temp_permission_dict['room_view']} placeholder='Required' onChange={(v) => { this.onChangeTempUserPms('room_view', v); }}>
                                <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_DISABLE_VIEW')}</option>
                                <option value={CsxUserPermissionLevel.ViewAssigned}>{getText('SETTING_PERMISSION_OPT_ASSIGN_ONLY')}</option>
                                <option value={CsxUserPermissionLevel.FullAccess}>{getText('SETTING_PERMISSION_OPT_VIEW_ALL')}</option>
                            </SelectNative>,
                            <SelectNative key='room_edit_permision' size='small' style={{ width: '120px' }} disabled={(temp_permission_dict['room_view'].length === 0 || temp_permission_dict['room_view'] === CsxUserPermissionLevel.NoAccess)} value={temp_permission_dict['room_edit']} placeholder='Disabled' onChange={(v) => { this.onChangeTempUserPms('room_edit', v); }}>
                                <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_NO_CTRL')}</option>
                                <option value={CsxUserPermissionLevel.EditOnly}>{getText('SETTING_PERMISSION_OPT_EDIT_ONLY')}</option>
                                <option value={CsxUserPermissionLevel.FullAccess} disabled={(temp_permission_dict['room_view'] !== CsxUserPermissionLevel.FullAccess)}>{getText('SETTING_PERMISSION_OPT_FULL_CTRL')}</option>
                            </SelectNative>,
                        ], label_width_calc)}/>
                        <Tree.Node label={tree_node_label(getText('APP_NAV_SCENARIO'), [
                            <SelectNative key='scenario_view_permision' size='small' style={{ width: '120px', marginRight: '10px' }} value={temp_permission_dict['scenario_view']} placeholder='Required' onChange={(v) => { this.onChangeTempUserPms('scenario_view', v); }}>
                                <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_DISABLE_VIEW')}</option>
                                <option value={CsxUserPermissionLevel.ViewAssigned}>{getText('SETTING_PERMISSION_OPT_FROM_ASSIGN')}</option>
                                <option value={CsxUserPermissionLevel.FullAccess}>{getText('SETTING_PERMISSION_OPT_VIEW_ALL')}</option>
                            </SelectNative>,
                            <SelectNative key='scenario_edit_permision' size='small' style={{ width: '120px' }} disabled={(temp_permission_dict['scenario_view'].length === 0 || temp_permission_dict['scenario_view'] === CsxUserPermissionLevel.NoAccess)} value={temp_permission_dict['scenario_edit']} placeholder='Disabled' onChange={(v) => { this.onChangeTempUserPms('scenario_edit', v); }}>
                                <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_NO_CTRL')}</option>
                                <option value={CsxUserPermissionLevel.EditOnly}>{getText('SETTING_PERMISSION_OPT_EDIT_ONLY')}</option>
                                <option value={CsxUserPermissionLevel.FullAccess} disabled={(temp_permission_dict['scenario_view'] !== CsxUserPermissionLevel.FullAccess)}>{getText('SETTING_PERMISSION_OPT_FULL_CTRL')}</option>
                            </SelectNative>,
                        ], label_width_calc)}/>
                        <Tree.Node label={tree_node_label(getText('APP_NAV_AUTOMATION'), [
                            <SelectNative key='automatio_view_permision' size='small' style={{ width: '120px', marginRight: '10px' }} value={temp_permission_dict['automation_view']} placeholder='Required' onChange={(v) => { this.onChangeTempUserPms('automation_view', v); }}>
                                <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_DISABLE_VIEW')}</option>
                                <option value={CsxUserPermissionLevel.ViewAssigned}>{getText('SETTING_PERMISSION_OPT_FROM_ASSIGN')}</option>
                                <option value={CsxUserPermissionLevel.FullAccess}>{getText('SETTING_PERMISSION_OPT_VIEW_ALL')}</option>
                            </SelectNative>,
                            <SelectNative key='automatio_edit_permision' size='small' style={{ width: '120px' }} disabled={(temp_permission_dict['automation_view'].length === 0 || temp_permission_dict['automation_view'] === CsxUserPermissionLevel.NoAccess)} value={temp_permission_dict['automation_edit']} placeholder='Disabled' onChange={(v) => { this.onChangeTempUserPms('automation_edit', v); }}>
                                <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_NO_CTRL')}</option>
                                <option value={CsxUserPermissionLevel.EditOnly}>{getText('SETTING_PERMISSION_OPT_EDIT_ONLY')}</option>
                                <option value={CsxUserPermissionLevel.FullAccess} disabled={(temp_permission_dict['automation_view'] !== CsxUserPermissionLevel.FullAccess)}>{getText('SETTING_PERMISSION_OPT_FULL_CTRL')}</option>
                            </SelectNative>,
                        ], label_width_calc)}/>
                        <Tree.Node label={tree_node_label(getText('APP_NAV_ICON'), [
                            <SelectNative key='icon_view_permision' size='small' style={{ width: '120px', marginRight: '10px' }} value={temp_permission_dict['icon_view']} placeholder='Required' onChange={(v) => { this.onChangeTempUserPms('icon_view', v); }}>
                                <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_DISABLE_VIEW')}</option>
                                <option value={CsxUserPermissionLevel.ViewAssigned}>{getText('SETTING_PERMISSION_OPT_FROM_ASSIGN')}</option>
                                <option value={CsxUserPermissionLevel.FullAccess}>{getText('SETTING_PERMISSION_OPT_VIEW_ALL')}</option>
                            </SelectNative>,
                            <SelectNative key='icon_edit_permision' size='small' style={{ width: '120px' }} disabled={(temp_permission_dict['icon_view'].length === 0 || temp_permission_dict['icon_view'] === CsxUserPermissionLevel.NoAccess)} value={temp_permission_dict['icon_edit']} placeholder='Disabled' onChange={(v) => { this.onChangeTempUserPms('icon_edit', v); }}>
                                <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_NO_CTRL')}</option>
                                <option value={CsxUserPermissionLevel.EditOnly}>{getText('SETTING_PERMISSION_OPT_EDIT_ONLY')}</option>
                                <option value={CsxUserPermissionLevel.FullAccess} disabled={(temp_permission_dict['icon_view'] !== CsxUserPermissionLevel.FullAccess)}>{getText('SETTING_PERMISSION_OPT_FULL_CTRL')}</option>
                            </SelectNative>,
                        ], label_width_calc)}/>
                    </Tree.Node>
                    <Tree.Node defaultClose label={tree_node_label(getText('SETTING_USR_MANAGE_SYSTEM_PAGE'), [
                        <SelectNative key='sys_page_permision' size='small' style={{ width: '120px' }} value={temp_permission_dict['system_permission']} placeholder='Required' onChange={(v) => { this.onChangeTempUserPms('system_permission', v); }}>
                            <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_DISABLE_VIEW')}</option>
                            <option value={CsxUserPermissionLevel.ViewOnly}>{getText('SETTING_PERMISSION_OPT_VIEW_ONLY')}</option>
                            <option value={CsxUserPermissionLevel.FullAccess}>{getText('SETTING_PERMISSION_OPT_CTRLABLE')}</option>
                        </SelectNative>,
                    ], label_width_calc)}>
                        {(temp_permission_dict['system_permission'] === CsxUserPermissionLevel.FullAccess) ? <Tree.Node label={tree_node_label(getText('SETTING_PERMISSION_UNIT_FWUP'), [
                            <SelectNative key='unit_fwup_permision' size='small' style={{ width: '120px' }} value={temp_permission_dict['unit_fwup']} placeholder='Required' onChange={(v) => { this.onChangeTempUserPms('unit_fwup', v); }}>
                                <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_NOT_ALLOW')}</option>
                                <option value={CsxUserPermissionLevel.FullAccess}>{getText('SETTING_PERMISSION_OPT_ALLOW')}</option>
                            </SelectNative>,
                        ], label_width_calc)} /> : undefined}
                    </Tree.Node>
                    <Tree.Node label={tree_node_label(getText('SETTING_USR_MANAGE_LABEL'), [
                        <SelectNative key='user_view' size='small' style={{ width: '120px', marginRight: '10px' }} value={temp_permission_dict['user_view']} placeholder='Required' onChange={(v) => { this.onChangeTempUserPms('user_view', v); }}>
                            <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_DISABLE_VIEW')}</option>
                            <option value={CsxUserPermissionLevel.FullAccess}>{getText('SETTING_PERMISSION_OPT_VIEW_ALL')}</option>
                        </SelectNative>,
                        <SelectNative key='user_edit' size='small' style={{ width: '120px' }} disabled={(temp_permission_dict['user_view'].length === 0 || temp_permission_dict['user_view'] === CsxUserPermissionLevel.NoAccess)} value={temp_permission_dict['user_edit']} placeholder='Disabled' onChange={(v) => { this.onChangeTempUserPms('user_edit', v); }}>
                            <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_NO_CTRL')}</option>
                            <option value={CsxUserPermissionLevel.EditOnly}>{getText('SETTING_PERMISSION_OPT_EDIT_ONLY')}</option>
                            <option value={CsxUserPermissionLevel.FullAccess}>{getText('SETTING_PERMISSION_OPT_FULL_CTRL')}</option>
                        </SelectNative>,
                    ], label_width_calc)} />
                    <Tree.Node label={tree_node_label(getText('SETTING_USR_MANAGE_ASSIGN_ROOM_PERM'), [
                        <SelectNative key='assign_room_permission' size='small' style={{ width: '120px' }} value={temp_permission_dict['assign_room_permission']} placeholder='Required' onChange={(v) => { this.onChangeTempUserPms('assign_room_permission', v); }}>
                            <option value={CsxUserPermissionLevel.NoAccess}>{getText('SETTING_PERMISSION_OPT_NOT_ALLOW')}</option>
                            <option value={CsxUserPermissionLevel.FullAccess}>{getText('SETTING_PERMISSION_OPT_ALLOW')}</option>
                        </SelectNative>,
                    ], label_width_calc)} />
                </Tree.Tree>
            </div>
        );
    }
    render() {
        const { usr_select, temp_user_auth, use_default_config, modal } = this.state;
        const room_list = Array.from(this.eventInstance.RoomSet());
        const user_edit_permission = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getPermission('user_edit') : CsxUserPermissionLevel.NoAccess;
        const assign_room_permission = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getPermission('assign_room_permission') : CsxUserPermissionLevel.NoAccess;
        const temp_attr_dict: { [s in UserAttributeType]: string } = {
            assigned_room: temp_user_auth.getAttribute('assigned_room'),
            username: temp_user_auth.getAttribute('username'),
            password: temp_user_auth.getAttribute('password'),
        };
        const temp_room_assign_set = temp_user_auth.getAssignedRoom();
        const temp_room_checked = room_list.map(room_id => { return temp_room_assign_set.has(room_id); });
        const usr_list = (window.CSX_CUR_USER &&
            window.CSX_CUR_AUTH &&
            window.CSX_CUR_AUTH.getPermission('user_view') === CsxUserPermissionLevel.FullAccess
        ) ? window.CSX_CUR_USER.LOCAL_USER_LIST.map(user_id => {
            if (window.CSX_CUR_USER) {
                const user_auth = this.instance.UserAuthorization(user_id);
                const username = (user_auth) ? user_auth.getAttribute('username') : undefined;
                const login_ip = (username && window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getUserLogin(username) : undefined;
                let showname = (user_auth) ? username : '';
                if (user_id === '1') { // guest account
                    showname = 'Guest';
                } else if (user_id === '2') { // admin account
                    showname += ' (Administrator)';
                }
                const login_status_led = (login_ip) ? <Tooltip direction='right' text={'Online (from):\n' + Array.from(login_ip).join('\n')}><Icon type='led-green'></Icon></Tooltip> : <Icon type='led-gray' />;
                // guest(id=1) & admin(id=2) cannot assign room
                return [
                    ((user_id === '1') ? <div /> : login_status_led),
                    <div style={{ display: 'inline-flex' }}>{showname}</div>,
                    <div className='usr_mng_op_wrapper'>
                        {(user_id !== '1' && user_id !== '2') ? <Tooltip text={getText('TOOLTIP_USER_ASSIGNED_ROOM_EDIT')}><Icon type='scenario' color='#088aab' style={{ transform: 'translateY(2px)' }} onClick={() => { this.onOpenAssignRoom(user_id); }} /></Tooltip> : undefined}
                        <Tooltip text={getText('TOOLTIP_USER_PERMISSION_EDIT')}>
                            <Icon
                                type='setting'
                                color='#088aab'
                                onClick={() => {
                                    if (user_id === '1') {
                                        this.onOpenEditGuest();
                                    } else {
                                        this.onOpenEditUser(user_id);
                                    }
                                }}
                            />
                        </Tooltip>
                        {(user_id !== '1' && user_id !== '2' && csxUserPermissionSatisfy(user_edit_permission, CsxUserPermissionLevel.FullAccess)) ? <Tooltip text={getText('TOOLTIP_USER_REMOVE')}><Icon type='trashcan' color='#b92454' onClick={() => { this.onDeleteUser(user_id); }} /></Tooltip> : undefined}
                    </div>
                ];
            }
            return [];
        }).filter(row => (row.length > 0)) : [];
        // validate input
        let error_msg = '';
        let user_valid = false;
        if (usr_select === '1') { // guest is ok
            user_valid = true;
        } else {
            user_valid = (temp_attr_dict['username'].length > 0 && 
                temp_attr_dict['password'].length > 0);
            if (!user_valid) {
                error_msg = getText('HINT_FORMAT_INCORRECT');
            }
        }
        if (modal === 'assign_room' && !csxUserPermissionSatisfy(assign_room_permission, CsxUserPermissionLevel.EditOnly)) {
            error_msg = getText('NOTIFY_MSG_UNAUTHORIZED_SHORT');
        } else if (modal === 'create' && !csxUserPermissionSatisfy(user_edit_permission, CsxUserPermissionLevel.FullAccess)) {
            error_msg = getText('NOTIFY_MSG_UNAUTHORIZED_SHORT');
        } else if (modal === 'edit' && !csxUserPermissionSatisfy(user_edit_permission, CsxUserPermissionLevel.EditOnly)) {
            error_msg = getText('NOTIFY_MSG_UNAUTHORIZED_SHORT');
        } else if (modal === 'guest' && !csxUserPermissionSatisfy(user_edit_permission, CsxUserPermissionLevel.EditOnly)) {
            error_msg = getText('NOTIFY_MSG_UNAUTHORIZED_SHORT');
        }
        /* Create modal */
        const modal_title: { [s in UserManageModalType]: string } = {
            none: '',
            create: getText('SETTING_USR_MANAGE_CREATE_USER'),
            edit: getText('SETTING_USR_MANAGE_ALLOW_USER_FUNC'),
            assign_room: getText('SETTING_USR_MANAGE_ASSIGN_ROOM'),
            guest: getText('SETTING_USR_MANAGE_GUEST_PERMISSION'),
        };
        const modal_width: { [s in UserManageModalType]: string } = {
            none: '0px',
            create: '500px',
            edit: '500px',
            assign_room: '300px',
            guest: '500px',
        };
        const modal_content: { [s in UserManageModalType]: React.ReactNode } = {
            none: undefined,
            create: (
                <Form.Form>
                    <Form.Item label={getText('SETTING_USR_MANAGE_USERNAME')} labelStyle={{ width: '200px' }}><Input size='small' value={temp_attr_dict['username']} onChange={e => { this.onChangeTempUserAttr('username', e.target.value); }} /></Form.Item>
                    <Form.Item label={getText('SETTING_USR_MANAGE_PASSWORD')} labelStyle={{ width: '200px' }}><Input size='small' value={temp_attr_dict['password']} onChange={e => { this.onChangeTempUserAttr('password', e.target.value); }} /></Form.Item>
                    <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <RadioGroup onChange={this.onUseDefaultConfig} value={use_default_config} options={[
                            { label: getText('SETTING_USR_MANAGE_DEFAULT_SI'), value: 'administrator' },
                            { label: getText('SETTING_USR_MANAGE_DEFAULT_USR'), value: 'power-user' },
                            { label: getText('SETTING_USR_MANAGE_DEFAULT_ENDUSR'), value: 'user' }
                        ]}></RadioGroup>
                    </Form.Item>
                    {this.createPermissionTree()}
                    <Form.Item error={error_msg} style={{ borderTop: '1px solid rgba(40, 40, 40, 0.3)', marginTop: '10px', paddingTop: '10px', justifyContent: 'flex-end' }}>
                        <Button type='primary' disabled={(error_msg.length > 0)} onClick={(usr_select.length > 0) ? this.onSaveUserAuth : this.onCreateUser}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
            ),
            edit: (
                <Form.Form>
                    <Form.Item label={getText('SETTING_USR_MANAGE_USERNAME')} labelStyle={{ width: '200px' }}><Input size='small' value={temp_attr_dict['username']} onChange={e => { this.onChangeTempUserAttr('username', e.target.value); }} /></Form.Item>
                    <Form.Item label={getText('SETTING_USR_MANAGE_PASSWORD')} labelStyle={{ width: '200px' }}><Input size='small' value={temp_attr_dict['password']} onChange={e => { this.onChangeTempUserAttr('password', e.target.value); }} /></Form.Item>
                    {(usr_select !== '2') ? <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <RadioGroup onChange={this.onUseDefaultConfig} value={use_default_config} options={[
                            { label: getText('SETTING_USR_MANAGE_DEFAULT_SI'), value: 'administrator' },
                            { label: getText('SETTING_USR_MANAGE_DEFAULT_USR'), value: 'power-user' },
                            { label: getText('SETTING_USR_MANAGE_DEFAULT_ENDUSR'), value: 'user' }
                        ]}></RadioGroup>
                    </Form.Item> : undefined}
                    {(usr_select !== '2') ? this.createPermissionTree() : undefined}
                    <Form.Item error={error_msg} style={{ borderTop: '1px solid rgba(40, 40, 40, 0.3)', marginTop: '10px', paddingTop: '10px', justifyContent: 'flex-end' }}>
                        <Button type='primary' disabled={(error_msg.length > 0)} onClick={(usr_select.length > 0) ? this.onSaveUserAuth : this.onCreateUser}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
            ),
            guest: (
                <Form.Form>
                    <Form.Item layout='vertical' label={getText('SETTING_USR_MANAGE_GUEST_HOME_LABEL')} style={{ justifyContent: 'flex-end' }}>
                        <RadioGroup value={temp_user_auth.get('disable_signout_room_control')} options={[
                            { label: getText('SETTING_USR_MANAGE_LAST_LOGIN_OPTION'), value: 'n' },
                            { label: getText('SETTING_USR_MANAGE_NO_ROOM_OPTION'), value: 'y' },
                        ]} onChange={v => { this.onChangeTempUserSupport('disable_signout_room_control', v); }}></RadioGroup>
                    </Form.Item>
                    <Form.Item error={error_msg} layout='horizontal' style={{ borderTop: '1px solid rgba(40, 40, 40, 0.3)', marginTop: '10px', paddingTop: '10px', justifyContent: 'flex-end' }}>
                        <Button type='primary' disabled={(error_msg.length > 0)} onClick={this.onSaveUserAuth}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
            ),
            assign_room: (
                <Form.Form>
                    <div className='permission_wrapper' style={{
                        maxHeight: '400px',
                        overflow: 'auto',
                    }}>
                        <Tree.Tree color='black' collapsable>
                            <Tree.Node
                                label={tree_node_label(getText('ROOM_OVERVIEW_ALL'), [
                                    <Checkbox key='user_assign_check_all' checked={temp_attr_dict['assigned_room'] === 'all'} onChange={e => { this.onChangeRoomAssignAll(e.target.checked); }}/>
                                ], 'calc(100% - 40px)')}
                            >
                                {room_list.map((room_id, idx) => {
                                    const room = this.eventInstance.Room(room_id);
                                    return (room) ? (
                                        <Tree.Node
                                            key={`user_assign_room_${room_id}`}
                                            label={tree_node_label(room.NAME, [
                                                <Checkbox key={`user_assign_check_${room_id}`} checked={temp_room_checked[idx]} onChange={e => { this.onChangeRoomAssign(room_id, e.target.checked); }}/>
                                            ], 'calc(100% - 40px)')}
                                        />
                                    ) : undefined;
                                }).filter(node => !!node)}
                            </Tree.Node>
                        </Tree.Tree>
                    </div>
                    <Form.Item error={error_msg} style={{ borderTop: '1px solid rgba(40, 40, 40, 0.3)', marginTop: '10px', paddingTop: '10px', justifyContent: 'flex-end' }}>
                        <Button type='primary' disabled={(error_msg.length > 0)} onClick={this.onSaveUserAuth}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
            ),
        }
        return (
            <Flat.Section title={getText('SETTING_USR_MANAGE_LABEL')} style={CsxUI.getHiddenStyle(this.instance.IsSupportUserView())} disableCollapsed>
                {(csxUserPermissionSatisfy(user_edit_permission, CsxUserPermissionLevel.FullAccess)) ? <Form.Form>
                    <Form.Item style={{ justifyContent: 'flex-end' }}><Button type='primary' style={{ marginRight: 0 }} onClick={this.onOpenCreateUser}>{getText('CREATE')}</Button></Form.Item>
                </Form.Form> : undefined}
                <Table
                    headers={[getText('STATUS'), getText('USER'), getText('OPERATION')]}
                    columnWidth={[120, NaN, 120]}
                    rows={usr_list}
                    pagination={true}
                    rowLimit={10}
                    bodyStyle={{ width: '500px' }}
                    responsive='shorten'
                    shortenProps={{
                        layout: {
                            topLeft: 0,
                            bottomLeft: 1,
                            topRight: [],
                            bottomRight: [2],
                        }
                    }}
                />
                <Modal
                    title={modal_title[modal]}
                    visible={modal !== 'none'}
                    onClose={this.onCloseModal}
                    style={{ width: modal_width[modal], maxWidth: '100%' }}
                >
                    {modal_content[modal]}
                </Modal>
            </Flat.Section>
        );
    }
}

class LANBlock extends CsxUI.IRQComponent<any> {
    state: { lan_select: string; ipconf_changed: boolean; static_ip_err: string; static_gw_err: string; static_nm_err: string }
    instance: CsxFeature.CsxGeneralDevice;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'GatewayRealTime';
        this.instance = new CsxFeature.CsxGeneralDevice(window.FOCUS_GATEWAY);
        this.state = { lan_select: '1', ipconf_changed: false, static_ip_err: '', static_gw_err: '', static_nm_err: '' };
    }
    get ipmodeOption() {
        const ipmodeParam = this.instance.IPModeParam();
        const opt = (ipmodeParam.s1.isOption) ? ipmodeParam.s1.isOption.option : ['static', 'dhcp'];
        return (opt.length >= 2) ? opt : ['static', 'dhcp'];
    }
    get ipmodeDesc() {
        const ipmodeParam = this.instance.IPModeParam();
        const desc = (ipmodeParam.s1.isOption) ? ipmodeParam.s1.isOption.desc : ['STATIC', 'DHCP'];
        return (desc && desc.length >= 2) ? desc : ['STATIC', 'DHCP'];
    }
    validate = (str: string, param: CsxFeature.STRING_PARAM): boolean => {
        if (param.regex)
            return param.regex.test(str);
        else
            return (str.length >= param.length.min && str.length <= param.length.max);
    }
    validateStaticIPAddr = (value: string) => {
        const ipaddrParam = this.instance.StaticIPAddressParam();
        let ok = true;
        if (ipaddrParam.nn.isString) {
            ok = this.validate(value, ipaddrParam.nn.isString);
        }
        this.setState({ static_ip_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    validateStaticGateway = (value: string) => {
        const gatewayParam = this.instance.StaticGatewayParam();
        let ok = true;
        if (gatewayParam.nn.isString) {
            ok = this.validate(value, gatewayParam.nn.isString);
        }
        this.setState({ static_gw_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    validateStaticNetmask = (value: string) => {
        const netmaskParam = this.instance.StaticNetmaskParam();
        let ok = true;
        if (netmaskParam.nn.isString) {
            ok = this.validate(value, netmaskParam.nn.isString);
        }
        this.setState({ static_nm_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    onChangeIpMode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const ip_mode_opt = this.ipmodeOption;
        if (ip_mode_opt.length >= 2) {
            this.instance.SetIPMode(this.state.lan_select, (e.target.checked) ? ip_mode_opt[1] : ip_mode_opt[0]);
            this.setState({ ipconf_changed: true });
        }
    }
    onChangeIpAddr = (e: React.ChangeEvent<HTMLInputElement>) => { this.validateStaticIPAddr(e.target.value); this.instance.SetStaticIPAddress(this.state.lan_select, e.target.value); this.setState({ ipconf_changed: true }); }
    onChangeGateway = (e: React.ChangeEvent<HTMLInputElement>) => { this.validateStaticGateway(e.target.value); this.instance.SetStaticGateway(this.state.lan_select, e.target.value); this.setState({ ipconf_changed: true }); }
    onChangeNetmask = (e: React.ChangeEvent<HTMLInputElement>) => { this.validateStaticNetmask(e.target.value); this.instance.SetStaticNetmask(this.state.lan_select, e.target.value); this.setState({ ipconf_changed: true }); }
    onChangeLanSelect = (value: string) => { this.instance.cleanBuffer(); this.setState({ lan_select: value, ipconf_changed: false }); }
    onSaveIPConfig = () => { this.instance.UploadStaticIPConfiguration(); this.setState({ ipconf_changed: false, static_ip_err: '', static_gw_err: '', static_nm_err: '' }); }
    render() {
        const { lan_select, ipconf_changed, static_gw_err, static_ip_err, static_nm_err } = this.state;
        const ipaddrParam = this.instance.StaticIPAddressParam();
        const ipmode = this.instance.IPMode(lan_select);
        const ipaddr = this.instance.IPAddress(lan_select);
        const gateway = this.instance.Gateway(lan_select);
        const netmask = this.instance.Netmask(lan_select);
        const macaddr = this.instance.MacAddress(lan_select);
        const staticIpaddr = this.instance.StaticIPAddress(lan_select);
        const staticGateway = this.instance.StaticGateway(lan_select);
        const staticNetmask = this.instance.StaticNetmask(lan_select);
        const lan_list = (ipaddrParam.n1.isOption) ? ipaddrParam.n1.isOption.option : [];
        const lan_desc = (ipaddrParam.n1.isOption) ? ipaddrParam.n1.isOption.desc : [];
        const ip_mode_opt = this.ipmodeOption;
        const ip_mode_desc = this.ipmodeDesc;
        const general_control_allow = (window.CSX_CUR_AUTH) ? (window.CSX_CUR_AUTH.getPermission('system_permission') === CsxUserPermissionLevel.FullAccess) : false;
        let ipconfig_support = false;
        if (this.instance.IsSupportStaticIPConfiguration()) {
            ipconfig_support = true;
            if (this.instance.IsSupportMultiLan() && lan_select.length === 0)
                ipconfig_support = false;
        }
        // validate input
        const ipconfig_valid = (static_gw_err.length === 0 && static_nm_err.length === 0 && static_ip_err.length === 0)
        return (
            <Flat.Section title={getText('SETTING_SYSTEM_IP_CONFIG_LABEL')} style={CsxUI.getHiddenStyle(this.instance.IsSupportIPConfiguration())} defaultExtend={true}>
                <Form.Form labelStyle={{ width: '150px' }}>
                    <Form.Item label={getText('SETTING_SYSTEM_IP_CONFIG_LAN')} style={CsxUI.getHiddenStyle(this.instance.IsSupportMultiLan())}>
                        <Select value={lan_select} placeholder='Select LAN' onChange={this.onChangeLanSelect}>
                            {lan_list.map((lan, idx) => <Option key={`ip_config_lan_${lan}`} value={lan}>{(lan_desc) ? lan_desc[idx] : lan}</Option>)}
                        </Select>
                    </Form.Item>
                    <Form.Item label={getText('SETTING_SYSTEM_IP_CONFIG_MAC')} style={CsxUI.getHiddenStyle(ipconfig_support)}>{macaddr}</Form.Item>
                    <Form.Item label={getText('SETTING_SYSTEM_IP_CONFIG_DHCP')} style={CsxUI.getHiddenStyle(ipconfig_support)}><SwitchButton checked={((ip_mode_opt.length >= 2)?(ipmode === ip_mode_opt[1]):false)} onChange={this.onChangeIpMode} label={[ ip_mode_desc[1], ip_mode_desc[0] ]} /></Form.Item>
                    <Form.Item error={static_ip_err} label={getText('SETTING_SYSTEM_IP_CONFIG_IPADDR')} style={CsxUI.getHiddenStyle(ipconfig_support)}><Input value={(ipmode === 'dhcp') ? ipaddr : staticIpaddr} disabled={ipmode === 'dhcp'} onChange={this.onChangeIpAddr} /></Form.Item>
                    <Form.Item error={static_gw_err} label={getText('SETTING_SYSTEM_IP_CONFIG_GW')} style={CsxUI.getHiddenStyle(ipconfig_support)}><Input value={(ipmode === 'dhcp') ? gateway : staticGateway} disabled={ipmode === 'dhcp'} onChange={this.onChangeGateway} /></Form.Item>
                    <Form.Item error={static_nm_err} label={getText('SETTING_SYSTEM_IP_CONFIG_NM')} style={CsxUI.getHiddenStyle(ipconfig_support)}><Input value={(ipmode === 'dhcp') ? netmask : staticNetmask} disabled={ipmode === 'dhcp'} onChange={this.onChangeNetmask} /></Form.Item>
                    <Form.Item style={{ justifyContent: 'flex-end', display: (ipconfig_support?undefined:'none')}}>
                        <Button disabled={(!ipconf_changed || !general_control_allow)} onClick={() => { this.setState({ ipconf_changed: false, static_nm_err: '', static_gw_err: '', static_ip_err: '' }); this.instance.cleanBuffer(); }}>{getText('RESTORE')}</Button>
                        <Button type='primary' disabled={(!ipconf_changed || !ipconfig_valid || !general_control_allow)} onClick={this.onSaveIPConfig}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
            </Flat.Section>
        );
    }
}

class FirmwareVerBlock extends CsxUI.IRQComponent<any> {
    instance: CsxFeature.CsxUpgradableDevice;
    fileSelector: HTMLInputElement | null = null;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'GatewayRealTime';
        this.instance = new CsxFeature.CsxUpgradableDevice(window.FOCUS_GATEWAY);
    }
    triggerFileSelector = () => {
        if (window.CSX_CUR_AUTH) {
            if (!csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('unit_fwup'), CsxUserPermissionLevel.FullAccess)) {
                Notify({ title: getText('NOTIFY_TITLE_ERROR'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' });
                return;
            }
        }
        if (this.fileSelector)
            this.fileSelector.click();
        else
            window.alert(getText('ALERT_OP_UNSUPPORT_NATIVE_OUT'));
    }
    fwUpdate = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const fw_file = (event.target.files) ? event.target.files[0] : undefined;
        if (fw_file) {
            window.userConfirm(getText('CONFIRM_FWUP_SINGLE_DEVICE'), async (ok) => {
                if (ok && window.FOCUS_GATEWAY) {
                    try {
                        await window.FOCUS_GATEWAY.firmwareUpdate(fw_file);
                    } catch (error) {
                        console.log('[settings->page.tsx->SettingPage->fwUpdate] error :>> ', error);
                    }
                }
            });
        }
        event.target.value = '';
    }
    // componentDidIRQUpdate() {
    //     const fw_upgrade_progress = this.instance.FWUpgradeProgress(0);
    //     const progress_num = parseInt(fw_upgrade_progress);
    //     if (progress_num === 100 && (window.FOCUS_GATEWAY && window.FOCUS_GATEWAY.SYS_STA === CsxUtil.SYS_STA_TYPE.Sync))
    //         CsxUtil.signout();
    // }
    render() {
        const fw_ver = this.instance.FWVersion();
        const cmd_ver = this.instance.CMDVersion();
        const fw_upgrade_progress = this.instance.FWUpgradeProgress(0);
        const progress_num = parseInt(fw_upgrade_progress);
        const disable_update = (!isNaN(progress_num) && progress_num !== 100) || (window.FOCUS_GATEWAY && window.FOCUS_GATEWAY.SYS_STA !== CsxUtil.SYS_STA_TYPE.Sync);
        return (
            <Flat.Section title={getText('SETTING_SYSTEM_VERSION_LABEL')}>
                <div className='software_desc_wrapper' style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100px', color: '#008aab' }}>
                    <div className='fw_ver' style={{ width: '130px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '20px' }}>
                        <div className='value' style={{ fontSize: '3em' }}>{fw_ver}</div>
                        <div className='title' style={{ fontSize: '1em' }}>{getText('FW_VER')}</div>
                    </div>
                    <div className='cmd_ver' style={{ width: '130px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <div className='value' style={{ fontSize: '3em' }}>{cmd_ver}</div>
                        <div className='title' style={{ fontSize: '1em' }}>{getText('CMD_VER')}</div>
                    </div>
                </div>
                <Form.Item style={CsxUI.getHiddenStyle(this.instance.IsSupportCMDVersion() || this.instance.IsSupportFWVersion())}>
                    {!window.APP_ON_HDMI ? <input type='file' ref={(inst) => { this.fileSelector = inst; }} onChange={this.fwUpdate} style={{ display: 'none' }} /> : undefined}
                    <Button shape='round' type='primary' icon='upload' disabled={disable_update} onClick={this.triggerFileSelector}>{getText('UPDATE')}</Button>
                    <ProgressBar
                        className='fwup_prog'
                        hint={`${getText('HINT_DONT_CUT_OFF_POWER')}... ${fw_upgrade_progress}%`}
                        percentage={(isNaN(progress_num) ? 0 : progress_num)}
                        style={{ opacity: ((isNaN(progress_num)) ? 0 : 1) }}
                    />
                </Form.Item>
            </Flat.Section>
        );
    }
}

class SystemBlock extends CsxUI.IRQComponent<any> {
    state: { timeout_changed: boolean; timeout_s?: string; timeout_err: string; hostname_changed: boolean; hostname_s?: string; hostname_err: string; }
    instance: CsxFeature.CsxGeneralDevice;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'GatewayRealTime';
        this.instance = new CsxFeature.CsxGeneralDevice(window.FOCUS_GATEWAY);
        this.state = {
            timeout_changed: false,
            timeout_err: '',
            hostname_changed: false,
            hostname_err: '',
        }
    }
    isNumber = (value: string): boolean => { return !isNaN(Number(value)); }
    validateTimeout = (value: string) => {
        const timeoutParam = this.instance.WebTimeoutParam();
        const ok = this.isNumber(value) && !(timeoutParam.n1.isRange && !this.validateRange(value, timeoutParam.n1.isRange));
        this.setState({ timeout_err: (ok ? '' : getText('HINT_FORMAT_INCORRECT')) });
        return ok;
    }
    onChangeTimeout = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.validateTimeout(e.target.value);
        this.setState({ timeout_changed: true, timeout_s: e.target.value });
    }
    onSaveTimeout = () => {
        const { timeout_s } = this.state;
        if (timeout_s) {
            const minute = Number(timeout_s);
            this.instance.SetWebTimeout(minute);
            this.setState({ timeout_s: undefined, timeout_changed: false, timeout_err: '' });
            window.CSX_MANAGER.loginTimerStart(minute);
        }
    }
    validateHostname = (value: string) => {
        const hostnameParam = this.instance.HostnameParam();
        const ok = !((typeof value === 'string') && hostnameParam.s1.isString && !this.validateString(value, hostnameParam.s1.isString));
        this.setState({ hostname_err: (ok ? '' : getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    onChangeHostname = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.validateHostname(e.target.value);
        this.setState({ hostname_changed: true, hostname_s: e.target.value });
    }
    onSaveHostname = () => {
        window.userConfirm(getText('CONFIRM_RESET_HOSTNAME'), (ok) => {
            const { hostname_s } = this.state;
            if (hostname_s && ok) {
                this.instance.SetHostname(hostname_s);
                this.setState({ hostname_s: undefined, hostname_changed: false, hostname_err: '' });
            }
        });
    }
    onFactoryDefault = () => {
        window.userConfirm(getText('CONFIRM_FACTORY_DEFAULT'), (ok) => {
            if (ok) {
                this.instance.FactoryDefault();
                setTimeout(CsxUtil.signout, 1000);
            }
        });
    }
    render() {
        const { hostname_changed, hostname_err, hostname_s, timeout_changed, timeout_err, timeout_s } = this.state;
        return (
            <Flat.Section title={getText('SETTING_SYSTEM_SYSTEM_LABEL')} style={CsxUI.getHiddenStyle(this.instance.IsSupportReboot() || this.instance.IsSupportPowerControl() || this.instance.isSupportFactoryDefault())}>
                <Form.Form labelStyle={{ width: '150px' }}>
                    <Form.Item style={CsxUI.getHiddenStyle(this.instance.IsSupportPowerControl())} label={getText('SETTING_SYSTEM_SYSTEM_POWER')}>
                        <Button style={CsxUI.getHiddenStyle(this.instance.IsSupportReboot())} onClick={this.instance.Reboot}>{getText('REBOOT')}</Button>
                        <Button style={CsxUI.getHiddenStyle(this.instance.IsSupportPowerCommand('standby'))} onClick={() => { this.instance.SetPower('standby'); }}>{getText('STANDBY')}</Button>
                        <Button style={CsxUI.getHiddenStyle(this.instance.IsSupportPowerCommand('on'))} onClick={() => { this.instance.SetPower('on'); }}>{getText('SETTING_SYSTEM_SYSTEM_ON')}</Button>
                        <Button style={CsxUI.getHiddenStyle(this.instance.IsSupportPowerCommand('off'))} onClick={() => { this.instance.SetPower('off'); }}>{getText('OFF')}</Button>
                    </Form.Item>
                    <Form.Item label={getText('RESTORE')} style={CsxUI.getHiddenStyle(this.instance.isSupportFactoryDefault())}><Button onClick={this.onFactoryDefault}>{getText('SETTING_SYSTEM_SYSTEM_FAC_DEFAULT')}</Button></Form.Item>
                    <Form.Item error={hostname_err} label={getText('SETTING_SYSTEM_SYSTEM_HOSTNAME')} style={CsxUI.getHiddenStyle(this.instance.IsSupportHostname())}>
                        <Input value={(typeof hostname_s === 'string') ? hostname_s : this.instance.Hostname()} placeholder='Type Hostname' onChange={this.onChangeHostname} />
                        <Button icon='revert' tooltip={getText('SETTING_SYSTEM_SYSTEM_BUTTIP_REVERT')} disabled={!hostname_changed} onClick={() => { this.setState({ hostname_changed: false, hostname_err: '', hostname_s: undefined }); }}></Button>
                        <Button type='primary' disabled={(!hostname_changed || hostname_err.length > 0)} onClick={this.onSaveHostname}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
                <Form.Form className='web_timeout_form' layout='vertical' colon={false}>
                    <Form.Item error={timeout_err} label={getText('SETTING_SYSTEM_SYSTEM_WEB_TIMEOUT')} style={CsxUI.getHiddenStyle(this.instance.IsSupportWebTimeout())}>
                        <Input value={(typeof timeout_s === 'string') ? timeout_s : this.instance.WebTimeout()} onChange={this.onChangeTimeout} />
                        <Button type='primary' disabled={(!timeout_changed || timeout_err.length > 0)} onClick={this.onSaveTimeout}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
            </Flat.Section>
        );
    }
}

class DeviceNameBlock extends CsxUI.IRQComponent<any> {
    state: {
        nick_changed: boolean;
        nickname_s?: string;
        nickname_err: string;
    }
    instance: CsxFeature.CsxGeneralDevice
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'GatewayRealTime';
        this.instance = new CsxFeature.CsxGeneralDevice(window.FOCUS_GATEWAY);
        this.state = {
            nick_changed: false,
            nickname_err: '',
        };
    }
    validateNickname = (value: string) => {
        const nicknameParam = this.instance.NicknameParam();
        const ok = !((typeof value === 'string') && nicknameParam.s1.isString && !this.validateString(value, nicknameParam.s1.isString));
        this.setState({ nickname_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    onChangeNickname = (e: React.ChangeEvent<HTMLInputElement>) => { if (this.validateNickname(e.target.value) || e.target.value.length === 0) this.setState({ nick_changed: true, nickname_s: e.target.value }); }
    onSaveNickname = () => {
        const { nickname_s } = this.state;
        if (nickname_s) {
            this.instance.SetNickname(nickname_s);
            this.setState({ nickname_s: undefined, nick_changed: false, nickname_err: '' });
        }
    }
    render() {
        const { nick_changed, nickname_s, nickname_err } = this.state;
        return (
            <Flat.Section title={getText('SETTING_SYSTEM_DEV_NAME_LABEL')} style={CsxUI.getHiddenStyle(this.instance.IsSupportNickname())}>
                <Form.Form labelStyle={{ width: '150px' }}>
                    <Form.Item error={nickname_err} label={getText('SETTING_SYSTEM_DEV_NAME')}><Input value={(typeof nickname_s === 'string') ? nickname_s : this.instance.Nickname()} placeholder='Type Device Name' onChange={this.onChangeNickname} /></Form.Item>
                    <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Button disabled={!nick_changed} onClick={() => { this.setState({ nick_changed: false, nickname_err: '', nickname_s: undefined }); }}>{getText('RESTORE')}</Button>
                        <Button type='primary' disabled={((!nick_changed) || nickname_err.length > 0)} onClick={this.onSaveNickname}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
            </Flat.Section>
        );
    }
}

class UARTBlock extends CsxUI.IRQComponent<any> {
    state: { uart_s: string }
    instance: CsxFeature.CsxIODevice;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'GatewayRealTime';
        this.instance = new CsxFeature.CsxIODevice(window.FOCUS_GATEWAY);
        this.state = { uart_s: '1', };
    }
    onChangeUartBaudrate = (value: string) => { this.instance.SetUARTBaudrate(this.state.uart_s, value); }
    onChangeUartDatabit = (value: string) => { this.instance.SetUARTDatabit(this.state.uart_s, value); }
    onChangeUartStopbit = (value: string) => { this.instance.SetUARTStopbit(this.state.uart_s, value); }
    onChangeUartParity = (value: string) => { this.instance.SetUARTParity(this.state.uart_s, value); }
    onChangeUartMode = (value: string) => { this.instance.SetUARTMode(this.state.uart_s, value); }
    onChangeUartSelect = (value: string) => { this.setState({ uart_s: value }); }
    onClickUARTReset = () => { this.instance.SetUARTReset(this.state.uart_s); }
    render() {
        const { uart_s } = this.state;
        const uartBRParam = this.instance.UARTBaudrateParam();
        const uartSBParam = this.instance.UARTStopbitParam();
        const uartDBParam = this.instance.UARTDatabitParam();
        const uartPTParam = this.instance.UARTParityParam();
        const uartModeParam = this.instance.UARTModeParam();
        const uart_opt = (uartBRParam.n1.isOption) ? uartBRParam.n1.isOption.option : [];
        const uart_desc = (uartBRParam.n1.isOption) ? uartBRParam.n1.isOption.desc : [];
        const uart_br_opt = (uartBRParam.n2.isOption) ? uartBRParam.n2.isOption.option : [];
        const uart_sb_opt = (uartSBParam.n2.isOption) ? uartSBParam.n2.isOption.option : [];
        const uart_db_opt = (uartDBParam.n2.isOption) ? uartDBParam.n2.isOption.option : [];
        const uart_pt_opt = (uartPTParam.n2.isOption) ? uartPTParam.n2.isOption.option : [];
        const uart_pt_desc = (uartPTParam.n2.isOption) ? uartPTParam.n2.isOption.desc : [];
        const uart_mode_opt = (uartModeParam.n2.isOption) ? uartModeParam.n2.isOption.option : [];
        const uart_mode_desc = (uartModeParam.n2.isOption) ? uartModeParam.n2.isOption.desc : [];
        const uart_br_s = this.instance.UARTBaudrate(uart_s);
        const uart_sb_s = this.instance.UARTStopbit(uart_s);
        const uart_db_s = this.instance.UARTDatabit(uart_s);
        const uart_pt_s = this.instance.UARTParity(uart_s);
        const uart_mode_s = this.instance.UARTMode(uart_s);
        return (
            <Flat.Section title={getText('SETTING_SYSTEM_UART_LABEL')} style={CsxUI.getHiddenStyle(this.instance.IsSupportUARTConfiguration())}>
                <Form.Form labelStyle={{ width: '150px' }}>
                    <Form.Item label={getText('SETTING_SYSTEM_UART_PORT')}><Select placeholder='Select Port' value={uart_s} onChange={this.onChangeUartSelect}>{
                        uart_opt.map((opt, idx) => <Option value={opt} key={`uart_opt_${opt}`}>{(uart_desc) ? uart_desc[idx] : opt}</Option>)
                    }</Select><Button type='danger' style={CsxUI.getHiddenStyle(this.instance.IsSupportUARTReset())} disabled={uart_s.length === 0} onClick={this.onClickUARTReset}>Reset Default</Button></Form.Item>
                    <Form.Item label={getText('SETTING_SYSTEM_UART_BAUDRATE')} style={CsxUI.getHiddenStyle(uart_s.length > 0)}><Select placeholder='Select Baudrate' value={uart_br_s} onChange={this.onChangeUartBaudrate}>{
                        uart_br_opt.map((opt) => <Option value={opt} key={`br_opt_${opt}`}>{opt}</Option>)
                    }</Select></Form.Item>
                    <Form.Item label={getText('SETTING_SYSTEM_UART_STOPBIT')} style={CsxUI.getHiddenStyle(uart_s.length > 0)}><Select placeholder='Select Stopbit' value={uart_sb_s} onChange={this.onChangeUartStopbit}>{
                        uart_sb_opt.map((opt) => <Option value={opt} key={`sb_opt_${opt}`}>{opt}</Option>)
                    }</Select></Form.Item>
                    <Form.Item label={getText('SETTING_SYSTEM_UART_DATABIT')} style={CsxUI.getHiddenStyle(uart_s.length > 0)}><Select placeholder='Select Databit' value={uart_db_s} onChange={this.onChangeUartDatabit}>{
                        uart_db_opt.map((opt) => <Option value={opt} key={`db_opt_${opt}`}>{opt}</Option>)
                    }</Select></Form.Item>
                    <Form.Item label={getText('SETTING_SYSTEM_UART_PARITY')} style={CsxUI.getHiddenStyle(uart_s.length > 0)}><Select placeholder='Select Baudrate' value={uart_pt_s} onChange={this.onChangeUartParity}>{
                        uart_pt_opt.map((opt, idx) => <Option value={opt} key={`pt_opt_${opt}`}>{(uart_pt_desc) ? uart_pt_desc[idx] : opt}</Option>)
                    }</Select></Form.Item>
                    <Form.Item label={getText('SETTING_SYSTEM_UART_MODE')} style={CsxUI.getHiddenStyle(this.instance.IsSupportUARTMode() && uart_s.length > 0)}><Select placeholder='Select Mode' value={uart_mode_s} onChange={this.onChangeUartMode}>{
                        uart_mode_opt.map((opt, idx) => <Option value={opt} key={`mode_opt_${opt}`}>{(uart_mode_desc) ? uart_mode_desc[idx] : opt}</Option>)
                    }</Select></Form.Item>
                    {/* <div className='field advance' style={CsxUI.getHiddenStyle(this.instance.IsSupportUARTSetCommand())}>
                        <div className='title'>Advance</div>
                    </div> */}
                </Form.Form>
            </Flat.Section>
        );
    }
}


class BackupBlock extends CsxUI.IRQComponent<any> {
    ICON_IMPORT_OPTION_LABEL: { [s in CsxControlSystem.IconImportOptionType]: string } = {
        "auto-rename": getText('ICON_IMPORT_BEHAVIOR_DESC_AUTO_RENAME'),
        "overwrite-same-name": getText('ICON_IMPORT_BEHAVIOR_DESC_OVERWRITE'),
        "skip-same-name": getText('ICON_IMPORT_BEHAVIOR_DESC_SKIP'),
    }
    state: {
        popup: BackupModalType,
        loading: boolean,
        batch_uploader_status: 'stop' | 'continue',
        upload_left: number,
        upload_total: number,
        icon_upload_stop_tmp_json?: CsxEventSystem.CsxIconJson,
        automation_upload_stop_tmp_json?: CsxEventSystem.CsxAutomationJson,
        scenario_upload_stop_tmp_json?: CsxEventSystem.CsxScenarioJson,
        room_upload_stop_tmp_json?: CsxEventSystem.CsxRoomJson,
        new_icon_name: string,
        new_automation_name: string,
        new_scenario_name: string,
        new_room_name: string,
    } = {
        popup: 'none',
        loading: false,
        upload_left: 0,
        upload_total: 0,
        new_icon_name: '',
        new_automation_name: '',
        new_scenario_name: '',
        new_room_name: '',
        batch_uploader_status: 'continue',
    };
    instance: CsxFeature.CsxEventSystemDevice;
    roomFileSelect: HTMLInputElement | null | undefined;
    scenarioFileSelect: HTMLInputElement | null | undefined;
    automationFileSelect: HTMLInputElement | null | undefined;
    iconFileSelect: HTMLInputElement | null | undefined;
    automationBatcher: CsxUtil.ArrayBatcher<CsxEventSystem.CsxAutomationJson>;
    scenarioBatcher: CsxUtil.ArrayBatcher<CsxEventSystem.CsxScenarioJson>;
    roomBatcher: CsxUtil.ArrayBatcher<CsxEventSystem.CsxRoomJson>;
    /* For icon upload policy */
    iconsFileTmp: File | undefined;
    dialog_icon_upload_policy: CsxControlSystem.IconImportOptionType = 'auto-rename';
    dialog_icon_upload_policy_remember: boolean = false;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'EventSystem';
        this.instance = new CsxFeature.CsxEventSystemDevice();

        /* Room Batch Uploader */
        this.roomBatcher = new CsxUtil.ArrayBatcher();
        this.roomBatcher.checkpoint = this.checkRoomUploadInvalid;
        this.roomBatcher.onFinish = () => { this.pop('none'); Notify({ type: 'success', title: getText('NOTIFY_TITLE_IMPORT_ROOM'), context: getText('NOTIFY_MSG_SUCCESS'), timeout: 3000 }); }
        this.roomBatcher.routine = this.handleRoomJsonUpload;
        /* Scenario Batch Uploader */
        this.scenarioBatcher = new CsxUtil.ArrayBatcher();
        this.scenarioBatcher.checkpoint = this.checkScenarioUploadInvalid;
        this.scenarioBatcher.onFinish = () => { this.pop('none'); Notify({ type: 'success', title: getText('NOTIFY_TITLE_IMPORT_SCENARIO'), context: getText('NOTIFY_MSG_SUCCESS'), timeout: 3000 }); }
        this.scenarioBatcher.routine = this.handleScenarioJsonUpload;
        /* Automation Batch Uploader */
        this.automationBatcher = new CsxUtil.ArrayBatcher();
        this.automationBatcher.checkpoint = this.checkAutomationUploadInvalid;
        this.automationBatcher.onFinish = () => { this.pop('none'); Notify({ type: 'success', title: getText('NOTIFY_TITLE_IMPORT_AUTOMATION'), context: getText('NOTIFY_MSG_SUCCESS'), timeout: 3000 }); }
        this.automationBatcher.routine = this.handleAutomationJsonUpload;
        /* Icon Batch Uploader */
        // this.iconBatcher = new CsxUtil.ArrayBatcher();
        // this.iconBatcher.checkpoint = this.checkIconUploadInvalid;
        // this.iconBatcher.onFinish = () => { this.pop('none'); Notify({ type: 'success', title: getText('NOTIFY_TITLE_IMPORT_ICON'), context: getText('NOTIFY_MSG_SUCCESS'), timeout: 3000 }); }
        // this.iconBatcher.routine = this.handleIconJsonUpload;
    }
    setLoading = (loading: boolean) => { this.setState({ loading }); }
    checkIconUploadInvalid = (obj: any): 'stop' | 'continue' => {
        if (CsxEventSystem.isCsxIconJson(obj)) {
            if (this.instance.CheckIconNameExist(obj.name)) {
                this.setState({ icon_upload_stop_tmp_json: CsxUtil.nestedClone(obj), new_icon_name: obj.name, batch_uploader_status: 'stop' });
                return 'stop';
            } else {
                this.setState({ icon_upload_stop_tmp_json: CsxUtil.nestedClone(obj), new_icon_name: '', batch_uploader_status: 'continue' });
                return 'continue';
            }
        }
        return 'stop';
    }
    checkAutomationUploadInvalid = (obj: any): 'stop' | 'continue' => {
        if (CsxEventSystem.isCsxAutomationJson(obj)) {
            if (this.instance.CheckAutomationNameExist(obj.name)) {
                this.setState({ automation_upload_stop_tmp_json: CsxUtil.nestedClone(obj), new_automation_name: obj.name, batch_uploader_status: 'stop' });
                return 'stop';
            } else {
                this.setState({ automation_upload_stop_tmp_json: CsxUtil.nestedClone(obj), new_automation_name: '', batch_uploader_status: 'continue' });
                return 'continue';
            }
        }
        return 'stop';
    }
    checkScenarioUploadInvalid = (obj: any): 'stop' | 'continue' => {
        if (CsxEventSystem.isCsxScenarioJson(obj)) {
            if (this.instance.CheckScenarioNameExist(obj.name)) {
                this.setState({ scenario_upload_stop_tmp_json: CsxUtil.nestedClone(obj), new_scenario_name: obj.name, batch_uploader_status: 'stop' });
                return 'stop';
            } else {
                this.setState({ scenario_upload_stop_tmp_json: CsxUtil.nestedClone(obj), new_scenario_name: '', batch_uploader_status: 'continue' });
                return 'continue';
            }
        }
        return 'stop';
    }
    checkRoomUploadInvalid = (obj: any): 'stop' | 'continue' => {
        if (CsxEventSystem.isCsxRoomJson(obj)) {
            if (this.instance.CheckRoomNameExist(obj.name)) {
                this.setState({ room_upload_stop_tmp_json: CsxUtil.nestedClone(obj), new_room_name: obj.name, batch_uploader_status: 'stop' });
                return 'stop';
            } else {
                this.setState({ room_upload_stop_tmp_json: CsxUtil.nestedClone(obj), new_room_name: '', batch_uploader_status: 'continue' });
                return 'continue';
            }
        }
        return 'stop';
    }
    onConfirmNewAutomationName = () => {
        const { new_automation_name } = this.state;
        if (this.automationBatcher.length > 0) {
            this.automationBatcher[0].name = new_automation_name.slice();
            this.automationBatcher.run();
        }
    }
    onConfirmNewScenarioName = () => {
        const { new_scenario_name } = this.state;
        if (this.scenarioBatcher.length > 0) {
            this.scenarioBatcher[0].name = new_scenario_name.slice();
            this.scenarioBatcher.run();
        }
    }
    onConfirmNewRoomName = () => {
        const { new_room_name } = this.state;
        if (this.roomBatcher.length > 0) {
            this.roomBatcher[0].name = new_room_name.slice();
            this.roomBatcher.run();
        }
    }
    onSkipAutomationName = () => {
        let next_len = this.automationBatcher.length - 1;
        this.automationBatcher.skip();
        this.setState({ upload_left: next_len });
    }
    onSkipScenarioName = () => {
        let next_len = this.scenarioBatcher.length - 1;
        this.scenarioBatcher.skip();
        this.setState({ upload_left: next_len });
    }
    onSkipRoomName = () => {
        let next_len = this.roomBatcher.length - 1;
        this.roomBatcher.skip();
        this.setState({ upload_left: next_len });
    }
    onOverwriteAutomation = () => {
        const { new_automation_name } = this.state;
        if (this.automationBatcher.length > 0) {
            this.automationBatcher[0].name = new_automation_name.slice();
            this.automationBatcher.forceRun();
        }
    }
    onOverwriteScenario = () => {
        const { new_scenario_name } = this.state;
        if (this.scenarioBatcher.length > 0) {
            this.scenarioBatcher[0].name = new_scenario_name.slice();
            this.scenarioBatcher.forceRun();
        }
    }
    onOverwriteRoom = () => {
        const { new_room_name } = this.state;
        if (this.roomBatcher.length > 0) {
            this.roomBatcher[0].name = new_room_name.slice();
            this.roomBatcher.forceRun();
        }
    }
    handleAutomationJsonUpload = (json: CsxEventSystem.CsxAutomationJson) => {
        const overwrite_automation_id = this.instance.CheckAutomationNameExist(json.name);
        const next_len = this.automationBatcher.length - 1;
        const new_automation_id = (this.automationBatcher.isForce && overwrite_automation_id) ? overwrite_automation_id : '0';
        const new_automation = new CsxEventSystem.CsxAutomation(new_automation_id, json);

        this.instance.SetAutomation(new_automation);
        this.setState({ upload_left: next_len });
    }
    handleScenarioJsonUpload = async (json: CsxEventSystem.CsxScenarioJson) => {
        const overwrite_scenario_id = this.instance.CheckScenarioNameExist(json.name);
        const next_len = this.scenarioBatcher.length - 1;
        const new_scenario_id = (this.scenarioBatcher.isForce && overwrite_scenario_id) ? overwrite_scenario_id : '0';
        const new_scenario = new CsxEventSystem.CsxScenario(new_scenario_id, json);

        this.setLoading(true);
        if (json.automationSnapshot) {
            await this.instance.ScenarioAutomationSnapshotProcess(json);
        }
        this.setLoading(false);

        this.instance.SetScenario(new_scenario);
        this.setState({ upload_left: next_len });
    }
    handleRoomJsonUpload = async (json: CsxEventSystem.CsxRoomJson) => {
        const overwrite_room_id = this.instance.CheckRoomNameExist(json.name);
        const next_len = this.roomBatcher.length - 1;
        const new_room_id = (this.roomBatcher.isForce && overwrite_room_id) ? overwrite_room_id : '0';

        this.setLoading(true);
        await this.instance.RoomAutomationSnapshotProcess(json);
        await this.instance.RoomScenarioSnapshotProcess(json);
        this.setLoading(false);

        const new_room = new CsxEventSystem.CsxRoom(new_room_id, json);
        this.instance.SetRoom(new_room);
        this.setState({ upload_left: next_len });
    }
    onChangeIconName = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ new_icon_name: e.target.value }); }
    onChangeAutomationName = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ new_automation_name: e.target.value }); }
    onChangeScenarioName = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ new_scenario_name: e.target.value }); }
    onChangeRoomName = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ new_room_name: e.target.value }); }
    onChangeIconImportOption = (v: string) => { if (CsxControlSystem.isIconImportOptionType(v)) this.dialog_icon_upload_policy = v; }
    onChangeIconImportHintRemember = (e: React.ChangeEvent<HTMLInputElement>) => { this.dialog_icon_upload_policy_remember = e.target.checked; }
    pop = (modal: BackupModalType) => {
        if (!modal) {
            this.setState({
                upload_left: [],
                upload_total: 0,
            });
        }
        this.setState({ popup: modal });
    }
    triggerSelectRoomFile = () => {
        if (window.CSX_CUR_AUTH) {
            if (!csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('room_edit'), CsxUserPermissionLevel.FullAccess) ||
                !csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('scenario_edit'), CsxUserPermissionLevel.FullAccess) ||
                !csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('automation_edit'), CsxUserPermissionLevel.FullAccess) ||
                !csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('icon_edit'), CsxUserPermissionLevel.FullAccess) ||
                !csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('system_permission'), CsxUserPermissionLevel.FullAccess)) {
                Notify({ title: getText('NOTIFY_TITLE_ERROR'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' });
                return;
            }
        }
        if (this.roomFileSelect)
            this.roomFileSelect.click();
        else
            window.alert(getText('ALERT_OP_UNSUPPORT_NATIVE_OUT'));
    }
    triggerSelectScenarioFile = () => {
        if (window.CSX_CUR_AUTH) {
            if (!csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('scenario_edit'), CsxUserPermissionLevel.FullAccess) ||
                !csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('automation_edit'), CsxUserPermissionLevel.FullAccess) ||
                !csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('icon_edit'), CsxUserPermissionLevel.FullAccess) ||
                !csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('system_permission'), CsxUserPermissionLevel.FullAccess)) {
                Notify({ title: getText('NOTIFY_TITLE_ERROR'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' });
                return;
            }
        }
        if (this.scenarioFileSelect)
            this.scenarioFileSelect.click();
        else
            window.alert(getText('ALERT_OP_UNSUPPORT_NATIVE_OUT'));
    }
    triggerSelectAutomationFile = () => {
        if (window.CSX_CUR_AUTH) {
            if (!csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('automation_edit'), CsxUserPermissionLevel.FullAccess) ||
                !csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('system_permission'), CsxUserPermissionLevel.FullAccess)) {
                Notify({ title: getText('NOTIFY_TITLE_ERROR'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' });
                return;
            }
        }
        if (this.automationFileSelect)
            this.automationFileSelect.click();
        else
            window.alert(getText('ALERT_OP_UNSUPPORT_NATIVE_OUT'));
    }
    triggerSelectIconFile = () => {
        if (window.CSX_CUR_AUTH) {
            if (!csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('icon_edit'), CsxUserPermissionLevel.FullAccess) ||
                !csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('system_permission'), CsxUserPermissionLevel.FullAccess)) {
                Notify({ title: getText('NOTIFY_TITLE_ERROR'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' });
                return;
            }
        }
        if (this.iconFileSelect)
            this.iconFileSelect.click();
        else
            window.alert(getText('ALERT_OP_UNSUPPORT_NATIVE_OUT'));
    }
    handleImportRoomFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const notify_title = getText('NOTIFY_TITLE_IMPORT_ROOM');
        const json_file = (event.target.files) ? event.target.files[0] : undefined;

        if (json_file) {
            CsxUtil.importHandler({
                file: json_file,
                userConfirm: getText('CONFIRM_IMPORT_ROOM_BACKUP'),
                validateFilename: (fn) => { const fn_walk = fn.split('.'); return (fn_walk.slice(-3).join('.').toLowerCase() === 'room.grp.json' || fn_walk.slice(-2).join('.').toLowerCase() === 'room.json'); },
                onConfirm: async (parseJson) => {
                    let empty_space = this.instance.RoomSizeLimit() - this.instance.RoomSize();
                    let import_list: Array<CsxEventSystem.CsxRoomJson> = [];
                    empty_space = (empty_space < 0) ? 0 : empty_space;
                    
                    if (CsxEventSystem.isCsxRoomSetJson(parseJson)) {
                        import_list = (parseJson.list.length > empty_space) ? parseJson.list.slice(0, empty_space) : parseJson.list;
                        if (parseJson.list.length > empty_space) {
                            Notify({
                                title: notify_title,
                                context: `${getText('NOTIFY_MSG_ROOM_SIZE_LIMIT')} ${this.instance.RoomSizeLimit()}.`,
                                type: 'warning',
                                timeout: 10000  
                            });
                        }
                    } else if (CsxEventSystem.isCsxRoomJson(parseJson)) {
                        import_list = (empty_space < 1) ? [] : [ parseJson ];
                        if (empty_space < 1) {
                            Notify({
                                title: notify_title,
                                context: `${getText('NOTIFY_MSG_ROOM_SIZE_LIMIT')} ${this.instance.RoomSizeLimit()}.`,
                                type: 'warning',
                                timeout: 10000
                            });
                        }
                    } else {
                        Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_FILE_FORMAT_INVALID'), timeout: 10000 });
                    }

                    this.roomBatcher.length = 0;
                    import_list.forEach(room_json => { this.roomBatcher.push(room_json); });
                    this.setState({ upload_left: import_list.length, upload_total: import_list.length });
                    if (import_list.length > 0) {
                        this.pop('room');
                        this.roomBatcher.run();
                    }
                },
                onError: (msg, timeout) => {
                    Notify({ type: 'error', title: notify_title, context: msg, timeout });
                }
            });
        }
        event.target.value = '';
    }
    handleImportScenarioFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const notify_title = getText('NOTIFY_TITLE_IMPORT_SCENARIO');
        const json_file = (event.target.files) ? event.target.files[0] : undefined;
        if (json_file) {
            CsxUtil.importHandler({
                file: json_file,
                userConfirm: getText('CONFIRM_IMPORT_SCENARIO_BACKUP'),
                validateFilename: (fn) => { const fn_walk = fn.split('.'); return (fn_walk.slice(-3).join('.').toLowerCase() === 'scene.grp.json' || fn_walk.slice(-2).join('.').toLowerCase() === 'scene.json'); },
                onConfirm: async (parseJson) => {
                    let empty_space = this.instance.ScenarioSizeLimit() - this.instance.ScenarioSize();
                    let import_list: Array<CsxEventSystem.CsxScenarioJson> = [];
                    empty_space = (empty_space < 0) ? 0 : empty_space;

                    if (CsxEventSystem.isCsxScenarioSetJson(parseJson)) {
                        import_list = (parseJson.list.length > empty_space) ? parseJson.list.slice(0, empty_space) : parseJson.list;
                        if (parseJson.list.length > empty_space) {
                            Notify({
                                title: notify_title,
                                context: `${getText('NOTIFY_MSG_SCENARIO_SIZE_LIMIT')} ${this.instance.ScenarioSizeLimit()}.`,
                                type: 'warning',
                                timeout: 10000
                            });
                        }
                    } else if (CsxEventSystem.isCsxScenarioJson(parseJson)) {
                        import_list = (empty_space < 1) ? [] : [ parseJson ];
                        if (empty_space < 1) {
                            Notify({
                                title: notify_title,
                                context: `${getText('NOTIFY_MSG_SCENARIO_SIZE_LIMIT')} ${this.instance.ScenarioSizeLimit()}.`,
                                type: 'warning',
                                timeout: 10000
                            });
                        }
                    } else {
                        Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_FILE_FORMAT_INVALID'), timeout: 10000 });
                    }
                    
                    this.scenarioBatcher.length = 0;
                    import_list.forEach(scenario_json => { this.scenarioBatcher.push(scenario_json); });
                    this.setState({ upload_left: import_list.length, upload_total: import_list.length });
                    if (import_list.length > 0) {
                        this.pop('scenario');
                        this.scenarioBatcher.run();
                    }
                },
                onError: (msg, timeout) => {
                    Notify({ type: 'error', title: notify_title, context: msg, timeout });
                }
            });
        }
        event.target.value = '';
    }
    handleImportAutomationFile = (event: React.ChangeEvent<HTMLInputElement>) => {
        const notify_title = getText('NOTIFY_TITLE_IMPORT_AUTOMATION');
        const json_file = (event.target.files) ? event.target.files[0] : undefined;
        if (json_file) {
            CsxUtil.importHandler({
                file: json_file,
                userConfirm: getText('CONFIRM_IMPORT_AUTOMATION_BACKUP'),
                validateFilename: (fn) => { const fn_walk = fn.split('.'); return (fn_walk.slice(-3).join('.').toLowerCase() === 'auto.grp.json' || fn_walk.slice(-2).join('.').toLowerCase() === 'auto.json'); },
                onConfirm: (parseJson) => {
                    let empty_space = this.instance.AutomationSizeLimit() - this.instance.AutomationSize();
                    let import_list: Array<CsxEventSystem.CsxAutomationJson> = [];
                    empty_space = (empty_space < 0) ? 0 : empty_space;

                    if (CsxEventSystem.isCsxAutomationSetJson(parseJson)) {
                        import_list = (parseJson.list.length > empty_space) ? parseJson.list.slice(0, empty_space) : parseJson.list;
                        if (parseJson.list.length > empty_space) {
                            Notify({
                                title: notify_title,
                                context: `${getText('NOTIFY_MSG_AUTOMATION_SIZE_LIMIT')} ${this.instance.AutomationSizeLimit()}.`,
                                type: 'warning',
                                timeout: 10000,
                            });
                        }
                    } else if (CsxEventSystem.isCsxAutomationJson(parseJson)) {
                        import_list = (empty_space < 1) ? [] : [ parseJson ];
                        if (empty_space < 1) {
                            Notify({
                                title: notify_title,
                                context: `${getText('NOTIFY_MSG_AUTOMATION_SIZE_LIMIT')} ${this.instance.AutomationSizeLimit()}.`,
                                type: 'warning',
                                timeout: 10000,
                            });
                        }
                    } else {
                        Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_FILE_FORMAT_INVALID'), timeout: 10000 });
                    }
                    
                    this.automationBatcher.length = 0;
                    import_list.forEach(automation_json => { this.automationBatcher.push(automation_json); });
                    this.setState({ upload_left: import_list.length, upload_total: import_list.length });
                    if (import_list.length > 0) {
                        this.pop('automation');
                        this.automationBatcher.run();
                    }
                },
                onError: (msg, timeout) => {
                    Notify({ type: 'error', title: notify_title, context: msg, timeout });
                }
            });
        }
        event.target.value = '';
    }
    handleImportIconFile = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const cur_option = this.instance.UserIconImportOption();
        const cur_remember = this.instance.UserIconImportHintRemember();
        this.iconsFileTmp = (event.target.files) ? event.target.files[0] : undefined;
        if (cur_remember === 'true') {
            /* Don't ask user again */
            if (this.iconsFileTmp && window.FOCUS_GATEWAY) {
                this.setLoading(true);
                try {
                    await window.FOCUS_GATEWAY.iconPackageImport(this.iconsFileTmp, cur_option);
                } catch (error) {
                    console.log('error :>> ', error);
                }
                this.setLoading(false);
            }
            this.iconsFileTmp = undefined;
        } else {
            const import_option_form = (
                <div style={{ width: '100%', height: '100%' }}>
                    <p>{getText('CONFIRM_IMPORT_ICON_BACKUP')}</p>
                    <RadioGroup
                        layout='vertical'
                        defaultValue={(CsxControlSystem.isIconImportOptionType(cur_option) ? cur_option : 'auto-rename')}
                        options={CsxControlSystem.ICON_IMPORT_OPTION_TYPE_LIST.map(option_type => {
                            return { value: option_type, label: this.ICON_IMPORT_OPTION_LABEL[option_type] };
                        })}
                        onChange={this.onChangeIconImportOption}
                    />
                    <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Checkbox
                            label={getText('ICON_IMPORT_BEHAVIOR_REMEMBER_OPTION')}
                            defaultChecked={(cur_remember === 'true')}
                            onChange={this.onChangeIconImportHintRemember}
                        />
                    </Form.Item>
                </div>
            );
            if (CsxControlSystem.isIconImportOptionType(cur_option)) {
                this.dialog_icon_upload_policy = cur_option;
            }
            this.dialog_icon_upload_policy_remember = (cur_remember === 'true');
            window.userConfirm(import_option_form, async (ok) => {
                if (ok) {
                    if (this.iconsFileTmp && window.FOCUS_GATEWAY) {
                        this.setLoading(true);
                        this.instance.SetUserIconImportOption(this.dialog_icon_upload_policy);
                        this.instance.SetUserIconImportHintRemember(this.dialog_icon_upload_policy_remember ? 'true' : 'false');
                        try {
                            await window.FOCUS_GATEWAY.iconPackageImport(this.iconsFileTmp, this.dialog_icon_upload_policy);
                        } catch (error) {
                            console.log('error :>> ', error);
                        }
                        this.setLoading(false);
                    }
                    this.iconsFileTmp = undefined;
                }
            }, {
                type: 'warning',
                width: 500,
                bodyFooterMargin: 0,
                bodyAlignment: 'left',
            });
        }

        /* Event may be set to null after a await call */
        if (event && event.target) {
            event.target.value = '';
        }
    }
    downloadRoomConfig = () => {
        const room_set_json = this.instance.RoomSetJson();
        if (room_set_json) {
            for (let i = 0; i < room_set_json.list.length; i++) {
                const room_json = room_set_json.list[i];
                const room = this.instance.Room(room_json.id);
                if (room) {
                    room_json.automationSnapshot = room.takeAutomationSnapshot();
                    room_json.scenarioSnapshot = room.takeScenarioSnapshot();
                    room_json.cypDeviceSnapshot = room.takeDeviceSnapshot();
                }
            }
            CsxUtil.downloadAsJson(this.instance.RoomSetFilename(), JSON.stringify(room_set_json, null, 4));
        } else {
            Notify({ title: getText('NOTIFY_TITLE_DOWNLOAD_ERROR'), context: getText('NOTIFY_MSG_CONFIG_NOT_FOUND'), type: 'error' });
        }
    }
    downloadScenarioConfig = async () => {
        const scenes_set_json = this.instance.ScenarioSetJson();
        if (scenes_set_json) {
            for (let i = 0; i < scenes_set_json.list.length; i++) {
                const scenario_json = scenes_set_json.list[i];
                const { id } = scenario_json;
                const scenario = this.instance.Scenario((id) ? id : '');
                if (scenario) {
                    scenario_json.automationSnapshot = scenario.takeAutomationSnapshot();
                    scenario_json.cypDeviceSnapshot = scenario.takeDeviceSnapshot();
                }
            }
            CsxUtil.downloadAsJson(this.instance.ScenarioSetFilename(), JSON.stringify(scenes_set_json, null, 4));
        } else {
            Notify({ title: getText('NOTIFY_TITLE_DOWNLOAD_ERROR'), context: getText('NOTIFY_MSG_CONFIG_NOT_FOUND'), type: 'error' });
        }
    }
    downloadAutomationConfig = () => {
        const automation_set_json = this.instance.AutomationSetJson();
        if (automation_set_json) {
            CsxUtil.downloadAsJson(this.instance.AutomationSetFilename(), JSON.stringify(automation_set_json, null, 4));
        } else {
            Notify({ title: getText('NOTIFY_TITLE_DOWNLOAD_ERROR'), context: getText('NOTIFY_MSG_CONFIG_NOT_FOUND'), type: 'error' });
        }
    }
    downloadIconConfig = async () => {
        this.setLoading(true);
        CsxUtil.downloadFromURL(
            this.instance.IconSetFilename(),
            "/cgi-bin/export-icon",
            (event) => { if (event.loaded === event.total) { this.setLoading(false); } },
            [{ key: 'Content-Type', value: 'application/json;charset=UTF-8' }],
            JSON.stringify({ icons: Array.from(this.instance.IconSet()) }));
    }
    render() {
        const {
            popup,
            loading,
            // new_icon_name, icon_upload_stop_tmp_json,
            new_automation_name, automation_upload_stop_tmp_json,
            new_scenario_name, scenario_upload_stop_tmp_json,
            new_room_name, room_upload_stop_tmp_json,
            upload_left, upload_total, batch_uploader_status,
        } = this.state;

        /* Room Upload */
        const room_name_valid = (new_room_name.length > 0 && !CsxUtil.hasChinese(new_room_name)) && !this.instance.CheckRoomNameExist(new_room_name);
        /* Scenario Upload */
        const scenario_name_valid = (new_scenario_name.length > 0 && !CsxUtil.hasChinese(new_scenario_name)) && !this.instance.CheckScenarioNameExist(new_scenario_name);
        /* Automation Upload */
        const automation_name_valid = (new_automation_name.length > 0 && !CsxUtil.hasChinese(new_automation_name)) && !this.instance.CheckAutomationNameExist(new_automation_name);
        /* Icon Upload */
        // const icon_name_valid = (new_icon_name.length > 0 && !CsxUtil.hasChinese(new_icon_name)) && !this.instance.CheckIconNameExist(new_icon_name);

        const cur_option = this.instance.UserIconImportOption();
        const cur_remember = this.instance.UserIconImportHintRemember();
        const upload_percentage = (((upload_total - upload_left) / upload_total) * 100);
        const upload_hint = `Uploaded ${(upload_total - upload_left)}/${upload_total}`;

        const modal_title: { [pop in BackupModalType]: string } = {
            none: '',
            icon_import_option: getText('SETTING_SYSTEM_ICON_IMPORT_SETTING_LABEL'),
            automation: `${getText('UPLOAD')} ${getText('APP_NAV_AUTOMATION')}`,
            scenario: `${getText('UPLOAD')} ${getText('APP_NAV_SCENARIO')}`,
            room: `${getText('UPLOAD')} ${getText('APP_NAV_ROOM')}`,
        }
        const modal_map: { [pop in BackupModalType]: React.ReactElement } = {
            none: <div />,
            icon_import_option: (
                <div>
                    <p>{getText('CONFIRM_IMPORT_ICON_BACKUP')}</p>
                    <RadioGroup
                        layout='vertical'
                        value={(CsxControlSystem.isIconImportOptionType(cur_option) ? cur_option : 'auto-rename')}
                        options={CsxControlSystem.ICON_IMPORT_OPTION_TYPE_LIST.map(option_type => {
                            return { value: option_type, label: this.ICON_IMPORT_OPTION_LABEL[option_type] };
                        })}
                        onChange={(v) => { this.instance.SetUserIconImportOption(v); }}
                    />
                    <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Checkbox
                            label={getText('ICON_IMPORT_BEHAVIOR_REMEMBER_OPTION')}
                            checked={(cur_remember === 'true')}
                            onChange={(e) => { this.instance.SetUserIconImportHintRemember(e.target.checked ? 'true' : 'false'); }}
                        />
                    </Form.Item>
                </div>
            ),
            automation: (
                <Form.Form labelStyle={{ width: '100px' }}>
                    <Form.Item>
                        <ProgressBar percentage={upload_percentage} hint={upload_hint}/>
                    </Form.Item>
                    <div style={{ fontStyle: 'italic' }}>
                        <p>{getText('SETTING_SYSTEM_AUTOMATION_INFO')}:</p>
                        {(automation_upload_stop_tmp_json) ? <ul style={{ paddingLeft: '10px', marginLeft: '10px' }}>
                            <li style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '10px' }}>
                                <span>* Current Name:</span>
                                <span>{automation_upload_stop_tmp_json.name}</span>
                            </li>
                            <li style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '10px' }}>
                                <span>{`* Detects ${automation_upload_stop_tmp_json.events.length} events`}</span>
                            </li>
                            <li style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '10px' }}>
                                <span>{`* Triggers ${automation_upload_stop_tmp_json.actions.length} actions`}</span>
                            </li>
                        </ul> : undefined}
                    </div>
                    {(batch_uploader_status === 'stop') ? <Form.Item
                        error={(automation_name_valid)?undefined:getText('HINT_DUPLICATE_NAME')}
                        label={getText('NEW_NAME')}
                    >
                        <Input
                            placeholder='Automation Name'
                            value={new_automation_name}
                            onChange={this.onChangeAutomationName}
                        />
                    </Form.Item> : undefined}
                    {(batch_uploader_status === 'stop') ? <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Button onClick={this.onOverwriteAutomation}>{getText('OVERWRITE')}</Button>
                        <Button onClick={this.onSkipAutomationName}>{getText('SKIP')}</Button>
                        <Button type='primary' onClick={this.onConfirmNewAutomationName} disabled={!automation_name_valid} >{getText('CONFIRM')}</Button>
                    </Form.Item> : undefined}
                </Form.Form>
            ),
            scenario: (
                <Form.Form labelStyle={{ width: '100px' }}>
                    <Form.Item>
                        <ProgressBar percentage={upload_percentage} hint={upload_hint}/>
                    </Form.Item>
                    <div style={{ fontStyle: 'italic' }}>
                        <p>{getText('SETTING_SYSTEM_SCENARIO_INFO')}:</p>
                        {(scenario_upload_stop_tmp_json) ? <ul style={{ paddingLeft: '10px', marginLeft: '10px' }}>
                            <li style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '10px' }}>
                                <span>* Current Name:</span>
                                <span>{scenario_upload_stop_tmp_json.name}</span>
                            </li>
                            <li style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '10px' }}>
                                <span>{`* Contains ${scenario_upload_stop_tmp_json.actions.length} scripts`}</span>
                            </li>
                            <li style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '10px' }}>
                                <span>{`* Contains ${scenario_upload_stop_tmp_json.automations.length} automation groups`}</span>
                            </li>
                        </ul> : undefined}
                    </div>
                    {(batch_uploader_status === 'stop') ? <Form.Item
                        error={(scenario_name_valid)?undefined:getText('HINT_DUPLICATE_NAME')}
                        label={getText('NEW_NAME')}
                    >
                        <Input
                            placeholder='Scenario Name'
                            value={new_scenario_name}
                            onChange={this.onChangeScenarioName}
                        />
                    </Form.Item> : undefined}
                    {(batch_uploader_status === 'stop') ? <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Button onClick={this.onOverwriteScenario}>{getText('OVERWRITE')}</Button>
                        <Button onClick={this.onSkipScenarioName}>{getText('SKIP')}</Button>
                        <Button type='primary' onClick={this.onConfirmNewScenarioName} disabled={!scenario_name_valid} >{getText('CONFIRM')}</Button>
                    </Form.Item> : undefined}
                </Form.Form>
            ),
            room: (
                <Form.Form labelStyle={{ width: '100px' }}>
                    <Form.Item>
                        <ProgressBar percentage={upload_percentage} hint={upload_hint}/>
                    </Form.Item>
                    <div style={{ fontStyle: 'italic' }}>
                        <p>{getText('SETTING_SYSTEM_ROOM_INFO')}:</p>
                        {(room_upload_stop_tmp_json) ? <ul style={{ paddingLeft: '10px', marginLeft: '10px' }}>
                            <li style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '10px' }}>
                                <span>* Current Name:</span>
                                <span>{room_upload_stop_tmp_json.name}</span>
                            </li>
                            <li style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', paddingRight: '10px' }}>
                                <span>{`* Contains ${room_upload_stop_tmp_json.scenes.length} scenarios`}</span>
                            </li>
                        </ul> : undefined}
                    </div>
                    {(batch_uploader_status === 'stop') ? <Form.Item
                        error={(room_name_valid)?undefined:getText('HINT_DUPLICATE_NAME')}
                        label={getText('NEW_NAME')}
                    >
                        <Input
                            placeholder='Room Name'
                            value={new_room_name}
                            onChange={this.onChangeRoomName}
                        />
                    </Form.Item> : undefined}
                    {(batch_uploader_status === 'stop') ? <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Button onClick={this.onOverwriteRoom}>{getText('OVERWRITE')}</Button>
                        <Button onClick={this.onSkipRoomName}>{getText('SKIP')}</Button>
                        <Button type='primary' onClick={this.onConfirmNewRoomName} disabled={!room_name_valid} >{getText('CONFIRM')}</Button>
                    </Form.Item> : undefined}
                </Form.Form>
            ),
        }
        const icon_import_label = (
            <div style={{ display: 'flex', position: 'relative', flexDirection: 'row', justifyContent: 'space-between', width: 'calc(100% - 10px)', alignItems: 'center' }}>
                <span style={{ width: 'calc(100% - 20px)' }}>{getText('SETTING_SYSTEM_ICON_CONF')}</span>
                <Tooltip direction='top' text={getText('SETTING_SYSTEM_ICON_IMPORT_SETTING_LABEL')}><Icon type='setting' color='#008aab' onClick={() => { this.pop('icon_import_option'); }}/></Tooltip>
            </div>
        );
        return (
            <Flat.Section title={getText('SETTING_SYSTEM_BACKUP_LABEL')}>
                <Spin visible={loading} style={{ opacity: 0.5 }} type='linear' />
                <Form.Form labelStyle={{ width: '200px' }}>
                    <Form.Item label={getText('SETTING_SYSTEM_ROOM_CONF')}>
                        <Button icon='upload' onClick={this.triggerSelectRoomFile}>{getText('UPLOAD')}</Button>
                        <Button icon='download' onClick={this.downloadRoomConfig}>{getText('DOWNLOAD')}</Button>
                    </Form.Item>
                    <Form.Item label={getText('SETTING_SYSTEM_SCENARIO_CONF')}>
                        <Button icon='upload' onClick={this.triggerSelectScenarioFile}>{getText('UPLOAD')}</Button>
                        <Button icon='download' onClick={this.downloadScenarioConfig}>{getText('DOWNLOAD')}</Button>
                    </Form.Item>
                    <Form.Item label={getText('SETTING_SYSTEM_AUTOMATION_CONF')}>
                        <Button icon='upload' onClick={this.triggerSelectAutomationFile}>{getText('UPLOAD')}</Button>
                        <Button icon='download' onClick={this.downloadAutomationConfig}>{getText('DOWNLOAD')}</Button>
                    </Form.Item>
                    <Form.Item labelStyle={{ width: '200px', display: 'flex', flexDirection: 'row' }} label={icon_import_label}>
                        <Button icon='upload' onClick={this.triggerSelectIconFile}>{getText('UPLOAD')}</Button>
                        <Button icon='download' onClick={this.downloadIconConfig}>{getText('DOWNLOAD')}</Button>
                    </Form.Item>
                </Form.Form>
                {!window.APP_ON_HDMI ? <input type='file' style={{ display: 'none' }} ref={inst => { this.roomFileSelect = inst; }} onChange={this.handleImportRoomFile} accept='.json' /> : undefined}
                {!window.APP_ON_HDMI ? <input type='file' style={{ display: 'none' }} ref={inst => { this.scenarioFileSelect = inst; }} onChange={this.handleImportScenarioFile} accept='.json' /> : undefined}
                {!window.APP_ON_HDMI ? <input type='file' style={{ display: 'none' }} ref={inst => { this.automationFileSelect = inst; }} onChange={this.handleImportAutomationFile} accept='.json' /> : undefined}
                {!window.APP_ON_HDMI ? <input type='file' style={{ display: 'none' }} ref={inst => { this.iconFileSelect = inst; }} onChange={this.handleImportIconFile} accept='.tgz' /> : undefined}
                <Modal
                    title={modal_title[popup]}
                    visible={popup !== 'none'}
                    onClose={() => { this.pop('none'); }}
                >
                    {modal_map[popup]}
                </Modal>
            </Flat.Section>
        );
    }
}

class TimeBlock extends CsxUI.IRQComponent<any> {
    state: {
        date?: Date; time?: Date; time_changed: boolean;
        ntp_url?: string; ntp_url_changed: boolean; ntp_url_err?: string;
    };
    instance: CsxFeature.CsxTimeManageDevice;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'DeviceRealTime';
        this.instance = new CsxFeature.CsxTimeManageDevice(window.FOCUS_GATEWAY);
        this.state = {
            time_changed: false,
            ntp_url_changed: false,
        };
        this.instance.TimeFlowSimulateStart();
    }
    validateNTPUrl = (value: string) => {
        const ntpUrlParam = this.instance.NTPServerUrlParam();
        let ok = true;
        if (ntpUrlParam.s1.isString) {
            ok = this.validateString(value, ntpUrlParam.s1.isString);
        }
        this.setState({ ntp_url_err: (ok?undefined:getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    onChangeNTPSync = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.instance.IsSupportNTPTimeSyncDuration()) {
            this.instance.SetNTPTimeSyncDuration(((e.target.checked)?1:0));
        }
    }
    onChangeNTPUrl = (e: React.ChangeEvent<HTMLInputElement>) => { if (this.validateNTPUrl(e.target.value) || e.target.value.length === 0) this.setState({ ntp_url: e.target.value, ntp_url_changed: true }); }
    onDateChange = (value: Date | Date[]) => { this.instance.TimeFlowSimulateStop(); this.setState({ date: value, time_changed: true }); }
    onTimeChange = (value: Date | Date[]) => { this.instance.TimeFlowSimulateStop(); this.setState({ time: value, time_changed: true }); }
    onSaveNTPUrl = () => {
        const { ntp_url } = this.state;
        if (ntp_url) {
            this.instance.SetNTPServerUrl(ntp_url);
            this.setState({ ntp_url: undefined, ntp_url_changed: false });
        }
    }
    onSaveTime = () => {
        const { time, date } = this.state;
        if (time) {
            this.instance.SetTime(time.getHours(), time.getMinutes(), time.getSeconds());
        }
        if (date) {
            this.instance.SetDate(date.getFullYear(), (date.getMonth() + 1), date.getDate());
        }
        this.setState({ time: undefined, date: undefined, time_changed: false });
        this.instance.TimeFlowSimulateStart();
    }
    componentBeforeUmount() {
        this.instance.TimeFlowSimulateStop();
    }
    render() {
        const { date, time, time_changed, ntp_url, ntp_url_changed, ntp_url_err } = this.state;
        const timezoneParam = this.instance.TimezoneParam();

        const timezone_opt = (timezoneParam.n1.isOption) ? timezoneParam.n1.isOption.option : [];
        const timezone_desc = (timezoneParam.n1.isOption) ? timezoneParam.n1.isOption.desc : [];

        const parse_inst_date = DateTime.ParseDateTime(this.instance.Date(), 'YYYY.MM.DD');
        const parse_inst_time = DateTime.ParseDateTime(this.instance.Time(), 'HH:mm:ss');
        const website_timelapse = this.instance.WebsiteTimelapse();
        if (parse_inst_date)
            parse_inst_date.setSeconds(parse_inst_date.getSeconds() + website_timelapse);
        if (parse_inst_time)
            parse_inst_time.setSeconds(parse_inst_time.getSeconds() + website_timelapse);

        const show_ntp_url = (ntp_url) ? ntp_url : this.instance.NTPServerUrl();
        const show_date = (date) ? date : (parse_inst_date ? parse_inst_date : new Date(0));
        const show_time = (time) ? time : (parse_inst_time ? parse_inst_time : new Date(0));

        const timezone = this.instance.Timezone();
        const ntp_sync_duration = parseInt(this.instance.NTPTimeSyncDuration());
        const ntp_sync_enable = (!isNaN(ntp_sync_duration) && (ntp_sync_duration > 0));

        return (
            <Flat.Section title={getText('SETTING_SYSTEM_TIME_LABEL')}>
                <Form.Form labelStyle={{ width: '150px' }}>
                    <Form.Item label={getText('SETTING_SYSTEM_TIME_NTP_SYNC')} style={CsxUI.getHiddenStyle(this.instance.IsSupportNTPTimeSynchronize())}>
                        <SwitchButton checked={ntp_sync_enable} label={[ 'Enable', 'Disable' ]} onChange={this.onChangeNTPSync} />
                    </Form.Item>
                    <Form.Item label={getText('SETTING_SYSTEM_TIME_NTP_SERVER')} error={ntp_url_err} style={CsxUI.getHiddenStyle(this.instance.IsSupportNTPServerUrl())}>
                        <Input value={show_ntp_url} onChange={this.onChangeNTPUrl} />
                        <Button type='primary' disabled={!ntp_url_changed} onClick={this.onSaveNTPUrl}>{getText('SAVE')}</Button>
                    </Form.Item>
                    <Form.Item label={getText('SETTING_SYSTEM_TIME_NTP_TIMEZONE')} style={CsxUI.getHiddenStyle(this.instance.IsSupportTimezone())}>
                        <Select
                            placeholder='Select Timezone'
                            value={timezone}
                            disabled={!this.instance.IsSupportTimezone()}
                            onChange={this.instance.SetTimezone}
                        >
                            {timezone_opt.map((opt, opt_idx) => {
                                const zonename = (timezone_desc) ? timezone_desc[opt_idx] : opt;
                                return <Option key={`timezone_opt_${opt_idx}`} value={opt}>{zonename}</Option>
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item label={getText('SETTING_SYSTEM_TIME_NTP_DATE_TIME')} style={CsxUI.getHiddenStyle(this.instance.IsSupportDate() || this.instance.IsSupportTime())}>
                        <DateTime.DatePicker value={show_date} disabled={ntp_sync_enable} onChange={this.onDateChange} style={CsxUI.getHiddenStyle(this.instance.IsSupportDate())} />
                        <DateTime.TimePicker value={show_time} disabled={ntp_sync_enable} onChange={this.onTimeChange} style={CsxUI.getHiddenStyle(this.instance.IsSupportTime())} />
                        <Button type='primary' disabled={!time_changed || ntp_sync_enable} onClick={this.onSaveTime}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
            </Flat.Section>
        );
    }
}

export default class SettingPage extends React.Component {
    render() {
        return (
            <div style={{ maxHeight: '100%', height: '100%', overflow: 'hidden' }}>
                <Route path='/management/settings/system/' render={() => (
                    <Flat.Playground hasExtendToggler contextStyle={{ height: 'auto' }}>
                        <LANBlock />
                        <DeviceNameBlock />
                        <TimeBlock />
                        <SystemBlock />
                        <UARTBlock />
                        <BackupBlock />
                        <FirmwareVerBlock />
                    </Flat.Playground>
                )} />
                <Route path='/management/settings/authentication/' render={() => <Flat.Playground><UserManagementBlock /></Flat.Playground>} />
            </div>
        );
    }
}
