import * as CsxUtil from './util';
import { CsxDevice, irqRefresh, CsxAuthorization, CsxUserPermissionLevel, UserPermissionType, csxUserPermissionSatisfy } from './manager';
import { CsxEventSystem } from '.';
import { Notify } from 'cypd';
import { getText } from './desc';

// UI Update Rule : 
// When user editing device state/information/settings, FeatureDevice instance will buffer all operation on UI (though not uploading to real-device yet).
// At this time, realtime upading will be stored but blocked on current UI, which may interrupt user operation.
// After buffer being empty, FeatureDevice enables the realtime upading to catch the latest status
// eslint-disable-next-line no-useless-escape
const NUM_REGEX_PATTERN = '[-+]?\\d+(?:\\.?\\d+)?';
const BUFFER_UNTIL_UPLOAD = true;
export const FEATURE_SUP_LIST = CsxUtil.stringLitArray([
    "Routing",
    "Audio",
    "OSD",
    "Scaler",
    "VideoWall",
    "Automation",
    "Streaming",
    "Record",
    "CEC",
    "EDID",
    "HDCP",
    "General",
    "IO",
    "Dante",
    "Time",
    "PowerMonitor",
    "FWUpdate",
    "CableTest",
    "Debug",
]);
export declare type FeatureSupportType = (typeof FEATURE_SUP_LIST)[number]
export const isFeatureSupportType = (x: any): x is FeatureSupportType => FEATURE_SUP_LIST.includes(x);

const CSX_CMD_VER_LIST = CsxUtil.stringLitArray(['v3_2_default', 'v3_3', 'v3_4']);
declare type CsxCommandVersion = (typeof CSX_CMD_VER_LIST)[number];
const isCsxCommandVersion = (x: any): x is CsxCommandVersion => CSX_CMD_VER_LIST.includes(x);
declare type stringable = string | number;
export declare type RANGE_PARAM = {
    range: { max: number; min: number; step: number };
    fixed?: number;
    radix?: 2 | 8 | 10 | 16;
}
export declare type OPTION_PARAM = {
    option: Array<string>;
    desc?: Array<string>;
}
export declare type STRING_PARAM = {
    length: { max: number; min: number };
    regex?: RegExp;
}
export declare type ARRAY_PARAM = {
    length: { max: number; min: number };
    items: { [s: string]: CSX_CMD_PARAM };
}
export declare type FLOAT_PARAM = {
    range: { max: number; min: number };
    precision: 16 | 32;
}
declare type CSX_CMD_PARAM = {
    isRange?: RANGE_PARAM;
    isOption?: OPTION_PARAM;
    isString?: STRING_PARAM;
    isArray?: ARRAY_PARAM;
    isFloat?: FLOAT_PARAM;
}

declare type CSX_PARAM_EXCEPTION = { [para_idx: string]: CSX_CMD_PARAM };

function isEmpty(obj: any): boolean {
    return JSON.stringify(obj) === '{}';
}

function flatelize(tar: any, leading: string): Array<string> {
    let buf: Array<string> = [];
    if (typeof tar === 'object' && tar !== null) {
        for (const key in tar) {
            const next_leading = leading.length ? `${leading}.${key}` : key;
            if (Object.prototype.hasOwnProperty.call(tar, key))
                buf = buf.concat(flatelize(tar[key], next_leading));
        }
    } else {
        buf = ((tar || tar === 0) ? [`${leading}=${tar}`] : []);
    }
    return buf;
}

const CMD_GET_TO_SET: { [s in CsxUtil.CYP_STD_CMD_TYPE]?: CsxUtil.CYP_STD_CMD_TYPE } = {                                    /* A42->A41,A44->A43,A46->A45 may use for outdate static ip configuration */
    A16: 'A15', A19: 'A18', A21: 'A20', A24: 'A23', A28: 'A27', A32: 'A31', A34: 'A33', A36: 'A35', A38: 'A37', A41: 'A40', A42: 'A41', A44: 'A43', A46: 'A45', A50: 'A49', A52: 'A51', A54: 'A53', A57: 'A56', A59: 'A58', A61: 'A60', A63: 'A62', A65: 'A64', A67: 'A66', A69: 'A70', A72: 'A126', A74: 'A73', A76: 'A75', A86: 'A85', A88: 'A87', A90: 'A89', A101: 'A100', A103: 'A102', A105: 'A104', A110: 'A109', A112: 'A111', A114: 'A113', A116: 'A115', A118: 'A117', A120: 'A119', A129: 'A128',
    B2: 'B1', B4: 'B3', B6: 'B5', B8: 'B7', B10: 'B9', B12: 'B11', B14: 'B13', B16: 'B15', B18: 'B17', B20: 'B19', B22: 'B21',
    C11: 'C10', C16: 'C15',
    D2: 'D1', D4: 'D3', D10: 'D9', D12: 'D11', D14: 'D13', D16: 'D15', D18: 'D17', D20: 'D19', D22: 'D21', D24: 'D23', D29: 'D28', D31: 'D30', D33: 'D32', D35: 'D34', D37: 'D36', D39: 'D38', D41: 'D40', D44: 'D43', D46: 'D45', D48: 'D47', D50: 'D49', D52: 'D51', D54: 'D53', D56: 'D55', D58: 'D57', D60: 'D59', D62: 'D61', D64: 'D63', D66: 'D65', D69: 'D68',
    E2: 'E1', E4: 'E3', E6: 'E5', E8: 'E7', E10: 'E9', E13: 'E12', E15: 'E14', E17: 'E16', E19: 'E18', E21: 'E20', E23: 'E22', E25: 'E24', E27: 'E26', E29: 'E28', E31: 'E30', E33: 'E32', E35: 'E34', E37: 'E36', E39: 'E38', E41: 'E40', E43: 'E42', E45: 'E43', E47: 'E46', E49: 'E48', E51: 'E50', E53: 'E52', E55: 'E54', E57: 'E56', E59: 'E58', E61: 'E60', E63: 'E62', E65: 'E64', E67: 'E66', E69: 'E68', E71: 'E70', E73: 'E72',
    F2: 'F1', F4: 'F3', F6: 'F5',
    G2: 'G1', G4: 'G3', G6: 'G5', G8: 'G7', G10: 'G9', G12: 'G11', G14: 'G13', G16: 'G15', G18: 'G17', G20: 'G19', G22: 'G21', G25: 'G24', G27: 'G26', G30: 'G29', G32: 'G31', G35: 'G34', G37: 'G36', G39: 'G38', G41: 'G40',
    H2: 'H1', H5: 'H4', H7: 'H6', H11: 'H10', H13: 'H12', H15: 'H14', H20: 'H19', H22: 'H21', H24: 'H23', H26: 'H25', H28: 'H27', H32: 'H31', H34: 'H33', H36: 'H35', H38: 'H37', H40: 'H39', H42: 'H41', H44: 'H43', H46: 'H45', H48: 'H47', H50: 'H49', H52: 'H51', H54: 'H53', H56: 'H55', H58: 'H57', H64: 'H63',
    I2: 'I1', I5: 'I4', I7: 'I6', I8: 'I8', I9: 'I9', I11: 'I11', I13: 'I12', I15: 'I14',
    J2: 'J1', J3: 'J3', J4: 'J4', J5: 'J5', J6: 'J6', J8: 'J7', J10: 'J9',
    L2: 'L1', L8: 'L7', L10: 'L9', L13: 'L12', L15: 'L14',
    O2: 'O1', O4: 'O3', O6: 'O5', O8: 'O7', O10: 'O9', O12: 'O11', O14: 'O13', O16: 'O15', O18: 'O17', O24: 'O23',
    P2: 'P1', P4: 'P3', P6: 'P5', P8: 'P7', P10: 'P9', P12: 'P11', P14: 'P13', P16: 'P15',
    M4: 'M3', M6: 'M5', M8: 'M7', M11: 'M10', M17: 'M16', M29: 'M28', M31: 'M30', M33: 'M32', M35: 'M34', M37: 'M36', M47: 'M46', M49: 'M48', M51: 'M50', M54: 'M53', M56: 'M55', M58: 'M57', M75: 'M74', M77: 'M76', M103: 'M102', M108: 'M107', M110: 'M109',
    S2: 'S1', S4: 'S3', S6: 'S5', S13: 'S12', S16: 'S15', S17: 'S17', S18: 'S18', S19: 'S19', S20: 'S20', S21: 'S21', S27: 'S27', S29: 'S28', S31: 'S30', S32: 'S32', S33: 'S33', S34: 'S34', S35: 'S35', S36: 'S36', S37: 'S37',
    T2: 'T1', T4: 'T3', T6: 'T5', T8: 'T7', T12: 'T11', T18: 'T17', T20: 'T19', T22: 'T21', T25: 'T24',
}

const CMD_DESC: { [s in CsxUtil.CYP_STD_CMD_TYPE]? : string } = {
    A46: 'get ipaddr',
    A50: 'get static ipaddr',
    A82: 'get lan n1 ipaddr',
    A86: 'get lan n1 static ipaddr',
    // A88: 'get lan n1 static netmask',
    // A90: 'get lan n1 static gateway',
    A32: 'get uart n1 baudrate (necessary)',
    // A34: 'get uart n1 stop bit',
    // A36: 'get uart n1 data bit',
    // A38: 'get uart n1 parity',
    // A41: 'get uart n1 mode',
    S2: 'get in n1 name',
    S4: 'get out x1 name',
    S6: 'get out x1 route (necessary)',
    // S17: 'get in n1 hactive',
    // S18: 'get in n1 vactive',
    // S19: 'get in n1 refresh rate',
    // S20: 'get in n1 interlace',
    // S21: 'get in n1 sync status',
    // S22: 'get out x1 sync status',
    // S27: 'get in n1 deep color',
    // S29: 'get out x1 deep color',
    // S31: 'get out x1 color space',
    // S32: 'get out x1 h active',
    // S33: 'get out x1 v active',
    // S34: 'get out x1 refresh rate',
    // S35: 'get out x1 interlace',
    // S36: 'get in n1 type',
    // S37: 'get out x1 type',
    D4: 'get out x1 timing (necessary)',
    H11: 'get audio out x1 name (necessary)',
    I7: 'get user n1 edid data (necessary)',
    I8: 'get sink n1 edid data (necessary)',
    I9: 'get in n1 edid data (necessary)',
    I11: 'get internal n1 edid data (necessary)',
    J2: 'get in n1 hdcp mode (necessary)',
    J3: 'get in n1 hdcp status',
    J4: 'get out x1 hdcp status',
    J5: 'get in n1 hdcp ability',
    J6: 'get out x1 hdcp ability',
}

const CSX_PARAM_TYPE_LIST = CsxUtil.stringLitArray(['isFloat', 'isRange', 'isString', 'isArray', 'isOption']);

declare type CSXParamSyntaxType = (typeof CSX_PARAM_TYPE_LIST)[number];
declare type CSXParamSyntaxDefinition = { [syntax: string]: CSXParamSyntaxType };
const isCSXParamSyntaxType = (x: any): x is CSXParamSyntaxType => CSX_PARAM_TYPE_LIST.includes(x);

class CSXParamSyntax {
    private __table: { [version in CsxCommandVersion]: CSXParamSyntaxDefinition };

    syntax(s: string, ver?: CsxCommandVersion): CSXParamSyntaxType {
        const select_ver: CsxCommandVersion = (ver) ? ver : 'v3_2_default';
        return this.__table[select_ver][s];
    }

    syntaxes(ver?: CsxCommandVersion): Array<string> {
        const select_ver: CsxCommandVersion = (ver) ? ver : 'v3_2_default';
        return Object.keys(this.__table[select_ver]);
    }

    constructor(params: CSXParamSyntaxDefinition, ver_overloads?: Array<[ CsxCommandVersion, CSXParamSyntaxDefinition ]>) {
        this.__table = {
            v3_2_default: params,
            v3_3: params,
            v3_4: params,
        }
        if (ver_overloads) {
            const overload_ver_set = new Set(ver_overloads.map(overload => overload[0]));
            for (let i = 0; i < ver_overloads.length; i++) {
                /**
                 * Different definition for specific version
                 */
                const [ ver, def ] = ver_overloads[i];
                this.__table[ver] = def;
                
                /**
                 * Deploy overload to all future versions which are undefined
                 * - (traversal_ver > ver): is future version
                 * - (!overload_ver_set.has(traversal_ver)): overload is undefined
                 */
                for (let j = 0; j < CSX_CMD_VER_LIST.length; j++) {
                    const traversal_ver = CSX_CMD_VER_LIST[j];
                    if (traversal_ver > ver && !overload_ver_set.has(traversal_ver)) {
                        this.__table[traversal_ver] = def;
                    }
                }
            }
        }
    }
}

export const CYP_STD_PARA_SYNTAX_DEF: { [cmd in CsxUtil.CYP_STD_CMD_TYPE]?: CSXParamSyntax } = {
    A15: new CSXParamSyntax({ s1: 'isString' }),
    A16: new CSXParamSyntax({ s1: 'isString' }),
    A18: new CSXParamSyntax({ b1: 'isOption' }),
    A19: new CSXParamSyntax({ b1: 'isOption' }),
    A20: new CSXParamSyntax({ b1: 'isOption' }),
    // A22: { b1: 'isOption' }, // no need to check this "get cmd parameter s1"
    A23: new CSXParamSyntax({ s1: 'isOption' }),
    A24: new CSXParamSyntax({ s1: 'isOption' }),
    A26: new CSXParamSyntax({ b1: 'isOption' }),
    A27: new CSXParamSyntax({ b1: 'isOption' }),
    A28: new CSXParamSyntax({ b1: 'isOption' }),
    A43: new CSXParamSyntax({ s1: 'isOption' }),
    A44: new CSXParamSyntax({ s1: 'isOption' }),
    A49: new CSXParamSyntax({ nn: 'isString' }, [
        [ 'v3_3', { ip1: 'isString' } ]
    ]),
    A50: new CSXParamSyntax({ nn: 'isString' }, [
        [ 'v3_3', { ip1: 'isString' } ]
    ]),
    A51: new CSXParamSyntax({ nn: 'isString' }, [
        [ 'v3_3', { ip1: 'isString' } ]
    ]),
    A52: new CSXParamSyntax({ nn: 'isString' }, [
        [ 'v3_3', { ip1: 'isString' } ]
    ]),
    A53: new CSXParamSyntax({ nn: 'isString' }, [
        [ 'v3_3', { ip1: 'isString' } ]
    ]),
    A54: new CSXParamSyntax({ nn: 'isString' }, [
        [ 'v3_3', { ip1: 'isString' } ]
    ]),
    A56: new CSXParamSyntax({ s1: 'isString' }),
    A57: new CSXParamSyntax({ s1: 'isString' }),
    A58: new CSXParamSyntax({ s1: 'isString' }),
    A59: new CSXParamSyntax({ s1: 'isString' }),
    A60: new CSXParamSyntax({ b1: 'isOption' }),
    A61: new CSXParamSyntax({ b1: 'isOption' }),
    A62: new CSXParamSyntax({ n1: 'isRange' }),
    A63: new CSXParamSyntax({ n1: 'isRange' }),
    A64: new CSXParamSyntax({ s1: 'isString' }),
    A65: new CSXParamSyntax({ s1: 'isString' }),
    A66: new CSXParamSyntax({ s1: 'isString' }),
    A67: new CSXParamSyntax({ s1: 'isString' }),
    A68: new CSXParamSyntax({ n1: 'isRange' }),
    A69: new CSXParamSyntax({ n1: 'isRange' }),
    A70: new CSXParamSyntax({ b1: 'isOption' }),
    A71: new CSXParamSyntax({ b1: 'isOption' }),
    A73: new CSXParamSyntax({ n1: 'isRange' }),
    A74: new CSXParamSyntax({ n1: 'isRange' }),
    A75: new CSXParamSyntax({ n1: 'isRange' }),
    A76: new CSXParamSyntax({ n1: 'isRange' }),
    A77: new CSXParamSyntax({ n1:'isOption' }),
    A78: new CSXParamSyntax({ n1:'isOption' }),
    A79: new CSXParamSyntax({ n1:'isOption' }),
    A80: new CSXParamSyntax({ n1:'isOption' }),
    A85: new CSXParamSyntax({ n1:'isOption', nn:'isString' }),
    A86: new CSXParamSyntax({ n1:'isOption', nn:'isString' }),
    A87: new CSXParamSyntax({ n1:'isOption', nn:'isString' }),
    A88: new CSXParamSyntax({ n1:'isOption', nn:'isString' }),
    A89: new CSXParamSyntax({ n1:'isOption', nn:'isString' }),
    A90: new CSXParamSyntax({ n1:'isOption', nn:'isString' }),
    A104: new CSXParamSyntax({ n1: 'isOption' }),
    A105: new CSXParamSyntax({ n1: 'isOption' }),
    A119: new CSXParamSyntax({ n1: 'isOption' }),
    A120: new CSXParamSyntax({ n1: 'isOption' }),
    A126: new CSXParamSyntax({ s1: 'isString' }),
    A127: new CSXParamSyntax({ s1: 'isString' }),
    A30: new CSXParamSyntax({ n1: 'isOption' }),
    A31: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    A32: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    A33: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    A34: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    A35: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    A36: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    A37: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    A38: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    A40: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    A41: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    A42: new CSXParamSyntax({ n1: 'isOption', s1: 'isString' }),
    A100: new CSXParamSyntax({ n1:'isOption' }),
    A101: new CSXParamSyntax({ n1:'isOption' }),
    A102: new CSXParamSyntax({ h1:'isRange' }),
    A103: new CSXParamSyntax({ h1:'isRange' }),
    A125: new CSXParamSyntax({ s1:'isString' }),
    A109: new CSXParamSyntax({ n1: 'isOption', b1: 'isOption' }),
    A110: new CSXParamSyntax({ n1: 'isOption', b1: 'isOption' }),
    A111: new CSXParamSyntax({ n1: 'isOption', b1: 'isOption' }),
    A112: new CSXParamSyntax({ n1: 'isOption', b1: 'isOption' }),
    A113: new CSXParamSyntax({ n1: 'isOption', b1: 'isOption' }),
    A114: new CSXParamSyntax({ n1: 'isOption', b1: 'isOption' }),
    A115: new CSXParamSyntax({ n1: 'isOption', h1: 'isRange', h2: 'isRange' }),
    A116: new CSXParamSyntax({ n1: 'isOption', h1: 'isRange', h2: 'isRange' }),
    A117: new CSXParamSyntax({ n1: 'isOption', nn1: 'isString' }),
    A118: new CSXParamSyntax({ n1: 'isOption', nn1: 'isString' }),
    A128: new CSXParamSyntax({ n1: 'isOption', s1: 'isString', n2: 'isRange' }),
    A129: new CSXParamSyntax({ n1: 'isOption', s1: 'isString', n2: 'isRange' }),
    Z1: new CSXParamSyntax({ s1: 'isString' }),
    Z2: new CSXParamSyntax({ s1: 'isString' }),
    Z3: new CSXParamSyntax({ s1: 'isString' }),
    Z4: new CSXParamSyntax({ s1: 'isString' }),
    Z5: new CSXParamSyntax({ s1: 'isString' }),
    Z6: new CSXParamSyntax({ s1: 'isString' }),
    Z7: new CSXParamSyntax({ s1: 'isString' }),
    Z8: new CSXParamSyntax({ s1: 'isString' }),
    Z9: new CSXParamSyntax({ s1: 'isString' }),
    Z10: new CSXParamSyntax({ s1: 'isString' }),
    Z11: new CSXParamSyntax({ s1: 'isString' }),
    Z12: new CSXParamSyntax({ s1: 'isString' }),
    Z13: new CSXParamSyntax({ hn: 'isString' }),
    Z14: new CSXParamSyntax({ hn: 'isString' }),
    Z15: new CSXParamSyntax({ s1: 'isString' }),
    Z16: new CSXParamSyntax({ s1: 'isString' }),
    Z17: new CSXParamSyntax({ s1: 'isString' }),
    Z19: new CSXParamSyntax({ n1: 'isOption' }),
    Z20: new CSXParamSyntax({ n1: 'isOption' }),
    Z21: new CSXParamSyntax({ n1: 'isOption' }),
    P1: new CSXParamSyntax({ b1: 'isOption' }),
    P2: new CSXParamSyntax({ b1: 'isOption' }),
    P3: new CSXParamSyntax({ b1: 'isOption' }),
    P4: new CSXParamSyntax({ b1: 'isOption' }),
    P5: new CSXParamSyntax({ s1: 'isString' }),
    P6: new CSXParamSyntax({ s1: 'isString' }),
    P7: new CSXParamSyntax({ b1: 'isOption' }),
    P8: new CSXParamSyntax({ b1: 'isOption' }),
    P9: new CSXParamSyntax({ s1: 'isOption' }),
    P10: new CSXParamSyntax({ s1: 'isOption' }),
    P11: new CSXParamSyntax({ s1: 'isRange' }),
    P12: new CSXParamSyntax({ s1: 'isRange' }),
    P13: new CSXParamSyntax({ n1: 'isRange' }),
    P14: new CSXParamSyntax({ n1: 'isRange' }),
    P15: new CSXParamSyntax({ n1: 'isOption' }),
    P16: new CSXParamSyntax({ n1: 'isOption' }),
    P17: new CSXParamSyntax({ s1: 'isString' }),
    P18: new CSXParamSyntax({ s1: 'isString' }),
    S8: new CSXParamSyntax({ n1: 'isOption' }),
    S9: new CSXParamSyntax({ n1: 'isOption' }),
    S12: new CSXParamSyntax({ n1: 'isOption' }),
    S13: new CSXParamSyntax({ n1: 'isOption' }),
    S15: new CSXParamSyntax({ n1: 'isRange' }),
    S16: new CSXParamSyntax({ n1: 'isRange' }),
    S38: new CSXParamSyntax({ b1: 'isOption' }),
    S39: new CSXParamSyntax({ b1: 'isOption' }),
    C5: new CSXParamSyntax({ n1: 'isOption' }),
    C6: new CSXParamSyntax({ n1: 'isOption' }),
    S1: new CSXParamSyntax({ s1: 'isString', n1: 'isOption' }),
    S2: new CSXParamSyntax({ s1: 'isString', n1: 'isOption' }),
    S3: new CSXParamSyntax({ s1: 'isString', x1: 'isOption' }),
    S4: new CSXParamSyntax({ s1: 'isString', x1: 'isOption' }),
    S5: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    S6: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    S7: new CSXParamSyntax({n1:'isOption'}),
    S28: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    S29: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    S30: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    S31: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    S40: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    S41: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    C10: new CSXParamSyntax({ s1: 'isString', n1: 'isOption' }),
    C11: new CSXParamSyntax({ s1: 'isString', n1: 'isOption' }),
    C15: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    C16: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    C17: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    C3: new CSXParamSyntax({ 'xn.n': 'isOption', 'xn.x':'isOption'}),
    C4: new CSXParamSyntax({ 'xn.n': 'isOption', 'xn.x':'isOption'}),
    D1: new CSXParamSyntax({ n1: 'isOption' }),
    D2: new CSXParamSyntax({ n1: 'isOption' }),
    D9: new CSXParamSyntax({ n1: 'isOption' }),
    D10: new CSXParamSyntax({ n1: 'isOption' }),
    D11: new CSXParamSyntax({ n1: 'isRange' }),
    D12: new CSXParamSyntax({ n1: 'isRange' }),
    D13: new CSXParamSyntax({ n1: 'isRange' }),
    D14: new CSXParamSyntax({ n1: 'isRange' }),
    D15: new CSXParamSyntax({ n1: 'isRange' }),
    D16: new CSXParamSyntax({ n1: 'isRange' }),
    D17: new CSXParamSyntax({ n1: 'isRange' }),
    D18: new CSXParamSyntax({ n1: 'isRange' }),
    D79: new CSXParamSyntax({ b1: 'isOption' }),
    D80: new CSXParamSyntax({ b1: 'isOption' }),
    D136: new CSXParamSyntax({ n1: 'isOption' }),
    D137: new CSXParamSyntax({ n1: 'isOption' }),
    D142: new CSXParamSyntax({ x1: 'isOption' }),
    D143: new CSXParamSyntax({ x1: 'isOption' }),
    D3: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    D4: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    D6: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    D7: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    D19: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D20: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D21: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    D22: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    D23: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    D24: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    D28: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D29: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D30: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D31: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D32: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D33: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D34: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D35: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D36: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D37: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D38: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D39: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D43: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D44: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D45: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D46: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D47: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D48: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D49: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D50: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D51: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D52: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D53: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D54: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D55: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D56: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D57: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D58: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D83: new CSXParamSyntax({ x1: 'isOption', n1: 'isOption' }),
    D84: new CSXParamSyntax({ x1: 'isOption', n1: 'isOption' }),
    D59: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    D60: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    D61: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    D62: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    D63: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    D64: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    D65: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    D66: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    D68: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    D69: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    D74: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    D75: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    D76: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    D77: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    D81: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    D82: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    D85: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D86: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D87: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D88: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D89: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D90: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    D91: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    D92: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    D93: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    D94: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    D95: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D96: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D97: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D98: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D99: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D100: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D101: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D102: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D103: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D104: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D105: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D106: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D107: new CSXParamSyntax({ n1: 'isOption' }),
    D127: new CSXParamSyntax({ n1: 'isOption' }),
    D108: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    D109: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    D110: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D111: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D112: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D113: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D114: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D115: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D116: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D117: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D118: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D119: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D120: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    D121: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    D123: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    D124: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    D125: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    D126: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    D128: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    D129: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    D130: new CSXParamSyntax({ x1: 'isOption', n1: 'isOption', n2: 'isOption' }),
    D131: new CSXParamSyntax({ x1: 'isOption', n1: 'isOption', n2: 'isOption' }),
    D132: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption', s1: 'isString' }),
    D133: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption', s1: 'isString' }),
    D141: new CSXParamSyntax({ n1: 'isOption' }),
    D144: new CSXParamSyntax({ n1: 'isOption' }),
    D145: new CSXParamSyntax({ n1: 'isOption' }),
    D154: new CSXParamSyntax({ n1: 'isOption' }),
    D165: new CSXParamSyntax({ n1: 'isOption' }),
    D166: new CSXParamSyntax({ b1: 'isOption' }),
    D167: new CSXParamSyntax({ b1: 'isOption' }),
    D168: new CSXParamSyntax({ n1: 'isOption' }),
    D169: new CSXParamSyntax({ n1: 'isOption' }),
    D184: new CSXParamSyntax({ n1: 'isOption' }),
    D185: new CSXParamSyntax({ n1: 'isOption' }),
    D134: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    D135: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    D146: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D147: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D148: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D149: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D150: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D151: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D152: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D153: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D155: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    D156: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    D157: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D158: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D159: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D160: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D161: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D162: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D163: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D164: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D170: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D171: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D172: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D173: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D174: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D175: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D176: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D177: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D178: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D179: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D180: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D181: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    D182: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    D183: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    O1: new CSXParamSyntax({ n1: 'isOption', s1: 'isOption', n2: 'isOption' }),
    O2: new CSXParamSyntax({ n1: 'isOption', s1: 'isOption', n2: 'isOption' }),
    O3: new CSXParamSyntax({ n1: 'isOption', n3: 'isRange', n2: 'isOption' }),
    O4: new CSXParamSyntax({ n1: 'isOption', n3: 'isRange', n2: 'isOption' }),
    O5: new CSXParamSyntax({ n1: 'isOption', n3: 'isRange', n2: 'isOption' }),
    O6: new CSXParamSyntax({ n1: 'isOption', n3: 'isRange', n2: 'isOption' }),
    O25: new CSXParamSyntax({ n1: 'isOption', s1: 'isOption', n2: 'isOption' }),
    O26: new CSXParamSyntax({ n1: 'isOption', s1: 'isOption', n2: 'isOption' }),
    O7: new CSXParamSyntax({ s1: 'isString' }),
    O8: new CSXParamSyntax({ s1: 'isString' }),
    O9: new CSXParamSyntax({ s1: 'isString' }),
    O10: new CSXParamSyntax({ s1: 'isString' }),
    O11: new CSXParamSyntax({ s1: 'isString' }),
    O12: new CSXParamSyntax({ s1: 'isString' }),
    O13: new CSXParamSyntax({ s1: 'isString' }),
    O14: new CSXParamSyntax({ s1: 'isString' }),
    O15: new CSXParamSyntax({ n1: 'isRange' }),
    O16: new CSXParamSyntax({ n1: 'isRange' }),
    O17: new CSXParamSyntax({ n1: 'isRange' }),
    O18: new CSXParamSyntax({ n1: 'isRange' }),
    O19: new CSXParamSyntax({ b1: 'isOption' }),
    O20: new CSXParamSyntax({ b1: 'isOption' }),
    O21: new CSXParamSyntax({ b1: 'isOption' }),
    O22: new CSXParamSyntax({ b1: 'isOption' }),
    O23: new CSXParamSyntax({ b1: 'isOption' }),
    O24: new CSXParamSyntax({ b1: 'isOption' }),
    A122: new CSXParamSyntax({ n1: 'isOption', s1: 'isString' }),
    A123: new CSXParamSyntax({ n1: 'isOption', s1: 'isString' }),
    J1: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    J2: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    J7: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    J8: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    J9: new CSXParamSyntax({ x1: 'isOption', n1: 'isOption' }),
    J10: new CSXParamSyntax({ x1: 'isOption', n1: 'isOption' }),
    H31: new CSXParamSyntax({ b1: 'isOption' }),
    H32: new CSXParamSyntax({ b1: 'isOption' }),
    H43: new CSXParamSyntax({ b1: 'isOption' }),
    H44: new CSXParamSyntax({ b1: 'isOption' }),
    H3: new CSXParamSyntax({ b1: 'isOption' }),
    H8: new CSXParamSyntax({ x1: 'isOption' }),
    H9: new CSXParamSyntax({ x1: 'isOption' }),
    H25: new CSXParamSyntax({ x1: 'isOption' }),
    H26: new CSXParamSyntax({ x1: 'isOption' }),
    H21: new CSXParamSyntax({ x1: 'isOption' }),
    H22: new CSXParamSyntax({ x1: 'isOption' }),
    H1: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    H2: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    H4: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    H5: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    H6: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H7: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H10: new CSXParamSyntax({ s1: 'isString', x1: 'isOption' }),
    H11: new CSXParamSyntax({ s1: 'isString', x1: 'isOption' }),
    H12: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H13: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H19: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    H20: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    H23: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H24: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H27: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    H28: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    H37: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    H38: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    H39: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    H40: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    H45: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    H46: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    H47: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H48: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H49: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H50: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H51: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H52: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H53: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H54: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H55: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H56: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H57: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H58: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H41: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    H42: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    H33: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    H34: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    H35: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H36: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    H14: new CSXParamSyntax({ x1: 'isOption', n1: 'isOption', n2: 'isRange' }),
    H15: new CSXParamSyntax({ x1: 'isOption', n1: 'isOption', n2: 'isRange' }),
    H16: new CSXParamSyntax({ x1: 'isOption', n1: 'isOption' }),
    H17: new CSXParamSyntax({ x1: 'isOption', n1: 'isOption' }),
    H18: new CSXParamSyntax({ x1: 'isOption', n1: 'isOption' }),
    B1: new CSXParamSyntax({ n1: 'isOption', b1: 'isOption', x1: 'isOption' }),
    B2: new CSXParamSyntax({ n1: 'isOption', b1: 'isOption', x1: 'isOption' }),
    B3: new CSXParamSyntax({ n1: 'isOption', s1: 'isString', x1: 'isOption' }),
    B4: new CSXParamSyntax({ n1: 'isOption', s1: 'isString', x1: 'isOption' }),
    B5: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', x1: 'isOption' }),
    B6: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', x1: 'isOption' }),
    B7: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', x1: 'isOption' }),
    B8: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', x1: 'isOption' }),
    B13: new CSXParamSyntax({ n1: 'isOption', nn: 'isString', x1: 'isOption' }),
    B14: new CSXParamSyntax({ n1: 'isOption', nn: 'isString', x1: 'isOption' }),
    B15: new CSXParamSyntax({ n1: 'isOption', b1: 'isOption', x1: 'isOption' }),
    B16: new CSXParamSyntax({ n1: 'isOption', b1: 'isOption', x1: 'isOption' }),
    B17: new CSXParamSyntax({ n1: 'isOption', hh: 'isString', x1: 'isOption' }),
    B18: new CSXParamSyntax({ n1: 'isOption', hh: 'isString', x1: 'isOption' }),
    B19: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', x1: 'isOption' }),
    B20: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', x1: 'isOption' }),
    B27: new CSXParamSyntax({ n1: 'isOption', nn: 'isString', x1: 'isOption' }),
    B28: new CSXParamSyntax({ n1: 'isOption', nn: 'isString', x1: 'isOption' }),
    B29: new CSXParamSyntax({ n1: 'isOption', b1: 'isOption', x1: 'isOption' }),
    B30: new CSXParamSyntax({ n1: 'isOption', b1: 'isOption', x1: 'isOption' }),
    B31: new CSXParamSyntax({ n1: 'isOption', s1: 'isString', x1: 'isOption' }),
    B32: new CSXParamSyntax({ n1: 'isOption', s1: 'isString', x1: 'isOption' }),
    B33: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', x1: 'isOption' }),
    B34: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', x1: 'isOption' }),
    B35: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', x1: 'isOption' }),
    B36: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', x1: 'isOption' }),
    B37: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption', x1: 'isOption' }),
    B38: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption', x1: 'isOption' }),
    B9: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', n3: 'isRange', s1: 'isString' }),
    B10: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', n3: 'isRange', s1: 'isString' }),
    B23: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', n3: 'isRange', s1: 'isString' }),
    B24: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', n3: 'isRange', s1: 'isString' }),
    B39: new CSXParamSyntax({ x1: 'isOption', b1: 'isOption' }),
    B40: new CSXParamSyntax({ x1: 'isOption', b1: 'isOption' }),
    B41: new CSXParamSyntax({ x1: 'isOption', s1: 'isString' }),
    B42: new CSXParamSyntax({ x1: 'isOption', s1: 'isString' }),
    B43: new CSXParamSyntax({ x1: 'isOption', s1: 'isString' }),
    B44: new CSXParamSyntax({ x1: 'isOption', s1: 'isString' }),
    B45: new CSXParamSyntax({ x1: 'isOption', s1: 'isString' }),
    B46: new CSXParamSyntax({ x1: 'isOption', s1: 'isString' }),
    B47: new CSXParamSyntax({ x1: 'isOption', s1: 'isString' }),
    B48: new CSXParamSyntax({ x1: 'isOption', s1: 'isString' }),
    B49: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange' }),
    B50: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange' }),
    B51: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange' }),
    B52: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange' }),
    B60: new CSXParamSyntax({ x1: 'isOption', nn: 'isString' }),
    B61: new CSXParamSyntax({ x1: 'isOption', nn: 'isString' }),
    B53: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    B58: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    B59: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    B54: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption', n2: 'isOption' }),
    B55: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption', n2: 'isOption' }),
    B56: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption', n2: 'isOption' }),
    B57: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption', n2: 'isOption' }),
    B62: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption', n2: 'isOption' }),
    B63: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption', n2: 'isOption' }),
    I1: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    I2: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption' }),
    I4: new CSXParamSyntax({ n1: 'isOption', s1: 'isString' }),
    I5: new CSXParamSyntax({ n1: 'isOption', s1: 'isString' }),
    I6: new CSXParamSyntax({ n1: 'isOption', hh: 'isString' }),
    I7: new CSXParamSyntax({ n1: 'isOption', hh: 'isString' }),
    E1: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    E2: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    I14: new CSXParamSyntax({ n1: 'isOption' }),
    I15: new CSXParamSyntax({ n1: 'isOption' }),
    I17: new CSXParamSyntax({ n1: 'isOption', n2: 'isOption', hh: 'isString' }),
    I18: new CSXParamSyntax({ n1: 'isRange' }),
    I23: new CSXParamSyntax({ n1: 'isOption' }),
    I24: new CSXParamSyntax({ n1: 'isOption', b1: 'isOption' }),
    I25: new CSXParamSyntax({ n1: 'isOption', b1: 'isOption' }),
    E3: new CSXParamSyntax({ n1: 'isOption' }),
    E4: new CSXParamSyntax({ n1: 'isOption' }),
    E5: new CSXParamSyntax({ n1: 'isOption' }),
    E6: new CSXParamSyntax({ n1: 'isOption' }),
    E7: new CSXParamSyntax({ b1: 'isOption' }),
    E8: new CSXParamSyntax({ b1: 'isOption' }),
    E9: new CSXParamSyntax({ n1: 'isRange' }),
    E10: new CSXParamSyntax({ n1: 'isRange' }),
    E12: new CSXParamSyntax({ n1: 'isRange' }),
    E13: new CSXParamSyntax({ n1: 'isRange' }),
    E14: new CSXParamSyntax({ n1: 'isRange' }),
    E15: new CSXParamSyntax({ n1: 'isRange' }),
    E16: new CSXParamSyntax({ n1: 'isRange' }),
    E17: new CSXParamSyntax({ n1: 'isRange' }),
    E18: new CSXParamSyntax({ n1: 'isRange' }),
    E19: new CSXParamSyntax({ n1: 'isRange' }),
    E20: new CSXParamSyntax({ n1: 'isRange' }),
    E21: new CSXParamSyntax({ n1: 'isRange' }),
    E22: new CSXParamSyntax({ n1: 'isRange' }),
    E23: new CSXParamSyntax({ n1: 'isRange' }),
    E24: new CSXParamSyntax({ n1: 'isRange' }),
    E25: new CSXParamSyntax({ n1: 'isRange' }),
    E26: new CSXParamSyntax({ n1: 'isRange' }),
    E27: new CSXParamSyntax({ n1: 'isRange' }),
    E28: new CSXParamSyntax({ n1: 'isRange' }),
    E29: new CSXParamSyntax({ n1: 'isRange' }),
    E30: new CSXParamSyntax({ n1: 'isRange' }),
    E31: new CSXParamSyntax({ n1: 'isRange' }),
    E32: new CSXParamSyntax({ n1: 'isRange' }),
    E33: new CSXParamSyntax({ n1: 'isRange' }),
    E34: new CSXParamSyntax({ n1: 'isRange' }),
    E35: new CSXParamSyntax({ n1: 'isRange' }),
    E36: new CSXParamSyntax({ n1: 'isRange' }),
    E37: new CSXParamSyntax({ n1: 'isRange' }),
    E38: new CSXParamSyntax({ n1: 'isOption' }),
    E39: new CSXParamSyntax({ n1: 'isOption' }),
    E40: new CSXParamSyntax({ b1: 'isOption' }),
    E41: new CSXParamSyntax({ b1: 'isOption' }),
    E58: new CSXParamSyntax({ n1: 'isRange' }),
    E59: new CSXParamSyntax({ n1: 'isRange' }),
    E60: new CSXParamSyntax({ n1: 'isRange' }),
    E61: new CSXParamSyntax({ n1: 'isRange' }),
    E72: new CSXParamSyntax({ b1: 'isOption' }),
    E73: new CSXParamSyntax({ b1: 'isOption' }),
    E74: new CSXParamSyntax({ n1: 'isOption' }),
    E75: new CSXParamSyntax({ n1: 'isOption' }),
    E42: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', n3: 'isRange' }),
    E43: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', n3: 'isRange' }),
    E44: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', n3: 'isRange' }),
    E45: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', n3: 'isRange' }),
    E46: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', n3: 'isRange' }),
    E47: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', n3: 'isRange' }),
    E48: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', n3: 'isRange' }),
    E49: new CSXParamSyntax({ n1: 'isOption', n2: 'isRange', n3: 'isRange' }),
    E50: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    E51: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    E52: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    E53: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    E54: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    E55: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    E56: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    E57: new CSXParamSyntax({ x1: 'isOption', n1: 'isRange', n2: 'isRange' }),
    E62: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    E63: new CSXParamSyntax({ n2: 'isOption', n1: 'isOption' }),
    E64: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    E65: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    E66: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    E67: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    E68: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    E69: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    E70: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    E71: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    G1: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G2: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G3: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    G4: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    G5: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G6: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G7: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G8: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G9: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G10: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G11: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G12: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G13: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G14: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G15: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    G16: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    G17: new CSXParamSyntax({ s1: 'isString', x1: 'isOption' }),
    G18: new CSXParamSyntax({ s1: 'isString', x1: 'isOption' }),
    G19: new CSXParamSyntax({ nn: 'isString', x1: 'isOption' }),
    G20: new CSXParamSyntax({ nn: 'isString', x1: 'isOption' }),
    G21: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    G22: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    G24: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G25: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G29: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    G30: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    G31: new CSXParamSyntax({ s1: 'isOption', x1: 'isOption' }),
    G32: new CSXParamSyntax({ s1: 'isOption', x1: 'isOption' }),
    G34: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    G35: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    G36: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G37: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G38: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G39: new CSXParamSyntax({ n1: 'isRange', x1: 'isOption' }),
    G40: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    G41: new CSXParamSyntax({ n1: 'isOption', x1: 'isOption' }),
    R10: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    R11: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    R12: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    R13: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    R14: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    R15: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    R16: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    R17: new CSXParamSyntax({ n2: 'isRange', n1: 'isOption' }),
    K1: new CSXParamSyntax({ hh: 'isString', x1: 'isOption' }),
    K2: new CSXParamSyntax({ hh: 'isString', x1: 'isOption' }),
    K3: new CSXParamSyntax({ hh: 'isString', n1: 'isOption' }),
    K4: new CSXParamSyntax({ b1: 'isOption', n1: 'isOption' }),
    K5: new CSXParamSyntax({ b1: 'isOption', x1: 'isOption' }),
    N3: new CSXParamSyntax({ n1: 'isOption' }),
    N4: new CSXParamSyntax({ n1: 'isOption' }),
    K9: new CSXParamSyntax({ 'xn.x': 'isOption', 'xn.n': 'isOption' }),
    M102: new CSXParamSyntax({ n1: 'isOption' }),
    M103: new CSXParamSyntax({ n1: 'isOption' }),
    M105: new CSXParamSyntax({ n1: 'isOption' }),
    M106: new CSXParamSyntax({ n1: 'isOption' }),
    M107: new CSXParamSyntax({ s1: 'isString' }),
    M108: new CSXParamSyntax({ s1: 'isString' }),
    M109: new CSXParamSyntax({ n1: 'isRange' }),
    M110: new CSXParamSyntax({ n1: 'isRange' }),
}

class CSXStandardCommand {
    private CMD_VARIABLE_EXPRESSION = '{([^}]*):([^{]*)}';
    private __table: { [version in CsxCommandVersion]: string };
    text(ver?: CsxCommandVersion): string {
        const select_ver: CsxCommandVersion = (ver) ? ver : 'v3_2_default';
        return this.__table[select_ver];
    }
    variables(ver?: CsxCommandVersion): Array<{ match: string, syntax: string, type: CSXParamSyntaxType }> {
        const CMD_VARIABLE_PATTERN = new RegExp(this.CMD_VARIABLE_EXPRESSION);
        const CMD_EXPRESSION_VARIABLE_PATTERN = new RegExp(this.CMD_VARIABLE_EXPRESSION, 'gi');
        const cmd_expression = this.text(ver);
        const match_vars = cmd_expression.match(CMD_EXPRESSION_VARIABLE_PATTERN);
        const result: Array<{ match: string, syntax: string, type: CSXParamSyntaxType }> = [];

        if (match_vars) {
            match_vars.forEach(param_match => {
                const var_describe = param_match.match(CMD_VARIABLE_PATTERN);
                if (var_describe) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [ _, param_syntax, param_type ] = Array.from(var_describe);
                    if (isCSXParamSyntaxType(param_type)) {
                        result.push({ match: param_match, syntax: param_syntax, type: param_type });
                    }
                }
            });
        }
        return result;
    }
    constructor(params: string, ver_overloads?: Array<[ CsxCommandVersion, string ]>) {
        this.__table = {
            v3_2_default: params,
            v3_3: params,
            v3_4: params,
        };
        if (ver_overloads) {
            const overload_ver_set = new Set(ver_overloads.map(overload => overload[0]));
            for (let i = 0; i < ver_overloads.length; i++) {
                /**
                 * Different definition for specific version
                 */
                const [ ver, cmd ] = ver_overloads[i];
                this.__table[ver] = cmd;
                
                /**
                 * Deploy overload to all future versions which are undefined
                 * - (traversal_ver > ver): is future version
                 * - (!overload_ver_set.has(traversal_ver)): overload is undefined
                 */
                for (let j = 0; j < CSX_CMD_VER_LIST.length; j++) {
                    const traversal_ver = CSX_CMD_VER_LIST[j];
                    if (traversal_ver > ver && !overload_ver_set.has(traversal_ver)) {
                        this.__table[traversal_ver] = cmd;
                    }
                }
            }
        }
    }
}

export const CYP_STD_CMD_TABLE: { [field in FeatureSupportType]: { [cmd in CsxUtil.CYP_STD_CMD_TYPE]?: CSXStandardCommand } } = {
    Dante: {
        T1: new CSXStandardCommand('set dante friendly name {s1:isString}'),
        T3: new CSXStandardCommand('set dante rx {n1:isOption} channel name {s1:isString}'),
        T5: new CSXStandardCommand('set dante tx {n1:isOption} channel name {s1:isString}'),
        T7: new CSXStandardCommand('set dante rx {n1:isOption} route {s1:isOption}@{s2:isOption}'),
        T9: new CSXStandardCommand('set dante discover'),
        T11: new CSXStandardCommand('set dante ip mode {s1:isOption}'),
        T17: new CSXStandardCommand('set dante static ipaddr {ip1:isString}'),
        T19: new CSXStandardCommand('set dante static netmask {ip1:isString}'),
        T21: new CSXStandardCommand('set dante static gateway {ip1:isString}'),
        T23: new CSXStandardCommand('set dante factory default {s1:isOption}'),
        T24: new CSXStandardCommand('set dante rx stereo {x1:isOption} {x2:isOption}@{s1:isString}'),
    },
    Time: {
        M102: new CSXStandardCommand('set utc time zone index {n1:isOption}'),
        M105: new CSXStandardCommand('set utc time offset {n1:isOption}'),
        M107: new CSXStandardCommand('set ntp server url {s1:isString}'),
        M109: new CSXStandardCommand('set ntp sync duration {n1:isRange}'),
        M111: new CSXStandardCommand('set ntp sync renew'),
    },
    General: {
        A10: new CSXStandardCommand('set factory default'),
        A11: new CSXStandardCommand('set factory ipconfig default'),
        A12: new CSXStandardCommand('set factory out route default'),
        A15: new CSXStandardCommand('set nickname {s1:isString}'),
        A18: new CSXStandardCommand('set feedback broadcast {b1:isOption}'),
        A20: new CSXStandardCommand('set local echo {b1:isOption}'),
        A23: new CSXStandardCommand('set power {s1:isOption}'),
        A25: new CSXStandardCommand('set system reboot'),
        A26: new CSXStandardCommand('set system led {b1:isOption}'),
        A27: new CSXStandardCommand('set keylock {b1:isOption}'),
        A29: new CSXStandardCommand('set system usb fw update'),
        A43: new CSXStandardCommand('set ip mode {s1:isOption}'),
        A49: new CSXStandardCommand('set static ipaddr {nn:isString}', [
            [ 'v3_3', 'set static ipaddr {ip1:isString}' ]
        ]),
        A51: new CSXStandardCommand('set static netmask {nn:isString}', [
            [ 'v3_3', 'set static netmask {ip1:isString}' ]
        ]),
        A53: new CSXStandardCommand('set static gateway {nn:isString}', [
            [ 'v3_3', 'set static gateway {ip1:isString}' ]
        ]),
        A56: new CSXStandardCommand('set webgui username {s1:isString}'),
        A58: new CSXStandardCommand('set webgui password {s1:isString}'),
        A60: new CSXStandardCommand('set webgui login {b1:isOption}'),
        A62: new CSXStandardCommand('set webgui port {n1:isRange}'),
        A64: new CSXStandardCommand('set telnet username {s1:isString}'),
        A66: new CSXStandardCommand('set telnet password {s1:isString}'),
        A68: new CSXStandardCommand('set telnet port {n1:isRange}'),
        A70: new CSXStandardCommand('set telnet login {b1:isOption}'),
        A73: new CSXStandardCommand('set telnet timeout {n1:isRange}'),
        A75: new CSXStandardCommand('set webgui login timeout {n1:isRange}'),
        A77: new CSXStandardCommand('set lan {n1:isOption} ip mode static'),
        A78: new CSXStandardCommand('set lan {n1:isOption} ip mode dhcp'),
        A79: new CSXStandardCommand('set lan {n1:isOption} ip mode force ip'),
        A85: new CSXStandardCommand('set lan {n1:isOption} static ipaddr {nn:isString}', [
            [ 'v3_3', 'set lan {n1:isOption} static ipaddr {ip1:isString}' ]
        ]),
        A87: new CSXStandardCommand('set lan {n1:isOption} static netmask {nn:isString}', [
            [ 'v3_3', 'set lan {n1:isOption} static netmask {ip1:isString}' ]
        ]),
        A89: new CSXStandardCommand('set lan {n1:isOption} static gateway {nn:isString}', [
            [ 'v3_3', 'set lan {n1:isOption} static gateway {ip1:isString}' ]
        ]),
        A104: new CSXStandardCommand('set fan control mode {n1:isOption}'),
        A119: new CSXStandardCommand('set lcm refresh time {n1:isOption}'),
        A126: new CSXStandardCommand('set hostname {s1:isString}'),
        A128: new CSXStandardCommand('set key {n1:isOption} led color {s1:isString} {n2:isRange}'),
        F3: new CSXStandardCommand('set hello mode {b1:isOption}'),
    },
    IO: {
        A30: new CSXStandardCommand('set uart {n1:isOption} reset'),
        A31: new CSXStandardCommand('set uart {n1:isOption} baudrate {n2:isOption}'),
        A33: new CSXStandardCommand('set uart {n1:isOption} stop bit {n2:isOption}'),
        A35: new CSXStandardCommand('set uart {n1:isOption} data bit {n2:isOption}'),
        A37: new CSXStandardCommand('set uart {n1:isOption} parity {n2:isOption}'),
        A40: new CSXStandardCommand('set uart {n1:isOption} mode {n2:isOption}'),
        A42: new CSXStandardCommand('set uart {n1:isOption} command {s1:isString}'),
        A100: new CSXStandardCommand('set ir in channel {n1:isOption}'),
        A102: new CSXStandardCommand('set ir in custom code {h1:isRange}'),
        A109: new CSXStandardCommand('set usb {n1:isOption} power supply {b1:isOption}'),
        A111: new CSXStandardCommand('set usb {n1:isOption} control mode {b1:isOption}'),
        A113: new CSXStandardCommand('set usb {n1:isOption} virtual hub {b1:isOption}'),
        A115: new CSXStandardCommand('set usb {n1:isOption} register {h1:isRange} {h2:isRange}'),
        A117: new CSXStandardCommand('set usb host {n1:isOption} route {nn1:isString}'),
        A125: new CSXStandardCommand('set factory usb custom logo loading {s1:isString}'),
    },
    Record: {
        P1: new CSXStandardCommand('set record mode {b1:isOption}'),
        P3: new CSXStandardCommand('set record overwrite {b1:isOption}'),
        P5: new CSXStandardCommand('set record media path {s1:isString}'),
        P7: new CSXStandardCommand('set schedule record mode {b1:isOption}'),
        P9: new CSXStandardCommand('set record resolution {s1:isOption}'),
        P11: new CSXStandardCommand('set record bitrate {s1:isRange}'),
        P13: new CSXStandardCommand('set record framerate {n1:isRange}'),
        P15: new CSXStandardCommand('set record profile path {n1:isOption}'),
        P17: new CSXStandardCommand('set snapshot {s1:isString}'),
    },
    Routing: {
        S1: new CSXStandardCommand('set in {n1:isOption} name {s1:isString}'),
        S3: new CSXStandardCommand('set out {x1:isOption} name {s1:isString}'),
        S5: new CSXStandardCommand('set out {x1:isOption} route {n1:isOption}'),
        S7: new CSXStandardCommand('set current route to preset {n1:isOption}'),
        S8: new CSXStandardCommand('set route preset {n1:isOption}'),
        S12: new CSXStandardCommand('set out auto mode {n1:isOption}'),
        S15: new CSXStandardCommand('set out auto switch sync debounce {n1:isRange}'),
        S28: new CSXStandardCommand('set out {x1:isOption} deep color {n1:isOption}'),
        S30: new CSXStandardCommand('set out {x1:isOption} color space {n1:isOption}'),
        S38: new CSXStandardCommand('set usb host auto mode {b1:isOption}'),
        S40: new CSXStandardCommand('set usbc {n1:isOption} 3.0 mode {b1:isOption}'),
        C3: new CSXStandardCommand('set out route {xn.x:isOption}{xn.n:isOption}'),
        C5: new CSXStandardCommand('set all out route {n1:isOption}'),
        C10: new CSXStandardCommand('set route preset {n1:isOption} name {s1:isString}'),
        C15: new CSXStandardCommand('set out {x1:isOption} mask {b1:isOption}'),
        C17: new CSXStandardCommand('set out {x1:isOption} ibc {b1:isOption}'),
    },
    Scaler: {
        D1: new CSXStandardCommand('set window layout mode {n1:isOption}'),
        D3: new CSXStandardCommand('set out {x1:isOption} timing {n1:isOption}'),
        D6: new CSXStandardCommand('set out {x1:isOption} uni timing {n1:isOption}'),
        D9: new CSXStandardCommand('set display mode {n1:isOption}'),
        D11: new CSXStandardCommand('set pip hsize {n1:isRange}'),
        D13: new CSXStandardCommand('set pip vertical size {n1:isRange}'),
        D15: new CSXStandardCommand('set pip horizontal position {n1:isRange}'),
        D17: new CSXStandardCommand('set pip vertical position {n1:isRange}'),
        D19: new CSXStandardCommand('set window {n1:isOption} border size {n2:isRange}'),
        D21: new CSXStandardCommand('set window {n1:isOption} route {n2:isOption}'),
        D23: new CSXStandardCommand('set window {n1:isOption} border color {n2:isOption}'),
        D28: new CSXStandardCommand('set out {x1:isOption} contrast {n1:isRange}'),
        D30: new CSXStandardCommand('set out {x1:isOption} brightness {n1:isRange}'),
        D32: new CSXStandardCommand('set out {x1:isOption} saturation {n1:isRange}'),
        D34: new CSXStandardCommand('set out {x1:isOption} hue {n1:isRange}'),
        D36: new CSXStandardCommand('set out {x1:isOption} sharpness {n1:isRange}'),
        D38: new CSXStandardCommand('set out {x1:isOption} nr {n1:isRange}'),
        D43: new CSXStandardCommand('set out {x1:isOption} auto sync off {n1:isRange}'),
        D45: new CSXStandardCommand('set out {x1:isOption} r gain {n1:isRange}'),
        D47: new CSXStandardCommand('set out {x1:isOption} g gain {n1:isRange}'),
        D49: new CSXStandardCommand('set out {x1:isOption} b gain {n1:isRange}'),
        D51: new CSXStandardCommand('set in {n1:isOption} phase {n2:isRange}'),
        D53: new CSXStandardCommand('set in {n1:isOption} clock {n2:isRange}'),
        D55: new CSXStandardCommand('set in {n1:isOption} hposition {n2:isRange}'),
        D57: new CSXStandardCommand('set in {n1:isOption} vposition {n2:isRange}'),
        D59: new CSXStandardCommand('set out {x1:isOption} window {n1:isRange} contrast {n2:isRange}'),
        D61: new CSXStandardCommand('set out {x1:isOption} window {n1:isRange} brightness {n2:isRange}'),
        D63: new CSXStandardCommand('set out {x1:isOption} window {n1:isRange} saturation {n2:isRange}'),
        D65: new CSXStandardCommand('set out {x1:isOption} window {n1:isRange} hue {n2:isRange}'),
        D68: new CSXStandardCommand('set in {n1:isOption} correct timing {n2:isOption}'),
        D74: new CSXStandardCommand('set out {x1:isOption} 4k2k downscale mode {n1:isOption}'),
        D76: new CSXStandardCommand('set out {x1:isOption} 4k2k upscale mode {n1:isOption}'),
        D78: new CSXStandardCommand('set factory vga default'),
        D79: new CSXStandardCommand('set vga auto ajust mode {b1:isOption}'),
        D81: new CSXStandardCommand('set out {x1:isOption} video mode {n1:isOption}'),
        D83: new CSXStandardCommand('set audio out {x1:isOption} mode {n1:isOption}'),
        D85: new CSXStandardCommand('set out {x1:isOption} r offset {n1:isRange}'),
        D87: new CSXStandardCommand('set out {x1:isOption} g offset {n1:isRange}'),
        D89: new CSXStandardCommand('set out {x1:isOption} b offset {n1:isRange}'),
        D91: new CSXStandardCommand('set out {x1:isOption} freeze {b1:isOption}'),
        D93: new CSXStandardCommand('set out {x1:isOption} blank {b1:isOption}'),
        D95: new CSXStandardCommand('set in {n1:isOption} contrast {n2:isRange}'),
        D97: new CSXStandardCommand('set in {n1:isOption} brightness {n2:isRange}'),
        D99: new CSXStandardCommand('set in {n1:isOption} saturation {n2:isRange}'),
        D101: new CSXStandardCommand('set in {n1:isOption} hue {n2:isRange}'),
        D103: new CSXStandardCommand('set in {n1:isOption} h sharpness {n2:isRange}'),
        D105: new CSXStandardCommand('set in {n1:isOption} v sharpness {n2:isRange}'),
        D107: new CSXStandardCommand('set in {n1:isOption} picture default'),
        D108: new CSXStandardCommand('set window {n1:isOption} mute {b1:isOption}'),
        D110: new CSXStandardCommand('set window {n1:isOption} hposition {n2:isRange}'),
        D112: new CSXStandardCommand('set window {n1:isOption} vposition {n2:isRange}'),
        D114: new CSXStandardCommand('set window {n1:isOption} hsize {n2:isRange}'),
        D116: new CSXStandardCommand('set window {n1:isOption} vsize {n2:isRange}'),
        D118: new CSXStandardCommand('set window {n1:isOption} priority {n2:isRange}'),
        D120: new CSXStandardCommand('set window {n1:isOption} aspect ratio {n2:isOption}'),
        D123: new CSXStandardCommand('set window {n1:isOption} mirror {b1:isOption}'),
        D125: new CSXStandardCommand('set window {n1:isOption} border mode {b1:isOption}'),
        D127: new CSXStandardCommand('set window {n1:isOption} default'),
        D128: new CSXStandardCommand('set out {x1:isOption} window layout mode {n1:isOption}'),
        D130: new CSXStandardCommand('set out {x1:isOption} window {n1:isOption} route {n2:isOption}'),
        D132: new CSXStandardCommand('set group {n1:isOption} in {n2:isOption} name {s1:isString}'),
        D134: new CSXStandardCommand('set group {n1:isOption} route {n2:isOption}'),
        D136: new CSXStandardCommand('set all group route {n1:isOption}'),
        D141: new CSXStandardCommand('set group {n1:isOption} default'),
        D142: new CSXStandardCommand('set ToIP route {x1:isOption}'),
        D144: new CSXStandardCommand('set cropping in {n1:isOption} mode'),
        D146: new CSXStandardCommand('set cropping in {n1:isOption} h start {n2:isRange}'),
        D148: new CSXStandardCommand('set cropping in {n1:isOption} v start {n2:isRange}'),
        D150: new CSXStandardCommand('set cropping in {n1:isOption} width {n2:isRange}'),
        D152: new CSXStandardCommand('set cropping in {n1:isOption} height {n2:isRange}'),
        D154: new CSXStandardCommand('set cropping in {n1:isOption} default'),
        D155: new CSXStandardCommand('set cropping window {n1:isOption} mode {b1:isOption}'),
        D157: new CSXStandardCommand('set cropping window {n1:isOption} h start {n2:isRange}'),
        D159: new CSXStandardCommand('set cropping window {n1:isOption} v start {n2:isRange}'),
        D161: new CSXStandardCommand('set cropping window {n1:isOption} width {n2:isRange}'),
        D163: new CSXStandardCommand('set cropping window {n1:isOption} height {n2:isRange}'),
        D165: new CSXStandardCommand('set cropping window {n1:isOption} default'),
        D166: new CSXStandardCommand('set chroma key mode {b1:isOption}'),
        D168: new CSXStandardCommand('set chroma key rgb codes {n1:isOption}'),
        D170: new CSXStandardCommand('set chroma key user {n1:isOption} r max {n2:isRange}'),
        D172: new CSXStandardCommand('set chroma key user {n1:isOption} r min {n2:isRange}'),
        D174: new CSXStandardCommand('set chroma key user {n1:isOption} g max {n2:isRange}'),
        D176: new CSXStandardCommand('set chroma key user {n1:isOption} g min {n2:isRange}'),
        D178: new CSXStandardCommand('set chroma key user {n1:isOption} b max {n2:isRange}'),
        D180: new CSXStandardCommand('set chroma key user {n1:isOption} b min {n2:isRange}'),
        D182: new CSXStandardCommand('set in {n1:isOption} rotation mode {b1:isOption}'),
        D184: new CSXStandardCommand('set transition mode {n1:isOption}'),
    },
    Streaming: {
        O1: new CSXStandardCommand('set encode {n1:isOption} profile {n2:isOption} resolution {s1:isOption}'),
        O3: new CSXStandardCommand('set encode {n1:isOption} profile {n2:isOption} bitrate {n3:isRange}'),
        O5: new CSXStandardCommand('set encode {n1:isOption} profile {n2:isOption} framerate {n3:isRange}'),
        O7: new CSXStandardCommand('set live mode path {s1:isString}'),
        O9: new CSXStandardCommand('set live stream url {s1:isString}'),
        O11: new CSXStandardCommand('set live stream key {s1:isString}'),
        O13: new CSXStandardCommand('set live encode resolution {s1:isString}'),
        O15: new CSXStandardCommand('set live encode bitrate {n1:isRange}'),
        O17: new CSXStandardCommand('set live encode framerate {n1:isRange}'),
        O19: new CSXStandardCommand('set onvif mode {b1:isOption}'),
        O21: new CSXStandardCommand('set onvif auth {b1:isOption}'),
        O23: new CSXStandardCommand('set live stream mode {b1:isOption}'),
        O25: new CSXStandardCommand('set encode {n1:isOption} profile {n2:isOption} video coding {s1:isOption}'),
    },
    FWUpdate: {
        A122: new CSXStandardCommand('set fw {n1:isOption} loading {s1:isString}'),
    },
    HDCP: {
        J1: new CSXStandardCommand('set in {n1:isOption} hdcp mode {n2:isOption}'),
        J7: new CSXStandardCommand('set in {n1:isOption} multiview hdcp mode {n2:isOption}'),
        J9: new CSXStandardCommand('set out {x1:isOption} hdcp mute mode {n1:isOption}'),
    },
    Audio: {
        H1: new CSXStandardCommand('set audio out {x1:isOption} mute {b1:isOption}'),
        H3: new CSXStandardCommand('set audio out all mute {b1:isOption}'),
        H4: new CSXStandardCommand('set audio out {x1:isOption} route {n1:isOption}'),
        H6: new CSXStandardCommand('set audio out {x1:isOption} volume {n1:isRange}'),
        H8: new CSXStandardCommand('set audio out {x1:isOption} volume up'),
        H9: new CSXStandardCommand('set audio out {x1:isOption} volume down'),
        H10: new CSXStandardCommand('set audio out {x1:isOption} name {s1:isString}'),
        H12: new CSXStandardCommand('set audio out {x1:isOption} delay {n1:isRange}'),
        H14: new CSXStandardCommand('set audio out {x1:isOption} eq {n1:isOption} value {n2:isRange}'),
        H16: new CSXStandardCommand('set audio out {x1:isOption} eq {n1:isOption} value up'),
        H17: new CSXStandardCommand('set audio out {x1:isOption} eq {n1:isOption} value down'),
        H19: new CSXStandardCommand('set audio out {x1:isOption} treble value {n1:isOption}'),
        H21: new CSXStandardCommand('set audio out {x1:isOption} treble value up'),
        H22: new CSXStandardCommand('set audio out {x1:isOption} treble value down'),
        H23: new CSXStandardCommand('set audio out {x1:isOption} bass value {n1:isRange}'),
        H25: new CSXStandardCommand('set audio out {x1:isOption} bass value up'),
        H26: new CSXStandardCommand('set audio out {x1:isOption} bass value down'),
        H27: new CSXStandardCommand('set audio in {n1:isOption} pre-gain {n2:isRange}'),
        H31: new CSXStandardCommand('set audio mixer {b1:isOption}'),
        H33: new CSXStandardCommand('set audio mixer in {n1:isOption} volume {n2:isRange}'),
        H35: new CSXStandardCommand('set audio mixer out {x1:isOption} volume {n1:isRange}'),
        H37: new CSXStandardCommand('set audio in {n1:isOption} mute {b1:isOption}'),
        H39: new CSXStandardCommand('set audio in {n1:isOption} power mode {n2:isOption}'),
        H41: new CSXStandardCommand('set audio mixer out {x1:isOption} source {n1:isOption}'),
        H43: new CSXStandardCommand('set audio volume knob lock {b1:isOption}'),
        H45: new CSXStandardCommand('set audio out {x1:isOption} talkover {b1:isOption}'),
        H47: new CSXStandardCommand('set audio out {x1:isOption} talkover trigger time {n1:isRange}'),
        H49: new CSXStandardCommand('set audio out {x1:isOption} talkover attack time {n1:isRange}'),
        H51: new CSXStandardCommand('set audio out {x1:isOption} talkover hold time {n1:isRange}'),
        H53: new CSXStandardCommand('set audio out {x1:isOption} talkover release time {n1:isRange}'),
        H55: new CSXStandardCommand('set audio out {x1:isOption} talkover depth {n1:isRange}'),
        H57: new CSXStandardCommand('set audio out {x1:isOption} talkover threshold {n1:isRange}'),
    },
    Automation: {
        B1: new CSXStandardCommand('set automation event {n1:isOption} uart {x1:isOption} {b1:isOption}'),
        B3: new CSXStandardCommand('set automation event {n1:isOption} uart {x1:isOption} command {s1:isString}'),
        B5: new CSXStandardCommand('set automation event {n1:isOption} uart {x1:isOption} delay {n2:isRange}'),
        B7: new CSXStandardCommand('set automation event {n1:isOption} uart {x1:isOption} wait {n2:isRange}'),
        B9: new CSXStandardCommand('set automation multi command {n1:isOption} delay {n2:isRange} wait {n3:isRange} {s1:isString}', [
            [ 'v3_4', 'set automation multi uart command {n1:isOption} delay {n2:isRange} wait {n3:isRange} {s1:isString}' ]
        ]),
        B13: new CSXStandardCommand('set automation event {n1:isOption} uart {x1:isOption} multi command {nn:isString}'),
        B15: new CSXStandardCommand('set automation event {n1:isOption} cec {x1:isOption} {b1:isOption}'),
        B17: new CSXStandardCommand('set automation event {n1:isOption} cec {x1:isOption} command {hh:isString}'),
        B19: new CSXStandardCommand('set automation event {n1:isOption} cec {x1:isOption} delay {n2:isRange}'),
        B23: new CSXStandardCommand('set automation multi command {n1:isOption} delay {n2:isRange} wait {n3:isRange} {s1:isString}', [
            [ 'v3_4', 'set automation multi cec command {n1:isOption} delay {n2:isRange} wait {n3:isRange} {s1:isString}' ]
        ]),
        B27: new CSXStandardCommand('set automation event {n1:isOption} cec {x1:isOption} multi command {nn:isString}'),
        B29: new CSXStandardCommand('set automation event {n1:isOption} telnet {x1:isOption} {b1:isOption}'),
        B31: new CSXStandardCommand('set automation event {n1:isOption} telnet {x1:isOption} command {s1:isString}'),
        B33: new CSXStandardCommand('set automation event {n1:isOption} telnet {x1:isOption} delay {n2:isRange}'),
        B35: new CSXStandardCommand('set automation event {n1:isOption} telnet {x1:isOption} wait {n2:isRange}'),
        B37: new CSXStandardCommand('set automation event {n1:isOption} telnet {x1:isOption} format {n2:isOption}'),
        B39: new CSXStandardCommand('set automation telnet {x1:isOption} login {b1:isOption}'),
        B41: new CSXStandardCommand('set automation telnet {x1:isOption} username {s1:isString}'),
        B43: new CSXStandardCommand('set automation telnet {x1:isOption} password {s1:isString}'),
        B45: new CSXStandardCommand('set automation telnet {x1:isOption} username prompt {s1:isString}'),
        B47: new CSXStandardCommand('set automation telnet {x1:isOption} password prompt {s1:isString}'),
        B49: new CSXStandardCommand('set automation telnet {x1:isOption} port {n1:isRange}'),
        B51: new CSXStandardCommand('set automation telnet {x1:isOption} timeout {n1:isRange}'),
        B53: new CSXStandardCommand('set automation event {n1:isOption} telnet {x1:isOption} test command'),
        B54: new CSXStandardCommand('set automation event {n1:isOption} uart {x1:isOption} endchar {n2:isOption}'),
        B56: new CSXStandardCommand('set automation event {n1:isOption} uart {x1:isOption} format {n2:isOption}'),
        B58: new CSXStandardCommand('set automation event {n1:isOption} uart {x1:isOption} test command'),
        B59: new CSXStandardCommand('set automation event {n1:isOption} cec {x1:isOption} test command'),
        B60: new CSXStandardCommand('set automation telnet {x1:isOption} remote ipaddr {nn:isString}'),
        B62: new CSXStandardCommand('set automation event {n1:isOption} telnet {x1:isOption} endchar {n2:isOption}'),
    },
    EDID: {
        I1: new CSXStandardCommand('set in {n1:isOption} edid {n2:isOption}'),
        I4: new CSXStandardCommand('set edid {n1:isOption} name {s1:isString}'),
        I6: new CSXStandardCommand('set user {n1:isOption} edid data {hh:isString}'),
        I14: new CSXStandardCommand('set all in edid {n1:isOption}'),
        I17: new CSXStandardCommand('set user {n1:isOption} edid segment {n2:isOption} data {hh:isString}'),
        I22: new CSXStandardCommand('set factory user edid default'),
        I23: new CSXStandardCommand('set user {n1:isOption} edid update'),
        I24: new CSXStandardCommand('set in edid merge {b1:isOption}'),
    },
    VideoWall: {
        E1: new CSXStandardCommand('set video wall layout {n1:isOption} {n2:isOption}'),
        E3: new CSXStandardCommand('set video wall h bezel {n1:isOption}'),
        E5: new CSXStandardCommand('set video wall v bezel {n1:isOption}'),
        E7: new CSXStandardCommand('set video wall bezel mode {b1:isOption}'),
        E9: new CSXStandardCommand('set video wall unit index {n1:isRange}'),
        E11: new CSXStandardCommand('set video wall default'),
        E12: new CSXStandardCommand('set video wall preset {n1:isRange}'),
        E14: new CSXStandardCommand('set video wall in h start {n1:isRange}'),
        E16: new CSXStandardCommand('set video wall in v start {n1:isRange}'),
        E18: new CSXStandardCommand('set video wall in h size {n1:isRange}'),
        E20: new CSXStandardCommand('set video wall in v size {n1:isRange}'),
        E22: new CSXStandardCommand('set video wall out h start {n1:isRange}'),
        E24: new CSXStandardCommand('set video wall out v start {n1:isRange}'),
        E26: new CSXStandardCommand('set video wall out h size {n1:isRange}'),
        E28: new CSXStandardCommand('set video wall out v size {n1:isRange}'),
        E30: new CSXStandardCommand('set video wall top bezel {n1:isRange}'),
        E32: new CSXStandardCommand('set video wall bottom bezel {n1:isRange}'),
        E34: new CSXStandardCommand('set video wall left bezel {n1:isRange}'),
        E36: new CSXStandardCommand('set video wall right bezel {n1:isRange}'),
        E38: new CSXStandardCommand('set device operation mode {n1:isOption}'),
        E40: new CSXStandardCommand('set warping mode {b1:isOption}'),
        E42: new CSXStandardCommand('set warping in {n1:isOption} top left corner {n2:isRange} {n3:isRange}'),
        E44: new CSXStandardCommand('set warping in {n1:isOption} top right corner {n2:isRange} {n3:isRange}'),
        E46: new CSXStandardCommand('set warping in {n1:isOption} bottom left corner {n2:isRange} {n3:isRange}'),
        E48: new CSXStandardCommand('set warping in {n1:isOption} bottom right corner {n2:isRange} {n3:isRange}'),
        E50: new CSXStandardCommand('set warping out {x1:isOption} top left corner {n1:isRange} {n2:isRange}'),
        E52: new CSXStandardCommand('set warping out {x1:isOption} top right corner {n1:isRange} {n2:isRange}'),
        E54: new CSXStandardCommand('set warping out {x1:isOption} bottom left corner {n1:isRange} {n2:isRange}'),
        E56: new CSXStandardCommand('set warping out {x1:isOption} bottom right corner {n1:isRange} {n2:isRange}'),
        E58: new CSXStandardCommand('set warping angle {n1:isRange}'),
        E60: new CSXStandardCommand('set rotate angle {n1:isRange}'),
        E62: new CSXStandardCommand('set continue rotate mode {n1:isOption} {n2:isOption}'),
        E64: new CSXStandardCommand('set warping in {n1:isOption} v start {n2:isRange}'),
        E66: new CSXStandardCommand('set warping in {n1:isOption} h start {n2:isRange}'),
        E68: new CSXStandardCommand('set warping in {n1:isOption} width {n2:isRange}'),
        E70: new CSXStandardCommand('set warping in {n1:isOption} height {n2:isRange}'),
        E72: new CSXStandardCommand('set warping keep aspect ratio {b1:isOption}'),
        E74: new CSXStandardCommand('set warping layout mode {n1:isOption}'),
    },
    OSD: {
        G1: new CSXStandardCommand('set out {x1:isOption} osd timeout {n1:isRange}'),
        G3: new CSXStandardCommand('set out {x1:isOption} osd info display {b1:isOption}'),
        G5: new CSXStandardCommand('set out {x1:isOption} osd info timeout {n1:isRange}'),
        G7: new CSXStandardCommand('set out {x1:isOption} osd vposition {n1:isRange}'),
        G9: new CSXStandardCommand('set out {x1:isOption} osd hposition {n1:isRange}'),
        G11: new CSXStandardCommand('set out {x1:isOption} osd transparency {n1:isRange}'),
        G13: new CSXStandardCommand('set out {x1:isOption} osd transparency level {n1:isRange}'),
        G15: new CSXStandardCommand('set out {x1:isOption} osd {b1:isOption}'),
        G17: new CSXStandardCommand('set out {x1:isOption} banner text {s1:isString}'),
        G19: new CSXStandardCommand('set out {x1:isOption} banner source select {nn:isString}'),
        G21: new CSXStandardCommand('set out {x1:isOption} osd banner location {n1:isOption}'),
        G24: new CSXStandardCommand('set out {x1:isOption} banner font size {n1:isRange}'),
        G29: new CSXStandardCommand('set out {x1:isOption} banner font transparency level {n1:isOption}'),
        G31: new CSXStandardCommand('set out {x1:isOption} osd background color {s1:isOption}'),
        G34: new CSXStandardCommand('set out {x1:isOption} osd logo display {b1:isOption}'),
        G36: new CSXStandardCommand('set out {x1:isOption} osd logo hposition {n1:isRange}'),
        G38: new CSXStandardCommand('set out {x1:isOption} osd logo vposition {n1:isRange}'),
        G40: new CSXStandardCommand('set out {x1:isOption} countdown timer {n1:isOption}'),
        G42: new CSXStandardCommand('set out osd logo default'),
        G43: new CSXStandardCommand('set system usb osd logo update'),
    },
    PowerMonitor: {
        R10: new CSXStandardCommand('set relay {n1:isOption} test mode {b1:isOption}'),
        R12: new CSXStandardCommand('set relay {n1:isOption} test time {n2:isRange}'),
        R14: new CSXStandardCommand('set relay {n1:isOption} test open time {n2:isRange}'),
        R16: new CSXStandardCommand('set relay {n1:isOption} test close time {n2:isRange}'),
    },
    CEC: {
        K1: new CSXStandardCommand('set out {x1:isOption} cec data {hh:isString}'),
        K2: new CSXStandardCommand('set in {n1:isOption} cec data {hh:isString}'),
        K3: new CSXStandardCommand('set out {x1:isOption} cec menu {b1:isOption}'),
        K4: new CSXStandardCommand('set in {n1:isOption} cec menu {b1:isOption}'),
        K9: new CSXStandardCommand('set out cec path {xn.x:isOption}{xn.n:isOption}'),
    },
    Debug: {

    },
    CableTest: {
        N3: new CSXStandardCommand('set in {n1:isOption} cable test reset'),
    }
}
// {b1:isOption}
// {s1:isString}
// {n1:Range}

export class CsxFeatureDevice {
    protected permission: CsxUserPermissionLevel;
    protected clone: CsxUtil.CSX_STD_STA | undefined;
    protected buffer: CsxUtil.CSX_STD_STA;
    public readonly commandVersion: { major: number; minor: number; str: CsxCommandVersion };
    protected readonly instance: CsxDevice; // just reference, so that we don't need to copy again when new status coming
    public readonly isSupport = (key_list: Array<string>): boolean => (key_list.filter(key => (CsxUtil.isCypStdCmdType(key) ? !this.instance.ATTRS.has(key) : true)).length === 0)
    public readonly inAllIndex = (key_list: Array<string>): boolean => ((typeof this.status.A17 === 'string') ? (key_list.filter(key => this.status.A17.indexOf(key) < 0).length === 0) : false)
    public readonly hasFeature = (f: FeatureSupportType): boolean => {
        // if (window.APP_DEV_MODE) { return true; }
        const cmd_ver_mapping: { [s in CsxCommandVersion]: Array<Array<CsxUtil.CYP_STD_CMD_TYPE>> | null } = {
            v3_2_default: null,
            v3_3: null,
            v3_4: null
        };
        if (f === 'FWUpdate') {
            cmd_ver_mapping.v3_2_default = [['A1']];
        } else if (f === 'Routing') {
            cmd_ver_mapping.v3_2_default = [['S6']];
        } else if (f === 'EDID') {
            cmd_ver_mapping.v3_2_default = [['I2']];
        } else if (f === 'HDCP') {
            cmd_ver_mapping.v3_2_default = [['J2']];
        } else if (f === 'General') {
            cmd_ver_mapping.v3_2_default = [
                // reboot   Power  nickname  keylock fan speed  fan control
                ['A25'], ['A24'], ['A16'], ['A28'], ['A106'], ['A105'],
                ['A80', 'A82', 'A83', 'A84'],   // multi lan
                ['A47', 'A44', 'A46', 'A48'],  // single lan
            ];
        } else if (f === 'Audio') {
            cmd_ver_mapping.v3_2_default = [['H2'], ['H7']];
        } else if (f === 'Scaler') {
            cmd_ver_mapping.v3_2_default = [['D4']];
        } else if (f === 'IO') {
            cmd_ver_mapping.v3_2_default = [
                // uart: reset    mode      configuration
                ['A30'], ['A41'], ['A32', 'A34', 'A36', 'A38'],
                // ir: in channel  in custom code
                ['A101'], ['A103'],
                // usb:  ps    control mode  vhub     host route
                ['A109'], ['A112'], ['A114'], ['A118'],
            ];
        } else if (f === 'VideoWall') {
            cmd_ver_mapping.v3_2_default = [
                // layout
                ['E2'],
                // warping
                ['E39'],
            ];
        } else if (f === 'OSD') {
            cmd_ver_mapping.v3_2_default = [['G2']];
        } else if (f === 'CableTest') {
            cmd_ver_mapping.v3_2_default = [['N9'], ['N10']];
        } else if (f === 'Automation') {
            cmd_ver_mapping.v3_2_default = [['B2']];
            // return true; // We don't support this feature now
            // return false; // We don't support this feature now
        } else if (f === 'Streaming') {
            cmd_ver_mapping.v3_2_default = [['O2', 'O4', 'O6']];
        } else if (f === 'Record') {
            cmd_ver_mapping.v3_2_default = [['N2']];
        } else if (f === 'CEC') {
            cmd_ver_mapping.v3_2_default = [['K5']];
        } else if (f === 'PowerMonitor') {
            cmd_ver_mapping.v3_2_default = [['R1']];
        } else if (f === 'Time') {
            cmd_ver_mapping.v3_2_default = [
                ['M103'], // timezone
                ['M106'], // time offset
                ['M108'], // NTP time server
                ['M110'], // NTP sync duration
            ];
        } else if (f === 'Dante') {
            cmd_ver_mapping.v3_4 = [
                ['T2'], // dante name
                // ['T8'], // rx route
                // ['T23'], // rx stereo route
                // ['T12', 'T14', 'T15', 'T16'], // ipconfig
                // ['T18', 'T20', 'T22', 'T12'], // static ipconfig
                // ['T23'], // dante factory default
            ]
            // return true;
            // return false;
        } else if (f === 'Debug') {
            return true;
        }
        let checkList = cmd_ver_mapping[this.commandVersion.str];
        checkList = (checkList == null) ? cmd_ver_mapping.v3_2_default : checkList;
        if (checkList) {
            return checkList.map(checkset => ((f === 'FWUpdate')?this.inAllIndex(checkset):this.isSupport(checkset))).filter(supported => supported).length > 0;
        } else {
            return false;
        }
    }
    protected readonly handleRequest = (msg: string, checkPermission?: CsxUserPermissionLevel) => {
        let error_msg = 'Connection abnormal';
        let valid = false;
        if (window.FOCUS_GATEWAY) {
            if (checkPermission) {
                valid = csxUserPermissionSatisfy(this.permission, checkPermission);
            } else {
                valid = csxUserPermissionSatisfy(this.permission, CsxUserPermissionLevel.EditOnly);
            }
            error_msg = (valid) ? '' : getText('NOTIFY_MSG_UNAUTHORIZED');
            if (valid) {
                window.FOCUS_GATEWAY.handleRequest(msg);
            }
        }

        if (!valid) {
            Notify({ title: getText('NOTIFY_TITLE_ERROR'), context: error_msg, type: 'error' });
        }
    }
    protected readonly trigger = (keypath: string) => {
        if (window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID)
                this.handleRequest(`?201:${keypath}\n`);
            else
                this.handleRequest(`?101:${this.instance.ID},${keypath}\n`);
        }
    }
    protected readonly setStatus = (keypath: string, value: stringable, disableUpload?: boolean) => {
        const keypath_walk = keypath.split('.');
        const keycode = keypath_walk[0];
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                if (!csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('system_permission'), CsxUserPermissionLevel.EditOnly)) {
                    Notify({ title: getText('NOTIFY_TITLE_ERROR'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' });
                    return;
                }
            } else {
                if (!csxUserPermissionSatisfy(this.permission, CsxUserPermissionLevel.EditOnly)) {
                    Notify({ title: getText('NOTIFY_TITLE_ERROR'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' });
                    return;
                }
            }
        }
        if (CsxUtil.isCypStdCmdType(keycode)) {
            let keycode_set = (keycode in CMD_GET_TO_SET) ? CMD_GET_TO_SET[keycode] : keycode;
            if (keycode === 'A80') { // command like a shit, one get must mapping to three set
                delete this.buffer['A77'];
                delete this.buffer['A78'];
                delete this.buffer['A79'];
                if (value === 'static') { keycode_set = 'A77'; }
                if (value === 'dhcp') { keycode_set = 'A78'; }
                if (value === 'force ip') { keycode_set = 'A79'; }
            }
            if (keycode_set) {
                const keypath_set = keycode_set + ((keypath_walk.length > 1) ? `.${keypath_walk.slice(1).join('.')}` : '');
                if (disableUpload) {
                    if (!this.clone) this.clone = CsxUtil.nestedClone(this.status);
                    CsxUtil.nestedSet(this.clone, keypath, value.toString());
                    CsxUtil.nestedSet(this.buffer, keypath_set, value.toString());
                    if (window.FOCUS_GATEWAY && this.instance.ID === window.FOCUS_GATEWAY.ID) {
                        irqRefresh(['GatewayRealTime']);
                    } else {
                        irqRefresh(['DeviceRealTime', 'GatewayRealTime']);
                    }
                } else {
                    CsxUtil.nestedSet(this.status, keypath, value.toString()); // make UI updated real-time
                    // console.log(`${keypath}=${value}`);
                    if (window.FOCUS_GATEWAY) {
                        const request = (window.FOCUS_GATEWAY.ID === this.instance.ID) ? `?201:${keypath_set}=${value}\n` : `?101:${this.instance.ID},${keypath_set}=${value}\n`;
                        this.handleRequest(request);
                        irqRefresh(['DeviceRealTime']);
                    }
                }
            }
        }
    }
    protected readonly getStatus = (keypath: string, default_ret?: string) => {
        const tar: CsxUtil.CSX_STD_STA = CsxUtil.defaultValue(this.clone, null, this.status);
        const r: string = CsxUtil.defaultValue(CsxUtil.nestedGet(tar, keypath), null, ((default_ret) ? default_ret : ''));
        return r;
    }
    private processParam = (para_status: any): CSX_CMD_PARAM => {
        const para: CSX_CMD_PARAM = {};
        if (para_status) {
            const { option, desc, range, length, regex, fixed, radix, precision } = para_status;
            if (typeof option === 'string') { // option
                para.isOption = { option: option.split(',') };
                if (typeof desc === 'string') para.isOption.desc = desc.split(',');
            }
            if (typeof range === 'string') { // range
                // eslint-disable-next-line no-useless-escape
                const range_pattern = new RegExp(`^(${NUM_REGEX_PATTERN})~(${NUM_REGEX_PATTERN})\\+(${NUM_REGEX_PATTERN})$`);
                const rmatch = range.match(range_pattern);
                if (rmatch) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [nouse, min_s, max_s, step_s] = Array.from(rmatch);
                    const radix_v = parseInt(radix);
                    para.isRange = { range: { max: parseInt(max_s), min: parseInt(min_s), step: parseInt(step_s) } }
                    if (typeof fixed === 'string') para.isRange.fixed = parseInt(fixed);
                    if (radix_v === 2 || radix_v === 8 || radix_v === 10 || radix_v === 16) para.isRange.radix = radix_v;
                } else { // check floating point
                    const float_pattern = new RegExp(`^(${NUM_REGEX_PATTERN})~(${NUM_REGEX_PATTERN})$`);
                    const fmatch = range.match(float_pattern);
                    if (fmatch) {
                        const precision_v = parseFloat(precision);
                        // eslint-disable-next-line @typescript-eslint/no-unused-vars
                        const [nouse, min_s, max_s] = Array.from(fmatch);
                        para.isFloat = { range: { max: parseFloat(max_s), min: parseFloat(min_s) }, precision: (precision_v === 32) ? 32 : 16 }
                    }
                }
            }
            if (typeof length === 'string') { // string or array
                const lmatch = length.match(/^(\d+)~(\d+)$/);
                if (lmatch) {
                    // eslint-disable-next-line @typescript-eslint/no-unused-vars
                    const [nouse, min_s, max_s] = Array.from(lmatch);
                    const regex_pattern = /^\/(.*)\/$/;
                    para.isString = { length: { max: parseInt(max_s), min: parseInt(min_s) } };
                    if (typeof regex === 'string' && regex_pattern.test(regex)) {
                        const rmatch = regex.match(regex_pattern);
                        if (rmatch) {
                            // eslint-disable-next-line @typescript-eslint/no-unused-vars
                            const [full, regex_str] = Array.from(rmatch);
                            para.isString.regex = new RegExp(regex_str);
                        }
                    }
                }
            }
        }
        return para;
    }
    public readonly searchParam = (cmd_idx: CsxUtil.CYP_STD_CMD_TYPE, para_idx: string): CSX_CMD_PARAM => {
        let para_status = CsxUtil.nestedGet(this.param, `${cmd_idx}.${para_idx}`);
        if (typeof para_status === 'undefined' && typeof CMD_GET_TO_SET[cmd_idx] !== 'undefined') {
            para_status = CsxUtil.nestedGet(this.param, `${CMD_GET_TO_SET[cmd_idx]}.${para_idx}`); // try using set definition
        }
        return this.processParam(para_status);
    }
    public readonly searchParamException = (cmd_idx: CsxUtil.CYP_STD_CMD_TYPE, cond_para_idx: string, cond_value: string): CSX_PARAM_EXCEPTION => {
        const para: CSX_PARAM_EXCEPTION = {};
        const exception_path =  `${cmd_idx}.${cond_para_idx}.${cond_value}`;
        const para_value_desc = CsxUtil.nestedGet(this.param, exception_path);
        const exception_desc = (para_value_desc) ? para_value_desc.exception : undefined
        if (exception_desc) {
            Object.keys(exception_desc).forEach(para_idx => {
                if (Object.prototype.hasOwnProperty.call(exception_desc, para_idx)) {
                    // console.log('exception_desc[para_idx] :>> ', exception_desc[para_idx]);
                    para[para_idx] = this.processParam(exception_desc[para_idx]);
                    // console.log('para[para_idx] :>> ', para[para_idx]);
                }
            });
        }
        return para;
    }
    protected upload = (partial?: Array<CsxUtil.CYP_STD_CMD_TYPE>): void => { // support partial buffer
        const line = flatelize(this.buffer, '').filter(stastr => {
            const keycode = stastr.split('=')[0].split('.')[0];
            if (CsxUtil.isCypStdCmdType(keycode))
                return (partial) ? partial.indexOf(keycode) >= 0 : true; // only upload indicated parts
            else
                return false;
        });
        if (window.FOCUS_GATEWAY && line.length > 0) {
            const request = line.map(stastr => {
                if (window.FOCUS_GATEWAY) {
                    const path = stastr.split('=')[0];
                    const keycode = path.split('.')[0];
                    if (keycode === 'A77' || keycode === 'A78' || keycode === 'A79') { // special case
                        return (window.FOCUS_GATEWAY.ID === this.instance.ID) ? `?201:${path}\n` : `?101:${this.instance.ID},${path}\n`;
                    } else {
                        return (window.FOCUS_GATEWAY.ID === this.instance.ID) ? `?201:${stastr}\n` : `?101:${this.instance.ID},${stastr}\n`;
                    }
                } else {
                    return '';
                }
            }).join('');
            this.handleRequest(request);
            this.clean(partial);
        }
        if (window.APP_DEV_MODE) {
            this.clean(partial);
            irqRefresh(['DeviceRealTime', 'GatewayRealTime']);
        }
    }
    protected clean = (partial?: Array<CsxUtil.CYP_STD_CMD_TYPE>): void => {
        if (partial) {
            partial.forEach(key => { delete this.buffer[key]; });
            if (isEmpty(this.buffer)) this.clone = undefined;
        } else {
            this.buffer = {};
            this.clone = undefined;
        }
        irqRefresh(['DeviceRealTime', 'GatewayRealTime']);
    }
    cleanBuffer = () => { this.clean(); }
    get SNO() { return this.instance.SNO; }
    get ID() { return this.instance.ID; }
    get status() { return this.instance.STA; }
    get sys_status() { return this.instance.SYS_STA; }
    get param() { return this.instance.PARAM; }
    get isUntouchable() { return (this.instance.SYS_STA !== CsxUtil.SYS_STA_TYPE.Sync); }
    get isOutdated() { return (this.commandVersion.major < 2 || (this.commandVersion.major >= 2 && this.commandVersion.minor < 5)); }
    constructor(props?: CsxDevice) {
        if (typeof props === 'undefined')
            console.warn('CsxFeatureDevice is constructed by undefined!');
        this.permission = CsxUserPermissionLevel.NoAccess;
        this.instance = (props) ? props : new CsxDevice({ id: 'undefined' });
        this.buffer = {};
        const cmdver: string = CsxUtil.defaultValue(this.instance.STA.A3, null, '0.0');
        const parse_cmdver = cmdver.match(/^\d+\.\d+$/);
        if (parse_cmdver) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const [nouse, major, minor] = Array.from(parse_cmdver);
            const verStr = `v${major}_${minor}`;
            this.commandVersion = { major: parseInt(major), minor: parseInt(minor), str: (isCsxCommandVersion(verStr) ? verStr : 'v3_2_default') };
        } else {
            this.commandVersion = { major: 0, minor: 0, str: 'v3_2_default' };
        }
    }
}

export class CsxDanteDevice extends CsxFeatureDevice {
    FriendlyName = () => { return this.getStatus(`T2`); }
    RxChannelName = (ch_idx: stringable) => { return this.getStatus(`T4.${ch_idx}`); }
    TxChannelName = (ch_idx: stringable) => { return this.getStatus(`T6.${ch_idx}`); }
    RxRoute = (ch_idx: stringable) => { return this.getStatus(`T8.${ch_idx}`); }
    Configuration = (dev_idx: stringable) => { return this.getStatus(`T10.${dev_idx}`); }
    IPMode = () => { return this.getStatus(`T12`); }
    IPAddress = () => { return this.getStatus(`T14`); }
    Netmask = () => { return this.getStatus(`T15`); }
    Gateway = () => { return this.getStatus(`T16`); }
    StaticIPAddress = () => { return this.getStatus(`T18`); }
    StaticNetmask = () => { return this.getStatus(`T20`); }
    StaticGateway = () => { return this.getStatus(`T22`); }
    RxStereoRoute = (local_idx: stringable) => { return this.getStatus(`T25.${local_idx}`); }
    // set function
    SetFriendlyName = (value: stringable) => { this.setStatus(`T2`, value); }
    SetRxChannelName = (ch_idx: stringable, value: string) => { this.setStatus(`T4.${ch_idx}`, value); }
    SetTxChannelName = (ch_idx: stringable, value: string) => { this.setStatus(`T6.${ch_idx}`, value); }
    SetRxRoute = (ch_idx: stringable, value: string) => { this.setStatus(`T8.${ch_idx}`, value); }
    SetIPMode = (value: stringable) => { this.setStatus(`T12`, value, BUFFER_UNTIL_UPLOAD); }
    SetStaticIPAddress = (value: stringable) => { this.setStatus(`T18`, value, BUFFER_UNTIL_UPLOAD); }
    SetStaticNetmask = (value: stringable) => { this.setStatus(`T20`, value, BUFFER_UNTIL_UPLOAD); }
    SetStaticGateway = (value: stringable) => { this.setStatus(`T22`, value, BUFFER_UNTIL_UPLOAD); }
    SetRxStereoRoute = (local_idx: stringable, value: stringable) => { this.setStatus(`T25.${local_idx}`, value); }
    DanteDiscover = () => { this.trigger(`T9`); }
    DanteFactoryDefault = (value: stringable) => { this.setStatus(`T23`, value); }
    // parameter getters
    FriendlyNameParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('T1', 's1') }; }
    RxChannelNameParam = (): { n1: CSX_CMD_PARAM, s1: CSX_CMD_PARAM } => { return { n1: this.searchParam('T3', 'n1'), s1: this.searchParam('T3', 's1') }; }
    TxChannelNameParam = (): { n1: CSX_CMD_PARAM, s1: CSX_CMD_PARAM } => { return { n1: this.searchParam('T5', 'n1'), s1: this.searchParam('T5', 's1') }; }
    RxRouteParam = (): { n1: CSX_CMD_PARAM, s1: CSX_CMD_PARAM, s2: CSX_CMD_PARAM } => { return { n1: this.searchParam('T7', 'n1'), s1: this.searchParam('T7', 's1'), s2: this.searchParam('T7', 's2') }; }
    IPModeParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('T11', 's1') }; }
    StaticIPAddressParam = (): { ip1: CSX_CMD_PARAM } => { return { ip1: this.searchParam('T17', 'ip1') }; }
    StaticNetmaskParam = (): { ip1: CSX_CMD_PARAM } => { return { ip1: this.searchParam('T19', 'ip1') }; }
    StaticGatewayParam = (): { ip1: CSX_CMD_PARAM } => { return { ip1: this.searchParam('T21', 'ip1') }; }
    RxStereoRouteParam = (): { x1: CSX_CMD_PARAM, x2: CSX_CMD_PARAM, s1: CSX_CMD_PARAM } => { return { x1: this.searchParam('T24', 'x1'), x2: this.searchParam('T24', 'x2'), s1: this.searchParam('T24', 's1') }; }
    DanteFactoryDefaultParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('T23', 's1') }; }
    // support getter
    IsSupportFriendlyName = () => { return this.isSupport(['T2']); }
    // IsSupportRxChannelName = () => { return this.isSupport(['T4']); }
    // IsSupportTxChannelName = () => { return this.isSupport(['T6']); }
    // IsSupportRxRoute = () => { return this.isSupport(['T8']); }
    // IsSupportConfiguration = () => { return this.isSupport(['T10']); }
    // IsSupportIPMode = () => { return this.isSupport(['T12']); }
    // IsSupportIPAddress = () => { return this.isSupport(['T14']); }
    // IsSupportNetmask = () => { return this.isSupport(['T15']); }
    // IsSupportGateway = () => { return this.isSupport(['T16']); }
    // IsSupportStaticIPAddress = () => { return this.isSupport(['T18']); }
    // IsSupportStaticNetmask = () => { return this.isSupport(['T20']); }
    // IsSupportStaticGateway = () => { return this.isSupport(['T22']); }
    // IsSupportIPConfiguration = () => { return (this.isSupport(['T12', 'T14', 'T15', 'T16'])); }
    // IsSupportStaticIPConfiguration = () => { return (this.isSupport(['T18', 'T20', 'T22', 'T12'])); }
    // IsSupportRxStereoRoute = () => { return this.isSupport(['T25']); }
    // IsSupportDanteDiscover = () => { return this.isSupport(['T9']); }
    // IsSupportDanteFactoryDefault = () => { return this.isSupport(['T23']); }
    // IsSupportFriendlyName = () => { return true; }
    IsSupportTxChannelName = () => { return false; }
    IsSupportRxChannelName = () => { return false; }
    IsSupportRxRoute = () => { return false; }
    IsSupportConfiguration = () => { return false; }
    IsSupportIPMode = () => { return false; }
    IsSupportIPAddress = () => { return false; }
    IsSupportNetmask = () => { return false; }
    IsSupportGateway = () => { return false; }
    IsSupportStaticIPAddress = () => { return false; }
    IsSupportStaticNetmask = () => { return false; }
    IsSupportStaticGateway = () => { return false; }
    IsSupportIPConfiguration = () => { return false; }
    IsSupportStaticIPConfiguration = () => { return false; }
    IsSupportRxStereoRoute = () => { return false; }
    IsSupportDanteDiscover = () => { return false; }
    IsSupportDanteFactoryDefault = () => { return false; }
    // update function
    UploadStaticIPConfiguration = () => {
        this.upload(['T17', 'T19', 'T21', 'T11']);
    }
    
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('dante_control');
            }
        }
    }
}

export class CsxCECDevice extends CsxFeatureDevice {
    OutCECCapbility = (out_idx: stringable) => { return this.getStatus(`K5.${out_idx}`); }
    InCECCapbility = (in_idx: stringable) => { return this.getStatus(`K6.${in_idx}`); }
    // set function
    SetOutCECData = (out_idx: stringable, value: stringable) => { this.setStatus(`K1.${out_idx}`, value); }
    SetInCECData = (in_idx: stringable, value: stringable) => { this.setStatus(`K2.${in_idx}`, value); }
    SetOutCECMenu = (out_idx: stringable, value: stringable) => { this.setStatus(`K3.${out_idx}`, value); }
    SetInCECMenu = (in_idx: stringable, value: stringable) => { this.setStatus(`K4.${in_idx}`, value); }
    // parameter getters
    OutCECCapbilityParam = (): { x1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { x1: this.searchParam('K5', 'x1'), b1: this.searchParam('K5', 'b1') }; }
    InCECCapbilityParam = (): { n1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { n1: this.searchParam('K6', 'n1'), b1: this.searchParam('K6', 'b1') }; }
    SetOutCECDataParam = (): { x1: CSX_CMD_PARAM; hh: CSX_CMD_PARAM } => { return { x1: this.searchParam('K1', 'x1'), hh: this.searchParam('K1', 'hh') }; }
    SetInCECDataParam = (): { n1: CSX_CMD_PARAM; hh: CSX_CMD_PARAM } => { return { n1: this.searchParam('K2', 'n1'), hh: this.searchParam('K2', 'hh') }; }
    SetOutCECMenuParam = (): { x1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { x1: this.searchParam('K3', 'x1'), b1: this.searchParam('K3', 'b1') }; }
    SetInCECMenuParam = (): { x1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { x1: this.searchParam('K4', 'x1'), b1: this.searchParam('K4', 'b1') }; }
    // support getter
    IsSupportOutCECCapbility = () => { return this.isSupport(['K5']); }
    IsSupportInCECCapbility = () => { return this.isSupport(['K6']); }
    IsSupportSetOutCECData = () => { return this.inAllIndex(['K1']); }
    IsSupportSetInCECData = () => { return this.inAllIndex(['K2']); }
    IsSupportSetOutCECMenu = () => { return this.inAllIndex(['K3']); }
    IsSupportSetInCECMenu = () => { return this.inAllIndex(['K4']); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('cec_control');
            }
        }
    }
}

export class CsxPatternGeneratorDevice extends CsxFeatureDevice {
    PatternTiming = (pat_idx: stringable) => { return this.getStatus(`L2.${pat_idx}`); }
    PatternMode = (pat_idx: stringable) => { return this.getStatus(`L8.${pat_idx}`); }
    PatternType = (pat_idx: stringable) => { return this.getStatus(`L10.${pat_idx}`); }
    FreerunMode = (fr_idx: stringable) => { return this.getStatus(`L13.${fr_idx}`); }
    FreerunType = (fr_idx: stringable) => { return this.getStatus(`L15.${fr_idx}`); }
    // set function
    SetPatternTiming = (pat_idx: stringable, value: stringable) => { this.setStatus(`L2.${pat_idx}`, value); }
    SetPatternMode = (pat_idx: stringable, value: stringable) => { this.setStatus(`L8.${pat_idx}`, value); }
    SetPatternType = (pat_idx: stringable, value: stringable) => { this.setStatus(`L10.${pat_idx}`, value); }
    SetFreerunMode = (fr_idx: stringable, value: stringable) => { this.setStatus(`L13.${fr_idx}`, value); }
    SetFreerunType = (fr_idx: stringable, value: stringable) => { this.setStatus(`L15.${fr_idx}`, value); }
    // parameter getters
    PatternTimingParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('L2', 'x1'), n1: this.searchParam('L2', 'n1') }; }
    PatternModeParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('L8', 'x1'), n1: this.searchParam('L8', 'n1') }; }
    PatternTypeParam = (): { x1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { x1: this.searchParam('L10', 'x1'), b1: this.searchParam('L10', 'b1') }; }
    FreerunModeParam = (): { x1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { x1: this.searchParam('L13', 'x1'), b1: this.searchParam('L13', 'b1') }; }
    FreerunTypeParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('L15', 'x1'), n1: this.searchParam('L15', 'n1') }; }
    // support getter
    IsSupportPatternTiming = () => { return this.isSupport(['L2']); }
    IsSupportPatternMode = () => { return this.isSupport(['L8']); }
    IsSupportPatternType = () => { return this.isSupport(['L10']); }
    IsSupportFreerunMode = () => { return this.isSupport(['L13']); }
    IsSupportFreerunType = () => { return this.isSupport(['L15']); }
}

export class CsxStreamingDevice extends CsxFeatureDevice {
    EncodeProfileResolution = (enc_idx: stringable, prof_idx: stringable) => { return this.getStatus(`O2.${enc_idx}.${prof_idx}`); }
    EncodeProfileBitrate = (enc_idx: stringable, prof_idx: stringable) => { return this.getStatus(`O4.${enc_idx}.${prof_idx}`); }
    EncodeProfileFramerate = (enc_idx: stringable, prof_idx: stringable) => { return this.getStatus(`O6.${enc_idx}.${prof_idx}`); }
    LiveModePath = () => { return this.getStatus('O8'); }
    LiveStreamUrl = () => { return this.getStatus('O10'); }
    LiveStreamKey = () => { return this.getStatus('O12'); }
    LiveStreamMode = () => { return this.getStatus('O24'); }
    LiveEncodeResolution = () => { return this.getStatus('O14'); }
    LiveEncodeBitrate = () => { return this.getStatus('O16'); }
    LiveEncodeFramerate = () => { return this.getStatus('O18'); }
    // set function
    SetEncodeProfileResolution = (enc_idx: stringable, prof_idx: stringable, value: stringable) => { this.setStatus(`O2.${enc_idx}.${prof_idx}`, value, BUFFER_UNTIL_UPLOAD); }
    SetEncodeProfileBitrate = (enc_idx: stringable, prof_idx: stringable, value: stringable) => { this.setStatus(`O4.${enc_idx}.${prof_idx}`, value, BUFFER_UNTIL_UPLOAD); }
    SetEncodeProfileFramerate = (enc_idx: stringable, prof_idx: stringable, value: stringable) => { this.setStatus(`O6.${enc_idx}.${prof_idx}`, value, BUFFER_UNTIL_UPLOAD); }
    SetLiveModePath = (value: stringable) => { this.setStatus(`O8`, value, BUFFER_UNTIL_UPLOAD); }
    SetLiveStreamUrl = (value: stringable) => { this.setStatus(`O10`, value, BUFFER_UNTIL_UPLOAD); }
    SetLiveStreamKey = (value: stringable) => { this.setStatus(`O12`, value, BUFFER_UNTIL_UPLOAD); }
    SetLiveStreamMode = (value: stringable) => { this.setStatus(`O24`, value); }
    SetLiveEncodeResolution = (value: stringable) => { this.setStatus(`O14`, value, BUFFER_UNTIL_UPLOAD); }
    SetLiveEncodeBitrate = (value: stringable) => { this.setStatus(`O16`, value, BUFFER_UNTIL_UPLOAD); }
    SetLiveEncodeFramerate = (value: stringable) => { this.setStatus(`O18`, value, BUFFER_UNTIL_UPLOAD); }
    // parameter getters
    EncodeProfileResolutionParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { n1: this.searchParam('O2', 'n1'), n2: this.searchParam('O2', 'n2'), s1: this.searchParam('O2', 's1') }; }
    EncodeProfileBitrateParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM; n3: CSX_CMD_PARAM } => { return { n1: this.searchParam('O4', 'n1'), n2: this.searchParam('O4', 'n2'), n3: this.searchParam('O4', 'n3') }; }
    EncodeProfileFramerateParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM; n3: CSX_CMD_PARAM } => { return { n1: this.searchParam('O6', 'n1'), n2: this.searchParam('O6', 'n2'), n3: this.searchParam('O6', 'n3') }; }
    LiveModePathParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('O8', 's1') }; }
    LiveStreamUrlParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('O10', 's1') }; }
    LiveStreamKeyParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('O12', 's1') }; }
    LiveStreamModeParam = (): { b1: CSX_CMD_PARAM } => { return { b1: this.searchParam('O24', 's1') }; }
    LiveEncodeResolutionParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('O14', 's1') }; }
    LiveEncodeBitrateParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('O16', 'n1') }; }
    LiveEncodeFramerateParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('O18', 'n1') }; }
    // support getter
    IsSupportEncodeProfileConfiguration = () => { return this.isSupport(['O2', 'O4', 'O6']); }
    IsSupportLiveModePath = () => { return this.isSupport(['O8']); }
    IsSupportLiveStreamUrl = () => { return this.isSupport(['O10']); }
    IsSupportLiveStreamKey = () => { return this.isSupport(['O12']); }
    IsSupportLiveStreamMode = () => { return this.isSupport(['O24']); }
    IsSupportLiveEncodeConfiguration = () => { return this.isSupport(['O14', 'O16', 'O18']); }
    // upload function
    UploadProfileConfiguration = () => { this.upload(['O1', 'O3', 'O5']); }
    UploadLiveConfiguration = () => { this.upload(['O7', 'O9', 'O11', 'O23', 'O13', 'O15', 'O17']); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('streaming_control');
            }
        }
    }
}

export class CsxRecordDevice extends CsxFeatureDevice {
    RecordMode = () => { return this.getStatus('P2'); }
    RecordOverwrite = () => { return this.getStatus('P4'); }
    RecordResolution = () => { return this.getStatus('P10'); }
    RecordBitrate = () => { return this.getStatus('P12'); }
    RecordFramerate = () => { return this.getStatus('P14'); }
    RecordMediaPath = () => { return this.getStatus('P6'); }
    RecordProfilePath = () => { return this.getStatus('P16'); }
    ScheduleMode = () => { return this.getStatus('P8'); }
    // set function
    SetRecordMode = (value: stringable) => { this.setStatus('P2', value); }
    SetRecordOverwrite = (value: stringable) => { this.setStatus('P4', value); }
    SetRecordResolution = (value: stringable) => { this.setStatus('P10', value, BUFFER_UNTIL_UPLOAD); }
    SetRecordBitrate = (value: stringable) => { this.setStatus('P12', value, BUFFER_UNTIL_UPLOAD); }
    SetRecordFramerate = (value: stringable) => { this.setStatus('P14', value, BUFFER_UNTIL_UPLOAD); }
    SetRecordMediaPath = (value: stringable) => { this.setStatus('P6', value, BUFFER_UNTIL_UPLOAD); }
    SetRecordProfilePath = (value: stringable) => { this.setStatus('P16', value, BUFFER_UNTIL_UPLOAD); }
    SetScheduleMode = (value: stringable) => { this.setStatus('P8', value); }
    // parameter getters
    RecordModeParam = (): { b1: CSX_CMD_PARAM } => { return { b1: this.searchParam('P2', 'b1') }; }
    RecordOverwriteParam = (): { b1: CSX_CMD_PARAM } => { return { b1: this.searchParam('P4', 'b1') }; }
    RecordResolutionParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('P10', 's1') }; }
    RecordBitrateParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('P12', 'n1') }; }
    RecordFramerateParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('P14', 'n1') }; }
    RecordMediaPathParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('P6', 's1') }; }
    RecordProfilePathParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('P16', 'n1') }; }
    ScheduleModeParam = (): { b1: CSX_CMD_PARAM } => { return { b1: this.searchParam('P8', 'b1') }; }
    // support getter
    IsSupportRecordMode = () => { return this.isSupport(['P2']); }
    IsSupportRecordOverwrite = () => { return this.isSupport(['P4']); }
    IsSupportRecordResolution = () => { return this.isSupport(['P10']); }
    IsSupportRecordBitrate = () => { return this.isSupport(['P12']); }
    IsSupportRecordFramerate = () => { return this.isSupport(['P14']); }
    IsSupportRecordMediaPath = () => { return this.isSupport(['P6']); }
    IsSupportRecordProfilePath = () => { return this.isSupport(['P16']); }
    IsSupportScheduleMode = () => { return this.isSupport(['P8']); }
    // upload function
    UploadConfiguration = () => { this.upload(['P5', 'P9', 'P11', 'P13', 'P15']); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('record_control');
            }
        }
    }
}

export class CsxAutomationDevice extends CsxFeatureDevice {
    MultiCommandConfiguration = (cmd_idx: stringable) => { return this.getStatus(`B10.${cmd_idx}`); }
    UARTEventEnable = (evt_idx: stringable, uart_idx: stringable) => { return this.getStatus(`B2.${evt_idx}.${uart_idx}`); }
    UARTEventCommand = (evt_idx: stringable, uart_idx: stringable) => { return this.getStatus(`B4.${evt_idx}.${uart_idx}`); }
    UARTEventDelay = (evt_idx: stringable, uart_idx: stringable) => { return this.getStatus(`B6.${evt_idx}.${uart_idx}`); }
    UARTEventWait = (evt_idx: stringable, uart_idx: stringable) => { return this.getStatus(`B8.${evt_idx}.${uart_idx}`); }
    MultiCommandUARTEvent = (evt_idx: stringable, uart_idx: stringable) => { return this.getStatus(`B14.${evt_idx}.${uart_idx}`); }
    CECEventEnable = (evt_idx: stringable, cec_idx: stringable) => { return this.getStatus(`B16.${evt_idx}.${cec_idx}`); }
    CECEventCommand = (evt_idx: stringable, cec_idx: stringable) => { return this.getStatus(`B18.${evt_idx}.${cec_idx}`); }
    CECEventDelay = (evt_idx: stringable, cec_idx: stringable) => { return this.getStatus(`B20.${evt_idx}.${cec_idx}`); }
    CECEventWait = (evt_idx: stringable, cec_idx: stringable) => { return this.getStatus(`B22.${evt_idx}.${cec_idx}`); }
    // set function
    SetUARTEventEnable = (evt_idx: stringable, uart_idx: stringable, value: stringable) => { this.setStatus(`B2.${evt_idx}.${uart_idx}`, value); }
    SetUARTEventCommand = (evt_idx: stringable, uart_idx: stringable, value: stringable) => { this.setStatus(`B4.${evt_idx}.${uart_idx}`, value); }
    SetUARTEventDelay = (evt_idx: stringable, uart_idx: stringable, value: stringable) => { this.setStatus(`B6.${evt_idx}.${uart_idx}`, value); }
    SetUARTEventWait = (evt_idx: stringable, uart_idx: stringable, value: stringable) => { this.setStatus(`B8.${evt_idx}.${uart_idx}`, value); }
    SetUARTEventCommandTest = (evt_idx: stringable, cec_idx: stringable) => { this.trigger(`B58.${evt_idx}.${cec_idx}`); }
    SetMultiCommandConfiguration = (cmd_idx: stringable, delay_value: stringable, wait_value: stringable, cmd_value: stringable) => { this.setStatus(`B10.${cmd_idx}.${delay_value}.${wait_value}`, cmd_value); }
    SetMultiCommandUARTEvent = (evt_idx: stringable, uart_idx: stringable, value: stringable) => { this.setStatus(`B14.${evt_idx}.${uart_idx}`, value); }
    SetCECEventEnable = (evt_idx: stringable, cec_idx: stringable, value: stringable) => { this.setStatus(`B16.${evt_idx}.${cec_idx}`, value); }
    SetCECEventCommand = (evt_idx: stringable, cec_idx: stringable, value: stringable) => { this.setStatus(`B18.${evt_idx}.${cec_idx}`, value); }
    SetCECEventDelay = (evt_idx: stringable, cec_idx: stringable, value: stringable) => { this.setStatus(`B20.${evt_idx}.${cec_idx}`, value); }
    SetCECEventWait = (evt_idx: stringable, cec_idx: stringable, value: stringable) => { this.setStatus(`B22.${evt_idx}.${cec_idx}`, value); }
    SetCECEventCommandTest = (evt_idx: stringable, cec_idx: stringable) => { this.trigger(`B59.${evt_idx}.${cec_idx}`); }
    // parameter getters
    UARTEventEnableParam = (): { n1: CSX_CMD_PARAM; x1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { n1: this.searchParam('B2', 'n1'), x1: this.searchParam('B2', 'x1'), b1: this.searchParam('B2', 'b1') }; }
    UARTEventCommandParam = (): { n1: CSX_CMD_PARAM; x1: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { n1: this.searchParam('B4', 'n1'), x1: this.searchParam('B4', 'x1'), s1: this.searchParam('B4', 's1') }; }
    UARTEventDelayParam = (): { n1: CSX_CMD_PARAM; x1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('B6', 'n1'), x1: this.searchParam('B6', 'x1'), n2: this.searchParam('B6', 'n2') }; }
    UARTEventWaitParam = (): { n1: CSX_CMD_PARAM; x1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('B8', 'n1'), x1: this.searchParam('B8', 'x1'), n2: this.searchParam('B8', 'n2') }; }
    MultiCommandConfigurationParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM; n3: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { n1: this.searchParam('B10', 'n1'), n2: this.searchParam('B10', 'n2'), n3: this.searchParam('B10', 'n3'), s1: this.searchParam('B10', 's1') }; }
    MultiCommandUARTEventParam = (): { n1: CSX_CMD_PARAM; x1: CSX_CMD_PARAM; nn: CSX_CMD_PARAM } => { return { n1: this.searchParam('B14', 'n1'), x1: this.searchParam('B14', 'x1'), nn: this.searchParam('B14', 'nn') }; }
    CECEventEnableParam = (): { n1: CSX_CMD_PARAM; x1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { n1: this.searchParam('B16', 'n1'), x1: this.searchParam('B16', 'x1'), b1: this.searchParam('B16', 'b1') }; }
    CECEventCommandParam = (): { n1: CSX_CMD_PARAM; x1: CSX_CMD_PARAM; hh: CSX_CMD_PARAM } => { return { n1: this.searchParam('B18', 'n1'), x1: this.searchParam('B18', 'x1'), hh: this.searchParam('B18', 'hh') }; }
    CECEventDelayParam = (): { n1: CSX_CMD_PARAM; x1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('B20', 'n1'), x1: this.searchParam('B20', 'x1'), n2: this.searchParam('B20', 'n2') }; }
    CECEventWaitParam = (): { n1: CSX_CMD_PARAM; x1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('B22', 'n1'), x1: this.searchParam('B22', 'x1'), n2: this.searchParam('B22', 'n2') }; }
    // support getter
    // IsSupportUARTEventEnable = () => { return true; }
    // IsSupportUARTEventCommand = () => { return true; }
    // IsSupportUARTEventDelay = () => { return true; }
    // IsSupportUARTEventWait = () => { return true; }
    // IsSupportMultiCommandConfiguration = () => { return true; }
    // IsSupportMultiCommandUARTEvent = () => { return true; }
    // IsSupportCECEventEnable = () => { return true; }
    // IsSupportCECEventCommand = () => { return true; }
    // IsSupportCECEventDelay = () => { return true; }
    // IsSupportCECEventWait = () => { return true; }
    IsSupportUARTEventEnable = () => { return this.isSupport(['B2']); }
    IsSupportUARTEventCommand = () => { return this.isSupport(['B4']); }
    IsSupportUARTEventDelay = () => { return this.isSupport(['B6']); }
    IsSupportUARTEventWait = () => { return this.isSupport(['B8']); }
    IsSupportUARTEventCommandTest = () => { return this.isSupport(['B58']); }
    IsSupportMultiCommandConfiguration = () => { return this.isSupport(['B10']); }
    IsSupportMultiCommandUARTEvent = () => { return this.isSupport(['B14']); }
    IsSupportCECEventEnable = () => { return this.isSupport(['B16']); }
    IsSupportCECEventCommand = () => { return this.isSupport(['B18']); }
    IsSupportCECEventDelay = () => { return this.isSupport(['B20']); }
    IsSupportCECEventWait = () => { return this.isSupport(['B22']); }
    IsSupportCECEventCommandTest = () => { return this.isSupport(['B59']); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('automation_control');
            }
        }
    }
}

export class CsxUpgradableDevice extends CsxFeatureDevice {
    // IPAddress = (lan_idx: stringable) => { return this.getStatus((this.IsSupportMultiLan()?`A82.${lan_idx}`:'A46')); }
    FWVersion = () => { return this.getStatus('A1', 'NaN'); }
    CMDVersion = () => { return this.getStatus('A3', 'NaN'); }
    UpdateFilename = () => { return this.getStatus('A14'); }
    FWUpgradeProgress = (fw_id: stringable) => { return this.getStatus(`A124.${fw_id}`); }
    FWUpgradeLoading = (fw_id: stringable) => { return this.getStatus(`A123.${fw_id}`); }
    VendorID = () => { return this.getStatus('Z2') }
    ProductID = () => { return this.getStatus('Z4') }
    // set function
    TriggerFWUpgradeLoading = (fw_id: stringable, value: string) => { this.setStatus(`A122.${fw_id}`, value); }
    CleanUpgrade = (fw_id: stringable) => {
        if (this.status.A124)
            delete this.status.A124[fw_id];
        if (this.status.A123)
            delete this.status.A123[fw_id];
        irqRefresh(['DeviceRealTime']);
    }
    // parameter getters
    FWVersionParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('A1', 's1') }; }
    CMDVersionParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('A3', 's1') }; }
    UpdateFilenameParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('A14', 's1') }; }
    FWUpgradeProgressParam = () => { return { n1: this.searchParam('A124', 'n1'), s1: this.searchParam('A124', 's1') }; }
    FWUpgradeLoadingParam = () => { return { n1: this.searchParam('A123', 'n1'), n2: this.searchParam('A123', 'n2') }; }
    // support getter
    // IsSupportMultiLan = () => { return this.isSupport(['A80', 'A82', 'A83', 'A84']); }
    IsSupportFWVersion = () => { return this.isSupport(['A1']); }
    IsSupportFilelessUpgrade = () => { return this.isSupport(['A14']); }
    IsSupportCMDVersion = () => { return this.isSupport(['A3']); }
    IsSupportFWUpgradeProgress = () => { return this.isSupport(['A124']); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                if (window.CSX_CUR_AUTH.getPermission('system_permission') === CsxUserPermissionLevel.FullAccess) {
                    this.permission = window.CSX_CUR_AUTH.getPermission('unit_fwup');
                }
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('fwup_control');
            }
        }
    }
}

export class CsxRoutableDevice extends CsxFeatureDevice {
    InRouteName = (in_idx: stringable) => { return this.getStatus(`S2.${in_idx}`); }
    InSyncStatus = (in_idx: stringable) => { return this.getStatus(`S21.${in_idx}`); }
    OutRouteName = (out_idx: stringable) => { return this.getStatus(`S4.${out_idx}`); }
    OutRouteSrc = (out_idx: stringable) => { return this.getStatus(`S6.${out_idx}`); }
    OutSyncStatus = (out_idx: stringable) => { return this.getStatus(`S22.${out_idx}`); }
    PresetName = (prst_idx: stringable) => { return this.getStatus(`C11.${prst_idx}`); }
    OutMask = (out_idx: stringable) => { return this.getStatus(`C16.${out_idx}`); }
    Preset = (prst_idx: stringable, out_idx: stringable) => { return this.getStatus(`S7.${prst_idx}.${out_idx}`); }
    // set function
    SetInRouteName = (in_idx: stringable, value: stringable) => { this.setStatus(`S2.${in_idx}`, value); }
    SetOutRouteName = (out_idx: stringable, value: stringable) => { this.setStatus(`S4.${out_idx}`, value); }
    SetOutRouteSrc = (out_idx: stringable, value: stringable) => { this.setStatus(`S6.${out_idx}`, value); }
    SetPresetName = (prst_idx: stringable, value: stringable) => { this.setStatus(`C11.${prst_idx}`, value); }
    SetOutMask = (out_idx: stringable, value: stringable) => { this.setStatus(`C16.${out_idx}`, value); }
    SavePreset = (value: stringable) => { this.setStatus(`C7`, value); }
    ApplyPreset = (value: stringable) => { this.setStatus(`S8`, value); }
    // parameter getters
    InRouteNameParam = (): { n1: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { n1: this.searchParam('S2', 'n1'), s1: this.searchParam('S2', 's1') }; }
    InSyncStatusParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('S21', 'n1'), n2: this.searchParam('S21', 'n2') }; }
    OutRouteNameParam = (): { x1: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { x1: this.searchParam('S4', 'x1'), s1: this.searchParam('S4', 's1') }; }
    OutRouteSrcParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('S6', 'x1'), n1: this.searchParam('S6', 'n1') }; }
    OutSyncStatusParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('S22', 'x1'), n1: this.searchParam('S22', 'n1') }; }
    PresetNameParam = (): { n1: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { n1: this.searchParam('C11', 'n1'), s1: this.searchParam('C11', 's1') }; }
    OutMaskParam = (): { x1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { x1: this.searchParam('C16', 'x1'), b1: this.searchParam('C16', 'b1') }; }
    PresetParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('S7', 'n1') }; }
    // support getter
    IsSupportInNaming = () => { return this.isSupport(['S2']); }
    IsSupportInSyncStatus = () => { return this.isSupport(['S21']); }
    IsSupportOutNaming = () => { return this.isSupport(['S4']); }
    IsSupportOutSyncStatus = () => { return this.isSupport(['S22']); }
    IsSupportRouting = () => { return this.isSupport(['S6']); }
    IsSupportSavePreset = () => { return this.inAllIndex(['S8']); }
    IsSupportApplyPreset = () => { return this.inAllIndex(['S7']); }
    IsSupportPresetNaming = () => { return this.isSupport(['C11']); }
    IsSupportOutMask = () => { return this.isSupport(['C16']); }
    // upload function
    UploadOutRouteName = () => { this.upload(['S3']); }
    UploadInRouteName = () => { this.upload(['S1']); }
    UploadPresetName = () => { this.upload(['C10']); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('routing_control');
            }
        }
    }
}

export class CsxEDIDDevice extends CsxFeatureDevice {
    InEDID = (in_idx: stringable) => { return this.getStatus(`I2.${in_idx}`); }
    UserEDIDName = (edid_idx: stringable) => { return this.getStatus(`I5.${edid_idx}`); }
    UserEDIDData = (edid_idx: stringable) => { return this.getStatus(`I7.${edid_idx}`); }
    SinkEDIDData = (edid_idx: stringable) => { return this.getStatus(`I8.${edid_idx}`); }
    InEDIDData = (edid_idx: stringable) => { return this.getStatus(`I9.${edid_idx}`); }
    InternalEDIDData = (edid_idx: stringable) => { return this.getStatus(`I11.${edid_idx}`); }
    InEDIDMerge = (edid_idx: stringable) => { return this.getStatus(`I25.${edid_idx}`); }
    AllEDIDMode = () => { return this.getStatus(`I13`); }
    AllEDID = () => { return this.getStatus(`I15`); }
    // set function
    SetInEDID = (in_idx: stringable, value: stringable) => { this.setStatus(`I2.${in_idx}`, value); }
    SetUserEDIDName = (in_idx: stringable, value: stringable) => { this.setStatus(`I5.${in_idx}`, value); }
    SetUserEDIDData = (edid_idx: stringable, value: stringable) => { this.setStatus(`I7.${edid_idx}`, value); }
    SetSinkEDIDData = (edid_idx: stringable, value: stringable) => { this.setStatus(`I8.${edid_idx}`, value); }
    SetInEDIDData = (edid_idx: stringable, value: stringable) => { this.setStatus(`I9.${edid_idx}`, value); }
    SetInternalEDIDData = (edid_idx: stringable, value: stringable) => { this.setStatus(`I11.${edid_idx}`, value); }
    SetInEDIDMerge = (edid_idx: stringable, value: stringable) => { this.setStatus(`I25.${edid_idx}`, value); }
    SetAllEDIDMode = (value: stringable) => { this.setStatus(`I13`, value); }
    SetAllEDID = (value: stringable) => { this.setStatus(`I15`, value); }
    // parameter getters
    InEDIDParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('I2', 'n1'), n2: this.searchParam('I2', 'n2') }; }
    UserEDIDNameParam = (): { n1: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { n1: this.searchParam('I4', 'n1'), s1: this.searchParam('I4', 's1') }; }
    UserEDIDDataParam = (): { n1: CSX_CMD_PARAM; hh: CSX_CMD_PARAM } => { return { n1: this.searchParam('I7', 'n1'), hh: this.searchParam('I7', 'hh') }; }
    SinkEDIDDataParam = (): { x1: CSX_CMD_PARAM; hh: CSX_CMD_PARAM } => { return { x1: this.searchParam('I8', 'x1'), hh: this.searchParam('I8', 'hh') }; }
    InEDIDDataParam = (): { n1: CSX_CMD_PARAM; hh: CSX_CMD_PARAM } => { return { n1: this.searchParam('I9', 'n1'), hh: this.searchParam('I9', 'hh') }; }
    InternalEDIDDataParam = (): { n1: CSX_CMD_PARAM; hh: CSX_CMD_PARAM } => { return { n1: this.searchParam('I11', 'n1'), hh: this.searchParam('I11', 'hh') }; }
    InEDIDMergeParam = (): { n1: CSX_CMD_PARAM, b1: CSX_CMD_PARAM } => { return { n1: this.searchParam('I24', 'n1'), b1: this.searchParam('I24', 'b1') }; }
    AllEDIDModeParam = (): { b1: CSX_CMD_PARAM } => { return { b1: this.searchParam('I13', 'b1') }; }
    AllEDIDParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('I15', 'n1') }; }
    // support getter
    IsSupportEDIDSwitch = () => { return this.isSupport(['I2']); }
    IsSupportUserEDIDNaming = () => { return this.isSupport(['I5']); }
    IsSupportUserEDIDWrite = () => { return this.inAllIndex(['I6']); }
    IsSupportUserEDIDRead = () => { return this.isSupport(['I7']); }
    IsSupportSinkEDIDRead = () => { return this.isSupport(['I8']); }
    IsSupportSrcEDIDRead = () => { return this.isSupport(['I9']); }
    IsSupportInternalEDIDRead = () => { return this.isSupport(['I11']); } 
    IsSupportInEDIDMerge = () => { return this.isSupport(['I25']); } 
    IsSupportAllEDIDMode = () => { return this.isSupport([`I13`]); }
    IsSupportAllEDID = () => { return this.isSupport([`I15`]); }
    // upload function
    UploadUserEDIDName = () => { this.upload(['I4']); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('edid_control');
            }
        }
    }
}

export class CsxHDCPDevice extends CsxFeatureDevice {
    InRouteName = (in_idx: stringable) => { return this.getStatus(`S2.${in_idx}`); }
    InHDCPMode = (in_idx: stringable) => { return this.getStatus(`J2.${in_idx}`); }
    InHDCPStatus = (in_idx: stringable) => { return this.getStatus(`J3.${in_idx}`); }
    OutHDCPStatus = (out_idx: stringable) => { return this.getStatus(`J4.${out_idx}`); }
    InHDCPAbility = (in_idx: stringable) => { return this.getStatus(`J6.${in_idx}`); }
    OutHDCPAbility = (out_idx: stringable) => { return this.getStatus(`J5.${out_idx}`); }
    InMultiviewHDCPMode = (in_idx: stringable) => { return this.getStatus(`J8.${in_idx}`); }
    OutHDCPMuteMode = (out_idx: stringable) => { return this.getStatus(`J10.${out_idx}`); }
    // set function
    SetInRouteName = (in_idx: stringable, value: stringable) => { this.setStatus(`S2.${in_idx}`, value); }
    SetInHDCPMode = (in_idx: stringable, value: stringable) => { this.setStatus(`J2.${in_idx}`, value); }
    SetInHDCPStatus = (in_idx: stringable, value: stringable) => { this.setStatus(`J3.${in_idx}`, value); }
    SetOutHDCPStatus = (out_idx: stringable, value: stringable) => { this.setStatus(`J4.${out_idx}`, value); }
    SetInHDCPAbility = (in_idx: stringable, value: stringable) => { this.setStatus(`J6.${in_idx}`, value); }
    SetOutHDCPAbility = (out_idx: stringable, value: stringable) => { this.setStatus(`J5.${out_idx}`, value); }
    SetInMultiviewHDCPMode = (in_idx: stringable, value: stringable) => { this.setStatus(`J8.${in_idx}`, value); }
    SetOutHDCPMuteMode = (out_idx: stringable, value: stringable) => { this.setStatus(`J10.${out_idx}`, value); }
    // parameter getters
    InRouteNameParam = (): { n1: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { n1: this.searchParam('S2', 'n1'), s1: this.searchParam('S2', 's1') }; }
    InHDCPModeParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('J2', 'n1'), n2: this.searchParam('J2', 'n2') }; }
    InHDCPStatusParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('J3', 'n1'), n2: this.searchParam('J3', 'n2') }; }
    OutHDCPStatusParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('J4', 'x1'), n1: this.searchParam('J4', 'n1') }; }
    InHDCPAbilityParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('J6', 'n1'), n2: this.searchParam('J6', 'n2') }; }
    OutHDCPAbilityParam = (): { n1: CSX_CMD_PARAM; x1: CSX_CMD_PARAM } => { return { n1: this.searchParam('J5', 'n1'), x1: this.searchParam('J5', 'x1') }; }
    InMultiviewHDCPModeParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('J8', 'n1'), n2: this.searchParam('J8', 'n2') }; }
    OutHDCPMuteModeParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('J10', 'x1'), n1: this.searchParam('J10', 'n1') }; }
    // support getter
    IsSupportInHDCPMode = () => { return this.isSupport(['J2']); }
    IsSupportInHDCPStatus = () => { return this.isSupport(['J3']); }
    IsSupportOutHDCPStatus = () => { return this.isSupport(['J4']); }
    IsSupportInHDCPAbility = () => { return this.isSupport(['J6']); }
    IsSupportOutHDCPAbility = () => { return this.isSupport(['J5']); }
    IsSupportMultiviewHDCPMode = () => { return this.isSupport(['J8']); }
    IsSupportHDCPMuteMode = () => { return this.isSupport(['J10']); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('hdcp_control');
            }
        }
    }
}

export class CsxIODevice extends CsxFeatureDevice {
    USBPowerSupply = (usb_idx: stringable) => { return this.getStatus(`A109.${usb_idx}`); }
    USBControlMode = (usb_idx: stringable) => { return this.getStatus(`A112.${usb_idx}`); }
    USBVirtualHub = (usb_idx: stringable) => { return this.getStatus(`A114.${usb_idx}`); }
    USBHostRoute = (usb_idx: stringable) => { return this.getStatus(`A118.${usb_idx}`); }
    UARTBaudrate = (uart_idx: stringable) => { return this.getStatus(`A32.${uart_idx}`); }
    UARTStopbit = (uart_idx: stringable) => { return this.getStatus(`A34.${uart_idx}`); }
    UARTDatabit = (uart_idx: stringable) => { return this.getStatus(`A36.${uart_idx}`); }
    UARTParity = (uart_idx: stringable) => { return this.getStatus(`A38.${uart_idx}`); }
    UARTMode = (uart_idx: stringable) => { return this.getStatus(`A41.${uart_idx}`); }
    IRInChannel = () => { return this.getStatus('A101'); }
    IRInCustomCode = () => { return this.getStatus('A103'); }
    // set function
    SetUSBPowerSupply = (usb_idx: stringable, value: stringable) => { this.setStatus(`A109.${usb_idx}`, value); }
    SetUSBControlMode = (usb_idx: stringable, value: stringable) => { this.setStatus(`A112.${usb_idx}`, value); }
    SetUSBVirtualHub = (usb_idx: stringable, value: stringable) => { this.setStatus(`A114.${usb_idx}`, value); }
    SetUSBHostRoute = (usb_idx: stringable, value: stringable) => { this.setStatus(`A118.${usb_idx}`, value); }
    SetUARTBaudrate = (uart_idx: stringable, value: stringable) => { this.setStatus(`A32.${uart_idx}`, value); }
    SetUARTStopbit = (uart_idx: stringable, value: stringable) => { this.setStatus(`A34.${uart_idx}`, value); }
    SetUARTDatabit = (uart_idx: stringable, value: stringable) => { this.setStatus(`A36.${uart_idx}`, value); }
    SetUARTParity = (uart_idx: stringable, value: stringable) => { this.setStatus(`A38.${uart_idx}`, value); }
    SetUARTMode = (uart_idx: stringable, value: stringable) => { this.setStatus(`A41.${uart_idx}`, value); }
    SetIRInChannel = (value: stringable) => { this.setStatus('A101', value); }
    SetIRInCustomCode = (value: stringable) => { this.setStatus('A103', value, BUFFER_UNTIL_UPLOAD); }
    SetUARTReset = (uart_idx: stringable) => { this.trigger(`A30.${uart_idx}\n`); }
    // parameter getters
    USBPowerSupplyParam = (): { n1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { n1: this.searchParam('A109', 'n1'), b1: this.searchParam('A109', 'b1') }; }
    USBControlModeParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('A112', 'n1'), n2: this.searchParam('A112', 'n2') }; }
    USBVirtualHubParam = (): { n1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { n1: this.searchParam('A114', 'n1'), b1: this.searchParam('A114', 'b1') }; }
    USBHostRouteParam = (): { n1: CSX_CMD_PARAM; nn1: CSX_CMD_PARAM } => { return { n1: this.searchParam('A118', 'n1'), nn1: this.searchParam('A118', 'nn1') }; }
    UARTBaudrateParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('A32', 'n1'), n2: this.searchParam('A32', 'n2') }; }
    UARTStopbitParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('A34', 'n1'), n2: this.searchParam('A34', 'n2') }; }
    UARTDatabitParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('A36', 'n1'), n2: this.searchParam('A36', 'n2') }; }
    UARTParityParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('A38', 'n1'), n2: this.searchParam('A38', 'n2') }; }
    UARTModeParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('A41', 'n1'), n2: this.searchParam('A41', 'n2') }; }
    IRInChannelParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('A101', 'n1') }; }
    IRInCustomCodeParam = (): { h1: CSX_CMD_PARAM } => { return { h1: this.searchParam('A103', 'h1') }; }
    // support getter
    IsSupportUARTReset = () => { return this.inAllIndex(['A30']); }
    IsSupportUARTSetCommand = () => { return this.inAllIndex(['A42']); }
    IsSupportUARTConfiguration = () => { return this.isSupport(['A32', 'A34', 'A36', 'A38']); }
    IsSupportUARTMode = () => { return this.isSupport(['A41']); }
    IsSupportUSBPowerSupply = () => { return this.isSupport(['A109']); }
    IsSupportUSBControlMode = () => { return this.isSupport(['A112']); }
    IsSupportUSBVirtualHub = () => { return this.isSupport(['A114']); }
    IsSupportUSBHostRoute = () => { return this.isSupport(['A118']); }
    IsSupportIRChannel = () => { return this.isSupport(['A101']); }
    IsSupportIRCustomCode = () => { return this.isSupport(['A103']); }
    // upload function
    UploadInIRCustomCode = () => { this.upload(['A102']); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('io_control');
            }
        }
    }
}

export class CsxGeneralDevice extends CsxFeatureDevice {
    get IP_SYNTAX() { return ((this.commandVersion.str === 'v3_2_default') ? 'nn' : 'ip1'); }
    MacAddress = (lan_idx: stringable) => { return this.getStatus((this.IsSupportMultiLan() ? `A5.${lan_idx}` : `A4`)); }
    IPMode = (lan_idx: stringable) => { return this.getStatus((this.IsSupportMultiLan() ? `A80.${lan_idx}` : 'A44')); }
    IPAddress = (lan_idx: stringable) => { return this.getStatus((this.IsSupportMultiLan() ? `A82.${lan_idx}` : 'A46')); }
    Netmask = (lan_idx: stringable) => { return this.getStatus((this.IsSupportMultiLan() ? `A83.${lan_idx}` : 'A47')); }
    Gateway = (lan_idx: stringable) => { return this.getStatus((this.IsSupportMultiLan() ? `A84.${lan_idx}` : 'A48')); }
    StaticIPAddress = (lan_idx: stringable) => { return this.getStatus((this.IsSupportMultiLan() ? `A86.${lan_idx}` : 'A50')); }
    StaticNetmask = (lan_idx: stringable) => { return this.getStatus((this.IsSupportMultiLan() ? `A88.${lan_idx}` : 'A52')); }
    StaticGateway = (lan_idx: stringable) => { return this.getStatus((this.IsSupportMultiLan() ? `A90.${lan_idx}` : 'A54')); }
    FanControlMode = (fan_idx: stringable) => { return this.getStatus(`A105.${fan_idx}`); }
    FanSpeed = (fan_idx: stringable) => { return this.getStatus(`A106.${fan_idx}`); }
    PointTemperature = (p_idx: stringable) => { return this.getStatus(`A107`); }
    VendorID = () => { return this.getStatus('Z2') }
    ProductID = () => { return this.getStatus('Z4') }
    ModelName = () => { return this.getStatus('Z8') }
    ModelDescription = () => { return this.getStatus('Z10'); }
    SerialNumber = () => { return this.getStatus('Z16'); }
    Nickname = () => { return this.getStatus('A16'); }
    AllCMDIndex = () => { return this.getStatus('A17'); }
    FeedbackBroadcast = () => { return this.getStatus('A19'); }
    Power = () => { return this.getStatus('A24'); }
    Keylock = () => { return this.getStatus('A28'); }
    Hostname = () => { return this.getStatus('A72'); }
    WebTimeout = () => { return this.getStatus('A76'); }
    HelloMode = () => { return this.getStatus('F4'); }
    KeyLedColor = (key_idx: stringable) => { return this.getStatus(`A129.${key_idx}`); }
    // set function
    SetStaticIPAddress = (lan_idx: stringable, value: stringable) => {
        if (this.IsSupportMultiLan()) {
            if (this.IsSupportFasionStaticIP())
                this.setStatus(`A86.${lan_idx}`, value, BUFFER_UNTIL_UPLOAD);
        } else {
            if (this.IsSupportFasionStaticIP()) {
                this.setStatus(`A50`, value, BUFFER_UNTIL_UPLOAD);
            } else {
                if (this.IsSupportOutdatedStaticIP())
                    this.setStatus(`A42`, value, BUFFER_UNTIL_UPLOAD);
            }
        }
    }
    SetStaticNetmask = (lan_idx: stringable, value: stringable) => {
        if (this.IsSupportMultiLan()) {
            if (this.IsSupportFasionStaticIP())
                this.setStatus(`A88.${lan_idx}`, value, BUFFER_UNTIL_UPLOAD);
        } else {
            if (this.IsSupportFasionStaticIP()) {
                this.setStatus(`A52`, value, BUFFER_UNTIL_UPLOAD);
            } else {
                if (this.IsSupportOutdatedStaticIP())
                    this.setStatus(`A44`, value, BUFFER_UNTIL_UPLOAD);
            }
        }
    }
    SetStaticGateway = (lan_idx: stringable, value: stringable) => {
        if (this.IsSupportMultiLan()) {
            if (this.IsSupportFasionStaticIP())
                this.setStatus(`A90.${lan_idx}`, value, BUFFER_UNTIL_UPLOAD);
        } else {
            if (this.IsSupportFasionStaticIP()) {
                this.setStatus(`A54`, value, BUFFER_UNTIL_UPLOAD);
            } else {
                if (this.IsSupportOutdatedStaticIP())
                    this.setStatus(`A46`, value, BUFFER_UNTIL_UPLOAD);
            }
        }
    }
    SetIPMode = (lan_idx: stringable, value: stringable) => { this.setStatus((this.IsSupportMultiLan() ? `A80.${lan_idx}` : 'A44'), value, BUFFER_UNTIL_UPLOAD); }
    SetFanControlMode = (fan_idx: stringable, value: stringable) => { this.setStatus(`A104.${fan_idx}`, value, false); }
    SetFanSpeed = (fan_idx: stringable, value: stringable) => { this.setStatus(`A105.${fan_idx}`, value); }
    SetNickname = (value: stringable) => { this.setStatus('A16', value); }
    SetFeedbackBroadcast = (value: stringable) => { this.setStatus('A19', value); }
    SetPower = (value: stringable) => { this.setStatus('A24', value); }
    SetKeylock = (value: stringable) => { this.setStatus('A28', value); }
    SetHostname = (value: stringable) => { this.setStatus('A72', value); }
    SetWebTimeout = (value: stringable) => { this.setStatus('A75', value); }
    SetHelloMode = (value: stringable) => { this.setStatus('F4', value); }
    SetKeyLedColor = (key_idx: stringable, value: stringable) => { this.setStatus(`A129.${key_idx}`, value); }
    Reboot = () => { this.trigger(`A25\n`); }
    FactoryDefault = () => { this.trigger(`A10\n`); }
    // parameter getters
    IPModeParam = (): { n1: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { n1: this.searchParam((this.IsSupportMultiLan() ? `A80` : 'A44'), 'n1'), s1: this.searchParam((this.IsSupportMultiLan() ? `A80` : 'A44'), 's1') }; }
    IPAddressParam = (): { n1: CSX_CMD_PARAM; nn: CSX_CMD_PARAM } => {
        return {
            n1: this.searchParam((this.IsSupportMultiLan() ? `A82` : 'A46'), 'n1'),
            nn: this.searchParam((this.IsSupportMultiLan() ? `A82` : 'A46'), this.IP_SYNTAX)
        };
    }
    NetmaskParam = (): { n1: CSX_CMD_PARAM; nn: CSX_CMD_PARAM } => {
        return {
            n1: this.searchParam((this.IsSupportMultiLan() ? `A83` : 'A47'), 'n1'),
            nn: this.searchParam((this.IsSupportMultiLan() ? `A83` : 'A47'), this.IP_SYNTAX)
        };
    }
    GatewayParam = (): { n1: CSX_CMD_PARAM; nn: CSX_CMD_PARAM } => {
        return {
            n1: this.searchParam((this.IsSupportMultiLan() ? `A84` : 'A48'), 'n1'),
            nn: this.searchParam((this.IsSupportMultiLan() ? `A84` : 'A48'), this.IP_SYNTAX)
        };
    }
    StaticIPAddressParam = (): { n1: CSX_CMD_PARAM; nn: CSX_CMD_PARAM } => {
        if (this.IsSupportMultiLan()) {
            if (this.IsSupportFasionStaticIP())
                return { n1: this.searchParam('A86', 'n1'), nn: this.searchParam('A86', this.IP_SYNTAX) };
        } else {
            if (this.IsSupportFasionStaticIP()) {
                return { n1: this.searchParam('A50', 'n1'), nn: this.searchParam('A50', this.IP_SYNTAX) };
            } else {
                if (this.IsSupportOutdatedStaticIP())
                    return { n1: this.searchParam('A42', 'n1'), nn: this.searchParam('A42', this.IP_SYNTAX) };
            }
        }
        return { n1: {}, nn: {} };
    }
    StaticNetmaskParam = (): { n1: CSX_CMD_PARAM; nn: CSX_CMD_PARAM } => {
        if (this.IsSupportMultiLan()) {
            if (this.IsSupportFasionStaticIP())
                return { n1: this.searchParam('A88', 'n1'), nn: this.searchParam('A87', this.IP_SYNTAX) };
        } else {
            if (this.IsSupportFasionStaticIP()) {
                return { n1: this.searchParam('A52', 'n1'), nn: this.searchParam('A52', this.IP_SYNTAX) };
            } else {
                if (this.IsSupportOutdatedStaticIP())
                    return { n1: this.searchParam('A44', 'n1'), nn: this.searchParam('A44', this.IP_SYNTAX) };
            }
        }
        return { n1: {}, nn: {} };
    }
    StaticGatewayParam = (): { n1: CSX_CMD_PARAM; nn: CSX_CMD_PARAM } => {
        if (this.IsSupportMultiLan()) {
            if (this.IsSupportFasionStaticIP())
                return { n1: this.searchParam('A90', 'n1'), nn: this.searchParam('A90', this.IP_SYNTAX) };
        } else {
            if (this.IsSupportFasionStaticIP()) {
                return { n1: this.searchParam('A54', 'n1'), nn: this.searchParam('A54', this.IP_SYNTAX) };
            } else {
                if (this.IsSupportOutdatedStaticIP())
                    return { n1: this.searchParam('A46', 'n1'), nn: this.searchParam('A46', this.IP_SYNTAX) };
            }
        }
        return { n1: {}, nn: {} };
    }
    FanControlModeParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('A105', 'n1') }; }
    FanSpeedParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('A106', 'n1'), n2: this.searchParam('A105', 'n2') }; }
    PointTemperatureParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('A107', 'n1') }; }
    VendorIDParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('Z2', 's1') }; }
    ProductIDParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('Z4', 's1') }; }
    ModelNameParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('Z8', 's1') }; }
    ModelDescriptionParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('Z10', 's1') }; }
    SerialNumberParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('Z16', 's1') }; }
    NicknameParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('A16', 's1') }; }
    AllCMDIndexParam = () => { return `${this.status.A17}`; }
    FeedbackBroadcastParam = (): { b1: CSX_CMD_PARAM } => { return { b1: this.searchParam('A19', 'b1') }; }
    PowerParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('A24', 's1') }; }
    KeylockParam = (): { b1: CSX_CMD_PARAM } => { return { b1: this.searchParam('A28', 'b1') }; }
    HostnameParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('A72', 's1') }; }
    WebTimeoutParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('A76', 'n1') }; }
    HelloModeParam = (): { b1: CSX_CMD_PARAM } => { return { b1: this.searchParam('F4', 'b1') }; }
    KeyLedColorParam = (): { n1: CSX_CMD_PARAM, s1: CSX_CMD_PARAM, n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('A128', 'n1'), s1: this.searchParam('A128', 's1'), n2: this.searchParam('A128', 'n2') }; }
    // support getter
    IsSupportReboot = () => { return this.inAllIndex(['A25']); }
    isSupportFactoryDefault = () => { return this.inAllIndex(['A10']); }
    IsSupportMultiLan = () => { return this.isSupport(['A80', 'A82', 'A83', 'A84']); }
    IsSupportFasionStaticIP = () => { return (this.inAllIndex((this.IsSupportMultiLan() ? ['A86', 'A88', 'A90',] : ['A50', 'A52', 'A54']))); }
    IsSupportOutdatedStaticIP = () => { return this.isOutdated && this.isSupport(['A41', 'A43', 'A45']); }
    IsSupportIPConfiguration = () => { return (this.IsSupportMultiLan() || this.isSupport(['A47', 'A44', 'A46', 'A48'])); }
    IsSupportStaticIPConfiguration = () => { return (this.IsSupportFasionStaticIP() || this.IsSupportOutdatedStaticIP()); }
    IsSupportFanControlMode = () => { return this.isSupport(['A105']); }
    IsSupportFanSpeed = () => { return this.isSupport(['A106']); }
    IsSupportTemperature = () => { return this.isSupport(['A107']); }
    IsSupportNickname = () => { return this.isSupport(['A16']); }
    IsSupportPowerControl = () => { return this.isSupport(['A24']); }
    IsSupportKeylock = () => { return this.isSupport(['A28']); }
    IsSupportHostname = () => { return this.isSupport(['A72']) && this.inAllIndex(['A126']); }
    IsSupportWebTimeout = () => { return this.isSupport(['A76']); }
    IsSupportHelloMode = () => { return this.isSupport(['F4']); }
    IsSupportKeyLedColor = () => { return this.isSupport(['A129']); }
    IsSupportPowerCommand = (cmd: string): boolean => {
        if (this.IsSupportPowerControl()) {
            const power_param = this.PowerParam();
            if (power_param.s1.isOption)
                return (power_param.s1.isOption.option.indexOf(cmd) >= 0);
        }
        return false;
    }
    // upload function
    UploadStaticIPConfiguration = () => {
        this.upload(['A85', 'A49', 'A41', 'A87', 'A51', 'A89', 'A53', 'A45']);
        this.upload(['A43', 'A77', 'A78', 'A79']);
    }
    UploadFanConfiguration = () => { this.upload(['A104']); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('general_control');
            }
        }
    }
}

export class CsxAudioDevice extends CsxFeatureDevice {
    OutAudioMute = (out_idx: stringable) => { return this.getStatus(`H2.${out_idx}`); }
    OutAudioRoute = (out_idx: stringable) => { return this.getStatus(`H5.${out_idx}`); }
    OutAudioVolume = (out_idx: stringable) => { return this.getStatus(`H7.${out_idx}`); }
    OutAudioName = (out_idx: stringable) => { return this.getStatus(`H11.${out_idx}`); }
    OutAudioDelay = (out_idx: stringable) => { return this.getStatus(`H13.${out_idx}`); }
    OutAudioEQ = (out_idx: stringable, freq_idx: stringable) => { return this.getStatus(`H15.${out_idx}.${freq_idx}`); }
    OutAudioTreble = (out_idx: stringable) => { return this.getStatus(`H20.${out_idx}`); }
    OutAudioBass = (out_idx: stringable) => { return this.getStatus(`H24.${out_idx}`); }
    OutAudioFormat = (out_idx: stringable) => { return this.getStatus(`H62.${out_idx}`); }
    OutAudioDSPMode = (out_idx: stringable) => { return this.getStatus(`H64.${out_idx}`); }
    InAudioPregain = (in_idx: stringable) => { return this.getStatus(`H28.${in_idx}`); }
    InAudioMute = (in_idx: stringable) => { return this.getStatus(`H38.${in_idx}`); }
    InAudioPowerMode = (in_idx: stringable) => { return this.getStatus(`H40.${in_idx}`); }
    MixerOutRoute = (out_idx: stringable) => { return this.getStatus(`H42.${out_idx}`); }
    MixerInVolume = (in_idx: stringable) => { return this.getStatus(`H34.${in_idx}`); }
    MixerOutVolume = (out_idx: stringable) => { return this.getStatus(`H36.${out_idx}`); }
    MixerEnable = () => { return this.getStatus(`H32`); }
    AudioVolumeKnoblock = () => { return this.getStatus(`H44`); }
    OutAudioTalkover = (out_idx: stringable) => { return this.getStatus(`H46.${out_idx}`); }
    OutAudioTalkoverTriggerTime = (out_idx: stringable) => { return this.getStatus(`H48.${out_idx}`); }
    OutAudioTalkoverAttackTime = (out_idx: stringable) => { return this.getStatus(`H50.${out_idx}`); }
    OutAudioTalkoverHoldTime = (out_idx: stringable) => { return this.getStatus(`H52.${out_idx}`); }
    OutAudioTalkoverReleaseTime = (out_idx: stringable) => { return this.getStatus(`H54.${out_idx}`); }
    OutAudioTalkoverDepth = (out_idx: stringable) => { return this.getStatus(`H56.${out_idx}`); }
    OutAudioTalkoverThreshold = (out_idx: stringable) => { return this.getStatus(`H58.${out_idx}`); }
    // set function
    SetOutAudioMute = (out_idx: stringable, value: stringable) => { this.setStatus(`H2.${out_idx}`, value); }
    SetOutAudioRoute = (out_idx: stringable, value: stringable) => { this.setStatus(`H5.${out_idx}`, value); }
    SetOutAudioVolume = (out_idx: stringable, value: stringable) => { this.setStatus(`H7.${out_idx}`, value); }
    SetOutAudioName = (out_idx: stringable, value: stringable) => { this.setStatus(`H11.${out_idx}`, value); }
    SetOutAudioDelay = (out_idx: stringable, value: stringable) => { this.setStatus(`H13.${out_idx}`, value); }
    SetOutAudioEQ = (out_idx: stringable, freq_idx: stringable, value: stringable) => { this.setStatus(`H15.${out_idx}.${freq_idx}`, value); }
    SetOutAudioTreble = (out_idx: stringable, value: stringable) => { this.setStatus(`H20.${out_idx}`, value); }
    SetOutAudioBass = (out_idx: stringable, value: stringable) => { this.setStatus(`H24.${out_idx}`, value); }
    SetOutAudioDSPMode = (out_idx: stringable, value: stringable) => { this.setStatus(`H64.${out_idx}`, value); }
    SetInAudioPregain = (in_idx: stringable, value: stringable) => { this.setStatus(`H28.${in_idx}`, value); }
    SetInAudioPregainUp = (in_idx: stringable) => { this.trigger(`H60.${in_idx}`); }
    SetInAudioPregainDown = (in_idx: stringable) => { this.trigger(`H61.${in_idx}`); }
    SetInAudioMute = (in_idx: stringable, value: stringable) => { this.setStatus(`H38.${in_idx}`, value); }
    SetInAudioPowerMode = (in_idx: stringable, value: stringable) => { this.setStatus(`H40.${in_idx}`, value); }
    SetMixerOutRoute = (out_idx: stringable, value: stringable) => { this.setStatus(`H42.${out_idx}`, value); }
    SetMixerInVolume = (in_idx: stringable, value: stringable) => { this.setStatus(`H34.${in_idx}`, value); }
    SetMixerOutVolume = (out_idx: stringable, value: stringable) => { this.setStatus(`H36.${out_idx}`, value); }
    SetMixerEnable = (value: stringable) => { this.setStatus(`H32`, value); }
    SetAudioVolumeKnoblock = (value: stringable) => { this.setStatus(`H44`, value); }
    SetOutAudioTalkover = (out_idx: stringable, value: stringable) => { this.setStatus(`H46.${out_idx}`, value); }
    SetOutAudioTalkoverTriggerTime = (out_idx: stringable, value: stringable) => { this.setStatus(`H48.${out_idx}`, value); }
    SetOutAudioTalkoverAttackTime = (out_idx: stringable, value: stringable) => { this.setStatus(`H50.${out_idx}`, value); }
    SetOutAudioTalkoverHoldTime = (out_idx: stringable, value: stringable) => { this.setStatus(`H52.${out_idx}`, value); }
    SetOutAudioTalkoverReleaseTime = (out_idx: stringable, value: stringable) => { this.setStatus(`H54.${out_idx}`, value); }
    SetOutAudioTalkoverDepth = (out_idx: stringable, value: stringable) => { this.setStatus(`H56.${out_idx}`, value); }
    SetOutAudioTalkoverThreshold = (out_idx: stringable, value: stringable) => { this.setStatus(`H58.${out_idx}`, value); }
    // parameter getters
    OutAudioMuteParam = (): { x1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H2', 'x1'), b1: this.searchParam('H2', 'b1') }; }
    OutAudioRouteParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H5', 'x1'), n1: this.searchParam('H5', 'n1') }; }
    OutAudioVolumeParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H7', 'x1'), n1: this.searchParam('H7', 'n1') }; }
    OutAudioNameParam = (): { x1: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H11', 'x1'), s1: this.searchParam('H11', 's1') }; }
    OutAudioDelayParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H13', 'x1'), n1: this.searchParam('H13', 'n1') }; }
    OutAudioEQParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { x1: this.searchParam('H15', 'x1'), n1: this.searchParam('H15', 'n1'), n2: this.searchParam('H15', 'n2') }; }
    OutAudioTrebleParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H20', 'x1'), n1: this.searchParam('H20', 'n1') }; }
    OutAudioBassParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H24', 'x1'), n1: this.searchParam('H24', 'n1') }; }
    OutAudioFormatParam = (): { x1: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H62', 'x1'), s1: this.searchParam('H62', 's1') }; }
    OutAudioDSPModeParam = (): { x1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H63', 'x1'), b1: this.searchParam('H63', 'b1') }; }
    InAudioPregainParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('H28', 'n1'), n2: this.searchParam('H28', 'n2') }; }
    InAudioPregainUpParam = (): { n1: CSX_CMD_PARAM, n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('H60', 'n1'), n2: this.searchParam('H60', 'n2') }; }
    InAudioPregainDownParam = (): { n1: CSX_CMD_PARAM, n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('H61', 'n1'), n2: this.searchParam('H61', 'n2') }; }
    InAudioMuteParam = (): { n1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { n1: this.searchParam('H38', 'n1'), b1: this.searchParam('H38', 'b1') }; }
    InAudioPowerModeParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('H40', 'n1'), n2: this.searchParam('H40', 'n2') }; }
    MixerOutRouteParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H42', 'x1'), n1: this.searchParam('H42', 'n1') }; }
    MixerInVolumeParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam(`H34`, 'n1'), n2: this.searchParam(`H34`, 'n2') }; }
    MixerOutVolumeParam = (): { n1: CSX_CMD_PARAM; x1: CSX_CMD_PARAM } => { return { n1: this.searchParam(`H36`, 'n1'), x1: this.searchParam(`H36`, 'x1') }; }
    MixerEnableParam = (): { b1: CSX_CMD_PARAM } => { return { b1: this.searchParam(`H32`, 'b1') }; }
    AudioVolumeKnoblockParam = (): { b1: CSX_CMD_PARAM } => { return { b1: this.searchParam('H44', 'b1') }; }
    OutAudioTalkoverParam = (): { x1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H46', 'x1'), b1: this.searchParam('H46', 'b1') }; }
    OutAudioTalkoverTriggerTimeParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H48', 'x1'), n1: this.searchParam('H48', 'n1') }; }
    OutAudioTalkoverAttackTimeParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H50', 'x1'), n1: this.searchParam('H50', 'n1') }; }
    OutAudioTalkoverHoldTimeParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H52', 'x1'), n1: this.searchParam('H52', 'n1') }; }
    OutAudioTalkoverReleaseTimeParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H54', 'x1'), n1: this.searchParam('H54', 'n1') }; }
    OutAudioTalkoverDepthParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H56', 'x1'), n1: this.searchParam('H56', 'n1') }; }
    OutAudioTalkoverThresholdParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('H58', 'x1'), n1: this.searchParam('H58', 'n1') }; }
    // support getter
    IsSupportOutMute = () => { return this.isSupport(['H2']); }
    IsSupportOutRoute = () => { return this.isSupport(['H5']); }
    IsSupportOutVolume = () => { return this.isSupport(['H7']); }
    IsSupportOutNaming = () => { return this.isSupport(['H11']); }
    IsSupportOutDelay = () => { return this.isSupport(['H13']); }
    IsSupportOutEQ = () => { return this.isSupport(['H15']); }
    IsSupportOutEQPlus = () => { return this.inAllIndex(['H16']); }
    IsSupportOutEQMinus = () => { return this.inAllIndex(['H17']); }
    IsSupportOutTreble = () => { return this.isSupport(['H20']); }
    IsSupportOutBass = () => { return this.isSupport(['H24']); }
    IsSupportOutAudioFormat = () => { return this.isSupport(['H62']); }
    IsSupportOutAudioDSPMode = () => { return this.isSupport(['H64']); }
    IsSupportInPregain = () => { return this.isSupport(['H28']); }
    IsSupportInAudioPregainUp = () => { return this.inAllIndex(['H60']); }
    IsSupportInAudioPregainDown = () => { return this.inAllIndex(['H61']); }
    IsSupportInMute = () => { return this.isSupport(['H38']); }
    IsSupportInPowerMode = () => { return this.isSupport(['H40']); }
    IsSupportKnoblock = () => { return this.isSupport(['H44']); }
    IsSupportMixer = () => { return this.isSupport(['H32']); }
    IsSupportMixerOutRoute = () => { return this.isSupport(['H42']); }
    IsSupportMixerInVolume = () => { return this.isSupport(['H34']); }
    IsSupportMixerOutVolume = () => { return this.isSupport(['H36']); }
    IsSupportTalkover = () => { return this.isSupport(['H48', 'H50', 'H52', 'H54', 'H56', 'H58']); }
    // upload function
    UploadOutAudioName = () => { this.upload(['H10']); }
    // Exception function
    MixerInVolumeInException = (in_idx: stringable) => { return this.searchParamException('H34', 'n1', `${in_idx}`); }
    OutAudioRouteInException = (out_idx: stringable) => { return this.searchParamException('H5', 'x1', `${out_idx}`); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('audio_control');
            }
        }
    }
}

export class CsxScalerDevice extends CsxFeatureDevice {
    OutTiming = (out_idx: stringable) => { return this.getStatus(`D4.${out_idx}`); }
    WindowBorderSize = (win_idx: stringable) => { return this.getStatus(`D20.${win_idx}`); }
    WindowRoute = (win_idx: stringable) => { return this.getStatus(`D22.${win_idx}`); }
    WindowBorderColor = (win_idx: stringable) => { return this.getStatus(`D24.${win_idx}`); }
    OutContrast = (out_idx: stringable) => { return this.getStatus(`D29.${out_idx}`); }
    OutBrightness = (out_idx: stringable) => { return this.getStatus(`D31.${out_idx}`); }
    OutSaturation = (out_idx: stringable) => { return this.getStatus(`D33.${out_idx}`); }
    OutHue = (out_idx: stringable) => { return this.getStatus(`D35.${out_idx}`); }
    OutSharpness = (out_idx: stringable) => { return this.getStatus(`D37.${out_idx}`); }
    OutAspectRatio = (out_idx: stringable) => { return this.getStatus(`D41.${out_idx}`); }
    OutAutoSyncOff = (out_idx: stringable) => { return this.getStatus(`D44.${out_idx}`); }
    OutRedGain = (out_idx: stringable) => { return this.getStatus(`D46.${out_idx}`); }
    OutGreenGain = (out_idx: stringable) => { return this.getStatus(`D48.${out_idx}`); }
    OutBlueGain = (out_idx: stringable) => { return this.getStatus(`D50.${out_idx}`); }
    InPhase = (in_idx: stringable) => { return this.getStatus(`D52.${in_idx}`); }
    InClock = (in_idx: stringable) => { return this.getStatus(`D54.${in_idx}`); }
    InHPosition = (in_idx: stringable) => { return this.getStatus(`D56.${in_idx}`); }
    InVPosition = (in_idx: stringable) => { return this.getStatus(`D58.${in_idx}`); }
    InCorrectTiming = (in_idx: stringable) => { return this.getStatus(`D69.${in_idx}`); }
    Out4K2KDownscaleMode = (out_idx: stringable) => { return this.getStatus(`D75.${out_idx}`); }
    Out4K2KUpscaleMode = (out_idx: stringable) => { return this.getStatus(`D77.${out_idx}`); }
    InHActive = (in_idx: stringable) => { return this.getStatus(`S17.${in_idx}`); }
    InVActive = (in_idx: stringable) => { return this.getStatus(`S18.${in_idx}`); }
    InRefreshRate = (in_idx: stringable) => { return this.getStatus(`S19.${in_idx}`); }
    InInterlace = (in_idx: stringable) => { return this.getStatus(`S20.${in_idx}`); }
    InSyncStatus = (in_idx: stringable) => { return this.getStatus(`S21.${in_idx}`); }
    InTiming = (in_idx: stringable) => { return this.getStatus(`S25.${in_idx}`); }
    InDeepColor = (in_idx: stringable) => { return this.getStatus(`S27.${in_idx}`); }
    OutSyncStatus = (out_idx: stringable) => { return this.getStatus(`S22.${out_idx}`); }
    OutDeepColor = (out_idx: stringable) => { return this.getStatus(`S29.${out_idx}`); }
    OutColorSpace = (out_idx: stringable) => { return this.getStatus(`S31.${out_idx}`); }
    OutHActive = (out_idx: stringable) => { return this.getStatus(`S32.${out_idx}`); }
    OutVActive = (out_idx: stringable) => { return this.getStatus(`S33.${out_idx}`); }
    OutRefreshRate = (out_idx: stringable) => { return this.getStatus(`S34.${out_idx}`); }
    OutInterlace = (out_idx: stringable) => { return this.getStatus(`S35.${out_idx}`); }
    InType = (in_idx: stringable) => { return this.getStatus(`S36.${in_idx}`); }
    OutType = (out_idx: stringable) => { return this.getStatus(`S37.${out_idx}`); }
    WindowLayoutMode = () => { return this.getStatus('D2'); }
    DisplayMode = () => { return this.getStatus('D10'); }
    HPIPSize = () => { return this.getStatus('D12'); }
    VPIPSize = () => { return this.getStatus('D14'); }
    HPIPPosition = () => { return this.getStatus('D16'); }
    VPIPPosition = () => { return this.getStatus('D17'); }
    // set function
    SetOutTiming = (out_idx: stringable, value: stringable) => { this.setStatus(`D4.${out_idx}`, value); }
    SetWindowBorderSize = (win_idx: stringable, value: stringable) => { this.setStatus(`D20.${win_idx}`, value); }
    SetWindowRoute = (win_idx: stringable, value: stringable) => { this.setStatus(`D22.${win_idx}`, value); }
    SetWindowBorderColor = (win_idx: stringable, value: stringable) => { this.setStatus(`D24.${win_idx}`, value); }
    SetOutContrast = (out_idx: stringable, value: stringable) => { this.setStatus(`D29.${out_idx}`, value); }
    SetOutBrightness = (out_idx: stringable, value: stringable) => { this.setStatus(`D31.${out_idx}`, value); }
    SetOutSaturation = (out_idx: stringable, value: stringable) => { this.setStatus(`D33.${out_idx}`, value); }
    SetOutHue = (out_idx: stringable, value: stringable) => { this.setStatus(`D35.${out_idx}`, value); }
    SetOutSharpness = (out_idx: stringable, value: stringable) => { this.setStatus(`D37.${out_idx}`, value); }
    SetOutAspectRatio = (out_idx: stringable, value: stringable) => { this.setStatus(`D41.${out_idx}`, value); }
    SetOutAutoSyncOff = (out_idx: stringable, value: stringable) => { this.setStatus(`D44.${out_idx}`, value); }
    SetOutRedGain = (out_idx: stringable, value: stringable) => { this.setStatus(`D46.${out_idx}`, value); }
    SetOutGreenGain = (out_idx: stringable, value: stringable) => { this.setStatus(`D48.${out_idx}`, value); }
    SetOutBlueGain = (out_idx: stringable, value: stringable) => { this.setStatus(`D50.${out_idx}`, value); }
    SetInPhase = (in_idx: stringable, value: stringable) => { this.setStatus(`D52.${in_idx}`, value); }
    SetInClock = (in_idx: stringable, value: stringable) => { this.setStatus(`D54.${in_idx}`, value); }
    SetInHPosition = (in_idx: stringable, value: stringable) => { this.setStatus(`D56.${in_idx}`, value); }
    SetInVPosition = (in_idx: stringable, value: stringable) => { this.setStatus(`D58.${in_idx}`, value); }
    SetInCorrectTiming = (in_idx: stringable, value: stringable) => { this.setStatus(`D69.${in_idx}`, value); }
    SetOut4K2KDownscaleMode = (out_idx: stringable, value: stringable) => { this.setStatus(`D75.${out_idx}`, value); }
    SetOut4K2KUpscaleMode = (out_idx: stringable, value: stringable) => { this.setStatus(`D77.${out_idx}`, value); }
    SetInHActive = (in_idx: stringable, value: stringable) => { this.setStatus(`S17.${in_idx}`, value); }
    SetInVActive = (in_idx: stringable, value: stringable) => { this.setStatus(`S18.${in_idx}`, value); }
    SetInRefreshRate = (in_idx: stringable, value: stringable) => { this.setStatus(`S19.${in_idx}`, value); }
    SetInInterlace = (in_idx: stringable, value: stringable) => { this.setStatus(`S20.${in_idx}`, value); }
    SetInSyncStatus = (in_idx: stringable, value: stringable) => { this.setStatus(`S21.${in_idx}`, value); }
    SetInDeepColor = (in_idx: stringable, value: stringable) => { this.setStatus(`S27.${in_idx}`, value); }
    SetOutSyncStatus = (out_idx: stringable, value: stringable) => { this.setStatus(`S22.${out_idx}`, value); }
    SetOutDeepColor = (out_idx: stringable, value: stringable) => { this.setStatus(`S29.${out_idx}`, value); }
    SetOutColorSpace = (out_idx: stringable, value: stringable) => { this.setStatus(`S31.${out_idx}`, value); }
    SetOutHActive = (out_idx: stringable, value: stringable) => { this.setStatus(`S32.${out_idx}`, value); }
    SetOutVActive = (out_idx: stringable, value: stringable) => { this.setStatus(`S33.${out_idx}`, value); }
    SetOutRefreshRate = (out_idx: stringable, value: stringable) => { this.setStatus(`S34.${out_idx}`, value); }
    SetOutInterlace = (out_idx: stringable, value: stringable) => { this.setStatus(`S35.${out_idx}`, value); }
    SetInType = (in_idx: stringable, value: stringable) => { this.setStatus(`S36.${in_idx}`, value); }
    SetOutType = (out_idx: stringable, value: stringable) => { this.setStatus(`S37.${out_idx}`, value); }
    SetWindowLayoutMode = (value: stringable) => { this.setStatus('D2', value); }
    SetDisplayMode = (value: stringable) => { this.setStatus('D10', value); }
    SetHPIPSize = (value: stringable) => { this.setStatus('D12', value); }
    SetVPIPSize = (value: stringable) => { this.setStatus('D14', value); }
    SetHPIPPosition = (value: stringable) => { this.setStatus('D16', value); }
    SetVPIPPosition = (value: stringable) => { this.setStatus('D17', value); }
    // parameter getters
    OutTimingParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('D4', 'x1'), n1: this.searchParam('D4', 'n1') }; }
    WindowLayoutModeParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('D2', 'n1') }; }
    DisplayModeParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('D10', 'n1') }; }
    HPIPSizeParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('D12', 'n1') }; }
    VPIPSizeParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('D14', 'n1') }; }
    HPIPPositionParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('D16', 'n1') }; }
    VPIPPositionParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('D17', 'n1') }; }
    WindowBorderSizeParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('D20', 'n1'), n2: this.searchParam('D20', 'n2') }; }
    WindowRouteParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('D22', 'n1'), n2: this.searchParam('D22', 'n2') }; }
    WindowBorderColorParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('D24', 'n1'), n2: this.searchParam('D24', 'n2') }; }
    OutContrastParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('D29', 'x1'), n1: this.searchParam('D29', 'n1') }; }
    OutBrightnessParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('D31', 'x1'), n1: this.searchParam('D31', 'n1') }; }
    OutSaturationParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('D33', 'x1'), n1: this.searchParam('D33', 'n1') }; }
    OutHueParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('D35', 'x1'), n1: this.searchParam('D35', 'n1') }; }
    OutSharpnessParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('D37', 'x1'), n1: this.searchParam('D37', 'n1') }; }
    OutAspectRatioParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('D41', 'x1'), n1: this.searchParam('D41', 'n1') }; }
    OutAutoSyncOffParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('D44', 'x1'), n1: this.searchParam('D44', 'n1') }; }
    OutRedGainParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('D46', 'x1'), n1: this.searchParam('D46', 'n1') }; }
    OutGreenGainParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('D48', 'x1'), n1: this.searchParam('D48', 'n1') }; }
    OutBlueGainParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('D50', 'x1'), n1: this.searchParam('D50', 'n1') }; }
    InPhaseParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('D52', 'n1'), n2: this.searchParam('D52', 'n2') }; }
    InClockParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('D54', 'n1'), n2: this.searchParam('D54', 'n2') }; }
    InHPositionParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('D56', 'n1'), n2: this.searchParam('D56', 'n2') }; }
    InVPositionParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('D58', 'n1'), n2: this.searchParam('D58', 'n2') }; }
    InCorrectTimingParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('D69', 'n1'), n2: this.searchParam('D69', 'n2') }; }
    Out4K2KDownscaleModeParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('D75', 'x1'), n1: this.searchParam('D75', 'n1') }; }
    Out4K2KUpscaleModeParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('D77', 'x1'), n1: this.searchParam('D77', 'n1') }; }
    InHActiveParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('S17', 'n1'), n2: this.searchParam('S17', 'n2') }; }
    InVActiveParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('S18', 'n1'), n2: this.searchParam('S18', 'n2') }; }
    InRefreshRateParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('S19', 'n1'), n2: this.searchParam('S19', 'n2') }; }
    InInterlaceParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('S20', 'n1'), n2: this.searchParam('S20', 'n2') }; }
    InSyncStatusParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('S21', 'n1'), n2: this.searchParam('S21', 'n2') }; }
    InTimingParam = (): { n1: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { n1: this.searchParam('S25', 'n1'), s1: this.searchParam('S25', 's1') }; }
    InDeepColorParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('S27', 'n1'), n2: this.searchParam('S27', 'n2') }; }
    OutSyncStatusParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('S22', 'x1'), n1: this.searchParam('S22', 'n1') }; }
    OutDeepColorParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('S29', 'x1'), n1: this.searchParam('S29', 'n1') }; }
    OutColorSpaceParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('S31', 'x1'), n1: this.searchParam('S31', 'n1') }; }
    OutHActiveParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('S32', 'x1'), n1: this.searchParam('S32', 'n1') }; }
    OutVActiveParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('S33', 'x1'), n1: this.searchParam('S33', 'n1') }; }
    OutRefreshRateParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('S34', 'x1'), n1: this.searchParam('S34', 'n1') }; }
    OutInterlaceParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('S35', 'x1'), n1: this.searchParam('S35', 'n1') }; }
    InTypeParam = (): { n1: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { n1: this.searchParam('S36', 'n1'), s1: this.searchParam('S36', 's1') }; }
    OutTypeParam = (): { x1: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { x1: this.searchParam('S37', 'x1'), s1: this.searchParam('S37', 's1') }; }
    // support getter
    IsSupportTimingSwitch = () => { return this.isSupport(['D4']); }
    IsSupportWindowLayoutMode = () => { return this.isSupport(['D2']); }
    IsSupportDisplayMode = () => { return this.isSupport(['D10']); }
    IsSupportPIP = () => { return this.isSupport(['D12', 'D14', 'D16', 'D17']); }
    IsSupportWindowBorderConfiguration = () => { return this.isSupport(['D20', 'D24']); }
    IsSupportWindowRoute = () => { return this.isSupport(['D22']); }
    IsSupportContrast = () => { return this.isSupport(['D29']); }
    IsSupportBrightness = () => { return this.isSupport(['D31']); }
    IsSupportSaturation = () => { return this.isSupport(['D33']); }
    IsSupportHue = () => { return this.isSupport(['D35']); }
    IsSupportSharpness = () => { return this.isSupport(['D37']); }
    IsSupportAspectRatio = () => { return this.isSupport(['D41']); }
    IsSupportAutoSyncOff = () => { return this.isSupport(['D44']); }
    IsSupportRGBGain = () => { return this.isSupport(['D46', 'D48', 'D50']); }
    IsSupportInPhase = () => { return this.isSupport(['D52']); }
    IsSupportInClock = () => { return this.isSupport(['D54']); }
    IsSupportInPosition = () => { return this.isSupport(['D56', 'D58']); }
    IsSupportInVGASetting = () => { return this.IsSupportInClock() || this.IsSupportInClock() || this.IsSupportInPosition(); }
    IsSupportInCorrectTiming = () => { return this.isSupport(['D69']); }
    IsSupport4k2kDownscale = () => { return this.isSupport(['D75']); }
    IsSupport4k2kUpscale = () => { return this.isSupport(['D77']); }
    IsSupportInResolutionRead = () => { return this.isSupport(['S17', 'S18', 'S19', 'S20']); }
    IsSupportInSyncStatus = () => { return this.isSupport(['S21']); }
    IsSupportOutSyncStatus = () => { return this.isSupport(['S22']); }
    IsSupportOutColorSpace = () => { return this.isSupport(['S31']); }
    IsSupportOutResolution = () => { return this.isSupport(['S32', 'S33', 'S34', 'S35']); }
    IsSupportInType = () => { return this.isSupport(['S36']); }
    IsSupportOutType = () => { return this.isSupport(['S37']); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('scaler_control');
            }
        }
    }
}

export class CsxVideoWallDevice extends CsxFeatureDevice {
    VideoWallPreset = (prst_idx: stringable) => { return this.getStatus(`E13.${prst_idx}`); }
    WarpInTopLeft = (in_idx: stringable) => { return this.getStatus(`E43.${in_idx}`); }
    WarpInTopRight = (in_idx: stringable) => { return this.getStatus(`E45.${in_idx}`); }
    WarpInBottomLeft = (in_idx: stringable) => { return this.getStatus(`E47.${in_idx}`); }
    WarpInBottomRight = (in_idx: stringable) => { return this.getStatus(`E49.${in_idx}`); }
    WarpInVStart = (in_idx: stringable) => { return this.getStatus(`E65.${in_idx}`); }
    WarpInHStart = (in_idx: stringable) => { return this.getStatus(`E67.${in_idx}`); }
    WarpInWidth = (in_idx: stringable) => { return this.getStatus(`E69.${in_idx}`); }
    WarpInHeight = (in_idx: stringable) => { return this.getStatus(`E71.${in_idx}`); }
    WarpOutTopLeft = (in_idx: stringable) => { return this.getStatus(`E51.${in_idx}`); }
    WarpOutTopRight = (in_idx: stringable) => { return this.getStatus(`E53.${in_idx}`); }
    WarpOutBottomLeft = (in_idx: stringable) => { return this.getStatus(`E55.${in_idx}`); }
    WarpOutBottomRight = (in_idx: stringable) => { return this.getStatus(`E57.${in_idx}`); }
    VideoWallLayout = () => { return this.getStatus('E2'); }
    VideoWallHBezel = () => { return this.getStatus('E4'); }
    VideoWallVBezel = () => { return this.getStatus('E6'); }
    VideoWallBezelMode = () => { return this.getStatus('E8'); }
    VideoWallUnitIndex = () => { return this.getStatus('E10'); }
    VideoWallInHStart = () => { return this.getStatus('E15'); }
    VideoWallInVStart = () => { return this.getStatus('E17'); }
    VideoWallInHSize = () => { return this.getStatus('E19'); }
    VideoWallInVSize = () => { return this.getStatus('E21'); }
    VideoWallOutHStart = () => { return this.getStatus('E23'); }
    VideoWallOutVStart = () => { return this.getStatus('E25'); }
    VideoWallOutHSize = () => { return this.getStatus('E27'); }
    VideoWallOutVSize = () => { return this.getStatus('E29'); }
    VideoWallTopBezel = () => { return this.getStatus('E31'); }
    VideoWallBottomBezel = () => { return this.getStatus('E33'); }
    VideoWallLeftBezel = () => { return this.getStatus('E35'); }
    VideoWallRightBezel = () => { return this.getStatus('E37'); }
    DeviceOperationMode = () => { return this.getStatus('E39'); }
    WarpingMode = () => { return this.getStatus('E41'); }
    WarpingKeepAR = () => { return this.getStatus('E73'); }
    WarpingAngle = () => { return this.getStatus('E59'); }
    RotateAngle = () => { return this.getStatus('E61'); }
    ContinueRotateMode = () => { return this.getStatus('E63'); }
    // set function 
    SetVideoWallPreset = (value: stringable) => { this.setStatus(`E13`, value); }
    SetWarpInTopLeft = (in_idx: stringable, value: stringable) => { this.setStatus(`E43.${in_idx}`, value); }
    SetWarpInTopRight = (in_idx: stringable, value: stringable) => { this.setStatus(`E45.${in_idx}`, value); }
    SetWarpInBottomLeft = (in_idx: stringable, value: stringable) => { this.setStatus(`E47.${in_idx}`, value); }
    SetWarpInBottomRight = (in_idx: stringable, value: stringable) => { this.setStatus(`E49.${in_idx}`, value); }
    SetWarpInVStart = (in_idx: stringable, value: stringable) => { this.setStatus(`E65.${in_idx}`, value); }
    SetWarpInHStart = (in_idx: stringable, value: stringable) => { this.setStatus(`E67.${in_idx}`, value); }
    SetWarpInWidth = (in_idx: stringable, value: stringable) => { this.setStatus(`E69.${in_idx}`, value); }
    SetWarpInHeight = (in_idx: stringable, value: stringable) => { this.setStatus(`E71.${in_idx}`, value); }
    SetWarpOutTopLeft = (in_idx: stringable, value: stringable) => { this.setStatus(`E51.${in_idx}`, value); }
    SetWarpOutTopRight = (in_idx: stringable, value: stringable) => { this.setStatus(`E53.${in_idx}`, value); }
    SetWarpOutBottomLeft = (in_idx: stringable, value: stringable) => { this.setStatus(`E55.${in_idx}`, value); }
    SetWarpOutBottomRight = (in_idx: stringable, value: stringable) => { this.setStatus(`E57.${in_idx}`, value); }
    SetVideoWallLayout = (value: stringable) => { this.setStatus('E2', value); }
    SetVideoWallHBezel = (value: stringable) => { this.setStatus('E4', value); }
    SetVideoWallVBezel = (value: stringable) => { this.setStatus('E6', value); }
    SetVideoWallBezelMode = (value: stringable) => { this.setStatus('E8', value); }
    SetVideoWallUnitIndex = (value: stringable) => { this.setStatus('E10', value); }
    SetVideoWallInHStart = (value: stringable) => { this.setStatus('E15', value); }
    SetVideoWallInVStart = (value: stringable) => { this.setStatus('E17', value); }
    SetVideoWallInHSize = (value: stringable) => { this.setStatus('E19', value); }
    SetVideoWallInVSize = (value: stringable) => { this.setStatus('E21', value); }
    SetVideoWallOutHStart = (value: stringable) => { this.setStatus('E23', value); }
    SetVideoWallOutVStart = (value: stringable) => { this.setStatus('E25', value); }
    SetVideoWallOutHSize = (value: stringable) => { this.setStatus('E27', value); }
    SetVideoWallOutVSize = (value: stringable) => { this.setStatus('E29', value); }
    SetVideoWallTopBezel = (value: stringable) => { this.setStatus('E31', value); }
    SetVideoWallBottomBezel = (value: stringable) => { this.setStatus('E33', value); }
    SetVideoWallLeftBezel = (value: stringable) => { this.setStatus('E35', value); }
    SetVideoWallRightBezel = (value: stringable) => { this.setStatus('E37', value); }
    SetDeviceOperationMode = (value: stringable) => { this.setStatus('E39', value); }
    SetWarpingMode = (value: stringable) => { this.setStatus('E41', value); }
    SetWarpingKeepAR = (value: stringable) => { this.setStatus('E73', value); }
    SetWarpingAngle = (value: stringable) => { this.setStatus('E59', value); }
    SetRotateAngle = (value: stringable) => { this.setStatus('E61', value); }
    SetContinueRotateMode = (value1: stringable, value2: stringable) => { this.setStatus('E63', `${value1} ${value2}`); }
    SetVideoWallDefault = () => { this.trigger('E11\n');}
    // parameter getters
    VideoWallPresetParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E13', 'n1') }; }
    WarpInTopLeftParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM; n3: CSX_CMD_PARAM } => { return { n1: this.searchParam('E43', 'n1'), n2: this.searchParam('E43', 'n2'), n3: this.searchParam('E43', 'n3') }; }
    WarpInTopRightParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM; n3: CSX_CMD_PARAM } => { return { n1: this.searchParam('E45', 'n1'), n2: this.searchParam('E45', 'n2'), n3: this.searchParam('E45', 'n3') }; }
    WarpInBottomLeftParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM; n3: CSX_CMD_PARAM } => { return { n1: this.searchParam('E47', 'n1'), n2: this.searchParam('E47', 'n2'), n3: this.searchParam('E47', 'n3') }; }
    WarpInBottomRightParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM; n3: CSX_CMD_PARAM } => { return { n1: this.searchParam('E49', 'n1'), n2: this.searchParam('E49', 'n2'), n3: this.searchParam('E49', 'n3') }; }
    WarpInVStartParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('E65', 'n1'), n2: this.searchParam('E65', 'n2') }; }
    WarpInHStartParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('E67', 'n1'), n2: this.searchParam('E67', 'n2') }; }
    WarpInWidthParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('E69', 'n1'), n2: this.searchParam('E69', 'n2') }; }
    WarpInHeightParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('E71', 'n1'), n2: this.searchParam('E71', 'n2') }; }
    WarpOutTopLeftParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM; n3: CSX_CMD_PARAM } => { return { n1: this.searchParam('E51', 'n1'), n2: this.searchParam('E51', 'n2'), n3: this.searchParam('E51', 'n3') }; }
    WarpOutTopRightParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM; n3: CSX_CMD_PARAM } => { return { n1: this.searchParam('E53', 'n1'), n2: this.searchParam('E53', 'n2'), n3: this.searchParam('E53', 'n3') }; }
    WarpOutBottomLeftParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM; n3: CSX_CMD_PARAM } => { return { n1: this.searchParam('E55', 'n1'), n2: this.searchParam('E55', 'n2'), n3: this.searchParam('E55', 'n3') }; }
    WarpOutBottomRightParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM; n3: CSX_CMD_PARAM } => { return { n1: this.searchParam('E57', 'n1'), n2: this.searchParam('E57', 'n2'), n3: this.searchParam('E57', 'n3') }; }
    VideoWallLayoutParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('E2', 'n1'), n2: this.searchParam('E2', 'n2') }; }
    VideoWallHBezelParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E4', 'n1') }; }
    VideoWallVBezelParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E6', 'n1') }; }
    VideoWallBezelModeParam = (): { b1: CSX_CMD_PARAM } => { return { b1: this.searchParam('E8', 'b1') }; }
    VideoWallUnitIndexParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E10', 'n1') }; }
    VideoWallInHStartParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E15', 'n1') }; }
    VideoWallInVStartParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E17', 'n1') }; }
    VideoWallInHSizeParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E19', 'n1') }; }
    VideoWallInVSizeParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E21', 'n1') }; }
    VideoWallOutHStartParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E23', 'n1') }; }
    VideoWallOutVStartParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E25', 'n1') }; }
    VideoWallOutHSizeParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E27', 'n1') }; }
    VideoWallOutVSizeParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E29', 'n1') }; }
    VideoWallTopBezelParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E31', 'n1') }; }
    VideoWallBottomBezelParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E33', 'n1') }; }
    VideoWallLeftBezelParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E35', 'n1') }; }
    VideoWallRightBezelParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E37', 'n1') }; }
    WarpingModeParam = (): { b1: CSX_CMD_PARAM } => { return { b1: this.searchParam('E41', 'b1') }; }
    WarpingKeepARParam = (): { b1: CSX_CMD_PARAM } => { return { b1: this.searchParam('E73', 'b1') }; }
    WarpingAngleParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E59', 'n1') }; }
    RotateAngleParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('E61', 'n1') }; }
    ContinueRotateModeParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('E63', 'n1'), n2: this.searchParam('E63', 'n2') }; }
    // support getter
    IsSupportVWPreset = () => { return this.isSupport(['E13']); }
    IsSupportSourceWarp = () => { return this.isSupport(['E43', 'E45', 'E47', 'E49', 'E65', 'E67', 'E69', 'E71']); }
    IsSupportOutputWarp = () => { return this.isSupport(['E51', 'E53', 'E55', 'E57']); }
    IsSupportVWConfiguration = () => { return this.isSupport(['E2', 'E4', 'E6']); }
    IsSupportVWBezelMode = () => { return this.isSupport(['E8']); }
    IsSupportVWUnitIndex = () => { return this.isSupport(['E10']); }
    IsSupportVWLayoutDefault = () => { return this.inAllIndex(['E11']); }
    IsSupportInHStart = () => { return this.isSupport(['E15']); }
    IsSupportInVStart = () => { return this.isSupport(['E17']); }
    IsSupportInHSize = () => { return this.isSupport(['E19']); }
    IsSupportInVSize = () => { return this.isSupport(['E21']); }
    IsSupportTopBezel = () => { return this.isSupport(['E31']); }
    IsSupportBottomBezel = () => { return this.isSupport(['E33']); }
    IsSupportLeftBezel = () => { return this.isSupport(['E35']); }
    IsSupportRightBezel = () => { return this.isSupport(['E37']); }
    IsSupportWarpMode = () => { return this.isSupport(['E41']); }
    IsSupportWarpKeepAR = () => { return this.isSupport(['E73']); }
    IsSupportWarpAngle = () => { return this.isSupport(['E59']); }
    IsSupportRotate = () => { return this.isSupport(['E61']); }
    IsSupportContinuedRotate = () => { return this.isSupport(['E63']); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('videoWall_control');
            }
        }
    }
}

export class CsxOSDDevice extends CsxFeatureDevice {
    OutOSDTimeout = (out_idx: stringable) => { return this.getStatus(`G2.${out_idx}`); }
    OutOSDInfoDisplay = (out_idx: stringable) => { return this.getStatus(`G4.${out_idx}`); }
    OutOSDInfoTimeout = (out_idx: stringable) => { return this.getStatus(`G6.${out_idx}`); }
    OutOSDVPosition = (out_idx: stringable) => { return this.getStatus(`G8.${out_idx}`); }
    OutOSDHPosition = (out_idx: stringable) => { return this.getStatus(`G10.${out_idx}`); }
    OutOSDTransparency = (out_idx: stringable) => { return this.getStatus(`G12.${out_idx}`); }
    OutOSDTransparencyLevel = (out_idx: stringable) => { return this.getStatus(`G14.${out_idx}`); }
    OutOSD = (out_idx: stringable) => { return this.getStatus(`G16.${out_idx}`); }
    OutBannerText = (out_idx: stringable) => { return this.getStatus(`G18.${out_idx}`); }
    OutBannerSrc = (out_idx: stringable) => { return this.getStatus(`G20.${out_idx}`); }
    OutBannerLocation = (out_idx: stringable) => { return this.getStatus(`G22.${out_idx}`); }
    OutBannerFontSize = (out_idx: stringable) => { return this.getStatus(`G25.${out_idx}`); }
    OutBannerFontColor = (out_idx: stringable) => { return this.getStatus(`G27.${out_idx}`); }
    OutBannerFontTransparencyLevel = (out_idx: stringable) => { return this.getStatus(`G30.${out_idx}`); }
    OutOSDBackgroundColor = (out_idx: stringable) => { return this.getStatus(`G32.${out_idx}`); }
    OutOSDLogoDisplay = (out_idx: stringable) => { return this.getStatus(`G35.${out_idx}`); }
    OutOSDLogoHPosition = (out_idx: stringable) => { return this.getStatus(`G37.${out_idx}`); }
    OutOSDLogoVPosition = (out_idx: stringable) => { return this.getStatus(`G39.${out_idx}`); }
    OutCountdownTimer = (out_idx: stringable) => { return this.getStatus(`G41.${out_idx}`); }
    // set function
    SetOutOSDTimeout = (out_idx: stringable, value: stringable) => { this.setStatus(`G2.${out_idx}`, value); }
    SetOutOSDInfoDisplay = (out_idx: stringable, value: stringable) => { this.setStatus(`G4.${out_idx}`, value); }
    SetOutOSDInfoTimeout = (out_idx: stringable, value: stringable) => { this.setStatus(`G6.${out_idx}`, value); }
    SetOutOSDVPosition = (out_idx: stringable, value: stringable) => { this.setStatus(`G8.${out_idx}`, value); }
    SetOutOSDHPosition = (out_idx: stringable, value: stringable) => { this.setStatus(`G10.${out_idx}`, value); }
    SetOutOSDTransparency = (out_idx: stringable, value: stringable) => { this.setStatus(`G12.${out_idx}`, value); }
    SetOutOSDTransparencyLevel = (out_idx: stringable, value: stringable) => { this.setStatus(`G14.${out_idx}`, value); }
    SetOutOSD = (out_idx: stringable, value: stringable) => { this.setStatus(`G16.${out_idx}`, value); }
    SetOutBannerText = (out_idx: stringable, value: stringable) => { this.setStatus(`G18.${out_idx}`, value); }
    SetOutBannerSrc = (out_idx: stringable, value: stringable) => { this.setStatus(`G20.${out_idx}`, value); }
    SetOutBannerLocation = (out_idx: stringable, value: stringable) => { this.setStatus(`G22.${out_idx}`, value); }
    SetOutBannerFontSize = (out_idx: stringable, value: stringable) => { this.setStatus(`G25.${out_idx}`, value); }
    SetOutBannerFontColor = (out_idx: stringable, value: stringable) => { this.setStatus(`G27.${out_idx}`, value); }
    SetOutBannerFontTransparencyLevel = (out_idx: stringable, value: stringable) => { this.setStatus(`G30.${out_idx}`, value); }
    SetOutOSDBackgroundColor = (out_idx: stringable, value: stringable) => { this.setStatus(`G32.${out_idx}`, value); }
    SetOutOSDLogoDisplay = (out_idx: stringable, value: stringable) => { this.setStatus(`G35.${out_idx}`, value); }
    SetOutOSDLogoHPosition = (out_idx: stringable, value: stringable) => { this.setStatus(`G37.${out_idx}`, value); }
    SetOutOSDLogoVPosition = (out_idx: stringable, value: stringable) => { this.setStatus(`G39.${out_idx}`, value); }
    SetOutCountdownTimer = (out_idx: stringable, value: stringable) => { this.setStatus(`G41.${out_idx}`, value); }
    // parameter getters
    OutOSDTimeoutParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G2', 'x1'), n1: this.searchParam('G2', 'n1') }; }
    OutOSDInfoDisplayParam = (): { x1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G4', 'x1'), b1: this.searchParam('G4', 'b1') }; }
    OutOSDInfoTimeoutParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G6', 'x1'), n1: this.searchParam('G6', 'n1') }; }
    OutOSDVPositionParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G8', 'x1'), n1: this.searchParam('G8', 'n1') }; }
    OutOSDHPositionParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G10', 'x1'), n1: this.searchParam('G10', 'n1') }; }
    OutOSDTransparencyParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G12', 'x1'), n1: this.searchParam('G12', 'n1') }; }
    OutOSDTransparencyLevelParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G14', 'x1'), n1: this.searchParam('G14', 'n1') }; }
    OutOSDParam = (): { x1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G16', 'x1'), b1: this.searchParam('G16', 'b1') }; }
    OutBannerTextParam = (): { x1: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G18', 'x1'), s1: this.searchParam('G18', 's1') }; }
    OutBannerSrcParam = (): { x1: CSX_CMD_PARAM; nn: CSX_CMD_PARAM; 'nn.n': CSX_CMD_PARAM } => { return { x1: this.searchParam('G20', 'x1'), nn: this.searchParam('G20', 'nn'), 'nn.n': this.searchParam('G20', 'nn.n') }; }
    OutBannerLocationParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G22', 'x1'), n1: this.searchParam('G22', 'n1') }; }
    OutBannerFontSizeParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G25', 'x1'), n1: this.searchParam('G25', 'n1') }; }
    OutBannerFontColorParam = (): { x1: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G27', 'x1'), s1: this.searchParam('G27', 's1') }; }
    OutBannerFontTransparencyLevelParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G30', 'x1'), n1: this.searchParam('G30', 'n1') }; }
    OutOSDBackgroundColorParam = (): { x1: CSX_CMD_PARAM; s1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G32', 'x1'), s1: this.searchParam('G32', 's1') }; }
    OutOSDLogoDisplayParam = (): { x1: CSX_CMD_PARAM; b1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G35', 'x1'), b1: this.searchParam('G35', 'b1') }; }
    OutOSDLogoHPositionParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G37', 'x1'), n1: this.searchParam('G37', 'n1') }; }
    OutOSDLogoVPositionParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G39', 'x1'), n1: this.searchParam('G39', 'n1') }; }
    OutCountdownTimerParam = (): { x1: CSX_CMD_PARAM; n1: CSX_CMD_PARAM } => { return { x1: this.searchParam('G41', 'x1'), n1: this.searchParam('G41', 'n1') }; }
    // support getter
    IsSupportOSDTimeout = () => { return this.isSupport(['G2']); }
    IsSupportOSDInfoDisplay = () => { return this.isSupport(['G4']); }
    IsSupportOSDInfoTimout = () => { return this.isSupport(['G6']); }
    IsSupportOSDPosition = () => { return this.isSupport(['G8', 'G10']); }
    IsSupportOSDTransparency = () => { return this.isSupport(['G12']); }
    IsSupportOSDTransparencyLevel = () => { return this.isSupport(['G14']); }
    IsSupportOSDEnable = () => { return this.isSupport(['G16']); }
    IsSupportBanner = () => { return  this.IsSupportBannerEdit() || this.IsSupportBannerSourceSelect() || this.IsSupportBannerLocation() || this.IsSupportBannerFontSize() || this.IsSupportBannerFontColor() || this.IsSupportBannerFontTransparencyLevel(); }
    IsSupportBannerEdit = () => { return this.isSupport(['G18']); }
    IsSupportBannerSourceSelect = () => { return this.isSupport(['G20']); }
    IsSupportBannerLocation = () => { return this.isSupport(['G22']); }
    IsSupportBannerFontSize = () => { return this.isSupport(['G25']); }
    IsSupportBannerFontColor = () => { return this.isSupport(['G27']); }
    IsSupportBannerFontTransparencyLevel = () => { return this.isSupport(['G30']); }
    IsSupportOSDBackgroundColor = () => { return this.isSupport(['G32']); }
    IsSupportLogoDisplay = () => { return this.isSupport(['G35']); }
    IsSupportLogoPosition = () => { return this.isSupport(['G37', 'G39']); }
    IsSupportCountdownTimer = () => { return this.isSupport(['G41']); }
    // upload function
    UploadBannerText = () => { this.upload(['G17']); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('osd_control');
            }
        }
    }
}

export class CsxCableTestDevice extends CsxFeatureDevice {
    InHDBTData = (in_idx: stringable) => { return this.getStatus(`N9.${in_idx}`); }
    OutHDBTData = (out_idx: stringable) => { return this.getStatus(`N10.${out_idx}`); }
    // set function
    SetInHDBTData = (in_idx: stringable, value: stringable) => { this.setStatus(`N9.${in_idx}`, value); }
    SetOutHDBTData = (out_idx: stringable, value: stringable) => { this.setStatus(`N10.${out_idx}`, value); }
    // parameter getters
    InHDBTDataParam = (): { n1: CSX_CMD_PARAM; hh: CSX_CMD_PARAM } => { return { n1: this.searchParam('N9', 'n1'), hh: this.searchParam('N9', 'hh') }; }
    OutHDBTDataParam = (): { x1: CSX_CMD_PARAM; hh: CSX_CMD_PARAM } => { return { x1: this.searchParam('N10', 'x1'), hh: this.searchParam('N10', 'hh') }; }
    // support getter
    IsSupportSourceAnalyze = () => { return this.isSupport(['N9']); }
    IsSupportOutputAnalyze = () => { return this.isSupport(['N10']); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('ct_control');
            }
        }
    }
}

export class CsxPowerMonitorDevice extends CsxFeatureDevice {
    InPowerCurrent = (in_idx: stringable) => { return this.getStatus(`R1.${in_idx}`); }
    InPowerVoltage = (in_idx: stringable) => { return this.getStatus(`R2.${in_idx}`); }
    InTemperature = (in_idx: stringable) => { return this.getStatus(`R3.${in_idx}`); }
    // set function
    SetInPowerCurrent = (in_idx: stringable, value: stringable) => { this.setStatus(`R1.${in_idx}`, value); }
    SetInPowerVoltage = (in_idx: stringable, value: stringable) => { this.setStatus(`R2.${in_idx}`, value); }
    SetInTemperature = (in_idx: stringable, value: stringable) => { this.setStatus(`R3.${in_idx}`, value); }
    // parameter getters
    InPowerCurrentParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('R1', 'n1'), n2: this.searchParam('R1', 'n2') }; }
    InPowerVoltageParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('R2', 'n1'), n2: this.searchParam('R2', 'n2') }; }
    InTemperatureParam = (): { n1: CSX_CMD_PARAM; n2: CSX_CMD_PARAM } => { return { n1: this.searchParam('R3', 'n1'), n2: this.searchParam('R3', 'n2') }; }
    // support getter
    IsSupportInPowerCurrent = () => { return this.isSupport(['R1']); }
    IsSupportInPowerVoltage = () => { return this.isSupport(['R2']); }
    IsSupportInTemperature = () => { return this.isSupport(['R3']); }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('pm_control');
            }
        }
    }
}

export class CsxTimeManageDevice extends CsxFeatureDevice {
    private _timer?: NodeJS.Timeout;
    private consoleCounter: number = 0;
    WebsiteTimelapse = () => { return this.instance.ONLINE_TIME; }
    Date = () => {
        if (this.IsSupportDirectGetDate()) return this.getStatus(`M75`);
        if (this.IsSupportInDirectGetDate()) return `${this.getStatus('M61')}.${this.getStatus('M63')}.${this.getStatus('M65')}`;
        return '';
    }
    Time = () => {
        if (this.IsSupportDirectGetTime()) return this.getStatus(`M77`);
        if (this.IsSupportInDirectGetTime()) return `${this.getStatus('M69')}:${this.getStatus('M71')}:${this.getStatus('M73')}`;
        return '';
    }
    Timezone = () => { return this.getStatus(`M103`); }
    TimeOffset = () => { return this.getStatus(`M106`); }
    NTPServerUrl = () => { return this.getStatus(`M108`); }
    NTPTimeSyncDuration = () => { return this.getStatus(`M110`); }
    // set function
    SetDate = (year: stringable, month: stringable, date: stringable) => {
        if (this.IsSupportDirectGetDate()) {
            this.setStatus(`M75`, `${year}.${month}.${date}`);
            return;
        }
        if (this.IsSupportInDirectGetDate()) {
            this.setStatus(`M61`, year);
            this.setStatus(`M63`, month);
            this.setStatus(`M65`, date);
            return;
        }
        return;
    }
    SetTime = (hour: stringable, min: stringable, sec: stringable) => {
        if (this.IsSupportDirectGetTime()) {
            this.setStatus(`M77`, `${hour}:${min}:${sec}`);
            return;
        }
        if (this.IsSupportInDirectGetTime()) {
            this.setStatus(`M69`, hour);
            this.setStatus(`M71`, min);
            this.setStatus(`M73`, sec);
            return;
        }
        return;
    }
    SetTimezone = (value: stringable) => { this.setStatus(`M102`, value); }
    SetTimeOffset = (value: stringable) => { this.setStatus(`M106`, value); }
    SetNTPServerUrl = (value: stringable) => { this.setStatus(`M108`, value); }
    SetNTPTimeSyncDuration = (value: stringable) => { this.setStatus(`M110`, value); }
    NTPTimeSynchronize = () => { this.trigger(`M111`); }
    QueryTime = () => {
        const cmds: Array<string> = [];
        if (window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                // window.FOCUS_GATEWAY.handleRequest(`?010:${this.consoleCounter++}=${msg}\n`);
                if (this.IsSupportDate()) {
                    if (this.IsSupportDirectGetDate()) {
                        cmds.push(`?010:${this.consoleCounter++}=get date`);
                    } else {
                        cmds.push(`?010:${this.consoleCounter++}=get year`);
                        cmds.push(`?010:${this.consoleCounter++}=get month`);
                        cmds.push(`?010:${this.consoleCounter++}=get day`);
                    }
                }
                if (this.IsSupportTime()) {
                    if (this.IsSupportDirectGetTime()) {
                        cmds.push(`?010:${this.consoleCounter++}=get time`);
                    } else {
                        cmds.push(`?010:${this.consoleCounter++}=get hour`);
                        cmds.push(`?010:${this.consoleCounter++}=get minute`);
                        cmds.push(`?010:${this.consoleCounter++}=get second`);
                    }
                }
            } else {
                // window.FOCUS_GATEWAY.handleRequest(`?009:${this.ID},${this.consoleCounter++}=${msg}\n`);
                if (this.IsSupportDate()) {
                    if (this.IsSupportDirectGetDate()) {
                        cmds.push(`?009:${this.ID},${this.consoleCounter++}=get date`);
                    } else {
                        cmds.push(`?009:${this.ID},${this.consoleCounter++}=get year`);
                        cmds.push(`?009:${this.ID},${this.consoleCounter++}=get month`);
                        cmds.push(`?009:${this.ID},${this.consoleCounter++}=get day`);
                    }
                }
                if (this.IsSupportTime()) {
                    if (this.IsSupportDirectGetTime()) {
                        cmds.push(`?009:${this.ID},${this.consoleCounter++}=get time`);
                    } else {
                        cmds.push(`?009:${this.ID},${this.consoleCounter++}=get hour`);
                        cmds.push(`?009:${this.ID},${this.consoleCounter++}=get minute`);
                        cmds.push(`?009:${this.ID},${this.consoleCounter++}=get second`);
                    }
                }
            }
            if (cmds.length > 0) {
                this.handleRequest(cmds.join('\n') + '\n', CsxUserPermissionLevel.ViewAssigned);
            }
        }
    }
    // parameter getters
    TimezoneParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('M102', 'n1') }; }
    TimeOffsetParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('M105', 'n1') }; }
    NTPServerUrlParam = (): { s1: CSX_CMD_PARAM } => { return { s1: this.searchParam('M107', 's1') }; }
    NTPTimeSyncDurationParam = (): { n1: CSX_CMD_PARAM } => { return { n1: this.searchParam('M109', 'n1') }; }
    // support getter
    IsSupportDate = () => { return (this.IsSupportDirectGetDate() || this.IsSupportInDirectGetDate()); }
    IsSupportTime = () => { return (this.IsSupportDirectGetTime() || this.IsSupportInDirectGetTime()); }
    IsSupportInDirectGetDate = () => { return this.isSupport([`M65`,`M63`,`M61`]); }
    IsSupportInDirectGetTime = () => { return this.isSupport([`M73`,`M71`,`M69`]); }
    IsSupportDirectGetDate = () => { return this.isSupport([`M75`]); }
    IsSupportDirectGetTime = () => { return this.isSupport([`M77`]); }
    IsSupportTimezone = () => { return this.isSupport([`M103`]); }
    IsSupportTimeOffset = () => { return this.isSupport([`M106`]); }
    IsSupportNTPServerUrl = () => { return this.isSupport([`M108`]); }
    IsSupportNTPTimeSyncDuration = () => { return this.isSupport([`M110`]); }
    IsSupportNTPTimeSynchronize = () => { return this.inAllIndex([`M111`]); }
    // special
    TimeFlowSimulateStart = () => {
        this.TimeFlowSimulateStop();
        global.setTimeout(this.QueryTime, 1000);
        this._timer = global.setInterval(() => { irqRefresh(['DeviceRealTime']); }, 1000);
    }
    TimeFlowSimulateStop = () => {
        if (this._timer) {
            global.clearInterval(this._timer);
        }
    }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('time_control');
            }
        }
    }
}

export class CsxDebugDevice extends CsxFeatureDevice { // special device
    private readonly POINTOUT_STYLE: string = 'font-weight:bold;font-size:16px';
    pass = 0;
    warn = 0;
    fail = 0;
    consoleCounter = 0;
    private echo = (msg: string) => {
        if (window.FOCUS_GATEWAY && window.FOCUS_GATEWAY.ID === this.ID) {
            irqRefresh(['GatewayMessageRealTime'], -1, msg + '\r');
        } else {
            irqRefresh(['MessageRealTime'], -1, msg + '\r');
        }
    } // seq === -1 means let message buffer choose a proper value
    StartDebug = (run_list: Array<boolean>) => {
        this.pass = 0;
        this.warn = 0;
        this.fail = 0;
        this.consoleCounter = 0;
        this.echo('Start CSX device debug...');
        [
            this.CheckParamComplete,
            this.CheckRegularExpression,
            this.CheckParamOption,
            this.CheckAudioBasic,
            this.CheckIPConfiguration,
            this.CheckAllParameter,
            this.CheckAllStatus,
        ].forEach((script, idx) => { if (run_list[idx]) script(); });
        this.echo(`\rFinish CSX device debug... ${CsxUtil.shellstring('WARNING', 'color:yellow')}=${this.warn}, ${CsxUtil.shellstring('FAIL', 'color:red')}=${this.fail}`);
    }
    CheckParamComplete = () => {
        const TAB = '\xa0\xa0\xa0\xa0';
        let perfect = true;
        this.echo(CsxUtil.shellstring('\r****Start checking parameters\'s completeness****', this.POINTOUT_STYLE));
        if (typeof this.status.A17 === 'string') {
            const all_support_cmd = new Set(this.status.A17.split(','));
            this.echo('\rDevice Support Commands = ' + this.status.A17);
            all_support_cmd.forEach(cmd => {
                if (CsxUtil.isCypStdCmdType(cmd)) {
                    const param = this.instance.PARAM[cmd];
                    const syntax_def = CYP_STD_PARA_SYNTAX_DEF[cmd];
                    let pass = true;
                    if (CMD_GET_TO_SET[cmd]) { // is get command
                        const data = this.status[cmd];
                        if (typeof data === 'undefined') { // is get command but no data return
                            this.echo(TAB + `Expect data of command "${cmd}", [${CsxUtil.shellstring('Not found!', 'color:yellow')}] ${(CMD_DESC[cmd]?CMD_DESC[cmd]:'')}`);
                            this.warn++;
                            perfect = false;
                            pass = false;
                        }
                        if (typeof param === 'undefined') {
                            this.echo(TAB + `Expect parameter of command "${cmd}", [${CsxUtil.shellstring('Not found!', 'color:yellow')}] ${(CMD_DESC[cmd]?CMD_DESC[cmd]:'')}`); 
                            this.warn++;
                            perfect = false;
                            pass = false;
                        }
                    }
                    if (typeof syntax_def !== 'undefined') {
                        if (param) {
                            const syntax_list = syntax_def.syntaxes(this.commandVersion.str);
                            syntax_list.forEach(syntax => {
                                const param_obj = CsxUtil.nestedGet(param, syntax);
                                if (typeof param_obj === 'undefined') {
                                    this.echo(TAB + CsxUtil.shellstring(`Set command "${cmd}" without parameter definition, definition: ${syntax_def.syntax(syntax, this.commandVersion.str)}`, 'color:red'));
                                    this.fail++;
                                    perfect = false;
                                    pass = false;
                                } else {
                                    const param_parse = this.searchParam(cmd, syntax);
                                    const syntax_type = syntax_def.syntax(syntax, this.commandVersion.str);
                                    if ((syntax_type === 'isArray' && typeof param_parse.isArray === 'undefined') ||
                                        (syntax_type === 'isFloat' && typeof param_parse.isFloat === 'undefined') ||
                                        (syntax_type === 'isString' && typeof param_parse.isString === 'undefined') ||
                                        (syntax_type === 'isOption' && typeof param_parse.isOption === 'undefined') ||
                                        (syntax_type === 'isRange' && typeof param_parse.isRange === 'undefined')
                                    ) {
                                        this.echo(TAB + `Expect syntax "${syntax}" of command "${cmd}" [${CsxUtil.shellstring(syntax_type, 'text-decoration:underline;font-weight:bold;')}], ${CsxUtil.shellstring('Wrong definition!!!', 'color:red')}`);
                                        this.echo(TAB + TAB + `${CsxUtil.shellstring('Device return', 'color:yellow;font-weight:bold;')} : ==================`);
                                        flatelize(param, '').forEach(path => { this.echo(TAB + TAB + '| ' + path); });
                                        this.echo(TAB + TAB + '==================================');
                                        this.fail++;
                                        perfect = false;
                                        pass = false;
                                    }
                                }
                            });
                        }
                    }

                    if (pass) {
                        // support = true;
                        this.echo(TAB + `"${cmd}", [${CsxUtil.shellstring('OK', 'color:green')}]`);
                    }
                }
            });
            if (perfect) this.echo(TAB + `...${CsxUtil.shellstring('PASS', 'color:green')}`);
        } else {
            this.echo(CsxUtil.shellstring('\rA17 NOT IMPLEMENT', 'font-size:18px;font-weight:bold;color:yellow'));
        }
    }
    CheckRegularExpression = () => {
        const TAB = '\xa0\xa0\xa0\xa0';
        let perfect = true;
        this.echo(CsxUtil.shellstring('\r****Start checking parameters\'s regular expression****', this.POINTOUT_STYLE));
        Object.keys(this.instance.PARAM).forEach(cmd => {
            if (CsxUtil.isCypStdCmdType(cmd)) {
                const param = this.instance.PARAM[cmd];
                let paramHasRegex = false;
                Object.keys(param).forEach(param_key => {
                    const tar = this.searchParam(cmd, param_key);
                    if (tar.isString && tar.isString.regex) {
                        const { regex, length } = tar.isString;
                        const tar_regex = regex.source;
                        const warnings: Array<string> = [];
                        const warn_ch_list: Array<string> = [];
                        if (tar_regex.indexOf('.') >= 0 && tar_regex.indexOf('\\.') < 0)
                            warn_ch_list.push('"."');
                        // if (tar_regex.indexOf('\\') >= 0 && tar_regex.indexOf('\\\\') < 0)
                        //     warn_ch_list.push('"\\"');
                        // if (tar_regex.indexOf('^') >= 0 && tar_regex.indexOf('\\^') < 0)
                        //     warn_ch_list.push('"^"');
                        if (tar_regex.indexOf('+') >= 0 && tar_regex.indexOf('\\+') < 0)
                            warn_ch_list.push('"+"');
                        if (tar_regex.indexOf('?') >= 0 && tar_regex.indexOf('\\?') < 0)
                            warn_ch_list.push('"?"');
                        if (tar_regex.indexOf('*') >= 0 && tar_regex.indexOf('\\*') < 0)
                            warn_ch_list.push('"*"');
                        if (warn_ch_list.length > 0)
                            warnings.push(`Special characeters { ${warn_ch_list.join(', ')} }`);
                        if (length.max === 0)
                            warnings.push(`Max string length is zero!`);
                        if (length.min === 0)
                            warnings.push(`Min string length is zero!`);

                        // output print
                        if (warnings.length !== 0) {
                            if (!paramHasRegex) this.echo(TAB + TAB + `[${cmd}]:`);
                            this.echo(TAB + TAB + TAB + `[${param_key}]====[${CsxUtil.shellstring('WARNING', 'color:yellow')}]:${tar_regex}`);
                            warnings.forEach(warn => { this.echo(TAB + TAB + TAB + TAB + warn); this.warn++; });
                            paramHasRegex = true;
                            perfect = false;
                        } else {
                            // this.echo(TAB + TAB + TAB + `[${param_key}]====[${CsxUtil.shellstring('OK', 'color:green')}]`);
                            // this.pass ++;
                        }
                    }
                })
            }
        });
        if (perfect) this.echo(TAB + `...${CsxUtil.shellstring('PASS', 'color:green')}`);
    }
    CheckParamOption = () => {
        const TAB = '\xa0\xa0\xa0\xa0';
        let perfect = true;
        this.echo(CsxUtil.shellstring('\r****Start checking parameters\'s option-desc pair****', this.POINTOUT_STYLE));
        Object.keys(this.instance.PARAM).forEach(cmd => {
            if (CsxUtil.isCypStdCmdType(cmd)) {
                const param = this.instance.PARAM[cmd];
                let paramHasOptionAndDesc = false;
                Object.keys(param).forEach(param_key => {
                    const tar = this.searchParam(cmd, param_key);
                    if (tar.isOption && tar.isOption.desc) {
                        const { option, desc } = tar.isOption;
                        if (option.length !== desc.length) {
                            if (!paramHasOptionAndDesc) this.echo(TAB + TAB + `[${cmd}]:`);
                            this.echo(TAB + TAB + TAB + `[${param_key}]====[${CsxUtil.shellstring('FAIL', 'color:red')}]:${JSON.stringify(param[param_key])}`);
                            this.echo(TAB + TAB + TAB + `Size not match!`);
                            this.fail++;
                            perfect = false;
                            paramHasOptionAndDesc = true;
                        } else if (option.length === 0) {
                            if (!paramHasOptionAndDesc) this.echo(TAB + TAB + `[${cmd}]:`);
                            this.echo(TAB + TAB + TAB + `[${param_key}]====[${CsxUtil.shellstring('WARNING', 'color:yellow')}]:${JSON.stringify(param[param_key])}`);
                            this.echo(TAB + TAB + TAB + `Option is empty`);
                            this.fail++;
                            perfect = false;
                            paramHasOptionAndDesc = true;
                        } else {
                            if (option.indexOf('') >= 0 || desc.indexOf('') >= 0) {
                                if (!paramHasOptionAndDesc) this.echo(TAB + TAB + `[${cmd}]:`);
                                this.echo(TAB + TAB + TAB + `[${param_key}]====[${CsxUtil.shellstring('WARNING', 'color:yellow')}]:${JSON.stringify(param[param_key])}`);
                                this.echo(TAB + TAB + TAB + `Empty string detect`);
                                this.warn++;
                                perfect = false;
                                paramHasOptionAndDesc = true;
                            } else {
                                // this.echo(TAB + TAB + TAB + `[${param_key}]====[${CsxUtil.shellstring('OK', 'color:green')}]`);
                                // this.pass ++;
                            }
                        }
                    }
                });
            }
        });
        if (perfect) this.echo(TAB + `...${CsxUtil.shellstring('PASS', 'color:green')}`);
    }
    CheckAudioBasic = () => {
        const TAB = '\xa0\xa0\xa0\xa0';
        let perfect = true;
        this.echo(CsxUtil.shellstring('\r****Start checking audio\'s basic parameter****', this.POINTOUT_STYLE));
        const audDevice: CsxAudioDevice = new CsxAudioDevice(this.instance);
        const outNameParam = audDevice.OutAudioNameParam();
        if (!(outNameParam.x1.isOption && outNameParam.x1.isOption.option.length >= 0)) {
            this.echo(TAB + `Expect basic command "H11" but not found. Audio function may not be support correctly====[${CsxUtil.shellstring('WARNING', 'color:yellow')}]`);
            this.warn++;
            perfect = false;
        }
        if (perfect) this.echo(TAB + `...${CsxUtil.shellstring('PASS', 'color:green')}`);
    }
    CheckIPConfiguration = () => {
        const TAB = '\xa0\xa0\xa0\xa0';
        let perfect = true;
        this.echo(CsxUtil.shellstring('\r****Start checking IP configuration****', this.POINTOUT_STYLE));
        const generalDevice: CsxGeneralDevice = new CsxGeneralDevice(this.instance);
        const lan_param = generalDevice.IPAddressParam().n1.isOption;
        if (lan_param) {
            lan_param.option.forEach(lan_idx => {
                this.echo(TAB + `${CsxUtil.shellstring(`LAN ${lan_idx} information`, 'font-weight:bold;color:yellow')} :`);;
                this.echo(TAB + `${CsxUtil.shellstring(`MAC Address`, 'color:green')}\xa0\xa0\xa0\xa0\xa0\xa0\xa0 -> ${generalDevice.MacAddress(lan_idx)}`);;
                this.echo(TAB + `${CsxUtil.shellstring('Current IP Mode', 'color:green')}\xa0\xa0\xa0 -> ${generalDevice.IPMode(lan_idx)}`);
                this.echo(TAB + `${CsxUtil.shellstring('Current IP Address', 'color:green')} -> ${generalDevice.IPAddress(lan_idx)}`);
                this.echo(TAB + `${CsxUtil.shellstring('Current IP Netmask', 'color:green')} -> ${generalDevice.Netmask(lan_idx)}`);
                this.echo(TAB + `${CsxUtil.shellstring('Current IP Gateway', 'color:green')} -> ${generalDevice.Gateway(lan_idx)}`);
                this.echo(TAB + `${CsxUtil.shellstring('Static IP Address', 'color:green')}\xa0 -> ${generalDevice.StaticIPAddress(lan_idx)}`);
                this.echo(TAB + `${CsxUtil.shellstring('Static IP Netmask', 'color:green')}\xa0 -> ${generalDevice.StaticNetmask(lan_idx)}`);
                this.echo(TAB + `${CsxUtil.shellstring('Static IP Gateway', 'color:green')}\xa0 -> ${generalDevice.StaticGateway(lan_idx)}`);
            })
        }
        if (perfect) this.echo(TAB + `...${CsxUtil.shellstring('PASS', 'color:green')}`);
    }
    CheckAllParameter = () => {
        const TAB = '\xa0\xa0\xa0\xa0';
        this.echo(CsxUtil.shellstring('\r****Print all parameter****', this.POINTOUT_STYLE));
        flatelize(this.param, '').forEach(path => {
            this.echo(TAB + path);
        });
    }
    CheckAllStatus = () => {
        const TAB = '\xa0\xa0\xa0\xa0';
        this.echo(CsxUtil.shellstring('\r****Print all status****', this.POINTOUT_STYLE));
        flatelize(this.status, '').forEach(path => {
            this.echo(TAB + path);
        });
    }
    VirtualConsoleSend = (msg: string) => {
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (csxUserPermissionSatisfy(this.permission, CsxUserPermissionLevel.EditOnly)) {
                const TAB = '\xa0\xa0';
                if (msg.length > 0) {
                    if (window.FOCUS_GATEWAY.ID === this.instance.ID)
                        window.FOCUS_GATEWAY.handleRequest(`?010:${this.consoleCounter++}=${msg}\n`);
                    else
                        window.FOCUS_GATEWAY.handleRequest(`?009:${this.ID},${this.consoleCounter++}=${msg}\n`);
                }
                this.echo(CsxUtil.shellstring('CSX-virtual-console#', 'color:yellow') + TAB + msg);
            } else {
                Notify({ title: getText('NOTIFY_TITLE_ERROR'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' });
            }
        }
    }
    constructor(props?: CsxDevice) {
        super(props);
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.ID === this.instance.ID) {
                this.permission = window.CSX_CUR_AUTH.getPermission('system_permission');
            } else {
                this.permission = window.CSX_CUR_AUTH.getPermission('debug_control');
            }
        }
    }
}

export class CsxUserManagementDevice {
    private _clone_local_user_dir: { [usr_id: string]: CsxAuthorization | undefined } = {};
    protected readonly userRequest = (msg: string, pms_id: UserPermissionType, level: CsxUserPermissionLevel) => {
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission(pms_id), level)) {
                if (window.FOCUS_GATEWAY)
                    window.FOCUS_GATEWAY.handleRequest(msg);
            } else {
                Notify({ title: getText('NOTIFY_TITLE_ERROR'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' });
            }
        }
    }
    UserSizeLimit = () => { return 64; }
    UserAuthorization = (usr_id: string) => {
        const clone_usr_auth = this._clone_local_user_dir[usr_id];
        if (typeof clone_usr_auth === 'undefined') {
            return (window.CSX_CUR_USER) ? window.CSX_CUR_USER.getLocalUserAuth(usr_id) : undefined;
        } else {
            return clone_usr_auth;
        }
    }
    TempUserAuthorization = () => { return new CsxAuthorization(); }
    // set function
    SetUserAuthorization = (usr_id: string, auth_doc: CsxAuthorization) => {
        this.userRequest(`?011:set.${usr_id}=${auth_doc.toLines()}\n`,
            'user_edit',
            ((usr_id === '0') ? CsxUserPermissionLevel.FullAccess : CsxUserPermissionLevel.EditOnly));
        irqRefresh(['GatewayRealTime']);
    }
    DeleteUser = (usr_id: string) => {
        this.userRequest(`?011:delete.${usr_id}\n`, 'user_edit', CsxUserPermissionLevel.FullAccess);
        irqRefresh(['GatewayRealTime']);
    }
    // parameter getters
    // support getter
    IsSupportUserView = (): boolean => {
        if (window.CSX_CUR_AUTH) {
            return (window.CSX_CUR_AUTH.getPermission('user_view') === CsxUserPermissionLevel.FullAccess);
        } else {
            return false;
        }
    }
    // upload function
}

export class CsxEventSystemDevice { // trigger === command
    protected readonly eventRequest = (msg: string, pms_id: UserPermissionType, level: CsxUserPermissionLevel) => {
        if (window.CSX_CUR_AUTH && window.FOCUS_GATEWAY) {
            if (csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission(pms_id), level)) {
                if (window.FOCUS_GATEWAY)
                    window.FOCUS_GATEWAY.handleRequest(msg);
            } else {
                Notify({ title: getText('NOTIFY_TITLE_ERROR'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' });
            }
        }
    }
    protected readonly getSystemConfig = (keypath: string, default_ret?: string) => {
        const sysConfig = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.SYSCONFIG : {};
        const r: string = CsxUtil.defaultValue(CsxUtil.nestedGet(sysConfig, keypath), null, ((default_ret) ? default_ret : ''));
        return r;
    }
    AutomationSetJson = () => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.AUTOMATION_SET_JSON : undefined; }
    AutomationSet = (): Set<string> => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.AUTOMATIONS : new Set(); }
    AutomationSetFilename = (): string => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.AUTOMATIONS_BACKUP_FILENAME : 'unknown'); }
    Automation = (automation_id: stringable) => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getAutomation(`${automation_id}`) : undefined }
    AutomationSize = (): number => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.AUTOMATION_SIZE : 0); }
    AutomationSizeLimit = (): number => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.AUTOMATION_SIZE_LIMIT : 256); }
    CheckAutomationNameExist = (name: string) => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.checkAutomationNameExist(name) : undefined }
    ScenarioSetJson = (): CsxEventSystem.CsxScenarioSetJson | undefined => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.SCENARIO_SET_JSON : undefined; }
    ScenarioSet = (): Set<string> => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.SCENARIOS : new Set(); }
    ScenarioSetFilename = (): string => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.SCENARIOS_BACKUP_FILENAME : 'unknown'); }
    Scenario = (scenario_id: stringable) => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getScenario(`${scenario_id}`) : undefined; }
    NewScenario = () => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.newScenario() : new CsxEventSystem.CsxScenario('0', ''); }
    ScenarioSize = (): number => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.SCENARIO_SIZE : 0); }
    ScenarioSizeLimit = (): number => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.SCENARIO_SIZE_LIMIT : 256); }
    CheckScenarioNameExist = (name: string) => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.checkScenarioNameExist(name) : undefined; }
    RoomSetJson = (): CsxEventSystem.CsxRoomSetJson | undefined => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.ROOMS_SET_JSON : undefined; }
    RoomSet = (): Set<string> => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.ROOMS : new Set(); }
    RoomSetFilename = (): string => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.ROOMS_BACKUP_FILENAME : 'unknown'); }
    Room = (room_id: stringable) => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getRoom(`${room_id}`) : undefined; }
    NewRoom = () => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.newRoom() : new CsxEventSystem.CsxRoom('0', ''); }
    RoomSize = (): number => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.ROOMS_SIZE : 0); }
    RoomSizeLimit = (): number => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.ROOMS_SIZE_LIMIT : 256); }
    CheckRoomNameExist = (name: string) => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.checkRoomNameExist(name) : undefined; }
    IconSetJson = (): CsxEventSystem.CsxIconSetJson | undefined => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.ICONS_SET_JSON : undefined; }
    IconSet = (): Set<string> => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.ICONS : new Set(); }
    IconSetFilename = (): string => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.ICONS_BACKUP_FILENAME : 'unknown'); }
    Icon = (icon_id: stringable) => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getIcon(`${icon_id}`) : undefined; }
    NewIcon = () => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.newIcon() : new CsxEventSystem.CsxIcon('0', ''); }
    IconSize = (): number => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.ICONS_SIZE : 0); }
    IconSizeLimit = (): number => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.ICONS_SIZE_LIMIT : 256); }
    CheckIconNameExist = (name: string) => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.checkIconNameExist(name) : undefined; }
    TcpDeviceSetJson = (): CsxEventSystem.TcpDeviceSetJson | undefined => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.TCPDEVS_SET_JSON : undefined; }
    TcpDeviceSet = (): Set<string> => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.TCPDEVS : new Set(); }
    TcpDeviceSetFilename = (): string => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.TCPDEVS_BACKUP_FILENAME : 'unknown'); }
    TcpDevice = (tcpdev_id: stringable) => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getTcpDevice(`${tcpdev_id}`) : undefined; }
    NewTcpDevice = () => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.newTcpDevice() : new CsxEventSystem.TcpDevice('0', ''); }
    TcpDeviceSize = (): number => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.TCPDEVS_SIZE : 0); }
    TcpDeviceSizeLimit = (): number => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.TCPDEVS_SIZE_LIMIT : 256); }
    CheckTcpDeviceIPPortExist = (ip: string, port: number) => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.checkTcpDeviceIPPortExist(ip, port) : undefined; }
    CommandSetJson = (): CsxEventSystem.CsxCommandSetJson | undefined => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.CMDS_SET_JSON : undefined; }
    CommandSet = (): Set<string> => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.CMDS : new Set(); }
    CommandSetFilename = (): string => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.CMDS_BACKUP_FILENAME : 'unknown'); }
    Command = (cmd_id: stringable) => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getCommand(`${cmd_id}`) : undefined; }
    NewCommand = () => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.newCommand() : new CsxEventSystem.CsxCommand('0', ''); }
    CommandSize = (): number => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.CMDS_SIZE : 0); }
    CommandSizeLimit = (): number => { return (window.FOCUS_GATEWAY ? window.FOCUS_GATEWAY.CMDS_SIZE_LIMIT : 256); }
    CheckCommandNameExist = (name: string) => { return (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.checkCommandNameExist(name) : undefined; }
    UserIconImportOption = () => { return this.getSystemConfig('ICON_IMPORT_OPTION', ''); }
    UserIconImportHintRemember = () => { return this.getSystemConfig('ICON_IMPORT_OPTION_REMEMBER', 'false'); }
    // set function
    CreateAutomationWithName = (name: string) => {
        const new_automation = new CsxEventSystem.CsxAutomation('0', null);
        new_automation.NAME = name;
        this.SetAutomation(new_automation);
    }
    SetAutomation = (automation: CsxEventSystem.CsxAutomation) => {
        this.eventRequest(`?201:AUTOMATION.${automation.ID}=${JSON.stringify(automation.toJson())}\n`,
            'automation_edit',
            ((automation.ID === '0') ? CsxUserPermissionLevel.FullAccess : CsxUserPermissionLevel.EditOnly));
        irqRefresh(['EventSystem']);
    }
    DeleteAutomation = (automation_id_list: Array<string>) => {
        if (automation_id_list.length > 0) {
            const scenario_list = Array.from(this.ScenarioSet());
            const room_list = Array.from(this.RoomSet());
            const delete_automation_cmd = automation_id_list.map(id => `?202:AUTOMATION.${id}`).join('\n');
            const set_scenario_cmd = scenario_list.map(scenario_id => {
                const scenario = this.Scenario(scenario_id);
                let cmd: string = '';
                let has_del_automation = false;
                if (scenario) {
                    const tmp_scenario = scenario.clone();
                    const automation_set = tmp_scenario.AUTOMATION_SET;
                    for (let i = 0; i < automation_id_list.length; i++) {
                        const del_automation_id = automation_id_list[i];
                        if (automation_set.has(del_automation_id)) {
                            has_del_automation = true;
                            tmp_scenario.removeAutomation(del_automation_id);
                        }
                    }
                    if (has_del_automation)
                        cmd = `?201:PROJECT.${tmp_scenario.ID}=${JSON.stringify(tmp_scenario.toJson())}`;
                }
                return cmd;
            }).filter(del_cmd => (del_cmd.length > 0)).join('\n'); // avoid scenario config miss reference
            const set_room_cmd = room_list.map(room_id => {
                const room = this.Room(room_id);
                let cmd: string = '';
                let has_del_automation = false;
                if (room) {
                    const tmp_room = room.clone;
                    const automation_set = new Set(tmp_room.fastAutomations);
                    for (let i = 0; i < automation_id_list.length; i++) {
                        const del_automation_id = automation_id_list[i];
                        if (automation_set.has(del_automation_id)) {
                            has_del_automation = true;
                            tmp_room.removeFastAutomation(del_automation_id);
                        }
                    }
                    if (has_del_automation)
                        cmd = `?201:ROOM.${tmp_room.ID}=${JSON.stringify(tmp_room.toJson())}`;
                }
                return cmd;
            }).filter(del_cmd => (del_cmd.length > 0)).join('\n'); // avoid room config miss reference
            let merge_cmd = delete_automation_cmd + '\n';
            if (set_scenario_cmd.length > 0)
                merge_cmd += set_scenario_cmd + '\n';
            if (set_room_cmd.length > 0)
                merge_cmd += set_room_cmd + '\n';
            this.eventRequest(merge_cmd, 'automation_edit', CsxUserPermissionLevel.FullAccess);
            irqRefresh(['EventSystem']);
        }
    }
    ArrangeAutomation = (new_automation_order: Array<string>) => {
        const requests = new_automation_order.map((automation_id, new_id) => {
            const old_automation = this.Automation(automation_id);
            if (old_automation) {
                const new_automation = old_automation.clone();
                new_automation.ID = (new_id + 1).toString();
                // return `?201:AUTOMATION.${new_automation.ID}=${new_automation.toLines().join('\r')}\n`;
                return `?201:AUTOMATION.${new_automation.ID}=${JSON.stringify(new_automation.toJson())}\n`;
            }
            return '';
        }).join('\n');
        this.eventRequest(requests, 'automation_edit', CsxUserPermissionLevel.EditOnly);
        irqRefresh(['EventSystem']);
    }
    // DeleteTrigger = (trigger_id_list: Array<string>) => {
    //     if (window.FOCUS_GATEWAY)
    //         window.FOCUS_GATEWAY.handleRequest(trigger_id_list.map(id => `?202:TRIGGER.${id}`).join('\n') + '\n');
    //     irqRefresh(['EventSystem']);
    // }
    CreateScenario = (scenario: CsxEventSystem.CsxScenario) => {
        // this.scenarioRequest(`?201:PROJECT.0=${scenario.toLines().join('\r')}\n`);
        this.eventRequest(`?201:PROJECT.0=${JSON.stringify(scenario.toJson())}\n`, 'scenario_edit', CsxUserPermissionLevel.FullAccess);
        irqRefresh(['EventSystem']);
    }
    SetScenario = (scenario: CsxEventSystem.CsxScenario) => {
        // this.scenarioRequest(`?201:PROJECT.${scenario.ID}=${scenario.toLines().join('\r')}\n`);
        const scenario_json = scenario.toJson();
        this.eventRequest(`?201:PROJECT.${scenario.ID}=${JSON.stringify(scenario_json)}\n`,
            'scenario_edit',
            ((scenario.ID === '0') ? CsxUserPermissionLevel.FullAccess : CsxUserPermissionLevel.EditOnly));
        irqRefresh(['EventSystem']);
    }
    DeleteScenario = (scenario_id_list: Array<string>) => {
        if (scenario_id_list.length > 0) {
            const room_list = Array.from(this.RoomSet());
            const delete_scenario_cmd = scenario_id_list.map(id => `?202:PROJECT.${id}`).join('\n');
            const set_room_cmd = room_list.map(room_id => {
                const room = this.Room(room_id);
                let cmd: string = '';
                let has_del_scene = false;
                if (room) {
                    const tmp_room = room.clone;
                    scenario_id_list.forEach(del_scene_id => {
                        if (tmp_room.scenes.indexOf(del_scene_id) >= 0) {
                            has_del_scene = true;
                            tmp_room.removeScenario(del_scene_id);
                        }
                    });
                    if (has_del_scene)
                        cmd = `?201:ROOM.${tmp_room.ID}=${JSON.stringify(tmp_room.toJson())}`;
                }
                return cmd;
            }).filter(del_cmd => (del_cmd.length > 0)).join('\n'); // avoid room config miss reference
            let merge_cmd = delete_scenario_cmd + '\n';
            if (set_room_cmd.length > 0)
                merge_cmd += set_room_cmd + '\n';
            this.eventRequest(merge_cmd, 'scenario_edit', CsxUserPermissionLevel.FullAccess);
            irqRefresh(['EventSystem']);
        }
    }
    SetIcon = async (icon: CsxEventSystem.CsxIcon, image?: File, silent?: boolean) => {
        if (image) {
            try {
                if (window.FOCUS_GATEWAY) {
                    await window.FOCUS_GATEWAY.iconUpdate(image, icon, silent);
                } else {
                    console.log('Gateway disconnect?');
                }
            } catch (error) {
                console.log('SetIcon error :>> ', error);
            }
        } else {
            this.eventRequest(`?201:ICON.${icon.ID}=${JSON.stringify(icon.toJson())}\n`,
                'icon_edit',
                ((icon.ID === '0') ? CsxUserPermissionLevel.FullAccess : CsxUserPermissionLevel.EditOnly));
        }
        irqRefresh(['EventSystem']);
    }
    DeleteIcon = (icon_id_list: Array<string>) => {
        if (icon_id_list.length > 0) {
            const scenario_list = Array.from(this.ScenarioSet());
            const delete_icon_cmd = icon_id_list.map(id => `?202:ICON.${id}\n`).join('');

            this.eventRequest(delete_icon_cmd, 'icon_edit', CsxUserPermissionLevel.FullAccess);
            scenario_list.forEach(scenario_id => {
                const scenario = this.Scenario(scenario_id);
                if (scenario) {
                    const tmp_scenario = scenario.clone();
                    icon_id_list.forEach(del_icon_id => {
                        if (tmp_scenario.IMAGE_ID === del_icon_id) {
                            tmp_scenario.IMAGE_ID = undefined;
                            this.SetScenario(tmp_scenario);
                        }
                    });
                }
            }); // avoid scenario config miss reference
            irqRefresh(['EventSystem']);
        }
    }
    SetRoom = (room: CsxEventSystem.CsxRoom) => {
        this.eventRequest(`?201:ROOM.${room.ID}=${JSON.stringify(room.toJson())}\n`,
            'room_edit',
            ((room.ID === '0') ? CsxUserPermissionLevel.FullAccess : CsxUserPermissionLevel.EditOnly));
        irqRefresh(['EventSystem']);
    }
    DeleteRoom = (room_id_list: Array<string>) => {
        this.eventRequest(room_id_list.map(id => `?202:ROOM.${id}\n`).join(''), 'room_edit', CsxUserPermissionLevel.FullAccess);
        irqRefresh(['EventSystem']);
    }
    RunScenario = (scenario_id: stringable) => {
        const scenario = this.Scenario(scenario_id);
        if (scenario) {
            if (window.FOCUS_GATEWAY) {
                scenario.ACTIVE = true;
                window.FOCUS_GATEWAY.handleRequest(`?201:PROJECT.${scenario_id}=${JSON.stringify(scenario.toJson())}\n`);
            }
            // if (window.FOCUS_GATEWAY)
            //     window.FOCUS_GATEWAY.handleRequest(`?201:PROJECT.${scenario_id}=${scenario.toLines().join('\r')}\n`);
        }
        irqRefresh(['EventSystem']);
    }
    SetTcpDevice = (tcpdev: CsxEventSystem.TcpDevice) => {
        this.eventRequest(`?201:TCPDEV.${tcpdev.ID}=${JSON.stringify(tcpdev.toJson())}\n`,
            'room_edit',
            CsxUserPermissionLevel.NoAccess);
        irqRefresh(['EventSystem']);
    }
    DeleteTcpDevice = (tcpdev_id_list: Array<string>) => {
        this.eventRequest(tcpdev_id_list.map(id => `?202:TCPDEV.${id}\n`).join(''), 'room_edit', CsxUserPermissionLevel.NoAccess);
        irqRefresh(['EventSystem']);
    }
    SetCommand = (cmd: CsxEventSystem.CsxCommand) => {
        this.eventRequest(`?201:CMD.${cmd.ID}=${JSON.stringify(cmd.toJson())}\n`,
            'room_edit',
            CsxUserPermissionLevel.NoAccess);
        irqRefresh(['EventSystem']);
    }
    DeleteCommand = (cmd_id_list: Array<string>) => {
        this.eventRequest(cmd_id_list.map(id => `?202:CMD.${id}\n`).join(''), 'room_edit', CsxUserPermissionLevel.NoAccess);
        irqRefresh(['EventSystem']);
    }
    StopScenario = (scenario_id: stringable) => {
        const scenario = this.Scenario(scenario_id);
        if (scenario) {
            scenario.ACTIVE = false;
            if (window.FOCUS_GATEWAY)
                window.FOCUS_GATEWAY.handleRequest(`?201:PROJECT.${scenario_id}=${JSON.stringify(scenario.toJson())}\n`);
        }
        irqRefresh(['EventSystem']);
    }
    EnableAutomation = (automation_id: stringable) => {
        const automation = this.Automation(automation_id);
        if (automation) {
            automation.ACTIVE = true;
            this.SetAutomation(automation);
        }
        irqRefresh(['EventSystem']);
    }
    DisableAutomation = (automation_id: stringable) => {
        const automation = this.Automation(automation_id);
        if (automation) {
            automation.ACTIVE = false;
            this.SetAutomation(automation);
        }
        irqRefresh(['EventSystem']);
    }
    RunActionTest = (data: any) => {
        if (data) {
            try {
                this.eventRequest(`?201:ACTION_TEST=${JSON.stringify(data)}\n`, 'automation_edit', CsxUserPermissionLevel.EditOnly);
            } catch (error) {
                Notify({ type: 'error', context: `${error}`, title: getText('NOTIFY_TITLE_FAIL') });
            }
        }
    }
    // snapshot handler
    private blockUntilAutomationNameExist = (name: string) => {
        return new Promise((resolve: ((id: string) => void)) => {
            const check_name_timeout = () => {
                const instance_id = this.CheckAutomationNameExist(name);
                if (typeof instance_id === 'string') {
                    resolve(instance_id);
                } else {
                    global.setTimeout(check_name_timeout, 500);
                }
            };
            check_name_timeout();
        });
    }
    private blockUntilScenarioNameExist = (name: string) => {
        return new Promise((resolve: ((id: string) => void)) => {
            const check_name_timeout = () => {
                const instance_id = this.CheckScenarioNameExist(name);
                if (typeof instance_id === 'string') {
                    resolve(instance_id);
                } else {
                    global.setTimeout(check_name_timeout, 500);
                }
            };
            check_name_timeout();
        });
    }
    RoomAutomationSnapshotProcess = async (object: CsxEventSystem.CsxRoomJson) => {
        if (object.automationSnapshot) {
            const automation_id_ref_list = Object.keys(object.automationSnapshot);
            for (let j = 0; j < automation_id_ref_list.length; j++) {
                const aid_ref = automation_id_ref_list[j];
                const automation_json = object.automationSnapshot[aid_ref];
                let aid_real = this.CheckAutomationNameExist(automation_json.name);
                if (!aid_real) {
                    if (this.AutomationSize() < this.AutomationSizeLimit()) {
                        /* if automation isn't exist, create a new one */
                        const new_automation = new CsxEventSystem.CsxAutomation('0', automation_json);
                        this.SetAutomation(new_automation);
                        aid_real = await this.blockUntilAutomationNameExist(automation_json.name);
                    } else {
                        Notify({ title: getText('NOTIFY_TITLE_CREATE_ROOM'), context: `${getText('NOTIFY_MSG_ROOM_SIZE_LIMIT')} ${this.AutomationSizeLimit()}. Ignore.`, type: 'warning' });
                    }
                }
                if (object.scenarioSnapshot) {
                    const scenario_id_ref_list = Object.keys(object.scenarioSnapshot);

                    for (let h = 0; h < scenario_id_ref_list.length; h++) {
                        const sid = scenario_id_ref_list[h];
                        const scenario_json = object.scenarioSnapshot[sid];
                        const aid_ref_idx = scenario_json.automations.indexOf(aid_ref);
                        if (aid_real && aid_ref_idx >= 0) {
                            /* replace with new id */
                            scenario_json.automations[aid_ref_idx] = aid_real;
                        } else {
                            /* remove undefined reference */
                            scenario_json.automations.splice(aid_ref_idx, 1);
                        }
                    }
                }
                if (object.fastAutomations) {
                    const aid_ref_idx = object.fastAutomations.indexOf(aid_ref);
                    if (aid_real && aid_ref_idx >= 0) {
                        object.fastAutomations[aid_ref_idx] = aid_real;
                    } else {
                        /* remove undefined reference */
                        object.fastAutomations.splice(aid_ref_idx, 1);
                    }
                }
            }
        }
    }
    RoomScenarioSnapshotProcess = async (object: CsxEventSystem.CsxRoomJson) => {
        if (object.scenarioSnapshot) {
            const scenario_id_ref_list = Object.keys(object.scenarioSnapshot);
            for (let j = 0; j < scenario_id_ref_list.length; j++) {
                const sid_ref = scenario_id_ref_list[j];
                const sid_ref_idx = object.scenes.indexOf(sid_ref);
                const scenario_json = object.scenarioSnapshot[sid_ref];

                let sid_real = this.CheckScenarioNameExist(scenario_json.name);
                if (!sid_real) {
                    if (this.ScenarioSize() < this.ScenarioSizeLimit()) {
                        /* if scenario isn't exist, create a new one */
                        const new_scenario = new CsxEventSystem.CsxScenario('0', scenario_json);
                        this.SetScenario(new_scenario);
                        sid_real = await this.blockUntilScenarioNameExist(scenario_json.name);
                    } else {
                        Notify({ title: getText('NOTIFY_TITLE_CREATE_SCENARIO'), context: `${getText('NOTIFY_MSG_SCENARIO_SIZE_LIMIT')} ${this.ScenarioSizeLimit()}. Ignore.`, type: 'warning' });
                    }
                }
                if (sid_real && sid_ref_idx >= 0) {
                    /* replace with new id */
                    object.scenes[sid_ref_idx] = sid_real;
                } else {
                    /* remove undefined reference */
                    object.scenes.splice(sid_ref_idx, 1);
                }
            }
        }
    }
    ScenarioAutomationSnapshotProcess = async (object: CsxEventSystem.CsxScenarioJson) => {
        if (object.automationSnapshot) {
            const automation_id_ref_list = Object.keys(object.automationSnapshot);
            for (let j = 0; j < automation_id_ref_list.length; j++) {
                const aid_ref = automation_id_ref_list[j];
                const aid_ref_idx = object.automations.indexOf(aid_ref);
                const automation_json = object.automationSnapshot[aid_ref];
                let aid_real = this.CheckAutomationNameExist(automation_json.name);
                if (!aid_real) {
                    if (this.AutomationSize() < this.AutomationSizeLimit()) {
                        /* if automation isn't exist, create a new one */
                        const new_automation = new CsxEventSystem.CsxAutomation('0', automation_json);
                        this.SetAutomation(new_automation);
                        aid_real = await this.blockUntilAutomationNameExist(automation_json.name);
                    } else {
                        Notify({ title: getText('NOTIFY_TITLE_CREATE_AUTOMATION'), context: `${getText('NOTIFY_MSG_AUTOMATION_SIZE_LIMIT')}: ${this.AutomationSizeLimit()}. Ignore.`, type: 'warning' });
                    }
                }
                if (aid_real && aid_ref_idx >= 0) {
                    /* replace with new id */
                    object.automations[aid_ref_idx] = aid_real;
                } else {
                    /* remove undefined reference */
                    object.automations.splice(aid_ref_idx, 1);
                }
            }
        }
    }
    SetUserIconImportOption = (v: stringable) => {
        this.eventRequest(`?201:ICON_IMPORT_OPTION=${v}\n`, 'icon_edit', CsxUserPermissionLevel.FullAccess);
    }
    SetUserIconImportHintRemember = (v: stringable) => {
        this.eventRequest(`?201:ICON_IMPORT_OPTION_REMEMBER=${v}\n`, 'icon_edit', CsxUserPermissionLevel.FullAccess);
    }
    // parameter getters
    // support getter
    IsSupportEventSystem = (): boolean => { return true; }
    // upload function
}