import React from 'react';
import { Prompt, withRouter } from 'react-router-dom';
import { DraggableEvent } from 'react-draggable';
import { Modal, Button, Notify, DateTime, Icon, Tooltip } from 'cypd';

import { CsxEventSystem, CsxDesc, CsxUtil, CsxFeature } from '../../../csx';
import * as Topology from '../topology/node';
import { 
    repeatEventParamParseDescription,
    OneTimeEventModalContent, 
    PeriodicalEventModalContent, 
    CustomCommandEventModalContent, 
    CYPDeviceEventModalContent, 
    ExternalTCPEventModalContent, 
    ExternalRS232EventModalContent,
    KeypadEventModalContent,
    PollingEventModalContent,
    CYPActionModalContent,
    TCPActionModalContent,
    RS232ActionModalContent,
    UDPActionModalContent,
} from './modal';
import { NodeSelector } from './sider';
import { nestedClone } from '../../../csx/util';
import { CsxUserPermissionLevel, csxUserPermissionSatisfy } from '../../../csx/manager';


const { isCsxActionType, isCsxEventType } = CsxEventSystem;
const { getText } = CsxDesc;

declare global {
    interface Window {
        canvas: {
            curNode: number;
            addNode: (type: CsxEventSystem.CsxNodeType, position?: { x: number; y: number }) => void;
            setNodePort: (nid: number, dir: 'in' | 'out', pidx: number, name: string) => void;
            getNode: (nid: number) => Readonly<Topology.Node> | undefined;
            setNodeData: (nid: number, data: any) => void;
            zoom: number;
        };
    }
}

type CanvasState = {
    mode: 'normal' | 'cut' | 'grab';
    nodes: Array<Topology.Node>;
    connections: Array<Topology.Edge>;
    // disable draggable box drag when pulling from output Node
    disableDrag: boolean;
    // modal popup
    popup: number;
    // trigger popup ask link destination
    whoAskLink: number;
    // determine input, output port insight filled
    outputEnable: { [s: string]: Array<boolean> };
    inputEnable: { [s: string]: Array<boolean> };
    // tempEdge appear when pulling from output Node, which creates new connection
    tempEdge?: { from_x: number; from_y: number; to_x: number; to_y: number };
    // canvas offset
    offset: { x: number; y: number };
    // determinthe canvas has been dirty
    changed: boolean;
    // check cycle exist
    isCycleExist: boolean;
}

const DEFAULT_LEFT_COLLAPSER_OFFSET = (window.innerWidth < 500) ? 0 : 60;
const DEFAULT_HEADER_OFFSET = 65;
const MAX_SCALE_RATE = 1;
const MIN_SCALE_RATE = 0.1;
const DEFAULT_SCALE_STEP = 0.1;
const APP_ENTRY_TIME = new Date();


const BLOCK_COMPONENT: { [s in CsxEventSystem.CsxNodeType]: React.FunctionComponent<Topology.NodeProperties> } = {
    NullEvent:                  () => <div key={Math.random()}/>,
    OneTimeEvent:               Topology.BasicEventNode,
    RepeatEvent:                Topology.BasicEventNode,
    CYPDeviceEvent:             Topology.BasicEventNode,
    ExternalTCPEvent:           Topology.BasicEventNode,
    ExternalRS232Event:         Topology.BasicEventNode,
    TriggerInEvent:             Topology.BasicEventNode,
    PollingEvent:               Topology.BasicEventNode,
    // SystemEvent:                Topology.BasicEventNode,
    CustomCmdEvent:             Topology.BasicEventNode,
    // TCPDeviceEvent:             () => <div key={Math.random()}/>,
    NullAction:                 () => <div key={Math.random()}/>,
    CYPAction:                  Topology.BasicActionNode,
    TCPAction:                  Topology.BasicActionNode,
    UDPAction:                  Topology.BasicActionNode,
    RS232Action:                Topology.BasicActionNode,
    // TCPDeviceAction:            () => <div key={Math.random()}/>,
    NullLogic:                  () => <div key={Math.random()}/>,
    AndLogic:                   Topology.LogicNode,
    OrLogic:                    Topology.LogicNode,
    NotLogic:                   Topology.LogicNode,
}

window.layout = (window.layout) ? window.layout : {};

declare type Point  = [ number, number ];
declare type Curve  = [ Point, Point, Point, Point ];

function lerp(A: Point, B: Point, t: number): Point {
    // A and B are arrays where the first element is the x 
    // and the second element is the y coordinate of the point
    // if(t == .5) the function returns a point in the center of the line AB
    // t is always a number between 0 and 1
    // 0 <= t <= 1
    return [
        (B[0] - A[0]) * t + A[0], // the x coordinate
        (B[1] - A[1]) * t + A[1]  // the y coordinate
    ];
}

function bazierCurve(curve: Curve, onclick?: (() => void), mark?: boolean): JSX.Element {
    return <path
        d={`M ${curve[0]} C ${curve[1]} ${curve[2]} ${curve[3]}`}
        fill="none"
        stroke="rgba(158, 8, 20, 0.5)"
        strokeWidth={5}
        markerEnd={(mark?'url(#arrow)':undefined)}
        onClick={onclick}
    />;
}

function bipartCurve(curve: Curve, t: number): [ Curve, Curve ] {
    const start_c1_mid: Point = lerp(curve[0], curve[1], t);
    const c1_c2_mid: Point = lerp(curve[1], curve[2], t);
    const c2_end_mid: Point = lerp(curve[2], curve[3], t);
    const help1: Point = lerp(start_c1_mid, c1_c2_mid, t);
    const help2: Point = lerp(c1_c2_mid, c2_end_mid, t);
    const h1_h2_mid: Point = lerp(help1, help2, t);
    return [
        [curve[0], start_c1_mid, help1, h1_h2_mid],
        [h1_h2_mid, help2, c2_end_mid, curve[3]]
    ];
}


function isMouseEvent<T>(e: any | React.MouseEvent<T>): e is React.MouseEvent<T> {
    const eMouse = e as React.MouseEvent<T>;
    // Can test for other properties as well
    return eMouse && typeof eMouse.clientX === "number" && typeof eMouse.clientY === "number";
}

function isTouchEvent<T>(e: any | React.KeyboardEvent<T>): e is React.TouchEvent<T> {
    const eTouch = e as React.TouchEvent<T>;
    // Can test for other properties as well
    return eTouch && eTouch.touches && eTouch.touches.length > 0;
}

export default class Canvas extends React.Component<any> {
    BLOCK_INPUT_FIELD: { [s in CsxEventSystem.CsxNodeType]: Array<Topology.NodePort> } = {
        NullEvent:                  [],
        OneTimeEvent:               [{ "name": `${getText('DEVICE_FUNC_TIME_LOCAL_DATE_TIME')} :` }, { "name": "N/A" }],
        RepeatEvent:                [{ "name": `${getText('DESCRIPTION')} :` }, { "name": "N/A" }],
        CYPDeviceEvent:             [{ "name": `${getText('DEVICE')} :` }, { "name": "N/A" }, { "name": `${getText('COMMAND')} :` }, { "name": "N/A" }], 
        ExternalTCPEvent:           [{ "name": `${getText('IP_PORT')} :` }, { "name": "N/A" }, { "name": `${getText('TRAILING')} :` }, { "name": "N/A" }, { "name": `${getText('TCP_KEY')} :` }, { "name": "N/A" }],
        ExternalRS232Event:         [{ "name": `${getText('TRAILING')} :` }, { "name": "N/A" }, { "name": `${getText('RS232_KEY')} :` }, { "name": "N/A" }],
        TriggerInEvent:             [{ "name": `${getText('TRIGGER')} :` }, { "name": "N/A" }],
        PollingEvent:               [{ "name": `${getText('IP_PORT')} :` }, { "name": "N/A" }, { "name": `${getText('SEND')} :` }, { "name": "N/A" }, { "name": `${getText('POLL_KEY')} :` }, { "name": "N/A" }],
        // SystemEvent:                [{ "name": "System" }, { "name": "N/A" }],
        CustomCmdEvent:             [{ "name": `${getText('PROTOCOL')} :` }, { "name": "N/A" }, { "name": `${getText('IP_PORT')} :` }, { "name": "N/A" }, { "name": `${getText('COMMAND')} :` }, { "name": "N/A" }],
        // TCPDeviceEvent:             [{ "name": `${getText('DEVICE')} :` }, { "name": "N/A" }, { "name": `${getText('IP_PORT')} :` }, { "name": "N/A" }, { "name": `${getText('KEYWORD')} :` }, { "name": "N/A" }],
        // TCPDeviceAction:            [{ "name": `${getText('DEVICE')} :` }, { "name": "N/A" }, { "name": `${getText('IP_PORT')} :` }, { "name": "N/A" }, { "name": `${getText('COMMAND')} :` }, { "name": "N/A" }],
        NullAction:                 [],
        CYPAction:                  [{ "name": getText('TRIGGER'), linkable: true }, { "name": "" }, { "name": `${getText('PRIORITY')} :` }, { "name": "0(default)" }],
        TCPAction:                  [{ "name": getText('TRIGGER'), linkable: true }, { "name": "" }, { "name": `${getText('PRIORITY')} :` }, { "name": "0(default)" }, { "name": "" }, { "name": "" }],
        UDPAction:                  [{ "name": getText('TRIGGER'), linkable: true }, { "name": "" }, { "name": `${getText('PRIORITY')} :` }, { "name": "0(default)" }, { "name": "" }, { "name": "" }],
        RS232Action:                [{ "name": getText('TRIGGER'), linkable: true }, { "name": "" }, { "name": `${getText('PRIORITY')} :` }, { "name": "0(default)" }],
        NullLogic:                  [],
        AndLogic:                   [{ "name": "", linkable: true }],
        OrLogic:                    [{ "name": "", linkable: true }],
        NotLogic:                   [{ "name": "", linkable: true }],
    };
    BLOCK_OUTPUT_FIELD: { [s in CsxEventSystem.CsxNodeType]: Array<Topology.NodePort> } = {
        NullEvent:                  [],
        OneTimeEvent:               [{ "name": getText('TRIGGER'), linkable: true }],
        RepeatEvent:                [{ "name": getText('TRIGGER'), linkable: true }],
        CYPDeviceEvent:             [{ "name": getText('TRIGGER'), linkable: true }],
        ExternalTCPEvent:           [{ "name": getText('TRIGGER'), linkable: true }],
        ExternalRS232Event:         [{ "name": getText('TRIGGER'), linkable: true }],
        TriggerInEvent:             [{ "name": getText('TRIGGER'), linkable: true }],
        PollingEvent:               [{ "name": getText('TRIGGER'), linkable: true }],
        // SystemEvent:                [{ "name": getText('TRIGGER'), linkable: true }],
        CustomCmdEvent:             [{ "name": getText('TRIGGER'), linkable: true }],
        // TCPDeviceEvent:             [{ "name": getText('TRIGGER'), linkable: true }],
        NullAction:                 [],
        CYPAction:                  [{ "name": `${getText('DEVICE')} :`, linkable: true }, { "name": "N/A" }, { "name": `${getText('COMMAND')} :` }, { "name": "N/A" }],
        TCPAction:                  [{ "name": `${getText('IP_PORT')} :`, linkable: true }, { "name": "N/A" }, { "name": `${getText('TRAILING')} :` }, { "name": "N/A" }, { "name": `${getText('COMMAND')} :` }, { "name": "N/A" }],
        UDPAction:                  [{ "name": `${getText('IP_PORT')} :`, linkable: true }, { "name": "N/A" }, { "name": `${getText('TRAILING')} :` }, { "name": "N/A" }, { "name": `${getText('COMMAND')} :` }, { "name": "N/A" }],
        RS232Action:                [{ "name": `${getText('TRAILING')} :`, linkable: true }, { "name": "N/A" }, { "name": `${getText('COMMAND')} :` }, { "name": "N/A" }],
        // TCPDeviceAction:            [{ "name": `${getText('TRAILING')} :`, linkable: true }, { "name": "N/A" }, { "name": `${getText('COMMAND')} :` }, { "name": "N/A" }],
        NullLogic:                  [],
        AndLogic:                   [{ "name": "", linkable: true }],
        OrLogic:                    [{ "name": "", linkable: true }],
        NotLogic:                   [{ "name": "", linkable: true }],
    }
    BLOCK_POP_COMPONENT: { [s in CsxEventSystem.CsxNodeType]: { title: 'string' | React.ReactNode; content: 'string' | React.ReactNode } | undefined } = {
        NullEvent:                  undefined,
        OneTimeEvent:               { title: getText('AUTOMATION_CANVAS_MODAL_EDIT_EVENT'), content: <OneTimeEventModalContent /> },
        RepeatEvent:                { title: getText('AUTOMATION_CANVAS_MODAL_EDIT_EVENT'), content: <PeriodicalEventModalContent /> },
        CYPDeviceEvent:             { title: getText('AUTOMATION_CANVAS_MODAL_EDIT_EVENT'), content: <CYPDeviceEventModalContent /> },
        ExternalTCPEvent:           { title: getText('AUTOMATION_CANVAS_MODAL_EDIT_EVENT'), content: <ExternalTCPEventModalContent /> },
        ExternalRS232Event:         { title: getText('AUTOMATION_CANVAS_MODAL_EDIT_EVENT'), content: <ExternalRS232EventModalContent /> },
        TriggerInEvent:             { title: getText('AUTOMATION_CANVAS_MODAL_EDIT_EVENT'), content: <KeypadEventModalContent /> },
        PollingEvent:               { title: getText('AUTOMATION_CANVAS_MODAL_EDIT_EVENT'), content: <PollingEventModalContent /> },
        CustomCmdEvent:             { title: getText('AUTOMATION_CANVAS_MODAL_EDIT_EVENT'), content: <CustomCommandEventModalContent /> },
        // TCPDeviceEvent:             undefined,
        NullAction:                 undefined,
        CYPAction:                  { title: getText('AUTOMATION_CANVAS_MODAL_EDIT_ACTION'),content: <CYPActionModalContent /> },
        TCPAction:                  { title: getText('AUTOMATION_CANVAS_MODAL_EDIT_ACTION'),content: <TCPActionModalContent /> },
        UDPAction:                  { title: getText('AUTOMATION_CANVAS_MODAL_EDIT_ACTION'),content: <UDPActionModalContent /> },
        RS232Action:                { title: getText('AUTOMATION_CANVAS_MODAL_EDIT_ACTION'),content: <RS232ActionModalContent /> },
        // TCPDeviceAction:            undefined,
        NullLogic:                  undefined,
        AndLogic:                   undefined,
        OrLogic:                    undefined,
        NotLogic:                   undefined,
    };
    DEFAULT_DATA_TABLE: { [s in CsxEventSystem.CsxNodeType]: CsxEventSystem.CsxEventParameter | CsxEventSystem.CsxLogicParameter | CsxEventSystem.CsxActionParameter | null } = {
        NullEvent:              null,
        OneTimeEvent:           { name: getText('EVENT_BOX_TITLE_ONCE', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, OneTime: { time: APP_ENTRY_TIME.getTime() } },
        RepeatEvent:            { name: getText('EVENT_BOX_TITLE_REPEAT', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, Repeat: { fromDate: APP_ENTRY_TIME.getTime(), toDate: APP_ENTRY_TIME.getTime(), fromTime: APP_ENTRY_TIME.getTime(), toTime: (new Date(APP_ENTRY_TIME.getTime() + 3600000).getTime()), triggerExpression: '10,sec', eventExpression: '1,1' } }, // default once per day
        CYPDeviceEvent:         { name: getText('EVENT_BOX_TITLE_CYPD', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, CYPDevice: { device: '', command: '' } },
        ExternalTCPEvent:       { name: getText('EVENT_BOX_TITLE_EXT_TCP', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, TCP: { ipaddr: '', port: 23, command: '', hexCommand: '', endchar: CsxEventSystem.CsxTrailType.NA, nic: 'eth0' } },
        ExternalRS232Event:     { name: getText('EVENT_BOX_TITLE_EXT_RS232', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, Control: { command: '', hexCommand: '', endchar: CsxEventSystem.CsxTrailType.NA } },
        TriggerInEvent:         { name: getText('EVENT_BOX_TITLE_KEYPAD', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, TriggerIn: { keycode: '' } },
        PollingEvent:           { name: getText('EVENT_BOX_TITLE_POLLING', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, Polling: { polling: '', pollingRef: '', hexDetect: '', hexPolling: '', detect: '', pollingEndchar: CsxEventSystem.CsxTrailType.NA, detectEndchar: CsxEventSystem.CsxTrailType.NA, ipaddr: '', port: 23, nic: 'eth0' } },
        // SystemEvent:            `${getText('EVENT_BOX_TITLE_SYSTEM', CsxUtil.APP_LANG_TYPE.ENGLISH)}`,
        CustomCmdEvent:         { name: getText('EVENT_BOX_TITLE_CUSTOM', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, Customized: { command: '', hexCommand: '', endchar: CsxEventSystem.CsxTrailType.NA, networkInterface: 'TCP', ipaddr: '', port: 5000, nic: 'eth0' } },
        // TCPDeviceEvent:         null,
        NullAction:             null,
        CYPAction:              { name: getText('ACTION_BOX_TITLE_CYP', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, priority: 0, delay: 0, CYPDevice: { command: '', device: '' } },
        TCPAction:              { name: getText('ACTION_BOX_TITLE_TCP', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, priority: 0, delay: 0, TCP: { ipaddr: '', port: 23, command: '', commandRef: '', hexCommand: '', endchar: CsxEventSystem.CsxTrailType.NA, nic: 'eth0' } },
        UDPAction:              { name: getText('ACTION_BOX_TITLE_UDP', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, priority: 0, delay: 0, UDP: { ipaddr: '', port: 9, command: '', commandRef: '', hexCommand: '', endchar: CsxEventSystem.CsxTrailType.NA, nic: 'eth0' } },
        RS232Action:            { name: getText('ACTION_BOX_TITLE_RS232', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, priority: 0, delay: 0, Control: { command: '', commandRef: '', hexCommand: '', endchar: CsxEventSystem.CsxTrailType.NA } },
        // TCPDeviceAction:        null,
        NullLogic:              null,
        AndLogic:               { lockOnCanvas: false },
        OrLogic:                { lockOnCanvas: false },
        NotLogic:               { lockOnCanvas: false },
    }
    state: CanvasState;
    // variable buffering draggable box start position
    cursorX?: number;
    cursorY?: number;
    // variable buffering new connection property
    tempConnections?: { from_node: number; from: string; to_node?: number; to?: string };
    // indicate node array postion with nid
    nidIndex: { [s: string]: number } = {};
    // counter for generating auto nid
    nidCounter: number = 0;
    // import file input
    fileSelector: HTMLInputElement | null | undefined;
    // edge identification (prevent duplicated connection)
    edgeSet: Set<string> = new Set();
    // handle canvas offset
    canvasMoveStartX?: number;
    canvasMoveStartY?: number;
    // csx feature instance
    instance: CsxFeature.CsxEventSystemDevice;
    // check topology circular
    parent_table: { [nid: string]: Set<string> } = {};

    constructor(props: any) {
        super(props);
        window.canvas = { addNode: this.handleAdd, setNodePort: this.handleSetPort, curNode: -1, getNode: this.handleGet, setNodeData: this.handleSetData, zoom: 1 };
        const automation_inst = window.routeEvent.FOCUS_AUTOMATION();
        const { state, counter, index, set } = this.parseAutomation(automation_inst);
        this.state = state;
        this.nidCounter = counter;
        this.nidIndex = index;
        this.edgeSet = set;
        this.instance = new CsxFeature.CsxEventSystemDevice();
        document.onkeydown = this.handleKeyboard;
    }

    /**
     * Scan topology at first to detect cycle
     */
    componentDidMount() { this.topologyCheckCyle(); }

    /**
     * Disable setState() and reset history lock when component unmounted
     */
    componentWillUnmount() { document.onkeydown = null; this.setState = () => {}; window.pushLock = false; }
    
    /**
     * Handle history push lock when canvas having been edit
     */
    componentDidUpdate(_: any, prevState: CanvasState) {
        if (window.CSX_CUR_AUTH) {
            if (!csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('automation_edit'), CsxUserPermissionLevel.EditAssigned)) {
                return;
            }
        }
        if (prevState.changed !== this.state.changed)
            window.pushLock = this.state.changed;
    }

    /**
     * Parse Logic Field
     */
    fillLogicField(type: CsxEventSystem.CsxLogicType, param: CsxEventSystem.CsxLogicParameter): { in: Array<Topology.NodePort>; out: Array<Topology.NodePort> } {
        const new_in_f = nestedClone(this.BLOCK_INPUT_FIELD[type]);
        const new_ou_f = nestedClone(this.BLOCK_OUTPUT_FIELD[type]);
        this.BLOCK_INPUT_FIELD[type].forEach((port: Topology.NodePort, idx) => { new_in_f[idx] = { ...port }; }); // shallow clone port to avoid reference
        this.BLOCK_OUTPUT_FIELD[type].forEach((port: Topology.NodePort, idx) => { new_ou_f[idx] = { ...port }; }); // shallow clone port to avoid reference
        const lgc_fields: { in: Array<Topology.NodePort>; out: Array<Topology.NodePort> } = { in: new_in_f, out: new_ou_f };
        return lgc_fields;
    }

    /**
     * Parse Action Field
     */
    fillActionField(type: CsxEventSystem.CsxActionType, param: CsxEventSystem.CsxActionParameter): { in: Array<Topology.NodePort>; out: Array<Topology.NodePort> } {
        const new_in_f = nestedClone(this.BLOCK_INPUT_FIELD[type]);
        const new_ou_f = nestedClone(this.BLOCK_OUTPUT_FIELD[type]);
        this.BLOCK_INPUT_FIELD[type].forEach((port: Topology.NodePort, idx) => { new_in_f[idx] = { ...port }; }); // shallow clone port to avoid reference
        this.BLOCK_OUTPUT_FIELD[type].forEach((port: Topology.NodePort, idx) => { new_ou_f[idx] = { ...port }; }); // shallow clone port to avoid reference
        const act_fields: { in: Array<Topology.NodePort>; out: Array<Topology.NodePort> } = { in: new_in_f, out: new_ou_f };

        act_fields.in[3].name = param.priority.toString();
        if (type === 'CYPAction') {
            if (param.CYPDevice) {
                const { device, command } = param.CYPDevice;
                act_fields.out[1].name = device;
                act_fields.out[3].name = command;
            }
        } else if (type === 'TCPAction') {
            if (param.TCP) {
                const { command, hexCommand, ipaddr, port, endchar } = param.TCP;
                act_fields.out[1].name = `${ipaddr}:${port}`;
                act_fields.out[3].name = endchar;
                act_fields.out[5].name = (command.length) ? command : hexCommand;
            }
        } else if (type === 'UDPAction') {
            if (param.UDP) {
                const { command, hexCommand, ipaddr, port, endchar } = param.UDP;
                act_fields.out[1].name = `${ipaddr}:${port}`;
                act_fields.out[3].name = endchar;
                act_fields.out[5].name = (command.length) ? command : hexCommand;
            }
        } else if (type === 'RS232Action') {
            if (param.Control) {
                const { command, hexCommand, endchar } = param.Control;
                act_fields.out[1].name = endchar;
                act_fields.out[3].name = (command.length) ? command : hexCommand;
            }
        }
        return act_fields;
    }
    /**
     * ParseEventField
     */
    fillEventField(type: CsxEventSystem.CsxEventType, param: CsxEventSystem.CsxEventParameter): { in: Array<Topology.NodePort>; out: Array<Topology.NodePort> } {
        const new_in_f = nestedClone(this.BLOCK_INPUT_FIELD[type]);
        const new_ou_f = nestedClone(this.BLOCK_OUTPUT_FIELD[type]);
        this.BLOCK_INPUT_FIELD[type].forEach((port: Topology.NodePort, idx) => { new_in_f[idx] = { ...port }; }); // shallow clone port to avoid reference
        this.BLOCK_OUTPUT_FIELD[type].forEach((port: Topology.NodePort, idx) => { new_ou_f[idx] = { ...port }; }); // shallow clone port to avoid reference
        const evt_fields: { in: Array<Topology.NodePort>; out: Array<Topology.NodePort> } = { in: new_in_f, out: new_ou_f };
        if (type === 'OneTimeEvent') {
            if (param.OneTime) {
                const { time } = param.OneTime;
                const parse_time = new Date(time);
                evt_fields.in[1].name = `${parse_time.toLocaleString()}`;
            }
        } else if (type === 'TriggerInEvent') {
            if (param.TriggerIn) {
                const { keycode } = param.TriggerIn;
                evt_fields.in[1].name = keycode.slice();
            }
        } else if (type === 'RepeatEvent') {
            evt_fields.in[1].name = repeatEventParamParseDescription(param);
        } else if (type === 'CustomCmdEvent') {
            if (param.Customized) {
                const { networkInterface, ipaddr, port, command, hexCommand, endchar } = param.Customized;
                evt_fields.in[1].name = networkInterface.slice();
                evt_fields.in[3].name = `${ipaddr}:${port}`;
                evt_fields.in[5].name = `${(command.length) ? command : hexCommand}:${endchar}`;
            }
        } else if (type === 'CYPDeviceEvent') {
            if (param.CYPDevice) {
                const { device, command } = param.CYPDevice;
                evt_fields.in[1].name = device;
                evt_fields.in[3].name = command;
            }
        } else if (type === 'ExternalRS232Event') {
            if (param.Control) {
                const { command, hexCommand, endchar } = param.Control;
                evt_fields.in[1].name = endchar;
                evt_fields.in[3].name = (command.length) ? command : hexCommand;
            }
        } else if (type === 'ExternalTCPEvent') {
            if (param.TCP) {
                const { command, hexCommand, ipaddr, port, endchar } = param.TCP;
                evt_fields.in[1].name = `${ipaddr}:${port}`;
                evt_fields.in[3].name = endchar;
                evt_fields.in[5].name = (command.length) ? command : hexCommand;
            }
        } else if (type === 'PollingEvent') {
            if (param.Polling) {
                const { ipaddr, port, polling, pollingEndchar, detect, detectEndchar, hexDetect, hexPolling } = param.Polling;
                evt_fields.in[1].name = `${ipaddr}:${port}`;
                evt_fields.in[3].name = `${(polling.length) ? polling : hexPolling}:${pollingEndchar}`;
                evt_fields.in[5].name = `${(detect.length) ? detect : hexDetect}:${detectEndchar}`;
            }
        }
        return evt_fields;
    }
    /**
     * Handle revert origin configuration
     */
    handleRevert = () => {
        window.userConfirm(getText('CONFIRM_REVERT_AUTOMATION_CANVAS'), (ok) => {
            if (ok) {
                // clean all self variables
                this.cursorX = undefined;
                this.cursorY = undefined;
                this.tempConnections = undefined;
                this.canvasMoveStartX = 0;
                this.canvasMoveStartY = 0;
                this.parent_table = {};
        
                // reload automation from realtime manager
                const automation_inst = window.routeEvent.FOCUS_AUTOMATION();
                const { state, counter, index, set } = this.parseAutomation(automation_inst);
                this.nidCounter = counter;
                this.nidIndex = index;
                this.edgeSet = set;
                this.setState(state);
            }
        });
    }

    /**
     * Handle canvas keyboard event
     */
    handleKeyboard = (e: KeyboardEvent) => {
        const { changed } = this.state;
        if (e.keyCode === 83 && e.ctrlKey && changed) { e.preventDefault(); this.handleSave(); } // handle ctrl+s event
    }

    /**
     * Handle translate canvas json
     */
    get canvasJson(): CsxEventSystem.CsxAutomationJson {
        const cur_automation = window.routeEvent.FOCUS_AUTOMATION();
        const defaultName = Math.random().toString().slice(2, 8);
        const buffer: CsxEventSystem.CsxAutomationJson = {
            name: (cur_automation) ? cur_automation.NAME : defaultName,
            enabled: (cur_automation) ? cur_automation.ACTIVE : false,
            events: [], logics: [], actions: [], edges: [],
            cypDeviceSnapshot: {},
        };
        const { nodes, connections } = this.state;
        for (let i = 0; i < nodes.length; i++) {
            const node = nodes[i];
            if (CsxEventSystem.isCsxEventType(node.type) && CsxEventSystem.isCsxEventParameter(node.data)) {
                buffer.events.push({
                    id: node.nid.toString(),
                    type: CsxEventSystem.csxEventTypeIndex(node.type),
                    param: CsxEventSystem.CsxEventParameterToJson(node.data),
                    coordinate: { x: node.x, y: node.y },
                });
            } else if (CsxEventSystem.isCsxLogicType(node.type) && CsxEventSystem.isCsxLogicParameter(node.data)) {
                buffer.logics.push({
                    id: node.nid.toString(),
                    type: CsxEventSystem.csxLogicTypeIndex(node.type),
                    param: CsxEventSystem.CsxLogicParameterToJson(node.data),
                    coordinate: { x: node.x, y: node.y },
                });
            } else if (isCsxActionType(node.type) && CsxEventSystem.isCsxActionParameter(node.data)) {
                buffer.actions.push({
                    id: node.nid.toString(),
                    type: CsxEventSystem.csxActionTypeIndex(node.type),
                    param: CsxEventSystem.CsxActionParameterToJson(node.data),
                    coordinate: { x: node.x, y: node.y },
                });
            }
        }
        for (let i = 0; i < connections.length; i++) {
            const edge = connections[i];
            buffer.edges.push({ fromNode: edge.from_node.toString(), toNode: edge.to_node.toString() });
        }

        buffer.actions.forEach(action => {
            if (action.type === CsxEventSystem.csxActionTypeIndex('CYPAction')) {
                const { device, command } = action.param;
                if (buffer.cypDeviceSnapshot && typeof device === 'string' && typeof command === 'string') {
                    const devinst = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(device) : undefined;
                    if (devinst && typeof buffer.cypDeviceSnapshot[device] === 'undefined') {
                        buffer.cypDeviceSnapshot[device] = {
                            id: device,
                            name: devinst.NICKNAME,
                            taskDescription: `Send "${command}" to ${devinst.NICKNAME}`,
                        };
                    }
                }
            }
        });
        buffer.events.forEach(event => {
            if (event.type === CsxEventSystem.csxEventTypeIndex('CYPDeviceEvent')) {
                const { device, command } = event.param;
                if (buffer.cypDeviceSnapshot && typeof device === 'string' && typeof command === 'string') {
                    const devinst = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(device) : undefined;
                    if (devinst && typeof buffer.cypDeviceSnapshot[device] === 'undefined') {
                        buffer.cypDeviceSnapshot[device] = {
                            id: device,
                            name: devinst.NICKNAME,
                            taskDescription: `Detect "${command}" event from ${devinst.NICKNAME}`,
                        };
                    }
                }
            }
        });

        return buffer;
    }

    /**
     * Check parent_table contains cycle
     */
    isCanvasExistCircular = () => {
        let is_exist = false;
        for (const nid in this.parent_table) {
            if (Object.prototype.hasOwnProperty.call(this.parent_table, nid)) {
                if (this.parent_table[nid].has(`${nid}`)) {
                    is_exist = true;
                    break;
                }
            }
        }
        return is_exist;
    }

    /**
     * Refresh node's topology parents
     */
    refreshNodeParents = (nid: number) => {
        const parent_nodes_set = this.parent_table[nid];
        const clone_parent_nodes_set = new Set(parent_nodes_set);
        let should_refresh_again = false;
        if (parent_nodes_set) {
            parent_nodes_set.forEach(parent_nid => {
                const parent_nodes_set_of_parent = this.parent_table[parent_nid];
                if (parent_nodes_set_of_parent) {
                    parent_nodes_set_of_parent.forEach(parent_of_parent_nid => {
                        if (!parent_nodes_set.has(parent_of_parent_nid)) {
                            clone_parent_nodes_set.add(parent_of_parent_nid);
                            should_refresh_again = true;
                        }
                    });
                }
            });
            this.parent_table[nid] = clone_parent_nodes_set;
            if (should_refresh_again) { this.refreshNodeParents(nid); }
        } else {
            Notify({ title: getText('NOTIFY_TITLE_ERROR'), context: getText('NOTIFY_MSG_CHECK_CIRCULAR_ERR'), type: 'error', timeout: 10000 });
        }
    }

    /**
     * Scan whole canvas to detect whether any cycle exists
     */
    topologyCheckCyle = () => {
        const { nodes, connections } = this.state;
        let exist = false;
        this.parent_table = {};
        nodes.forEach(node => { this.parent_table[node.nid] = new Set(); });
        connections.forEach((edge) => {
            // refresh all node's parent
            this.parent_table[edge.to_node].add(`${edge.from_node}`);
            this.refreshNodeParents(edge.to_node);
        });
        exist = this.isCanvasExistCircular();
        if (exist) {
            Notify({ type: 'warning', title: getText('NOTIFY_TITLE_TOPOLOGY_WARNING'), context: getText('NOTIFY_MSG_CIRCULAR_EXIST'), timeout: 10000 });
        }
        this.setState({ isCycleExist: exist });
    }

    /**
     * Parse automation to component state
     */
    parseAutomation(automation: CsxEventSystem.CsxAutomation | undefined): ({ state: CanvasState; index: { [s: string]: number }; counter: number; set: Set<string> }) {
        const initState: CanvasState = {
            nodes: [], connections: [],
            outputEnable: {}, inputEnable: {},
            disableDrag: false, popup: -1, changed: false, mode: 'normal',
            offset: { x: 0, y: 0 },
            isCycleExist: false, whoAskLink: -1,
        };
        let nidIndex: { [s: string]: number } = {};
        let nidCounter = 0;
        const edgeSet: Set<string> = new Set();
        if (automation) {
            const evt_nodes: Array<Topology.Node> = Array.from(automation.EVENT_SET).map((evt_nid: string): Topology.Node => {
                const evt_inst = automation.getEvent(evt_nid);
                const default_data: CsxEventSystem.CsxEventParameter = { name: '', lockOnCanvas: false };
                let ret_node: Topology.Node = { nid: -1, type: 'NullEvent', x: 0, y: 0, fields: { in: [], out: [] }, data: default_data, parents: new Set() };
                if (evt_inst) {
                    const nid = parseInt(evt_inst.ID);
                    ret_node = {
                        nid: nid,
                        type: evt_inst.TYPE,
                        x: evt_inst.POSITION.x,
                        y: evt_inst.POSITION.y,
                        fields: this.fillEventField(evt_inst.TYPE, evt_inst.PARAM),
                        data: evt_inst.PARAM,
                        parents: new Set(),
                    }
                    nidCounter = (nid >= nidCounter) ? nid + 1 : nidCounter;
                }
                return ret_node;
            });
            const lgc_nodes: Array<Topology.Node> = Array.from(automation.LOGIC_SET).map((lgc_nid: string): Topology.Node => {
                const lgc_inst = automation.getLogic(lgc_nid);
                const default_data: CsxEventSystem.CsxLogicParameter = { lockOnCanvas: false };
                let ret_node: Topology.Node = { nid: -1, type: 'NullLogic', x: 0, y: 0, fields: { in: [], out: [] }, data: default_data, parents: new Set() };
                if (lgc_inst) {
                    const nid = parseInt(lgc_inst.ID);
                    ret_node = {
                        nid: parseInt(lgc_inst.ID),
                        type: lgc_inst.TYPE,
                        x: lgc_inst.POSITION.x,
                        y: lgc_inst.POSITION.y,
                        fields: this.fillLogicField(lgc_inst.TYPE, lgc_inst.PARAM),
                        data: lgc_inst.PARAM,
                        parents: new Set(),
                    }
                    nidCounter = (nid >= nidCounter) ? nid + 1 : nidCounter;
                }
                return ret_node;
            });
            const act_nodes: Array<Topology.Node> = Array.from(automation.ACTION_SET).map((act_nid: string): Topology.Node => {
                const act_inst = automation.getAction(act_nid);
                const default_data: CsxEventSystem.CsxActionParameter = { name: '', priority: 0, lockOnCanvas: false, delay: 0 };
                let ret_node: Topology.Node = { nid: -1, type: 'NullAction', x: 0, y: 0, fields: { in: [], out: [] }, data: default_data, parents: new Set() };
                if (act_inst) {
                    const nid = parseInt(act_inst.ID);
                    ret_node = {
                        nid: parseInt(act_inst.ID),
                        type: act_inst.TYPE,
                        x: act_inst.POSITION.x,
                        y: act_inst.POSITION.y,
                        fields: this.fillActionField(act_inst.TYPE, act_inst.PARAM),
                        data: act_inst.PARAM,
                        parents: new Set(),
                    }
                    nidCounter = (nid >= nidCounter) ? nid + 1 : nidCounter;
                }
                return ret_node;
            });
            initState.nodes = initState.nodes.concat(evt_nodes).concat(lgc_nodes).concat(act_nodes);
            nidIndex = initState.nodes.reduce((acc: any, cur, idx) => { acc[cur.nid] = idx; return acc; }, {});
            const { nodes } = initState;
            const connections: Array<Topology.Edge> = automation.EDGES.map((edge): Topology.Edge => {
                const from_node = parseInt(edge.from);
                const to_node = parseInt(edge.to);
                const from = nodes[nidIndex[edge.from]].fields.out[0].name;
                const to = nodes[nidIndex[edge.to]].fields.in[0].name;
                return { from_node, to_node, from, to };
            });
            const outputEnable = nodes.reduce((acc: any, cur) => { acc[cur.nid] = cur.fields.out.map((output: any) => false); return acc; }, {});
            const inputEnable = nodes.reduce((acc: any, cur) => { acc[cur.nid] = cur.fields.in.map((input: any) => false); return acc; }, {});
            connections.forEach((edge) => {
                edgeSet.add(`${edge.from_node}_${edge.from}_${edge.to_node}_${edge.to}`);
                nodes[nidIndex[edge.from_node]].fields.out.forEach((output, idx) => { outputEnable[edge.from_node][idx] = (outputEnable[edge.from_node][idx] || output.name === edge.from); });
                nodes[nidIndex[edge.to_node]].fields.in.forEach((input, idx) => { inputEnable[edge.to_node][idx] = (inputEnable[edge.to_node][idx] || input.name === edge.to); });
            });
            initState.connections = connections;
            initState.inputEnable = inputEnable;
            initState.outputEnable = outputEnable;
        }
        return { state: initState, index: nidIndex, counter: nidCounter, set: edgeSet };
    }

    /**
     * Refresh box Node insight filled
     */
    refreshNode = () => {
        const { nodes, connections } = this.state;
        const outputEnable = nodes.reduce((acc: any, cur) => { acc[cur.nid] = cur.fields.out.map((_) => false); return acc; }, {});
        const inputEnable = nodes.reduce((acc: any, cur) => { acc[cur.nid] = cur.fields.in.map((_) => false); return acc; }, {});
        connections.forEach((edge) => {
            nodes[this.nidIndex[edge.from_node]].fields.out.forEach((output, idx) => { outputEnable[edge.from_node][idx] = (outputEnable[edge.from_node][idx] || output.name === edge.from); });
            nodes[this.nidIndex[edge.to_node]].fields.in.forEach((input, idx) => { inputEnable[edge.to_node][idx] = (inputEnable[edge.to_node][idx] || input.name === edge.to); });
        });
        this.setState({ outputEnable, inputEnable });
    }

    /**
     * Popup modal view
     */
    openModal = (view: number) => { window.canvas.curNode = view; this.setState({ popup: view }); }

    /**
     * Popup modal view
     */
    closeModal = () => { window.canvas.curNode = -1; this.setState({ popup: -1 }); }

    /**
     * Run action test
     */
    handleRunTest = (nid: number) => {
        const { nodes } = this.state;
        const node = nodes[this.nidIndex[nid]];
        if (node && CsxEventSystem.isCsxActionType(node.type) && CsxEventSystem.isCsxActionParameter(node.data)) {
            const action_object = {
                id: node.nid.toString(),
                type: CsxEventSystem.csxActionTypeIndex(node.type),
                param: CsxEventSystem.CsxActionParameterToJson(node.data),
                coordinate: { x: node.x, y: node.y },
            }
            this.instance.RunActionTest(action_object);
        }
    }

    /**
     * Handle box dragging start
     */
    handleStart = (_: number, e: DraggableEvent) => {
        if (isTouchEvent(e)) {
            const first_touch = e.touches.item(0);
            if (first_touch) {
                this.cursorX = first_touch.clientX;
                this.cursorY = first_touch.clientY;
            }
        }
        if (isMouseEvent(e)) {
            this.cursorX = e.clientX;
            this.cursorY = e.clientY;
        }
        if (this.cursorX && this.cursorY) {
            this.cursorX = this.cursorX / window.canvas.zoom;
            this.cursorY = this.cursorY / window.canvas.zoom;
        }
    }

    /**
     * Handle box dragging
     */
    handleDrag = (nid: number, e: DraggableEvent) => {
        // const newState = CsxUtil.nestedClone(this.state);
        const newState = { ...this.state };
        const newNodes = [...newState.nodes];
        let cur_x = 0;
        let cur_y = 0;
        if (isMouseEvent(e)) { cur_x = e.clientX; cur_y = e.clientY; }
        if (isTouchEvent(e)) { const touch_item = e.touches.item(0); if (touch_item) { cur_x = touch_item.clientX; cur_y = touch_item.clientY; } }
        cur_x = cur_x / window.canvas.zoom;
        cur_y = cur_y / window.canvas.zoom;
        const new_x = newNodes[this.nidIndex[nid]].x + (cur_x - (this.cursorX ? this.cursorX : 0));
        const new_y = newNodes[this.nidIndex[nid]].y + (cur_y - (this.cursorY ? this.cursorY : 0));
        newNodes[this.nidIndex[nid]].x = new_x;
        newNodes[this.nidIndex[nid]].y = new_y;
        this.cursorX = cur_x;
        this.cursorY = cur_y;
        newState.nodes = newNodes;
        newState.changed = true;
        this.setState(newState);
    }

    /**
     * Handle lock/unlock box dragging
     */
    handleFixed = (nid: number) => {
        const newState = CsxUtil.nestedClone(this.state);
        const newNodes = [...newState.nodes];
        newNodes[this.nidIndex[nid]].data.lockOnCanvas = !newNodes[this.nidIndex[nid]].data.lockOnCanvas;
        newState.changed = true;
        this.setState({ nodes: newNodes });
    }

    /**
     * Handle box dragging stop
     */
    handleStop = (nid: number) => {
        const newState = { ...this.state };
        const newNodes = [...newState.nodes];
        const node = newNodes[this.nidIndex[nid]];
        // const { zoom } = window.canvas;
        const new_x = node.x;
        const new_y = node.y;

        newNodes[this.nidIndex[nid]].x = new_x;
        newNodes[this.nidIndex[nid]].y = new_y;
        newState.nodes = newNodes;
        newState.changed = true;
        this.setState(newState);
    }

    /**
     * Handle draggable box delete
     */
    handleDelete = (nid: number) => {
        this.setState((prevState: CanvasState) => {
            const newNodes = [...prevState.nodes];
            const newConnections = prevState.connections.filter((connections: any) => (connections.from_node !== nid && connections.to_node !== nid));
            newNodes.splice(this.nidIndex[nid], 1);
            this.nidIndex = newNodes.reduce((acc: any, cur, idx) => { acc[cur.nid] = idx; return acc; }, {});
            return { nodes: newNodes, connections: newConnections, changed: true };
        }, () => {
            this.refreshNode();
            this.topologyCheckCyle();
        });
    }

    /**
     * Handle draggable box add
     */
    handleAdd = (type: CsxEventSystem.CsxNodeType, position?: { x: number; y: number }) => {
        this.setState((prevState: CanvasState) => {
            const newNodes = [...prevState.nodes];
            const defaultData = this.DEFAULT_DATA_TABLE[type];
            const addData = ((defaultData) ? defaultData : { lockOnCanvas: false });
            let new_field: { in: Topology.NodePort[]; out: Topology.NodePort[] } = { in: [], out: [] };
            if (CsxEventSystem.isCsxEventType(type) && CsxEventSystem.isCsxEventParameter(addData)) {
                new_field = this.fillEventField(type, addData);
            } else if (CsxEventSystem.isCsxLogicType(type) && CsxEventSystem.isCsxLogicParameter(addData)) {
                new_field = this.fillLogicField(type, addData);
            } else if (CsxEventSystem.isCsxActionType(type) && CsxEventSystem.isCsxActionParameter(addData)) {
                new_field = this.fillActionField(type, addData);
            }
            this.parent_table[this.nidCounter] = new Set();
            newNodes.push({
                nid: this.nidCounter,
                type,
                x: (position) ? position.x : (prevState.offset.x * -1),
                y: (position) ? position.y : (prevState.offset.y * -1),
                fields: new_field,
                data: addData,
                parents: new Set(),
            });
            const outputEnable = newNodes.reduce((acc: any, cur) => { acc[cur.nid] = cur.fields.out.map((output: any) => false); return acc; }, {});
            const inputEnable = newNodes.reduce((acc: any, cur) => { acc[cur.nid] = cur.fields.in.map((input: any) => false); return acc; }, {});
            return { nodes: newNodes, inputEnable, outputEnable, changed: true };
        }, () => {
            this.nidCounter++;
            this.nidIndex = this.state.nodes.reduce((acc: any, cur, idx) => { acc[cur.nid] = idx; return acc; }, {});
            this.refreshNode();
        });
    }

    /**
     * Handle modify node port description
     */
    handleSetPort = (nid: number, dir: 'in' | 'out', pidx: number, name: string) => {
        this.setState((prevState: CanvasState): Partial<CanvasState> => {
            const newNodes = [...prevState.nodes];
            newNodes[this.nidIndex[nid]].fields[dir][pidx].name = name;
            return { nodes: newNodes, changed: true };
        });
    }

    /**
     * Handle modify node hidden data (param)
     */
    handleSetData = (nid: number, data: any) => {
        this.setState((prevState: CanvasState): Partial<CanvasState> => {
            const newNodes = [...prevState.nodes];
            newNodes[this.nidIndex[nid]].data = data;
            return { nodes: newNodes, changed: true };
        });
        this.closeModal();
    }

    /**
     * Handle get node information
     */
    handleGet = (nid: number) => { return this.state.nodes[this.nidIndex[nid]]; }

    /**
     * Handle save canvas information
     */
    handleSave = () => {
        const cur_automation = window.routeEvent.FOCUS_AUTOMATION();
        if (cur_automation) {
            const new_auto = new CsxEventSystem.CsxAutomation(cur_automation.ID, this.canvasJson);
            if (window.CSX_CUR_AUTH && csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission("automation_edit"), CsxUserPermissionLevel.EditAssigned)) {
                this.instance.SetAutomation(new_auto);
                this.setState({ changed: false });
                Notify({ title: getText('NOTIFY_TITLE_SUCCESS'), context: getText('NOTIFY_MSG_AUTOMATION_SAVE'), type: 'success', });
            } else {
                Notify({ title: getText('NOTIFY_TITLE_ERROR'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error', });
            }
        }
    }

    /**
     * Handle automation import
     */
    handleImport = (event: React.ChangeEvent<HTMLInputElement>) => {
        const notify_title = getText('NOTIFY_TITLE_IMPORT_AUTOMATION');
        const json_file = (event.target.files) ? event.target.files[0] : undefined;
        if (json_file) {
            CsxUtil.importHandler({
                file: json_file,
                validateFilename: (fn) => (fn.split('.').slice(-2).join('.').toLowerCase() === 'auto.json'),
                userConfirm: getText('CONFIRM_IMPORT_AUTOMATION_CANVAS'),
                onConfirm: (parseJson) => {
                    if (CsxEventSystem.isCsxAutomationJson(parseJson) || CsxEventSystem.Old_isCsxAutomationJson(parseJson)) {
                        /* Notify if json file has old format */
                        if (!CsxEventSystem.isCsxAutomationJson(parseJson) && CsxEventSystem.Old_isCsxAutomationJson(parseJson))
                            Notify({ type: 'warning', title: notify_title, context: getText('NOTIFY_MSG_DEPRECATE_FORMAT'), timeout: 5000 });

                        const cur_automation = window.routeEvent.FOCUS_AUTOMATION();
                        const pid = (cur_automation) ? cur_automation.ID : `${Math.random().toString().slice(2, 8)}`;
                        const temp_automation = new CsxEventSystem.CsxAutomation(pid, parseJson);
                        const { state, counter, index, set } = this.parseAutomation(temp_automation);
                        let cause_cycle = false;
                        this.nidCounter = counter;
                        this.nidIndex = index;
                        this.edgeSet = set;
                        state.changed = true;
                        this.parent_table = {};
                        state.nodes.forEach(node => { this.parent_table[node.nid] = new Set(); });
                        state.connections.forEach(edge => {
                            this.parent_table[edge.to_node].add(`${edge.from_node}`);
                            this.refreshNodeParents(edge.to_node);
                        });
                        cause_cycle = this.isCanvasExistCircular();
                        state.isCycleExist = cause_cycle;
                        if (cause_cycle)
                            Notify({ type: 'warning', title: getText('NOTIFY_TITLE_TOPOLOGY_WARNING'), context: getText('NOTIFY_MSG_CIRCULAR_EXIST'), timeout: 10000 });
                        this.setState(state);

                    } else {
                        Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_FILE_FORMAT_INVALID'), timeout: 10000 });
                    }
                },
                onError: (msg, timeout) => {
                    Notify({ type: 'error', title: notify_title, context: msg, timeout });
                }
            });
        }
        event.target.value = '';
    }

    /**
     * Handle automation export
     */
    handleExport = () => {
        const automation_json = this.canvasJson;
        const time = DateTime.FormatDateTime(new Date(), 'YYYYMMDDHHmmss');
        const filename = CsxUtil.mapFilename(automation_json.name);
        CsxUtil.downloadAsJson(`automation-${filename}-${time}.auto.json`, JSON.stringify(automation_json, null, 4));
    }

    /**
     * Handle canvas zoom in
     */
    handleZoomIn = () => { const { zoom } = window.canvas; window.canvas.zoom = ((zoom + DEFAULT_SCALE_STEP) > MAX_SCALE_RATE) ? MAX_SCALE_RATE : parseFloat((zoom + DEFAULT_SCALE_STEP).toFixed(10)); this.forceUpdate(); }

    /**
     * Handle canvas zoom out
     */
    handleZoomOut = () => { const { zoom } = window.canvas; window.canvas.zoom = ((zoom - DEFAULT_SCALE_STEP) < MIN_SCALE_RATE) ? MIN_SCALE_RATE : parseFloat((zoom - DEFAULT_SCALE_STEP).toFixed(10)); this.forceUpdate(); }

    /**
     * Handle canvas zoom out
     */
    handleZoom = (v: number) => { window.canvas.zoom = v; this.forceUpdate(); }

    /**
     * Handle canvas mode switch
     */
    handleSwitchMode = (mode: string) => { this.setState({ mode }); }


    handleLinkAttach = (nid: number, in_pid: number, _: DraggableEvent) => {
        const node = this.state.nodes[this.nidIndex[nid]];
        const connections = this.state.connections;
        let cause_cycle = false;
        if (node && !isCsxEventType(node.type)) {
            if (this.tempConnections && this.tempConnections.from_node !== nid) { // from and to cannot be the same node
                
                /* NOT gate should only handle single source */
                if (node.type === 'NotLogic') {
                    for (let i = 0; i < connections.length; i++) {
                        const connection = connections[i];
                        if (connection.to_node === nid) {
                            Notify({ type: 'warning', title: getText('NOTIFY_TITLE_LOGIC_WARNING'), context: getText('NOTIFY_MSG_NOT_GATE_SINGLE_SRC'), timeout: 10000 });
                            return;
                        }
                    }
                }

                /* create new connection */
                this.setState((prevState: CanvasState): Partial<CanvasState> => {
                    const newConnections = [...prevState.connections];
                    const edge = {
                        to: this.state.nodes[this.nidIndex[nid]].fields.in[in_pid].name,
                        from: (this.tempConnections && this.tempConnections.from) ? this.tempConnections.from : '',
                        to_node: nid,
                        from_node: (this.tempConnections && typeof this.tempConnections.from_node === 'number') ? this.tempConnections.from_node : -1,
                    }
                    newConnections.push(edge);
                    const edgeID = `${edge.from_node}_${edge.from}_${edge.to_node}_${edge.to}`;
                    // if (this.edgeSet.has(edgeID))
                    //     return { disableDrag: false, };
                    if (!this.edgeSet.has(edgeID)) {
                        this.edgeSet.add(edgeID);
                        this.parent_table[edge.to_node].add(`${edge.from_node}`);
                        this.refreshNodeParents(nid);
                        cause_cycle = this.isCanvasExistCircular(); 
                        if (cause_cycle) {
                            Notify({ type: 'warning', title: getText('NOTIFY_TITLE_TOPOLOGY_WARNING'), context: getText('NOTIFY_MSG_CIRCULAR_EXIST'), timeout: 10000 });
                        }
                        return { changed: true, connections: newConnections, isCycleExist: cause_cycle, whoAskLink: -1 };
                    } else {
                        return { whoAskLink: -1 };
                    }
                });
            }
        }
    }

    /**
     * Handle box Node holding mouse press 
     * 
     * This method creates a temporary edge in this.state, and keeps its connection property the same time.
     * When creating temporary edge, a mousemove event listerner is added for buffering edge path and cursor position.
     * This method is only triggered by box output Node.
     */
    handleNewLink = (nid: number, out_pid: number, e: DraggableEvent) => {
        const { nodes } = this.state;
        const node = nodes[this.nidIndex[nid]];
        const newOutputEnable = { ...this.state.outputEnable };
        newOutputEnable[nid][out_pid] = true;
        
        let cur_x = 0;
        let cur_y = 0;
        if (isMouseEvent(e)) {
            cur_x = e.clientX;
            cur_y = e.clientY;
        }
        if (isTouchEvent(e)) {
            const touch_item = e.touches.item(0);
            cur_x = touch_item.clientX;
            cur_y = touch_item.clientY;
        }
        this.setState({
            tempEdge: {
                from_x: node.x + (CsxEventSystem.isCsxLogicType(node.type)?40:Topology.DEFAULT_BOX_WIDTH),
                from_y: node.y + (CsxEventSystem.isCsxLogicType(node.type)?20:Topology.calc_h(out_pid) + 9),
                to_x: cur_x - DEFAULT_LEFT_COLLAPSER_OFFSET,
                to_y: cur_y,
            },
            outputEnable: newOutputEnable,
        }, () => {
            document.addEventListener('mousemove', this.handleMouseDragNewLink, false);
            document.addEventListener('mouseup', this.handleFreeTempEdge, false);
            document.addEventListener('touchmove', this.handleTouchDragNewLink, false);
            document.addEventListener('touchend', this.handleFreeTempEdge, false);
            this.tempConnections = { from_node: nid, from: this.state.nodes[this.nidIndex[nid]].fields.out[out_pid].name };
        });
    }

    /**
     * Handle mouse hover into box Node
     * 
     * When hovering into a box Node, this method removes the mouseup event listener because of events can be 
     * handled by onMouseUp callback. 
     * It alse blocks draggable box being dragged, which disturbs pulling temporary edge from output Node.
     */
    handleNodeMouseEnter = () => {
        // document.removeEventListener('mouseup', this.handleNodeMouseRelease, false);
        // this.setState({ disableDrag: true });
    }

    /**
     * Handle mouse hover out box Node
     * 
     * When hovering out a box Node, this method adds a mouseup event listener for handling mouse releasing at a wrong position,
     * for example, directly on canvas. As user releases mouse at a unhandled place, temporary buffer should be reset. 
     * It alse enables draggable box being dragged, which blocked by this.handleNodeMouseEnter.
     */
    /**
     * Handle edge removing
     */
    handleEdgeRemove = (idx: number) => {
        if (this.state.mode === 'cut') {
            this.setState((prevState: CanvasState) => {
                const newConnections = [...prevState.connections];
                const edge = newConnections[idx];
                const edgeID = `${edge.from_node}_${edge.from}_${edge.to_node}_${edge.to}`;
                this.edgeSet.delete(edgeID);
                newConnections.splice(idx, 1);
                return { connections: newConnections, changed: true };
            }, () => {
                this.refreshNode();
                this.topologyCheckCyle();
            });
        }
    }

    /**
     * Handle temporary edge dragging action
     */
    handleDragNewLink = (x: number, y: number) => {
        this.setState((prevState: CanvasState) => {
            if (prevState.tempEdge) {
                const newTempEdge = { ...prevState.tempEdge };
                newTempEdge.to_x = x - DEFAULT_LEFT_COLLAPSER_OFFSET;
                newTempEdge.to_y = y;
                return { tempEdge: newTempEdge };
            } else {
                return {};
            }
        });
    }
    handleMouseDragNewLink = (e: MouseEvent) => {
        this.handleDragNewLink(e.clientX, e.clientY);
    }
    handleTouchDragNewLink = (e: TouchEvent) => {
        const touch_item = e.touches.item(0);
        if (touch_item)
            this.handleDragNewLink(touch_item.clientX, touch_item.clientY);
    }

    handleFreeTempEdge = () => {
        const { whoAskLink } = this.state;
        global.setTimeout(() => {
            console.log('handleFreeTempEdge');
            document.removeEventListener('mousemove', this.handleMouseDragNewLink, false); // addEventListener in this.handleNewLink
            document.removeEventListener('touchmove', this.handleTouchDragNewLink, false); // addEventListener in this.handleNewLink
            document.removeEventListener('mouseup', this.handleFreeTempEdge, false); // addEventListener in this.handleNewLink
            document.removeEventListener('touchend', this.handleFreeTempEdge, false); // addEventListener in this.handleNewLink
            document.removeEventListener('mousedown', this.handleFreeTempEdge, false); // addEventListener in this.handleAttempLinkByLongTouch
            document.removeEventListener('touchstart', this.handleFreeTempEdge, false); // addEventListener in this.handleAttempLinkByLongTouch
            this.tempConnections = undefined;
            this.setState({ tempEdge: undefined, whoAskLink: -1 });
            this.refreshNode();
        }, (whoAskLink >= 0) ? 200 : 50); // add delay to give this.handleLinkAttach complete, and delay should be different between using deag and using long touch
    }

    /**
     * handle link request triggered by mobile device touch event
     */
    handleAttempLinkByLongTouch = (nid: number, out_pid: number) => {
        const newOutputEnable = { ...this.state.outputEnable };
        // newOutputEnable[nid][out_pid] = true;
        document.addEventListener('mousedown', this.handleFreeTempEdge, false);
        document.addEventListener('touchstart', this.handleFreeTempEdge, false);
        this.setState({ whoAskLink: nid, outputEnable: newOutputEnable });
        this.tempConnections = { from_node: nid, from: this.state.nodes[this.nidIndex[nid]].fields.out[out_pid].name };
    }

    /**
     * Trigger fileSelector click
     */
    triggerFileSelector = () => {
        if (this.fileSelector)
            this.fileSelector.click();
        else
            window.alert(getText('ALERT_OP_UNSUPPORT_NATIVE_OUT'));
    } 
    
    /**
     * Drag Canvas
     * Handle Press
     */
    handlePressDownCanvas = (e: DraggableEvent) => {
        const { offset, mode } = this.state;
        if (mode !== 'grab')
            return;
        if (isTouchEvent(e)) {
            const first_touch = e.touches.item(0);
            if (first_touch) {
                this.canvasMoveStartX = first_touch.clientX - offset.x;
                this.canvasMoveStartY = first_touch.clientY - offset.y;
            }
        }
        if (isMouseEvent(e)) {
            this.canvasMoveStartX = e.clientX - offset.x;
            this.canvasMoveStartY = e.clientY - offset.y;
        }
        document.addEventListener('mousemove', this.handleMouseDragCanvas, false);
        document.addEventListener('mouseup', this.handleFreeDragCanvas, false);
        document.addEventListener('touchmove', this.handleTouchDragCanvas, false);
        document.addEventListener('touchend', this.handleFreeDragCanvas, false);
    }
    
    /**
     * Drag Canvas
     * Handle Drag
     */
    handleDragCanvas = (x: number, y: number) => {
        if (this.state.mode !== 'grab')
            return;
        this.setState((prevState: CanvasState) => {
            if (this.canvasMoveStartX && this.canvasMoveStartY) {
                const newOffset = { ...prevState.offset };
                newOffset.x = x - this.canvasMoveStartX;
                newOffset.y = y - this.canvasMoveStartY;
                return { offset: newOffset };
            }
        });
    }
    handleMouseDragCanvas = (e: MouseEvent) => {
        this.handleDragCanvas(e.clientX, e.clientY);
    }
    handleTouchDragCanvas = (e: TouchEvent) => {
        const touch_item = e.touches.item(0);
        if (touch_item)
            this.handleDragCanvas(touch_item.clientX, touch_item.clientY);
    }

    /**
     * Drag Canvas
     * Handle Release Drag
     */
    handleFreeDragCanvas = () => {
        if (this.state.mode !== 'grab')
            return;
        document.removeEventListener('mousemove', this.handleMouseDragCanvas, false);
        document.removeEventListener('touchmove', this.handleTouchDragCanvas, false);
        document.removeEventListener('mouseup', this.handleFreeDragCanvas, false);
        document.removeEventListener('touchend', this.handleFreeDragCanvas, false);
    }

    getDisplayName = (name: string) => {
        const devinst = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(name) : undefined;
        return (devinst && devinst.NICKNAME) ? devinst.NICKNAME : name;
    }

    render() {
        let tempBridge, tempPort;
        const automation_edit_permission = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getPermission('automation_edit') : CsxUserPermissionLevel.NoAccess;
        const automation_inst = window.routeEvent.FOCUS_AUTOMATION();
        const { nodes, connections, disableDrag, inputEnable, outputEnable, tempEdge, offset, mode, popup, changed, whoAskLink, isCycleExist } = this.state;
        const { zoom } = window.canvas;
        if (tempEdge) {
            let { to_x, to_y } = tempEdge;
            const { from_x, from_y } = tempEdge;
            to_x = (to_x ? to_x : 0);
            to_y = (to_y ? to_y : 0) - DEFAULT_HEADER_OFFSET;
            to_x *= 1/zoom;
            to_y *= 1/zoom;
            const controlPoint1 = [to_x - offset.x, from_y];
            const controlPoint2 = [from_x, to_y - offset.y];
            tempBridge = (<path
                d={`
                        M ${[from_x, from_y]}
                        C ${controlPoint1} ${controlPoint2} ${[to_x - offset.x, to_y - offset.y]}
                    `}
                fill="none"
                stroke="rgba(158, 8, 20, 0.7)"
                strokeWidth={5}
            />);
            tempPort = (<div
                className='temp-circle'
                style={{ top: to_y - offset.y, left: to_x - offset.x, }}
            />);
        }
        const boxes = nodes.map((node) => {
            if (node.type === 'CYPAction') {
                node.fields.out.forEach(output => output.displayName = this.getDisplayName(output.name));
            }
            else if (node.type === 'CYPDeviceEvent') {
                node.fields.in.forEach(input => input.displayName = this.getDisplayName(input.name));
            }
            const nodeProps: Topology.NodeProperties = {
                key: `node_${node.nid}`,
                node, inputEnable, outputEnable,
                handler: {
                    pinHandler: {
                        onPress: this.handleNewLink,
                        onLongPress: this.handleAttempLinkByLongTouch
                    },
                    nodeHandler: {
                        onRelease: this.handleLinkAttach,
                    },
                    draggableHandler: {
                        onStart: this.handleStart,
                        onStop: this.handleStop,
                        onDrag: this.handleDrag,
                        onDelete: this.handleDelete,
                        onOpen: this.openModal,
                        onRunTest: (CsxEventSystem.isCsxActionType(node.type) ? this.handleRunTest : undefined),
                        onFixed: this.handleFixed,
                    }
                },
                draggableProps: { handle: '.handle', disabled: disableDrag }
            };
            return (node.type in BLOCK_COMPONENT) ? BLOCK_COMPONENT[node.type](nodeProps) : undefined;
        }).filter(node => node);
        const edges = connections.map((edge, idx) => {
            const startNode = nodes[this.nidIndex[edge.from_node]];
            const endNode = nodes[this.nidIndex[edge.to_node]];
            let start: Point = [0, 0], end: Point = [0, 0];
            let control1: Point = [0, 0], control2: Point = [0, 0];
            for (let i = 0; i < startNode.fields.out.length; i++) {
                const output = startNode.fields.out[i];
                if (output.name === edge.from) {
                    if (CsxEventSystem.isCsxLogicType(startNode.type)) {
                        start = [startNode.x + 45, startNode.y + 20];
                    } else {
                        start = [startNode.x + Topology.DEFAULT_BOX_WIDTH, startNode.y + Topology.calc_h(i) + 9]; // node output point, x equals to start node x + DEFAULT_BOX_WIDTH, calculate y pos
                    }
                }
            }
            for (let i = 0; i < endNode.fields.in.length; i++) {
                const input = endNode.fields.in[i];
                if (input.name === edge.to) {
                    if (CsxEventSystem.isCsxLogicType(endNode.type)) {
                        end = [endNode.x - 5, endNode.y + 20]; // node input point, x the same as end node, calculate y pos
                    } else {
                        end = [endNode.x, endNode.y + Topology.calc_h(i) + 9]; // node input point, x the same as end node, calculate y pos
                    }
                }
                    
            }
            control1 = [end[0], start[1]];
            control2 = [start[0], end[1]];
            const curve: Curve = [ start, control1, control2, end ];
            const bicurve = bipartCurve(curve, 0.5);
            return <g key={`edge_${edge.from_node}_${edge.to_node}`}>{bazierCurve(bicurve[0], () => { this.handleEdgeRemove(idx); }, true)}{bazierCurve(bicurve[1], () => { this.handleEdgeRemove(idx); })}</g>
        }).filter(edge => edge);
        const lines = (<svg className='edge-layout'>
            <defs><marker id='arrow' viewBox='0 0 4 4'
                refY='1.5'
                refX='1'
                markerWidth='4'
                markerHeight='4'
                orient='auto'
            ><path d='M 0 0 L 3 1.5 L 0 3 Z' fill='rgba(158, 8, 20)'/>
            </marker></defs>
            {edges}
            {tempBridge}
        </svg>);
        const modal_idx = nodes.reduce((acc: any, cur) => { acc[cur.nid] = this.BLOCK_POP_COMPONENT[cur.type]; return acc; }, {});
        const modal = (popup > -1) ? modal_idx[popup] : undefined;
        const modal_title = (modal) ? modal.title : undefined;
        const modal_content = (modal) ? modal.content : undefined;
        const automation_name = (automation_inst) ? automation_inst.NAME : undefined;
        const BackButton = withRouter(({ history }) => (
            <Button
                icon='arrow-left'
                style={{ position: 'absolute', top: 0, right: 0, margin: '10px', zIndex: 2 }}
                type='danger'
                shape='round'
                onClick={() => {
                    // history.push('/management/es/automation/');
                    history.goBack();
                }}
            >
                {automation_name}
            </Button>
        ))
        return (
            <div className={`cypd-canvas ${mode}-mode ${(whoAskLink >= 0)?'highlight-nodes':''}`}
                onMouseDown={this.handlePressDownCanvas}
                onTouchStart={this.handlePressDownCanvas}
            >
                <div className='graph' style={{transformOrigin: '0 0', transform: `translate(${offset.x}px,${offset.y}px)`, width: `${100*(1/zoom)}%`, height: `${100*(1/zoom)}%`}}>
                    {boxes}
                    {lines}
                    {tempPort}
                </div>
                <Modal
                    title={modal_title}
                    visible={popup > -1}
                    onClose={this.closeModal}
                >{modal_content}</Modal>
                <Prompt when={(changed && csxUserPermissionSatisfy(automation_edit_permission, CsxUserPermissionLevel.EditAssigned))} message='Canvas are not saved. Leave without saving?' />
                <div className='canvas_float_bar'>
                    {(csxUserPermissionSatisfy(automation_edit_permission, CsxUserPermissionLevel.EditAssigned)) ? <Button
                        tooltip={getText('AUTOMATION_BUTTONTIP_CANVAS_IMPORT')}
                        tooltipDirection='left'
                        tooltipFixedWidth={150}
                        icon='upload'
                        onClick={this.triggerFileSelector}
                    /> : undefined}
                    <Button
                        tooltip={getText('AUTOMATION_BUTTONTIP_CANVAS_EXPORT')}
                        tooltipDirection='left'
                        tooltipFixedWidth={150}
                        icon='download'
                        onClick={this.handleExport}
                    />
                    {/* <Button icon='zoom-out' disabled={(zoom <= MIN_SCALE_RATE)} onClick={this.handleZoomOut} /> */}
                    {/* <Slider max={MAX_SCALE_RATE} min={MIN_SCALE_RATE} step={DEFAULT_SCALE_STEP} value={zoom} onChange={this.handleZoom} /> */}
                    {/* <Button icon='zoom-in' disabled={(zoom >= MAX_SCALE_RATE)} onClick={this.handleZoomIn} /> */}
                    <Button
                        tooltip={(mode!=='cut')?getText('AUTOMATION_BUTTONTIP_CANVAS_CUT'):getText('AUTOMATION_BUTTONTIP_CANVAS_EXIT_CUT')}
                        tooltipDirection='left'
                        tooltipFixedWidth={(mode!=='cut')?150:95}
                        icon={(mode!=='cut')?'scissors':'cancel'}
                        type={(mode!=='cut')?'default':'danger'}
                        onClick={() => { this.handleSwitchMode((mode!=='cut')?'cut':'normal'); }}
                    />
                    <Button
                        tooltip={(mode!=='cut')?getText('AUTOMATION_BUTTONTIP_CANVAS_MOVE'):getText('AUTOMATION_BUTTONTIP_CANVAS_EXIT_MOVE')}
                        tooltipDirection='left'
                        tooltipFixedWidth={(mode!=='cut')?150:95}
                        icon={(mode!=='grab')?'move':'cancel'}
                        type={(mode!=='grab')?'default':'danger'}
                        onClick={() => { this.handleSwitchMode((mode!=='grab')?'grab':'normal'); }}
                    />
                    <Button
                        tooltip={getText('AUTOMATION_BUTTONTIP_CANVAS_REVERT')}
                        tooltipDirection='left'
                        tooltipFixedWidth={150}
                        icon='revert'
                        onClick={this.handleRevert}
                    />
                    {(csxUserPermissionSatisfy(automation_edit_permission, CsxUserPermissionLevel.EditAssigned)) ? <Button
                        tooltip={getText('AUTOMATION_BUTTONTIP_CANVAS_SAVE')}
                        tooltipDirection='left'
                        tooltipFixedWidth={150}
                        icon='save'
                        disabled={!changed}
                        onClick={this.handleSave}
                    /> : undefined}
                    {isCycleExist ? <Tooltip text={getText('NOTIFY_MSG_CIRCULAR_EXIST')} fixedWidth={150} direction='left'><Icon style={{ marginRight: '3px' }} type='warning'/></Tooltip> : undefined}
                </div>
                <BackButton />
                <NodeSelector shaking={(boxes.length === 0)}/>
                {!window.APP_ON_HDMI ? <input type='file' accept='application/JSON' ref={(inst) => { this.fileSelector = inst; }} onChange={this.handleImport} style={{display: 'none'}}/> : undefined}
            </div>
        );
    }
}
