import React from 'react';

import { CsxDesc, CsxEventSystem } from '../../../csx';


declare type EventTreeNodeType = { type: CsxEventSystem.CsxEventType; label: string; tooltip?: string };
declare type LogicTreeNodeType = { type: CsxEventSystem.CsxLogicType; label: string; tooltip?: string };
declare type ActionTreeNodeType = { type: CsxEventSystem.CsxActionType; label: string; tooltip?: string };

export class NodeSelector extends React.Component<{ shaking: boolean }> {
    EVENT_BLOCK_OPTION: Array<EventTreeNodeType> = [
        { type: 'OneTimeEvent', label: CsxDesc.getText('EVENT_LABEL_ONCE') },
        { type: 'RepeatEvent', label: CsxDesc.getText('EVENT_LABEL_REPEAT') },
        { type: 'CYPDeviceEvent', label: CsxDesc.getText('EVENT_LABEL_CYPD') },
        { type: 'ExternalTCPEvent', label: CsxDesc.getText('EVENT_LABEL_EXT_TCP') },
        { type: 'ExternalRS232Event', label: CsxDesc.getText('EVENT_LABEL_EXT_RS232') },
        { type: 'TriggerInEvent', label: CsxDesc.getText('EVENT_LABEL_KEYPAD') },
        { type: 'PollingEvent', label: CsxDesc.getText('EVENT_LABEL_POLLING') },
        // { type: 'SystemEvent', label: CsxDesc.getText('EVENT_LABEL_SYSTEM') },
        { type: 'CustomCmdEvent', label: CsxDesc.getText('EVENT_LABEL_CUSTOM') },
    ];
    LOGIC_BLOCK_OPTION: Array<LogicTreeNodeType> = [
        { type: 'AndLogic', label: CsxDesc.getText('LOGIC_LABEL_AND') },
        { type: 'OrLogic', label: CsxDesc.getText('LOGIC_LABEL_OR') },
        { type: 'NotLogic', label: CsxDesc.getText('LOGIC_LABEL_NOT') },
    ];
    ACTION_BLOCK_OPTION: Array<ActionTreeNodeType> = [
        { type: 'CYPAction', label: CsxDesc.getText('ACTION_LABEL_CYP'), tooltip: CsxDesc.getText('ACTION_LABEL_TOOLTIP_CYP') },
        { type: 'TCPAction', label: CsxDesc.getText('ACTION_LABEL_TCP'), tooltip: CsxDesc.getText('ACTION_LABEL_TOOLTIP_TCP') },
        { type: 'UDPAction', label: CsxDesc.getText('ACTION_LABEL_UDP'), tooltip: CsxDesc.getText('ACTION_LABEL_TOOLTIP_UDP') },
        { type: 'RS232Action', label: CsxDesc.getText('ACTION_LABEL_RS232'), tooltip: CsxDesc.getText('ACTION_LABEL_TOOLTIP_RS232') },
    ];
    toggle: HTMLInputElement | undefined | null;
    addNode = (type: CsxEventSystem.CsxNodeType) => { window.canvas.addNode(type); }
    triggerToggle = () => { if (this.toggle) this.toggle.click(); }
    render() {
        const { shaking } = this.props;
        return (
            <div className={`automation_node_selector ${shaking ? 'shaking' : ''}`}>
                <input ref={inst => { this.toggle = inst; }} id='toggler' type='checkbox' />
                <label className='add_button' htmlFor='toggler' style={{ fontSize: '2em' }}>+</label>
                <div className='wrapper'><div className='list_view'>
                    <div className='header'><div className='title'>{CsxDesc.getText('EVENT_SYS_AUTOMATION_ADD_LABEL')}</div><div className='close' onClick={this.triggerToggle} /></div>
                    <div className='group'>
                        <div className='label'>{CsxDesc.getText('ACTION_LABEL')}</div>
                        {this.ACTION_BLOCK_OPTION.map((option, idx) => <div className='item' key={`action_add_${idx}`} onClick={() => { this.addNode(option.type); }}>{option.label}</div>)}
                    </div>
                    <div className='group'>
                        <div className='label'>{CsxDesc.getText('LOGIC_LABEL')}</div>
                        {this.LOGIC_BLOCK_OPTION.map((option, idx) => <div className='item' key={`logic_add_${idx}`} onClick={() => { this.addNode(option.type); }}>{option.label}</div>)}
                    </div>
                    <div className='group'>
                        <div className='label'>{CsxDesc.getText('EVENT_LABEL')}</div>
                        {this.EVENT_BLOCK_OPTION.map((option, idx) => <div className='item' key={`event_add_${idx}`} onClick={() => { this.addNode(option.type); }}>{option.label}</div>)}
                    </div>
                </div>
                </div>
            </div>
        );
    }
}

