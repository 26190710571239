import React from 'react';
import { Line } from 'react-chartjs-2';


interface GraphParameters {
    tooltips?: boolean;
    deviceId?: string;
    width?: number | string;
    visibility?: Array<boolean>;
    data: [Array<number>, Array<number>, Array<number>, Array<number>];
    threshold: Array<number>;
}

// const BUFFER_SIZE = 600;
const UPPER_BOUND = 0;
// const THRESHOLD = -15;
const LOWER_BOUND = -25;
const formatTime = (time: number) => {
    const datetime = new Date(time);
    return `${datetime.getHours()}:${datetime.getMinutes()}:${datetime.getSeconds()}`;
}
const dataset_opt = {
    fill: false,
    lineTension: 0.1,
    borderCapStyle: 'butt',
    borderDash: [],
    borderDashOffset: 0.0,
    borderJoinStyle: 'miter',
    pointBackgroundColor: '#fff',
    pointBorderWidth: 1,
    pointHoverRadius: 5,
    pointHoverBorderWidth: 2,
    pointRadius: 1,
    pointHitRadius: 10,
}
const BLUE_COLOR_CODE = '#0000FF';
const GREEN_COLOR_CODE = '#00DD00';
const PURPLE_COLOR_CODE = '#FF0088';
const BLACK_COLOR_CODE = '#DCDCDC';
const GRAY_COLOR_CODE = '#999999';
const RED_COLOR_CODE = '#FF0000';
const MSE_A_Line_Style = {
    label: 'CH A',
    backgroundColor: BLUE_COLOR_CODE,
    borderColor: BLUE_COLOR_CODE,
    pointBorderColor: BLUE_COLOR_CODE,
    pointHoverBackgroundColor: BLUE_COLOR_CODE,
    pointHoverBorderColor: BLACK_COLOR_CODE,
    pointRadius: 0,
}
const MSE_B_Line_Style = {
    label: 'CH B',
    backgroundColor: RED_COLOR_CODE,
    borderColor: RED_COLOR_CODE,
    pointBorderColor: RED_COLOR_CODE,
    pointHoverBackgroundColor: RED_COLOR_CODE,
    pointHoverBorderColor: BLACK_COLOR_CODE,
    pointRadius: 0,
}
const MSE_C_Line_Style = {
    label: 'CH C',
    backgroundColor: GREEN_COLOR_CODE,
    borderColor: GREEN_COLOR_CODE,
    pointBorderColor: GREEN_COLOR_CODE,
    pointHoverBackgroundColor: GREEN_COLOR_CODE,
    pointHoverBorderColor: BLACK_COLOR_CODE,
    pointRadius: 0,
}
const MSE_D_Line_Style = {
    label: 'CH D',
    backgroundColor: PURPLE_COLOR_CODE,
    borderColor: PURPLE_COLOR_CODE,
    pointBorderColor: PURPLE_COLOR_CODE,
    pointHoverBackgroundColor: PURPLE_COLOR_CODE,
    pointHoverBorderColor: BLACK_COLOR_CODE,
    pointRadius: 0,
}
const Threshold_Style = {
    label: 'Threshold',
    backgroundColor: GRAY_COLOR_CODE,
    borderColor: GRAY_COLOR_CODE,
    pointBorderColor: GRAY_COLOR_CODE,
    pointHoverBackgroundColor: GRAY_COLOR_CODE,
    pointHoverBorderColor: BLACK_COLOR_CODE,
    borderWidth: 1,
    pointRadius: 0,
}

export default class Graph extends React.Component<GraphParameters> {
    
    // state: any;
    startTime: number;
    // polling: NodeJS.Timeout;
    
    constructor(props: any) {
        super(props);
        this.startTime = new Date().getTime();
        // var chart_opt = { labels: Array.from(Array(60).keys()).map(offset => formatTime(this.startTime+(offset*1000))) }
        // this.state = {
        //     ...chart_opt,
        //     datasets: [
        //         { ...dataset_opt, ...MSE_A_Line_Style, data: [] },
        //         { ...dataset_opt, ...MSE_B_Line_Style, data: [] },
        //         { ...dataset_opt, ...MSE_C_Line_Style, data: [] },
        //         { ...dataset_opt, ...MSE_D_Line_Style, data: [] },
        //         {
        //             ...dataset_opt,
        //             ...Threshold_Style,
        //             data: Array.from(Array(60).keys()).map(e => THRESHOLD)
        //         },
        //     ]
        // };
        // this.polling = setInterval(() => { 
        //     var newDatasets = [ ...this.state.datasets ];
        //     for (let i = 0; i < newDatasets.length-1; i++) {
        //         var newData = [ ...newDatasets[i].data ];
        //         var rand = Math.random();
        //         var len = newData.length
        //         if (!len)
        //             rand = Math.floor(rand*(-25));
        //         else
        //             rand = newData[len-1] + Math.round(5*(0.5-rand));
        //         rand = (rand > UPPER_BOUND) ? UPPER_BOUND : rand;
        //         rand = (rand < LOWER_BOUND) ? LOWER_BOUND : rand;
        //         newData.push(rand);
        //         if (newData.length > chart_opt.labels.length)
        //             chart_opt.labels.push(formatTime(new Date().getTime()));
        //         if (newData.length > BUFFER_SIZE) {
        //             newData.splice(0, 1);
        //             chart_opt.labels.splice(0, 1);
        //         }
        //         newDatasets[i].data = newData;
        //         newDatasets[i].showLine = (!this.props.visibility || this.props.visibility[i]);
        //     }
        //     if (newDatasets[newDatasets.length-1].data.length < chart_opt.labels.length)
        //         newDatasets[newDatasets.length-1].data.push(THRESHOLD);
        //     this.setState({
        //         ...chart_opt,
        //         datasets: newDatasets
        //     }); 
        // }, 1000);
    }

    // componentWillUnmount() {
    //     clearInterval(this.polling);
    // }
    
    render() {
        const chart_opt = { labels: Array.from(Array(this.props.threshold.length).keys()).map(offset => formatTime(this.startTime+(offset*1000))) }
        return (
            <div className='container'>
                <Line 
                    data={{
                        ...chart_opt,
                        datasets: [
                            { ...dataset_opt, ...MSE_A_Line_Style, data: this.props.data[0] },
                            { ...dataset_opt, ...MSE_B_Line_Style, data: this.props.data[1] },
                            { ...dataset_opt, ...MSE_C_Line_Style, data: this.props.data[2] },
                            { ...dataset_opt, ...MSE_D_Line_Style, data: this.props.data[3] },
                            { ...dataset_opt, ...Threshold_Style, data: this.props.threshold },
                        ]
                    }} 
                    options={{
                        tooltips: { enabled: (this.props.tooltips) },
                        scales: { 
                            yAxes: [{
                                ticks: {
                                    stepSize: 10,
                                    suggestedMin: LOWER_BOUND,
                                    suggestedMax: UPPER_BOUND,
                                },
                                // scaleLabel: {
                                //     display: true,
                                //     labelString: 'MSE[dB]',
                                //     fontStyle: 'italic',
                                // }
                            }], 
                            xAxes: [{
                                ticks: { 
                                    maxRotation: 0,
                                    minRotation: 0,
                                    maxTicksLimit: 5 
                                },
                            }] 
                        },
                        animation: { duration: 0 },
                        legend: {
                            position: 'right',
                            align: 'end',
                            labels: { boxWidth: 12 }
                        }
                    }} 
                />
            </div>
        );
    }
}
