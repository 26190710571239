import React from 'react';
import { withRouter } from 'react-router-dom';
import { Href, Flat } from 'cypd';

import { CsxUI, CsxFeature } from '../../csx';
import ConfigurationPage from './scenario_config/config';
import ScenarioOverview from './scenario_list/view';
import { getText } from '../../csx/desc';

class Breadcrum extends CsxUI.IRQComponent<any> {
    instance: CsxFeature.CsxEventSystemDevice;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'EventSystem';
        this.instance = new CsxFeature.CsxEventSystemDevice();
    }
    render() {
        const curURLParameters = this.getURLParameter();
        const scenario_id = curURLParameters.get('scenario_id');
        const scenario = (scenario_id) ? this.instance.Scenario(scenario_id) : undefined;
        const Component = withRouter(({history}) => (
            <div className='breadcrum'>
                <Href className='breadcrum-item' to={() => { history.push(`/management/es/scenario`); }}>{getText('APP_NAV_SCENARIO')}</Href>
                {scenario?<Href className='breadcrum-item' to={() => { history.push(`/management/es/scenario/?scenario_id=${scenario_id}`); }}>{scenario.NAME}</Href>:undefined}
            </div>
        ));
        return <Component />;
    }
}

export default class SenarioPage extends CsxUI.IRQComponent<any> {
    instance: CsxFeature.CsxEventSystemDevice;
    constructor(props: any) {
        super(props);
        this.instance = new CsxFeature.CsxEventSystemDevice();
    }
    render() {
        const curURLParameters = this.getURLParameter();
        const scenario_id = curURLParameters.get('scenario_id');
        let center = <ScenarioOverview />;
        if (scenario_id && this.instance.Scenario(scenario_id))
            center = <ConfigurationPage />;
        return <Flat.Playground style={{ height: '100%' }} contextStyle={{ height: 'auto' }}>
            <Breadcrum />
            {center}
        </Flat.Playground>;
    }
}
