import React from 'react';

import { DateTime, Form, Button, Input, Select, Option, Checkbox, RadioGroup, SwitchButton } from 'cypd';
import { CsxUtil, CsxEventSystem, CsxDesc, CsxUI, CsxFeature } from '../../../csx';
import { IRQComponent } from '../../../csx/ui';
import { CsxTrailType, isCsxTrailType } from '../../../csx/event';


declare type CMD_INPUT_TYPE = 'plain' | 'hex';

const {pureHexStringToCypHexString, cypHexStringToString, stringToCypHexString, cypHexStringToPureHexString, CYP_HEX_STRING_CHECK_PATTERN  } = CsxEventSystem;
const { getText } = CsxDesc;
const WEEKDAYS_TEXT_POSITION: Array<CsxDesc.TextPosition> = [
    'SIMPLE_MONDAY',
    'SIMPLE_TUESDAY',
    'SIMPLE_WEDNESDAY',
    'SIMPLE_THURSDAY',
    'SIMPLE_FRIDAY',
    'SIMPLE_SATURDAY',
    'SIMPLE_SUNDAY',
];
const MONTHS_TEXT_POSITION: Array<CsxDesc.TextPosition> = [
    'SIMPLE_JANUARY',
    'SIMPLE_FEBRUARY',
    'SIMPLE_MARCH',
    'SIMPLE_APRIL',
    'SIMPLE_MAY',
    'SIMPLE_JUNE',
    'SIMPLE_JULY',
    'SIMPLE_AUGUST',
    'SIMPLE_SEPTEMBER',
    'SIMPLE_OCTOBER',
    'SIMPLE_NOVEMBER',
    'SIMPLE_DECEMBER',
];
// const TRAILING_MAP: { [s: string]: string } = { 1: {CsxTrailType.NA}, 2: {CsxTrailType.CR}, 3: {CsxTrailType.LF}, 4: {CsxTrailType.CRLF}, 5: {CsxTrailType.SPACE}, 6: {CsxTrailType.STX}, 7: {CsxTrailType.ETX} };

// const DEFAULT_NAME_TABLE: { [s in CsxEventSystem.CsxNodeType]: string } = {
//     NullEvent: '???',
//     OneTimeEvent: CsxDesc.getText('EVENT_BOX_TITLE_ONCE', CsxUtil.APP_LANG_TYPE.ENGLISH),
//     RepeatEvent: CsxDesc.getText('EVENT_BOX_TITLE_REPEAT', CsxUtil.APP_LANG_TYPE.ENGLISH),
//     CYPDeviceEvent: CsxDesc.getText('EVENT_BOX_TITLE_CYPD', CsxUtil.APP_LANG_TYPE.ENGLISH),
//     ExternalTCPEvent: CsxDesc.getText('EVENT_BOX_TITLE_EXT_TCP', CsxUtil.APP_LANG_TYPE.ENGLISH),
//     ExternalRS232Event: CsxDesc.getText('EVENT_BOX_TITLE_EXT_RS232', CsxUtil.APP_LANG_TYPE.ENGLISH),
//     TriggerInEvent: CsxDesc.getText('EVENT_BOX_TITLE_KEYPAD', CsxUtil.APP_LANG_TYPE.ENGLISH),
//     PollingEvent: CsxDesc.getText('EVENT_BOX_TITLE_POLLING', CsxUtil.APP_LANG_TYPE.ENGLISH),
//     // SystemEvent: CsxDesc.getText('EVENT_BOX_TITLE_SYSTEM', CsxUtil.APP_LANG_TYPE.ENGLISH),
//     CustomCmdEvent: CsxDesc.getText('EVENT_BOX_TITLE_CUSTOM', CsxUtil.APP_LANG_TYPE.ENGLISH),
//     NullAction: '???',
//     CYPAction: CsxDesc.getText('ACTION_BOX_TITLE_CYP', CsxUtil.APP_LANG_TYPE.ENGLISH),
//     TCPAction: CsxDesc.getText('ACTION_BOX_TITLE_TCP', CsxUtil.APP_LANG_TYPE.ENGLISH),
//     UDPAction: CsxDesc.getText('ACTION_BOX_TITLE_UDP', CsxUtil.APP_LANG_TYPE.ENGLISH),
//     RS232Action: CsxDesc.getText('ACTION_BOX_TITLE_RS232', CsxUtil.APP_LANG_TYPE.ENGLISH),
//     NullLogic: '???',
//     AndLogic: '',
//     OrLogic: '',
//     NotLogic: '',
// }

const isNumber = (v: string): boolean => { return !isNaN(parseInt(v)); }
const isWeekday = (v: string): boolean => {
    for (let i = 0; i < v.length; i++) { if (v[i] !== '1' && v[i] !== '0') return false; }
    return true;
}
const isWeekAndDay = (v: string): boolean => { return /^(\d+-\d+)$/.test(v); }

const calculateSupportFeatureByDeviceID = (dev_id: string): Array<CsxFeature.FeatureSupportType> => {
    const dev_inst = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(dev_id) : undefined;
    return CsxFeature.FEATURE_SUP_LIST.filter(feature => {
        const feature_support_table = CsxFeature.CYP_STD_CMD_TABLE[feature];
        let is_support = false;
        if (dev_inst) {
            for (const keycode in feature_support_table) {
                if (Object.prototype.hasOwnProperty.call(feature_support_table, keycode)) {
                    if (CsxUtil.isCypStdCmdType(keycode) && dev_inst.ATTRS.has(keycode)) {
                        is_support = true;
                        break;
                    }
                }
            }
        }
        return is_support;
    });
}
const calculateFastcmdByDeviceIDAndScope = (dev_id: string, scope: string) => {
    const dev_inst = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(dev_id) : undefined;
    const feature_dev = (dev_inst) ? new CsxFeature.CsxFeatureDevice(dev_inst) : undefined;
    let dev_fastcmd_list: Array<string> = [];
    if (CsxFeature.isFeatureSupportType(scope) && feature_dev) {
        const stdcmd_map = CsxFeature.CYP_STD_CMD_TABLE[scope];
        for (const keycode in stdcmd_map) {
            if (Object.prototype.hasOwnProperty.call(stdcmd_map, keycode)) {
                if (CsxUtil.isCypStdCmdType(keycode) && feature_dev.isSupport([keycode])) {
                    const stdcmd = stdcmd_map[keycode];
                    if (stdcmd) {
                        const cmd_expression = stdcmd.text(feature_dev.commandVersion.str);
                        const cmd_vars = stdcmd.variables();
                        const fastcmd_generate: Array<string> = [];

                        cmd_vars.forEach(variable => {
                            const { match, syntax, type } = variable;
                            const fetch_param = feature_dev.searchParam(keycode, syntax);
                            if (type === 'isOption') {
                                const opt = (fetch_param.isOption) ? fetch_param.isOption.option : [];
                                if (fastcmd_generate.length === 0) {
                                    if (opt.length > 0) // check if device really update this param correctly to CS9
                                        opt.forEach(value => { fastcmd_generate.push(cmd_expression.replace(match, value)); });
                                    else
                                        fastcmd_generate.push(cmd_expression.replace(match, '$P'));
                                } else { // exist semi-finished fastcmds
                                    const new_fastcmd_generate: Array<string> = [];
                                    fastcmd_generate.forEach(semi_fastcmd => {
                                        if (opt.length > 0) // check if device really update this param correctly to CS9
                                            opt.forEach(value => { new_fastcmd_generate.push(semi_fastcmd.replace(match, value)); });
                                        else
                                            new_fastcmd_generate.push(semi_fastcmd.replace(match, '$P'));
                                    });
                                    fastcmd_generate.splice(0, fastcmd_generate.length);
                                    new_fastcmd_generate.forEach(fastcmd => fastcmd_generate.push(fastcmd));
                                }
                            } else if (type === 'isString') {
                                if (fastcmd_generate.length === 0) {
                                    fastcmd_generate.push(cmd_expression.replace(match, '$S'));
                                } else { // exist semi-finished fastcmds
                                    const new_fastcmd_generate: Array<string> = [];
                                    fastcmd_generate.forEach(semi_fastcmd => {
                                        new_fastcmd_generate.push(semi_fastcmd.replace(match, '$S'));
                                    });
                                    fastcmd_generate.splice(0, fastcmd_generate.length);
                                    new_fastcmd_generate.forEach(fastcmd => fastcmd_generate.push(fastcmd));
                                }
                            } else if (type === 'isRange') {
                                if (fastcmd_generate.length === 0) {
                                    fastcmd_generate.push(cmd_expression.replace(match, '$N'));
                                } else { // exist semi-finished fastcmds
                                    const new_fastcmd_generate: Array<string> = [];
                                    fastcmd_generate.forEach(semi_fastcmd => {
                                        new_fastcmd_generate.push(semi_fastcmd.replace(match, '$N'));
                                    });
                                    fastcmd_generate.splice(0, fastcmd_generate.length);
                                    new_fastcmd_generate.forEach(fastcmd => fastcmd_generate.push(fastcmd));
                                }
                            } else {
                                if (fastcmd_generate.length === 0) {
                                    fastcmd_generate.push(cmd_expression.replace(match, '$P'));
                                } else { // exist semi-finished fastcmds
                                    const new_fastcmd_generate: Array<string> = [];
                                    fastcmd_generate.forEach(semi_fastcmd => {
                                        new_fastcmd_generate.push(semi_fastcmd.replace(match, '$P'));
                                    });
                                    fastcmd_generate.splice(0, fastcmd_generate.length);
                                    new_fastcmd_generate.forEach(fastcmd => fastcmd_generate.push(fastcmd));
                                }
                            }
                        })
                        dev_fastcmd_list = dev_fastcmd_list.concat(fastcmd_generate);
                    }
                }
            }
        }
    }
    return dev_fastcmd_list;
}


export function repeatEventParamParseDescription(param: CsxEventSystem.CsxEventParameter) {
    let desc = '';
    const WEEKDAYS_MAP = WEEKDAYS_TEXT_POSITION.map(pos => getText(pos, CsxUtil.APP_LANG_TYPE.ENGLISH));
    // const WEEKDAYS_MAP_LANG = WEEKDAYS_TEXT_POSITION.map(pos => getText(pos));
    const MONTHS_MAP = MONTHS_TEXT_POSITION.map(pos => getText(pos, CsxUtil.APP_LANG_TYPE.ENGLISH));
    // const MONTHS_MAP_LANG = MONTHS_TEXT_POSITION.map(pos => getText(pos));
    if (param.Repeat) {
        const { fromDate, fromTime, toDate, toTime, eventExpression, triggerExpression } = param.Repeat;
        const sec_map: { [s: string]: number } = { sec: 1, min: 60, hr: 3600, day: 86400 }; 
        const count_end: { [s: string]: string } = { '1': 'st', '2': 'nd', '3': 'rd' };
        // const param0_parse = Date.parse(param_walk[1]);
        // const param1_parse = Date.parse(param_walk[2]);
        // const param2_parse = Date.parse(`${param_walk[1]}T${param_walk[3]}`); // param_walk[1]+T is meaningless, just to satisify the parse function format
        // const param3_parse = Date.parse(`${param_walk[2]}T${param_walk[4]}`); // param_walk[2]+T is meaningless, just to satisify the parse function format
        const from_date = new Date(fromDate);
        const to_date = new Date(toDate);
        const from_time = new Date(fromTime);
        const to_time = new Date(toTime);
        const event_walk = eventExpression.split(',');
        const trigger_walk = triggerExpression.split(',');
        const sec_value = parseInt(CsxUtil.defaultValue(trigger_walk[0], isNumber, '10'));
        const unit = CsxUtil.defaultValue(trigger_walk[1], null, 'sec');
        const show_value = sec_value/sec_map[unit];
            
        desc += `Once per ${show_value}${unit}, `;
        const periodic = CsxUtil.defaultValue(event_walk[0], isNumber, '1'); // param_walk[6]
        const period = CsxUtil.defaultValue(event_walk[1], isNumber, '1'); // param_walk[7]
        const from_date_s = DateTime.FormatDateTime(from_date, 'YYYY-MM-DD');
        const to_date_s = DateTime.FormatDateTime(to_date, 'YYYY-MM-DD');
        const from_time_s = DateTime.FormatDateTime(from_time, 'HH:mm:ss', '12-hour');
        const to_time_s = DateTime.FormatDateTime(to_time, 'HH:mm:ss', '12-hour');
        const coverage_s = `between ${from_date_s} and ${to_date_s}, from ${from_time_s} to ${to_time_s}`;
        if (periodic === '1') {
            desc += `Every ${(period === '1') ? '' : `${period} `}day, ${coverage_s}`;
        } else if (periodic === '2') {
            const weekday_str = CsxUtil.defaultValue(event_walk[2], isWeekday, '0000000'); // param_walk[8]
            const weekday = Array.from(weekday_str).map(ch => (ch === '1'));
            desc += `Each ${WEEKDAYS_MAP.filter((_, idx) => weekday[idx]).join(', ')} of every ${(period === '1') ? 'week' : `${period} weeks`}, ${coverage_s}`;
        } else if (periodic === '3') {
            const month_mode = CsxUtil.defaultValue(event_walk[2], isNumber, '1'); // param_walk[8]
            if (month_mode === '1') {
                const month_day = CsxUtil.defaultValue(event_walk[3], isNumber, '1'); // param_walk[9]
                const end_w = CsxUtil.defaultValue(count_end[month_day], null, 'th');
                desc += `Each ${month_day}${end_w} of every ${(period === '1') ? 'month' : `${period} months`}, ${coverage_s}`;
            } else if (month_mode === '2') {
                const month_param_walk = CsxUtil.defaultValue(event_walk[3], isWeekAndDay, '1-1').split('-'); // param_walk[9]
                const month_week = month_param_walk[0];
                const month_weekday = month_param_walk[1];
                const week_end_w = count_end[month_week];
                const month_weekday_desc = WEEKDAYS_MAP[parseInt(month_weekday) - 1];
                desc += `Each ${month_week}${week_end_w} ${month_weekday_desc} of every ${(period === '1') ? 'month' : `${period} months`}, ${coverage_s}`;
            }
        } else if (periodic === '4') {
            const month = CsxUtil.defaultValue(event_walk[2], isNumber, '1'); // param_walk[8]
            const month_mode = CsxUtil.defaultValue(event_walk[3], isNumber, '1'); // param_walk[9]
            const month_desc = MONTHS_MAP[parseInt(month) - 1];
            if (month_mode === '1') {
                const month_day = event_walk[4]; // param_walk[10]
                const end_w = CsxUtil.defaultValue(count_end[month_day], null, 'th');
                desc += `Each ${month_desc} ${month_day}${end_w} of every ${(period === '1') ? 'year' : `${period} years`}, ${coverage_s}`;
            } else if (month_mode === '2') {
                const month_param_walk = CsxUtil.defaultValue(event_walk[4], isWeekAndDay, '1-1').split('-'); // param_walk[10]
                const month_week = month_param_walk[0];
                const month_weekday = month_param_walk[1];
                const week_end_w = count_end[month_week];
                const month_weekday_desc = WEEKDAYS_MAP[parseInt(month_weekday) - 1];
                desc += `Each ${month_desc} ${month_week}${week_end_w} ${month_weekday_desc} of every ${(period === '1') ? 'month' : `${period} months`}, ${coverage_s}`;
            }
        }
    }
    return desc;
}

class ModalBasicComponent extends React.Component {
    DEFAULT_NAME_TABLE: { [s in CsxEventSystem.CsxNodeType]: string } = {
        NullEvent: '???',
        OneTimeEvent: CsxDesc.getText('EVENT_BOX_TITLE_ONCE', CsxUtil.APP_LANG_TYPE.ENGLISH),
        RepeatEvent: CsxDesc.getText('EVENT_BOX_TITLE_REPEAT', CsxUtil.APP_LANG_TYPE.ENGLISH),
        CYPDeviceEvent: CsxDesc.getText('EVENT_BOX_TITLE_CYPD', CsxUtil.APP_LANG_TYPE.ENGLISH),
        ExternalTCPEvent: CsxDesc.getText('EVENT_BOX_TITLE_EXT_TCP', CsxUtil.APP_LANG_TYPE.ENGLISH),
        ExternalRS232Event: CsxDesc.getText('EVENT_BOX_TITLE_EXT_RS232', CsxUtil.APP_LANG_TYPE.ENGLISH),
        TriggerInEvent: CsxDesc.getText('EVENT_BOX_TITLE_KEYPAD', CsxUtil.APP_LANG_TYPE.ENGLISH),
        PollingEvent: CsxDesc.getText('EVENT_BOX_TITLE_POLLING', CsxUtil.APP_LANG_TYPE.ENGLISH),
        // SystemEvent: CsxDesc.getText('EVENT_BOX_TITLE_SYSTEM', CsxUtil.APP_LANG_TYPE.ENGLISH),
        CustomCmdEvent: CsxDesc.getText('EVENT_BOX_TITLE_CUSTOM', CsxUtil.APP_LANG_TYPE.ENGLISH),
        // TCPDeviceEvent: 'TCPDeviceEvent',
        // TCPDeviceAction: 'TCPDeviceAction',
        NullAction: '???',
        CYPAction: CsxDesc.getText('ACTION_BOX_TITLE_CYP', CsxUtil.APP_LANG_TYPE.ENGLISH),
        TCPAction: CsxDesc.getText('ACTION_BOX_TITLE_TCP', CsxUtil.APP_LANG_TYPE.ENGLISH),
        UDPAction: CsxDesc.getText('ACTION_BOX_TITLE_UDP', CsxUtil.APP_LANG_TYPE.ENGLISH),
        RS232Action: CsxDesc.getText('ACTION_BOX_TITLE_RS232', CsxUtil.APP_LANG_TYPE.ENGLISH),
        NullLogic: '???',
        AndLogic: '',
        OrLogic: '',
        NotLogic: '',
    }
}

export class OneTimeEventModalContent extends ModalBasicComponent {
    private defaultName: string = this.DEFAULT_NAME_TABLE.OneTimeEvent;
    state: { time: Date; name: string; dataChanged: boolean };
    constructor(props: any) {
        super(props);
        this.state = { time: new Date(), name: this.defaultName, dataChanged: true };
        const data = this.node_data;
        if (data && data.OneTime) {
            // const param_walk = node_inst.data.split(',');
            // const utc_date = Date.parse(CsxUtil.defaultValue(param_walk[1], null, '').replace(' ', 'T'));
            // this.state.name = CsxUtil.defaultValue(param_walk[0], null, this.defaultName);
            // this.state.time = (isNaN(utc_date)) ? new Date() : new Date(utc_date);
            this.state.name = data.name;
            this.state.time = new Date(data.OneTime.time);
        }
    }
    componentWillUnmount() { this.setState = () => { }; }
    get node_data() {
        const node_inst = window.canvas.getNode(window.canvas.curNode);
        return (node_inst && CsxEventSystem.isCsxEventParameter(node_inst.data)) ? node_inst.data : undefined;
    }
    onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ name: e.target.value, dataChanged: true }); }
    onTimeChange = (value: Date | Date[]) => { this.setState({ time: value, dataChanged: true }); }
    validateInput = (): { name_err: string } | undefined => {
        const { name } = this.state;
        if (name.length === 0)
            return { name_err: getText('HINT_NAME_CANNOT_NULL') };
        if (CsxUtil.hasChinese(name) || name.indexOf(',') >= 0)
            return { name_err: getText('HINT_CONTANS_INVALID_CHAR') };
        return undefined;
    }
    render() {
        const { name, time, dataChanged } = this.state;
        const form_error = this.validateInput();
        const data = this.node_data;
        const param: CsxEventSystem.CsxEventParameter = {
            lockOnCanvas: (data) ? data.lockOnCanvas : false,
            name,
            OneTime: {
                time: time.getTime()
            }
        };
        return (
            <Form.Form style={{ minWidth: '300px' }} labelStyle={{ width: '100px' }}>
                <Form.Item label={getText('NAME')} error={(form_error) ? form_error.name_err : undefined} ><Input value={name} onChange={this.onNameChange} /></Form.Item>
                <Form.Item label={getText('TIME')}>
                    <DateTime.DatePicker value={time} onChange={this.onTimeChange} />
                    <DateTime.TimePicker value={time} onChange={this.onTimeChange} />
                </Form.Item>
                <Form.Item style={{ justifyContent: 'flex-end' }}>
                    <Button disabled={!dataChanged || !!form_error} type='primary' onClick={() => {
                        const disp = DateTime.FormatDateTime(time, 'YYYY-MM-DD HH:mm:ss');
                        window.canvas.setNodePort(window.canvas.curNode, 'in', 1, disp);
                        window.canvas.setNodeData(window.canvas.curNode, param);
                        this.setState({ dataChanged: false });
                    }}>{getText('CONFIRM')}</Button>
                </Form.Item>
            </Form.Form>
        );
    }
}

export class PeriodicalEventModalContent extends ModalBasicComponent {
    private defaultName: string = this.DEFAULT_NAME_TABLE.RepeatEvent;
    state: {
        name: string;
        from_date: Date; to_date: Date; from_time: Date; to_time: Date;
        periodic: string; period: string;
        weekday: Array<boolean>;
        month_mode: string; month_day: string; month_week: string; month_weekday: string;
        month: string;
        unit: string; value: string;
        dataChanged: boolean;
    };
    constructor(props: any) {
        super(props);
        const cur_time = new Date();
        this.state = {
            name: this.defaultName,
            from_date: cur_time, to_date: cur_time, from_time: cur_time, to_time: new Date(cur_time.getTime() + 3600000),
            periodic: '1', period: '1',
            weekday: [false, false, false, false, false, false, false],
            month_mode: '1', month_day: cur_time.getDate().toString(), month_week: Math.ceil(cur_time.getDate() / 7).toString(), month_weekday: cur_time.getDay().toString(),
            month: (cur_time.getMonth() + 1).toString(),
            value: '', unit: 'sec',
            dataChanged: true
        };
        const data = this.node_data;
        if (data && data.Repeat) {
            const { Repeat } = data;
            const event_walk = Repeat.eventExpression.split(',');
            const trigger_walk = Repeat.triggerExpression.split(',');
            this.state.name = data.name;
            this.state.from_date = new Date(Repeat.fromDate);
            this.state.to_date = new Date(Repeat.toDate);
            this.state.from_time = new Date(Repeat.fromTime);
            this.state.to_time = new Date(Repeat.toTime);
            this.state.value = CsxUtil.defaultValue(trigger_walk[0], isNumber, '10');
            this.state.unit = CsxUtil.defaultValue(trigger_walk[1], null, 'sec');
            /* Fixed value is not matched to unit issue */
            if (this.state.unit === 'min') {
                const real_value = parseInt(this.state.value, 10);
                this.state.value = (Number.isNaN(real_value)) ? '0' : (real_value / 60).toString();
            } else if (this.state.unit === 'hr') {
                const real_value = parseInt(this.state.value, 10);
                this.state.value = (Number.isNaN(real_value)) ? '0' : (real_value / 3600).toString();
            }
            this.state.periodic = CsxUtil.defaultValue(event_walk[0], isNumber, '1');
            this.state.period = CsxUtil.defaultValue(event_walk[1], isNumber, '1');
            if (this.state.periodic === '2') {
                const weekday_str = CsxUtil.defaultValue(event_walk[2], isWeekday, '0000000');
                this.state.weekday = Array.from(weekday_str).map(ch => (ch === '1'));
            } else if (this.state.periodic === '3') {
                this.state.month_mode = CsxUtil.defaultValue(event_walk[2], isNumber, '1');
                if (this.state.month_mode === '1') {
                    this.state.month_day = CsxUtil.defaultValue(event_walk[3], isNumber, '1');
                } else if (this.state.month_mode === '2') {
                    const month_param_walk = CsxUtil.defaultValue(event_walk[3], isWeekAndDay, '1-1').split('-');
                    this.state.month_week = month_param_walk[0];
                    this.state.month_weekday = month_param_walk[1];
                }
            } else if (this.state.periodic === '4') {
                this.state.month = CsxUtil.defaultValue(event_walk[2], isNumber, '1');
                this.state.month_mode = CsxUtil.defaultValue(event_walk[3], isNumber, '1');
                if (this.state.month_mode === '1') {
                    this.state.month_day = event_walk[4];
                } else if (this.state.month_mode === '2') {
                    const month_param_walk = CsxUtil.defaultValue(event_walk[4], isWeekAndDay, '1-1').split('-');
                    this.state.month_week = month_param_walk[0];
                    this.state.month_weekday = month_param_walk[1];
                }
            }
        }
    }
    componentWillUnmount() { this.setState = () => { }; }
    get node_data() {
        const node_inst = window.canvas.getNode(window.canvas.curNode);
        return (node_inst && CsxEventSystem.isCsxEventParameter(node_inst.data)) ? node_inst.data : undefined;
    }
    onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ name: e.target.value, dataChanged: true }); }
    onFromDateChange = (value: Date | Date[]) => { this.setState({ from_date: value, dataChanged: true }); }
    onToDateChange = (value: Date | Date[]) => { this.setState({ to_date: value, dataChanged: true }); }
    onFromTimeChange = (value: Date | Date[]) => { this.setState({ from_time: value, dataChanged: true }); }
    onToTimeChange = (value: Date | Date[]) => { this.setState({ to_time: value, dataChanged: true }); }
    onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ value: e.target.value, dataChanged: true }); }
    onPeriodicChange = (value: string) => { this.setState({ periodic: value, dataChanged: true }); }
    onPeriodChange = (value: string) => { this.setState({ period: value, dataChanged: true }); }
    onUnitChange = (value: string) => { this.setState({ unit: value, dataChanged: true }); }
    onWeekdayChange = (idx: number) => {
        this.setState((prevState: any) => {
            prevState.weekday[idx] = !prevState.weekday[idx];
            prevState.dataChanged = true;
            return prevState;
        });
    }
    onMonthChange = (value: string) => { this.setState({ month: value, dataChanged: true }); }
    onMonthModeChange = (value: string) => { this.setState({ month_mode: value, dataChanged: true }); }
    onMonthDayChange = (value: string) => { this.setState({ month_day: value, month_mode: '1', dataChanged: true }); }
    onMonthWeekChange = (value: string) => { this.setState({ month_week: value, month_mode: '2', dataChanged: true }); }
    onMonthWeekdayChange = (value: string) => { this.setState({ month_weekday: value, month_mode: '2', dataChanged: true }); }
    validateInput = (): { date_error?: string; trigger_error?: string; name_error?: string; freq_error?: string } | undefined => {
        const { weekday, periodic, value, unit, name, from_date, to_date } = this.state;
        const isTriggerValid = (!isNaN(parseInt(value)) && parseInt(value) > 0 && unit.length > 0);
        const form_error: { date_error?: string; trigger_error?: string; name_error?: string; freq_error?: string } = {};
        if (name.length === 0)
            form_error.name_error = getText('HINT_NAME_CANNOT_NULL');
        if (CsxUtil.hasChinese(name) || name.indexOf(',') >= 0)
            form_error.name_error = getText('HINT_CONTANS_INVALID_CHAR');
        if (!isTriggerValid)
            form_error.trigger_error = getText('HINT_INTERVAL_INVALID');
        if (periodic === '2' && (weekday.filter(checked => checked).length === 0))
            form_error.freq_error = getText('HINT_AT_LEAST_ONE_WEEKDAY');
        if ((from_date.getMonth() === to_date.getMonth() && from_date.getDate() > to_date.getDate()) ||
            (from_date.getMonth() > to_date.getMonth()))
            form_error.date_error = getText('HINT_DATES_CONFLICT');
        return (CsxUtil.isEmptyObject(form_error)) ? undefined : form_error;
    }
    render() {
        const {
            name,
            from_date, to_date, from_time, to_time, weekday, period, periodic,
            month_mode, month_day, month_week, month_weekday,
            month,
            value, unit, dataChanged } = this.state;
        const WEEKDAYS_MAP_LANG = WEEKDAYS_TEXT_POSITION.map(pos => getText(pos));
        const MONTHS_MAP_LANG = MONTHS_TEXT_POSITION.map(pos => getText(pos));
        let opt_formitems: any = undefined;
        if (periodic === '2') {
            opt_formitems = (
                <Form.Item label=' ' colon={false} className='level2'>
                    {WEEKDAYS_MAP_LANG.map((label, idx) => <Checkbox label={label} checked={weekday[idx]} onChange={() => { this.onWeekdayChange(idx); }} key={Math.random()} />)}
                </Form.Item>
            );
        } else if (periodic === '3') {
            opt_formitems = (
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'flex-start', justifyContent: 'flex-start' }}>
                    <RadioGroup style={{ marginLeft: '130px' }} layout='vertical' value={month_mode} options={[{ value: '1' }, { value: '2' }]} onChange={this.onMonthModeChange} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'strech' }}>
                        <div>
                            At <Select size='small' style={{ width: '80px', flexGrow: 0 }} value={month_day} disabled={month_mode !== '1'} onChange={this.onMonthDayChange}>
                                {Array.from(Array(31).keys()).map(idx => <Option value={(idx + 1).toString()} key={Math.random()}>{idx + 1}</Option>)}
                            </Select> th day
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            At <Select size='small' style={{ width: '80px', flexGrow: 0 }} value={month_week} disabled={month_mode !== '2'} onChange={this.onMonthWeekChange}>
                                {Array.from(Array(5).keys()).map(idx => <Option value={(idx + 1).toString()} key={Math.random()}>{idx + 1}</Option>)}
                            </Select> th
                            <Select size='small' style={{ width: '80px', flexGrow: 0, marginLeft: '10px' }} value={month_weekday} disabled={month_mode !== '2'} onChange={this.onMonthWeekdayChange}>
                                {WEEKDAYS_MAP_LANG.map((label, idx) => <Option value={(idx + 1).toString()} key={Math.random()}>{label}</Option>)}
                            </Select>
                        </div>
                    </div>
                </div>
            );
        } else if (periodic === '4') {
            opt_formitems = (
                <Form.Item className='repeat_modal_radiogroup_formitem' layout='vertical' style={{ margin: '40px 0' }}>
                    <RadioGroup style={{ marginLeft: '130px', marginTop: '40px' }} layout='vertical' value={month_mode} options={[{ value: '1' }, { value: '2' }]} onChange={this.onMonthModeChange} />
                    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'strech' }}>
                        <div style={{ transform: 'translateX(-33px)' }}>
                            {getText('MONTH')}: <Select size='small' style={{ width: '80px', flexGrow: 0 }} value={month} onChange={this.onMonthChange}>
                                {MONTHS_MAP_LANG.map((label, idx) => <Option value={(idx + 1).toString()} key={Math.random()}>{label}</Option>)}
                            </Select>
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            At <Select size='small' style={{ width: '80px', flexGrow: 0 }} value={month_day} disabled={month_mode !== '1'} onChange={this.onMonthDayChange}>
                                {Array.from(Array(31).keys()).map(idx => <Option value={(idx + 1).toString()} key={Math.random()}>{idx + 1}</Option>)}
                            </Select> th day
                        </div>
                        <div style={{ marginTop: '10px' }}>
                            At <Select size='small' style={{ width: '80px', flexGrow: 0 }} value={month_week} disabled={month_mode !== '2'} onChange={this.onMonthWeekChange}>
                                {Array.from(Array(5).keys()).map(idx => <Option value={(idx + 1).toString()} key={Math.random()}>{idx + 1}</Option>)}
                            </Select> th
                            <Select size='small' style={{ width: '80px', flexGrow: 0, marginLeft: '10px' }} value={month_weekday} disabled={month_mode !== '2'} onChange={this.onMonthWeekdayChange}>
                                {WEEKDAYS_MAP_LANG.map((label, idx) => <Option value={(idx + 1).toString()} key={Math.random()}>{label}</Option>)}
                            </Select>
                        </div>
                    </div>
                </Form.Item>
            );
        }
        const form_error = this.validateInput();
        return (
            <Form.Form style={{ minWidth: '300px' }} labelStyle={{ width: '120px' }}>
                <Form.Item label={getText('NAME')} error={(form_error) ? form_error.name_error : undefined}><Input value={name} onChange={this.onNameChange} /></Form.Item>
                <Form.Item label={getText('TIME')} style={{ flexWrap: 'wrap', height: 'auto' }}>
                    <DateTime.TimePicker value={from_time} onChange={this.onFromTimeChange} />
                To<DateTime.TimePicker value={to_time} onChange={this.onToTimeChange} />
                </Form.Item>
                <Form.Item label={getText('DATE')} style={{ flexWrap: 'wrap', height: 'auto' }} className='level1' error={(form_error) ? form_error.date_error : undefined}>
                    <DateTime.DatePicker value={from_date} onChange={this.onFromDateChange} />
                To<DateTime.DatePicker value={to_date} onChange={this.onToDateChange} />
                </Form.Item>
                <Form.Item label={getText('EVERY')} className='level1' error={(form_error) ? form_error.freq_error : undefined}>
                    <Select className='periodic_l_s' value={period} onChange={this.onPeriodChange}>
                        {Array.from(Array(5).keys()).map(idx => <Option value={(idx + 1).toString()} key={Math.random()}>{idx + 1}</Option>)}
                    </Select>
                    <Select className='periodic_s' value={periodic} onChange={this.onPeriodicChange}>
                        <Option value='1'>{getText('DAY')}</Option>
                        <Option value='2'>{getText('WEEK')}</Option>
                        <Option value='3'>{getText('MONTH')}</Option>
                        <Option value='4'>{getText('YEAR')}</Option>
                    </Select>
                </Form.Item>
                {opt_formitems}
                <Form.Item label={getText('TIRGGER_INTERVAL')} className='level1' error={(form_error) ? form_error.trigger_error : undefined}>
                    <Input className='interval_i' value={value} placeholder='Event interval' onChange={this.onValueChange} />
                    <Select className='interval_u_s' value={unit} placeholder='Interval unit' onChange={this.onUnitChange}>
                        <Option value='sec'>{getText('SECOND')}</Option>
                        <Option value='min'>{getText('MINUTE')}</Option>
                        <Option value='hr'>{getText('HOUR')}</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={{ justifyContent: 'flex-end' }}>
                    <Button className='conf_b' type='primary' disabled={!!form_error || !dataChanged} onClick={() => {
                        let schedule_f = '';
                        const sec_map: { [s: string]: number } = { sec: 1, min: 60, hr: 3600, day: 86400 };
                        // const from_date_s = DateTime.FormatDateTime(from_date, 'YYYY-MM-DD');
                        // const to_date_s = DateTime.FormatDateTime(to_date, 'YYYY-MM-DD');
                        if (periodic === '1') {
                            schedule_f = `${periodic},${period}`;
                        } else if (periodic === '2') {
                            schedule_f = `${periodic},${period},${weekday.map(checked => checked ? '1' : '0').join('')}`;
                        } else if (periodic === '3') {
                            if (month_mode === '1') {
                                schedule_f = `${periodic},${period},${month_mode},${month_day}`;
                            } else if (month_mode === '2') {
                                schedule_f = `${periodic},${period},${month_mode},${month_week}-${month_weekday}`;
                            }
                        } else if (periodic === '4') {
                            if (month_mode === '1') {
                                schedule_f = `${periodic},${period},${month},${month_mode},${month_day}`;
                            } else if (month_mode === '2') {
                                schedule_f = `${periodic},${period},${month},${month_mode},${month_week}-${month_weekday}`;
                            }
                        }
                        const event_expression = `${schedule_f}`;
                        const trigger_expression = `${parseInt(value, 10) * sec_map[unit]},${unit}`;
                        const data = this.node_data;
                        const param: CsxEventSystem.CsxEventParameter = {
                            lockOnCanvas: (data) ? data.lockOnCanvas : false,
                            name,
                            Repeat: {
                                fromDate: from_date.getTime(),
                                toDate: to_date.getTime(),
                                fromTime: from_time.getTime(),
                                toTime: to_time.getTime(),
                                eventExpression: event_expression,
                                triggerExpression: trigger_expression,
                            }
                        }
                        window.canvas.setNodePort(window.canvas.curNode, 'in', 1, repeatEventParamParseDescription(param));
                        window.canvas.setNodeData(window.canvas.curNode, param);
                        this.setState({ dataChanged: false });
                    }}>{getText('CONFIRM')}</Button>
                </Form.Item>
            </Form.Form>
        );
    }
}

export class CustomCommandEventModalContent extends ModalBasicComponent {
    private defaultName: string = this.DEFAULT_NAME_TABLE.CustomCmdEvent;
    state: {
        name: string; nic: string; ipaddr: string; ports: { [s: string]: string }; endchar: string; command: string; net: string; dataChanged: boolean; cmd_type: CMD_INPUT_TYPE };
    constructor(props: any) {
        super(props);
        this.state = {
            name: this.defaultName, nic: 'eth0', ipaddr: '', ports: { UDP: '50000', TCP: '5000' }, endchar: '', net: '', command: '', cmd_type: 'plain', dataChanged: true };
        const data = this.node_data;
        if (data && data.Customized) {
            const { networkInterface, ipaddr, port, nic, endchar, command, hexCommand } = data.Customized;
            this.state.name = data.name;
            this.state.net = networkInterface;
            this.state.ipaddr = ipaddr;
            this.state.ports[networkInterface] = port.toString();
            this.state.endchar = endchar;
            this.state.command = command;
            this.state.nic = nic;
            if (hexCommand.length > 0) {
                this.state.command = pureHexStringToCypHexString(hexCommand);
                this.state.cmd_type = 'hex';
            }
        }
    }
    componentWillUnmount() { this.setState = () => { }; }
    get node_data() {
        const node_inst = window.canvas.getNode(window.canvas.curNode);
        return (node_inst && CsxEventSystem.isCsxEventParameter(node_inst.data)) ? node_inst.data : undefined;
    }
    get net_port() {
        const { net, ports } = this.state;
        return ports[net];
    }
    onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ name: e.target.value, dataChanged: true }); }
    onCommandChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ command: e.target.value, dataChanged: true }); }
    onIpAddrChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ ipaddr: e.target.value, dataChanged: true }); }
    onPortChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let cur_ports = this.state.ports;
        cur_ports[this.state.net] = e.target.value;
        this.setState({ ports: cur_ports, dataChanged: true });
    }
    onEndCharChange = (value: string) => { this.setState({ endchar: value, dataChanged: true }); }
    onNetChange = (value: string) => { this.setState({ net: value, dataChanged: true }); }
    onNICChange = (value: string) => { this.setState({ nic: value, dataChanged: true }); }
    onCmdTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { command } = this.state;
        this.setState({
            cmd_type: (e.target.checked?'plain':'hex'),
            command: ((e.target.checked)?cypHexStringToString(command):stringToCypHexString(command)),
            dataChanged: true,
        });
    }
    validateInput = (): { ip_error?: string; name_error?: string; cmd_error?: string } | undefined => {
        const { name, ipaddr, cmd_type, command } = this.state;
        const hexMatcher = new RegExp(CYP_HEX_STRING_CHECK_PATTERN);
        const form_error: { ip_error?: string; name_error?: string; cmd_error?: string } = {};
        const parse_port = parseInt(this.net_port);
        if (name.length === 0)
            form_error.name_error = getText('HINT_NAME_CANNOT_NULL');
        if (CsxUtil.hasChinese(name) || name.indexOf(',') >= 0)
            form_error.name_error = getText('HINT_CONTANS_INVALID_CHAR');
        if (!(CsxUtil.isIP(ipaddr)))
            form_error.ip_error = getText('HINT_LAN_IP_INVALID');
        if (isNaN(parse_port) || parse_port <= 0 || parse_port >= 65536)
            form_error.ip_error = getText('HINT_PORT_INVALID');
        if (command.length === 0)
            form_error.cmd_error = getText('HINT_CMD_CANNOT_NULL');
        if (cmd_type === 'hex' && !hexMatcher.test(command)) 
            form_error.cmd_error = `${getText('HINT_HEX_FORMAT_INCORRECT')} (${CYP_HEX_STRING_CHECK_PATTERN})`;
        return (CsxUtil.isEmptyObject(form_error)) ? undefined : form_error;
    }
    render() {
        const { name, ipaddr, nic, endchar, net, cmd_type, command, dataChanged } = this.state;
        const form_error = this.validateInput();
        return (
            <Form.Form style={{ minWidth: '300px' }} labelStyle={{ width: '100px' }}>
                <p>{`${getText('AUTOMATION_CUST_CMD_DESC1')} ${net}.`}</p>
                {/*<p>{`${getText('AUTOMATION_CUST_CMD_DESC2')} ${(net === 'UDP') ? '50000' : '5000'}.`}</p>*/}
                <Form.Item label={getText('NAME')} error={(form_error) ? form_error.name_error : undefined}><Input value={name} onChange={this.onNameChange} /></Form.Item>
                <Form.Item label={getText('PROTOCOL')}>
                    <Select value={net} onChange={this.onNetChange} placeholder='Network Protocol'>
                        <Option value='UDP'>UDP</Option>
                        <Option value='TCP'>TCP</Option>
                    </Select>
                </Form.Item>
                {/* <Form.Item label='NIC'>
                    <Select value={nic} onChange={this.onNICChange} placeholder='Network Interface Card'>
                        <Option value='eth0'>LAN 1</Option>
                        <Option value='eth1'>LAN 2</Option>
                    </Select>
                </Form.Item> */}
                <Form.Item label={getText('IP_PORT')} error={(form_error) ? form_error.ip_error : undefined}>
                    <Input value={ipaddr} onChange={this.onIpAddrChange} style={{ width: '180px' }}/>
                    <Input className='port_input' value={this.net_port} placeholder='1~65535' onChange={this.onPortChange} />
                </Form.Item>
                <Form.Item label={getText('COMMAND')} error={(form_error) ? form_error.cmd_error : undefined}>
                    <Input value={command} onChange={this.onCommandChange} />
                    <SwitchButton checked={(cmd_type === 'plain')} onChange={this.onCmdTypeChange} label={[ getText('PLAIN'), getText('HEX') ]}/>
                </Form.Item>
                <p style={{ textAlign: 'right', margin: '3px', lineHeight: '20px', color: 'gray', display: ((cmd_type === 'hex')?undefined:'none') }}>HEX format is \x01\x02 ...</p>
                <Form.Item label={getText('ENDCHAR')}>
                    <Select value={endchar} onChange={this.onEndCharChange} placeholder='Trailing Character'>
                        <Option value={CsxTrailType.NA}>N/A</Option>
                        <Option value={CsxTrailType.CR}>CR</Option>
                        <Option value={CsxTrailType.LF}>LF</Option>
                        <Option value={CsxTrailType.CRLF}>CR/LF</Option>
                        <Option value={CsxTrailType.SPACE}>Space</Option>
                        <Option value={CsxTrailType.STX}>STX</Option>
                        <Option value={CsxTrailType.ETX}>ETX</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={{ justifyContent: 'flex-end' }}>
                    <Button type='primary' disabled={!!form_error || !dataChanged} onClick={() => {
                        const cur_nid = window.canvas.curNode;
                        const data = this.node_data;
                        const port = this.net_port;
                        const param: CsxEventSystem.CsxEventParameter = {
                            lockOnCanvas: (data) ? data.lockOnCanvas : false,
                            name,
                            Customized: {
                                ipaddr, port: parseInt(port),
                                endchar: (isCsxTrailType(endchar) ? endchar : CsxTrailType.NA), nic,
                                networkInterface: net,
                                command: (cmd_type === 'plain') ? command : '',
                                hexCommand: (cmd_type === 'hex') ? cypHexStringToPureHexString(command) : '',
                            }
                        };
                        // const data = `${name},${net},${ipaddr},${endchar},${command}`;
                        window.canvas.setNodePort(cur_nid, 'in', 1, net);
                        window.canvas.setNodePort(cur_nid, 'in', 3, `${ipaddr}:${port}`);
                        if (cmd_type === 'plain')
                            window.canvas.setNodePort(cur_nid, 'in', 5, `${command}:${endchar}`);
                        else if (cmd_type === 'hex')
                            window.canvas.setNodePort(cur_nid, 'in', 5, `${cypHexStringToPureHexString(command)}:${endchar}`);
                        window.canvas.setNodeData(cur_nid, param);
                        this.setState({ dataChanged: false });
                    }}>{getText('CONFIRM')}</Button>
                </Form.Item>
            </Form.Form>
        );
    }
}

class SayHelloButton extends IRQComponent<{ device_id: string }> {
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'DeviceRealTime';
    }
    render() {
        const { device_id } = this.props;
        const dev_inst = (typeof device_id === 'string' && window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(device_id) : undefined;
        const sayhello_dev = new CsxFeature.CsxGeneralDevice(dev_inst);
        const sayhello_support = sayhello_dev.IsSupportHelloMode();
        const helloModeParam = (sayhello_dev) ? sayhello_dev.HelloModeParam() : undefined;
        const hello_opt = (helloModeParam && helloModeParam.b1.isOption) ? helloModeParam.b1.isOption.option : ['on', 'off'];
        const hello_on = (sayhello_dev.HelloMode() === hello_opt[0]);
        return <Button type={hello_on ? 'danger' : 'default'} disabled={!sayhello_support} onClick={() => { sayhello_dev.SetHelloMode(hello_on ? hello_opt[1] : hello_opt[0]); }}>{hello_on ? 'Sh!' : 'Hi.'}</Button>;
    }
}

export class CYPDeviceEventModalContent extends ModalBasicComponent {
    private defaultName: string = this.DEFAULT_NAME_TABLE.CYPDeviceEvent;
    state: { name: string; device: string; scope_s: string; fastcmd_s: string; dev_support_features: Array<CsxFeature.FeatureSupportType>; dev_fastcmd_list: Array<string>; keySentence: string; dataChanged: boolean };
    // sayhelloDevice?: CsxFeature.CsxGeneralDevice;
    constructor(props: any) {
        super(props);
        const default_support_list: Array<CsxFeature.FeatureSupportType> = [];
        this.state = { name: this.defaultName, device: '', scope_s: '', dev_support_features: default_support_list, fastcmd_s: '', dev_fastcmd_list: [], keySentence: '', dataChanged: false };
        const data = this.node_data;
        if (data && data.CYPDevice) {
            const { device, command } = data.CYPDevice;
            this.state.name = data.name;
            this.state.device = device;
            this.state.dev_support_features = calculateSupportFeatureByDeviceID(device);
            this.state.keySentence = command;
        }
    }
    componentWillUnmount() { this.setState = () => { }; }
    get node_data() {
        const node_inst = window.canvas.getNode(window.canvas.curNode);
        return (node_inst && CsxEventSystem.isCsxEventParameter(node_inst.data)) ? node_inst.data : undefined;
    }
    onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ name: e.target.value, dataChanged: true }); }
    onKeySentenceChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ keySentence: e.target.value, dataChanged: true }); }
    onDeviceChange = (value: string) => {
        this.setState({ device: value, dev_support_features: calculateSupportFeatureByDeviceID(value), scope_s: '', fastcmd_s: '', dataChanged: true });
    }
    onScopeChange = (value: string) => {
        const { device } = this.state;
        this.setState({ scope_s: value, dev_fastcmd_list: calculateFastcmdByDeviceIDAndScope(device, value) });
    }
    onFastcmdChange = (value: string) => { this.setState({ fastcmd_s: value, keySentence: value, dataChanged: true }); }
    validateInput = (): { dev_error?: string; name_error?: string; key_error?: string } | undefined => {
        const { name, device, keySentence } = this.state;
        const form_error: { dev_error?: string; name_error?: string; key_error?: string } = {};
        if (name.length === 0)
            form_error.name_error = getText('HINT_NAME_CANNOT_NULL');
        if (CsxUtil.hasChinese(name) || name.indexOf(',') >= 0)
            form_error.name_error = getText('HINT_CONTANS_INVALID_CHAR');
        if (device.length === 0)
            form_error.dev_error = getText('HINT_DEVICE_CANNOT_NULL');
        if (keySentence.length === 0)
            form_error.key_error = getText('HINT_KEYWORD_CANNOT_NULL');
        return (CsxUtil.isEmptyObject(form_error)) ? undefined : form_error;
    }
    render() {
        const { name, device, scope_s, fastcmd_s, dev_support_features, dev_fastcmd_list, keySentence, dataChanged } = this.state;
        const gw_inst = window.FOCUS_GATEWAY;
        const form_error = this.validateInput();
        return (
            <Form.Form style={{ minWidth: '400px' }} labelStyle={{ width: '150px' }}>
                <Form.Item label={getText('NAME')} error={(form_error) ? form_error.name_error : undefined}><Input value={name} onChange={this.onNameChange} /></Form.Item>
                <Form.Item label={getText('DEVICE')} error={(form_error) ? form_error.dev_error : undefined}>
                    <Select value={device} onChange={this.onDeviceChange} placeholder='CYP Device'>
                        {(gw_inst) ? Array.from(gw_inst.DEVICES).map(dev_id => {
                            const dev_inst = gw_inst.getDevice(dev_id);
                            const dev_model = (dev_inst) ? dev_inst.STA.Z8 : '';
                            const disp_name = (dev_inst) ? dev_inst.NICKNAME : dev_inst;
                            return <Option key={Math.random()} value={dev_id}>{(disp_name) ? disp_name : `${dev_model}(${dev_id.slice(9)})`}</Option>;
                        }) : []}
                    </Select>
                    {(device.length > 0) ? <SayHelloButton device_id={device}/> : undefined}
                </Form.Item>
                <Form.Item label={getText('SCOPE')} style={CsxUI.getHiddenStyle((device.length > 0))}>
                    <Select value={scope_s} placeholder='Select Function Scope' onChange={this.onScopeChange}>
                        {dev_support_features.map((feature, idx) => <Option key={`cypdev_feature_${idx}`} value={feature}>{feature}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label={getText('COMMAND_LIST')} style={CsxUI.getHiddenStyle((device.length > 0))}>
                    <Select value={fastcmd_s} placeholder='Select Command String' onChange={this.onFastcmdChange}>
                        {dev_fastcmd_list.map((cmdstr) => (cmdstr.replace('set ', 'status : '))).map((cmdstr, idx) => <Option key={`fastcmd_str_${idx}`} value={cmdstr}>{cmdstr}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label={getText('KEYWORD')} error={(form_error) ? form_error.key_error : undefined}>
                    <Input value={keySentence} placeholder='Trigger keyword' onChange={this.onKeySentenceChange} />
                </Form.Item>
                <Form.Item style={{ justifyContent: 'flex-end' }}>
                    <Button type='primary' disabled={!!form_error || !dataChanged} onClick={() => {
                        const cur_nid = window.canvas.curNode;
                        const data = this.node_data;
                        const param: CsxEventSystem.CsxEventParameter = {
                            lockOnCanvas: (data) ? data.lockOnCanvas : false,
                            name,
                            CYPDevice: {
                                device,
                                command: keySentence,
                            }
                        };
                        window.canvas.setNodePort(cur_nid, 'in', 1, device);
                        window.canvas.setNodePort(cur_nid, 'in', 3, keySentence);
                        window.canvas.setNodeData(cur_nid, param);
                        this.setState({ dataChanged: false });
                    }}>{getText('CONFIRM')}</Button>
                </Form.Item>
            </Form.Form>
        );
    }
}

export class ExternalTCPEventModalContent extends ModalBasicComponent {
    private defaultName: string = this.DEFAULT_NAME_TABLE.ExternalTCPEvent;
    state: { name: string; nic: string; ipaddr: string; port: string; endchar: string; keySentence: string; dataChanged: boolean; cmd_type: CMD_INPUT_TYPE };
    constructor(props: any) {
        super(props);
        this.state = { name: this.defaultName, nic: 'eth0', ipaddr: '', port: '', endchar: '', keySentence: '', cmd_type: 'plain', dataChanged: false };
        const data = this.node_data;
        if (data && data.TCP) {
            const { ipaddr, port, endchar, command, nic, hexCommand } = data.TCP;
            this.state.name = data.name;
            this.state.ipaddr = ipaddr;
            this.state.port = port.toString();
            this.state.endchar = endchar;
            this.state.keySentence = command;
            this.state.nic = nic;
            // console.log('nic :>> ', nic);
            if (hexCommand.length > 0) {
                this.state.keySentence = pureHexStringToCypHexString(hexCommand);
                this.state.cmd_type = 'hex';
            }
        }
    }
    componentWillUnmount() { this.setState = () => { }; }
    get node_data() {
        const node_inst = window.canvas.getNode(window.canvas.curNode);
        return (node_inst && CsxEventSystem.isCsxEventParameter(node_inst.data)) ? node_inst.data : undefined;
    }
    onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ name: e.target.value, dataChanged: true }); }
    onKeySentenceChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ keySentence: e.target.value, dataChanged: true }); }
    onPortChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ port: e.target.value, dataChanged: true }); }
    onIpAddrChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ ipaddr: e.target.value, dataChanged: true }); }
    onNICChange = (value: string) => { this.setState({ nic: value, dataChanged: true }); }
    onEndCharChange = (value: string) => { this.setState({ endchar: value, dataChanged: true }); }
    onCmdTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { keySentence } = this.state;
        this.setState({
            cmd_type: (e.target.checked?'plain':'hex'),
            keySentence: ((e.target.checked)?cypHexStringToString(keySentence):stringToCypHexString(keySentence)),
            dataChanged: true,
        });
    }
    validateInput = (): { ip_error?: string; name_error?: string; key_error?: string } | undefined => {
        const { name, ipaddr, port, cmd_type, keySentence } = this.state;
        const hexMatcher = new RegExp(CYP_HEX_STRING_CHECK_PATTERN);
        const form_error: { ip_error?: string; name_error?: string; key_error?: string } = {};
        const parse_port = parseInt(port);
        if (name.length === 0)
            form_error.name_error = getText('HINT_NAME_CANNOT_NULL');
        if (CsxUtil.hasChinese(name) || name.indexOf(',') >= 0)
            form_error.name_error = getText('HINT_CONTANS_INVALID_CHAR');
        if (!(CsxUtil.isIP(ipaddr)))
            form_error.ip_error = getText('HINT_LAN_IP_INVALID');
        if (isNaN(parse_port) || parse_port <= 0 || parse_port >= 65536)
            form_error.ip_error = getText('HINT_PORT_INVALID');
        if (keySentence.length === 0)
            form_error.key_error = getText('HINT_KEYWORD_CANNOT_NULL');
        if (cmd_type === 'hex' && !hexMatcher.test(keySentence))
            form_error.key_error = `${getText('HINT_HEX_FORMAT_INCORRECT')} (${CYP_HEX_STRING_CHECK_PATTERN})`;
        return (CsxUtil.isEmptyObject(form_error)) ? undefined : form_error;
    }
    render() {
        const { name, nic, ipaddr, port, endchar, cmd_type, keySentence, dataChanged } = this.state;
        const form_error = this.validateInput();
        return (
            <Form.Form style={{ minWidth: '300px' }} labelStyle={{ width: '100px' }}>
                <Form.Item label={getText('NAME')} error={(form_error) ? form_error.name_error : undefined}><Input value={name} onChange={this.onNameChange} /></Form.Item>
                {/* <Form.Item label='NIC'>
                    <Select value={nic} onChange={this.onNICChange} placeholder='Network Interface Card'>
                        <Option value='eth0'>LAN 1</Option>
                        <Option value='eth1'>LAN 2</Option>
                    </Select>
                </Form.Item> */}
                <Form.Item label={getText('IP_PORT')} error={(form_error) ? form_error.ip_error : undefined}>
                    <Input value={ipaddr} onChange={this.onIpAddrChange} style={{ width: '180px' }} />
                    <Input className='port_input' value={port} placeholder='1~65535' onChange={this.onPortChange}/>
                </Form.Item>
                <Form.Item label={getText('ENDCHAR')}>
                    <Select value={endchar} onChange={this.onEndCharChange} placeholder='Trailing Character'>
                        <Option value={CsxTrailType.NA}>N/A</Option>
                        <Option value={CsxTrailType.CR}>CR</Option>
                        <Option value={CsxTrailType.LF}>LF</Option>
                        <Option value={CsxTrailType.CRLF}>CR/LF</Option>
                        <Option value={CsxTrailType.SPACE}>Space</Option>
                        <Option value={CsxTrailType.STX}>STX</Option>
                        <Option value={CsxTrailType.ETX}>ETX</Option>
                    </Select>
                </Form.Item>
                <Form.Item label={getText('KEYWORD')} error={(form_error) ? form_error.key_error : undefined}>
                    <Input value={keySentence} placeholder='Trigger keyword' onChange={this.onKeySentenceChange} />
                    <SwitchButton checked={(cmd_type === 'plain')} onChange={this.onCmdTypeChange} label={[ getText('PLAIN'), getText('HEX') ]}/>
                </Form.Item>
                <p style={{ textAlign: 'right', margin: '3px', lineHeight: '20px', color: 'gray', display: ((cmd_type === 'hex')?undefined:'none') }}>HEX format is \x01\x02 ...</p>
                <Form.Item style={{ justifyContent: 'flex-end' }}>
                    <Button type='primary' disabled={!!form_error || !dataChanged} onClick={() => {
                        const cur_nid = window.canvas.curNode;
                        const data = this.node_data;
                        const param: CsxEventSystem.CsxEventParameter = {
                            lockOnCanvas: (data) ? data.lockOnCanvas : false,
                            name,
                            TCP: {
                                ipaddr, port: parseInt(port), endchar: (isCsxTrailType(endchar) ? endchar : CsxTrailType.NA),
                                nic, 
                                command: (cmd_type === 'plain') ? keySentence : '',
                                hexCommand: (cmd_type === 'hex') ? cypHexStringToPureHexString(keySentence) : '',
                            }
                        };
                        window.canvas.setNodePort(cur_nid, 'in', 1, `${ipaddr}:${port}`);
                        window.canvas.setNodePort(cur_nid, 'in', 3, endchar);
                        if (cmd_type === 'plain')
                            window.canvas.setNodePort(cur_nid, 'in', 5, keySentence);
                        else if (cmd_type === 'hex')
                            window.canvas.setNodePort(cur_nid, 'in', 5, cypHexStringToPureHexString(keySentence));
                        window.canvas.setNodeData(cur_nid, param);
                        this.setState({ dataChanged: false });
                    }}>{getText('CONFIRM')}</Button>
                </Form.Item>
            </Form.Form>
        );
    }
}

export class ExternalRS232EventModalContent extends ModalBasicComponent {
    private defaultName: string = this.DEFAULT_NAME_TABLE.ExternalRS232Event;
    state: { name: string; endchar: string; keySentence: string; port: string; dataChanged: boolean; cmd_type: CMD_INPUT_TYPE };
    constructor(props: any) {
        super(props);
        this.state = { name: this.defaultName, endchar: '', keySentence: '', port: '0', cmd_type: 'plain', dataChanged: false };
        const data = this.node_data;
        if (data && data.Control) {
            const { endchar, command, hexCommand } = data.Control
            this.state.name = data.name;
            this.state.port = '0';
            this.state.endchar = endchar;
            this.state.keySentence = command;
            if (hexCommand.length > 0) {
                this.state.keySentence = pureHexStringToCypHexString(hexCommand);
                this.state.cmd_type = 'hex';
            }
        }
    }
    componentWillUnmount() { this.setState = () => { }; }
    get node_data() {
        const node_inst = window.canvas.getNode(window.canvas.curNode);
        return (node_inst && CsxEventSystem.isCsxEventParameter(node_inst.data)) ? node_inst.data : undefined;
    }
    onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ name: e.target.value, dataChanged: true }); }
    onKeySentenceChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ keySentence: e.target.value, dataChanged: true }); }
    onPortChange = (value: string) => { this.setState({ port: value, dataChanged: true }); }
    onEndCharChange = (value: string) => { this.setState({ endchar: value, dataChanged: true }); }
    onCmdTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { keySentence } = this.state;
        this.setState({
            cmd_type: (e.target.checked?'plain':'hex'),
            keySentence: ((e.target.checked)?cypHexStringToString(keySentence):stringToCypHexString(keySentence)),
            dataChanged: true,
        });
    }
    validateInput = (): { name_error?: string; key_error?: string } | undefined => {
        const { name, cmd_type, keySentence } = this.state;
        const hexMatcher = new RegExp(CYP_HEX_STRING_CHECK_PATTERN);
        const form_error: { name_error?: string; key_error?: string } = {};
        if (name.length === 0)
            form_error.name_error = getText('HINT_NAME_CANNOT_NULL');
        if (CsxUtil.hasChinese(name) || name.indexOf(',') >= 0)
            form_error.name_error = getText('HINT_CONTANS_INVALID_CHAR');
        if (keySentence.length === 0)
            form_error.key_error = getText('HINT_KEYWORD_CANNOT_NULL');
        if (cmd_type === 'hex' && !hexMatcher.test(keySentence))
            form_error.key_error = `${getText('HINT_HEX_FORMAT_INCORRECT')} (${CYP_HEX_STRING_CHECK_PATTERN})`;
        return (CsxUtil.isEmptyObject(form_error)) ? undefined : form_error;
    }
    render() {
        const { name, endchar, cmd_type, keySentence, dataChanged } = this.state;
        const form_error = this.validateInput();
        return (
            <Form.Form style={{ minWidth: '300px' }} labelStyle={{ width: '100px' }}>
                <Form.Item label={getText('NAME')} error={(form_error) ? form_error.name_error : undefined}><Input value={name} onChange={this.onNameChange} /></Form.Item>
                {/* <Form.Item label='Port'>
                <Select value={port} onChange={this.onPortChange} placeholder='RS232 Port'>
                    <Option value='0'>All</Option>
                    <Option value='1'>1</Option>
                    <Option value='2'>2</Option>
                </Select>
            </Form.Item> */}
                <Form.Item label={getText('ENDCHAR')}>
                    <Select value={endchar} onChange={this.onEndCharChange} placeholder='Trailing Character'>
                        <Option value={CsxTrailType.NA}>N/A</Option>
                        <Option value={CsxTrailType.CR}>CR</Option>
                        <Option value={CsxTrailType.LF}>LF</Option>
                        <Option value={CsxTrailType.CRLF}>CR/LF</Option>
                        <Option value={CsxTrailType.SPACE}>Space</Option>
                        <Option value={CsxTrailType.STX}>STX</Option>
                        <Option value={CsxTrailType.ETX}>ETX</Option>
                    </Select>
                </Form.Item>
                <Form.Item label={getText('KEYWORD')} error={(form_error) ? form_error.key_error : undefined}>
                    <Input value={keySentence} placeholder='Trigger keyword' onChange={this.onKeySentenceChange} />
                    <SwitchButton checked={(cmd_type === 'plain')} onChange={this.onCmdTypeChange} label={[ getText('PLAIN'), getText('HEX') ]}/>
                </Form.Item>
                <p style={{ textAlign: 'right', margin: '3px', lineHeight: '20px', color: 'gray', display: ((cmd_type === 'hex')?undefined:'none') }}>HEX format is \x01\x02 ...</p>
                <Form.Item style={{ justifyContent: 'flex-end' }}>
                    <Button type='primary' disabled={!!form_error || !dataChanged} onClick={() => {
                        const cur_nid = window.canvas.curNode;
                        // const data = `${name},${port},${endchar},${keySentence}`;
                        const data = this.node_data;
                        const param: CsxEventSystem.CsxEventParameter = {
                            lockOnCanvas: (data) ? data.lockOnCanvas : false,
                            name,
                            Control: {
                                endchar: (isCsxTrailType(endchar) ? endchar : CsxTrailType.NA),
                                command: (cmd_type === 'plain') ? keySentence : '',
                                hexCommand: (cmd_type === 'hex') ? cypHexStringToPureHexString(keySentence) : '',
                            }
                        };
                        window.canvas.setNodePort(cur_nid, 'in', 1, endchar);
                        if (cmd_type === 'plain')
                            window.canvas.setNodePort(cur_nid, 'in', 3, keySentence);
                        else if (cmd_type === 'hex')
                            window.canvas.setNodePort(cur_nid, 'in', 3, cypHexStringToPureHexString(keySentence));
                        window.canvas.setNodeData(cur_nid, param);
                        this.setState({ dataChanged: false });
                    }}>{getText('CONFIRM')}</Button>
                </Form.Item>
            </Form.Form>
        );
    }
}

export class KeypadEventModalContent extends ModalBasicComponent {
    private defaultName: string = this.DEFAULT_NAME_TABLE.TriggerInEvent;
    state: { name: string; keycode: string; dataChanged: boolean };
    constructor(props: any) {
        super(props);
        this.state = { name: this.defaultName, keycode: '', dataChanged: true };
        const data = this.node_data;
        if (data && data.TriggerIn) {
            const { keycode } = data.TriggerIn;
            this.state.name = data.name;
            this.state.keycode = keycode;
        }
    }
    componentWillUnmount() { this.setState = () => { }; }
    get node_data() {
        const node_inst = window.canvas.getNode(window.canvas.curNode);
        return (node_inst && CsxEventSystem.isCsxEventParameter(node_inst.data)) ? node_inst.data : undefined;
    }
    onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ name: e.target.value, dataChanged: true }); }
    onChange = (value: string) => { this.setState({ keycode: value, dataChanged: true }); }
    validateInput = (): { name_error?: string; key_error?: string } | undefined => {
        const { name, keycode } = this.state;
        const form_error: { name_error?: string; key_error?: string } = {};
        if (name.length === 0)
            form_error.name_error = getText('HINT_NAME_CANNOT_NULL');
        if (CsxUtil.hasChinese(name) || name.indexOf(',') >= 0)
            form_error.name_error = getText('HINT_CONTANS_INVALID_CHAR');
        if (keycode.length === 0)
            form_error.key_error = getText('HINT_KEYWORD_CANNOT_NULL');
        return (CsxUtil.isEmptyObject(form_error)) ? undefined : form_error;
    }
    render() {
        const { keycode, name, dataChanged } = this.state;
        const form_error = this.validateInput();
        return (
            <Form.Form style={{ minWidth: '300px' }} labelStyle={{ width: '100px' }}>
                <Form.Item label={getText('NAME')} error={(form_error) ? form_error.name_error : undefined}><Input value={name} onChange={this.onNameChange} /></Form.Item>
                <Form.Item label={getText('KEYCODE')} error={(form_error) ? form_error.key_error : undefined}>
                    {/* <Input className='keypad_evt_edit_modal_input' value={keycode} placeholder='Trigger keycode' onChange={this.onChange} /> */}
                    <Select value={keycode} placeholder='Trigger keycode' onChange={this.onChange}>
                        {Array.from(Array(8).keys()).map(idx => <Option value={(idx + 1).toString()} key={`keypad_evt_keycode_${idx}`}>{(idx + 1)}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item style={{ justifyContent: 'flex-end' }}>
                    <Button disabled={!!form_error || !dataChanged} type='primary' onClick={() => {
                        const data = this.node_data;
                        const param: CsxEventSystem.CsxEventParameter = {
                            lockOnCanvas: (data) ? data.lockOnCanvas : false,
                            name,
                            TriggerIn: {
                                keycode
                            }
                        };
                        window.canvas.setNodePort(window.canvas.curNode, 'in', 1, keycode);
                        window.canvas.setNodeData(window.canvas.curNode, param);
                        this.setState({ dataChanged: false });
                    }}>{getText('CONFIRM')}</Button>
                </Form.Item>
            </Form.Form>
        );
    }
}

export class PollingEventModalContent extends ModalBasicComponent {
    private defaultName: string = this.DEFAULT_NAME_TABLE.PollingEvent;
    state: { name: string; nic: string; ipaddr: string; port: string; pollingCmd: string; keySentence: string; pollingEnd: string; keyEnd: string; dataChanged: boolean; cmd_type: CMD_INPUT_TYPE };
    constructor(props: any) {
        super(props);
        this.state = { name: this.defaultName, nic: 'eth0', ipaddr: '', port: '', pollingCmd: '', keySentence: '', pollingEnd: '', keyEnd: '', cmd_type: 'plain', dataChanged: false };
        const data = this.node_data;
        if (data && data.Polling) {
            const { ipaddr, port, polling, hexPolling, hexDetect, pollingEndchar, detect, detectEndchar } = data.Polling;
            this.state.name = data.name;
            this.state.ipaddr = ipaddr;
            this.state.port = port.toString();
            this.state.pollingEnd = pollingEndchar;
            this.state.keyEnd = detectEndchar;
            this.state.pollingCmd = polling;
            this.state.keySentence = detect;
            if (hexPolling.length > 0) {
                this.state.pollingCmd = pureHexStringToCypHexString(hexPolling);
                this.state.keySentence = pureHexStringToCypHexString(hexDetect);
                this.state.cmd_type = 'hex';
            }
        }
    }
    componentWillUnmount() { this.setState = () => { }; }
    get node_data() {
        const node_inst = window.canvas.getNode(window.canvas.curNode);
        return (node_inst && CsxEventSystem.isCsxEventParameter(node_inst.data)) ? node_inst.data : undefined;
    }
    onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ name: e.target.value, dataChanged: true }); }
    onPollingCmdChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ pollingCmd: e.target.value, dataChanged: true }); }
    onKeySentenceChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ keySentence: e.target.value, dataChanged: true }); }
    onPortChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ port: e.target.value, dataChanged: true }); }
    onIpAddrChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ ipaddr: e.target.value, dataChanged: true }); }
    onPollingEndChange = (value: string) => { this.setState({ pollingEnd: value, dataChanged: true }); }
    onKeyEndChange = (value: string) => { this.setState({ keyEnd: value, dataChanged: true }); }
    onNICChange = (value: string) => { this.setState({ nic: value, dataChanged: true }); }
    onCmdTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { keySentence, pollingCmd } = this.state;
        this.setState({
            cmd_type: (e.target.checked?'plain':'hex'),
            pollingCmd: ((e.target.checked)?cypHexStringToString(pollingCmd):stringToCypHexString(pollingCmd)),
            keySentence: ((e.target.checked)?cypHexStringToString(keySentence):stringToCypHexString(keySentence)),
            dataChanged: true,
        });
    }
    validateInput = (): { ip_error?: string; name_error?: string; key_error?: string; polling_error?: string } | undefined => {
        const { ipaddr, pollingCmd, port, cmd_type, keySentence, name } = this.state;
        const hexMatcher = new RegExp(CYP_HEX_STRING_CHECK_PATTERN);
        const form_error: { ip_error?: string; name_error?: string; key_error?: string; polling_error?: string } = {};
        const parse_port = parseInt(port);
        if (name.length === 0)
            form_error.name_error = getText('HINT_NAME_CANNOT_NULL');
        if (CsxUtil.hasChinese(name) || name.indexOf(',') >= 0)
            form_error.name_error = getText('HINT_CONTANS_INVALID_CHAR');
        if (!(CsxUtil.isIP(ipaddr)))
            form_error.ip_error = getText('HINT_LAN_IP_INVALID');
        if (isNaN(parse_port) || parse_port <= 0 || parse_port >= 65536)
            form_error.ip_error = getText('HINT_PORT_INVALID');
        if (pollingCmd.length === 0)
            form_error.polling_error = 'Polling cannot be null';
        if (keySentence.length === 0)
            form_error.key_error = getText('HINT_KEYWORD_CANNOT_NULL');
        if (cmd_type === 'hex' && !hexMatcher.test(pollingCmd))
            form_error.polling_error = `${getText('HINT_HEX_FORMAT_INCORRECT')} (${CYP_HEX_STRING_CHECK_PATTERN})`;
        if (cmd_type === 'hex' && !hexMatcher.test(keySentence))
            form_error.key_error = `${getText('HINT_HEX_FORMAT_INCORRECT')} (${CYP_HEX_STRING_CHECK_PATTERN})`;
        return (CsxUtil.isEmptyObject(form_error)) ? undefined : form_error;
    }
    render() {
        const { name, nic, ipaddr, cmd_type, pollingCmd, pollingEnd, port, keySentence, keyEnd } = this.state;
        const form_error = this.validateInput();
        return (
            <Form.Form style={{ minWidth: '300px' }} labelStyle={{ width: '100px' }}>
                <Form.Item label={getText('NAME')} error={(form_error) ? form_error.name_error : undefined}><Input value={name} onChange={this.onNameChange} /></Form.Item>
                {/* <Form.Item label='NIC'>
                    <Select value={nic} onChange={this.onNICChange} placeholder='Network Interface Card'>
                        <Option value='eth0'>LAN 1</Option>
                        <Option value='eth1'>LAN 2</Option>
                    </Select>
                </Form.Item> */}
                <Form.Item label={getText('IP_PORT')} error={(form_error) ? form_error.ip_error : undefined}>
                    <Input value={ipaddr} onChange={this.onIpAddrChange} style={{ width: '180px' }}/>
                    <Input className='port_input' value={port} placeholder='1~65535' onChange={this.onPortChange} />
                </Form.Item>
                <Form.Item label={getText('INPUT_TYPE')}>
                    <SwitchButton checked={(cmd_type === 'plain')} onChange={this.onCmdTypeChange} label={[ getText('PLAIN'), getText('HEX') ]}/>
                </Form.Item>
                <p style={{ textAlign: 'right', margin: '3px', lineHeight: '20px', color: 'gray', display: ((cmd_type === 'hex')?undefined:'none') }}>HEX format is \x01\x02 ...</p>
                <Form.Item label={getText('POLLING')} error={(form_error) ? form_error.polling_error : undefined}>
                    <Input value={pollingCmd} placeholder='Polling command' onChange={this.onPollingCmdChange} />
                    <Select value={pollingEnd} onChange={this.onPollingEndChange} placeholder='Trailing' style={{ width: '100px', flexGrow: 0 }}>
                        <Option value={CsxTrailType.NA}>N/A</Option>
                        <Option value={CsxTrailType.CR}>CR</Option>
                        <Option value={CsxTrailType.LF}>LF</Option>
                        <Option value={CsxTrailType.CRLF}>CR/LF</Option>
                        <Option value={CsxTrailType.SPACE}>Space</Option>
                        <Option value={CsxTrailType.STX}>STX</Option>
                        <Option value={CsxTrailType.ETX}>ETX</Option>
                    </Select>
                </Form.Item>
                <Form.Item label={getText('DETECT')} error={(form_error) ? form_error.key_error : undefined}>
                    <Input value={keySentence} placeholder='Trigger keyword' onChange={this.onKeySentenceChange} />
                    <Select value={keyEnd} onChange={this.onKeyEndChange} placeholder='Trailing' style={{ width: '100px', flexGrow: 0 }}>
                        <Option value={CsxTrailType.NA}>N/A</Option>
                        <Option value={CsxTrailType.CR}>CR</Option>
                        <Option value={CsxTrailType.LF}>LF</Option>
                        <Option value={CsxTrailType.CRLF}>CR/LF</Option>
                        <Option value={CsxTrailType.SPACE}>Space</Option>
                        <Option value={CsxTrailType.STX}>STX</Option>
                        <Option value={CsxTrailType.ETX}>ETX</Option>
                    </Select>
                </Form.Item>
                <Form.Item style={{ justifyContent: 'flex-end' }}>
                    <Button disabled={!!form_error || !this.state.dataChanged} type='primary' onClick={() => {
                        const cur_nid = window.canvas.curNode;
                        const data = this.node_data;
                        const param: CsxEventSystem.CsxEventParameter = {
                            lockOnCanvas: (data) ? data.lockOnCanvas : false,
                            name,
                            Polling: {
                                ipaddr, nic,
                                port: parseInt(port),
                                polling: (cmd_type === 'plain') ? pollingCmd : '',
                                detect: (cmd_type === 'plain') ? keySentence : '',
                                pollingRef: '',
                                pollingEndchar: pollingEnd,
                                detectEndchar: keyEnd,
                                hexDetect: (cmd_type === 'hex') ? cypHexStringToPureHexString(keySentence) : '',
                                hexPolling: (cmd_type === 'hex') ? cypHexStringToPureHexString(pollingCmd) : '',
                            }
                        };
                        window.canvas.setNodePort(cur_nid, 'in', 1, `${ipaddr}:${port}`);
                        if (cmd_type === 'plain') {
                            window.canvas.setNodePort(cur_nid, 'in', 3, `${pollingCmd}:${pollingEnd}`);
                            window.canvas.setNodePort(cur_nid, 'in', 5, `${keySentence}:${keyEnd}`);
                        } else if (cmd_type === 'hex') {
                            window.canvas.setNodePort(cur_nid, 'in', 3, `${cypHexStringToPureHexString(pollingCmd)}:${pollingEnd}`);
                            window.canvas.setNodePort(cur_nid, 'in', 5, `${cypHexStringToPureHexString(keySentence)}:${keyEnd}`);
                        }
                        window.canvas.setNodeData(cur_nid, param);
                        this.setState({ dataChanged: false });
                    }}>{getText('CONFIRM')}</Button>
                </Form.Item>
            </Form.Form>
        );
    }
}

export class CYPActionModalContent extends ModalBasicComponent {
    private defaultName: string = this.DEFAULT_NAME_TABLE.CYPAction;
    state: { name: string; device: string; command: string; order: string; delay: string; scope_s: string; fastcmd_s: string; dev_support_features: Array<CsxFeature.FeatureSupportType>; dev_fastcmd_list: Array<string>; dataChanged: boolean };
    constructor(props: any) {
        super(props);
        const default_support_list: Array<CsxFeature.FeatureSupportType> = [];
        this.state = { name: this.defaultName, device: '', command: '', order: '', delay: '0', scope_s: '', fastcmd_s: '', dev_support_features: default_support_list, dev_fastcmd_list: [], dataChanged: false };
        const data = this.node_data;
        if (data && data.CYPDevice) {
            const { device, command } = data.CYPDevice;
            this.state.name = data.name;
            this.state.order = data.priority.toString();
            this.state.delay = data.delay.toString();
            this.state.device = device;
            this.state.command = command;
            this.state.dev_support_features = calculateSupportFeatureByDeviceID(device);
        }
    }
    componentWillUnmount() { this.setState = () => { }; }
    get node_data() {
        const node_inst = window.canvas.getNode(window.canvas.curNode);
        return (node_inst && CsxEventSystem.isCsxActionParameter(node_inst.data)) ? node_inst.data : undefined;
    }
    onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ name: e.target.value, dataChanged: true }); }
    onOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ order: e.target.value, dataChanged: true }); }
    onDelayChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ delay: e.target.value, dataChanged: true }); }
    onCommandChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ command: e.target.value, dataChanged: true }); }
    onDeviceChange = (value: string) => {
        this.setState({ device: value, dev_support_features: calculateSupportFeatureByDeviceID(value), scope_s: '', fastcmd_s: '', dataChanged: true });
    }
    onScopeChange = (value: string) => {
        const { device } = this.state;
        this.setState({ scope_s: value, dev_fastcmd_list: calculateFastcmdByDeviceIDAndScope(device, value) });
    }
    onFastcmdChange = (value: string) => { this.setState({ fastcmd_s: value, command: value, dataChanged: true }); }
    validateInput = (): { dev_error?: string; name_error?: string; order_error?: string; cmd_error?: string; delay_error?: string } | undefined => {
        const { device, command, order, name, delay } = this.state;
        const form_error: { dev_error?: string; name_error?: string; order_error?: string; cmd_error?: string; delay_error?: string } = {};
        const delay_parse = parseInt(delay);
        if (name.length === 0)
            form_error.name_error = getText('HINT_NAME_CANNOT_NULL');
        if (CsxUtil.hasChinese(name) || name.indexOf(',') >= 0)
            form_error.name_error = getText('HINT_CONTANS_INVALID_CHAR');
        if (device.length === 0)
            form_error.dev_error = getText('HINT_DEVICE_CANNOT_NULL');
        if (command.length === 0)
            form_error.cmd_error = getText('HINT_CMD_CANNOT_NULL');
        if (order && isNaN(parseInt(order)))
            form_error.order_error = getText('HINT_ORDER_INVALID');
        if (isNaN(delay_parse)) {
            if (delay.length > 0) {
                form_error.delay_error = getText('HINT_DELAY_INVALID');
            }
        } else {
            if (delay_parse < 0) {
                form_error.delay_error = getText('HINT_DELAY_MUST_POSITIVE');
            }
        }
        return (CsxUtil.isEmptyObject(form_error)) ? undefined : form_error;
    }
    render() {
        const { name, device, command, order, delay, scope_s, fastcmd_s, dev_fastcmd_list, dev_support_features, dataChanged } = this.state;
        const gw_inst = window.FOCUS_GATEWAY;
        const form_error = this.validateInput();
        return (
            <Form.Form labelStyle={{ width: '150px' }}>
                <Form.Item label={getText('NAME')} error={(form_error) ? form_error.name_error : undefined}><Input value={name} onChange={this.onNameChange} /></Form.Item>
                <Form.Item label={getText('PRIORITY')} error={(form_error) ? form_error.order_error : undefined} style={CsxUI.getHiddenStyle(window.canvas.curNode !== -1)}>
                    <Input className='order_input' value={order} placeholder='0(Default)' onChange={this.onOrderChange} />
                    <p style={{ color: 'gray', fontSize: '12px', height: '40px', paddingTop: '20px', fontStyle: 'italic' }}>Larger value has higher priority</p>
                </Form.Item>
                <Form.Item label={getText('DELAY')} error={(form_error) ? form_error.delay_error : undefined}>
                    <Input value={delay} placeholder='0(Default)' onChange={this.onDelayChange} />
                </Form.Item>
                <Form.Item label={getText('DEVICE')} error={(form_error) ? form_error.dev_error : undefined}>
                    <Select value={device} onChange={this.onDeviceChange} placeholder='CYP Device'>
                        {(gw_inst) ? Array.from(gw_inst.DEVICES).map(dev_id => {
                            const dev_inst = gw_inst.getDevice(dev_id);
                            const dev_model = (dev_inst) ? dev_inst.STA.Z8 : '';
                            const disp_name = (dev_inst) ? dev_inst.NICKNAME : dev_inst;
                            return <Option key={Math.random()} value={dev_id}>{(disp_name) ? disp_name : `${dev_model}(${dev_id.slice(9)})`}</Option>;
                        }) : []}
                    </Select>
                    {(device.length > 0) ? <SayHelloButton device_id={device}/> : undefined}
                </Form.Item>
                <Form.Item label={getText('SCOPE')} style={CsxUI.getHiddenStyle((device.length > 0))}>
                    <Select value={scope_s} placeholder='Select Function Scope' onChange={this.onScopeChange}>
                        {dev_support_features.map((feature, idx) => <Option key={`cypdev_feature_${idx}`} value={feature}>{feature}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label={getText('COMMAND_LIST')} style={CsxUI.getHiddenStyle((device.length > 0))}>
                    <Select value={fastcmd_s} placeholder='Select Command String' onChange={this.onFastcmdChange}>
                        {dev_fastcmd_list.map((cmdstr, idx) => <Option key={`fastcmd_str_${idx}`} value={cmdstr}>{cmdstr}</Option>)}
                    </Select>
                </Form.Item>
                <Form.Item label={getText('COMMAND')} error={(form_error) ? form_error.cmd_error : undefined}>
                    <Input value={command} placeholder='Run command' onChange={this.onCommandChange} />
                </Form.Item>
                <Form.Item style={{ justifyContent: 'flex-end' }}>
                    <Button type='primary' disabled={!!form_error || !dataChanged} onClick={() => {
                        const cur_nid = window.canvas.curNode;
                        const order_s = (order.length > 0) ? order : '0';
                        // const data = `${name},${order_s},${device},${command}`;
                        const data = this.node_data;
                        const param: CsxEventSystem.CsxActionParameter = {
                            lockOnCanvas: (data) ? data.lockOnCanvas : false,
                            name,
                            delay: isNaN(parseInt(delay)) ? 0 : parseInt(delay),
                            priority: parseInt(order_s),
                            CYPDevice: {
                                device, 
                                command,
                            }
                        };
                        window.canvas.setNodePort(cur_nid, 'out', 1, device);
                        window.canvas.setNodePort(cur_nid, 'out', 3, command);
                        window.canvas.setNodePort(cur_nid, 'in', 3, (order_s === '0') ? '0(default)' : order_s);
                        window.canvas.setNodeData(cur_nid, param);
                        this.setState({ dataChanged: false });
                    }}>{getText('CONFIRM')}</Button>
                </Form.Item>
            </Form.Form>
        );
    }
}

export class TCPActionModalContent extends ModalBasicComponent {
    private defaultName: string = this.DEFAULT_NAME_TABLE.TCPAction;
    state: { name: string; nic: string; ipaddr: string; port: string; endchar: string; command: string; order: string; delay: string; dataChanged: boolean; cmd_type: CMD_INPUT_TYPE };
    constructor(props: any) {
        super(props);
        this.state = { name: this.defaultName, nic: 'eth0', ipaddr: '', port: '', endchar: '', command: '', cmd_type: 'plain', order: '', delay: '0', dataChanged: false };
        const data = this.node_data;
        if (data) {
            this.state.name = data.name;
            this.state.order = data.priority.toString();
            this.state.delay = data.delay.toString();
            if (data.TCP) {
                const { ipaddr, nic, port, endchar, command, hexCommand } = data.TCP;
                this.state.ipaddr = ipaddr;
                this.state.endchar = endchar;
                this.state.port = port.toString();
                this.state.command = command;
                this.state.nic = nic;
                if (hexCommand.length > 0) {
                    this.state.command = pureHexStringToCypHexString(hexCommand);
                    this.state.cmd_type = 'hex';
                }
            }
        }
    }
    componentWillUnmount() { this.setState = () => { }; }
    get node_data() {
        const node_inst = window.canvas.getNode(window.canvas.curNode);
        return (node_inst && CsxEventSystem.isCsxActionParameter(node_inst.data)) ? node_inst.data : undefined;
    }
    onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ name: e.target.value, dataChanged: true }); }
    onOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ order: e.target.value, dataChanged: true }); }
    onDelayChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ delay: e.target.value, dataChanged: true }); }
    onCommandChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ command: e.target.value, dataChanged: true }); }
    onPortChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ port: e.target.value, dataChanged: true }); }
    onIpAddrChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ ipaddr: e.target.value, dataChanged: true }); }
    onCmdTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { command } = this.state;
        this.setState({
            cmd_type: (e.target.checked?'plain':'hex'),
            command: ((e.target.checked)?cypHexStringToString(command):stringToCypHexString(command)),
            dataChanged: true,
        });
    }
    onEndCharChange = (value: string) => { this.setState({ endchar: value, dataChanged: true }); }
    onNICChange = (value: string) => { this.setState({ nic: value, dataChanged: true }); }
    validateInput = (): { ip_error?: string; name_error?: string; cmd_error?: string; order_error?: string; delay_error?: string } | undefined => {
        const { name, ipaddr, port, cmd_type, command, order, delay } = this.state;
        const hexMatcher = new RegExp(CYP_HEX_STRING_CHECK_PATTERN);
        const form_error: { ip_error?: string; name_error?: string; cmd_error?: string; order_error?: string; delay_error?: string } = {};
        const parse_port = parseInt(port);
        const delay_parse = parseInt(delay);
        if (name.length === 0)
            form_error.name_error = getText('HINT_NAME_CANNOT_NULL');
        if (CsxUtil.hasChinese(name) || name.indexOf(',') >= 0)
            form_error.name_error = getText('HINT_CONTANS_INVALID_CHAR');
        if (!(CsxUtil.isIP(ipaddr)))
            form_error.ip_error = getText('HINT_LAN_IP_INVALID');
        if (isNaN(parse_port) || parse_port <= 0 || parse_port >= 65536)
            form_error.ip_error = getText('HINT_PORT_INVALID');
        if (command.length === 0)
            form_error.cmd_error = getText('HINT_CMD_CANNOT_NULL');
        if (order && isNaN(parseInt(order)))
            form_error.order_error = getText('HINT_ORDER_INVALID');
        if (isNaN(delay_parse)) {
            if (delay.length > 0) {
                form_error.delay_error = getText('HINT_DELAY_INVALID');
            }
        } else {
            if (delay_parse < 0) {
                form_error.delay_error = getText('HINT_DELAY_MUST_POSITIVE');
            }
        }
        if (cmd_type === 'hex' && !hexMatcher.test(command))
            form_error.cmd_error = `${getText('HINT_HEX_FORMAT_INCORRECT')} (${CYP_HEX_STRING_CHECK_PATTERN})`;
        return (CsxUtil.isEmptyObject(form_error)) ? undefined : form_error;
    }
    render() {
        const { name, nic, ipaddr, port, endchar, command, cmd_type, order, delay, dataChanged } = this.state;
        const form_error = this.validateInput();
        return (
            <Form.Form labelStyle={{ width: '110px' }}>
                <Form.Item label={getText('NAME')} error={(form_error) ? form_error.name_error : undefined}><Input value={name} onChange={this.onNameChange} /></Form.Item>
                <Form.Item label={getText('PRIORITY')} error={(form_error) ? form_error.order_error : undefined} style={CsxUI.getHiddenStyle(window.canvas.curNode !== -1)}>
                    <Input className='order_input' value={order} placeholder='0(Default)' onChange={this.onOrderChange} />
                    <p style={{ color: 'gray', fontSize: '12px', height: '40px', paddingTop: '20px', fontStyle: 'italic' }}>Larger value has higher priority</p>
                </Form.Item>
                <Form.Item label={getText('DELAY')} error={(form_error) ? form_error.delay_error : undefined}>
                    <Input value={delay} placeholder='0(Default)' onChange={this.onDelayChange} />
                </Form.Item>
                <Form.Item label={getText('NIC')}>
                    <Select value={nic} onChange={this.onNICChange} placeholder='Network Interface Card'>
                        <Option value='eth0'>LAN 1</Option>
                        <Option value='eth1'>LAN 2</Option>
                    </Select>
                </Form.Item>
                <Form.Item label={getText('IP_PORT')} error={(form_error) ? form_error.ip_error : undefined}>
                    <Input value={ipaddr} onChange={this.onIpAddrChange} style={{ width: '180px' }}/>
                    <Input className='port_input' value={port} placeholder='1~65535' onChange={this.onPortChange} />
                </Form.Item>
                <Form.Item label={getText('ENDCHAR')}>
                    <Select value={endchar} onChange={this.onEndCharChange} placeholder='Trailing Character'>
                        <Option value={CsxTrailType.NA}>N/A</Option>
                        <Option value={CsxTrailType.CR}>CR</Option>
                        <Option value={CsxTrailType.LF}>LF</Option>
                        <Option value={CsxTrailType.CRLF}>CR/LF</Option>
                        <Option value={CsxTrailType.SPACE}>Space</Option>
                        <Option value={CsxTrailType.STX}>STX</Option>
                        <Option value={CsxTrailType.ETX}>ETX</Option>
                    </Select>
                </Form.Item>
                <Form.Item label={getText('COMMAND')} error={(form_error) ? form_error.cmd_error : undefined}>
                    <Input value={command} placeholder='Run Command' onChange={this.onCommandChange} />
                    <SwitchButton checked={(cmd_type === 'plain')} onChange={this.onCmdTypeChange} label={[ getText('PLAIN'), getText('HEX') ]}/>
                </Form.Item>
                <p style={{ textAlign: 'right', margin: '3px', lineHeight: '20px', color: 'gray', display: ((cmd_type === 'hex')?undefined:'none') }}>HEX format is \x01\x02 ...</p>
                <Form.Item style={{ justifyContent: 'flex-end' }}>
                    <Button type='primary' disabled={!!form_error || !dataChanged} onClick={() => {
                        const cur_nid = window.canvas.curNode;
                        const order_s = (order.length > 0) ? order : '0';
                        // const data = `${name},${order_s},${ipaddr},${port},${endchar},${command}`;
                        const data = this.node_data;
                        const param: CsxEventSystem.CsxActionParameter = {
                            lockOnCanvas: (data) ? data.lockOnCanvas : false,
                            name,
                            delay: isNaN(parseInt(delay)) ? 0 : parseInt(delay),
                            priority: parseInt(order_s)
                        };
                        if (data && data.TCP) {
                            param.TCP = {
                                ipaddr,
                                endchar: (isCsxTrailType(endchar) ? endchar : CsxTrailType.NA),
                                command: (cmd_type === 'plain') ? command : '',
                                hexCommand: (cmd_type === 'hex') ? cypHexStringToPureHexString(command) : '',
                                port: parseInt(port), nic,
                                commandRef: '',
                            };
                        }
                        window.canvas.setNodePort(cur_nid, 'out', 1, `${ipaddr}:${port}`);
                        window.canvas.setNodePort(cur_nid, 'out', 3, endchar);
                        window.canvas.setNodePort(cur_nid, 'in', 3, (order_s === '0') ? '0(default)' : order_s);
                        if (cmd_type === 'plain')
                            window.canvas.setNodePort(cur_nid, 'out', 5, command);
                        else if (cmd_type === 'hex')
                            window.canvas.setNodePort(cur_nid, 'out', 5, cypHexStringToPureHexString(command));
                        window.canvas.setNodeData(cur_nid, param);
                        this.setState({ dataChanged: false });
                    }}>{getText('CONFIRM')}</Button>
                </Form.Item>
            </Form.Form>
        );
    }
}

export class UDPActionModalContent extends ModalBasicComponent {
    private defaultName: string = this.DEFAULT_NAME_TABLE.TCPAction;
    state: { name: string; nic: string; ipaddr: string; port: string; endchar: string; command: string; order: string; delay: string; dataChanged: boolean; cmd_type: CMD_INPUT_TYPE };
    constructor(props: any) {
        super(props);
        this.state = { name: this.defaultName, nic: 'eth0', ipaddr: '', port: '', endchar: '', command: '', order: '', delay: '0', cmd_type: 'plain', dataChanged: false };
        const data = this.node_data;
        if (data) {
            this.state.name = data.name;
            this.state.order = data.priority.toString();
            this.state.delay = data.delay.toString();
            if (data.UDP) {
                const { ipaddr, nic, port, endchar, command, hexCommand } = data.UDP;
                // this.defaultName = DEFAULT_NAME_TABLE.UDPAction;
                this.state.ipaddr = ipaddr;
                this.state.endchar = endchar;
                this.state.port = port.toString();
                this.state.command = command;
                this.state.nic = nic;
                if (hexCommand.length > 0) {
                    this.state.command = pureHexStringToCypHexString(hexCommand);
                    this.state.cmd_type = 'hex';
                }
            }
        }
    }
    componentWillUnmount() { this.setState = () => { }; }
    get node_data() {
        const node_inst = window.canvas.getNode(window.canvas.curNode);
        return (node_inst && CsxEventSystem.isCsxActionParameter(node_inst.data)) ? node_inst.data : undefined;
    }
    onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ name: e.target.value, dataChanged: true }); }
    onOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ order: e.target.value, dataChanged: true }); }
    onDelayChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ delay: e.target.value, dataChanged: true }); }
    onCommandChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ command: e.target.value, dataChanged: true }); }
    onPortChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ port: e.target.value, dataChanged: true }); }
    onIpAddrChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ ipaddr: e.target.value, dataChanged: true }); }
    onCmdTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { command } = this.state;
        this.setState({
            cmd_type: (e.target.checked?'plain':'hex'),
            command: ((e.target.checked)?cypHexStringToString(command):stringToCypHexString(command)),
            dataChanged: true,
        });
    }
    onEndCharChange = (value: string) => { this.setState({ endchar: value, dataChanged: true }); }
    onNICChange = (value: string) => { this.setState({ nic: value, dataChanged: true }); }
    validateInput = (): { ip_error?: string; name_error?: string; cmd_error?: string; order_error?: string; delay_error?: string } | undefined => {
        const { name, ipaddr, port, cmd_type, command, order, delay } = this.state;
        const hexMatcher = new RegExp(CYP_HEX_STRING_CHECK_PATTERN);
        const form_error: { ip_error?: string; name_error?: string; cmd_error?: string; order_error?: string; delay_error?: string } = {};
        const parse_port = parseInt(port);
        const delay_parse = parseInt(delay);
        if (name.length === 0)
            form_error.name_error = getText('HINT_NAME_CANNOT_NULL');
        if (CsxUtil.hasChinese(name) || name.indexOf(',') >= 0)
            form_error.name_error = getText('HINT_CONTANS_INVALID_CHAR');
        if (!(CsxUtil.isIP(ipaddr)))
            form_error.ip_error = getText('HINT_LAN_IP_INVALID');
        if (isNaN(parse_port) || parse_port <= 0 || parse_port >= 65536)
            form_error.ip_error = getText('HINT_PORT_INVALID');
        if (command.length === 0)
            form_error.cmd_error = getText('HINT_CMD_CANNOT_NULL');
        if (order && isNaN(parseInt(order)))
            form_error.order_error = getText('HINT_ORDER_INVALID');
        if (isNaN(delay_parse)) {
            if (delay.length > 0) {
                form_error.delay_error = getText('HINT_DELAY_INVALID');
            }
        } else {
            if (delay_parse < 0) {
                form_error.delay_error = getText('HINT_DELAY_MUST_POSITIVE');
            }
        }
        if (cmd_type === 'hex' && !hexMatcher.test(command))
            form_error.cmd_error = `${getText('HINT_HEX_FORMAT_INCORRECT')} (${CYP_HEX_STRING_CHECK_PATTERN})`;
        return (CsxUtil.isEmptyObject(form_error)) ? undefined : form_error;
    }
    render() {
        const { name, nic, ipaddr, port, endchar, command, cmd_type, order, delay, dataChanged } = this.state;
        const form_error = this.validateInput();
        return (
            <Form.Form labelStyle={{ width: '110px' }}>
                <Form.Item label={getText('NAME')} error={(form_error) ? form_error.name_error : undefined}><Input value={name} onChange={this.onNameChange} /></Form.Item>
                <Form.Item label={getText('PRIORITY')} error={(form_error) ? form_error.order_error : undefined} style={CsxUI.getHiddenStyle(window.canvas.curNode !== -1)}>
                    <Input className='order_input' value={order} placeholder='0(Default)' onChange={this.onOrderChange} />
                    <p style={{ color: 'gray', fontSize: '12px', height: '40px', paddingTop: '20px', fontStyle: 'italic' }}>Larger value has higher priority</p>
                </Form.Item>
                <Form.Item label={getText('DELAY')} error={(form_error) ? form_error.delay_error : undefined}>
                    <Input value={delay} placeholder='0(Default)' onChange={this.onDelayChange} />
                </Form.Item>
                <Form.Item label={getText('NIC')}>
                    <Select value={nic} onChange={this.onNICChange} placeholder='Network Interface Card'>
                        <Option value='eth0'>LAN 1</Option>
                        <Option value='eth1'>LAN 2</Option>
                    </Select>
                </Form.Item>
                <Form.Item label={getText('IP_PORT')} error={(form_error) ? form_error.ip_error : undefined}>
                    <Input value={ipaddr} onChange={this.onIpAddrChange} style={{ width: '180px' }}/>
                    <Input className='port_input' value={port} placeholder='1~65535' onChange={this.onPortChange} />
                </Form.Item>
                <Form.Item label={getText('IP_PORT')}>
                    <Select value={endchar} onChange={this.onEndCharChange} placeholder='Trailing Character'>
                        <Option value={CsxTrailType.NA}>N/A</Option>
                        <Option value={CsxTrailType.CR}>CR</Option>
                        <Option value={CsxTrailType.LF}>LF</Option>
                        <Option value={CsxTrailType.CRLF}>CR/LF</Option>
                        <Option value={CsxTrailType.SPACE}>Space</Option>
                        <Option value={CsxTrailType.STX}>STX</Option>
                        <Option value={CsxTrailType.ETX}>ETX</Option>
                    </Select>
                </Form.Item>
                <Form.Item label={getText('COMMAND')} error={(form_error) ? form_error.cmd_error : undefined}>
                    <Input value={command} placeholder='Run Command' onChange={this.onCommandChange} />
                    <SwitchButton checked={(cmd_type === 'plain')} onChange={this.onCmdTypeChange} label={[ getText('PLAIN'), getText('HEX') ]}/>
                </Form.Item>
                <p style={{ textAlign: 'right', margin: '3px', lineHeight: '20px', color: 'gray', display: ((cmd_type === 'hex')?undefined:'none') }}>HEX format is \x01\x02 ...</p>
                <Form.Item style={{ justifyContent: 'flex-end' }}>
                    <Button type='primary' disabled={!!form_error || !dataChanged} onClick={() => {
                        const cur_nid = window.canvas.curNode;
                        const order_s = (order.length > 0) ? order : '0';
                        // const data = `${name},${order_s},${ipaddr},${port},${endchar},${command}`;
                        const data = this.node_data;
                        const param: CsxEventSystem.CsxActionParameter = {
                            lockOnCanvas: (data) ? data.lockOnCanvas : false,
                            name,
                            delay: isNaN(parseInt(delay)) ? 0 : parseInt(delay),
                            priority: parseInt(order_s)
                        };
                        if (data && data.UDP) {
                            param.UDP = {
                                ipaddr,
                                endchar: (isCsxTrailType(endchar) ? endchar : CsxTrailType.NA),
                                command: (cmd_type === 'plain') ? command : '',
                                hexCommand: (cmd_type === 'hex') ? cypHexStringToPureHexString(command) : '',
                                port: parseInt(port), nic,
                                commandRef: '',
                            };
                        }
                        window.canvas.setNodePort(cur_nid, 'out', 1, `${ipaddr}:${port}`);
                        window.canvas.setNodePort(cur_nid, 'out', 3, endchar);
                        window.canvas.setNodePort(cur_nid, 'in', 3, (order_s === '0') ? '0(default)' : order_s);
                        if (cmd_type === 'plain')
                            window.canvas.setNodePort(cur_nid, 'out', 5, command);
                        else if (cmd_type === 'hex')
                            window.canvas.setNodePort(cur_nid, 'out', 5, cypHexStringToPureHexString(command));
                        window.canvas.setNodeData(cur_nid, param);
                        this.setState({ dataChanged: false });
                    }}>{getText('CONFIRM')}</Button>
                </Form.Item>
            </Form.Form>
        );
    }
}

export class RS232ActionModalContent extends ModalBasicComponent {
    private defaultName: string = this.DEFAULT_NAME_TABLE.RS232Action;
    state: { name: string; endchar: string; command: string; order: string; delay: string; dataChanged: boolean; cmd_type: CMD_INPUT_TYPE };
    constructor(props: any) {
        super(props);
        this.state = { name: this.defaultName, endchar: '', command: '', order: '', delay: '0', cmd_type: 'plain', dataChanged: true };
        const data = this.node_data;
        if (data && data.Control) {
            const { command, endchar, hexCommand } = data.Control;
            this.state.name = data.name;
            this.state.order = data.priority.toString();
            this.state.delay = data.delay.toString();
            this.state.endchar = endchar;
            this.state.command = command;
            if (hexCommand.length > 0) {
                this.state.command = pureHexStringToCypHexString(hexCommand);
                this.state.cmd_type = 'hex';
            }
        }
    }
    componentWillUnmount() { this.setState = () => { }; }
    get node_data() {
        const node_inst = window.canvas.getNode(window.canvas.curNode);
        return (node_inst && CsxEventSystem.isCsxActionParameter(node_inst.data)) ? node_inst.data : undefined;
    }
    onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ name: e.target.value, dataChanged: true }); }
    onOrderChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ order: e.target.value, dataChanged: true }); }
    onDelayChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ delay: e.target.value, dataChanged: true }); }
    onCommandChange = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ command: e.target.value, dataChanged: true }); }
    onCmdTypeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { command } = this.state;
        this.setState({
            cmd_type: (e.target.checked?'plain':'hex'),
            command: ((e.target.checked)?cypHexStringToString(command):stringToCypHexString(command)),
            dataChanged: true,
        });
    }
    onEndCharChange = (value: string) => { this.setState({ endchar: value, dataChanged: true }); }
    validateInput = (): { name_error?: string; order_error?: string; cmd_error?: string; delay_error?: string } | undefined => {
        const { command, cmd_type, order, name, delay } = this.state;
        const hexMatcher = new RegExp(CYP_HEX_STRING_CHECK_PATTERN);
        const form_error: { name_error?: string; order_error?: string; cmd_error?: string; delay_error?: string } = {};
        const delay_parse = parseInt(delay);
        if (name.length === 0)
            form_error.name_error = getText('HINT_NAME_CANNOT_NULL');
        if (CsxUtil.hasChinese(name) || name.indexOf(',') >= 0)
            form_error.name_error = getText('HINT_CONTANS_INVALID_CHAR');
        if (command.length === 0)
            form_error.cmd_error = getText('HINT_CMD_CANNOT_NULL');
        if (order && isNaN(parseInt(order)))
            form_error.order_error = getText('HINT_ORDER_INVALID');
        if (isNaN(delay_parse)) {
            if (delay.length > 0) {
                form_error.delay_error = getText('HINT_DELAY_INVALID');
            }
        } else {
            if (delay_parse < 0) {
                form_error.delay_error = getText('HINT_DELAY_MUST_POSITIVE');
            }
        }
        if (cmd_type === 'hex' && !hexMatcher.test(command))
            form_error.cmd_error = `${getText('HINT_HEX_FORMAT_INCORRECT')} (${CYP_HEX_STRING_CHECK_PATTERN})`;
        return (CsxUtil.isEmptyObject(form_error)) ? undefined : form_error;
    }
    render() {
        const { name, endchar, command, cmd_type, order, delay, dataChanged } = this.state;
        const form_error = this.validateInput();
        return (
            <Form.Form labelStyle={{ width: '110px' }}>
                <Form.Item label={getText('NAME')} error={(form_error) ? form_error.name_error : undefined}><Input value={name} onChange={this.onNameChange} /></Form.Item>
                <Form.Item label={getText('PRIORITY')} error={(form_error) ? form_error.order_error : undefined} style={CsxUI.getHiddenStyle(window.canvas.curNode !== -1)}>
                    <Input className='order_input' value={order} placeholder='0(Default)' onChange={this.onOrderChange} />
                    <p style={{ color: 'gray', fontSize: '12px', height: '40px', paddingTop: '20px', fontStyle: 'italic' }}>Larger value has higher priority</p>
                </Form.Item>
                <Form.Item label={getText('DELAY')} error={(form_error) ? form_error.delay_error : undefined}>
                    <Input value={delay} placeholder='0(Default)' onChange={this.onDelayChange} />
                </Form.Item>
                <Form.Item label={getText('ENDCHAR')}>
                    <Select value={endchar} onChange={this.onEndCharChange} placeholder='Trailing Character'>
                        <Option value={CsxTrailType.NA}>N/A</Option>
                        <Option value={CsxTrailType.CR}>CR</Option>
                        <Option value={CsxTrailType.LF}>LF</Option>
                        <Option value={CsxTrailType.CRLF}>CR/LF</Option>
                        <Option value={CsxTrailType.SPACE}>Space</Option>
                        <Option value={CsxTrailType.STX}>STX</Option>
                        <Option value={CsxTrailType.ETX}>ETX</Option>
                    </Select>
                </Form.Item>
                <Form.Item label={getText('COMMAND')} error={(form_error) ? form_error.cmd_error : undefined}>
                    <Input value={command} placeholder='Run Command' onChange={this.onCommandChange} />
                    <SwitchButton checked={(cmd_type === 'plain')} onChange={this.onCmdTypeChange} label={[ getText('PLAIN'), getText('HEX') ]}/>
                </Form.Item>
                <p style={{ textAlign: 'right', margin: '3px', lineHeight: '20px', color: 'gray', display: ((cmd_type === 'hex')?undefined:'none') }}>HEX format is \x01\x02 ...</p>
                <Form.Item style={{ justifyContent: 'flex-end' }}>
                    <Button type='primary' disabled={!!form_error || !dataChanged} onClick={() => {
                        const cur_nid = window.canvas.curNode;
                        const order_s = (order.length > 0) ? order : '0';
                        // const data = `${name},${order_s},${endchar},${command}`;
                        const data = this.node_data;
                        const param: CsxEventSystem.CsxActionParameter = {
                            lockOnCanvas: (data) ? data.lockOnCanvas : false,
                            name,
                            delay: isNaN(parseInt(delay)) ? 0 : parseInt(delay),
                            priority: parseInt(order_s),
                            Control: {
                                endchar: (isCsxTrailType(endchar) ? endchar : CsxTrailType.NA),
                                command: (cmd_type === 'plain') ? command : '',
                                hexCommand: (cmd_type === 'hex') ? cypHexStringToPureHexString(command) : '',
                                commandRef: '',
                            }
                        };
                        window.canvas.setNodePort(window.canvas.curNode, 'out', 1, endchar);
                        if (cmd_type === 'plain')
                            window.canvas.setNodePort(cur_nid, 'out', 3, command);
                        else if (cmd_type === 'hex')
                            window.canvas.setNodePort(cur_nid, 'out', 3, cypHexStringToPureHexString(command));
                        window.canvas.setNodePort(window.canvas.curNode, 'in', 3, (order_s === '0') ? '0(default)' : order_s);
                        window.canvas.setNodeData(cur_nid, param);
                        this.setState({ dataChanged: false });
                    }}>{getText('CONFIRM')}</Button>
                </Form.Item>
            </Form.Form>
        );
    }
}