import React from 'react';

import { CsxUI, CsxFeature, CsxUtil, CsxDesc } from '../../../csx';
import { Table, Icon, Form, Button, Input, SwitchButton, Select, Option, Slider, Modal, Tree, Checkbox, ProgressBar, Cone, Notify, RadioGroup, DateTime, Flat } from 'cypd';
import { MaxErrChart, MseChart } from '../../../chart';
import { CsxUserPermissionLevel, csxUserPermissionSatisfy, CSX_NAME } from '../../../csx/manager';


declare type Feature = { name: string; check: () => boolean }

const notHiddenIf = CsxUI.getHiddenStyle;
const { getText } = CsxDesc;
const slider_show_value_style: React.CSSProperties = { width: '30px', height: '40px', lineHeight: '40px', display: 'flex', flexDirection: 'row', justifyContent: 'center', position: 'relative' };

class DebugView extends CsxUI.IRQComponent<any> {
    state: { run_basic: boolean; run_regex: boolean; run_opt: boolean; run_audio: boolean; run_show_para: boolean; run_show_status: boolean ; console_in: string }
    instance: CsxFeature.CsxDebugDevice;
    vcBuffer: Array<string> = [];
    vcHeader = -1;
    shellRef?: HTMLDivElement | null;
    constructor(props: any) {
        super(props);
        this.state = { run_basic: true, run_regex: false, run_opt: false, run_audio: false, run_show_para: false, run_show_status: false, console_in: '' };
        this.instance = new CsxFeature.CsxDebugDevice(window.FOCUS_DEVICE);
        this.IRQIndex = 'MessageRealTime';
    }
    componentDidUpdate() {
        if (this.shellRef && this.shellRef.scrollTop) {
            if ((this.shellRef.scrollHeight - this.shellRef.scrollTop) <= (this.shellRef.clientHeight + 30))
                this.shellRef.scrollTop = this.shellRef.scrollHeight;
        }
    }
    shellRow(msg: string) {
        return <div className='shell_row'
            key={Math.random()}
            dangerouslySetInnerHTML={{ __html: msg }}
        />;
    }
    onChangeRunBasic = (event: React.ChangeEvent<HTMLInputElement>): void => { this.setState({ run_basic: event.target.checked }); }
    onChangeRunRegex = (event: React.ChangeEvent<HTMLInputElement>): void => { this.setState({ run_regex: event.target.checked }); }
    onChangeRunOption = (event: React.ChangeEvent<HTMLInputElement>): void => { this.setState({ run_opt: event.target.checked }); }
    onChangeRunAudio = (event: React.ChangeEvent<HTMLInputElement>): void => { this.setState({ run_audio: event.target.checked }); }
    onChangeRunShowPara = (event: React.ChangeEvent<HTMLInputElement>): void => { this.setState({ run_show_para: event.target.checked }); }
    onChangeRunShowStatus = (event: React.ChangeEvent<HTMLInputElement>): void => { this.setState({ run_show_status: event.target.checked }); }
    onInputChange = (event: React.ChangeEvent<HTMLInputElement>): void => { this.setState({ console_in: event.target.value }); }
    onInputEnter = (event: React.KeyboardEvent<HTMLInputElement>): void => {
        const { console_in } = this.state;
        if (event.keyCode === 13) { // enter button
            this.instance.VirtualConsoleSend(console_in);
            if (console_in.length > 0)
                this.vcBuffer.splice(0, 0, console_in);
            this.vcHeader = -1;
            this.setState({ console_in: '' });
            if (this.shellRef && this.shellRef.scrollTop)
                this.shellRef.scrollTop = this.shellRef.scrollHeight;
        } else if (event.keyCode === 38) { // up button
            if (this.vcBuffer.length === 0) return;
            this.vcHeader = (this.vcHeader < this.vcBuffer.length - 1) ? this.vcHeader + 1 : this.vcHeader;
            this.setState({ console_in: this.vcBuffer[this.vcHeader] });
        } else if (event.keyCode === 40) { // down button
            if (this.vcBuffer.length === 0) return;
            this.vcHeader = (this.vcHeader >= 0) ? this.vcHeader - 1 : this.vcHeader;
            this.setState({ console_in: ((this.vcHeader >= 0) ? this.vcBuffer[this.vcHeader] : '') });
        }
    }
    render() {
        const { run_basic, run_regex, run_opt, run_audio, run_show_status, run_show_para } = this.state;
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateConnected(window.FOCUS_DEVICE.SYS_STA));
        return <Flat.Playground loading={!ready}>
            <div className='terminal_container'>
                <Form.Item style={{ flexWrap: 'wrap', height: 'auto' }}>
                    <Button type='primary' onClick={() => {
                        this.MessageBuffer.free();
                        this.instance.StartDebug([run_basic, run_regex, run_opt, run_audio, false, run_show_para, run_show_status]);
                    }}>Run Test</Button>
                    <Checkbox label='Param: basic' checked={run_basic} onChange={this.onChangeRunBasic} />
                    <Checkbox label='Param: regex' checked={run_regex} onChange={this.onChangeRunRegex} />
                    <Checkbox label='Param: option' checked={run_opt} onChange={this.onChangeRunOption} />
                    <Checkbox label='Audio: basic' checked={run_audio} onChange={this.onChangeRunAudio} />
                    <Checkbox label='All Parameter' checked={run_show_para} onChange={this.onChangeRunShowPara} />
                    <Checkbox label='All Status' checked={run_show_status} onChange={this.onChangeRunShowStatus} />
                </Form.Item>
                <div className='shell_field' ref={(inst) => { this.shellRef = inst; }}>
                    {this.MessageBuffer.messages.map(msg => this.shellRow(msg))}
                </div>
                <div className='input_field'>
                    <span style={{ fontSize: '12px' }}>{`${CSX_NAME} Debug Shell`}</span>
                    <div className='prefix' >
                        <input value={this.state.console_in} onChange={this.onInputChange} onKeyUp={this.onInputEnter} />
                    </div>
                </div>
            </div>
        </Flat.Playground>;
    }
}

class IOView extends CsxUI.IRQComponent<any> {
    state: { usb_s: string; uart_s: string; ir_code_err: string; usb_hr_s?: string; usb_hr_s_changed: boolean; usb_hr_s_err: string }
    instance: CsxFeature.CsxIODevice
    constructor(props: any) {
        super(props);
        this.state = { usb_s: '1', uart_s: '1', ir_code_err: '', usb_hr_s_changed: false, usb_hr_s_err: '' };
        this.IRQIndex = 'DeviceRealTime';
        this.instance = new CsxFeature.CsxIODevice(window.FOCUS_DEVICE);
    }
    validateIRCustomCode = (value: string) => {
        const irInCustomCodeParam = this.instance.IRInCustomCodeParam();
        const ok = !(irInCustomCodeParam.h1.isRange && !this.validateRange(value, irInCustomCodeParam.h1.isRange));
        this.setState({ ir_code_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    validateUSBHostRoute = (value: string) => {
        const usbHRParam = this.instance.USBHostRouteParam();
        const ok = !(usbHRParam.nn1.isString && !this.validateString(value, usbHRParam.nn1.isString));
        this.setState({ usb_hr_s_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    onChangeIRCustomCode = (e: React.ChangeEvent<HTMLInputElement>) => { if (this.validateIRCustomCode(e.target.value) || e.target.value.length === 0) this.instance.SetIRInCustomCode(e.target.value); }
    onChangeUsbPowerSupply = (e: React.ChangeEvent<HTMLInputElement>) => {
        const usbPSParam = this.instance.USBPowerSupplyParam();
        const usb_ps_opt = (usbPSParam.b1.isOption) ? usbPSParam.b1.isOption.option : [];
        if (usb_ps_opt.length >= 2)
            this.instance.SetUSBPowerSupply(this.state.usb_s, (e.target.checked) ? usb_ps_opt[0] : usb_ps_opt[1]);
    }
    onChangeUsbHostRoute = (e: React.ChangeEvent<HTMLInputElement>) => { if (this.validateUSBHostRoute(e.target.value) || e.target.value.length === 0) this.setState({ usb_hr_s_changed: true, usb_hr_s: e.target.value }); }
    onChangeUsbControlMode = (value: string) => { this.instance.SetUSBControlMode(this.state.usb_s, value); }
    onChangeUartBaudrate = (value: string) => { this.instance.SetUARTBaudrate(this.state.uart_s, value); }
    onChangeUartDatabit = (value: string) => { this.instance.SetUARTDatabit(this.state.uart_s, value); }
    onChangeUartStopbit = (value: string) => { this.instance.SetUARTStopbit(this.state.uart_s, value); }
    onChangeUartParity = (value: string) => { this.instance.SetUARTParity(this.state.uart_s, value); }
    onChangeUartMode = (value: string) => { this.instance.SetUARTMode(this.state.uart_s, value); }
    onChangeIRInChannel = (value: string) => { this.instance.SetIRInChannel(value); }
    onChangeUartSelect = (value: string) => { this.setState({ uart_s: value }); }
    onChangeUsbSelect = (value: string) => { this.setState({ usb_s: value }); }
    onClickUARTReset = () => { this.instance.SetUARTReset(this.state.uart_s); }
    onSaveIRCustomCode = () => { this.instance.UploadInIRCustomCode(); this.setState({ ir_code_err: '' }); }
    onSaveUsbHostRoute = () => {
        const { usb_hr_s, usb_s } = this.state;
        if (usb_hr_s) {
            this.instance.SetUSBHostRoute(usb_s, usb_hr_s);
            this.setState({ usb_hr_s: undefined, usb_hr_s_changed: false, usb_hr_s_err: '' });
        }
    }
    render() {
        const { usb_s, uart_s, ir_code_err, usb_hr_s, usb_hr_s_changed, usb_hr_s_err } = this.state;
        const irInChannelParam = this.instance.IRInChannelParam();
        const usbVHParam = this.instance.USBVirtualHubParam();
        const usbCMParam = this.instance.USBControlModeParam();
        const usbHRParam = this.instance.USBHostRouteParam();
        const uartBRParam = this.instance.UARTBaudrateParam();
        const uartSBParam = this.instance.UARTStopbitParam();
        const uartDBParam = this.instance.UARTDatabitParam();
        const uartPTParam = this.instance.UARTParityParam();
        const uartModeParam = this.instance.UARTModeParam();
        const usbPSParam = this.instance.USBPowerSupplyParam();
        const ir_in_opt = (irInChannelParam.n1.isOption) ? irInChannelParam.n1.isOption.option : [];
        const ir_in_desc = (irInChannelParam.n1.isOption) ? irInChannelParam.n1.isOption.desc : [];
        let usb_opt = (usbPSParam.n1.isOption) ? usbPSParam.n1.isOption.option : [];
        let usb_desc = (usbPSParam.n1.isOption) ? usbPSParam.n1.isOption.desc : [];
        const usb_ps_opt = (usbPSParam.b1.isOption) ? usbPSParam.b1.isOption.option : [];
        const usb_ps_desc = (usbPSParam.b1.isOption) ? usbPSParam.b1.isOption.desc : [];
        const usb_cm_opt = (usbCMParam.n2.isOption) ? usbCMParam.n2.isOption.option : [];
        const usb_cm_desc = (usbCMParam.n2.isOption) ? usbCMParam.n2.isOption.desc : [];
        const uart_opt = (uartBRParam.n1.isOption) ? uartBRParam.n1.isOption.option : [];
        const uart_desc = (uartBRParam.n1.isOption) ? uartBRParam.n1.isOption.desc : [];
        const uart_br_opt = (uartBRParam.n2.isOption) ? uartBRParam.n2.isOption.option : [];
        const uart_sb_opt = (uartSBParam.n2.isOption) ? uartSBParam.n2.isOption.option : [];
        const uart_db_opt = (uartDBParam.n2.isOption) ? uartDBParam.n2.isOption.option : [];
        const uart_pt_opt = (uartPTParam.n2.isOption) ? uartPTParam.n2.isOption.option : [];
        const uart_pt_desc = (uartPTParam.n2.isOption) ? uartPTParam.n2.isOption.desc : [];
        const uart_mode_opt = (uartModeParam.n2.isOption) ? uartModeParam.n2.isOption.option : [];
        const uart_mode_desc = (uartModeParam.n2.isOption) ? uartModeParam.n2.isOption.desc : [];
        if (usbVHParam.n1.isOption && usbVHParam.n1.isOption.option.length > usb_opt.length) {
            usb_opt = usbVHParam.n1.isOption.option;
            usb_desc = usbVHParam.n1.isOption.desc;
        }
        if (usbCMParam.n1.isOption && usbCMParam.n1.isOption.option.length > usb_opt.length) {
            usb_opt = usbCMParam.n1.isOption.option;
            usb_desc = usbCMParam.n1.isOption.desc;
        }
        if (usbHRParam.n1.isOption && usbHRParam.n1.isOption.option.length > usb_opt.length) {
            usb_opt = usbHRParam.n1.isOption.option;
            usb_desc = usbHRParam.n1.isOption.desc;
        }
        const usb_ps_s = this.instance.USBPowerSupply(usb_s);
        const usb_cm_s = this.instance.USBControlMode(usb_s);
        const uart_br_s = this.instance.UARTBaudrate(uart_s);
        const uart_sb_s = this.instance.UARTStopbit(uart_s);
        const uart_db_s = this.instance.UARTDatabit(uart_s);
        const uart_pt_s = this.instance.UARTParity(uart_s);
        const uart_mode_s = this.instance.UARTMode(uart_s);
        const ir_in_ch = this.instance.IRInChannel();
        const ir_cus_code = this.instance.IRInCustomCode();
        // validate input
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_IO_IR_LABEL')} style={notHiddenIf(this.instance.IsSupportIRChannel() || this.instance.IsSupportIRCustomCode())} defaultExtend={true}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_IO_IR_IN_CHANNEL')} style={notHiddenIf(this.instance.IsSupportIRChannel())}><Select placeholder='Select Source Channel' value={ir_in_ch} onChange={this.onChangeIRInChannel}>{
                            ir_in_opt.map((opt, idx) => <Option value={opt} key={`irin_opt_${opt}`}>{(ir_in_desc) ? ir_in_desc[idx] : opt}</Option>)
                        }</Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_IO_IR_CUST_CODE')} error={ir_code_err} style={notHiddenIf(this.instance.IsSupportIRCustomCode())}>
                            <Input value={ir_cus_code} placeholder='IR Code' onChange={this.onChangeIRCustomCode} />
                            <Button type='primary' disabled={ir_code_err.length > 0} onClick={this.instance.UploadInIRCustomCode}>{getText('SAVE')}</Button>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_IO_USB_LABEL')} style={notHiddenIf(this.instance.IsSupportUSBPowerSupply() || this.instance.IsSupportUSBVirtualHub() || this.instance.IsSupportUSBHostRoute())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_IO_USB_PORT')}><Select placeholder='Select USB Port' value={usb_s} onChange={this.onChangeUsbSelect}>{
                            usb_opt.map((opt, idx) => <Option value={opt} key={`usb_opt_${opt}`}>{(usb_desc) ? usb_desc[idx] : opt}</Option>)
                        }</Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_IO_USB_CTRL_MODE')} disabled={usb_s.length === 0} style={notHiddenIf(this.instance.IsSupportUSBControlMode())}><Select placeholder='Select Mode' value={usb_cm_s} onChange={this.onChangeUsbControlMode}>{
                            usb_cm_opt.map((opt, idx) => <Option value={opt} key={`usb_cm_opt_${opt}`}>{(usb_cm_desc) ? usb_cm_desc[idx] : opt}</Option>)
                        }</Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_IO_USB_PS')} disabled={usb_s.length === 0} style={notHiddenIf(this.instance.IsSupportUSBPowerSupply())}><SwitchButton checked={((usb_ps_opt.length >= 2) ? usb_ps_s === usb_ps_opt[0] : false)} label={(usb_ps_desc?[ usb_ps_desc[0], usb_ps_desc[1] ]:undefined)} onChange={this.onChangeUsbPowerSupply} /></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_IO_USB_HOST_ROUTE')} disabled={usb_s.length === 0} style={notHiddenIf(this.instance.IsSupportUSBHostRoute())}>
                            <Input value={(typeof usb_hr_s === 'string') ? usb_hr_s : this.instance.USBHostRoute(usb_s)} placeholder='Type Device Name' onChange={this.onChangeUsbHostRoute} />
                            <Button type='primary' disabled={(!usb_hr_s || usb_hr_s_err.length > 0 || !usb_hr_s_changed)} onClick={this.onSaveUsbHostRoute}>{getText('SAVE')}</Button>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_IO_UART_LABEL')} style={notHiddenIf(this.instance.IsSupportUARTConfiguration())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_IO_UART_PORT')}><Select placeholder='Select Port' value={uart_s} onChange={this.onChangeUartSelect}>{
                            uart_opt.map((opt, idx) => <Option value={opt} key={`uart_opt_${opt}`}>{(uart_desc) ? uart_desc[idx] : opt}</Option>)
                        }</Select><Button type='danger' style={notHiddenIf(this.instance.IsSupportUARTReset() && uart_s.length > 0)} onClick={this.onClickUARTReset}>Reset Default</Button></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_IO_UART_BAUDRATE')} style={notHiddenIf(uart_s.length > 0)}><Select placeholder='Select Baudrate' value={uart_br_s} onChange={this.onChangeUartBaudrate}>{
                            uart_br_opt.map((opt) => <Option value={opt} key={`br_opt_${opt}`}>{opt}</Option>)
                        }</Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_IO_UART_STOPBIT')} style={notHiddenIf(uart_s.length > 0)}><Select placeholder='Select Stopbit' value={uart_sb_s} onChange={this.onChangeUartStopbit}>{
                            uart_sb_opt.map((opt) => <Option value={opt} key={`sb_opt_${opt}`}>{opt}</Option>)
                        }</Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_IO_UART_DATABIT')} style={notHiddenIf(uart_s.length > 0)}><Select placeholder='Select Databit' value={uart_db_s} onChange={this.onChangeUartDatabit}>{
                            uart_db_opt.map((opt) => <Option value={opt} key={`db_opt_${opt}`}>{opt}</Option>)
                        }</Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_IO_UART_PARITY')} style={notHiddenIf(uart_s.length > 0)}><Select placeholder='Select Baudrate' value={uart_pt_s} onChange={this.onChangeUartParity}>{
                            uart_pt_opt.map((opt, idx) => <Option value={opt} key={`pt_opt_${opt}`}>{(uart_pt_desc) ? uart_pt_desc[idx] : opt}</Option>)
                        }</Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_IO_UART_MODE')} style={notHiddenIf(this.instance.IsSupportUARTMode() && uart_s.length > 0)}><Select placeholder='Select Mode' value={uart_mode_s} onChange={this.onChangeUartMode}>{
                            uart_mode_opt.map((opt, idx) => <Option value={opt} key={`mode_opt_${opt}`}>{(uart_mode_desc) ? uart_mode_desc[idx] : opt}</Option>)
                        }</Select></Form.Item>
                    </Form.Form>
                    {/* <div className='field advance' style={notHiddenIf(this.instance.IsSupportUARTSetCommand())}>
                            <div className='title'>Advance</div>
                        </div> */}
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

class VideoWallView extends CsxUI.IRQComponent<any> {
    state: { prst_s: string; cur_vw_layout_h: string; cur_vw_layout_v: string; warpin_s: string; warpout_s: string }
    instance: CsxFeature.CsxVideoWallDevice
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'DeviceRealTime';
        this.instance = new CsxFeature.CsxVideoWallDevice(window.FOCUS_DEVICE);
        const vw_layout = this.instance.VideoWallLayout().split(' ');
        this.state = { prst_s: '', warpin_s: '', warpout_s: '', cur_vw_layout_h: vw_layout[0], cur_vw_layout_v: CsxUtil.defaultValue(vw_layout[1], null, '') };
    }
    onChangeWarpKeepAR = (e: React.ChangeEvent<HTMLInputElement>) => {
        const warpKeepARParam = this.instance.WarpingKeepARParam();
        const warp_keepar_opt = (warpKeepARParam.b1.isOption) ? warpKeepARParam.b1.isOption.option : [];
        if (warp_keepar_opt.length >= 2) {
            this.instance.SetWarpingKeepAR((e.target.checked) ? warp_keepar_opt[0] : warp_keepar_opt[1]);
        }
    }
    onChangeWarpMode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const warpModeParam = this.instance.WarpingModeParam();
        const warp_mode_opt = (warpModeParam.b1.isOption) ? warpModeParam.b1.isOption.option : [];
        if (warp_mode_opt.length >= 2) {
            this.instance.SetWarpingMode((e.target.checked) ? warp_mode_opt[0] : warp_mode_opt[1]);
        }
    }
    onChangeBezelMode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const vwBezelModeParam = this.instance.VideoWallBezelModeParam();
        const vw_bzmode_opt = (vwBezelModeParam.b1.isOption) ? vwBezelModeParam.b1.isOption.option : [];
        if (vw_bzmode_opt.length >= 2) {
            this.instance.SetVideoWallBezelMode((e.target.checked) ? vw_bzmode_opt[0] : vw_bzmode_opt[1]);
        }
    }
    onChangeContinueRotateMode = (value: string) => {
        const rotate_angle = this.instance.RotateAngle();
        this.instance.SetContinueRotateMode(value, rotate_angle);
    }
    onChangeVWPreset = (value: string) => { this.instance.SetVideoWallPreset(value); this.setState({ prst_s: value }); }
    onChangeHVWLayout = (value: string) => { this.setState({ cur_vw_layout_h: value }); }
    onChangeVVWLayout = (value: string) => { this.setState({ cur_vw_layout_v: value }); }
    onChangeRotateAngle = (value: number) => { this.instance.SetRotateAngle(value); }
    onChangeHVWBezel = (value: number) => { this.instance.SetVideoWallHBezel(value); }
    onChangeVVWBezel = (value: number) => { this.instance.SetVideoWallVBezel(value); }
    onChangeHVWStart = (value: number) => { this.instance.SetVideoWallOutHStart(value); }
    onChangeVVWStart = (value: number) => { this.instance.SetVideoWallOutVStart(value); }
    onChangeHVWSize = (value: number) => { this.instance.SetVideoWallOutHSize(value); }
    onChangeVVWSize = (value: number) => { this.instance.SetVideoWallOutVSize(value); }
    onChangeHInStart = (value: number) => { this.instance.SetVideoWallInHStart(value); }
    onChangeVInStart = (value: number) => { this.instance.SetVideoWallInVStart(value); }
    onChangeHInSize = (value: number) => { this.instance.SetVideoWallInHSize(value); }
    onChangeVInSize = (value: number) => { this.instance.SetVideoWallInVSize(value); }
    onChangeTopBezel = (value: number) => { this.instance.SetVideoWallTopBezel(value); }
    onChangeBottomBezel = (value: number) => { this.instance.SetVideoWallBottomBezel(value); }
    onChangeLeftBezel = (value: number) => { this.instance.SetVideoWallLeftBezel(value); }
    onChangeRightBezel = (value: number) => { this.instance.SetVideoWallRightBezel(value); }
    onChangeWarpAngle = (value: number) => { this.instance.SetWarpingAngle(value); }
    onChangeWarpInHeight = (value: number) => { this.instance.SetWarpInHeight(this.state.warpin_s, value); }
    onChangeWarpInWidth = (value: number) => { this.instance.SetWarpInWidth(this.state.warpin_s, value); }
    onChangeWarpInHStart = (value: number) => { this.instance.SetWarpInHStart(this.state.warpin_s, value); }
    onChangeWarpInVStart = (value: number) => { this.instance.SetWarpInVStart(this.state.warpin_s, value); }
    onApplyVWLayout = () => { this.instance.SetVideoWallLayout(`${this.state.cur_vw_layout_h} ${this.state.cur_vw_layout_v}`); }
    onResetVWConfiguration = () => { window.userConfirm(getText('CONFIRM_RESET_DEV_VW_SETUP'), (ok) => { if (ok) { this.instance.SetVideoWallDefault(); } }); }
    onChangeWarpInSelect = (value: string) => { this.setState({ warpin_s: value }); }
    render() {
        const { prst_s, cur_vw_layout_h, cur_vw_layout_v, warpin_s } = this.state;
        const prstParam = this.instance.VideoWallPresetParam();
        const vwLayoutParam = this.instance.VideoWallLayoutParam();
        const rotateParam = this.instance.RotateAngleParam();
        const vwHBezelParam = this.instance.VideoWallHBezelParam();
        const vwVBezelParam = this.instance.VideoWallVBezelParam();
        const vwHStartParam = this.instance.VideoWallOutHStartParam();
        const vwVStartParam = this.instance.VideoWallOutVStartParam();
        const vwHSizeParam = this.instance.VideoWallOutHSizeParam();
        const vwVSizeParam = this.instance.VideoWallOutVSizeParam();
        const vwBezelModeParam = this.instance.VideoWallBezelModeParam();
        const inHStartParam = this.instance.VideoWallInHStartParam();
        const inVStartParam = this.instance.VideoWallInVStartParam();
        const inHSizeParam = this.instance.VideoWallInHSizeParam();
        const inVSizeParam = this.instance.VideoWallInVSizeParam();
        const topBezelParam = this.instance.VideoWallTopBezelParam();
        const bottomBezelParam = this.instance.VideoWallBottomBezelParam();
        const leftBezelParam = this.instance.VideoWallBottomBezelParam();
        const rightBezelParam = this.instance.VideoWallBottomBezelParam();
        const warpAngleParam = this.instance.WarpingAngleParam();
        const warpInTopLeftParam = this.instance.WarpInTopLeftParam();
        // const warpInTopRightParam = this.instance.WarpInTopRightParam();
        // const warpInBottomLeftParam = this.instance.WarpInBottomLeftParam();
        // const warpInBottomRightParam = this.instance.WarpInBottomRightParam();
        const warpInHStartParam = this.instance.WarpInHStartParam();
        const warpInVStartParam = this.instance.WarpInVStartParam();
        const warpInHeigthParam = this.instance.WarpInHeightParam();
        const warpInWidthParam = this.instance.WarpInWidthParam();
        const warpModeParam = this.instance.WarpingModeParam();
        const warpKeepARParam = this.instance.WarpingKeepARParam();
        const continueRotateParam = this.instance.ContinueRotateModeParam();
        const prst_opt = (prstParam.n1.isOption) ? prstParam.n1.isOption.option : [];
        const prst_desc = (prstParam.n1.isOption) ? prstParam.n1.isOption.desc : [];
        const warpin_opt = (warpInTopLeftParam.n1.isOption) ? warpInTopLeftParam.n1.isOption.option : [];
        const warpin_desc = (warpInTopLeftParam.n1.isOption) ? warpInTopLeftParam.n1.isOption.desc : [];
        const vw_bzmode_opt = (vwBezelModeParam.b1.isOption) ? vwBezelModeParam.b1.isOption.option : [];
        const vw_bzmode_desc = (vwBezelModeParam.b1.isOption) ? vwBezelModeParam.b1.isOption.desc : [];
        const vw_layout_h_range = (vwLayoutParam.n1.isRange) ? vwLayoutParam.n1.isRange.range : { max: 0, min: 0, step: 1 };
        const vw_layout_v_range = (vwLayoutParam.n2.isRange) ? vwLayoutParam.n2.isRange.range : { max: 0, min: 0, step: 1 };
        // const rotate_opt = (rotateParam.n1.isOption) ? rotateParam.n1.isOption.option : [];
        // const rotate_desc = (rotateParam.n1.isOption) ? rotateParam.n1.isOption.desc : [];
        const warp_mode_opt = (warpModeParam.b1.isOption) ? warpModeParam.b1.isOption.option : [];
        const warp_mode_desc = (warpModeParam.b1.isOption) ? warpModeParam.b1.isOption.desc : [];
        const warp_keepar_opt = (warpKeepARParam.b1.isOption) ? warpKeepARParam.b1.isOption.option : [];
        const warp_keepar_desc = (warpKeepARParam.b1.isOption) ? warpKeepARParam.b1.isOption.desc : [];
        const cont_rotate_opt = (continueRotateParam.n1.isOption) ? continueRotateParam.n1.isOption.option : [];
        const cont_rotate_desc = (continueRotateParam.n1.isOption) ? continueRotateParam.n1.isOption.desc : [];
        const cur_h_Bezel = this.instance.VideoWallHBezel();
        const cur_v_Bezel = this.instance.VideoWallVBezel();
        const cur_Bezel_mode = this.instance.VideoWallBezelMode();
        const cur_h_start = this.instance.VideoWallOutHStart();
        const cur_v_start = this.instance.VideoWallOutVStart();
        const cur_h_size = this.instance.VideoWallOutHSize();
        const cur_v_size = this.instance.VideoWallOutVSize();
        const in_h_start = this.instance.VideoWallInHStart();
        const in_v_start = this.instance.VideoWallInVStart();
        const in_h_size = this.instance.VideoWallInHSize();
        const in_v_size = this.instance.VideoWallInVSize();
        const top_bezel = this.instance.VideoWallTopBezel();
        const bottom_bezel = this.instance.VideoWallBottomBezel();
        const left_bezel = this.instance.VideoWallLeftBezel();
        const right_bezel = this.instance.VideoWallRightBezel();
        const warp_mode = this.instance.WarpingMode();
        const warp_keep_ar = this.instance.WarpingKeepAR();
        const warp_angle = this.instance.WarpingAngle();
        const warpin_height = this.instance.WarpInHeight(warpin_s);
        const warpin_width = this.instance.WarpInWidth(warpin_s);
        const warpin_h_start = this.instance.WarpInHStart(warpin_s);
        const warpin_v_start = this.instance.WarpInVStart(warpin_s);
        const rotate_angle = this.instance.RotateAngle();
        const cont_rotate = this.instance.ContinueRotateMode();
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_VW_LAYOUT_LABEL')} style={notHiddenIf(this.instance.IsSupportVWConfiguration())} defaultExtend={true}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_VW_LAYOUT')} className='size'>
                            <Select placeholder='Hor.' value={cur_vw_layout_h} onChange={this.onChangeHVWLayout}>{
                                Array.from(Array((vw_layout_h_range.max - vw_layout_h_range.min) / vw_layout_h_range.step)).map((value, idx) => {
                                    const trans_v = vw_layout_h_range.min + (vw_layout_h_range.step * idx);
                                    return <Option value={trans_v} key={`vw_h_opt_${idx}`}>{trans_v}</Option>;
                                })
                            }</Select>
                            x
                            <Select placeholder='Ver.' value={cur_vw_layout_v} onChange={this.onChangeVVWLayout}>{
                                Array.from(Array((vw_layout_v_range.max - vw_layout_v_range.min) / vw_layout_v_range.step)).map((value, idx) => {
                                    const trans_v = vw_layout_v_range.min + (vw_layout_v_range.step * idx);
                                    return <Option value={trans_v} key={`vw_h_opt_${idx}`}>{trans_v}</Option>;
                                })
                            }</Select>
                            <Button type='primary' disabled={(cur_vw_layout_h.length === 0 || cur_vw_layout_v.length === 0)} onClick={this.onApplyVWLayout}>{getText('APPLY')}</Button>
                            <Button type='danger' style={notHiddenIf(this.instance.IsSupportVWLayoutDefault())} onClick={this.onResetVWConfiguration}>Reset</Button>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_VW_LAYOUT_BEZEL_MODE')} style={notHiddenIf(this.instance.IsSupportVWBezelMode())}><SwitchButton checked={((vw_bzmode_opt.length >= 2) ? cur_Bezel_mode === vw_bzmode_opt[0] : false)} label={(vw_bzmode_desc?[ vw_bzmode_desc[0], vw_bzmode_desc[1] ]:undefined)} onChange={this.onChangeBezelMode} /></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_VW_LAYOUT_BEZEL_H')} style={notHiddenIf(!!vwHBezelParam.n1.isRange)}>
                            <Slider
                                value={parseInt(cur_h_Bezel)}
                                max={(vwHBezelParam.n1.isRange) ? vwHBezelParam.n1.isRange.range.max : 100}
                                min={(vwHBezelParam.n1.isRange) ? vwHBezelParam.n1.isRange.range.min : 0}
                                step={(vwHBezelParam.n1.isRange) ? vwHBezelParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeHVWBezel}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(cur_h_Bezel)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_VW_LAYOUT_BEZEL_V')} style={notHiddenIf(!!vwVBezelParam.n1.isRange)}>
                            <Slider
                                value={parseInt(cur_v_Bezel)}
                                max={(vwVBezelParam.n1.isRange) ? vwVBezelParam.n1.isRange.range.max : 100}
                                min={(vwVBezelParam.n1.isRange) ? vwVBezelParam.n1.isRange.range.min : 0}
                                step={(vwVBezelParam.n1.isRange) ? vwVBezelParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeVVWBezel}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(cur_v_Bezel)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_VW_LAYOUT_START_PXL_H')} style={notHiddenIf(!!vwHStartParam.n1.isRange)}>
                            <Slider
                                value={parseInt(cur_h_start)}
                                max={(vwHStartParam.n1.isRange) ? vwHStartParam.n1.isRange.range.max : 100}
                                min={(vwHStartParam.n1.isRange) ? vwHStartParam.n1.isRange.range.min : 0}
                                step={(vwHStartParam.n1.isRange) ? vwHStartParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeHVWStart}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(cur_h_start)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_VW_LAYOUT_START_PXL_V')} style={notHiddenIf(!!vwVStartParam.n1.isRange)}>
                            <Slider
                                value={parseInt(cur_v_start)}
                                max={(vwVStartParam.n1.isRange) ? vwVStartParam.n1.isRange.range.max : 100}
                                min={(vwVStartParam.n1.isRange) ? vwVStartParam.n1.isRange.range.min : 0}
                                step={(vwVStartParam.n1.isRange) ? vwVStartParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeVVWStart}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(cur_v_start)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_VW_LAYOUT_SIZE_H')} style={notHiddenIf(!!vwHSizeParam.n1.isRange)}>
                            <Slider
                                value={parseInt(cur_h_size)}
                                max={(vwHSizeParam.n1.isRange) ? vwHSizeParam.n1.isRange.range.max : 100}
                                min={(vwHSizeParam.n1.isRange) ? vwHSizeParam.n1.isRange.range.min : 0}
                                step={(vwHSizeParam.n1.isRange) ? vwHSizeParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeHVWSize}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(cur_h_size)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_VW_LAYOUT_SIZE_V')} style={notHiddenIf(!!vwVSizeParam.n1.isRange)}>
                            <Slider
                                value={parseInt(cur_v_size)}
                                max={(vwVSizeParam.n1.isRange) ? vwVSizeParam.n1.isRange.range.max : 100}
                                min={(vwVSizeParam.n1.isRange) ? vwVSizeParam.n1.isRange.range.min : 0}
                                step={(vwVSizeParam.n1.isRange) ? vwVSizeParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeVVWSize}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(cur_v_size)}`}</div>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_VW_PRESET_LABEL')} style={notHiddenIf(this.instance.IsSupportVWPreset())}>
                    <Form.Item label={getText('DEVICE_FUNC_VW_PRESET')} labelStyle={{ width: '150px' }}><Select placeholder='Select Preset' value={prst_s} onChange={this.onChangeVWPreset}>{
                        prst_opt.map((opt, idx) => <Option value={opt} key={`prst_opt_${opt}`}>{(prst_desc) ? prst_desc[idx] : opt}</Option>)
                    }</Select></Form.Item>
                </Flat.Section>
                <Flat.Section title='Source Configuration' style={notHiddenIf(this.instance.IsSupportInHSize() || this.instance.IsSupportInVSize() || this.instance.IsSupportInHStart() || this.instance.IsSupportInVStart())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_VW_SRC_START_PXL_H')} style={notHiddenIf(!!inHStartParam.n1.isRange)}>
                            <Slider
                                value={parseInt(in_h_start)}
                                max={(inHStartParam.n1.isRange) ? inHStartParam.n1.isRange.range.max : 100}
                                min={(inHStartParam.n1.isRange) ? inHStartParam.n1.isRange.range.min : 0}
                                step={(inHStartParam.n1.isRange) ? inHStartParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeHInStart}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(in_h_start)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_VW_SRC_START_PXL_V')} style={notHiddenIf(!!inVStartParam.n1.isRange)}>
                            <Slider
                                value={parseInt(in_v_start)}
                                max={(inVStartParam.n1.isRange) ? inVStartParam.n1.isRange.range.max : 100}
                                min={(inVStartParam.n1.isRange) ? inVStartParam.n1.isRange.range.min : 0}
                                step={(inVStartParam.n1.isRange) ? inVStartParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeVInStart}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(in_v_start)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_VW_SRC_SIZE_H')} style={notHiddenIf(!!inHSizeParam.n1.isRange)}>
                            <Slider
                                value={parseInt(in_h_size)}
                                max={(inHSizeParam.n1.isRange) ? inHSizeParam.n1.isRange.range.max : 100}
                                min={(inHSizeParam.n1.isRange) ? inHSizeParam.n1.isRange.range.min : 0}
                                step={(inHSizeParam.n1.isRange) ? inHSizeParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeHInSize}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(in_h_size)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_VW_SRC_SIZE_V')} style={notHiddenIf(!!inVSizeParam.n1.isRange)}>
                            <Slider
                                value={parseInt(in_v_size)}
                                max={(inVSizeParam.n1.isRange) ? inVSizeParam.n1.isRange.range.max : 100}
                                min={(inVSizeParam.n1.isRange) ? inVSizeParam.n1.isRange.range.min : 0}
                                step={(inVSizeParam.n1.isRange) ? inVSizeParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeVInSize}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(in_v_size)}`}</div>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_VW_DETAIL_BEZEL_LABEL')} style={notHiddenIf(this.instance.IsSupportLeftBezel() || this.instance.IsSupportTopBezel() || this.instance.IsSupportRightBezel() || this.instance.IsSupportBottomBezel())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_VW_DETAIL_BEZEL_TOP')} style={notHiddenIf(!!topBezelParam.n1.isRange)}>
                            <Slider
                                value={parseInt(top_bezel)}
                                max={(topBezelParam.n1.isRange) ? topBezelParam.n1.isRange.range.max : 100}
                                min={(topBezelParam.n1.isRange) ? topBezelParam.n1.isRange.range.min : 0}
                                step={(topBezelParam.n1.isRange) ? topBezelParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeTopBezel}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(top_bezel)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_VW_DETAIL_BEZEL_BOTTOM')} style={notHiddenIf(!!bottomBezelParam.n1.isRange)}>
                            <Slider
                                value={parseInt(bottom_bezel)}
                                max={(bottomBezelParam.n1.isRange) ? bottomBezelParam.n1.isRange.range.max : 100}
                                min={(bottomBezelParam.n1.isRange) ? bottomBezelParam.n1.isRange.range.min : 0}
                                step={(bottomBezelParam.n1.isRange) ? bottomBezelParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeBottomBezel}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(bottom_bezel)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_VW_DETAIL_BEZEL_LEFT')} style={notHiddenIf(!!leftBezelParam.n1.isRange)}>
                            <Slider
                                value={parseInt(left_bezel)}
                                max={(leftBezelParam.n1.isRange) ? leftBezelParam.n1.isRange.range.max : 100}
                                min={(leftBezelParam.n1.isRange) ? leftBezelParam.n1.isRange.range.min : 0}
                                step={(leftBezelParam.n1.isRange) ? leftBezelParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeLeftBezel}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(left_bezel)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_VW_DETAIL_BEZEL_RIGHT')} style={notHiddenIf(!!rightBezelParam.n1.isRange)}>
                            <Slider
                                value={parseInt(right_bezel)}
                                max={(rightBezelParam.n1.isRange) ? rightBezelParam.n1.isRange.range.max : 100}
                                min={(rightBezelParam.n1.isRange) ? rightBezelParam.n1.isRange.range.min : 0}
                                step={(rightBezelParam.n1.isRange) ? rightBezelParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeRightBezel}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(right_bezel)}`}</div>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_VW_WARP_LABEL')} style={notHiddenIf(this.instance.IsSupportWarpMode() || this.instance.IsSupportWarpKeepAR() || this.instance.IsSupportWarpAngle())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_VW_WARP_MODE')} style={notHiddenIf(this.instance.IsSupportWarpMode())}><SwitchButton checked={((warp_mode_opt.length >= 2) ? warp_mode === warp_mode_opt[0] : false)} label={(warp_mode_desc?[ warp_mode_desc[0], warp_mode_desc[1] ]:undefined)} onChange={this.onChangeWarpMode} /></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_VW_WARP_KEEP_AR')} style={notHiddenIf(this.instance.IsSupportWarpKeepAR())}><SwitchButton checked={((warp_keepar_opt.length >= 2) ? warp_keep_ar === warp_keepar_opt[0] : false)} label={(warp_keepar_desc?[ warp_keepar_desc[0], warp_keepar_desc[1] ]:undefined)} onChange={this.onChangeWarpKeepAR} /></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_VW_WARP_ANGLE')} style={notHiddenIf(!!warpAngleParam.n1.isRange)}>
                            <Slider
                                value={parseInt(warp_angle)}
                                max={(warpAngleParam.n1.isRange) ? warpAngleParam.n1.isRange.range.max : 360}
                                min={(warpAngleParam.n1.isRange) ? warpAngleParam.n1.isRange.range.min : 0}
                                step={(warpAngleParam.n1.isRange) ? warpAngleParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeWarpAngle}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(warp_angle)}`}</div>
                        </Form.Item>
                    </Form.Form>
                    <Flat.Field title={getText('DEVICE_FUNC_VW_WARP_IN_LABEL')} style={notHiddenIf(this.instance.IsSupportSourceWarp())}>
                        <Form.Form labelStyle={{ width: '150px' }}>
                            <Form.Item label={getText('DEVICE_FUNC_VW_WARP_IN_PORT')}><Select placeholder='Select Source' value={warpin_s} onChange={this.onChangeWarpInSelect}>{
                                warpin_opt.map((opt, idx) => <Option value={opt} key={`warpin_opt_${opt}`}>{(warpin_desc) ? warpin_desc[idx] : opt}</Option>)
                            }</Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_VW_WARP_IN_HEIGHT')} disabled={warpin_s.length === 0} style={notHiddenIf(!!warpInHeigthParam.n2.isRange)}>
                                <Slider
                                    value={parseInt(warpin_height)}
                                    max={(warpInHeigthParam.n2.isRange) ? warpInHeigthParam.n2.isRange.range.max : 100}
                                    min={(warpInHeigthParam.n2.isRange) ? warpInHeigthParam.n2.isRange.range.min : 0}
                                    step={(warpInHeigthParam.n2.isRange) ? warpInHeigthParam.n2.isRange.range.step : 1}
                                    onAfterChange={this.onChangeWarpInHeight}
                                />
                                <div style={slider_show_value_style}>{`${parseInt(warpin_height)}}`}</div>
                            </Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_VW_WARP_IN_WIDTH')} disabled={warpin_s.length === 0} style={notHiddenIf(!!warpInWidthParam.n2.isRange)}>
                                <Slider
                                    value={parseInt(warpin_width)}
                                    max={(warpInWidthParam.n2.isRange) ? warpInWidthParam.n2.isRange.range.max : 100}
                                    min={(warpInWidthParam.n2.isRange) ? warpInWidthParam.n2.isRange.range.min : 0}
                                    step={(warpInWidthParam.n2.isRange) ? warpInWidthParam.n2.isRange.range.step : 1}
                                    onAfterChange={this.onChangeWarpInWidth}
                                />
                                <div style={slider_show_value_style}>{`${parseInt(warpin_width)}`}</div>
                            </Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_VW_WARP_IN_START_PXL_H')} disabled={warpin_s.length === 0} style={notHiddenIf(!!warpInHStartParam.n2.isRange)}>
                                <Slider
                                    value={parseInt(warpin_h_start)}
                                    max={(warpInHStartParam.n2.isRange) ? warpInHStartParam.n2.isRange.range.max : 100}
                                    min={(warpInHStartParam.n2.isRange) ? warpInHStartParam.n2.isRange.range.min : 0}
                                    step={(warpInHStartParam.n2.isRange) ? warpInHStartParam.n2.isRange.range.step : 1}
                                    onAfterChange={this.onChangeHInStart}
                                />
                                <div style={slider_show_value_style}>{`${parseInt(warpin_h_start)}`}</div>
                            </Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_VW_WARP_IN_START_PXL_V')} disabled={warpin_s.length === 0} style={notHiddenIf(!!warpInVStartParam.n2.isRange)}>
                                <Slider
                                    value={parseInt(warpin_v_start)}
                                    max={(warpInVStartParam.n2.isRange) ? warpInVStartParam.n2.isRange.range.max : 100}
                                    min={(warpInVStartParam.n2.isRange) ? warpInVStartParam.n2.isRange.range.min : 0}
                                    step={(warpInVStartParam.n2.isRange) ? warpInVStartParam.n2.isRange.range.step : 1}
                                    onAfterChange={this.onChangeVInStart}
                                />
                                <div style={slider_show_value_style}>{`${parseInt(warpin_v_start)}`}</div>
                            </Form.Item>
                        </Form.Form>
                    </Flat.Field>
                    <Flat.Field title={getText('DEVICE_FUNC_VW_WARP_OUT_LABEL')} style={notHiddenIf(this.instance.IsSupportOutputWarp())}>
                    </Flat.Field>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_VW_ROTATE_LABEL')} style={notHiddenIf(this.instance.IsSupportRotate() || this.instance.IsSupportContinuedRotate())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_VW_ROTATE_MODE')} style={notHiddenIf(this.instance.IsSupportContinuedRotate())}>
                            <Select value={cont_rotate} placeholder='Select Rotate Mode' onChange={this.onChangeContinueRotateMode}>{cont_rotate_opt.map((opt, idx) => <Option value={opt} key={`cont_rotate_${opt}`}>{(cont_rotate_desc) ? cont_rotate_desc[idx] : opt}</Option>)}</Select>
                        </Form.Item>
                        {/* <Form.Item label='Angle (°)' style={notHiddenIf(!!warpAngleParam.n1.isRange)}>
                            <Select value={rotate_angle} placeholder='Select Angle' onChange={this.onChangeRotateAngle}>{rotate_opt.map((opt, idx) => <Option value={opt} key={`rot_ang_${opt}`}>{(rotate_desc) ? rotate_desc[idx] : opt}</Option>)}</Select>
                        </Form.Item> */}
                        <Form.Item label={getText('DEVICE_FUNC_VW_ROTATE_ANGLE')} style={notHiddenIf(!!rotateParam.n1.isRange)}>
                            <Slider
                                value={parseInt(rotate_angle)}
                                max={(rotateParam.n1.isRange) ? rotateParam.n1.isRange.range.max : 100}
                                min={(rotateParam.n1.isRange) ? rotateParam.n1.isRange.range.min : 0}
                                step={(rotateParam.n1.isRange) ? rotateParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeRotateAngle}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(rotate_angle)}`}</div>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

class OSDView extends CsxUI.IRQComponent<any> {
    state: { out_s: string; tmp_osd_text?: string; text_err: string }
    instance: CsxFeature.CsxOSDDevice
    constructor(props: any) {
        super(props);
        this.state = { out_s: 'a', text_err: '' }
        this.IRQIndex = 'DeviceRealTime';
        this.instance = new CsxFeature.CsxOSDDevice(window.FOCUS_DEVICE);
    }
    validateOSDText = (value: string) => {
        const osdTextParam = this.instance.OutBannerTextParam();
        const ok = !(osdTextParam.s1.isString && !this.validateString(value, osdTextParam.s1.isString));
        this.setState({ text_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    onChangeOsdEnable = (e: React.ChangeEvent<HTMLInputElement>) => {
        const osdEnableParam = this.instance.OutOSDParam();
        const osd_enable_opt = (osdEnableParam.b1.isOption) ? osdEnableParam.b1.isOption.option : [];
        if (osd_enable_opt.length >= 2) {
            this.instance.SetOutOSD(this.state.out_s, (e.target.checked) ? osd_enable_opt[0] : osd_enable_opt[1]);
        }
    }
    onChangeOsdInfoDisplay = (e: React.ChangeEvent<HTMLInputElement>) => {
        const outOsdInfoDisplayParam = this.instance.OutOSDInfoDisplayParam();
        const osd_info_show_opt = (outOsdInfoDisplayParam.b1.isOption) ? outOsdInfoDisplayParam.b1.isOption.option : [];
        if (osd_info_show_opt.length >= 2) {
            this.instance.SetOutOSDInfoDisplay(this.state.out_s, (e.target.checked) ? osd_info_show_opt[0] : osd_info_show_opt[1]);
        }
    }
    onChangeOsdLogoDisplay = (e: React.ChangeEvent<HTMLInputElement>) => {
        const outOSDLogoDisplayParam = this.instance.OutOSDLogoDisplayParam();
        const osd_logo_show_opt = (outOSDLogoDisplayParam.b1.isOption) ? outOSDLogoDisplayParam.b1.isOption.option : [];
        if (osd_logo_show_opt.length >= 2) {
            this.instance.SetOutOSDLogoDisplay(this.state.out_s, (e.target.checked) ? osd_logo_show_opt[0] : osd_logo_show_opt[1]);
        }
    }
    onChangeBannerText = (e: React.ChangeEvent<HTMLInputElement>) => { if (this.validateOSDText(e.target.value) || e.target.value.length === 0) this.setState({ tmp_osd_text: e.target.value }); }
    onChangeOutSelect = (value: string) => { this.setState({ out_s: value }); }
    onChangeBannerLocation = (value: string) => { this.instance.SetOutBannerLocation(this.state.out_s, value); }
    onChangeBannerSrc = (value: string) => { this.instance.SetOutBannerSrc(this.state.out_s, value); }
    onChangeBannerFontColor = (value: string) => { this.instance.SetOutBannerFontColor(this.state.out_s, value); }
    onChangeBannerTransparencyLevel = (value: string) => { this.instance.SetOutBannerFontTransparencyLevel(this.state.out_s, value); }
    onChangeOSDBackgroundColor = (value: string) => { this.instance.SetOutOSDBackgroundColor(this.state.out_s, value); }
    onChangeOutOSDTimeout = (value: number) => { this.instance.SetOutOSDTimeout(this.state.out_s, value); }
    onChangeOutOSDInfoTimeout = (value: number) => { this.instance.SetOutOSDInfoTimeout(this.state.out_s, value); }
    onChangeOutOSDHPosition = (value: number) => { this.instance.SetOutOSDHPosition(this.state.out_s, value); }
    onChangeOutOSDVPosition = (value: number) => { this.instance.SetOutOSDVPosition(this.state.out_s, value); }
    onChangeOutOSDTransparency = (value: number) => { this.instance.SetOutOSDTransparency(this.state.out_s, value); }
    onChangeOutBannerFontSize = (value: number) => { this.instance.SetOutBannerFontSize(this.state.out_s, value); }
    onChangeOutLogoHPosition = (value: number) => { this.instance.SetOutOSDLogoHPosition(this.state.out_s, value); }
    onChangeOutLogoVPosition = (value: number) => { this.instance.SetOutOSDLogoVPosition(this.state.out_s, value); }
    onChangeCountdownTimer = (value: number) => { this.instance.SetOutCountdownTimer(this.state.out_s, value); }
    onSaveBannerText = () => {
        const { out_s, tmp_osd_text } = this.state;
        if (typeof tmp_osd_text === 'string') {
            this.instance.SetOutBannerText(out_s, tmp_osd_text);
            this.setState({ tmp_osd_text: undefined, text_err: '' });
        }
    }
    render() {
        const { out_s, text_err, tmp_osd_text } = this.state;
        const osdEnableParam = this.instance.OutOSDParam();
        const osdTimeoutParam = this.instance.OutOSDTimeoutParam();
        const osdInfoTimeoutParam = this.instance.OutOSDInfoTimeoutParam();
        const osdHPositionParam = this.instance.OutOSDHPositionParam();
        const osdVPositionParam = this.instance.OutOSDVPositionParam();
        const osdBgParam = this.instance.OutOSDBackgroundColorParam();
        const osdTransparencyParam = this.instance.OutOSDTransparencyParam();
        const bannerFontSizeParam = this.instance.OutBannerFontSizeParam();
        const logoHPositionParam = this.instance.OutOSDLogoHPositionParam();
        const logoVPositionParam = this.instance.OutOSDLogoVPositionParam();
        const countdownTimerParam = this.instance.OutCountdownTimerParam();
        const bannerTansparencyLevelParam = this.instance.OutBannerFontTransparencyLevelParam();
        const bannerFontColorParam = this.instance.OutBannerFontColorParam();
        const osdSourceParam = this.instance.OutBannerSrcParam();
        const bannerLocationParam = this.instance.OutBannerLocationParam();
        const outOsdInfoDisplayParam = this.instance.OutOSDInfoDisplayParam();
        const outOSDLogoDisplayParam = this.instance.OutOSDLogoDisplayParam();
        const osd_enable_opt = (osdEnableParam.b1.isOption) ? osdEnableParam.b1.isOption.option : [];
        const osd_enable_desc = (osdEnableParam.b1.isOption) ? osdEnableParam.b1.isOption.desc : [];
        const osd_bg_opt = (osdBgParam.s1.isOption) ? osdBgParam.s1.isOption.option : [];
        const osd_bg_desc = (osdBgParam.s1.isOption) ? osdBgParam.s1.isOption.desc : [];
        const osd_info_show_opt = (outOsdInfoDisplayParam.b1.isOption) ? outOsdInfoDisplayParam.b1.isOption.option : [];
        const osd_info_show_desc = (outOsdInfoDisplayParam.b1.isOption) ? outOsdInfoDisplayParam.b1.isOption.desc : [];
        const osd_logo_show_opt = (outOSDLogoDisplayParam.b1.isOption) ? outOSDLogoDisplayParam.b1.isOption.option : [];
        const osd_logo_show_desc = (outOSDLogoDisplayParam.b1.isOption) ? outOSDLogoDisplayParam.b1.isOption.desc : [];
        const osd_out_opt = (osdTimeoutParam.x1.isOption) ? osdTimeoutParam.x1.isOption.option : [];
        const osd_out_desc = (osdTimeoutParam.x1.isOption) ? osdTimeoutParam.x1.isOption.desc : [];
        const banner_loc_opt = (bannerLocationParam.n1.isOption) ? bannerLocationParam.n1.isOption.option : [];
        const banner_loc_desc = (bannerLocationParam.n1.isOption) ? bannerLocationParam.n1.isOption.desc : [];
        const banner_src_opt = (osdSourceParam["nn.n"].isOption) ? osdSourceParam["nn.n"].isOption.option : [];
        const banner_src_desc = (osdSourceParam["nn.n"].isOption) ? osdSourceParam["nn.n"].isOption.desc : [];
        const font_color_opt = (bannerFontColorParam.s1.isOption) ? bannerFontColorParam.s1.isOption.option : [];
        const font_color_desc = (bannerFontColorParam.s1.isOption) ? bannerFontColorParam.s1.isOption.desc : [];
        const banner_trans_level_opt = (bannerTansparencyLevelParam.n1.isOption) ? bannerTansparencyLevelParam.n1.isOption.option : [];
        const banner_trans_level_desc = (bannerTansparencyLevelParam.n1.isOption) ? bannerTansparencyLevelParam.n1.isOption.desc : [];
        const osd_enable = this.instance.OutOSD(out_s);
        const osd_timout = this.instance.OutOSDTimeout(out_s);
        const osd_bg = this.instance.OutOSDBackgroundColor(out_s);
        const osd_info_show = this.instance.OutOSDInfoDisplay(out_s);
        const osd_info_timeout = this.instance.OutOSDInfoTimeout(out_s);
        const osd_h_pos = this.instance.OutOSDHPosition(out_s);
        const osd_v_pos = this.instance.OutOSDVPosition(out_s);
        const osd_text = this.instance.OutBannerText(out_s);
        const osd_trans = this.instance.OutOSDTransparency(out_s);
        const banner_loc = this.instance.OutBannerLocation(out_s);
        const banner_src = this.instance.OutBannerSrc(out_s);
        const font_size = this.instance.OutBannerFontSize(out_s);
        const font_color = this.instance.OutBannerFontColor(out_s);
        const banner_trans_level = this.instance.OutBannerFontTransparencyLevel(out_s);
        const logo_show = this.instance.OutOSDLogoDisplay(out_s);
        const logo_h_pos = this.instance.OutOSDLogoHPosition(out_s);
        const logo_v_pos = this.instance.OutOSDLogoVPosition(out_s);
        const countdown_timer = this.instance.OutCountdownTimer(out_s);
        // validate input
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_OSD_OUT_LABEL')} style={notHiddenIf(this.instance.IsSupportOSDTimeout())} defaultExtend={true}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_PORT')}><Select placeholder='Select Destination' value={out_s} onChange={this.onChangeOutSelect}>
                            {osd_out_opt.map((opt, idx) => <Option value={opt} key={`out_opt_${opt}`}>{(osd_out_desc) ? osd_out_desc[idx] : opt}</Option>)}
                        </Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_ON')} style={notHiddenIf(this.instance.IsSupportOSDEnable() && out_s.length > 0)}><SwitchButton checked={((osd_enable_opt.length >= 2)?(osd_enable === osd_enable_opt[0]):false)} label={(osd_enable_desc?[ osd_enable_desc[0], osd_enable_desc[1] ]:undefined)} onChange={this.onChangeOsdEnable} /></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_TEXT')} style={notHiddenIf(this.instance.IsSupportBannerEdit() && out_s.length > 0)} error={text_err}>
                            <Input placeholder='OSD Text' value={(typeof tmp_osd_text === 'string') ? tmp_osd_text : osd_text} onChange={this.onChangeBannerText} />
                            <Button type='primary' disabled={text_err.length > 0} onClick={this.onSaveBannerText}>{getText('SAVE')}</Button>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_BG_COLOR')} style={notHiddenIf(this.instance.IsSupportOSDBackgroundColor() && out_s.length > 0)}>
                            <Select placeholder='Select Color' value={osd_bg} onChange={this.onChangeOSDBackgroundColor}>
                                {osd_bg_opt.map((opt, idx) => <Option value={opt} key={`osd_bg_${opt}`}>{(osd_bg_desc) ? osd_bg_desc[idx] : opt}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_TIMEOUT')} style={notHiddenIf(!!osdTimeoutParam.n1.isRange && out_s.length > 0)}>
                            <Slider
                                value={parseInt(osd_timout)}
                                max={(osdTimeoutParam.n1.isRange) ? osdTimeoutParam.n1.isRange.range.max : 100}
                                min={(osdTimeoutParam.n1.isRange) ? osdTimeoutParam.n1.isRange.range.min : 0}
                                step={(osdTimeoutParam.n1.isRange) ? osdTimeoutParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeOutOSDTimeout}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(osd_timout)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_TRANSPARENCY')} style={notHiddenIf(!!osdTransparencyParam.n1.isRange && out_s.length > 0)}>
                            <Slider
                                value={parseInt(osd_trans)}
                                max={(osdTransparencyParam.n1.isRange) ? osdTransparencyParam.n1.isRange.range.max : 100}
                                min={(osdTransparencyParam.n1.isRange) ? osdTransparencyParam.n1.isRange.range.min : 0}
                                step={(osdTransparencyParam.n1.isRange) ? osdTransparencyParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeOutOSDTransparency}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(osd_trans)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_POS_H')} style={notHiddenIf(!!osdHPositionParam.n1.isRange && out_s.length > 0)}>
                            <Slider
                                value={parseInt(osd_h_pos)}
                                max={(osdHPositionParam.n1.isRange) ? osdHPositionParam.n1.isRange.range.max : 100}
                                min={(osdHPositionParam.n1.isRange) ? osdHPositionParam.n1.isRange.range.min : 0}
                                step={(osdHPositionParam.n1.isRange) ? osdHPositionParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeOutOSDHPosition}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(osd_h_pos)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_POS_V')} style={notHiddenIf(!!osdVPositionParam.n1.isRange && out_s.length > 0)}>
                            <Slider
                                value={parseInt(osd_v_pos)}
                                max={(osdVPositionParam.n1.isRange) ? osdVPositionParam.n1.isRange.range.max : 100}
                                min={(osdVPositionParam.n1.isRange) ? osdVPositionParam.n1.isRange.range.min : 0}
                                step={(osdVPositionParam.n1.isRange) ? osdVPositionParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeOutOSDVPosition}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(osd_v_pos)}`}</div>
                        </Form.Item>
                    </Form.Form>
                    <Flat.Field title={getText('DEVICE_FUNC_OSD_OUT_DISP_INFO_LABEL')} style={notHiddenIf(out_s.length > 0)}>
                        <Form.Form labelStyle={{ width: '150px' }}>
                            <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_DISP_INFO_ON')} style={notHiddenIf(this.instance.IsSupportOSDInfoDisplay())}><SwitchButton checked={((osd_info_show_opt.length >= 2)?(osd_info_show === osd_info_show_opt[0]):false)} label={(osd_info_show_desc?[ osd_info_show_desc[0], osd_info_show_desc[1] ]:undefined)} onChange={this.onChangeOsdInfoDisplay} /></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_DISP_INFO_TIMEOUT')} style={notHiddenIf(!!osdInfoTimeoutParam.n1.isRange)}>
                                <Slider
                                    value={parseInt(osd_info_timeout)}
                                    max={(osdInfoTimeoutParam.n1.isRange) ? osdInfoTimeoutParam.n1.isRange.range.max : 100}
                                    min={(osdInfoTimeoutParam.n1.isRange) ? osdInfoTimeoutParam.n1.isRange.range.min : 0}
                                    step={(osdInfoTimeoutParam.n1.isRange) ? osdInfoTimeoutParam.n1.isRange.range.step : 1}
                                    onAfterChange={this.onChangeOutOSDInfoTimeout}
                                />
                                <div style={slider_show_value_style}>{`${parseInt(osd_info_timeout)}`}</div>
                            </Form.Item>
                        </Form.Form>
                    </Flat.Field>
                    <Flat.Field title={getText('DEVICE_FUNC_OSD_OUT_BANNER_LABEL')} style={notHiddenIf(this.instance.IsSupportBanner() && out_s.length > 0)}>
                        <Form.Form labelStyle={{ width: '150px' }}>
                            <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_BANNER_SRC')} style={notHiddenIf(this.instance.IsSupportBannerSourceSelect())}><Select placeholder='Select Source' value={banner_src} onChange={this.onChangeBannerSrc}>
                                {banner_src_opt.map((opt, idx) => <Option value={opt} key={`src_opt_${opt}`}>{(banner_src_desc) ? banner_src_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_BANNER_LOC')} style={notHiddenIf(this.instance.IsSupportBannerLocation())}><Select placeholder='Select Location' value={banner_loc} onChange={this.onChangeBannerLocation}>
                                {banner_loc_opt.map((opt, idx) => <Option value={opt} key={`loc_opt_${opt}`}>{(banner_loc_desc) ? banner_loc_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_BANNER_FONT_SIZE')} style={notHiddenIf(!!bannerFontSizeParam.n1.isRange)}>
                                <Slider
                                    value={parseInt(font_size)}
                                    max={(bannerFontSizeParam.n1.isRange) ? bannerFontSizeParam.n1.isRange.range.max : 100}
                                    min={(bannerFontSizeParam.n1.isRange) ? bannerFontSizeParam.n1.isRange.range.min : 0}
                                    step={(bannerFontSizeParam.n1.isRange) ? bannerFontSizeParam.n1.isRange.range.step : 1}
                                    onAfterChange={this.onChangeOutBannerFontSize}
                                />
                                <div style={slider_show_value_style}>{`${parseInt(font_size)}`}</div>
                            </Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_BANNER_FONT_CLR')} style={notHiddenIf(this.instance.IsSupportBannerFontColor())}><Select placeholder='Select Color' value={font_color} onChange={this.onChangeBannerFontColor}>
                                {font_color_opt.map((opt, idx) => <Option value={opt} key={`color_opt_${opt}`}>{(font_color_desc) ? font_color_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_BANNER_FONT_TRANS')} style={notHiddenIf(this.instance.IsSupportBannerFontTransparencyLevel())}><Select placeholder='Select Level' value={banner_trans_level} onChange={this.onChangeBannerTransparencyLevel}>
                                {banner_trans_level_opt.map((opt, idx) => <Option value={opt} key={`banner_translevel_opt_${opt}`}>{(banner_trans_level_desc) ? banner_trans_level_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                        </Form.Form>
                    </Flat.Field>
                    <Flat.Field title={getText('DEVICE_FUNC_OSD_OUT_LOGO_LABEL')} style={notHiddenIf(this.instance.IsSupportLogoDisplay() && out_s.length > 0)}>
                        <Form.Form labelStyle={{ width: '150px' }}>
                            <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_LOGO_ON')}><SwitchButton checked={((osd_logo_show_opt.length >= 2)?(logo_show === osd_logo_show_opt[0]):false)} label={(osd_logo_show_desc?[ osd_logo_show_desc[0], osd_logo_show_desc[1] ]:undefined)} onChange={this.onChangeOsdLogoDisplay} /></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_LOGO_POS_H')} style={notHiddenIf(!!logoHPositionParam.n1.isRange)}>
                                <Slider
                                    value={parseInt(logo_h_pos)}
                                    max={(logoHPositionParam.n1.isRange) ? logoHPositionParam.n1.isRange.range.max : 100}
                                    min={(logoHPositionParam.n1.isRange) ? logoHPositionParam.n1.isRange.range.min : 0}
                                    step={(logoHPositionParam.n1.isRange) ? logoHPositionParam.n1.isRange.range.step : 1}
                                    onAfterChange={this.onChangeOutLogoHPosition}
                                />
                                <div style={slider_show_value_style}>{`${parseInt(logo_h_pos)}`}</div>
                            </Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_LOGO_POS_V')} style={notHiddenIf(!!logoVPositionParam.n1.isRange)}>
                                <Slider
                                    value={parseInt(logo_v_pos)}
                                    max={(logoVPositionParam.n1.isRange) ? logoVPositionParam.n1.isRange.range.max : 100}
                                    min={(logoVPositionParam.n1.isRange) ? logoVPositionParam.n1.isRange.range.min : 0}
                                    step={(logoVPositionParam.n1.isRange) ? logoVPositionParam.n1.isRange.range.step : 1}
                                    onAfterChange={this.onChangeOutLogoVPosition}
                                />
                                <div style={slider_show_value_style}>{`${parseInt(logo_v_pos)}`}</div>
                            </Form.Item>
                        </Form.Form>
                    </Flat.Field>
                    <Flat.Field title={getText('DEVICE_FUNC_OSD_OUT_COUNTDOWN_LABEL')} style={notHiddenIf(this.instance.IsSupportCountdownTimer() && out_s.length > 0)}>
                        <Form.Form labelStyle={{ width: '150px' }}>
                            <Form.Item label={getText('DEVICE_FUNC_OSD_OUT_COUNTDOWN_TIMER')} style={notHiddenIf(!!countdownTimerParam.n1.isRange)}>
                                <Slider
                                    value={parseInt(countdown_timer)}
                                    max={(countdownTimerParam.n1.isRange) ? countdownTimerParam.n1.isRange.range.max : 100}
                                    min={(countdownTimerParam.n1.isRange) ? countdownTimerParam.n1.isRange.range.min : 0}
                                    step={(countdownTimerParam.n1.isRange) ? countdownTimerParam.n1.isRange.range.step : 1}
                                    onAfterChange={this.onChangeCountdownTimer}
                                />
                                <div style={slider_show_value_style}>{`${parseInt(countdown_timer)}`}</div>
                            </Form.Item>
                        </Form.Form>
                    </Flat.Field>
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

declare type CableTestViewState = {
    in_on: boolean; out_on: boolean; in_timeout: number; out_timeout: number;
    src_mse_a: Array<number>; src_mse_b: Array<number>; src_mse_c: Array<number>; src_mse_d: Array<number>;
    src_mae_a: Array<number>; src_mae_b: Array<number>; src_mae_c: Array<number>; src_mae_d: Array<number>;
    src_maxerr_a: Array<number>; src_maxerr_b: Array<number>; src_maxerr_c: Array<number>; src_maxerr_d: Array<number>;
    src_fmaxerr_a: Array<number>; src_fmaxerr_b: Array<number>; src_fmaxerr_c: Array<number>; src_fmaxerr_d: Array<number>;
    out_mse_a: Array<number>; out_mse_b: Array<number>; out_mse_c: Array<number>; out_mse_d: Array<number>;
    out_mae_a: Array<number>; out_mae_b: Array<number>; out_mae_c: Array<number>; out_mae_d: Array<number>;
    out_maxerr_a: Array<number>; out_maxerr_b: Array<number>; out_maxerr_c: Array<number>; out_maxerr_d: Array<number>;
    out_fmaxerr_a: Array<number>; out_fmaxerr_b: Array<number>; out_fmaxerr_c: Array<number>; out_fmaxerr_d: Array<number>;
}

class CableTestView extends CsxUI.IRQComponent<any> {
    state: CableTestViewState
    instance: CsxFeature.CsxCableTestDevice
    constructor(props: any) {
        super(props);
        const DEFAULT_TIMEOUT = 10;
        this.MIN_RENDER_INTERVAL = 100;
        this.state = {
            in_on: false, out_on: false, in_timeout: DEFAULT_TIMEOUT, out_timeout: DEFAULT_TIMEOUT,
            src_mse_a: [], src_mse_b: [], src_mse_c: [], src_mse_d: [],
            src_mae_a: [], src_mae_b: [], src_mae_c: [], src_mae_d: [],
            src_maxerr_a: [], src_maxerr_b: [], src_maxerr_c: [], src_maxerr_d: [],
            src_fmaxerr_a: [], src_fmaxerr_b: [], src_fmaxerr_c: [], src_fmaxerr_d: [],
            out_mse_a: [], out_mse_b: [], out_mse_c: [], out_mse_d: [],
            out_mae_a: [], out_mae_b: [], out_mae_c: [], out_mae_d: [],
            out_maxerr_a: [], out_maxerr_b: [], out_maxerr_c: [], out_maxerr_d: [],
            out_fmaxerr_a: [], out_fmaxerr_b: [], out_fmaxerr_c: [], out_fmaxerr_d: [],
        };
        this.IRQIndex = 'DeviceRealTime';
        this.instance = new CsxFeature.CsxCableTestDevice(window.FOCUS_DEVICE);
    }
    pushSrcData = (data: CsxUtil.HDBTAnalyze) => {
        this.setState((prevState: CableTestViewState): Partial<CableTestViewState> => {
            if (data.RX) {
                const { in_timeout, src_maxerr_a, src_maxerr_b, src_maxerr_c, src_maxerr_d, src_mse_a, src_mse_b, src_mse_c, src_mse_d } = prevState;
                src_maxerr_a.push(data.MaxError.A);
                src_maxerr_b.push(data.MaxError.B);
                src_maxerr_c.push(data.MaxError.C);
                src_maxerr_d.push(data.MaxError.D);
                src_mse_a.push(data.RX.MSE.A);
                src_mse_b.push(data.RX.MSE.B);
                src_mse_c.push(data.RX.MSE.C);
                src_mse_d.push(data.RX.MSE.D);
                return {
                    src_maxerr_a: (src_maxerr_a.length > in_timeout * 60) ? src_maxerr_a.slice(1) : src_maxerr_a.slice(),
                    src_maxerr_b: (src_maxerr_b.length > in_timeout * 60) ? src_maxerr_b.slice(1) : src_maxerr_b.slice(),
                    src_maxerr_c: (src_maxerr_c.length > in_timeout * 60) ? src_maxerr_c.slice(1) : src_maxerr_c.slice(),
                    src_maxerr_d: (src_maxerr_d.length > in_timeout * 60) ? src_maxerr_d.slice(1) : src_maxerr_d.slice(),
                    src_mse_a: (src_mse_a.length > in_timeout * 60) ? src_mse_a.slice(1) : src_mse_a.slice(),
                    src_mse_b: (src_mse_b.length > in_timeout * 60) ? src_mse_b.slice(1) : src_mse_b.slice(),
                    src_mse_c: (src_mse_c.length > in_timeout * 60) ? src_mse_c.slice(1) : src_mse_c.slice(),
                    src_mse_d: (src_mse_d.length > in_timeout * 60) ? src_mse_d.slice(1) : src_mse_d.slice(),
                }
            } else if (data.TX) {
                const { out_timeout, src_fmaxerr_a, src_fmaxerr_b, src_fmaxerr_c, src_fmaxerr_d, src_mae_a, src_mae_b, src_mae_c, src_mae_d, } = prevState;
                src_fmaxerr_a.push(data.MaxError.A);
                src_fmaxerr_b.push(data.MaxError.B);
                src_fmaxerr_c.push(data.MaxError.C);
                src_fmaxerr_d.push(data.MaxError.D);
                src_mae_a.push(data.TX.MAE.A);
                src_mae_b.push(data.TX.MAE.B);
                src_mae_c.push(data.TX.MAE.C);
                src_mae_d.push(data.TX.MAE.D);
                return {
                    src_fmaxerr_a: (src_fmaxerr_a.length > out_timeout * 60) ? src_fmaxerr_a.slice(1) : src_fmaxerr_a.slice(),
                    src_fmaxerr_b: (src_fmaxerr_b.length > out_timeout * 60) ? src_fmaxerr_b.slice(1) : src_fmaxerr_b.slice(),
                    src_fmaxerr_c: (src_fmaxerr_c.length > out_timeout * 60) ? src_fmaxerr_c.slice(1) : src_fmaxerr_c.slice(),
                    src_fmaxerr_d: (src_fmaxerr_d.length > out_timeout * 60) ? src_fmaxerr_d.slice(1) : src_fmaxerr_d.slice(),
                    src_mae_a: (src_mae_a.length > out_timeout * 60) ? src_mae_a.slice(1) : src_mae_a.slice(),
                    src_mae_b: (src_mae_b.length > out_timeout * 60) ? src_mae_b.slice(1) : src_mae_b.slice(),
                    src_mae_c: (src_mae_c.length > out_timeout * 60) ? src_mae_c.slice(1) : src_mae_c.slice(),
                    src_mae_d: (src_mae_d.length > out_timeout * 60) ? src_mae_d.slice(1) : src_mae_d.slice(),
                }
            } else {
                return {};
            }
        });
    }
    pushOutData = (data: CsxUtil.HDBTAnalyze) => {
        this.setState((prevState: CableTestViewState): Partial<CableTestViewState> => {
            if (data.RX) {
                const { in_timeout, out_maxerr_a, out_maxerr_b, out_maxerr_c, out_maxerr_d, out_mse_a, out_mse_b, out_mse_c, out_mse_d, } = prevState;
                out_maxerr_a.push(data.MaxError.A);
                out_maxerr_b.push(data.MaxError.B);
                out_maxerr_c.push(data.MaxError.C);
                out_maxerr_d.push(data.MaxError.D);
                out_mse_a.push(data.RX.MSE.A);
                out_mse_b.push(data.RX.MSE.B);
                out_mse_c.push(data.RX.MSE.C);
                out_mse_d.push(data.RX.MSE.D);
                return {
                    out_maxerr_a: (out_maxerr_a.length > in_timeout * 60) ? out_maxerr_a.slice(1) : out_maxerr_a.slice(),
                    out_maxerr_b: (out_maxerr_b.length > in_timeout * 60) ? out_maxerr_b.slice(1) : out_maxerr_b.slice(),
                    out_maxerr_c: (out_maxerr_c.length > in_timeout * 60) ? out_maxerr_c.slice(1) : out_maxerr_c.slice(),
                    out_maxerr_d: (out_maxerr_d.length > in_timeout * 60) ? out_maxerr_d.slice(1) : out_maxerr_d.slice(),
                    out_mse_a: (out_mse_a.length > in_timeout * 60) ? out_mse_a.slice(1) : out_mse_a.slice(),
                    out_mse_b: (out_mse_b.length > in_timeout * 60) ? out_mse_b.slice(1) : out_mse_b.slice(),
                    out_mse_c: (out_mse_c.length > in_timeout * 60) ? out_mse_c.slice(1) : out_mse_c.slice(),
                    out_mse_d: (out_mse_d.length > in_timeout * 60) ? out_mse_d.slice(1) : out_mse_d.slice(),
                }
            } else if (data.TX) {
                const { out_timeout, out_fmaxerr_a, out_fmaxerr_b, out_fmaxerr_c, out_fmaxerr_d, out_mae_a, out_mae_b, out_mae_c, out_mae_d, } = prevState;
                out_fmaxerr_a.push(data.MaxError.A);
                out_fmaxerr_b.push(data.MaxError.B);
                out_fmaxerr_c.push(data.MaxError.C);
                out_fmaxerr_d.push(data.MaxError.D);
                out_mae_a.push(data.TX.MAE.A);
                out_mae_b.push(data.TX.MAE.B);
                out_mae_c.push(data.TX.MAE.C);
                out_mae_d.push(data.TX.MAE.D);
                return {
                    out_fmaxerr_a: (out_fmaxerr_a.length > out_timeout * 60) ? out_fmaxerr_a.slice(1) : out_fmaxerr_a.slice(),
                    out_fmaxerr_b: (out_fmaxerr_b.length > out_timeout * 60) ? out_fmaxerr_b.slice(1) : out_fmaxerr_b.slice(),
                    out_fmaxerr_c: (out_fmaxerr_c.length > out_timeout * 60) ? out_fmaxerr_c.slice(1) : out_fmaxerr_c.slice(),
                    out_fmaxerr_d: (out_fmaxerr_d.length > out_timeout * 60) ? out_fmaxerr_d.slice(1) : out_fmaxerr_d.slice(),
                    out_mae_a: (out_mae_a.length > out_timeout * 60) ? out_mae_a.slice(1) : out_mae_a.slice(),
                    out_mae_b: (out_mae_b.length > out_timeout * 60) ? out_mae_b.slice(1) : out_mae_b.slice(),
                    out_mae_c: (out_mae_c.length > out_timeout * 60) ? out_mae_c.slice(1) : out_mae_c.slice(),
                    out_mae_d: (out_mae_d.length > out_timeout * 60) ? out_mae_d.slice(1) : out_mae_d.slice(),
                }
            } else {
                return {};
            }
        });
    }
    onChangeInStart = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ in_on: e.target.checked });
        window.HEALTH_HANDLER[this.instance.ID] = { input: ((e.target.checked) ? this.pushSrcData : undefined) };
    }
    onChangeOutStart = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ out_on: e.target.checked });
        window.HEALTH_HANDLER[this.instance.ID] = { output: ((e.target.checked) ? this.pushOutData : undefined) };
    }
    onChangeInTimeout = (value: string) => { this.setState({ in_timeout: parseInt(value) }); }
    onChangeOutTimeout = (value: string) => { this.setState({ out_timeout: parseInt(value) }); }
    render() {
        const MSE_DEFAULT_THRESHOLD = -15;
        const MAR_DEFAULT_THRESHOLD = 0.95;
        const { in_on, out_on, in_timeout, out_timeout,
            src_mse_a, src_mse_b, src_mse_c, src_mse_d,
            // src_mae_a, src_mae_b, src_mae_c, src_mae_d,
            src_maxerr_a, src_maxerr_b, src_maxerr_c, src_maxerr_d,
            // src_fmaxerr_a, src_fmaxerr_b, src_fmaxerr_c, src_fmaxerr_d,
            out_mse_a, out_mse_b, out_mse_c, out_mse_d,
            // out_mae_a, out_mae_b, out_mae_c, out_mae_d,
            out_maxerr_a, out_maxerr_b, out_maxerr_c, out_maxerr_d,
            // out_fmaxerr_a, out_fmaxerr_b, out_fmaxerr_c, out_fmaxerr_d,
        } = this.state;
        const src_mse_th = Array.from(Array(in_timeout * 60).keys()).map(e => MSE_DEFAULT_THRESHOLD);
        // const src_mae_th = Array.from(Array(in_timeout*60).keys()).map(e => MSE_DEFAULT_THRESHOLD);
        const src_maxerr_th = Array.from(Array(in_timeout * 60).keys()).map(e => MAR_DEFAULT_THRESHOLD);
        // const src_fmaxerr_th = Array.from(Array(in_timeout*60).keys()).map(e => MAR_DEFAULT_THRESHOLD);
        const out_mse_th = Array.from(Array(out_timeout * 60).keys()).map(e => MSE_DEFAULT_THRESHOLD);
        // const out_mae_th = Array.from(Array(out_timeout*60).keys()).map(e => MSE_DEFAULT_THRESHOLD);
        const out_maxerr_th = Array.from(Array(out_timeout * 60).keys()).map(e => MAR_DEFAULT_THRESHOLD);
        // const out_fmaxerr_th = Array.from(Array(out_timeout*60).keys()).map(e => MAR_DEFAULT_THRESHOLD);
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_CABEL_IN_ANALYZ_LABEL')} style={notHiddenIf(this.instance.IsSupportSourceAnalyze())} defaultExtend={true}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_CABEL_IN_ANALYZ_START')}><SwitchButton checked={in_on} label={['ON', 'OFF']} onChange={this.onChangeInStart} /></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_CABEL_IN_ANALYZ_TIMEOUT')}><Select disabled={in_on} value={in_timeout} onChange={this.onChangeInTimeout}>
                            <Option value='1'>1 min</Option>
                            <Option value='5'>5 min</Option>
                            <Option value='10'>10 min</Option>
                        </Select></Form.Item>
                    </Form.Form>
                    {(in_on) ? <Flat.Field title={getText('DEVICE_FUNC_CABEL_IN_MSE_LABEL')}>
                        <div className='chart_warpper'><MseChart data={[src_mse_a, src_mse_b, src_mse_c, src_mse_d]} threshold={src_mse_th} /></div>
                    </Flat.Field> : undefined}
                    {(in_on) ? <Flat.Field title={getText('DEVICE_FUNC_CABEL_IN_MAXERR_LABEL')}>
                        <div className='chart_warpper'><MaxErrChart data={[src_maxerr_a, src_maxerr_b, src_maxerr_c, src_maxerr_d]} threshold={src_maxerr_th} /></div>
                    </Flat.Field> : undefined}
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_CABEL_OUT_ANALYZ_LABEL')} style={notHiddenIf(this.instance.IsSupportOutputAnalyze())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_CABEL_OUT_ANALYZ_START')}><SwitchButton checked={out_on} label={['ON', 'OFF']} onChange={this.onChangeOutStart} /></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_CABEL_OUT_ANALYZ_TIMEOUT')}><Select disabled={out_on} value={out_timeout} onChange={this.onChangeOutTimeout}>
                            <Option value='1'>1 min</Option>
                            <Option value='5'>5 min</Option>
                            <Option value='10'>10 min</Option>
                        </Select></Form.Item>
                    </Form.Form>
                    {(out_on) ? <Flat.Field title={getText('DEVICE_FUNC_CABEL_OUT_MSE_LABEL')}>
                        <div className='chart_warpper'><MseChart data={[out_mse_a, out_mse_b, out_mse_c, out_mse_d]} threshold={out_mse_th} /></div>
                    </Flat.Field> : undefined}
                    {(out_on) ? <Flat.Field title={getText('DEVICE_FUNC_CABEL_OUT_MAXERR_LABEL')}>
                        <div className='chart_warpper'><MaxErrChart data={[out_maxerr_a, out_maxerr_b, out_maxerr_c, out_maxerr_d]} threshold={out_maxerr_th} /></div>
                    </Flat.Field> : undefined}
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

class StreamingView extends CsxUI.IRQComponent<any> {
    state: { ch_s: string; prof_s: string; key_err: string; url_err: string; live_changed: boolean; prof_changed: boolean }
    instance: CsxFeature.CsxStreamingDevice
    constructor(props: any) {
        super(props);
        this.state = { ch_s: '', prof_s: '', key_err: '', url_err: '', live_changed: false, prof_changed: false };
        this.IRQIndex = 'DeviceRealTime';
        this.instance = new CsxFeature.CsxStreamingDevice(window.FOCUS_DEVICE);
    }
    validateKey = (value: string) => {
        const liveKeyParam = this.instance.LiveStreamKeyParam();
        const ok = !(liveKeyParam.s1.isString && !this.validateString(value, liveKeyParam.s1.isString));
        this.setState({ key_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    validateUrl = (value: string) => {
        const liveUrlParam = this.instance.LiveStreamUrlParam();
        const ok = !(liveUrlParam.s1.isString && !this.validateString(value, liveUrlParam.s1.isString));
        this.setState({ url_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    onChangeLiveStart = (e: React.ChangeEvent<HTMLInputElement>) => {
        const liveModeParam = this.instance.LiveStreamModeParam();
        const live_stream_opt = (liveModeParam.b1.isOption) ? liveModeParam.b1.isOption.option : [];
        if (live_stream_opt.length >= 2) {
            this.instance.SetLiveStreamMode((e.target.checked) ? live_stream_opt[0] : live_stream_opt[1]);
        }
    }
    onChangeLiveURL = (e: React.ChangeEvent<HTMLInputElement>) => { this.validateUrl(e.target.value); this.instance.SetLiveStreamUrl(e.target.value); this.setState({ live_changed: true }); }
    onChangeLiveKey = (e: React.ChangeEvent<HTMLInputElement>) => { this.validateKey(e.target.value); this.instance.SetLiveStreamKey(e.target.value); this.setState({ live_changed: true }); }
    onChangeEncodeRes = (value: string) => { this.instance.SetEncodeProfileResolution(this.state.ch_s, this.state.prof_s, value); this.setState({ prof_changed: true }); }
    onChangeEncodeBR = (value: string) => { this.instance.SetEncodeProfileBitrate(this.state.ch_s, this.state.prof_s, value); this.setState({ prof_changed: true }); }
    onChangeEncodeFR = (value: string) => { this.instance.SetEncodeProfileFramerate(this.state.ch_s, this.state.prof_s, value); this.setState({ prof_changed: true }); }
    onChangeLivePlatform = (value: string) => { this.instance.SetLiveModePath(value); this.setState({ live_changed: true }); }
    onChangeLiveRes = (value: string) => { this.instance.SetLiveEncodeResolution(value); this.setState({ live_changed: true }); }
    onChangeLiveBR = (value: string) => { this.instance.SetLiveEncodeBitrate(value); this.setState({ live_changed: true }); }
    onChangeLiveFR = (value: string) => { this.instance.SetLiveEncodeFramerate(value); this.setState({ live_changed: true }); }
    onChangeChannelSelect = (value: string) => { this.setState({ ch_s: value }); }
    onChangeProfileSelect = (value: string) => { this.setState({ prof_s: value }); }
    onSaveLiveConfiguration = () => { this.instance.UploadLiveConfiguration(); this.setState({ live_changed: false }); }
    onSaveProfileConfiguration = () => { this.instance.UploadProfileConfiguration(); this.setState({ prof_changed: false, key_err: '', url_err: '' }); }
    render() {
        const { ch_s, prof_s, key_err, url_err, live_changed } = this.state;
        const encodeResolutionParam = this.instance.EncodeProfileResolutionParam();
        const encodeBitrateParam = this.instance.EncodeProfileBitrateParam();
        const encodeFramerateParam = this.instance.EncodeProfileFramerateParam();
        const livePlatformParam = this.instance.LiveModePathParam();
        const liveModeParam = this.instance.LiveStreamModeParam();
        const liveResParam = this.instance.LiveEncodeResolutionParam();
        const liveBRParam = this.instance.LiveEncodeBitrateParam();
        const liveFRParam = this.instance.LiveEncodeFramerateParam();
        const ch_opt = (encodeResolutionParam.n1.isOption) ? encodeResolutionParam.n1.isOption.option : [];
        const ch_desc = (encodeResolutionParam.n1.isOption) ? encodeResolutionParam.n1.isOption.desc : [];
        const prof_opt = (encodeResolutionParam.n2.isOption) ? encodeResolutionParam.n2.isOption.option : [];
        const prof_desc = (encodeResolutionParam.n2.isOption) ? encodeResolutionParam.n2.isOption.desc : [];
        const res_opt = (encodeResolutionParam.s1.isOption) ? encodeResolutionParam.s1.isOption.option : [];
        const res_desc = (encodeResolutionParam.s1.isOption) ? encodeResolutionParam.s1.isOption.desc : [];
        const br_opt = (encodeBitrateParam.n3.isOption) ? encodeBitrateParam.n3.isOption.option : [];
        const br_desc = (encodeBitrateParam.n3.isOption) ? encodeBitrateParam.n3.isOption.desc : [];
        const fr_opt = (encodeFramerateParam.n3.isOption) ? encodeFramerateParam.n3.isOption.option : [];
        const fr_desc = (encodeFramerateParam.n3.isOption) ? encodeFramerateParam.n3.isOption.desc : [];
        const live_res_opt = (liveResParam.s1.isOption) ? liveResParam.s1.isOption.option : [];
        const live_res_desc = (liveResParam.s1.isOption) ? liveResParam.s1.isOption.desc : [];
        const live_br_opt = (liveBRParam.n1.isOption) ? liveBRParam.n1.isOption.option : [];
        const live_br_desc = (liveBRParam.n1.isOption) ? liveBRParam.n1.isOption.desc : [];
        const live_fr_opt = (liveFRParam.n1.isOption) ? liveFRParam.n1.isOption.option : [];
        const live_fr_desc = (liveFRParam.n1.isOption) ? liveFRParam.n1.isOption.desc : [];
        const live_platform_opt = (livePlatformParam.s1.isOption) ? livePlatformParam.s1.isOption.option : [];
        const live_platform_desc = (livePlatformParam.s1.isOption) ? livePlatformParam.s1.isOption.desc : [];
        const live_stream_opt = (liveModeParam.b1.isOption) ? liveModeParam.b1.isOption.option : [];
        const live_stream_desc = (liveModeParam.b1.isOption) ? liveModeParam.b1.isOption.desc : [];
        const res_s = this.instance.EncodeProfileResolution(ch_s, prof_s);
        const br_s = this.instance.EncodeProfileBitrate(ch_s, prof_s);
        const fr_s = this.instance.EncodeProfileFramerate(ch_s, prof_s);
        const live_platform = this.instance.LiveModePath();
        const live_url = this.instance.LiveStreamUrl();
        const live_key = this.instance.LiveStreamKey();
        const live_mode = this.instance.LiveStreamMode();
        const live_res = this.instance.LiveEncodeResolution();
        const live_br = this.instance.LiveEncodeBitrate();
        const live_fr = this.instance.LiveEncodeFramerate();
        // validate input
        const streaming_conf_valid = (key_err.length === 0 && url_err.length === 0);
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_STREAM_LIVE_LABEL')} style={notHiddenIf(this.instance.IsSupportLiveEncodeConfiguration())} defaultExtend={true}>
                    <Form.Item label={getText('DEVICE_FUNC_STREAM_LIVE_START')} labelStyle={{ width: '150px' }}><SwitchButton checked={((live_stream_opt.length >= 2)?(live_mode === live_stream_opt[0]):false)} label={(live_stream_desc?[ live_stream_desc[0], live_stream_desc[1] ]:undefined)} onChange={this.onChangeLiveStart} /></Form.Item>
                    <Flat.Field title={getText('DEVICE_FUNC_STREAM_LIVECONF_LABEL')}>
                        <Form.Form labelStyle={{ width: '150px' }}>
                            <Form.Item label={getText('DEVICE_FUNC_STREAM_LIVECONF_PLAT')} style={notHiddenIf(this.instance.IsSupportLiveModePath())}><Select disabled={live_mode === 'on'} placeholder='Select Live Platform' value={live_platform} onChange={this.onChangeLivePlatform}>
                                {live_platform_opt.map((opt, idx) => <Option value={opt} key={`live_pf_opt_${opt}`}>{(live_platform_desc) ? live_platform_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_STREAM_LIVECONF_URL')} style={notHiddenIf(this.instance.IsSupportLiveStreamUrl())} error={url_err}>
                                <Input disabled={live_mode === 'on'} placeholder='Live URL' value={live_url} onChange={this.onChangeLiveURL} />
                            </Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_STREAM_LIVECONF_KEY')} style={notHiddenIf(this.instance.IsSupportLiveStreamKey())} error={key_err}>
                                <Input disabled={live_mode === 'on'} placeholder='Live Key' value={live_key} onChange={this.onChangeLiveKey} />
                            </Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_STREAM_LIVECONF_RES')} style={notHiddenIf(this.instance.IsSupportLiveEncodeConfiguration())}><Select disabled={live_mode === 'on'} placeholder='Select Resolution' value={live_res} onChange={this.onChangeLiveRes}>
                                {live_res_opt.map((opt, idx) => <Option value={opt} key={`live_res_opt_${opt}`}>{(live_res_desc) ? live_res_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_STREAM_LIVECONF_BR')} style={notHiddenIf(this.instance.IsSupportLiveEncodeConfiguration())}><Select disabled={live_mode === 'on'} placeholder='Select Bitrate' value={live_br} onChange={this.onChangeLiveBR}>
                                {live_br_opt.map((opt, idx) => <Option value={opt} key={`live_br_opt_${opt}`}>{(live_br_desc) ? live_br_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_STREAM_LIVECONF_FR')} style={notHiddenIf(this.instance.IsSupportLiveEncodeConfiguration())}><Select disabled={live_mode === 'on'} placeholder='Select Framerate' value={live_fr} onChange={this.onChangeLiveFR}>
                                {live_fr_opt.map((opt, idx) => <Option value={opt} key={`live_fr_opt_${opt}`}>{(live_fr_desc) ? live_fr_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item style={{ justifyContent: 'flex-end' }}><Button type='primary' disabled={(!live_changed || !streaming_conf_valid)} onClick={this.onSaveLiveConfiguration}>{getText('SAVE')}</Button></Form.Item>
                        </Form.Form>
                    </Flat.Field>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_STREAM_ENC_LABEL')} style={notHiddenIf(this.instance.IsSupportEncodeProfileConfiguration())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_STREAM_ENC_CHANNEL')}><Select placeholder='Select Channel' value={ch_s} onChange={this.onChangeChannelSelect}>
                            {ch_opt.map((opt, idx) => <Option value={opt} key={`ch_opt_${opt}`}>{(ch_desc) ? ch_desc[idx] : opt}</Option>)}
                        </Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_STREAM_ENC_PROFILE')}><Select placeholder='Select Profile' value={prof_s} onChange={this.onChangeProfileSelect}>
                            {prof_opt.map((opt, idx) => <Option value={opt} key={`prof_opt_${opt}`}>{(prof_desc) ? prof_desc[idx] : opt}</Option>)}
                        </Select></Form.Item>
                    </Form.Form>
                    <Flat.Field title={getText('DEVICE_FUNC_STREAM_ENCCONF_LABEL')}>
                        <Form.Form labelStyle={{ width: '150px' }}>
                            <Form.Item label={getText('DEVICE_FUNC_STREAM_ENCCONF_RES')} disabled={ch_s.length === 0 || prof_s.length === 0}><Select placeholder='Select Resolution' value={res_s} onChange={this.onChangeEncodeRes}>
                                {res_opt.map((opt, idx) => <Option value={opt} key={`res_opt_${opt}`}>{(res_desc) ? res_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_STREAM_ENCCONF_BR')} disabled={ch_s.length === 0 || prof_s.length === 0}><Select placeholder='Select Bitrate' value={br_s} onChange={this.onChangeEncodeBR}>
                                {br_opt.map((opt, idx) => <Option value={opt} key={`br_opt_${opt}`}>{(br_desc) ? br_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_STREAM_ENCCONF_FR')} disabled={ch_s.length === 0 || prof_s.length === 0}><Select placeholder='Select Framerate' value={fr_s} onChange={this.onChangeEncodeFR}>
                                {fr_opt.map((opt, idx) => <Option value={opt} key={`fr_opt_${opt}`}>{(fr_desc) ? fr_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item><Button style={{ justifySelf: 'flex-end', marginLeft: 'auto' }} type='primary' disabled={ch_s.length === 0 || prof_s.length === 0} onClick={this.onSaveProfileConfiguration}>{getText('SAVE')}</Button></Form.Item>
                        </Form.Form>
                    </Flat.Field>
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

class RecordView extends CsxUI.IRQComponent<any> {
    state: { conf_changed: boolean }
    instance: CsxFeature.CsxRecordDevice
    // feature_list: Array<Feature>
    constructor(props: any) {
        super(props);
        this.state = { conf_changed: false };
        this.MIN_RENDER_INTERVAL = 100;
        this.IRQIndex = 'DeviceRealTime';
        this.instance = new CsxFeature.CsxRecordDevice(window.FOCUS_DEVICE);
        // this.feature_list = [
        //     { name: 'Record Mode', check: this.instance.IsSupportRecordMode },
        //     { name: 'Record Overwrite', check: this.instance.IsSupportRecordOverwrite },
        //     { name: 'Bitrate', check: this.instance.IsSupportRecordBitrate },
        //     { name: 'Framerate', check: this.instance.IsSupportRecordFramerate },
        //     { name: 'Resolution', check: this.instance.IsSupportRecordResolution },
        //     { name: 'Record Media Path Edit', check: this.instance.IsSupportRecordMediaPath },
        //     { name: 'Record Profile Path Edit', check: this.instance.IsSupportRecordProfilePath },
        //     { name: 'Schedule Mode', check: this.instance.IsSupportScheduleMode },
        // ];
    }
    onChangeRecordStart = (e: React.ChangeEvent<HTMLInputElement>) => {
        const recordModeParam = this.instance.RecordModeParam();
        const rec_mode_opt = (recordModeParam.b1.isOption) ? recordModeParam.b1.isOption.option : [];
        if (rec_mode_opt.length >= 2) {
            this.instance.SetRecordMode((e.target.checked) ? rec_mode_opt[0] : rec_mode_opt[1]);
        }
    }
    onChangeRecordOverwrite = (e: React.ChangeEvent<HTMLInputElement>) => {
        const recordOverwriteParam = this.instance.RecordOverwriteParam();
        const rec_overwrite_opt = (recordOverwriteParam.b1.isOption) ? recordOverwriteParam.b1.isOption.option : [];
        if (rec_overwrite_opt.length >= 2) {
            this.instance.SetRecordOverwrite((e.target.checked) ? rec_overwrite_opt[0] : rec_overwrite_opt[1]);
        }
    }
    onChangeScheduleMode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const scheduleModeParam = this.instance.ScheduleModeParam();
        const schd_mode_opt = (scheduleModeParam.b1.isOption) ? scheduleModeParam.b1.isOption.option : [];
        if (schd_mode_opt.length >= 2) {
            this.instance.SetScheduleMode((e.target.checked) ? schd_mode_opt[0] : schd_mode_opt[1]);
        }
    }
    onChangeRecordStorage = (value: string) => { this.instance.SetRecordMediaPath(value); this.setState({ conf_changed: true }); }
    onChangeRecordProfile = (value: string) => { this.instance.SetRecordProfilePath(value); this.setState({ conf_changed: true }); }
    onChangeRecordResolution = (value: string) => { this.instance.SetRecordResolution(value); this.setState({ conf_changed: true }); }
    onChangeRecordBitrate = (value: string) => { this.instance.SetRecordBitrate(value); this.setState({ conf_changed: true }); }
    onChangeRecordFramerate = (value: string) => { this.instance.SetRecordFramerate(value); this.setState({ conf_changed: true }); }
    onSaveConfiguration = () => { this.instance.UploadConfiguration(); this.setState({ conf_changed: false }); }
    render() {
        const { conf_changed } = this.state;
        const storagePathParam = this.instance.RecordMediaPathParam();
        const profilePathParam = this.instance.RecordProfilePathParam();
        const resParam = this.instance.RecordResolutionParam();
        const brParam = this.instance.RecordBitrateParam();
        const frParam = this.instance.RecordFramerateParam();
        const recordOverwriteParam = this.instance.RecordOverwriteParam();
        const recordModeParam = this.instance.RecordModeParam();
        const scheduleModeParam = this.instance.ScheduleModeParam();
        const storage_path_opt = (storagePathParam.s1.isOption) ? storagePathParam.s1.isOption.option : [];
        const storage_path_desc = (storagePathParam.s1.isOption) ? storagePathParam.s1.isOption.desc : [];
        const profile_path_opt = (profilePathParam.n1.isOption) ? profilePathParam.n1.isOption.option : [];
        const profile_path_desc = (profilePathParam.n1.isOption) ? profilePathParam.n1.isOption.desc : [];
        const res_opt = (resParam.s1.isOption) ? resParam.s1.isOption.option : [];
        const res_desc = (resParam.s1.isOption) ? resParam.s1.isOption.desc : [];
        const br_opt = (brParam.n1.isOption) ? brParam.n1.isOption.option : [];
        const br_desc = (brParam.n1.isOption) ? brParam.n1.isOption.desc : [];
        const fr_opt = (frParam.n1.isOption) ? frParam.n1.isOption.option : [];
        const fr_desc = (frParam.n1.isOption) ? frParam.n1.isOption.desc : [];
        const rec_overwrite_opt = (recordOverwriteParam.b1.isOption) ? recordOverwriteParam.b1.isOption.option : [];
        const rec_overwrite_desc = (recordOverwriteParam.b1.isOption) ? recordOverwriteParam.b1.isOption.desc : [];
        const rec_mode_opt = (recordModeParam.b1.isOption) ? recordModeParam.b1.isOption.option : [];
        const rec_mode_desc = (recordModeParam.b1.isOption) ? recordModeParam.b1.isOption.desc : [];
        const schd_mode_opt = (scheduleModeParam.b1.isOption) ? scheduleModeParam.b1.isOption.option : [];
        const schd_mode_desc = (scheduleModeParam.b1.isOption) ? scheduleModeParam.b1.isOption.desc : [];
        const rec_mode = this.instance.RecordMode();
        const rec_overwrite = this.instance.RecordOverwrite();
        const rec_storage = this.instance.RecordMediaPath();
        const rec_profile = this.instance.RecordProfilePath();
        const rec_res = this.instance.RecordResolution();
        const rec_br = this.instance.RecordBitrate();
        const rec_fr = this.instance.RecordFramerate();
        const schd_mode = this.instance.ScheduleMode();
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_REC_LABEL')} style={notHiddenIf(this.instance.IsSupportRecordMode())} defaultExtend={true}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_REC_START')}><SwitchButton checked={((rec_mode_opt.length >= 2)?(rec_mode === rec_mode_opt[0]):false)} label={(rec_mode_desc?[ rec_mode_desc[0], rec_mode_desc[1] ]:undefined)} onChange={this.onChangeRecordStart} /></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_REC_OVERWRITE')} style={notHiddenIf(this.instance.IsSupportRecordOverwrite())}><SwitchButton checked={((rec_overwrite_opt.length >= 2)?(rec_overwrite === rec_overwrite_opt[0]):false)} label={(rec_overwrite_desc?[ rec_overwrite_desc[0], rec_overwrite_desc[1] ]:undefined)} onChange={this.onChangeRecordOverwrite} /></Form.Item>
                    </Form.Form>
                    <Flat.Field title={getText('DEVICE_FUNC_REC_CONF_LABEL')}>
                        <Form.Form labelStyle={{ width: '150px' }}>
                            <Form.Item label={getText('DEVICE_FUNC_REC_CONF_STORAGE')} style={notHiddenIf(this.instance.IsSupportRecordMediaPath())}><Select placeholder='Select Storage Media' value={rec_storage} onChange={this.onChangeRecordStorage}>
                                {storage_path_opt.map((opt, idx) => <Option value={opt} key={`storage_path_opt_${opt}`}>{(storage_path_desc) ? storage_path_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_REC_CONF_PROFILE')} style={notHiddenIf(this.instance.IsSupportRecordProfilePath())}><Select placeholder='Select Profile' value={rec_profile} onChange={this.onChangeRecordProfile}>
                                {profile_path_opt.map((opt, idx) => <Option value={opt} key={`profile_path_opt_${opt}`}>{(profile_path_desc) ? profile_path_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_REC_CONF_RES')} style={notHiddenIf(this.instance.IsSupportRecordResolution())}><Select placeholder='Select Resolution' value={rec_res} onChange={this.onChangeRecordResolution}>
                                {res_opt.map((opt, idx) => <Option value={opt} key={`res_opt_${opt}`}>{(res_desc) ? res_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_REC_CONF_BR')} style={notHiddenIf(this.instance.IsSupportRecordBitrate())}><Select placeholder='Select Bitrate' value={rec_br} onChange={this.onChangeRecordBitrate}>
                                {br_opt.map((opt, idx) => <Option value={opt} key={`br_opt_${opt}`}>{(br_desc) ? br_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_REC_CONF_FR')} style={notHiddenIf(this.instance.IsSupportRecordFramerate())}><Select placeholder='Select Framerate' value={rec_fr} onChange={this.onChangeRecordFramerate}>
                                {fr_opt.map((opt, idx) => <Option value={opt} key={`fr_opt_${opt}`}>{(fr_desc) ? fr_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item><Button style={{ justifySelf: 'flex-end', marginLeft: 'auto' }} type='primary' disabled={!conf_changed} onClick={this.onSaveConfiguration}>{getText('SAVE')}</Button></Form.Item>
                        </Form.Form>
                    </Flat.Field>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_REC_SCHDULE_LABEL')} style={notHiddenIf(this.instance.IsSupportScheduleMode())}>
                    <Form.Item label={getText('DEVICE_FUNC_REC_SCHDULE_MODE')} labelStyle={{ width: '150px' }}><SwitchButton checked={((schd_mode_opt.length >= 2)?(schd_mode === schd_mode_opt[0]):false)} label={(schd_mode_desc?[ schd_mode_desc[0], schd_mode_desc[1] ]:undefined)} onChange={this.onChangeScheduleMode} /></Form.Item>
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

class AutomationView extends CsxUI.IRQComponent<any> {
    state: {
        evt_cec_select: { [s: string]: { idx: string } };
        evt_uart_select: { [s: string]: { idx: string } };
        cec_cmd_edit: { [cec: string]: { [evt: string]: { text: string, error: string } } };
        uart_cmd_edit: { [uart: string]: { [evt: string]: { text: string, error: string } } };
    }
    instance: CsxFeature.CsxAutomationDevice
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'DeviceRealTime';
        this.MIN_RENDER_INTERVAL = 100;
        this.instance = new CsxFeature.CsxAutomationDevice(window.FOCUS_DEVICE);
        this.state = {
            evt_cec_select: {},
            evt_uart_select: {},
            cec_cmd_edit: {},
            uart_cmd_edit: {},
        }
    }
    validateCECEventCommand = (value: string) => {
        const cecEventCommandParam = this.instance.CECEventCommandParam();
        return !(cecEventCommandParam.hh.isString && !this.validateString(value, cecEventCommandParam.hh.isString));
    }
    onChangeCECEventCommand = (cec_idx: string, evt_idx: string, e: React.ChangeEvent<HTMLInputElement>) => {
        const { cec_cmd_edit } = this.state;
        const buffer_clone = CsxUtil.nestedClone(cec_cmd_edit);
        const ok = this.validateCECEventCommand(e.target.value);

        if (typeof buffer_clone[cec_idx] === 'undefined') {
            buffer_clone[cec_idx] = {};
        }
        buffer_clone[cec_idx][evt_idx] = { text: e.target.value, error: (ok ? '' : getText('HINT_FORMAT_NOT_MATCH')) }
        this.setState({ cec_cmd_edit: buffer_clone });
    }
    onSaveCECEventCommand = (cec_idx: string, evt_idx: string) => {
        const { cec_cmd_edit } = this.state;
        const buffer_clone = CsxUtil.nestedClone(cec_cmd_edit);
        const cmd_edit = this.getCECInputEditor(cec_idx, evt_idx);

        if (cmd_edit && cmd_edit.error.length === 0) {
            this.instance.SetCECEventCommand(evt_idx, cec_idx, cmd_edit.text);
            delete buffer_clone[cec_idx][evt_idx];
            this.setState({ cec_cmd_edit: buffer_clone });
        }
    }
    onTestCECEventCommand = (cec_idx: string, evt_idx: string) => {
        this.instance.SetCECEventCommandTest(evt_idx, cec_idx);
    }
    validateUARTEventCommand = (value: string) => {
        const uartEventCommandParam = this.instance.UARTEventCommandParam();
        return !(uartEventCommandParam.s1.isString && !this.validateString(value, uartEventCommandParam.s1.isString));
    }
    onChangeUARTEventCommand = (uart_idx: string, evt_idx: string, e: React.ChangeEvent<HTMLInputElement>) => {
        const { uart_cmd_edit } = this.state;
        const buffer_clone = CsxUtil.nestedClone(uart_cmd_edit);
        const ok = this.validateUARTEventCommand(e.target.value);

        if (typeof buffer_clone[uart_idx] === 'undefined') {
            buffer_clone[uart_idx] = {};
        }
        buffer_clone[uart_idx][evt_idx] = { text: e.target.value, error: (ok ? '' : getText('HINT_FORMAT_NOT_MATCH')) }
        this.setState({ uart_cmd_edit: buffer_clone });
    }
    onSaveUARTEventCommand = (uart_idx: string, evt_idx: string) => {
        const { uart_cmd_edit } = this.state;
        const buffer_clone = CsxUtil.nestedClone(uart_cmd_edit);
        const cmd_edit = this.getUARTInputEditor(uart_idx, evt_idx);

        if (cmd_edit && cmd_edit.error.length === 0) {
            this.instance.SetUARTEventCommand(evt_idx, uart_idx, cmd_edit.text);
            delete buffer_clone[uart_idx][evt_idx];
            this.setState({ uart_cmd_edit: buffer_clone });
        }
    }
    onTestUARTEventCommand = (uart_idx: string, evt_idx: string) => {
        this.instance.SetUARTEventCommandTest(evt_idx, uart_idx);
    }
    onChangeCECSelect = (evt_idx: string, v: string) => {
        const { evt_cec_select } = this.state;
        const select_clone = CsxUtil.nestedClone(evt_cec_select);
        select_clone[evt_idx] = { idx: v };
        this.setState({ evt_cec_select: select_clone });
    }
    onChangeUARTSelect = (evt_idx: string, v: string) => {
        const { evt_uart_select } = this.state;
        const select_clone = CsxUtil.nestedClone(evt_uart_select);
        select_clone[evt_idx] = { idx: v };
        this.setState({ evt_uart_select: select_clone });
    }
    getCECInputEditor = (cec_idx: (string | undefined), evt_idx: string): ({ error: string, text: string } | undefined) => {
        const { cec_cmd_edit } = this.state;
        if (typeof cec_idx !== 'undefined') {
            return (cec_cmd_edit[cec_idx]) ? cec_cmd_edit[cec_idx][evt_idx] : undefined;
        } else {
            return undefined;
        }
    }
    getUARTInputEditor = (uart_idx: (string | undefined), evt_idx: string): ({ error: string, text: string } | undefined) => {
        const { uart_cmd_edit } = this.state;
        if (typeof uart_idx !== 'undefined') {
            return (uart_cmd_edit[uart_idx]) ? uart_cmd_edit[uart_idx][evt_idx] : undefined;
        } else {
            return undefined;
        }
    }
    render() {
        const { evt_cec_select, evt_uart_select, } = this.state;
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        const cecEventParam = this.instance.CECEventEnableParam();
        const cecEventDelayParam = this.instance.CECEventDelayParam();
        const cecEventWaitParam = this.instance.CECEventWaitParam();
        const uartEventParam = this.instance.UARTEventEnableParam();
        const uartEventDelayParam = this.instance.UARTEventDelayParam();
        const uartEventWaitParam = this.instance.UARTEventWaitParam();

        const cec_evt_opt = (cecEventParam.x1.isOption) ? cecEventParam.x1.isOption.option : [];
        const cec_evt_desc = (cecEventParam.x1.isOption) ? cecEventParam.x1.isOption.desc : [];
        const cec_evt_type_opt = (cecEventParam.n1.isOption) ? cecEventParam.n1.isOption.option : [];
        const cec_evt_type_desc = (cecEventParam.n1.isOption) ? cecEventParam.n1.isOption.desc : [];
        const cec_evt_state_opt = (cecEventParam.b1.isOption) ? cecEventParam.b1.isOption.option : [];
        const cec_evt_state_desc = (cecEventParam.b1.isOption) ? cecEventParam.b1.isOption.desc : [];
        const uart_evt_opt = (uartEventParam.x1.isOption) ? uartEventParam.x1.isOption.option : [];
        const uart_evt_desc = (uartEventParam.x1.isOption) ? uartEventParam.x1.isOption.desc : [];
        const uart_evt_type_opt = (uartEventParam.n1.isOption) ? uartEventParam.n1.isOption.option : [];
        const uart_evt_type_desc = (uartEventParam.n1.isOption) ? uartEventParam.n1.isOption.desc : [];
        const uart_evt_state_opt = (uartEventParam.b1.isOption) ? uartEventParam.b1.isOption.option : [];
        const uart_evt_state_desc = (uartEventParam.b1.isOption) ? uartEventParam.b1.isOption.desc : [];
        let cec_evt_delay_opt: Array<string> = [];
        let cec_evt_wait_opt: Array<string> = [];
        let uart_evt_delay_opt: Array<string> = [];
        let uart_evt_wait_opt: Array<string> = [];

        if (cecEventDelayParam.n2.isRange) {
            const { max, min, step } = cecEventDelayParam.n2.isRange.range;
            cec_evt_delay_opt = Array.from(Array((max - min) / step + 1)).map((_, v) => (min + step * v).toString());
        }
        if (cecEventDelayParam.n2.isOption) {
            cec_evt_delay_opt = cecEventDelayParam.n2.isOption.option;
        }
        if (cecEventWaitParam.n2.isRange) {
            const { max, min, step } = cecEventWaitParam.n2.isRange.range;
            cec_evt_wait_opt = Array.from(Array((max - min) / step + 1)).map((_, v) => (min + step * v).toString());
        }
        if (cecEventWaitParam.n2.isOption) {
            cec_evt_wait_opt = cecEventWaitParam.n2.isOption.option;
        }
        if (uartEventDelayParam.n2.isRange) {
            const { max, min, step } = uartEventDelayParam.n2.isRange.range;
            uart_evt_delay_opt = Array.from(Array((max - min) / step + 1)).map((_, v) => (min + step * v).toString());
        }
        if (uartEventDelayParam.n2.isOption) {
            uart_evt_delay_opt = uartEventDelayParam.n2.isOption.option;
        }
        if (uartEventWaitParam.n2.isRange) {
            const { max, min, step } = uartEventWaitParam.n2.isRange.range;
            uart_evt_wait_opt = Array.from(Array((max - min) / step + 1)).map((_, v) => (min + step * v).toString());
        }
        if (uartEventWaitParam.n2.isOption) {
            uart_evt_wait_opt = uartEventWaitParam.n2.isOption.option;
        }

        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FEATURE_CEC')} style={notHiddenIf(this.instance.IsSupportCECEventEnable())} defaultExtend={true}>
                    {cec_evt_type_opt.map((evt_id, idx) => {
                        const cec_idx = (evt_cec_select[evt_id]) ? evt_cec_select[evt_id].idx : undefined;
                        const evt_enable_show_name = cec_evt_type_desc ? `${cec_evt_type_desc[idx]} ${getText('EVENT_LABEL')}` : `${getText('EVENT_LABEL')} ${evt_id}`;
                        const evt_state = (cec_idx) ? this.instance.CECEventEnable(evt_id, cec_idx) : 'off';
                        const evt_delay = (cec_idx) ? this.instance.CECEventDelay(evt_id, cec_idx) : '0';
                        const evt_wait = (cec_idx) ? this.instance.CECEventWait(evt_id, cec_idx) : '0';
                        const evt_cmd = (cec_idx) ? this.instance.CECEventCommand(evt_id, cec_idx) : '';
                        const evt_cmd_editor = this.getCECInputEditor(cec_idx, evt_id);

                        return (
                            <Form.Form labelStyle={{ width: '180px' }} key={`cec_evt_${evt_id}_form`}>
                                <Form.Item label={evt_enable_show_name}>
                                    {(cec_idx) ? <SwitchButton
                                        checked={((cec_evt_state_opt.length >= 2)?(evt_state === cec_evt_state_opt[0]):false)}
                                        label={(cec_evt_state_desc?[ cec_evt_state_desc[0], cec_evt_state_desc[1] ]:[ cec_evt_state_opt[0], cec_evt_state_opt[1] ])}
                                        onChange={(v) => {
                                            this.instance.SetCECEventEnable(evt_id, cec_idx, v.target.checked ? cec_evt_state_opt[0] : cec_evt_state_opt[1]);
                                        }}
                                    /> : undefined}
                                    <Select placeholder='Select CEC' value={(cec_idx ? cec_idx : '')} onChange={(v) => { this.onChangeCECSelect(evt_id, v); }}>
                                        {cec_evt_opt.map((cec_id, idx) => <Option value={cec_id} key={`cec_evt_opt_${cec_id}`}>{(cec_evt_desc ? cec_evt_desc[idx] : cec_id)}</Option>)}
                                    </Select>
                                </Form.Item>
                                {(cec_idx && this.instance.IsSupportCECEventDelay()) ? <Form.Item label={getText('DELAY_SEC')}><Select placeholder='Select delay' value={evt_delay} onChange={(v) => { this.instance.SetCECEventDelay(evt_id, cec_idx, v); }}>
                                    {cec_evt_delay_opt.map((opt) => <Option value={opt} key={`cec_${cec_idx}_evt_${evt_id}_delay_${opt}`}>{opt}</Option>)}
                                </Select></Form.Item> : undefined}
                                {(cec_idx && this.instance.IsSupportCECEventWait()) ? <Form.Item label={getText('WAIT')}><Select placeholder='Select wait' value={evt_wait} onChange={(v) => { this.instance.SetCECEventWait(evt_id, cec_idx, v); }}>
                                    {cec_evt_wait_opt.map((opt) => <Option value={opt} key={`cec_${cec_idx}_evt_${evt_id}_wait_${opt}`}>{opt}</Option>)}
                                </Select></Form.Item> : undefined}
                                {(cec_idx && (this.instance.IsSupportCECEventCommandTest() || this.instance.IsSupportCECEventCommand())) ? <Form.Item label={getText('COMMAND')} error={(evt_cmd_editor ? evt_cmd_editor.error : undefined)}>
                                    <Input placeholder="HEX data" value={(evt_cmd_editor ? evt_cmd_editor.text : evt_cmd)} onChange={(e) => { this.onChangeCECEventCommand(cec_idx, evt_id, e); }} />
                                    <Button style={notHiddenIf(this.instance.IsSupportCECEventCommand())} type='primary' onClick={() => { this.onSaveCECEventCommand(cec_idx, evt_id); }} disabled={!(evt_cmd_editor && evt_cmd_editor.error.length === 0)}>{getText('SAVE')}</Button>
                                    <Button style={notHiddenIf(this.instance.IsSupportCECEventCommandTest())} onClick={() => { this.onTestCECEventCommand(cec_idx, evt_id); }} disabled={(evt_cmd_editor && evt_cmd_editor.error.length > 0)}>{getText('TEST')}</Button>
                                </Form.Item> : undefined}
                            </Form.Form>
                        );
                    })}
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_IO_UART_LABEL')} style={notHiddenIf(this.instance.IsSupportUARTEventEnable())}>
                    {uart_evt_type_opt.map((evt_id, idx) => {
                        const uart_idx = (evt_uart_select[evt_id]) ? evt_uart_select[evt_id].idx : undefined;
                        const evt_enable_show_name = uart_evt_type_desc ? `${uart_evt_type_desc[idx]} ${getText('EVENT_LABEL')}` : `${getText('EVENT_LABEL')} ${evt_id}`;
                        const evt_state = (uart_idx) ? this.instance.UARTEventEnable(evt_id, uart_idx) : 'off';
                        const evt_delay = (uart_idx) ? this.instance.UARTEventDelay(evt_id, uart_idx) : '0';
                        const evt_wait = (uart_idx) ? this.instance.UARTEventWait(evt_id, uart_idx) : '0';
                        const evt_cmd = (uart_idx) ? this.instance.UARTEventCommand(evt_id, uart_idx) : '';
                        const evt_cmd_editor = this.getUARTInputEditor(uart_idx, evt_id);
                        return (
                            <Form.Form labelStyle={{ width: '180px' }} key={`uart_evt_${evt_id}_form`}>
                                <Form.Item label={evt_enable_show_name}>
                                    {(uart_idx) ? <SwitchButton
                                        checked={((uart_evt_state_opt.length >= 2)?(evt_state === uart_evt_state_opt[0]):false)}
                                        label={(uart_evt_state_desc?[ uart_evt_state_desc[0], uart_evt_state_desc[1] ]:[ uart_evt_state_opt[0], uart_evt_state_opt[1] ])}
                                        onChange={(v) => {
                                            this.instance.SetUARTEventEnable(evt_id, uart_idx, v.target.checked ? uart_evt_state_opt[0] : uart_evt_state_opt[1]);
                                        }}
                                    /> : undefined}
                                    <Select placeholder='Select UART' value={(uart_idx ? uart_idx : '')} onChange={(v) => { this.onChangeUARTSelect(evt_id, v); }}>
                                        {uart_evt_opt.map((uart_id, idx) => <Option value={uart_id} key={`uart_evt_opt_${uart_id}`}>{(uart_evt_desc ? uart_evt_desc[idx] : uart_id)}</Option>)}
                                    </Select>
                                </Form.Item>
                                {(uart_idx && this.instance.IsSupportUARTEventDelay()) ? <Form.Item label={getText('DELAY_SEC')}><Select placeholder='Select delay' value={evt_delay} onChange={(v) => { this.instance.SetUARTEventDelay(evt_id, uart_idx, v); }}>
                                    {uart_evt_delay_opt.map((opt) => <Option value={opt} key={`uart_${uart_idx}_evt_${evt_id}_delay_${opt}`}>{opt}</Option>)}
                                </Select></Form.Item> : undefined}
                                {(uart_idx && this.instance.IsSupportUARTEventWait()) ? <Form.Item label={getText('WAIT')}><Select placeholder='Select wait' value={evt_wait} onChange={(v) => { this.instance.SetUARTEventWait(evt_id, uart_idx, v); }}>
                                    {uart_evt_wait_opt.map((opt) => <Option value={opt} key={`uart_${uart_idx}_evt_${evt_id}_wait_${opt}`}>{opt}</Option>)}
                                </Select></Form.Item> : undefined}
                                {(uart_idx && (this.instance.IsSupportUARTEventCommandTest() || this.instance.IsSupportUARTEventCommand())) ? <Form.Item label={getText('COMMAND')} error={(evt_cmd_editor ? evt_cmd_editor.error : undefined)}>
                                    <Input placeholder="Command" value={(evt_cmd_editor ? evt_cmd_editor.text : evt_cmd)} onChange={(e) => { this.onChangeUARTEventCommand(uart_idx, evt_id, e); }} />
                                    <Button style={notHiddenIf(this.instance.IsSupportUARTEventCommand())} type='primary' onClick={() => { this.onSaveUARTEventCommand(uart_idx, evt_id); }} disabled={!(evt_cmd_editor && evt_cmd_editor.error.length === 0)}>{getText('SAVE')}</Button>
                                    <Button style={notHiddenIf(this.instance.IsSupportUARTEventCommandTest())} onClick={() => { this.onTestUARTEventCommand(uart_idx, evt_id); }} disabled={(evt_cmd_editor && evt_cmd_editor.error.length > 0)}>{getText('TEST')}</Button>
                                </Form.Item> : undefined}
                            </Form.Form>
                        );
                    })}
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

class PowerMonitorView extends CsxUI.IRQComponent<any> {
    instance: CsxFeature.CsxPowerMonitorDevice
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'DeviceRealTime';
        this.MIN_RENDER_INTERVAL = 100;
        this.instance = new CsxFeature.CsxPowerMonitorDevice(window.FOCUS_DEVICE);
    }
    render() {
        const inPowerCurrentParam = this.instance.InPowerCurrentParam();
        const inPowerVoltageParam = this.instance.InPowerVoltageParam();
        const inTemperatureParam = this.instance.InTemperatureParam();
        const cur_in_opt = (inPowerCurrentParam.n1.isOption) ? inPowerCurrentParam.n1.isOption.option : [];
        const cur_in_desc = (inPowerCurrentParam.n1.isOption) ? inPowerCurrentParam.n1.isOption.desc : [];
        const vol_in_opt = (inPowerVoltageParam.n1.isOption) ? inPowerVoltageParam.n1.isOption.option : [];
        // const vol_in_desc = (inPowerVoltageParam.n1.isOption)?inPowerVoltageParam.n1.isOption.desc:[];
        const tem_in_opt = (inTemperatureParam.n1.isOption) ? inTemperatureParam.n1.isOption.option : [];
        const tem_in_desc = (inTemperatureParam.n1.isOption) ? inTemperatureParam.n1.isOption.desc : [];

        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_PM_POWER_LABEL')} style={notHiddenIf(this.instance.IsSupportInPowerCurrent() && this.instance.IsSupportInPowerVoltage())} defaultExtend={true}>
                    <Table
                        headers={(cur_in_desc) ? cur_in_desc : cur_in_opt}
                        rows={[((cur_in_opt.length > vol_in_opt.length) ? cur_in_opt : vol_in_opt).map((opt) => {
                            const cur_in_val = parseInt(this.instance.InPowerCurrent(opt));
                            const vol_in_val = parseInt(this.instance.InPowerVoltage(opt));
                            return `${cur_in_val} A/${vol_in_val} V`;
                        })]}
                    />
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_PM_TEMPERATIRE_LABEL')} style={notHiddenIf(this.instance.IsSupportInTemperature())}>
                    <Table
                        headers={(tem_in_desc) ? tem_in_desc : tem_in_opt}
                        rows={[tem_in_opt.map((opt) => {
                            const tem_in_val = parseInt(this.instance.InTemperature(opt));
                            return `${tem_in_val} ℃`;
                        })]}
                    />
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

class CECView extends CsxUI.IRQComponent<any> {
    state: { in_s: string; out_s: string; in_data: string; out_data: string; in_data_err: string; out_data_err: string; in_mode: 'raw' | 'str'; out_mode: 'raw' | 'str' }
    instance: CsxFeature.CsxCECDevice
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'DeviceRealTime';
        this.instance = new CsxFeature.CsxCECDevice(window.FOCUS_DEVICE);
        this.state = {
            in_s: '', out_s: '', in_data: '', out_data: '', in_data_err: '', out_data_err: '',
            in_mode: (this.instance.IsSupportSetInCECMenu() ? 'str' : 'raw'),
            out_mode: (this.instance.IsSupportSetOutCECMenu() ? 'str' : 'raw'),
        };
    }
    validateOutData = (value: string) => {
        const outCECDataParam = this.instance.SetOutCECDataParam();
        const ok = !(outCECDataParam.hh.isString && !this.validateString(value, outCECDataParam.hh.isString));
        this.setState({ out_data_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    validateInData = (value: string) => {
        const inCECDataParam = this.instance.SetInCECDataParam();
        const ok = !(inCECDataParam.hh.isString && !this.validateString(value, inCECDataParam.hh.isString));
        this.setState({ in_data_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    onChangeInData = (e: React.ChangeEvent<HTMLInputElement>) => { this.validateInData(e.target.value); this.setState({ in_data: e.target.value, in_data_changed: true }); }
    onChangeOutData = (e: React.ChangeEvent<HTMLInputElement>) => { this.validateOutData(e.target.value); this.setState({ out_data: e.target.value, out_data_changed: true }); }
    onChangeInMode = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ in_mode: (e.target.checked ? 'str' : 'raw') }); }
    onChangeOutMode = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ out_mode: (e.target.checked ? 'str' : 'raw') }); }
    onChangeInSelect = (value: string) => { this.setState({ in_s: value }); }
    onChangeOutSelect = (value: string) => { this.setState({ out_s: value }); }
    onSaveInData = () => {
        if (this.instance.IsSupportSetInCECMenu())
            this.instance.SetInCECMenu(this.state.in_s, this.state.in_data);
        else
            this.instance.SetInCECData(this.state.in_s, this.state.in_data);
        this.setState({ in_data_err: '' });
    }
    onSaveOutData = () => {
        if (this.instance.IsSupportSetOutCECMenu())
            this.instance.SetOutCECMenu(this.state.out_s, this.state.out_data);
        else
            this.instance.SetOutCECData(this.state.out_s, this.state.out_data);
        this.setState({ out_data_err: '' });
    }
    render() {
        const { out_data, out_s, in_data, in_s, in_mode, out_mode, in_data_err, out_data_err } = this.state;
        const inCECDataParam = this.instance.SetInCECDataParam();
        const outCECDataParam = this.instance.SetOutCECDataParam();
        const in_opt = (inCECDataParam.n1.isOption) ? inCECDataParam.n1.isOption.option : [];
        const in_desc = (inCECDataParam.n1.isOption) ? inCECDataParam.n1.isOption.desc : [];
        const out_opt = (outCECDataParam.x1.isOption) ? outCECDataParam.x1.isOption.option : [];
        const out_desc = (outCECDataParam.x1.isOption) ? outCECDataParam.x1.isOption.desc : [];
        // validate input
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_CEC_LABEL')} style={notHiddenIf(this.instance.IsSupportSetOutCECData() || this.instance.IsSupportSetInCECData())} defaultExtend={true}>
                    <Flat.Field title={getText('DEVICE_FUNC_CEC_IN_LABEL')}>
                        <Form.Form labelStyle={{ width: '150px' }}>
                            <Form.Item label={getText('DEVICE_FUNC_CEC_IN_PORT')} style={notHiddenIf(!!inCECDataParam.n1.isOption)}><Select placeholder='CEC In Port' value={in_s} onChange={this.onChangeInSelect}>
                                {in_opt.map((opt, idx) => <Option value={opt} key={`in_opt_${opt}`}>{(in_desc) ? in_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_CEC_IN_AUTO_HEXENC')} style={notHiddenIf(this.instance.IsSupportSetInCECMenu())}>
                                <SwitchButton checked={in_mode === 'str'} onChange={this.onChangeInMode} label={[ 'ON', 'OFF' ]}/>
                            </Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_CEC_IN_DATA')} error={in_data_err} disabled={in_s.length === 0}>
                                <Input placeholder={(in_mode === 'str') ? 'CEC String' : 'Hex CEC Data'} value={in_data} onChange={this.onChangeInData} />
                                <Button type='primary' onClick={this.onSaveInData} disabled={(in_data_err.length > 0)}>Send</Button>
                            </Form.Item>
                        </Form.Form>
                    </Flat.Field>
                    <Flat.Field title={getText('DEVICE_FUNC_CEC_OUT_LABEL')}>
                        <Form.Form labelStyle={{ width: '150px' }}>
                            <Form.Item label={getText('DEVICE_FUNC_CEC_OUT_PORT')} style={notHiddenIf(!!outCECDataParam.x1.isOption)}><Select placeholder='CEC Out Port' value={out_s} onChange={this.onChangeOutSelect}>
                                {out_opt.map((opt, idx) => <Option value={opt} key={`out_opt_${opt}`}>{(out_desc) ? out_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_CEC_OUT_AUTO_HEXENC')} style={notHiddenIf(this.instance.IsSupportSetOutCECMenu())}>
                                <SwitchButton checked={out_mode === 'str'} onChange={this.onChangeOutMode} label={[ 'ON', 'OFF' ]} />
                            </Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_CEC_OUT_DATA')} error={out_data_err} disabled={out_s.length === 0}>
                                <Input placeholder={(out_mode === 'str') ? 'CEC String' : 'Hex CEC Data'} value={out_data} onChange={this.onChangeOutData} />
                                <Button type='primary' onClick={this.onSaveOutData} disabled={out_data_err.length > 0}>Send</Button>
                            </Form.Item>
                        </Form.Form>
                    </Flat.Field>
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

declare type SOFTWARE_UPDATE_AUTHENTICATION_METHOD = 'none' | 'basic';

class SoftwareView extends CsxUI.IRQComponent<any> {
    state: { use_auth: SOFTWARE_UPDATE_AUTHENTICATION_METHOD, username_in: string, password_in: string } = { use_auth: 'none', password_in: '', username_in: '' };
    instance: CsxFeature.CsxUpgradableDevice
    feature_list: Array<Feature>
    fileSelector: HTMLInputElement | null = null;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'DeviceRealTime';
        this.instance = new CsxFeature.CsxUpgradableDevice(window.FOCUS_DEVICE);
        this.feature_list = [
            { name: 'Frimware Upgrade', check: this.instance.IsSupportFWVersion },
            { name: 'Fileless Upgrade', check: this.instance.IsSupportFilelessUpgrade },
        ];
    }
    triggerFileSelector = () => {
        if (window.CSX_CUR_AUTH) {
            if (!csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('fwup_control'), CsxUserPermissionLevel.FullAccess)) {
                Notify({ title: getText('NOTIFY_TITLE_ERROR'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' });
                return;
            }
        }
        if (this.fileSelector)
            this.fileSelector.click();
        else
            window.alert(getText('ALERT_OP_UNSUPPORT_NATIVE_OUT'));
    }
    fwUpdate = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const fw_file = (event.target.files) ? event.target.files[0] : undefined;
        if (fw_file) {
            window.userConfirm(getText('CONFIRM_FWUP_SINGLE_DEVICE'), async (ok) => {
                if (ok && window.FOCUS_GATEWAY) {
                    try {
                        await window.FOCUS_GATEWAY.deviceFirmwareUpdate(fw_file, [this.instance.ID]);
                    } catch (error) {
                        console.log('[draw.tsx->SoftwareView->fwUpdate] error :>> ', error);
                    }
                }
            });
        }
        event.target.value = '';
    }
    onChangeUsername = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ username_in: e.target.value }); }
    onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ password_in: e.target.value }); }
    onChangeUseAuth = (v: string) => { this.setState({ use_auth: v }); }
    componentDidIRQUpdate() {
        const fw_upgrade_progress = this.instance.FWUpgradeProgress(0);
        const progress_num = parseInt(fw_upgrade_progress);
        if (progress_num === 100 && CsxUtil.isCsxSystemStateSync(this.instance.sys_status)) {
            this.instance.CleanUpgrade(0);
        }
    }
    render() {
        const { use_auth, password_in, username_in } = this.state;
        const fw_ver = this.instance.FWVersion();
        const cmd_ver = this.instance.CMDVersion();
        const fw_upgrade_progress = this.instance.FWUpgradeProgress(0);
        const progress_num = parseInt(this.instance.FWUpgradeProgress(0));
        const disable_operation = (!isNaN(progress_num) && progress_num !== 100);
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_FW_LABEL')} defaultExtend={true}>
                    <div className='software_desc_wrapper' style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', height: '100px', color: '#008aab' }}>
                        <div className='fw_ver' style={{ width: '130px', display: 'flex', flexDirection: 'column', alignItems: 'center', marginRight: '20px' }}>
                            <div className='value' style={{ fontSize: '3em' }}>{fw_ver}</div>
                            <div className='title' style={{ fontSize: '1em' }}>{getText('FW_VER')}</div>
                        </div>
                        <div className='cmd_ver' style={{ width: '130px', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                            <div className='value' style={{ fontSize: '3em' }}>{cmd_ver}</div>
                            <div className='title' style={{ fontSize: '1em' }}>{getText('CMD_VER')}</div>
                        </div>
                    </div>
                    <Form.Item style={notHiddenIf(this.instance.IsSupportFWUpgradeProgress())}>
                        {!window.APP_ON_HDMI ? <input type='file' ref={(inst) => { this.fileSelector = inst; }} onChange={this.fwUpdate} style={{ display: 'none' }} /> : undefined}
                        <Button shape='round' size='small' icon='upload' style={{ background: 'none' }} disabled={disable_operation} onClick={this.triggerFileSelector}>Firmware Update</Button>
                        <ProgressBar
                            className='fwup_prog'
                            hint={`${getText('HINT_DONT_CUT_OFF_POWER')}... ${fw_upgrade_progress}%`}
                            percentage={(isNaN(progress_num) ? 0 : progress_num)}
                            style={{ opacity: ((isNaN(progress_num)) ? 0 : 1) }}
                        />
                    </Form.Item>
                    <p style={{ color: 'gray', display: (this.instance.IsSupportFWUpgradeProgress() ? 'none' : undefined) }}>Device currently not support firmware update function.</p>
                    <Form.Item label={getText('DEVICE_FUNC_FW_AUTH')} labelStyle={{ width: '130px' }} style={notHiddenIf(this.instance.IsSupportFWUpgradeProgress())}>
                        <RadioGroup value={use_auth} onChange={this.onChangeUseAuth} options={[{ label: 'None', value: 'none', disabled: disable_operation }, { label: 'Basic', value: 'basic', disabled: true }]}/>
                    </Form.Item>
                    <Form.Form style={notHiddenIf(use_auth === 'basic')} labelStyle={{ width: '130px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_FW_AUTH_USERNAME')}><Input disabled={disable_operation} value={username_in} onChange={this.onChangeUsername}/></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_FW_AUTH_PASSWORD')}><Input disabled={disable_operation} value={password_in} onChange={this.onChangePassword} type='password' /></Form.Item>
                    </Form.Form>
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

class RouterView extends CsxUI.IRQComponent<any> {
    state: {
        out_s: string;
        dialog: '' | 'preset_name';
        preset_s: string;
        modal_title: string;
        // src_s: string;
        buffer: string;
        // out_name_err: string;
        // src_name_err: string;
        prst_name_err: string;
        src_name_buffer: { [src_opt: string]: { name_string?: string, name_error: string } };
        out_name_buffer: { [out_opt: string]: { name_string?: string, name_error: string } };
    }
    instance: CsxFeature.CsxRoutableDevice;
    scaler: CsxFeature.CsxScalerDevice;
    constructor(props: any) {
        super(props);
        this.state = {
            out_s: '',
            dialog: '',
            preset_s: '1',
            modal_title: '',
            // src_s: '1',
            buffer: '',
            // out_name_err: '',
            prst_name_err: '',
            // src_name_err: '',
            src_name_buffer: {},
            out_name_buffer: {},
        };
        this.IRQIndex = 'DeviceRealTime';
        this.MIN_RENDER_INTERVAL = 100;
        this.instance = new CsxFeature.CsxRoutableDevice(window.FOCUS_DEVICE);
        this.scaler = new CsxFeature.CsxScalerDevice(window.FOCUS_DEVICE);
    }
    validateOutName = (value: string): string => {
        const outRouteNameParam = this.instance.OutRouteNameParam();
        const ok = !(outRouteNameParam.s1.isString && !this.validateString(value, outRouteNameParam.s1.isString));
        // this.setState({ out_name_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        // return ok;
        return (ok?'':getText('HINT_FORMAT_NOT_MATCH'));
    }
    validateSrcName = (value: string): string => {
        const inRouteNameParam = this.instance.InRouteNameParam();
        const ok = !(inRouteNameParam.s1.isString && !this.validateString(value, inRouteNameParam.s1.isString));
        // this.setState({ src_name_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        // return ok;
        return (ok?'':getText('HINT_FORMAT_NOT_MATCH'));
    }
    validatePresetName = (value: string) => {
        const presetNameParam = this.instance.PresetNameParam();
        const ok = !(presetNameParam.s1.isString && !this.validateString(value, presetNameParam.s1.isString));
        this.setState({ prst_name_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    closeEdit = () => { this.setState({ dialog: '', buffer: '' }); }
    // editOutName = (out_idx: string) => { this.setState({ out_s: out_idx, dialog: 'out_name', buffer: this.instance.OutRouteName(out_idx), modal_title: `Edit Output ${out_idx} Name` }); }
    editOutName = (out_idx: string) => { this.setState({ out_s: out_idx, dialog: 'out_name', buffer: this.instance.OutRouteName(out_idx), modal_title: getText('DEVICE_MODAL_EDIT_ROUTE_OUT_NAME') }); }
    // editSourceName = () => { this.setState({ dialog: 'src_name', buffer: this.instance.InRouteName(this.state.src_s), modal_title: `Edit Source ${this.state.src_s} Name` }); }
    // editPresetName = () => { this.setState({ dialog: 'preset_name', buffer: this.instance.PresetName(this.state.preset_s), modal_title: `Edit Preset ${this.state.preset_s} Name` }); }
    editPresetName = () => { this.setState({ dialog: 'preset_name', buffer: this.instance.PresetName(this.state.preset_s), modal_title: getText('DEVICE_MODAL_EDIT_ROUTE_PRESET_NAME') }); }
    onChangeSource = (out_idx: string | number, src_idx: string | number) => { this.instance.SetOutRouteSrc(out_idx, src_idx); }
    onChangeOutName = (out_idx: string, value: string) => {
        const { out_name_buffer } = this.state;
        const clone_buffer = CsxUtil.nestedClone(out_name_buffer);
        const name_error = this.validateOutName(value);
        if (name_error.length === 0 || value.length === 0) {
            clone_buffer[out_idx] = { name_string: value, name_error: '' };
        } else {
            if (clone_buffer[out_idx]) {
                clone_buffer[out_idx].name_error = name_error;
            } else {
                clone_buffer[out_idx] = { name_error };
            }
        }
        this.setState({ out_name_buffer: clone_buffer });
    }
    onChangeSrcName = (src_idx: string, value: string) => {
        const { src_name_buffer } = this.state;
        const clone_buffer = CsxUtil.nestedClone(src_name_buffer);
        const name_error = this.validateSrcName(value);
        if (name_error.length === 0 || value.length === 0) {
            clone_buffer[src_idx] = { name_string: value, name_error: '' };
        } else {
            if (clone_buffer[src_idx]) {
                clone_buffer[src_idx].name_error = name_error;
            } else {
                clone_buffer[src_idx] = { name_error };
            }
        }
        this.setState({ src_name_buffer: clone_buffer });
    }
    onChangePresetName = (e: React.ChangeEvent<HTMLInputElement>) => { if (this.validatePresetName(e.target.value) || e.target.value.length === 0) this.setState({ buffer: e.target.value }); }
    onChangeOutMask = (out_idx: string, value: boolean) => {
        const outMaskParam = this.instance.OutMaskParam();
        const out_mask_opt = (outMaskParam.b1.isOption) ? outMaskParam.b1.isOption.option : [];
        if (out_mask_opt.length >= 2) {
            this.instance.SetOutMask(out_idx, value ? out_mask_opt[0] : out_mask_opt[1]);
        }
    }
    onChangePresetSelect = (value: string) => { this.setState({ preset_s: value }); }
    onChangeSourceSelect = (value: string) => { this.setState({ src_s: value }); }
    // onSaveOutName = () => { this.instance.SetOutRouteName(this.state.out_s, this.state.buffer); this.setState({ dialog: '', out_name_err: '' }); }
    onSaveOutName = (out_idx: string) => {
        const { out_name_buffer } = this.state;
        const clone_buffer = CsxUtil.nestedClone(out_name_buffer);

        if (clone_buffer[out_idx]) {
            const { name_string } = clone_buffer[out_idx];
            if (name_string) {
                this.instance.SetOutRouteName(out_idx, name_string);
            }
            delete clone_buffer[out_idx];
        }
        this.setState({ out_name_buffer: clone_buffer });
    }
    onSaveSrcName = (src_idx: string) => {
        const { src_name_buffer } = this.state;
        const clone_buffer = CsxUtil.nestedClone(src_name_buffer);

        if (clone_buffer[src_idx]) {
            const { name_string } = clone_buffer[src_idx];
            if (name_string) {
                this.instance.SetInRouteName(src_idx, name_string);
            }
            delete clone_buffer[src_idx];
        }
        this.setState({ src_name_buffer: clone_buffer });
    }
    onSavePresetName = () => { this.instance.SetPresetName(this.state.preset_s, this.state.buffer); this.setState({ dialog: '', prst_name_err: '' }); }
    render() {
        const { dialog, preset_s, modal_title, src_name_buffer, out_name_buffer, prst_name_err, buffer } = this.state;
        // const presetNameParam = this.instance.PresetNameParam();
        const presetParam = this.instance.PresetParam();
        const outRouteSrcParam = this.instance.OutRouteSrcParam();
        const outMaskParam = this.instance.OutMaskParam();
        const out_opt = (outRouteSrcParam.x1.isOption) ? outRouteSrcParam.x1.isOption.option : [];
        const out_desc = (outRouteSrcParam.x1.isOption) ? outRouteSrcParam.x1.isOption.desc : [];
        const src_opt = (outRouteSrcParam.n1.isOption) ? outRouteSrcParam.n1.isOption.option : [];
        const src_desc = (outRouteSrcParam.n1.isOption) ? outRouteSrcParam.n1.isOption.desc : [];
        // const prst_name_opt = (presetNameParam.n1.isOption) ? presetNameParam.n1.isOption.option : [];
        // const prst_name_desc = (presetNameParam.n1.isOption) ? presetNameParam.n1.isOption.desc : [];
        const prst_opt = (presetParam.n1.isOption) ? presetParam.n1.isOption.option : [];
        const prst_desc = (presetParam.n1.isOption) ? presetParam.n1.isOption.desc : [];
        const out_mask_opt = (outMaskParam.b1.isOption) ? outMaskParam.b1.isOption.option : [];
        const out_mask_desc = (outMaskParam.b1.isOption) ? outMaskParam.b1.isOption.desc : [];
        const out_src = out_opt.map(opt => this.instance.OutRouteSrc(opt));
        const out_mask = out_opt.map(opt => this.instance.OutMask(opt));
        const out_name_opt = out_opt.map(opt => this.instance.OutRouteName(opt));
        const src_name_opt = src_opt.map(opt => this.instance.InRouteName(opt));
        // validate input
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));

        /* Preset label */
        const edit_icon_style = notHiddenIf(this.instance.IsSupportPresetNaming());
        edit_icon_style.marginBottom = '3px';
        const preset_label = (
            <div style={{ display: 'flex', position: 'relative', flexDirection: 'row', justifyContent: 'space-between', width: 'calc(100% - 10px)', alignItems: 'center' }}>
                <span style={{ width: 'calc(100% - 20px)' }}>Preset</span>
                <Icon type='write' color='#008aab' style={edit_icon_style} onClick={this.editPresetName}/>
            </div>
        );

        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_ROUTE_SWITCH_LABEL')} style={notHiddenIf(this.instance.IsSupportRouting())} defaultExtend={true}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <div style={{ position: 'relative', width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center', height: '30px' }}>
                            <div style={{ height: '100%', backgroundColor: '#66b6ca', paddingLeft: '5px', width: '160px', marginRight: '3px', color: 'white', lineHeight: '30px', fontWeight: 'bold' }}>{getText('DEVICE_FUNC_ROUTE_IN_LABEL')}</div>
                            <div style={{ height: '100%', backgroundColor: '#66b6ca', paddingLeft: '5px', flexGrow: 3, color: 'white', lineHeight: '30px', fontWeight: 'bold' }}>{getText('DEVICE_FUNC_ROUTE_OUT_LABEL')}</div>
                        </div>
                        {out_opt.map((out, idx) => {
                            let label = <span className='text'>{(out_desc) ? out_desc[idx] : `Output ${out}`}</span>;
                            if (out_name_opt[idx].length > 0) { label = <span className='text'>{out_name_opt[idx]}</span>; }
                            // const label_node = <span className='icon_label'>{label}<Icon color='#008aab' style={notHiddenIf(this.instance.IsSupportOutNaming())} type='write' onClick={() => { this.editOutName(`${out}`); }} /></span>
                            return <Form.Item key={`matrix_formitem_${out}`} label={label}><Select placeholder='No Source' value={out_src[idx]} onChange={(v) => { this.onChangeSource(out, v); }}>
                                {src_opt.map((src, src_idx) => {
                                    let src_showname = (src_desc) ? src_desc[src_idx] : `Source ${src}`;
                                    if (src_name_opt[src_idx].length > 0) { src_showname = src_name_opt[src_idx]; }
                                    if (this.scaler.IsSupportInResolutionRead()) {
                                        const inInterlaceParam = this.scaler.InInterlaceParam();
                                        const inRefreshRateParam = this.scaler.InRefreshRateParam();
                                        const h = this.scaler.InHActive(src);
                                        const v = this.scaler.InVActive(src);
                                        const interlaced = (inInterlaceParam.n2.isOption) ? ((inInterlaceParam.n2.isOption.option[0] === this.scaler.InInterlace(src)) ? 'i' : 'p') : '?';
                                        const rr = (inRefreshRateParam.n2.isOption && inRefreshRateParam.n2.isOption.desc) ? inRefreshRateParam.n2.isOption.desc[inRefreshRateParam.n2.isOption.option.indexOf(this.scaler.InRefreshRate(src))] : '?';
                                        if (h !== '0' && v !== '0' && interlaced !== '?' && rr !== '?')
                                            src_showname += ` (${h}x${v}${interlaced}${rr})`;
                                    }
                                    return <Option key={`src_option_${out}_${src}`} value={src}>{src_showname}</Option>;
                                })}
                            </Select></Form.Item>
                        })}
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_ROUTE_RENAME_LABEL')} style={notHiddenIf(this.instance.IsSupportInNaming())}>
                    <Flat.Field title={getText('DEVICE_FUNC_ROUTE_IN_LABEL')}>
                        <Form.Form labelStyle={{ width: '150px' }}>
                            {src_opt.map((src, idx) => {
                                let src_showname = (src_desc) ? src_desc[idx] : `Source ${src}`;
                                if (src_name_opt[idx].length > 0) { src_showname = src_name_opt[idx]; }
                                let edit_buffer = src_name_buffer[src];
                                let edit_string = src_showname;
                                let disable_save = !edit_buffer;
                                if (edit_buffer && typeof edit_buffer.name_string === 'string') {
                                    edit_string = edit_buffer.name_string;
                                    disable_save = (edit_buffer.name_string.length === 0);
                                }
                                return (
                                    <Form.Item label={src_showname} key={`route_in_name_${idx}`} error={(edit_buffer) ? edit_buffer.name_error : ''}>
                                        <Input onChange={(e) => { this.onChangeSrcName(src, e.target.value); }} value={edit_string} />
                                        <Button type='primary' onClick={() => { this.onSaveSrcName(src); }} disabled={disable_save}>{getText('SAVE')}</Button>
                                    </Form.Item>
                                );
                            })}
                        </Form.Form>
                    </Flat.Field>
                    <Flat.Field title={getText('DEVICE_FUNC_ROUTE_OUT_LABEL')}>
                        <Form.Form labelStyle={{ width: '150px' }}>
                            {out_opt.map((out, idx) => {
                                let out_showname = (out_desc) ? out_desc[idx] : `Output ${out}`;
                                if (out_name_opt[idx].length > 0) { out_showname = out_name_opt[idx]; }
                                let edit_buffer = out_name_buffer[out];
                                let edit_string = out_showname;
                                let disable_save = !edit_buffer;
                                if (edit_buffer && typeof edit_buffer.name_string === 'string') {
                                    edit_string = edit_buffer.name_string;
                                    disable_save = (edit_buffer.name_string.length === 0);
                                }
                                return (
                                    <Form.Item label={out_showname} key={`route_out_name_${idx}`} error={(edit_buffer) ? edit_buffer.name_error : ''}>
                                        <Input onChange={(e) => { this.onChangeOutName(out, e.target.value); }} value={edit_string} />
                                        <Button type='primary' onClick={() => { this.onSaveOutName(out); }} disabled={disable_save}>{getText('SAVE')}</Button>
                                    </Form.Item>
                                );
                            })}
                        </Form.Form>
                    </Flat.Field>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_ROUTE_PRESET_LABEL')} style={notHiddenIf(this.instance.IsSupportApplyPreset())}>
                    <Form.Form labelStyle={{ width: '150px', display: 'flex', flexDirection: 'row' }}>
                        <Form.Item label={preset_label}>
                            <Select value={preset_s} onChange={this.onChangePresetSelect} placeholder='Select Preset'>
                                {prst_opt.map((prst, idx) => <Option key={`prst_option_${prst}`} value={prst}>{(prst_desc) ? prst_desc[idx] : `Preset ${prst}`}</Option>)}
                            </Select>
                            <Button type='primary' onClick={() => { this.instance.ApplyPreset(preset_s); }}>{getText('APPLY')}</Button>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_ROUTE_OUT_MASK_LABEL')} style={notHiddenIf(this.instance.IsSupportOutMask())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        {out_opt.map((out, idx) => {
                            const label = <span className='text'>{(out_desc) ? out_desc[idx] : `Output ${out}`}</span>;
                            const label_node = <span className='icon_label'>{label}</span>
                            return <Form.Item key={`matrix_formitem_${out}`} label={label_node}>
                                <SwitchButton checked={((out_mask_opt.length >= 2)?(out_mask[idx] === out_mask_opt[0]):false)} label={(out_mask_desc?[ out_mask_desc[0], out_mask_desc[1] ]:undefined)} onChange={(v) => { this.onChangeOutMask(`${out}`, v.target.checked); }} />
                            </Form.Item>
                        })}
                    </Form.Form>
                </Flat.Section>
                <Modal
                    title={modal_title}
                    visible={dialog.length > 0}
                    onClose={this.closeEdit}
                >
                    {(dialog === 'preset_name') ? <Form.Item error={prst_name_err}><Input placeholder='Type Name' onChange={this.onChangePresetName} value={buffer} /><Button type='primary' disabled={(prst_name_err.length > 0)} onClick={this.onSavePresetName}>{getText('SAVE')}</Button></Form.Item> : undefined}
                </Modal>
            </Flat.Playground >
        );
    }
}

class EDIDView extends CsxUI.IRQComponent<any> {
    state: { edid_s: string; name_changed: boolean; temp_edid_name?: string; edid_name_err: '' }
    instance: CsxFeature.CsxEDIDDevice
    fileSelector: HTMLInputElement | null | undefined;
    fileSelectorFor?: string;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'DeviceRealTime';
        this.instance = new CsxFeature.CsxEDIDDevice(window.FOCUS_DEVICE);
        this.state = { edid_s: 'I7.1', edid_name_err: '', name_changed: false };
    }
    get int_edid_number() {
        const intEdidParam = this.instance.InternalEDIDDataParam();
        const int_edid_opt = (intEdidParam.n1.isOption) ? intEdidParam.n1.isOption.option : [];
        return int_edid_opt.length;
    }
    validateNickname = (value: string) => {
        const usrEdidNameParam = this.instance.UserEDIDNameParam();
        const ok = !(usrEdidNameParam.s1.isString && !this.validateString(value, usrEdidNameParam.s1.isString));
        this.setState({ edid_name_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    onChangeAllEdidMode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const allEdidModeParam = this.instance.AllEDIDModeParam();
        const alledid_mode_opt = (allEdidModeParam.b1.isOption) ? allEdidModeParam.b1.isOption.option : ['all','appoint'];
        if (alledid_mode_opt.length >= 2) {
            this.instance.SetAllEDIDMode((e.target.checked) ? alledid_mode_opt[0] : alledid_mode_opt[1]);
        }
    }
    onChangeEDIDSelect = (value: string) => {
        const edid_idx_walk = value.split('.');
        if (edid_idx_walk[0] === 'I7') {
            this.setState({ edid_s: value, temp_edid_name: this.instance.UserEDIDName(parseInt(edid_idx_walk[1]) + this.int_edid_number) });
        } else {
            this.setState({ edid_s: value, temp_edid_name: undefined });
        }
    }
    onChangeAllEdid = (value: string) => { this.instance.SetAllEDID(value); }
    onChangeUserEDIDName = (event: React.ChangeEvent<HTMLInputElement>) => { if (this.validateNickname(event.target.value) || event.target.value.length === 0) this.setState({ temp_edid_name: event.target.value, name_changed: true }); }
    onSaveUserEDIDName = () => {
        const { edid_s, temp_edid_name } = this.state;
        const edid_idx_walk = edid_s.split('.');
        if (edid_idx_walk[0] === 'I7' && temp_edid_name) {
            this.instance.SetUserEDIDName((parseInt(edid_idx_walk[1]) + this.int_edid_number), temp_edid_name);
            this.setState({ name_changed: false, edid_name_err: '' });
        }
    }
    onDownloadEDID = (edid_data: string) => { CsxUtil.downloadAsEDID('EDID.bin', edid_data); }
    onUploadEDID = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { files } = event.target;
        if (files) {
            const fr = new FileReader();
            fr.onload = () => {
                const content = fr.result;
                let edid_str = '';
                if (typeof content === 'string') {
                    edid_str = Array.from(content).map(ch => {
                        const v = ch.charCodeAt(0);
                        return `${(v < 16) ? '0' : ''}${v.toString(16)}`;
                    }).join(',').toUpperCase();
                    window.userConfirm(getText('CONFIRM_RESET_DEV_EDID_SETUP'), (ok) => {
                        if (ok && this.fileSelectorFor) {
                            this.instance.SetUserEDIDData(this.fileSelectorFor, edid_str);
                            Notify({ title: getText('NOTIFY_TITLE_SUCCESS'), context: getText('NOTIFY_MSG_UPLOAD_SUCCESS'), type: 'success' });
                        }
                        this.fileSelectorFor = undefined;
                    });
                } else {
                    this.fileSelectorFor = undefined;
                }
            }
            fr.readAsBinaryString(files[0]);
        } else {
            this.fileSelectorFor = undefined;
        }
    }
    triggerSelectFileForEdid = (edid_idx: string) => {
        if (window.CSX_CUR_AUTH && csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('edid_control'), CsxUserPermissionLevel.FullAccess)) {
            if (this.fileSelector) {
                this.fileSelectorFor = edid_idx;
                this.fileSelector.click();
            } else {
                window.alert(getText('ALERT_OP_UNSUPPORT_NATIVE_OUT'));
            }
        } else {
            Notify({ title: getText('NOTIFY_TITLE_SUCCESS'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' });
        }
    }
    render() {
        const { edid_s, name_changed, temp_edid_name, edid_name_err } = this.state;
        const edid_idx_walk = edid_s.split('.');
        const inEdidParam = this.instance.InEDIDParam();
        const usrEdidParam = this.instance.UserEDIDDataParam();
        const intEdidParam = this.instance.InternalEDIDDataParam();
        const snkEdidParam = this.instance.SinkEDIDDataParam();
        const inEdidDataParam = this.instance.InEDIDDataParam();
        const allEdidModeParam = this.instance.AllEDIDModeParam();
        const allEdidParam = this.instance.AllEDIDParam();
        const src_opt = (inEdidParam.n1.isOption) ? inEdidParam.n1.isOption.option : [];
        const src_desc = (inEdidParam.n1.isOption) ? inEdidParam.n1.isOption.desc : [];
        const edid_opt = (inEdidParam.n2.isOption) ? inEdidParam.n2.isOption.option : [];
        const edid_desc = (inEdidParam.n2.isOption) ? inEdidParam.n2.isOption.desc : [];
        const usr_edid_opt = (usrEdidParam.n1.isOption) ? usrEdidParam.n1.isOption.option : [];
        const usr_edid_desc = (usrEdidParam.n1.isOption) ? usrEdidParam.n1.isOption.desc : [];
        const int_edid_opt = (intEdidParam.n1.isOption) ? intEdidParam.n1.isOption.option : [];
        const int_edid_desc = (intEdidParam.n1.isOption) ? intEdidParam.n1.isOption.desc : [];
        const snk_edid_opt = (snkEdidParam.x1.isOption) ? snkEdidParam.x1.isOption.option : [];
        const snk_edid_desc = (snkEdidParam.x1.isOption) ? snkEdidParam.x1.isOption.desc : [];
        const src_edid_opt = (inEdidDataParam.n1.isOption) ? inEdidDataParam.n1.isOption.option : [];
        const src_edid_desc = (inEdidDataParam.n1.isOption) ? inEdidDataParam.n1.isOption.desc : [];
        const alledid_mode_opt = (allEdidModeParam.b1.isOption) ? allEdidModeParam.b1.isOption.option : ['all','appoint'];
        const alledid_mode_desc = (allEdidModeParam.b1.isOption) ? allEdidModeParam.b1.isOption.desc : ['ALL','APPOINT'];
        const alledid_opt = (allEdidParam.n1.isOption) ? allEdidParam.n1.isOption.option : [];
        // const alledid_desc = (allEdidParam.n1.isOption) ? allEdidParam.n1.isOption.desc : [];
        const usr_edid_name = usr_edid_opt.map(idx => this.instance.UserEDIDName(parseInt(idx) + int_edid_opt.length));
        const usr_edid_name_s = this.instance.UserEDIDName(parseInt(edid_idx_walk[1]) + int_edid_opt.length);
        const alledid_mode = this.instance.AllEDIDMode();
        const alledid = this.instance.AllEDID();
        const cur_edid = src_opt.map(opt => this.instance.InEDID(opt));
        const edid_parse_debug_level = 0;
        let edid_parse: CsxUtil.EDIDElement = new CsxUtil.EDIDElement('');
        if (edid_s.length > 0) {
            if (edid_idx_walk[0] === 'I7')
                edid_parse = new CsxUtil.EDIDElement(this.instance.UserEDIDData(edid_idx_walk[1]).split(',').map(hexstr => ((hexstr.length < 2)?((hexstr.length < 1)?'00':`0${hexstr}`):hexstr)).join(''), edid_parse_debug_level);
            else if (edid_idx_walk[0] === 'I8')
                edid_parse = new CsxUtil.EDIDElement(this.instance.SinkEDIDData(edid_idx_walk[1]).split(',').map(hexstr => ((hexstr.length < 2)?((hexstr.length < 1)?'00':`0${hexstr}`):hexstr)).join(''), edid_parse_debug_level);
            else if (edid_idx_walk[0] === 'I9')
                edid_parse = new CsxUtil.EDIDElement(this.instance.InEDIDData(edid_idx_walk[1]).split(',').map(hexstr => ((hexstr.length < 2)?((hexstr.length < 1)?'00':`0${hexstr}`):hexstr)).join(''), edid_parse_debug_level);
            else if (edid_idx_walk[0] === 'I11')
                edid_parse = new CsxUtil.EDIDElement(this.instance.InternalEDIDData(edid_idx_walk[1]).split(',').map(hexstr => ((hexstr.length < 2)?((hexstr.length < 1)?'00':`0${hexstr}`):hexstr)).join(''), edid_parse_debug_level);
        }
        const { audio, venderSpec, extended } = edid_parse.extension;
        // const { blockTag } = edid_parse.extension;
        const ext_video_list: Array<string> = [];
        if (extended && extended.HDRStaticMetadata && extended.HDRStaticMetadata.supportSDR)
            ext_video_list.push('SDR');
        if (extended && extended.HDRStaticMetadata && extended.HDRStaticMetadata.supportHDR)
            ext_video_list.push('HDR');
        if (extended && extended.HDRStaticMetadata && extended.HDRStaticMetadata.supportHLG)
            ext_video_list.push('HLG');
        if (extended && extended.HDRStaticMetadata && extended.HDRStaticMetadata.supportHDR10)
            ext_video_list.push('HDR10');
        if (venderSpec && venderSpec.HDR10PlusSupoort)
            ext_video_list.push('HDR10+');
        if (venderSpec && venderSpec.DolbyVisionSupport)
            ext_video_list.push('Dolby Vision');
        // validate input 
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_EDID_SRC_SWITCH_LABEL')} style={notHiddenIf(this.instance.IsSupportEDIDSwitch())} defaultExtend={true}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_EDID_SRC_MODE')} style={notHiddenIf(this.instance.IsSupportAllEDIDMode())}>
                            <SwitchButton
                                checked={((alledid_mode_opt.length >= 2) ? alledid_mode === alledid_mode_opt[0] : false)}
                                label={(alledid_mode_desc && alledid_mode_desc.length >= 2) ? [ alledid_mode_desc[0], alledid_mode_desc[1] ] : undefined}
                                onChange={this.onChangeAllEdidMode}
                            />
                        </Form.Item>
                        {(alledid_mode === alledid_mode_opt[0]) ? <Form.Item label={getText('DEVICE_FUNC_EDID_SRC_ALL')} style={notHiddenIf(this.instance.IsSupportAllEDID())}>
                            <Select placeholder='No All EDID' value={alledid} onChange={this.onChangeAllEdid}>
                                {alledid_opt.map((edid, edid_idx) => {
                                    if (edid_idx >= int_edid_opt.length && edid_idx < (int_edid_opt.length + usr_edid_opt.length)) {
                                        const usr_edid_idx = edid_idx - int_edid_opt.length;
                                        const defaultName = (usr_edid_desc) ? usr_edid_desc[usr_edid_idx] : `User ${usr_edid_idx + 1}`;
                                        return <Option key={`edid_option_${edid}`} value={edid}>{CsxUtil.defaultValue(usr_edid_name[usr_edid_idx], ((s) => s.length > 0), defaultName)}</Option>;
                                    } else {
                                        return <Option key={`edid_option_${edid}`} value={edid}>{(edid_desc) ? edid_desc[edid_idx] : `EDID ${edid}`}</Option>;
                                    }
                                })}
                            </Select>
                        </Form.Item> : undefined}
                        {(alledid_mode !== alledid_mode_opt[0]) ? src_opt.map((input, idx) => {
                            return <Form.Item key={`edid_formitem_${input}`} label={(src_desc) ? src_desc[idx] : `Source ${input}`}><Select placeholder='No EDID' value={cur_edid[idx]} onChange={(v) => { this.instance.SetInEDID(input, v); }}>
                                {edid_opt.map((edid, edid_idx) => {
                                    if (edid_idx >= int_edid_opt.length && edid_idx < (int_edid_opt.length + usr_edid_opt.length)) {
                                        const usr_edid_idx = edid_idx - int_edid_opt.length;
                                        const defaultName = (usr_edid_desc) ? usr_edid_desc[usr_edid_idx] : `User ${usr_edid_idx + 1}`;
                                        return <Option key={`edid_option_${edid}`} value={edid}>{CsxUtil.defaultValue(usr_edid_name[usr_edid_idx], ((s) => s.length > 0), defaultName)}</Option>;
                                    } else {
                                        return <Option key={`edid_option_${edid}`} value={edid}>{(edid_desc) ? edid_desc[edid_idx] : `EDID ${edid}`}</Option>;
                                    }
                                })}
                            </Select></Form.Item>
                        }) : undefined}
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_EDID_UPDOWN_LOAD_LABEL')} style={notHiddenIf(this.instance.IsSupportInternalEDIDRead() || this.instance.IsSupportSinkEDIDRead() || this.instance.IsSupportSrcEDIDRead() || this.instance.IsSupportUserEDIDRead())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        {usr_edid_opt.map((edid, idx) => {
                            const defaultName = (usr_edid_desc) ? usr_edid_desc[idx] : `User ${edid}`;
                            return <Form.Item key={`usr_edid_formitem_${edid}`} label={CsxUtil.defaultValue(usr_edid_name[idx], ((s) => s.length > 0), defaultName)}>
                                <Button icon='download' size='small' shape='round' onClick={() => { this.onDownloadEDID(this.instance.UserEDIDData(edid)); }}>{getText('DOWNLOAD')}</Button>
                                {this.instance.IsSupportUserEDIDWrite() ? <Button icon='upload' size='small' shape='round' style={{ width: '107px' }} onClick={() => { this.triggerSelectFileForEdid(edid); }}>{getText('UPLOAD')}</Button> : undefined}
                            </Form.Item>
                        }).concat(int_edid_opt.map((edid, idx) => {
                            return <Form.Item key={`int_edid_formitem_${edid}`} label={(int_edid_desc) ? int_edid_desc[idx] : `Internal ${edid}`}>
                                <Button icon='download' size='small' shape='round' onClick={() => { this.onDownloadEDID(this.instance.InternalEDIDData(edid)); }}>{getText('DOWNLOAD')}</Button>
                            </Form.Item>
                        })).concat(snk_edid_opt.map((edid, idx) => {
                            return <Form.Item key={`snk_edid_formitem_${edid}`} label={(snk_edid_desc) ? snk_edid_desc[idx] : `Sink ${edid}`}>
                                <Button icon='download' size='small' shape='round' onClick={() => { this.onDownloadEDID(this.instance.SinkEDIDData(edid)); }}>{getText('DOWNLOAD')}</Button>
                            </Form.Item>
                        })).concat(src_edid_opt.map((edid, idx) => {
                            return <Form.Item key={`src_edid_formitem_${edid}`} label={(src_edid_desc) ? src_edid_desc[idx] : `Source ${edid}`}>
                                <Button icon='download' size='small' shape='round' onClick={() => { this.onDownloadEDID(this.instance.InEDIDData(edid)); }}>{getText('DOWNLOAD')}</Button>
                            </Form.Item>
                        }))}
                        {!window.APP_ON_HDMI ? <input type='file' ref={inst => { this.fileSelector = inst; }} onChange={this.onUploadEDID} style={{ visibility: 'hidden' }} /> : undefined}
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_EDID_INFO_LABEL')} style={notHiddenIf(this.instance.IsSupportInternalEDIDRead() || this.instance.IsSupportSinkEDIDRead() || this.instance.IsSupportSrcEDIDRead() || this.instance.IsSupportUserEDIDRead())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label='EDID'><Select placeholder='No EDID' value={edid_s} onChange={this.onChangeEDIDSelect}>
                            {usr_edid_opt.map((edid, edid_idx) => {
                                const defaultName = (usr_edid_desc) ? usr_edid_desc[edid_idx] : `User ${edid}`;
                                return <Option key={`edid_info_usr${edid}`} value={`I7.${edid}`}>{CsxUtil.defaultValue(usr_edid_name[edid_idx], ((s) => s.length > 0), defaultName)}</Option>;
                            }).concat(int_edid_opt.map((edid, edid_idx) => {
                                return <Option key={`edid_info_int${edid}`} value={`I11.${edid}`}>{(int_edid_desc) ? int_edid_desc[edid_idx] : `Internal ${edid}`}</Option>;
                            })).concat(snk_edid_opt.map((edid, edid_idx) => {
                                return <Option key={`edid_info_snk${edid}`} value={`I8.${edid}`}>{(snk_edid_desc) ? snk_edid_desc[edid_idx] : `Sink ${edid}`}</Option>;
                            })).concat(src_edid_opt.map((edid, edid_idx) => {
                                return <Option key={`edid_info_src${edid}`} value={`I9.${edid}`}>{(src_edid_desc) ? src_edid_desc[edid_idx] : `Sink ${edid}`}</Option>;
                            }))}
                        </Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_EDID_INFO_NAME')} error={edid_name_err} style={notHiddenIf(this.instance.IsSupportUserEDIDNaming() && (edid_idx_walk[0] === 'I7') && (edid_s.length > 0))}>
                            <Input placeholder='Custom EDID Name' value={(typeof temp_edid_name === 'string') ? temp_edid_name : usr_edid_name_s} onChange={this.onChangeUserEDIDName} />
                            <Button type='primary' disabled={(!name_changed || edid_name_err.length > 0)} onClick={this.onSaveUserEDIDName}>{getText('SAVE')}</Button>
                        </Form.Item>
                    </Form.Form>
                    {(edid_parse.isValid) ?
                        <Tree.Tree color='black' collapsable>
                            <Tree.Node label={getText('DEVICE_FUNC_EDID_INFO_BLOCK1')}>
                                <Tree.Node label={<div className='edid_node_label'>{getText('DEVICE_FUNC_EDID_INFO_MF_NAME')}<Input disabled size='small' value={edid_parse.manufacturerName} /></div>}></Tree.Node>
                                {/* <Tree.Node label={<div className='edid_node_label'>Product ID<Input disabled size='small' value={edid_parse.productId} /></div>}></Tree.Node> */}
                                <Tree.Node label={<div className='edid_node_label'>{getText('DEVICE_FUNC_EDID_INFO_1_DETAIL_TIMING')}<Input disabled size='small' value={edid_parse.firstDetailTiming} /></div>}></Tree.Node>
                                {/* <Tree.Node label={<div className='edid_node_label'>Display Product Name<Input disabled size='small' value={edid_parse.descriptorProductName} /></div>}></Tree.Node> */}
                            </Tree.Node>
                            {(edid_parse.hasBlock2) ? <Tree.Node label={getText('DEVICE_FUNC_EDID_INFO_BLOCK2')} defaultClose >
                                {/* <Tree.Node label={<div className='edid_node_label'>CEA 861 Extension Code<Input disabled size='small' value={'0x' + blockTag.toString(16)} /></div>}></Tree.Node> */}
                                <Tree.Node label='Audio'>
                                    {/* <Tree.Node label={<div className='edid_node_label'>Max Channel Number<Input disabled size='small' value={(audio) ? audio.maxChannel : 'Not Support'} /></div>}></Tree.Node> */}
                                    <Tree.Node label={<div className='edid_node_label'>LPCM<Input disabled size='small' value={(audio) ? (audio.LPCM ? `${audio.LPCM.maxChannels}CH` : 'Not Support') : 'Not Support'} /></div>}></Tree.Node>
                                    <Tree.Node label={<div className='edid_node_label'>Bitstream<Input disabled size='small' value={(audio) ? (audio.bitstream ? 'Support' : 'Not Support') : 'Not Support'} /></div>}></Tree.Node>
                                    <Tree.Node label={<div className='edid_node_label'>HBR<Input disabled size='small' value={(audio) ? (audio.HBR ? 'Support' : 'Not Support') : 'Not Support'} /></div>}></Tree.Node>
                                </Tree.Node>
                                <Tree.Node label={getText('DEVICE_FUNC_EDID_INFO_VIDEO')}>
                                    <Tree.Node label={getText('DEVICE_FUNC_EDID_INFO_VIDEO_TIMING')}>
                                        {edid_parse.timingList.map((timing_str, idx) => <Tree.Node key={`edid_parse_detail_timing_${idx}`} label={timing_str}/>)}
                                    </Tree.Node>
                                    {(ext_video_list.length > 0) ? <Tree.Node label='Extension Video' defaultClose>
                                        {ext_video_list.map((ext_video, idx) => <Tree.Node key={`ext_video_${idx}`} label={ext_video}/>)}
                                    </Tree.Node> : undefined}
                                </Tree.Node>
                                {/* <Tree.Node label='HDMI v1.4b VSDB'>
                                    <Tree.Node label={<div className='edid_node_label'>Deep Color Support<Input disabled size='small' value={(venderSpec && venderSpec.HDMIV14bVSDB) ? (venderSpec.HDMIV14bVSDB.supportDeepColor ? 'yes' : 'no') : 'Not Support'} /></div>}></Tree.Node>
                                    <Tree.Node label={<div className='edid_node_label'>3D Support<Input disabled size='small' value={(venderSpec && venderSpec.HDMIV14bVSDB) ? (venderSpec.HDMIV14bVSDB.support3D ? 'yes' : 'no') : 'Not Support'} /></div>}></Tree.Node>
                                    <Tree.Node label={<div className='edid_node_label'>4K3G Support<Input disabled size='small' value={(venderSpec && venderSpec.HDMIV14bVSDB) ? (venderSpec.HDMIV14bVSDB.support4K3G ? 'yes' : 'no') : 'Not Support'} /></div>}></Tree.Node>
                                </Tree.Node>
                                <Tree.Node label={`HDMI Forum VSDB ${(venderSpec && venderSpec.HDMIForumVSDB) ? '' : '(Not Found)'}`}>
                                    <Tree.Node label={<div className='edid_node_label'>340M csc Support<Input disabled size='small' value={(venderSpec && venderSpec.HDMIForumVSDB) ? (venderSpec.HDMIForumVSDB.support340Mcsc ? 'yes' : 'no') : 'Not Support'} /></div>}></Tree.Node>
                                    <Tree.Node label={<div className='edid_node_label'>SDCD Support<Input disabled size='small' value={(venderSpec && venderSpec.HDMIForumVSDB) ? (venderSpec.HDMIForumVSDB.supportSCDC ? 'yes' : 'no') : 'Not Support'} /></div>}></Tree.Node>
                                    <Tree.Node label={<div className='edid_node_label'>YCbCr 4:2:0 Deep Color<Input disabled size='small' value={(venderSpec && venderSpec.HDMIForumVSDB) ? (venderSpec.HDMIForumVSDB.Y420DeepColor ? 'yes' : 'no') : 'Not Support'} /></div>}></Tree.Node>
                                </Tree.Node> */}
                                {/* <Tree.Node label='Extended Data Block'>
                                    <Tree.Node label={<div className='edid_node_label'>HDR<Input disabled size='small' value={(extended) ? (extended.supportHDRBlock ? 'yes' : 'no') : 'Not Support'} /></div>}></Tree.Node>
                                    <Tree.Node label={<div className='edid_node_label'>YCbCr 4:2:0<Input disabled size='small' value={(extended) ? (extended.supportY420Block ? 'yes' : 'no') : 'Not Support'} /></div>}></Tree.Node>
                                </Tree.Node> */}
                            </Tree.Node> : undefined }
                        </Tree.Tree> : undefined}
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

class HDCPView extends CsxUI.IRQComponent<any> {
    state: { mtvin_s: string; out_s: string }
    instance: CsxFeature.CsxHDCPDevice;
    route: CsxFeature.CsxRoutableDevice;
    constructor(props: any) {
        super(props);
        this.state = { mtvin_s: '', out_s: '' };
        this.IRQIndex = 'DeviceRealTime';
        this.MIN_RENDER_INTERVAL = 100;
        this.instance = new CsxFeature.CsxHDCPDevice(window.FOCUS_DEVICE);
        this.route = new CsxFeature.CsxRoutableDevice(window.FOCUS_DEVICE);
    }
    onChangeInHDCPMode = (in_idx: string, value: string) => { this.instance.SetInHDCPMode(in_idx, value); }
    onChangeOutHDCPMuteMode = (value: string) => { this.instance.SetOutHDCPMuteMode(this.state.out_s, value); }
    onChangeMtvInHDCPMode = (value: string) => { this.instance.SetInMultiviewHDCPMode(this.state.mtvin_s, value); }
    onChangeMtvInSelect = (value: string) => { this.setState({ mtvin_s: value }); }
    onChangeOutSelect = (value: string) => { this.setState({ out_s: value }); }
    render() {
        const { mtvin_s, out_s } = this.state;
        const inHDCPModeParam = this.instance.InHDCPModeParam();
        const inHDCPStatusParam = this.instance.InHDCPStatusParam();
        const outHDCPStatusParam = this.instance.OutHDCPStatusParam();
        const inHDCPAbilityParam = this.instance.InHDCPAbilityParam();
        const outHDCPAbilityParam = this.instance.OutHDCPAbilityParam();
        const inMultiviewHDCPModeParam = this.instance.InMultiviewHDCPModeParam();
        const outHDCPMuteParam = this.instance.OutHDCPMuteModeParam();
        const inSyncStatusParam = this.route.InSyncStatusParam();
        const outSyncStatusParam = this.route.OutSyncStatusParam();
        const hdcp_in_opt = (inHDCPModeParam.n1.isOption) ? inHDCPModeParam.n1.isOption.option : [];
        const hdcp_in_desc = (inHDCPModeParam.n1.isOption) ? inHDCPModeParam.n1.isOption.desc : [];
        const hdcp_mode_opt = (inHDCPModeParam.n2.isOption) ? inHDCPModeParam.n2.isOption.option : [];
        const hdcp_mode_desc = (inHDCPModeParam.n2.isOption) ? inHDCPModeParam.n2.isOption.desc : [];
        const hdcpsta_in_opt = (inHDCPStatusParam.n2.isOption) ? inHDCPStatusParam.n2.isOption.option : [];
        const hdcpsta_in_desc = (inHDCPStatusParam.n2.isOption) ? inHDCPStatusParam.n2.isOption.desc : [];
        const hdcp_out_opt = (outHDCPStatusParam.x1.isOption) ? outHDCPStatusParam.x1.isOption.option : [];
        const hdcp_out_desc = (outHDCPStatusParam.x1.isOption) ? outHDCPStatusParam.x1.isOption.desc : [];
        const hdcpsta_out_opt = (outHDCPStatusParam.n1.isOption) ? outHDCPStatusParam.n1.isOption.option : [];
        const hdcpsta_out_desc = (outHDCPStatusParam.n1.isOption) ? outHDCPStatusParam.n1.isOption.desc : [];
        const hdcpin_ability_opt = (inHDCPAbilityParam.n2.isOption) ? inHDCPAbilityParam.n2.isOption.option : [];
        const hdcpin_ability_desc = (inHDCPAbilityParam.n2.isOption) ? inHDCPAbilityParam.n2.isOption.desc : [];
        const hdcpout_ability_opt = (outHDCPAbilityParam.n1.isOption) ? outHDCPAbilityParam.n1.isOption.option : [];
        const hdcpout_ability_desc = (outHDCPAbilityParam.n1.isOption) ? outHDCPAbilityParam.n1.isOption.desc : [];
        const mtv_hdcpin_opt = (inMultiviewHDCPModeParam.n1.isOption) ? inMultiviewHDCPModeParam.n1.isOption.option : [];
        const mtv_hdcpin_desc = (inMultiviewHDCPModeParam.n1.isOption) ? inMultiviewHDCPModeParam.n1.isOption.desc : [];
        const mtv_hdcpin_mode_opt = (inMultiviewHDCPModeParam.n2.isOption) ? inMultiviewHDCPModeParam.n2.isOption.option : [];
        const mtv_hdcpin_mode_desc = (inMultiviewHDCPModeParam.n2.isOption) ? inMultiviewHDCPModeParam.n2.isOption.desc : [];
        const in_sync_sta_opt = (inSyncStatusParam.n2.isOption) ? inSyncStatusParam.n2.isOption.option : ['no sync', 'sync active'];
        // const in_sync_sta_desc = (inSyncStatusParam.n2.isOption) ? inSyncStatusParam.n2.isOption.desc : [];
        const out_sync_sta_opt = (outSyncStatusParam.n1.isOption) ? outSyncStatusParam.n1.isOption.option : ['no sync', 'sync active'];
        // const out_sync_sta_desc = (outSyncStatusParam.n1.isOption) ? outSyncStatusParam.n1.isOption.desc : [];
        const mute_out_opt = (outHDCPMuteParam.x1.isOption) ? outHDCPMuteParam.x1.isOption.option : [];
        const mute_out_desc = (outHDCPMuteParam.x1.isOption) ? outHDCPMuteParam.x1.isOption.desc : [];
        const mute_mode_opt = (outHDCPMuteParam.n1.isOption) ? outHDCPMuteParam.n1.isOption.option : [];
        const mute_mode_desc = (outHDCPMuteParam.n1.isOption) ? outHDCPMuteParam.n1.isOption.desc : [];
        const hdcp_mode_value = hdcp_in_opt.map(idx => this.instance.InHDCPMode(idx));
        const mtvin_mode_s = this.instance.InMultiviewHDCPMode(mtvin_s);
        const mute_mode_s = this.instance.OutHDCPMuteMode(out_s);
        const support_in_overview = this.instance.IsSupportInHDCPStatus();
        const support_out_overview = this.instance.IsSupportOutHDCPStatus();
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title='HDCP Overview' className='hdcpoverview_section' style={notHiddenIf(support_in_overview || support_out_overview)} defaultExtend={true}>
                    {support_in_overview ? <Table
                        headers={[getText('DEVICE_FUNC_HDCP_TABLE_IN'), getText('DEVICE_FUNC_HDCP_TABLE_CURRENT'), getText('DEVICE_FUNC_HDCP_TABLE_ABILITY')]}
                        columnWidth={[ 120, 120, 120 ]}
                        responsive='no'
                        rows={hdcp_in_opt.map((in_opt, in_idx) => {
                            const cur_sta = this.route.InSyncStatus(in_opt);
                            const cur_hdcp = this.instance.InHDCPStatus(in_opt);
                            const sync_on = ((in_sync_sta_opt.length >= 2)?(cur_sta === in_sync_sta_opt[1]):false);
                            const cur_sta_desc = (hdcpsta_in_desc) ? hdcpsta_in_desc[hdcpsta_in_opt.indexOf(cur_hdcp)] : in_opt;
                            const in_ability = this.instance.InHDCPAbility(in_opt);
                            const in_ability_desc = (hdcpin_ability_desc) ? hdcpin_ability_desc[hdcpin_ability_opt.indexOf(in_ability)] : 'unknown';
                            const led_color = sync_on ? 'green' : 'gray';
                            return [
                                ((hdcp_in_desc) ? hdcp_in_desc[in_idx] : in_opt),
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><Icon type={`led-${led_color}`} style={{ marginRight: '5px' }}/>{`${(cur_sta_desc) ? cur_sta_desc : 'Unknown'}`}</div>,
                                `${(in_ability_desc) ? in_ability_desc : 'Unknown'}`,
                            ];
                        })}
                        
                    /> : undefined}
                    {support_out_overview ? <Table
                        headers={[getText('DEVICE_FUNC_HDCP_TABLE_OUT'), getText('DEVICE_FUNC_HDCP_TABLE_CURRENT'), getText('DEVICE_FUNC_HDCP_TABLE_ABILITY')]}
                        columnWidth={[ 120, 120, 120 ]}
                        responsive='no'
                        rows={hdcp_out_opt.map((out_opt, out_idx) => {
                            const cur_sta = this.route.OutSyncStatus(out_opt);
                            const cur_hdcp = this.instance.OutHDCPStatus(out_opt);
                            const sync_on = ((out_sync_sta_opt.length >= 2)?(cur_sta === out_sync_sta_opt[1]):false);
                            const cur_sta_desc = (hdcpsta_out_desc) ? hdcpsta_out_desc[hdcpsta_out_opt.indexOf(cur_hdcp)] : out_opt;
                            const out_ability = this.instance.OutHDCPAbility(out_opt);
                            const out_ability_desc = (hdcpout_ability_desc) ? hdcpout_ability_desc[hdcpout_ability_opt.indexOf(out_ability)] : 'unknown';
                            const led_color = sync_on ? 'green' : 'gray';
                            return [
                                ((hdcp_out_desc) ? hdcp_out_desc[out_idx] : out_opt),
                                <div style={{ width: '100%', display: 'flex', flexDirection: 'row', alignItems: 'center' }}><Icon type={`led-${led_color}`} style={{ marginRight: '5px' }}/>{`${(cur_sta_desc) ? cur_sta_desc : 'Unknown'}`}</div>,
                                `${(out_ability_desc) ? out_ability_desc : 'Unknown'}`,
                            ];
                        })}
                    /> : undefined}
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_HDCP_IN_CONF_LABEL')} style={notHiddenIf(this.instance.IsSupportInHDCPMode())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        {hdcp_in_opt.map((in_opt, idx) => {
                            const cur_sta = this.instance.InHDCPStatus(in_opt);
                            const color = (cur_sta === '1' || cur_sta === '2') ? 'green' : 'gray';
                            const label_node = <span className='icon_label'>{(hdcp_in_desc ? hdcp_in_desc[idx] : in_opt)}<Icon style={notHiddenIf(this.instance.IsSupportInHDCPStatus())} type={`led-${color}`} /></span>
                            return <Form.Item label={label_node} key={`hdcpin_form_${in_opt}`}><Select placeholder='Select HDCP Mode' value={hdcp_mode_value[idx]} onChange={(v) => { this.onChangeInHDCPMode(`${in_opt}`, v); }}>
                                {hdcp_mode_opt.map((mode_opt, mode_idx) => <Option value={mode_opt} key={`hdcpin_${in_opt}_mode_opt_${mode_opt}`}>{hdcp_mode_desc ? hdcp_mode_desc[mode_idx] : mode_opt}</Option>)}
                            </Select></Form.Item>;
                        })}
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_HDCP_MULTIVIEW_LABEL')} style={notHiddenIf(this.instance.IsSupportMultiviewHDCPMode())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_HDCP_MULTIVIEW_SRC')}><Select placeholder='Select Source' value={mtvin_s} onChange={this.onChangeMtvInSelect}>
                            {mtv_hdcpin_opt.map((in_opt, in_idx) => <Option value={in_opt} key={`mtvin_opt_${in_opt}`}>{mtv_hdcpin_desc ? mtv_hdcpin_desc[in_idx] : in_opt}</Option>)}
                        </Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_HDCP_MULTIVIEW_MODE')} disabled={mtvin_s.length === 0}><Select placeholder='Select Mode' value={mtvin_mode_s} onChange={this.onChangeMtvInHDCPMode}>
                            {mtv_hdcpin_mode_opt.map((mode_opt, mode_idx) => <Option value={mode_opt} key={`mtvin_mode_opt_${mode_opt}`}>{mtv_hdcpin_mode_desc ? mtv_hdcpin_mode_desc[mode_idx] : mode_opt}</Option>)}
                        </Select></Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_HDCP_MUTE_LABEL')} style={notHiddenIf(this.instance.IsSupportHDCPMuteMode())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_HDCP_MUTE_OUT')}><Select placeholder='Select Output' value={out_s} onChange={this.onChangeOutSelect}>
                            {mute_out_opt.map((out_opt, out_idx) => <Option value={out_opt} key={`mute_out_opt_${out_opt}`}>{mute_out_desc ? mute_out_desc[out_idx] : out_opt}</Option>)}
                        </Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_HDCP_MUTE_MODE')} disabled={out_s.length === 0}><Select placeholder='Select Mode' value={mute_mode_s} onChange={this.onChangeOutHDCPMuteMode}>
                            {mute_mode_opt.map((mode_opt, mode_idx) => <Option value={mode_opt} key={`mute_mode_opt_${mode_opt}`}>{mute_mode_desc ? mute_mode_desc[mode_idx] : mode_opt}</Option>)}
                        </Select></Form.Item>
                    </Form.Form>
                </Flat.Section>
            </Flat.Playground >
        );
    }
}

class GeneralView extends CsxUI.IRQComponent<any> {
    state: {
        lan_select: string; fan_select: string; temperature_select: string;
        ipconf_changed: boolean; nick_changed: boolean; fan_changed: boolean; hostname_changed: boolean;
        nickname_s?: string; hostname_s?: string;
        static_ip_err: string; static_gw_err: string; static_nm_err: string; nickname_err: string; hostname_err: string;
    }
    instance: CsxFeature.CsxGeneralDevice
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'DeviceRealTime';
        // this.MIN_RENDER_INTERVAL = 100;
        this.instance = new CsxFeature.CsxGeneralDevice(window.FOCUS_DEVICE);
        this.state = {
            lan_select: '1',
            fan_select: '',
            temperature_select: '',
            ipconf_changed: false,
            nick_changed: false,
            fan_changed: false,
            hostname_changed: false,
            static_ip_err: '', static_gw_err: '', static_nm_err: '', nickname_err: '', hostname_err: '',
        }
    }
    get ipmodeOption() {
        const ipmodeParam = this.instance.IPModeParam();
        const opt = (ipmodeParam.s1.isOption) ? ipmodeParam.s1.isOption.option : ['static', 'dhcp'];
        return (opt.length >= 2) ? opt : ['static', 'dhcp'];
    }
    get ipmodeDesc() {
        const ipmodeParam = this.instance.IPModeParam();
        const desc = (ipmodeParam.s1.isOption) ? ipmodeParam.s1.isOption.desc : ['STATIC', 'DHCP'];
        return (desc && desc.length >= 2) ? desc : ['STATIC', 'DHCP'];
    }
    validateNickname = (value: string) => {
        const nicknameParam = this.instance.NicknameParam();
        const ok = !((typeof value === 'string') && nicknameParam.s1.isString && !this.validateString(value, nicknameParam.s1.isString));
        this.setState({ nickname_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    validateHostname = (value: string) => {
        const hostnameParam = this.instance.HostnameParam();
        const ok = !((typeof value === 'string') && hostnameParam.s1.isString && !this.validateString(value, hostnameParam.s1.isString));
        this.setState({ hostname_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    validateStaticIPAddr = (value: string) => {
        const ipaddrParam = this.instance.StaticIPAddressParam();
        let ok = true;
        if (ipaddrParam.nn.isString) {
            ok = this.validateString(value, ipaddrParam.nn.isString);
        }
        this.setState({ static_ip_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    validateStaticGateway = (value: string) => {
        const gatewayParam = this.instance.StaticGatewayParam();
        let ok = true;
        if (gatewayParam.nn.isString) {
            ok = this.validateString(value, gatewayParam.nn.isString);
        }
        this.setState({ static_gw_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    validateStaticNetmask = (value: string) => {
        const netmaskParam = this.instance.StaticNetmaskParam();
        let ok = true;
        if (netmaskParam.nn.isString) {
            ok = this.validateString(value, netmaskParam.nn.isString);
        }
        this.setState({ static_nm_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    onChangeKeylock = (e: React.ChangeEvent<HTMLInputElement>) => {
        const keylockParam = this.instance.KeylockParam();
        const keylock_opt = (keylockParam.b1.isOption) ? keylockParam.b1.isOption.option : [];
        if (keylock_opt.length >= 2) {
            this.instance.SetKeylock((e.target.checked) ? keylock_opt[0] : keylock_opt[1]);
        }
    }
    onChangeIpMode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const ip_mode_opt = this.ipmodeOption;
        if (ip_mode_opt.length >= 2) {
            this.instance.SetIPMode(this.state.lan_select, (e.target.checked) ? ip_mode_opt[1] : ip_mode_opt[0]);
            this.setState({ ipconf_changed: true });
        }
    }
    onChangeIpAddr = (e: React.ChangeEvent<HTMLInputElement>) => { this.validateStaticIPAddr(e.target.value); this.instance.SetStaticIPAddress(this.state.lan_select, e.target.value); this.setState({ ipconf_changed: true }); }
    onChangeGateway = (e: React.ChangeEvent<HTMLInputElement>) => { this.validateStaticGateway(e.target.value); this.instance.SetStaticGateway(this.state.lan_select, e.target.value); this.setState({ ipconf_changed: true }); }
    onChangeNetmask = (e: React.ChangeEvent<HTMLInputElement>) => { this.validateStaticNetmask(e.target.value); this.instance.SetStaticNetmask(this.state.lan_select, e.target.value); this.setState({ ipconf_changed: true }); }
    onChangeNickname = (e: React.ChangeEvent<HTMLInputElement>) => { if (this.validateNickname(e.target.value) || e.target.value.length === 0) this.setState({ nick_changed: true, nickname_s: e.target.value }); }
    onChangeHostname = (e: React.ChangeEvent<HTMLInputElement>) => { if (this.validateHostname(e.target.value) || e.target.value.length === 0) this.setState({ hostname_changed: true, hostname_s: e.target.value }); }
    onChangeFanMode = (value: string) => { this.instance.SetFanControlMode(this.state.fan_select, value); this.setState({ fan_changed: true }); }
    onChangeLanSelect = (value: string) => { this.instance.cleanBuffer(); this.setState({ lan_select: value, ipconf_changed: false }); }
    onChangeFanSelect = (value: string) => { this.setState({ fan_select: value }); }
    onSaveIPConfig = () => { this.instance.UploadStaticIPConfiguration(); this.setState({ ipconf_changed: false, static_ip_err: '', static_gw_err: '', static_nm_err: '' }); }
    onSaveNickname = () => {
        const { nickname_s } = this.state;
        if (nickname_s) {
            this.instance.SetNickname(nickname_s);
            this.setState({ nickname_s: undefined, nick_changed: false, nickname_err: '' });
        }
    }
    onSaveHostname = () => {
        const { hostname_s } = this.state;
        if (hostname_s) {
            this.instance.SetHostname(hostname_s);
            this.setState({ hostname_s: undefined, hostname_changed: false, hostname_err: '' });
        }
    }
    onSaveFan = () => { this.instance.UploadFanConfiguration(); this.setState({ fan_changed: false }); }
    onFactoryDefault = () => { window.userConfirm(getText('CONFIRM_FACTORY_DEFAULT'), (ok) => { if (ok) { this.instance.FactoryDefault(); } }); }
    render() {
        const { 
            lan_select, fan_select, 
            ipconf_changed, nick_changed, fan_changed, hostname_changed,
            nickname_s, hostname_s,
            nickname_err, static_gw_err, static_ip_err, static_nm_err, hostname_err
        } = this.state;
        const ipaddrParam = this.instance.StaticIPAddressParam();
        const keylockParam = this.instance.KeylockParam();
        const fanSpeed = this.instance.FanSpeed(fan_select);
        const fanMode = this.instance.FanControlMode(fan_select);
        // const temperature = this.instance.PointTemperature(temperature_select);
        const ipmode = this.instance.IPMode(lan_select);
        const ipaddr = this.instance.IPAddress(lan_select);
        const gateway = this.instance.Gateway(lan_select);
        const netmask = this.instance.Netmask(lan_select);
        const staticIpaddr = this.instance.StaticIPAddress(lan_select);
        const staticGateway = this.instance.StaticGateway(lan_select);
        const staticNetmask = this.instance.StaticNetmask(lan_select);
        // const nickname = (nickname_s.length > 0) ? nickname_s : this.instance.Nickname();
        const lan_list = (ipaddrParam.n1.isOption) ? ipaddrParam.n1.isOption.option : [];
        const lan_desc = (ipaddrParam.n1.isOption) ? ipaddrParam.n1.isOption.desc : [];
        const ip_mode_opt = this.ipmodeOption;
        const ip_mode_desc = this.ipmodeDesc;
        const keylock_opt = (keylockParam.b1.isOption) ? keylockParam.b1.isOption.option : [];
        const keylock_desc = (keylockParam.b1.isOption) ? keylockParam.b1.isOption.desc : [];
        const general_control_allow = (window.CSX_CUR_AUTH) ? (window.CSX_CUR_AUTH.getPermission('general_control') === CsxUserPermissionLevel.FullAccess) : false;
        let ipconfig_support = false;
        if (this.instance.IsSupportStaticIPConfiguration()) {
            ipconfig_support = true;
            if (this.instance.IsSupportMultiLan() && lan_select.length === 0)
                ipconfig_support = false;
        }
        // const powerControl = this.instance.Power();
        const keylock = this.instance.Keylock();
        // validate input
        const ipconfig_valid = (static_gw_err.length === 0 && static_nm_err.length === 0 && static_ip_err.length === 0)
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_GEN_IP_CONF_LABEL')} style={notHiddenIf(this.instance.IsSupportIPConfiguration())} defaultExtend={true}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_GEN_IP_CONF_LAN')} style={notHiddenIf(this.instance.IsSupportMultiLan())}>
                            <Select value={lan_select} placeholder='Select LAN' onChange={this.onChangeLanSelect}>
                                {lan_list.map((lan, idx) => <Option key={`ip_config_lan_${lan}`} value={lan}>{(lan_desc) ? lan_desc[idx] : lan}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_GEN_IP_CONF_MAC')} style={CsxUI.getHiddenStyle(ipconfig_support)}>{this.instance.MacAddress(lan_select)}</Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_GEN_IP_CONF_DHCP')} style={CsxUI.getHiddenStyle(ipconfig_support)}><SwitchButton checked={((ip_mode_opt.length >= 2)?(ipmode === ip_mode_opt[1]):false)} onChange={this.onChangeIpMode} label={(ip_mode_desc?[ ip_mode_desc[1], ip_mode_desc[0] ]:undefined)} /></Form.Item>
                        <Form.Item error={static_ip_err} label={getText('DEVICE_FUNC_GEN_IP_CONF_IPADDR')} style={notHiddenIf(ipconfig_support)}><Input value={(ipmode === 'dhcp') ? ipaddr : staticIpaddr} disabled={ipmode === 'dhcp'} onChange={this.onChangeIpAddr} /></Form.Item>
                        <Form.Item error={static_gw_err} label={getText('DEVICE_FUNC_GEN_IP_CONF_GW')} style={notHiddenIf(ipconfig_support)}><Input value={(ipmode === 'dhcp') ? gateway : staticGateway} disabled={ipmode === 'dhcp'} onChange={this.onChangeGateway} /></Form.Item>
                        <Form.Item error={static_nm_err} label={getText('DEVICE_FUNC_GEN_IP_CONF_NM')} style={notHiddenIf(ipconfig_support)}><Input value={(ipmode === 'dhcp') ? netmask : staticNetmask} disabled={ipmode === 'dhcp'} onChange={this.onChangeNetmask} /></Form.Item>
                        <Form.Item style={{ justifyContent: 'flex-end', display: (ipconfig_support?undefined:'none') }}>
                            <Button disabled={(!ipconf_changed || !general_control_allow)} onClick={() => { this.setState({ ipconf_changed: false, static_nm_err: '', static_gw_err: '', static_ip_err: '' }); this.instance.cleanBuffer(); }}>{getText('RESTORE')}</Button>
                            <Button type='primary' disabled={(!ipconf_changed || !ipconfig_valid || !general_control_allow)} onClick={this.onSaveIPConfig}>{getText('SAVE')}</Button>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_GEN_DEV_NAME_LABEL')} style={notHiddenIf(this.instance.IsSupportNickname())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item error={nickname_err} label={getText('DEVICE_FUNC_GEN_DEV_NAME')}>
                            <Input value={(typeof nickname_s === 'string') ? nickname_s : this.instance.Nickname()} placeholder='Type Device Name' onChange={this.onChangeNickname} />
                            <Button type='primary' disabled={(!nick_changed || nickname_err.length > 0)} onClick={this.onSaveNickname}>{getText('SAVE')}</Button>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_GEN_FAN_CTRL_LABEL')} style={notHiddenIf(this.instance.IsSupportFanControlMode() || this.instance.IsSupportFanSpeed())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_GEN_FAN_CTRL_SELECT')}>
                            <Select value={fan_select} placeholder='Select Fan' onChange={this.onChangeFanSelect}><Option value='1'>FAN1</Option><Option value='2'>FAN2</Option></Select>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_GEN_FAN_CTRL_MODE')} disabled={fan_select.length === 0} style={notHiddenIf(this.instance.IsSupportFanControlMode())}>
                            <Select value={fanMode} onChange={this.onChangeFanMode}><Option value='0'>Always ON</Option><Option value='1'>Auto</Option></Select>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_GEN_FAN_CTRL_SPEED')} disabled={fan_select.length === 0} style={notHiddenIf(this.instance.IsSupportFanSpeed())}><Input placeholder='No Data' value={fanSpeed} readOnly /></Form.Item>
                        <Form.Item style={{ justifyContent: 'flex-end' }}><Button type='primary' disabled={!fan_changed} onClick={this.onSaveFan}>{getText('SAVE')}</Button></Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_GEN_SYS_LABEL')} style={notHiddenIf(this.instance.IsSupportReboot() || this.instance.IsSupportPowerControl() || this.instance.isSupportFactoryDefault())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item style={notHiddenIf(this.instance.IsSupportPowerControl())} label='Power'>
                            <Button style={notHiddenIf(this.instance.IsSupportReboot())} onClick={this.instance.Reboot}>{getText('REBOOT')}</Button>
                            <Button style={notHiddenIf(this.instance.IsSupportPowerCommand('standby'))} onClick={() => { this.instance.SetPower('standby'); }}>{getText('STANDBY')}</Button>
                            <Button style={notHiddenIf(this.instance.IsSupportPowerCommand('on'))} onClick={() => { this.instance.SetPower('on'); }}>{getText('ON')}</Button>
                            <Button style={notHiddenIf(this.instance.IsSupportPowerCommand('off'))} onClick={() => { this.instance.SetPower('off'); }}>{getText('OFF')}</Button>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_GEN_SYS_RESTORE')} style={notHiddenIf(this.instance.isSupportFactoryDefault())}><Button onClick={this.onFactoryDefault}>{getText('SETTING_SYSTEM_SYSTEM_FAC_DEFAULT')}</Button></Form.Item>
                        <Form.Item error={hostname_err} label={getText('DEVICE_FUNC_GEN_SYS_HOSTNAME')} style={CsxUI.getHiddenStyle(this.instance.IsSupportHostname())}>
                            <Input value={(typeof hostname_s === 'string') ? hostname_s : this.instance.Hostname()} placeholder='Type Hostname' onChange={this.onChangeHostname} />
                            <Button type='primary' disabled={(!hostname_changed || hostname_err.length > 0)} onClick={this.onSaveHostname}>{getText('SAVE')}</Button>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_GEN_FRONT_PANEL_LABEL')} style={notHiddenIf(this.instance.IsSupportKeylock())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_GEN_FRONT_PANEL_KEYLOCK')}>
                            <SwitchButton checked={((keylock_opt.length >= 2)?(keylock === keylock_opt[0]):false)} label={(keylock_desc?[ keylock_opt[0], keylock_opt[1] ]:undefined)} onChange={this.onChangeKeylock} />
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

class AudioView extends CsxUI.IRQComponent<any> {
    state: { in_s: string; out_s: string; mixin_s: string; mixout_s: string; eqout_s: string; eqf_s: string; tkout_s: string; temp_out_name?: string; out_name_err: string }
    instance: CsxFeature.CsxAudioDevice
    constructor(props: any) {
        super(props);
        this.state = { in_s: '1', out_s: 'a', mixin_s: '1', mixout_s: 'a', eqf_s: '1', eqout_s: 'a', tkout_s: 'a', out_name_err: '' };
        // this.MIN_RENDER_INTERVAL = 100;
        this.IRQIndex = 'DeviceRealTime';
        this.instance = new CsxFeature.CsxAudioDevice(window.FOCUS_DEVICE);
    }
    validateOutName = (value: string) => {
        const audOutNameParam = this.instance.OutAudioNameParam();
        const ok = !(audOutNameParam.s1.isString && !this.validateString(value, audOutNameParam.s1.isString));
        this.setState({ out_name_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    onChangeTkOutEnable = (e: React.ChangeEvent<HTMLInputElement>) => {
        const tkOutEnableParam = this.instance.OutAudioTalkoverParam();
        const tk_out_enable_opt = (tkOutEnableParam.b1.isOption) ? tkOutEnableParam.b1.isOption.option : [];
        if (tk_out_enable_opt.length >= 2) {
            this.instance.SetOutAudioTalkover(this.state.tkout_s, (e.target.checked) ? tk_out_enable_opt[0] : tk_out_enable_opt[1]);
        }
    }
    onChangeKnobLock = (e: React.ChangeEvent<HTMLInputElement>) => {
        const volKnoblockParam = this.instance.AudioVolumeKnoblockParam();
        const vol_knoblock_opt = (volKnoblockParam.b1.isOption) ? volKnoblockParam.b1.isOption.option : [];
        if (vol_knoblock_opt.length >= 2) {
            this.instance.SetAudioVolumeKnoblock((e.target.checked) ? vol_knoblock_opt[0] : vol_knoblock_opt[1]);
        }
    }
    onChangeOutMute = (e: React.ChangeEvent<HTMLInputElement>) => {
        const outMuteParam = this.instance.OutAudioMuteParam();
        const out_mute_opt = (outMuteParam.b1.isOption) ? outMuteParam.b1.isOption.option : [];
        if (out_mute_opt.length >= 2) {
            this.instance.SetOutAudioMute(this.state.out_s, (e.target.checked) ? out_mute_opt[0] : out_mute_opt[1]);
        }
    }
    onChangeOutDSPMode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const audOutDSPModeParam = this.instance.OutAudioDSPModeParam();
        const out_dsp_opt = (audOutDSPModeParam.b1.isOption) ? audOutDSPModeParam.b1.isOption.option : [];
        if (out_dsp_opt.length >= 2) {
            this.instance.SetOutAudioDSPMode(this.state.out_s, (e.target.checked) ? out_dsp_opt[0] : out_dsp_opt[1]);
        }
    }
    onChangeOutName = (e: React.ChangeEvent<HTMLInputElement>) => { if (this.validateOutName(e.target.value) || e.target.value.length === 0) this.setState({ temp_out_name: e.target.value }); }
    onChangeOutSource = (value: number | string) => { this.instance.SetOutAudioRoute(this.state.out_s, value); }
    onChangeOutVolume = (value: number | string) => { this.instance.SetOutAudioVolume(this.state.out_s, value); }
    onChangeOutDelay = (value: number | string) => { this.instance.SetOutAudioDelay(this.state.out_s, value); }
    onChangeOutTreble = (value: number | string) => { this.instance.SetOutAudioTreble(this.state.out_s, value); }
    onChangeOutEQ = (value: number | string) => { this.instance.SetOutAudioEQ(this.state.eqout_s, this.state.eqf_s, value); }
    onChangeOutBass = (value: number | string) => { this.instance.SetOutAudioBass(this.state.out_s, value); }
    onChangeInPregain = (value: number | string) => { this.instance.SetInAudioPregain(this.state.in_s, value); }
    onChangeInPowerMode = (value: number | string) => { this.instance.SetInAudioPowerMode(this.state.in_s, value); }
    onChangeInMute = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inMuteParam = this.instance.InAudioMuteParam();
        const in_mute_opt = (inMuteParam.b1.isOption) ? inMuteParam.b1.isOption.option : [];
        if (in_mute_opt.length >= 2) {
            this.instance.SetInAudioMute(this.state.in_s, (e.target.checked) ? in_mute_opt[0] : in_mute_opt[1]);
        }
    }
    onChangeMixerEnable = (e: React.ChangeEvent<HTMLInputElement>) => {
        const mixerEnableParam = this.instance.MixerEnableParam();
        const mixer_enable_opt = (mixerEnableParam.b1.isOption) ? mixerEnableParam.b1.isOption.option : [];
        if (mixer_enable_opt.length >= 2) {
            this.instance.SetMixerEnable((e.target.checked) ? mixer_enable_opt[0] : mixer_enable_opt[1]);
        }
    }
    onChangeMixerInVolume = (value: number | string) => { this.instance.SetMixerInVolume(this.state.mixin_s, value); }
    onChangeMixerOutVolume = (value: number | string) => { this.instance.SetMixerOutVolume(this.state.mixout_s, value); }
    onChangeMixerOutRoute = (value: number | string) => { this.instance.SetMixerOutRoute(this.state.mixout_s, value); }
    onChangeTkOutTriggerTime = (value: number | string) => { this.instance.SetOutAudioTalkoverTriggerTime(this.state.tkout_s, value); }
    onChangeTkOutAttackTIme = (value: number | string) => { this.instance.SetOutAudioTalkoverAttackTime(this.state.tkout_s, value); }
    onChangeTkOutHoldTime = (value: number | string) => { this.instance.SetOutAudioTalkoverHoldTime(this.state.tkout_s, value); }
    onChangeTkOutReleaseTIme = (value: number | string) => { this.instance.SetOutAudioTalkoverReleaseTime(this.state.tkout_s, value); }
    onChangeTkOutDepth = (value: number | string) => { this.instance.SetOutAudioTalkoverDepth(this.state.tkout_s, value); }
    onChangeTkOutThreshold = (value: number | string) => { this.instance.SetOutAudioTalkoverThreshold(this.state.tkout_s, value); }
    onChangeOutSelect = (value: string) => { this.setState({ out_s: value, temp_out_name: this.instance.OutAudioName(value) }); }
    onChangeInSelect = (value: string) => { this.setState({ in_s: value }); }
    onChangeMixerOutSelect = (value: string) => { this.setState({ mixout_s: value }); }
    onChangeMixerInSelect = (value: string) => { this.setState({ mixin_s: value }); }
    onChangeEQOutSelect = (value: string) => { this.setState({ eqout_s: value }); }
    onChangeEQFrequencySelect = (value: string) => { this.setState({ eqf_s: value }); }
    onChangeTalkoverOutSelect = (value: string) => { this.setState({ tkout_s: value }); }
    onSaveOutName = () => {
        const { out_s, temp_out_name } = this.state;
        if (temp_out_name) {
            this.instance.SetOutAudioName(out_s, temp_out_name);
            this.setState({ temp_out_name: undefined, out_name_err: '' });
        }
    }
    render() {
        const { out_s, in_s, mixin_s, mixout_s, eqout_s, eqf_s, tkout_s, temp_out_name, out_name_err } = this.state;
        const audOutRouteParam = this.instance.OutAudioRouteParam();
        const audOutMuteParam = this.instance.OutAudioMuteParam();
        const audInMuteParam = this.instance.InAudioMuteParam();
        const audInPWModeParam = this.instance.InAudioPowerModeParam();
        const tkOutParam = this.instance.OutAudioTalkoverParam();
        const audInPregainParam = this.instance.InAudioPregainParam();
        const audOutVolumeParam = this.instance.OutAudioVolumeParam();
        const audOutNameParam = this.instance.OutAudioNameParam();
        const audOutDeplayParam = this.instance.OutAudioDelayParam();
        const audOutTrebleParam = this.instance.OutAudioTrebleParam();
        const audOutBassParam = this.instance.OutAudioBassParam();
        const audOutEQParam = this.instance.OutAudioEQParam();
        const audOutFormatParam = this.instance.OutAudioFormatParam();
        const audOutDSPModeParam = this.instance.OutAudioDSPModeParam();
        const mixerInVolumeParam = this.instance.MixerInVolumeParam();
        const mixerOutVolumeParam = this.instance.MixerOutVolumeParam();
        const tkOutEnableParam = this.instance.OutAudioTalkoverParam();
        const tkOutTriggerTimeParam = this.instance.OutAudioTalkoverTriggerTimeParam();
        const tkOutAttackTimeParam = this.instance.OutAudioTalkoverAttackTimeParam();
        const tkOutHoldTimeParam = this.instance.OutAudioTalkoverHoldTimeParam();
        const tkOutReleaseTimeParam = this.instance.OutAudioTalkoverReleaseTimeParam();
        const tkOutDepthParam = this.instance.OutAudioTalkoverDepthParam();
        const tkOutThresholdParam = this.instance.OutAudioTalkoverThresholdParam();
        const outMuteParam = this.instance.OutAudioMuteParam();
        const inMuteParam = this.instance.InAudioMuteParam();
        const volKnoblockParam = this.instance.AudioVolumeKnoblockParam();
        const mixerEnableParam = this.instance.MixerEnableParam();
        const mixerInVolumeException = this.instance.MixerInVolumeInException(mixin_s);
        const audOutRouteException = this.instance.OutAudioRouteInException(out_s);
        const aud_out_opt = (audOutNameParam.x1.isOption) ? audOutNameParam.x1.isOption.option : [];
        const aud_out_desc = (audOutNameParam.x1.isOption) ? audOutNameParam.x1.isOption.desc : [];
        const aud_out_format_opt = (audOutFormatParam.s1.isOption) ? audOutFormatParam.s1.isOption.option : [];
        const aud_out_format_desc = (audOutFormatParam.s1.isOption) ? audOutFormatParam.s1.isOption.desc : [];
        const aud_out_dsp_opt = (audOutDSPModeParam.b1.isOption) ? audOutDSPModeParam.b1.isOption.option : [];
        const aud_out_dsp_desc = (audOutDSPModeParam.b1.isOption) ? audOutDSPModeParam.b1.isOption.desc : [];
        const aud_src_opt = (audOutRouteParam.n1.isOption) ? audOutRouteParam.n1.isOption.option : [];
        const aud_src_desc = (audOutRouteParam.n1.isOption) ? audOutRouteParam.n1.isOption.desc : [];
        let aud_in_opt: Array<string> = [];
        let aud_in_desc: Array<string> = [];
        // const out_vol_opt = (audOutVolumeParam.n1.isOption)?audOutVolumeParam.n1.isOption.option:[];
        // const out_vol_desc = (audOutVolumeParam.n1.isOption)?audOutVolumeParam.n1.isOption.desc:[];
        const in_pw_mode_opt = (audInPWModeParam.n2.isOption) ? audInPWModeParam.n2.isOption.option : [];
        const in_pw_mode_desc = (audInPWModeParam.n2.isOption) ? audInPWModeParam.n2.isOption.desc : [];
        // const out_treb_opt = (audOutTrebleParam.n1.isOption)?audOutTrebleParam.n1.isOption.option:[];
        // const out_bass_opt = (audOutBassParam.n1.isOption)?audOutBassParam.n1.isOption.option:[];
        // const in_pregain_opt = (audInPregainParam.n2.isOption)?audInPregainParam.n2.isOption.option:[];
        const mixer_in_opt = (mixerInVolumeParam.n1.isOption) ? mixerInVolumeParam.n1.isOption.option : [];
        const mixer_in_desc = (mixerInVolumeParam.n1.isOption) ? mixerInVolumeParam.n1.isOption.desc : [];
        const mixer_out_opt = (mixerInVolumeParam.n1.isOption) ? mixerInVolumeParam.n1.isOption.option : [];
        const mixer_out_desc = (mixerInVolumeParam.n1.isOption) ? mixerInVolumeParam.n1.isOption.desc : [];
        const tk_out_opt = (tkOutParam.x1.isOption) ? tkOutParam.x1.isOption.option : [];
        const tk_out_desc = (tkOutParam.x1.isOption) ? tkOutParam.x1.isOption.desc : [];
        // const mixin_vol_opt = (mixerInVolumeParam.n2.isOption)?mixerInVolumeParam.n2.isOption.option:[];
        // const mixin_vol_desc = (mixerInVolumeParam.n2.isOption)?mixerInVolumeParam.n2.isOption.desc:[];
        // const mixout_vol_opt = (mixerOutVolumeParam.n1.isOption)?mixerOutVolumeParam.n1.isOption.option:[];
        // const mixout_vol_desc = (mixerOutVolumeParam.n1.isOption)?mixerOutVolumeParam.n1.isOption.desc:[];
        // const eq_level_opt = (audOutEQParam.n2.isOption)?audOutEQParam.n2.isOption.option:[];
        // const eq_level_desc = (audOutEQParam.n2.isOption)?audOutEQParam.n2.isOption.desc:[];
        const eq_freq_opt = (audOutEQParam.n1.isOption) ? audOutEQParam.n1.isOption.option : [];
        const eq_freq_desc = (audOutEQParam.n1.isOption) ? audOutEQParam.n1.isOption.option : [];
        const out_mute_opt = (outMuteParam.b1.isOption) ? outMuteParam.b1.isOption.option : [];
        const out_mute_desc = (outMuteParam.b1.isOption) ? outMuteParam.b1.isOption.option : [];
        const in_mute_opt = (inMuteParam.b1.isOption) ? inMuteParam.b1.isOption.option : [];
        const in_mute_desc = (inMuteParam.b1.isOption) ? inMuteParam.b1.isOption.option : [];
        const vol_knoblock_opt = (volKnoblockParam.b1.isOption) ? volKnoblockParam.b1.isOption.option : [];
        const vol_knoblock_desc = (volKnoblockParam.b1.isOption) ? volKnoblockParam.b1.isOption.option : [];
        const mixer_enable_opt = (mixerEnableParam.b1.isOption) ? mixerEnableParam.b1.isOption.option : [];
        const mixer_enable_desc = (mixerEnableParam.b1.isOption) ? mixerEnableParam.b1.isOption.option : [];
        const tk_out_enable_opt = (tkOutEnableParam.b1.isOption) ? tkOutEnableParam.b1.isOption.option : [];
        const tk_out_enable_desc = (tkOutEnableParam.b1.isOption) ? tkOutEnableParam.b1.isOption.option : [];
        const aud_out_name = aud_out_opt.map(idx => this.instance.OutAudioName(idx));
        const aud_out_mute = this.instance.OutAudioMute(out_s);
        const aud_out_vol = this.instance.OutAudioVolume(out_s);
        const aud_out_src = this.instance.OutAudioRoute(out_s);
        const aud_out_delay = this.instance.OutAudioDelay(out_s);
        const aud_out_treble = this.instance.OutAudioTreble(out_s);
        const aud_out_bass = this.instance.OutAudioBass(out_s);
        const aud_in_mute = this.instance.InAudioMute(in_s);
        const aud_in_pregain = this.instance.InAudioPregain(in_s);
        const aud_out_name_s = this.instance.OutAudioName(out_s);
        const aud_in_pw_mode_s = this.instance.InAudioPowerMode(in_s);
        const mixer_enable = this.instance.MixerEnable();
        const mix_in_vol_s = this.instance.MixerInVolume(mixin_s);
        const mix_out_vol_s = this.instance.MixerOutVolume(mixout_s);
        const mix_out_src_s = this.instance.MixerOutRoute(mixout_s);
        const aud_out_eq_s = this.instance.OutAudioEQ(eqout_s, eqf_s);
        const tkout_on_s = this.instance.OutAudioTalkover(tkout_s);
        const tkout_trigtime_s = this.instance.OutAudioTalkoverTriggerTime(tkout_s);
        const tkout_atktime_s = this.instance.OutAudioTalkoverAttackTime(tkout_s);
        const tkout_holdtime_s = this.instance.OutAudioTalkoverHoldTime(tkout_s);
        const tkout_relstime_s = this.instance.OutAudioTalkoverReleaseTime(tkout_s);
        const tkout_depth_s = this.instance.OutAudioTalkoverDepth(tkout_s);
        const tkout_threshold_s = this.instance.OutAudioTalkoverThreshold(tkout_s);
        const vol_knoblock = this.instance.AudioVolumeKnoblock();
        const aud_out_format = this.instance.OutAudioFormat(out_s);
        const aud_out_format_idx = aud_out_format_opt.indexOf(aud_out_format);
        const aud_out_dsp_mode = this.instance.OutAudioDSPMode(out_s);
        const aud_in_pregain_val = (audInPregainParam.n1.isRange) ? parseInt(aud_in_pregain, audInPregainParam.n1.isRange.radix) : parseInt(aud_in_pregain);
        const aud_in_pregain_range: { min: number, max: number, step: number } = (audInPregainParam.n1.isRange) ? audInPregainParam.n1.isRange.range : { max: 100, min: 1, step: 1 }
        // check mute support port
        const aud_src_out_opt = (audOutRouteParam.x1.isOption) ? audOutRouteParam.x1.isOption.option : [];
        const aud_mute_out_opt = (audOutMuteParam.x1.isOption) ? audOutMuteParam.x1.isOption.option : [];
        const aud_vol_out_opt = (audOutVolumeParam.x1.isOption) ? audOutVolumeParam.x1.isOption.option : [];
        // check support output format
        const aud_out_format_out_opt = (audOutFormatParam.x1.isOption) ? audOutFormatParam.x1.isOption.option : [];
        // check support dsp mode
        const aud_dsp_out_opt = (audOutDSPModeParam.x1.isOption) ? audOutDSPModeParam.x1.isOption.option : [];
        // generate audio input list
        const aud_in_mute_opt = (audInMuteParam.n1.isOption) ? audInMuteParam.n1.isOption.option : [];
        const aud_in_mute_desc = (audInMuteParam.n1.isOption) ? audInMuteParam.n1.isOption.desc : [];
        const aud_in_pregain_opt = (audInPregainParam.n1.isOption) ? audInPregainParam.n1.isOption.option : [];
        const aud_in_pregain_desc = (audInPregainParam.n1.isOption) ? audInPregainParam.n1.isOption.desc : [];
        const aud_in_pwmode_opt = (audInPWModeParam.n1.isOption) ? audInPWModeParam.n1.isOption.option : [];
        const aud_in_pwmode_desc = (audInPWModeParam.n1.isOption) ? audInPWModeParam.n1.isOption.desc : [];
        for (let i = 0; i < aud_in_mute_opt.length; i++) {
            const opt = aud_in_mute_opt[i];
            const desc = (aud_in_mute_desc) ? (aud_in_mute_desc[i]?aud_in_mute_desc[i]:`Input ${opt}`) : `Input ${opt}`;
            if (aud_in_opt.indexOf(opt) < 0) {
                aud_in_opt.push(opt);
                aud_in_desc.push(desc);
            }
        }
        for (let i = 0; i < aud_in_pregain_opt.length; i++) {
            const opt = aud_in_pregain_opt[i];
            const desc = (aud_in_pregain_desc) ? (aud_in_pregain_desc[i]?aud_in_pregain_desc[i]:`Input ${opt}`) : `Input ${opt}`;
            if (aud_in_opt.indexOf(opt) < 0) {
                aud_in_opt.push(opt);
                aud_in_desc.push(desc);
            }
        }
        for (let i = 0; i < aud_in_pwmode_opt.length; i++) {
            const opt = aud_in_pwmode_opt[i];
            const desc = (aud_in_pwmode_desc) ? (aud_in_pwmode_desc[i]?aud_in_pwmode_desc[i]:`Input ${opt}`) : `Input ${opt}`;
            if (aud_in_opt.indexOf(opt) < 0) {
                aud_in_opt.push(opt);
                aud_in_desc.push(desc);
            }
        }
        // validate input
        // exception handle
        const mixer_in_volume_range = (mixerInVolumeParam.n2 && mixerInVolumeParam.n2.isRange) ? mixerInVolumeParam.n2.isRange : undefined;
        const mixer_in_volume_range_exception = (mixerInVolumeException.n2 && mixerInVolumeException.n2.isRange) ? mixerInVolumeException.n2.isRange : undefined;
        const aud_out_route_src_exception = (audOutRouteException.n1 && audOutRouteException.n1.isOption) ? audOutRouteException.n1.isOption : undefined;
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_AUDIO_OUT_LABEL')} style={notHiddenIf(this.instance.IsSupportOutRoute() || this.instance.IsSupportOutMute() || this.instance.IsSupportOutVolume() || this.instance.IsSupportOutNaming())} defaultExtend={true}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_OUT_PORT')}><Select value={out_s} placeholder='Select Port' onChange={this.onChangeOutSelect}>
                            {aud_out_opt.map((out_idx, idx) => {
                                const defaultName = (aud_out_desc) ? aud_out_desc[idx] : `Output ${out_idx}`;
                                return <Option value={out_idx} key={`aud_out_opt_${out_idx}`}>{CsxUtil.defaultValue(aud_out_name[idx], ((s) => s.length > 0), defaultName)}</Option>
                            })}
                        </Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_OUT_SRC')} disabled={out_s.length === 0} style={notHiddenIf(this.instance.IsSupportOutRoute() && aud_src_out_opt.indexOf(out_s) >= 0)}><Select value={aud_out_src} placeholder='Select Source' onChange={this.onChangeOutSource}>
                            {(aud_out_route_src_exception ? aud_out_route_src_exception.option : aud_src_opt).map((opt, idx) => <Option value={opt} key={`aud_src_opt_${opt}`}>{(aud_src_desc) ? aud_src_desc[aud_src_opt.indexOf(opt)] : opt}</Option>)}
                        </Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_OUT_FORMAT')} disabled={out_s.length === 0} style={notHiddenIf(this.instance.IsSupportOutAudioFormat() && aud_out_format_out_opt.indexOf(out_s) >= 0)}>
                            {aud_out_format_desc ? aud_out_format_desc[aud_out_format_idx] : aud_out_format}
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_OUT_MUTE')} disabled={out_s.length === 0} style={notHiddenIf(this.instance.IsSupportOutMute() && aud_mute_out_opt.indexOf(out_s) >= 0)}><SwitchButton checked={((out_mute_opt.length >= 2)?(aud_out_mute === out_mute_opt[0]):false)} label={(out_mute_desc?[ out_mute_desc[0], out_mute_desc[1] ]:undefined)} onChange={this.onChangeOutMute} /></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_OUT_VOL')} disabled={out_s.length === 0} style={notHiddenIf(this.instance.IsSupportOutVolume() && !!audOutVolumeParam.n1.isRange && aud_vol_out_opt.indexOf(out_s) >= 0)}>
                            <Slider
                                value={parseInt(aud_out_vol)}
                                max={(audOutVolumeParam.n1.isRange) ? audOutVolumeParam.n1.isRange.range.max : 100}
                                min={(audOutVolumeParam.n1.isRange) ? audOutVolumeParam.n1.isRange.range.min : 0}
                                step={(audOutVolumeParam.n1.isRange) ? audOutVolumeParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeOutVolume}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(aud_out_vol)}`}</div>
                        </Form.Item>
                        {/* <Form.Item label='Volume (dB)' disabled={out_s.length === 0} style={notHiddenIf(this.instance.IsSupportOutVolume() && !!audOutVolumeParam.n1.isOption)}><Select value={aud_out_vol} placeholder='Volume Level' onChange={this.onChangeOutVolume}>
                            {out_vol_opt.map((opt, idx) => { return <Option value={opt} key={`audout_vol_opt_${opt}`}>{(out_vol_desc)?out_vol_desc[idx]:opt}</Option> })}
                        </Select></Form.Item> */}
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_OUT_TREBLE')} disabled={out_s.length === 0} style={notHiddenIf(this.instance.IsSupportOutTreble() && !!audOutTrebleParam.n1.isRange)}>
                            <Slider
                                value={parseInt(aud_out_treble)}
                                max={(audOutTrebleParam.n1.isRange) ? audOutTrebleParam.n1.isRange.range.max : 100}
                                min={(audOutTrebleParam.n1.isRange) ? audOutTrebleParam.n1.isRange.range.min : 0}
                                step={(audOutTrebleParam.n1.isRange) ? audOutTrebleParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeOutTreble}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(aud_out_treble)}`}</div>
                        </Form.Item>
                        {/* <Form.Item label='Treble (dB)' disabled={out_s.length === 0} style={notHiddenIf(this.instance.IsSupportOutTreble() && !!audOutTrebleParam.n1.isOption)}><Select value={aud_out_treble} placeholder='Treble Level' onChange={this.onChangeOutTreble}>
                            {out_treb_opt.map((opt) => { return <Option value={opt} key={`audout_treb_opt_${opt}`}>{opt}</Option> })}
                        </Select></Form.Item> */}
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_OUT_BASS')} disabled={out_s.length === 0} style={notHiddenIf(this.instance.IsSupportOutBass() && !!audOutBassParam.n1.isRange)}>
                            <Slider
                                value={parseInt(aud_out_bass)}
                                max={(audOutBassParam.n1.isRange) ? audOutBassParam.n1.isRange.range.max : 100}
                                min={(audOutBassParam.n1.isRange) ? audOutBassParam.n1.isRange.range.min : 0}
                                step={(audOutBassParam.n1.isRange) ? audOutBassParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeOutBass}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(aud_out_bass)}`}</div>
                        </Form.Item>
                        {/* <Form.Item label='Bass (dB)' disabled={out_s.length === 0} style={notHiddenIf(this.instance.IsSupportOutBass() && !!audOutTrebleParam.n1.isOption)}><Select value={aud_out_bass} placeholder='Bass Level' onChange={this.onChangeOutTreble}>
                            {out_bass_opt.map((opt) => { return <Option value={opt} key={`audout_bass_opt_${opt}`}>{opt}</Option> })}
                        </Select></Form.Item> */}
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_OUT_DELAY')} disabled={out_s.length === 0} style={notHiddenIf(this.instance.IsSupportOutDelay() && !!audOutDeplayParam.n1.isRange)}>
                            <Slider
                                value={parseInt(aud_out_delay)}
                                max={(audOutDeplayParam.n1.isRange) ? audOutDeplayParam.n1.isRange.range.max : 100}
                                min={(audOutDeplayParam.n1.isRange) ? audOutDeplayParam.n1.isRange.range.min : 0}
                                step={(audOutDeplayParam.n1.isRange) ? audOutDeplayParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeOutDelay}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(aud_out_delay)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_OUT_NAME')} error={out_name_err} style={notHiddenIf(this.instance.IsSupportOutNaming() && out_s.length > 0)}>
                            <Input value={(typeof temp_out_name === 'string') ? temp_out_name : aud_out_name_s} onChange={this.onChangeOutName} />
                            <Button type='primary' disabled={out_name_err.length > 0} onClick={this.onSaveOutName}>{getText('SAVE')}</Button>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_OUT_DSP_MODE')} disabled={out_s.length === 0} style={notHiddenIf(this.instance.IsSupportOutAudioDSPMode() && aud_dsp_out_opt.indexOf(out_s) >= 0)}><SwitchButton checked={((aud_out_dsp_opt.length >= 2)?(aud_out_dsp_mode === aud_out_dsp_opt[0]):false)} label={(aud_out_dsp_desc?[ aud_out_dsp_desc[0], aud_out_dsp_desc[1] ]:undefined)} onChange={this.onChangeOutDSPMode} /></Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_AUDIO_IN_LABEL')} style={notHiddenIf(this.instance.IsSupportInMute() || this.instance.IsSupportInPowerMode() || this.instance.IsSupportInPregain())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_IN_PORT')}><Select value={in_s} placeholder='Select Port' onChange={this.onChangeInSelect}>
                            {aud_in_opt.map((in_idx, idx) => {
                                const defaultName = (aud_in_desc) ? aud_in_desc[idx] : `Input ${in_idx}`;
                                return <Option value={in_idx} key={`audin_opt_${in_idx}`}>{defaultName}</Option>;
                            })}
                        </Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_IN_MUTE')} disabled={in_s.length === 0} style={notHiddenIf(this.instance.IsSupportInMute() && aud_in_mute_opt.indexOf(in_s) >= 0)}><SwitchButton checked={((in_mute_opt.length >= 2)?(aud_in_mute === in_mute_opt[0]):false)} label={(in_mute_desc?[ in_mute_desc[0], in_mute_desc[1] ]:undefined)} onChange={this.onChangeInMute} /></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_IN_PREGAIN')} disabled={in_s.length === 0} style={notHiddenIf(this.instance.IsSupportInPregain() && aud_in_pregain_opt.indexOf(in_s) >= 0 && !!audInPregainParam.n2.isRange)}>
                            <Button icon='minus' style={notHiddenIf(this.instance.IsSupportInAudioPregainDown())} disabled={aud_in_pregain_val <= aud_in_pregain_range.min} onClick={() => { this.instance.SetInAudioPregainDown(in_s); }} />
                            <Slider
                                value={aud_in_pregain_val}
                                max={aud_in_pregain_range.max}
                                min={aud_in_pregain_range.min}
                                step={aud_in_pregain_range.step}
                                onAfterChange={this.onChangeInPregain}
                            />
                            <div style={slider_show_value_style}>{`${aud_in_pregain_val}`}</div>
                            <Button icon='plus' style={notHiddenIf(this.instance.IsSupportInAudioPregainUp())} disabled={aud_in_pregain_val >= aud_in_pregain_range.max} onClick={() => { this.instance.SetInAudioPregainUp(in_s); }} />
                        </Form.Item>
                        {/* <Form.Item label='Pre-gain (dB)' disabled={in_s.length === 0} style={notHiddenIf(this.instance.IsSupportInPregain() && !!audOutVolumeParam.n1.isOption)}><Select value={aud_in_pregain} placeholder='Pregain Level' onChange={this.onChangeInPregain}>
                            {in_pregain_opt.map((opt) => { return <Option value={opt} key={`audin_gain_opt_${opt}`}>{opt}</Option> })}
                        </Select></Form.Item> */}
                        <Form.Item label='Power Mode' disabled={in_s.length === 0} style={notHiddenIf(this.instance.IsSupportInPowerMode() && aud_in_pwmode_opt.indexOf(in_s) >= 0)}><Select value={aud_in_pw_mode_s} placeholder='Power Mode' onChange={this.onChangeInPowerMode}>
                            {in_pw_mode_opt.map((opt, idx) => { return <Option value={opt} key={`audin_pm_opt_${opt}`}>{(in_pw_mode_desc) ? in_pw_mode_desc[idx] : opt}</Option> })}
                        </Select></Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_AUDIO_KNOB_LABEL')} style={notHiddenIf(this.instance.IsSupportKnoblock())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_KNOB_LOCK')}><SwitchButton checked={((vol_knoblock_opt.length >= 2)?(vol_knoblock === vol_knoblock_opt[0]):false)} label={(vol_knoblock_desc?[ vol_knoblock_desc[0], vol_knoblock_desc[1] ]:undefined)} onChange={this.onChangeKnobLock} /></Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_AUDIO_EQ_LABEL')} style={notHiddenIf(this.instance.IsSupportOutEQ())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_EQ_OUT_SELECT')}><Select value={eqout_s} placeholder='Select Output' onChange={this.onChangeEQOutSelect}>
                            {aud_out_opt.map((out_idx, idx) => {
                                const defaultName = (aud_out_desc) ? aud_out_desc[idx] : `Output ${out_idx}`;
                                return <Option value={out_idx} key={`eq_out_opt_${out_idx}`}>{CsxUtil.defaultValue(aud_out_name[idx], ((s) => s.length > 0), defaultName)}</Option>
                            })}
                        </Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_EQ_FREQ')} disabled={eqout_s.length === 0}><Select value={eqf_s} placeholder='Select Frequency' onChange={this.onChangeEQFrequencySelect}>
                            {eq_freq_opt.map((freq_opt, freq_idx) => <Option value={freq_opt} key={`eq_freq_opt_${freq_opt}`}>{(eq_freq_desc) ? eq_freq_desc[freq_idx] : `Frequency ${freq_opt}`}</Option>)}
                        </Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_EQ_LEVEL')} disabled={eqout_s.length === 0} style={notHiddenIf(!!audOutEQParam.n2.isRange)}>
                            <Button icon='minus' shape='round' size='small' style={notHiddenIf(this.instance.IsSupportOutEQMinus())} />
                            <Slider
                                value={parseInt(aud_out_eq_s)}
                                max={(audOutEQParam.n2.isRange) ? audOutEQParam.n2.isRange.range.max : 100}
                                min={(audOutEQParam.n2.isRange) ? audOutEQParam.n2.isRange.range.min : 1}
                                step={(audOutEQParam.n2.isRange) ? audOutEQParam.n2.isRange.range.step : 1}
                                onAfterChange={this.onChangeOutEQ}
                            />
                            <Button icon='plus' shape='round' size='small' style={notHiddenIf(this.instance.IsSupportOutEQPlus())} />
                        </Form.Item>
                        {/* <Form.Item label='Level' disabled={eqout_s.length === 0} style={notHiddenIf(this.instance.IsSupportOutEQMinus() && !!audOutEQParam.n2.isOption)}><Select value={aud_out_eq_s} placeholder='Select EQ Level' onChange={this.onChangeOutEQ}>
                            {eq_level_opt.map((opt, idx) => { return <Option value={opt} key={`eq_level_opt_${opt}`}>{(eq_level_desc) ? eq_level_desc[idx] : opt}</Option> })}
                        </Select></Form.Item> */}
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_AUDIO_MIXER_LABEL')} style={notHiddenIf(this.instance.IsSupportMixer())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_MIXER_ON')}><SwitchButton checked={((mixer_enable_opt.length >= 2)?(mixer_enable === mixer_enable_opt[0]):false)} label={(mixer_enable_desc?[ mixer_enable_desc[0], mixer_enable_desc[1] ]:undefined)} onChange={this.onChangeMixerEnable} /></Form.Item>
                        <Flat.Field title={getText('DEVICE_FUNC_AUDIO_MIXER_IN_LABEL')} style={notHiddenIf(this.instance.IsSupportMixerInVolume() && mixer_enable === mixer_enable_opt[0])}>
                            <Form.Form labelStyle={{ width: '150px' }}>
                                <Form.Item label={getText('DEVICE_FUNC_AUDIO_MIXER_IN_SELECT')}><Select value={mixin_s} placeholder='Select Mixer Input' onChange={this.onChangeMixerInSelect}>
                                    {mixer_in_opt.map((in_opt, in_idx) => { return <Option value={in_opt} key={`mixin_opt_${in_opt}`}>{(mixer_in_desc) ? mixer_in_desc[in_idx] : in_opt}</Option> })}
                                </Select></Form.Item>
                                <Form.Item label={getText('DEVICE_FUNC_AUDIO_MIXER_IN_VOL')} style={notHiddenIf(this.instance.IsSupportMixerInVolume() && mixin_s.length > 0 && !!mixerInVolumeParam.n2.isRange)}>
                                    <Slider
                                        value={parseInt(mix_in_vol_s)}
                                        max={(mixer_in_volume_range_exception) ? mixer_in_volume_range_exception.range.max : (mixer_in_volume_range ? mixer_in_volume_range.range.max : 100)}
                                        min={(mixer_in_volume_range_exception) ? mixer_in_volume_range_exception.range.min : (mixer_in_volume_range ? mixer_in_volume_range.range.min : 1)}
                                        step={(mixer_in_volume_range_exception) ? mixer_in_volume_range_exception.range.step : (mixer_in_volume_range ? mixer_in_volume_range.range.step : 1)}
                                        onAfterChange={this.onChangeMixerInVolume}
                                    />
                                    <div style={slider_show_value_style}>{`${parseInt(mix_in_vol_s)}`}</div>
                                </Form.Item>
                                {/* <Form.Item label='Volume(dB)' disabled={mixin_s.length === 0} style={notHiddenIf(this.instance.IsSupportMixerInVolume() && !!mixerInVolumeParam.n2.isOption)}><Select value={mix_out_vol_s} placeholder='Mixer Volume' onChange={this.onChangeMixerOutVolume}>
                            {mixin_vol_opt.map((vol_opt, vol_idx) => { return <Option value={vol_opt} key={`mixin_vol_opt_${vol_opt}`}>{(mixin_vol_desc) ? mixin_vol_desc[vol_idx] : vol_opt}</Option> })}
                        </Select></Form.Item> */}
                            </Form.Form>
                        </Flat.Field>
                        <Flat.Field title={getText('DEVICE_FUNC_AUDIO_MIXER_OUT_LABEL')} style={notHiddenIf(this.instance.IsSupportMixerOutVolume() && mixer_enable === mixer_enable_opt[0])}>
                            <Form.Form labelStyle={{ width: '150px' }}>
                                <Form.Item label={getText('DEVICE_FUNC_AUDIO_MIXER_OUT_SELECT')}><Select value={mixout_s} placeholder='Select Mixer Output' onChange={this.onChangeMixerOutSelect}>
                                    {mixer_out_opt.map((out_opt, out_idx) => { return <Option value={out_opt} key={`mixout_opt_${out_opt}`}>{(mixer_out_desc) ? mixer_out_desc[out_idx] : out_opt}</Option> })}
                                </Select></Form.Item>
                                <Form.Item label={getText('DEVICE_FUNC_AUDIO_MIXER_OUT_VOL')} disabled={mixout_s.length === 0} style={notHiddenIf(this.instance.IsSupportMixerOutVolume() && !!mixerOutVolumeParam.n1.isRange)}>
                                    <Slider
                                        value={parseInt(mix_out_vol_s)}
                                        max={(mixerOutVolumeParam.n1.isRange) ? mixerOutVolumeParam.n1.isRange.range.max : 100}
                                        min={(mixerOutVolumeParam.n1.isRange) ? mixerOutVolumeParam.n1.isRange.range.min : 1}
                                        step={(mixerOutVolumeParam.n1.isRange) ? mixerOutVolumeParam.n1.isRange.range.step : 1}
                                        onAfterChange={this.onChangeMixerOutVolume}
                                    />
                                    <div style={slider_show_value_style}>{`${parseInt(mix_out_vol_s)}`}</div>
                                </Form.Item>
                                {/* <Form.Item label='Volume(dB)' disabled={mixout_s.length === 0} style={notHiddenIf(this.instance.IsSupportMixerOutVolume() && !!mixerOutVolumeParam.n1.isOption)}><Select value={mix_in_vol_s} placeholder='Mixer Volume' onChange={this.onChangeMixerInVolume}>
                                    {mixout_vol_opt.map((vol_opt, vol_idx) => { return <Option value={vol_opt} key={`mixout_vol_opt_${vol_opt}`}>{(mixout_vol_desc) ? mixout_vol_desc[vol_idx] : vol_opt}</Option> })}
                                </Select></Form.Item> */}
                                <Form.Item label={getText('DEVICE_FUNC_AUDIO_MIXER_OUT_SRC')} disabled={mixout_s.length === 0} style={notHiddenIf(this.instance.IsSupportMixerOutRoute())}><Select value={mix_out_src_s} placeholder='Select Source' onChange={this.onChangeMixerOutRoute}>
                                    {aud_in_opt.map((in_idx, idx) => {
                                        const defaultName = (aud_in_desc) ? aud_in_desc[idx] : `Input ${in_idx}`;
                                        return <Option value={in_idx} key={`mixout_src_opt_${in_idx}`}>{defaultName}</Option>;
                                    })}
                                </Select></Form.Item>
                            </Form.Form>
                        </Flat.Field>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_AUDIO_TALKOVER_LABEL')} style={notHiddenIf(this.instance.IsSupportTalkover())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_TALKOVER_SELECT')}><Select value={tkout_s} placeholder='Select Output' onChange={this.onChangeTalkoverOutSelect}>
                            {tk_out_opt.map((out_opt, out_idx) => { return <Option value={out_opt} key={`tkout_opt_${out_opt}`}>{(tk_out_desc) ? tk_out_desc[out_idx] : out_opt}</Option> })}
                        </Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_TALKOVER_ON')} disabled={tkout_s.length === 0}><SwitchButton checked={((tk_out_enable_opt.length >= 2)?(tkout_on_s === tk_out_enable_opt[0]):false)} label={(tk_out_enable_desc?[ tk_out_enable_desc[0], tk_out_enable_desc[1] ]:undefined)} onChange={this.onChangeTkOutEnable} /></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_TALKOVER_TRG_TIME')} disabled={tkout_s.length === 0}>
                            <Slider
                                value={parseInt(tkout_trigtime_s)}
                                max={(tkOutTriggerTimeParam.n1.isRange) ? tkOutTriggerTimeParam.n1.isRange.range.max : 100}
                                min={(tkOutTriggerTimeParam.n1.isRange) ? tkOutTriggerTimeParam.n1.isRange.range.min : 1}
                                step={(tkOutTriggerTimeParam.n1.isRange) ? tkOutTriggerTimeParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeTkOutTriggerTime}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(tkout_trigtime_s)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_TALKOVER_ATK_TIME')} disabled={tkout_s.length === 0}>
                            <Slider
                                value={parseInt(tkout_atktime_s)}
                                max={(tkOutAttackTimeParam.n1.isRange) ? tkOutAttackTimeParam.n1.isRange.range.max : 100}
                                min={(tkOutAttackTimeParam.n1.isRange) ? tkOutAttackTimeParam.n1.isRange.range.min : 1}
                                step={(tkOutAttackTimeParam.n1.isRange) ? tkOutAttackTimeParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeTkOutAttackTIme}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(tkout_atktime_s)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_TALKOVER_HLD_TIME')} disabled={tkout_s.length === 0}>
                            <Slider
                                value={parseInt(tkout_holdtime_s)}
                                max={(tkOutHoldTimeParam.n1.isRange) ? tkOutHoldTimeParam.n1.isRange.range.max : 100}
                                min={(tkOutHoldTimeParam.n1.isRange) ? tkOutHoldTimeParam.n1.isRange.range.min : 1}
                                step={(tkOutHoldTimeParam.n1.isRange) ? tkOutHoldTimeParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeTkOutHoldTime}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(tkout_holdtime_s)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_TALKOVER_RLS_TIME')} disabled={tkout_s.length === 0}>
                            <Slider
                                value={parseInt(tkout_relstime_s)}
                                max={(tkOutReleaseTimeParam.n1.isRange) ? tkOutReleaseTimeParam.n1.isRange.range.max : 100}
                                min={(tkOutReleaseTimeParam.n1.isRange) ? tkOutReleaseTimeParam.n1.isRange.range.min : 1}
                                step={(tkOutReleaseTimeParam.n1.isRange) ? tkOutReleaseTimeParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeTkOutReleaseTIme}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(tkout_relstime_s)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_TALKOVER_DPT')} disabled={tkout_s.length === 0}>
                            <Slider
                                value={parseInt(tkout_depth_s)}
                                max={(tkOutDepthParam.n1.isRange) ? tkOutDepthParam.n1.isRange.range.max : 100}
                                min={(tkOutDepthParam.n1.isRange) ? tkOutDepthParam.n1.isRange.range.min : 1}
                                step={(tkOutDepthParam.n1.isRange) ? tkOutDepthParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeTkOutDepth}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(tkout_depth_s)}`}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_AUDIO_TALKOVER_THD')} disabled={tkout_s.length === 0}>
                            <Slider
                                value={parseInt(tkout_threshold_s)}
                                max={(tkOutThresholdParam.n1.isRange) ? tkOutThresholdParam.n1.isRange.range.max : 100}
                                min={(tkOutThresholdParam.n1.isRange) ? tkOutThresholdParam.n1.isRange.range.min : 1}
                                step={(tkOutThresholdParam.n1.isRange) ? tkOutThresholdParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeTkOutThreshold}
                            />
                            <div style={slider_show_value_style}>{`${parseInt(tkout_threshold_s)}`}</div>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

class ScalerView extends CsxUI.IRQComponent<any> {
    state: {
        out_select: string; in_select: string; win_select: string; vga_in_select: string
    }
    instance: CsxFeature.CsxScalerDevice
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'DeviceRealTime';
        this.MIN_RENDER_INTERVAL = 100;
        this.instance = new CsxFeature.CsxScalerDevice(window.FOCUS_DEVICE);
        const vgaInPhaseParam = this.instance.InPhaseParam();
        const vga_in_opt = (vgaInPhaseParam.n1.isOption) ? vgaInPhaseParam.n1.isOption.option : [];
        this.state = {
            out_select: 'a', in_select: '1', win_select: '', vga_in_select: ((vga_in_opt.length > 0) ? vga_in_opt[0] : ''),
        }
    }
    onChange4k2kDownscale = (value: string) => { this.instance.SetOut4K2KDownscaleMode(this.state.out_select, value); }
    onChange4k2kUpscale = (value: string) => { this.instance.SetOut4K2KUpscaleMode(this.state.out_select, value); }
    onChangeWinSelect = (value: string) => { this.setState({ win_select: value }); }
    onChangeInSelect = (value: string) => { this.setState({ in_select: value }); }
    onChangeOutSelect = (value: string) => { this.setState({ out_select: value }); }
    onChangeInVGA = (value: string) => { this.setState({ vga_in_select: value }); }
    onChangeTiming = (value: string) => { this.instance.SetOutTiming(this.state.out_select, value); }
    onChangeAR = (value: string) => { this.instance.SetOutAspectRatio(this.state.out_select, value); }
    onChangeContrast = (value: number) => { this.instance.SetOutContrast(this.state.out_select, value); }
    onChangeBrightness = (value: number) => { this.instance.SetOutBrightness(this.state.out_select, value); }
    onChangeSaturation = (value: number) => { this.instance.SetOutSaturation(this.state.out_select, value); }
    onChangeHue = (value: number) => { this.instance.SetOutHue(this.state.out_select, value); }
    onChangeSharpness = (value: number) => { this.instance.SetOutSharpness(this.state.out_select, value); }
    onChangeRGain = (value: number) => { this.instance.SetOutRedGain(this.state.out_select, value); }
    onChangeGGain = (value: number) => { this.instance.SetOutGreenGain(this.state.out_select, value); }
    onChangeBGain = (value: number) => { this.instance.SetOutBlueGain(this.state.out_select, value); }
    onChangeWinLayoutMode = (value: string) => { this.instance.SetWindowLayoutMode(value); }
    onChangeWinRoute = (value: string) => { this.instance.SetWindowRoute(this.state.win_select, value); }
    onChangeWinBorderColor = (value: string) => { this.instance.SetWindowBorderColor(this.state.win_select, value); }
    onChangeWinBorderSize = (value: number) => { this.instance.SetWindowBorderSize(this.state.win_select, value); }
    onChangeWinHPIPSize = (value: number) => { this.instance.SetHPIPSize(value); }
    onChangeWinVPIPSize = (value: number) => { this.instance.SetVPIPSize(value); }
    onChangeWinHPIPPos = (value: number) => { this.instance.SetHPIPPosition(value); }
    onChangeWinVPIPPos = (value: number) => { this.instance.SetVPIPPosition(value); }
    onChangeDsiplayMode = (value: string) => { this.instance.SetDisplayMode(value); }
    render() {
        const { out_select, in_select, win_select } = this.state;
        // const { out_select, in_select, win_select, vga_in_select } = this.state;
        const outTimingParam = this.instance.OutTimingParam();
        const outARParam = this.instance.OutAspectRatioParam();
        const outContrastParam = this.instance.OutContrastParam();
        const outBrightParam = this.instance.OutBrightnessParam();
        const outSaturationParam = this.instance.OutSaturationParam();
        const outHueParam = this.instance.OutHueParam();
        const outSharpParam = this.instance.OutSharpnessParam();
        const outRGParam = this.instance.OutRedGainParam();
        const outGGParam = this.instance.OutGreenGainParam();
        const outBGParam = this.instance.OutBlueGainParam();
        const winRouteParam = this.instance.WindowRouteParam();
        const winModeParam = this.instance.WindowLayoutModeParam();
        const winBorderColorParam = this.instance.WindowBorderColorParam();
        const winBorderSizeParam = this.instance.WindowBorderSizeParam();
        const pipDisplayModeParam = this.instance.DisplayModeParam();
        const pipHSizeParam = this.instance.HPIPSizeParam();
        const pipVSizeParam = this.instance.VPIPSizeParam();
        const pipHPositionParam = this.instance.HPIPPositionParam();
        const pipVPositionParam = this.instance.VPIPPositionParam();
        const uhdDownscaleModeParam = this.instance.Out4K2KDownscaleModeParam();
        const uhdUpscaleModeParam = this.instance.Out4K2KUpscaleModeParam();
        // const vgaInPhaseParam = this.instance.InPhaseParam();
        const out_opt = (outTimingParam.x1.isOption) ? outTimingParam.x1.isOption.option : [];
        const out_desc = (outTimingParam.x1.isOption) ? outTimingParam.x1.isOption.desc : [];
        const timing_opt = (outTimingParam.n1.isOption) ? outTimingParam.n1.isOption.option : [];
        const timing_desc = (outTimingParam.n1.isOption) ? outTimingParam.n1.isOption.desc : [];
        const ar_opt = (outARParam.n1.isOption) ? outARParam.n1.isOption.option : [];
        const ar_desc = (outARParam.n1.isOption) ? outARParam.n1.isOption.desc : [];
        const win_opt = (winRouteParam.n1.isOption) ? winRouteParam.n1.isOption.option : [];
        const win_desc = (winRouteParam.n1.isOption) ? winRouteParam.n1.isOption.desc : [];
        const win_src_opt = (winRouteParam.n2.isOption) ? winRouteParam.n2.isOption.option : [];
        const win_src_desc = (winRouteParam.n2.isOption) ? winRouteParam.n2.isOption.desc : [];
        const win_mode_opt = (winModeParam.n1.isOption) ? winModeParam.n1.isOption.option : [];
        const win_mode_desc = (winModeParam.n1.isOption) ? winModeParam.n1.isOption.desc : [];
        const border_color_opt = (winBorderColorParam.n2.isOption) ? winBorderColorParam.n2.isOption.option : [];
        const border_color_desc = (winBorderColorParam.n2.isOption) ? winBorderColorParam.n2.isOption.desc : [];
        const display_mode_opt = (pipDisplayModeParam.n1.isOption) ? pipDisplayModeParam.n1.isOption.option : [];
        const display_mode_desc = (pipDisplayModeParam.n1.isOption) ? pipDisplayModeParam.n1.isOption.desc : [];
        const uhd_downscale_opt = (uhdDownscaleModeParam.n1.isOption) ? uhdDownscaleModeParam.n1.isOption.option : [];
        const uhd_downscale_desc = (uhdDownscaleModeParam.n1.isOption) ? uhdDownscaleModeParam.n1.isOption.desc : [];
        const uhd_upscale_opt = (uhdUpscaleModeParam.n1.isOption) ? uhdUpscaleModeParam.n1.isOption.option : [];
        const uhd_upscale_desc = (uhdUpscaleModeParam.n1.isOption) ? uhdUpscaleModeParam.n1.isOption.desc : [];
        // const vga_in_opt = (vgaInPhaseParam.n1.isOption) ? vgaInPhaseParam.n1.isOption.option : [];
        // const vga_in_desc = (vgaInPhaseParam.n1.isOption) ? vgaInPhaseParam.n1.isOption.desc : [];
        const timing = this.instance.OutTiming(out_select);
        const winBrdSize = parseInt(this.instance.WindowBorderSize(win_select));
        const winRoute = this.instance.WindowRoute(win_select);
        const winBrdColor = this.instance.WindowBorderColor(win_select);
        const contrast = parseInt(this.instance.OutContrast(out_select));
        const brightness = parseInt(this.instance.OutBrightness(out_select));
        const saturation = parseInt(this.instance.OutSaturation(out_select));
        const hue = parseInt(this.instance.OutHue(out_select));
        const sharpness = parseInt(this.instance.OutSharpness(out_select));
        const rgain = parseInt(this.instance.OutRedGain(out_select));
        const ggain = parseInt(this.instance.OutGreenGain(out_select));
        const bgain = parseInt(this.instance.OutBlueGain(out_select));
        const ar = this.instance.OutAspectRatio(out_select);
        // const phase = this.instance.InPhase(in_select);
        // const clock = this.instance.InClock(in_select);
        // const inHPos = this.instance.InHPosition(in_select);
        // const inVPos = this.instance.InVPosition(in_select);
        // const inCorrectTiming = this.instance.InCorrectTiming(in_select);
        const UHDDownscaleMode = this.instance.Out4K2KDownscaleMode(in_select);
        const UHDUpscaleMode = this.instance.Out4K2KUpscaleMode(in_select);
        // const inHActive = this.instance.InHActive(in_select);
        // const inVActive = this.instance.InVActive(in_select);
        // const inRR = this.instance.InRefreshRate(in_select);
        // const inItlc = this.instance.InInterlace(in_select);
        // const outCSpace = this.instance.OutColorSpace(out_select);
        // const outItlc = this.instance.OutInterlace(out_select);
        // const outType = this.instance.OutType(out_select);
        // const inType = this.instance.InType(in_select);
        const winLayoutMode = this.instance.WindowLayoutMode();
        const displayMode = this.instance.DisplayMode();
        const HPIPSize = parseInt(this.instance.HPIPSize());
        const VPIPSize = parseInt(this.instance.VPIPSize());
        const VPIPPos = parseInt(this.instance.VPIPPosition());
        const HPIPPos = parseInt(this.instance.HPIPPosition());
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_SCALER_OUT_LABEL')} style={notHiddenIf(this.instance.IsSupportTimingSwitch())} defaultExtend={true}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_OUT_SELECT')}><Select value={out_select} placeholder='Select Port' onChange={this.onChangeOutSelect}>
                            {out_opt.map((opt, idx) => <Option value={opt} key={`out_opt_${opt}`}>{(out_desc) ? out_desc[idx] : opt}</Option>)}
                        </Select></Form.Item>
                    </Form.Form>
                    <Form.Form style={notHiddenIf(out_select.length > 0)} labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_OUT_TIMING')} disabled={out_select.length === 0} style={notHiddenIf(this.instance.IsSupportTimingSwitch())}><Select value={timing} placeholder='Select Timing' onChange={this.onChangeTiming}>
                            {timing_opt.map((opt, idx) => <Option value={opt} key={`timing_opt_${opt}`}>{(timing_desc) ? timing_desc[idx] : opt}</Option>)}
                        </Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_OUT_AR')} disabled={out_select.length === 0} style={notHiddenIf(this.instance.IsSupportAspectRatio())}><Select value={ar} placeholder='Select AR' onChange={this.onChangeAR}>
                            {ar_opt.map((opt, idx) => <Option value={opt} key={`ar_opt_${opt}`}>{(ar_desc) ? ar_desc[idx] : opt}</Option>)}
                        </Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_OUT_CONTRAST')} disabled={out_select.length === 0} style={notHiddenIf(this.instance.IsSupportContrast())}>
                            <Button icon='minus' disabled={contrast <= ((outContrastParam.n1.isRange) ? outContrastParam.n1.isRange.range.min : 1)} onClick={() => { this.onChangeContrast(contrast - ((outContrastParam.n1.isRange) ? outContrastParam.n1.isRange.range.step : 1)); }} />
                            <Slider
                                value={contrast}
                                max={(outContrastParam.n1.isRange) ? outContrastParam.n1.isRange.range.max : 100}
                                min={(outContrastParam.n1.isRange) ? outContrastParam.n1.isRange.range.min : 1}
                                step={(outContrastParam.n1.isRange) ? outContrastParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeContrast}
                            />
                            <div style={slider_show_value_style}>{contrast}</div>
                            <Button icon='plus' disabled={contrast >= ((outContrastParam.n1.isRange) ? outContrastParam.n1.isRange.range.max : 100)} onClick={() => { this.onChangeContrast(contrast + ((outContrastParam.n1.isRange) ? outContrastParam.n1.isRange.range.step : 1)); }} />
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_OUT_BRIGHTNESS')} disabled={out_select.length === 0} style={notHiddenIf(this.instance.IsSupportBrightness())}>
                            <Button icon='minus' disabled={brightness <= ((outBrightParam.n1.isRange) ? outBrightParam.n1.isRange.range.min : 1)} onClick={() => { this.onChangeBrightness(brightness - ((outBrightParam.n1.isRange) ? outBrightParam.n1.isRange.range.step : 1)); }} />
                            <Slider
                                value={brightness}
                                max={(outBrightParam.n1.isRange) ? outBrightParam.n1.isRange.range.max : 100}
                                min={(outBrightParam.n1.isRange) ? outBrightParam.n1.isRange.range.min : 1}
                                step={(outBrightParam.n1.isRange) ? outBrightParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeBrightness}
                            />
                            <div style={slider_show_value_style}>{brightness}</div>
                            <Button icon='plus' disabled={brightness >= ((outBrightParam.n1.isRange) ? outBrightParam.n1.isRange.range.max : 100)} onClick={() => { this.onChangeBrightness(brightness + ((outBrightParam.n1.isRange) ? outBrightParam.n1.isRange.range.step : 1)); }} />
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_OUT_SATURATION')} disabled={out_select.length === 0} style={notHiddenIf(this.instance.IsSupportSaturation())}>
                            <Button icon='minus' disabled={saturation <= ((outSaturationParam.n1.isRange) ? outSaturationParam.n1.isRange.range.min : 1)} onClick={() => { this.onChangeSaturation(saturation - ((outSaturationParam.n1.isRange) ? outSaturationParam.n1.isRange.range.step : 1)); }} />
                            <Slider
                                value={saturation}
                                max={(outSaturationParam.n1.isRange) ? outSaturationParam.n1.isRange.range.max : 100}
                                min={(outSaturationParam.n1.isRange) ? outSaturationParam.n1.isRange.range.min : 1}
                                step={(outSaturationParam.n1.isRange) ? outSaturationParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeSaturation}
                            />
                            <div style={slider_show_value_style}>{saturation}</div>
                            <Button icon='plus' disabled={saturation >= ((outSaturationParam.n1.isRange) ? outSaturationParam.n1.isRange.range.max : 100)} onClick={() => { this.onChangeSaturation(saturation + ((outSaturationParam.n1.isRange) ? outSaturationParam.n1.isRange.range.step : 1)); }} />
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_OUT_HUE')} disabled={out_select.length === 0} style={notHiddenIf(this.instance.IsSupportHue())}>
                            <Button icon='minus' disabled={hue <= ((outHueParam.n1.isRange) ? outHueParam.n1.isRange.range.min : 1)} onClick={() => { this.onChangeHue(hue - ((outHueParam.n1.isRange) ? outHueParam.n1.isRange.range.step : 1)); }} />
                            <Slider
                                value={hue}
                                max={(outHueParam.n1.isRange) ? outHueParam.n1.isRange.range.max : 100}
                                min={(outHueParam.n1.isRange) ? outHueParam.n1.isRange.range.min : 1}
                                step={(outHueParam.n1.isRange) ? outHueParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeHue}
                            />
                            <div style={slider_show_value_style}>{hue}</div>
                            <Button icon='plus' disabled={hue >= ((outHueParam.n1.isRange) ? outHueParam.n1.isRange.range.max : 100)} onClick={() => { this.onChangeHue(hue + ((outHueParam.n1.isRange) ? outHueParam.n1.isRange.range.step : 1)); }} />
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_OUT_SHARP')} disabled={out_select.length === 0} style={notHiddenIf(this.instance.IsSupportSharpness())}>
                            <Button icon='minus' disabled={sharpness <= ((outSharpParam.n1.isRange) ? outSharpParam.n1.isRange.range.min : 1)} onClick={() => { this.onChangeSharpness(sharpness - ((outSharpParam.n1.isRange) ? outSharpParam.n1.isRange.range.step : 1)); }} />
                            <Slider
                                value={sharpness}
                                max={(outSharpParam.n1.isRange) ? outSharpParam.n1.isRange.range.max : 100}
                                min={(outSharpParam.n1.isRange) ? outSharpParam.n1.isRange.range.min : 1}
                                step={(outSharpParam.n1.isRange) ? outSharpParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeSharpness}
                            />
                            <div style={slider_show_value_style}>{sharpness}</div>
                            <Button icon='plus' disabled={sharpness >= ((outSharpParam.n1.isRange) ? outSharpParam.n1.isRange.range.max : 100)} onClick={() => { this.onChangeSharpness(sharpness + ((outSharpParam.n1.isRange) ? outSharpParam.n1.isRange.range.step : 1)); }} />
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_OUT_RGAIN')} disabled={out_select.length === 0} style={notHiddenIf(this.instance.IsSupportRGBGain())}>
                            <Button icon='minus' disabled={rgain <= ((outRGParam.n1.isRange) ? outRGParam.n1.isRange.range.min : 1)} onClick={() => { this.onChangeRGain(rgain - ((outRGParam.n1.isRange) ? outRGParam.n1.isRange.range.step : 1)); }} />
                            <Slider
                                value={rgain}
                                max={(outRGParam.n1.isRange) ? outRGParam.n1.isRange.range.max : 100}
                                min={(outRGParam.n1.isRange) ? outRGParam.n1.isRange.range.min : 1}
                                step={(outRGParam.n1.isRange) ? outRGParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeRGain}
                            />
                            <div style={slider_show_value_style}>{rgain}</div>
                            <Button icon='plus' disabled={rgain >= ((outRGParam.n1.isRange) ? outRGParam.n1.isRange.range.max : 100)} onClick={() => { this.onChangeRGain(rgain + ((outRGParam.n1.isRange) ? outRGParam.n1.isRange.range.step : 1)); }} />
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_OUT_GGAIN')} disabled={out_select.length === 0} style={notHiddenIf(this.instance.IsSupportRGBGain())}>
                            <Button icon='minus' disabled={ggain <= ((outGGParam.n1.isRange) ? outGGParam.n1.isRange.range.min : 1)} onClick={() => { this.onChangeGGain(ggain - ((outGGParam.n1.isRange) ? outGGParam.n1.isRange.range.step : 1)); }} />
                            <Slider
                                value={ggain}
                                max={(outGGParam.n1.isRange) ? outGGParam.n1.isRange.range.max : 100}
                                min={(outGGParam.n1.isRange) ? outGGParam.n1.isRange.range.min : 1}
                                step={(outGGParam.n1.isRange) ? outGGParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeGGain}
                            />
                            <div style={slider_show_value_style}>{ggain}</div>
                            <Button icon='plus' disabled={ggain >= ((outGGParam.n1.isRange) ? outGGParam.n1.isRange.range.max : 100)} onClick={() => { this.onChangeGGain(ggain + ((outGGParam.n1.isRange) ? outGGParam.n1.isRange.range.step : 1)); }} />
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_OUT_BGAIN')} disabled={out_select.length === 0} style={notHiddenIf(this.instance.IsSupportRGBGain())}>
                            <Button icon='minus' disabled={bgain <= ((outBGParam.n1.isRange) ? outBGParam.n1.isRange.range.min : 1)} onClick={() => { this.onChangeBGain(bgain - ((outBGParam.n1.isRange) ? outBGParam.n1.isRange.range.step : 1)); }} />
                            <Slider
                                value={bgain}
                                max={(outBGParam.n1.isRange) ? outBGParam.n1.isRange.range.max : 100}
                                min={(outBGParam.n1.isRange) ? outBGParam.n1.isRange.range.min : 1}
                                step={(outBGParam.n1.isRange) ? outBGParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeBGain}
                            />
                            <div style={slider_show_value_style}>{bgain}</div>
                            <Button icon='plus' disabled={bgain >= ((outBGParam.n1.isRange) ? outBGParam.n1.isRange.range.max : 100)} onClick={() => { this.onChangeBGain(bgain + ((outBGParam.n1.isRange) ? outBGParam.n1.isRange.range.step : 1)); }} />
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_OUT_4KDOWNSCALE')} disabled={out_select.length === 0} style={notHiddenIf(this.instance.IsSupport4k2kDownscale())}>
                            <Select value={UHDDownscaleMode} placeholder='Select Mode' onChange={this.onChange4k2kDownscale}>
                                {uhd_downscale_opt.map((opt, idx) => <Option value={opt} key={`uhd_downscale_opt_${opt}`}>{(uhd_downscale_desc) ? uhd_downscale_desc[idx] : opt}</Option>)}
                            </Select>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_OUT_4KUPSCALE')} disabled={out_select.length === 0} style={notHiddenIf(this.instance.IsSupport4k2kUpscale())}>
                            <Select value={UHDUpscaleMode} placeholder='Select Mode' onChange={this.onChange4k2kUpscale}>
                                {uhd_upscale_opt.map((opt, idx) => <Option value={opt} key={`uhd_upscale_opt_${opt}`}>{(uhd_upscale_desc) ? uhd_upscale_desc[idx] : opt}</Option>)}
                            </Select>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_SCALER_WIN_LABEL')} style={notHiddenIf(this.instance.IsSupportWindowRoute())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_WIN_LAYOUT_MODE')} style={notHiddenIf(this.instance.IsSupportWindowLayoutMode())}><Select value={winLayoutMode} placeholder='Select Mode' onChange={this.onChangeWinLayoutMode}>
                            {win_mode_opt.map((opt, idx) => <Option value={opt} key={`win_mode_opt_${opt}`}>{(win_mode_desc) ? win_mode_desc[idx] : opt}</Option>)}
                        </Select></Form.Item>
                    </Form.Form>
                    <Flat.Field title={getText('DEVICE_FUNC_SCALER_WIN_CONF_LABEL')}>
                        <Form.Form labelStyle={{ width: '150px' }}>
                            <Form.Item label={getText('DEVICE_FUNC_SCALER_WIN_CONF_SELECT')}><Select value={win_select} placeholder='Select Window' onChange={this.onChangeWinSelect}>
                                {win_opt.map((opt, idx) => <Option value={opt} key={`win_opt_${opt}`}>{(win_desc) ? win_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_SCALER_WIN_CONF_SRC')} disabled={win_select.length === 0}><Select value={winRoute} placeholder='Select Source' onChange={this.onChangeWinRoute}>
                                {win_src_opt.map((opt, idx) => <Option value={opt} key={`win_src_opt_${opt}`}>{(win_src_desc) ? win_src_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_SCALER_WIN_CONF_BRD_CLR')} disabled={win_select.length === 0} style={notHiddenIf(this.instance.IsSupportWindowBorderConfiguration())}><Select value={winBrdColor} placeholder='Select Color' onChange={this.onChangeWinBorderColor}>
                                {border_color_opt.map((opt, idx) => <Option value={opt} key={`border_color_opt_${opt}`}>{(border_color_desc) ? border_color_desc[idx] : opt}</Option>)}
                            </Select></Form.Item>
                            <Form.Item label={getText('DEVICE_FUNC_SCALER_WIN_CONF_BRD_SIZE')} disabled={win_select.length === 0} style={notHiddenIf(this.instance.IsSupportWindowBorderConfiguration())}><Slider
                                value={winBrdSize}
                                max={(winBorderSizeParam.n2.isRange) ? winBorderSizeParam.n2.isRange.range.max : 100}
                                min={(winBorderSizeParam.n2.isRange) ? winBorderSizeParam.n2.isRange.range.min : 1}
                                step={(winBorderSizeParam.n2.isRange) ? winBorderSizeParam.n2.isRange.range.step : 1}
                                onAfterChange={this.onChangeWinBorderSize}
                            /></Form.Item>
                        </Form.Form>
                    </Flat.Field>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_SCALER_PIP_LABEL')} style={notHiddenIf(this.instance.IsSupportPIP())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_PIP_DISP_MODE')} style={notHiddenIf(this.instance.IsSupportDisplayMode())}><Select value={displayMode} placeholder='Select Mode' onChange={this.onChangeDsiplayMode}>
                            {display_mode_opt.map((opt, idx) => <Option value={opt} key={`display_mode_opt_${opt}`}>{(display_mode_desc) ? display_mode_desc[idx] : opt}</Option>)}
                        </Select></Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_PIP_SIZE_H')}>
                            <Slider
                                value={HPIPSize}
                                max={(pipHSizeParam.n1.isRange) ? pipHSizeParam.n1.isRange.range.max : 100}
                                min={(pipHSizeParam.n1.isRange) ? pipHSizeParam.n1.isRange.range.min : 1}
                                step={(pipHSizeParam.n1.isRange) ? pipHSizeParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeWinHPIPSize}
                            />
                            <div style={slider_show_value_style}>{HPIPSize}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_PIP_SIZE_V')}>
                            <Slider
                                value={VPIPSize}
                                max={(pipVSizeParam.n1.isRange) ? pipVSizeParam.n1.isRange.range.max : 100}
                                min={(pipVSizeParam.n1.isRange) ? pipVSizeParam.n1.isRange.range.min : 1}
                                step={(pipVSizeParam.n1.isRange) ? pipVSizeParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeWinVPIPSize}
                            />
                            <div style={slider_show_value_style}>{VPIPSize}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_PIP_POS_H')}>
                            <Slider
                                value={HPIPPos}
                                max={(pipHPositionParam.n1.isRange) ? pipHPositionParam.n1.isRange.range.max : 100}
                                min={(pipHPositionParam.n1.isRange) ? pipHPositionParam.n1.isRange.range.min : 1}
                                step={(pipHPositionParam.n1.isRange) ? pipHPositionParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeWinHPIPPos}
                            />
                            <div style={slider_show_value_style}>{HPIPPos}</div>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_SCALER_PIP_POS_V')}>
                            <Slider
                                value={VPIPPos}
                                max={(pipVPositionParam.n1.isRange) ? pipVPositionParam.n1.isRange.range.max : 100}
                                min={(pipVPositionParam.n1.isRange) ? pipVPositionParam.n1.isRange.range.min : 1}
                                step={(pipVPositionParam.n1.isRange) ? pipVPositionParam.n1.isRange.range.step : 1}
                                onAfterChange={this.onChangeWinVPIPPos}
                            />
                            <div style={slider_show_value_style}>{VPIPPos}</div>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
                {/* <Flat.Section title='VGA' style={notHiddenIf(this.instance.IsSupportInVGASetting())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label='VGA In'><Select value={vga_in_select} placeholder='Select VGA' onChange={this.onChangeInVGA}>
                            {vga_in_opt.map((opt, idx) => <Option value={opt} key={`vga_in_opt_${opt}`}>{(vga_in_desc) ? vga_in_desc[idx] : opt}</Option>)}
                        </Select></Form.Item>
                    </Form.Form>
                    <Form.Form labelStyle={{ width: '150px', display: (vga_in_select.length > 0 ? undefined : 'none') }}>
                        <Form.Item label='Phase'><Select value={vga_in_select} placeholder='Select VGA' onChange={this.onChangeInVGA}>
                            {vga_in_opt.map((opt, idx) => <Option value={opt} key={`vga_in_opt_${opt}`}>{(vga_in_desc) ? vga_in_desc[idx] : opt}</Option>)}
                        </Select></Form.Item>
                    </Form.Form>
                </Flat.Section> */}
                <Flat.Section title={getText('DEVICE_FUNC_SCALER_IN_TIMING_LABEL')} style={notHiddenIf(this.instance.IsSupportInResolutionRead())}>
                    <Cone backgroundColor='white' color='rgb(0,138,171)' />
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

declare type DanteViewModalType = "rxch-name" | "fac-default" | "none";

class DanteView extends CsxUI.IRQComponent<any> {
    state: {
        dante_name?: string, dante_name_changed: boolean, dante_name_err?: string,
        rx_ch_name: string, rx_ch_name_err: string,
        fac_reset_mode: 'all' | 'keep ip';
        ipconf_changed: boolean; 
        static_ip_err: string; static_gw_err: string; static_nm_err: string; 
        tx_name_buffer: { [tx_opt: string]: { name_string?: string, name_error: string } };
        modal: DanteViewModalType,
    };
    instance: CsxFeature.CsxDanteDevice;
    rx_ch_s: string;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'DeviceRealTime';
        this.instance = new CsxFeature.CsxDanteDevice(window.FOCUS_DEVICE);
        // const rx_opt = (rxRouteParam.n1.isOption) ? rxRouteParam.n1.isOption.option : [];
        this.state = {
            dante_name_changed: false,
            rx_ch_name: '',
            rx_ch_name_err: '',
            modal: 'none',
            fac_reset_mode: 'keep ip',
            ipconf_changed: false, 
            static_ip_err: '',
            static_gw_err: '',
            static_nm_err: '',
            tx_name_buffer: {},
        };
        this.rx_ch_s = '';
    }
    get ipmodeOption() {
        const ipmodeParam = this.instance.IPModeParam();
        const opt = (ipmodeParam.s1.isOption) ? ipmodeParam.s1.isOption.option : ['static', 'dhcp'];
        return (opt.length >= 2) ? opt : ['static', 'dhcp'];
    }
    get ipmodeDesc() {
        const ipmodeParam = this.instance.IPModeParam();
        const desc = (ipmodeParam.s1.isOption) ? ipmodeParam.s1.isOption.desc : ['STATIC', 'DHCP'];
        return (desc && desc.length >= 2) ? desc : ['STATIC', 'DHCP'];
    }
    validateDanteName = (value: string) => {
        const friendlyNameParam = this.instance.FriendlyNameParam();
        let ok = true;
        if (friendlyNameParam.s1.isString) {
            ok = this.validateString(value, friendlyNameParam.s1.isString);
        }
        this.setState({ dante_name_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    validateRxChannelName = (value: string) => {
        let new_rx_ch_name_err = this.state.rx_ch_name_err.slice();
        const rxChNameParam = this.instance.RxChannelNameParam();
        let ok = true;
        if (rxChNameParam.s1.isString) {
            ok = this.validateString(value, rxChNameParam.s1.isString);
        }
        new_rx_ch_name_err = (ok?'':getText('HINT_FORMAT_NOT_MATCH'));
        this.setState({ rx_ch_name_err: new_rx_ch_name_err });
        return ok;
    }
    validateTxChannelName = (value: string) => {
        const txChannelNameParam = this.instance.TxChannelNameParam();
        const ok = !(txChannelNameParam.s1.isString && !this.validateString(value, txChannelNameParam.s1.isString));
        return (ok?'':getText('HINT_FORMAT_NOT_MATCH'));
    }
    validateStaticIPAddr = (value: string) => {
        const ipaddrParam = this.instance.StaticIPAddressParam();
        let ok = true;
        if (ipaddrParam.ip1.isString) {
            ok = this.validateString(value, ipaddrParam.ip1.isString);
        }
        this.setState({ static_ip_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    validateStaticGateway = (value: string) => {
        const gatewayParam = this.instance.StaticGatewayParam();
        let ok = true;
        if (gatewayParam.ip1.isString) {
            ok = this.validateString(value, gatewayParam.ip1.isString);
        }
        this.setState({ static_gw_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    validateStaticNetmask = (value: string) => {
        const netmaskParam = this.instance.StaticNetmaskParam();
        let ok = true;
        if (netmaskParam.ip1.isString) {
            ok = this.validateString(value, netmaskParam.ip1.isString);
        }
        this.setState({ static_nm_err: (ok?'':getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    onChangeIpMode = (e: React.ChangeEvent<HTMLInputElement>) => {
        const ip_mode_opt = this.ipmodeOption;
        if (ip_mode_opt.length >= 2) {
            this.instance.SetIPMode((e.target.checked) ? ip_mode_opt[1] : ip_mode_opt[0]);
            this.setState({ ipconf_changed: true });
        }
    }
    onChangeIpAddr = (e: React.ChangeEvent<HTMLInputElement>) => { this.validateStaticIPAddr(e.target.value); this.instance.SetStaticIPAddress(e.target.value); this.setState({ ipconf_changed: true }); }
    onChangeGateway = (e: React.ChangeEvent<HTMLInputElement>) => { this.validateStaticGateway(e.target.value); this.instance.SetStaticGateway(e.target.value); this.setState({ ipconf_changed: true }); }
    onChangeNetmask = (e: React.ChangeEvent<HTMLInputElement>) => { this.validateStaticNetmask(e.target.value); this.instance.SetStaticNetmask(e.target.value); this.setState({ ipconf_changed: true }); }
    onSaveIPConfig = () => { this.instance.UploadStaticIPConfiguration(); this.setState({ ipconf_changed: false, static_ip_err: '', static_gw_err: '', static_nm_err: '' }); }
    onChangeDanteName = (e: React.ChangeEvent<HTMLInputElement>) => { if (this.validateDanteName(e.target.value) || e.target.value.length === 0) this.setState({ dante_name: e.target.value, dante_name_changed: true }); }
    onChangeRxChannelName = (e: React.ChangeEvent<HTMLInputElement>) => { if (this.validateRxChannelName(e.target.value) || e.target.value.length === 0) this.setState({ rx_ch_name: e.target.value }); }
    onChangeTxChannelName = (tx_idx: string, value: string) => {
        const { tx_name_buffer } = this.state;
        const clone_buffer = CsxUtil.nestedClone(tx_name_buffer);
        const name_error = this.validateTxChannelName(value);
        if (name_error.length === 0 || value.length === 0) {
            clone_buffer[tx_idx] = { name_string: value, name_error: '' };
        } else {
            if (clone_buffer[tx_idx]) {
                clone_buffer[tx_idx].name_error = name_error;
            } else {
                clone_buffer[tx_idx] = { name_error };
            }
        }
        this.setState({ tx_name_buffer: clone_buffer });
    }
    onOpenRxChannelNameEdit = (rxch_id: string) => {
        const rx_ch_name = this.instance.RxChannelName(rxch_id);
        this.rx_ch_s = rxch_id;
        this.setState({ rx_ch_name, modal: 'rxch-name' });
    }
    onSaveRxChannelName = () => {
        const { rx_ch_name } = this.state;
        this.instance.SetRxChannelName(this.rx_ch_s, rx_ch_name);
        this.setState({ modal: 'none', rx_ch_name: '', rx_ch_name_err: '' });
    }
    onSaveTxChannelName = (tx_idx: string) => {
        const { tx_name_buffer } = this.state;
        const clone_buffer = CsxUtil.nestedClone(tx_name_buffer);

        if (clone_buffer[tx_idx]) {
            const { name_string } = clone_buffer[tx_idx];
            if (name_string) {
                this.instance.SetTxChannelName(tx_idx, name_string);
            }
            delete clone_buffer[tx_idx];
        }
        this.setState({ tx_name_buffer: clone_buffer });
    }
    onSaveDanteName = () => {
        const { dante_name } = this.state;
        if (dante_name)
            this.instance.SetFriendlyName(dante_name);
    }
    onOpenFactoryDefault = () => {
        this.setState({ fac_reset_mode: 'keep ip', modal: 'fac-default' });
    }
    onDanteFactoryDefault = () => {
        const { fac_reset_mode } = this.state;
        this.instance.DanteFactoryDefault(fac_reset_mode);
    }
    render() {
        const { dante_name, dante_name_changed, dante_name_err, rx_ch_name, rx_ch_name_err, modal, tx_name_buffer, fac_reset_mode, static_gw_err, static_ip_err, static_nm_err, ipconf_changed } = this.state;
        const rxRouteParam = this.instance.RxRouteParam();
        const rxStereoRouteParam = this.instance.RxStereoRouteParam();
        const txChannelNameParam = this.instance.TxChannelNameParam();

        const rx_opt = (rxRouteParam.n1.isOption) ? rxRouteParam.n1.isOption.option : ['1', '2', '3'];
        const rx_desc = (rxRouteParam.n1.isOption) ? rxRouteParam.n1.isOption.desc : ['ch1', 'ch2', 'ch3'];
        const stereo_local_rx_opt = (rxStereoRouteParam.x1.isOption) ? rxStereoRouteParam.x1.isOption.option : ['a', 'b', 'c'];
        const stereo_local_rx_desc = (rxStereoRouteParam.x1.isOption) ? rxStereoRouteParam.x1.isOption.desc : ['stereo1', 'stereo2', 'stereo3'];
        const stereo_remote_rx_opt = (rxStereoRouteParam.x2.isOption) ? rxStereoRouteParam.x2.isOption.option : ['a', 'b'];
        const stereo_remote_rx_desc = (rxStereoRouteParam.x2.isOption) ? rxStereoRouteParam.x2.isOption.desc : ['stereo1', 'stereo2'];
        const stereo_remote_name_opt = (rxStereoRouteParam.s1.isOption) ? rxStereoRouteParam.s1.isOption.option : ['a', 'b'];
        const stereo_remote_name_desc = (rxStereoRouteParam.s1.isOption) ? rxStereoRouteParam.s1.isOption.desc : ['stereo unknown1', 'stereo unknown2'];
        const remote_ch_opt = (rxRouteParam.s1.isOption) ? rxRouteParam.s1.isOption.option : ['1', '2'];
        const remote_ch_desc = (rxRouteParam.s1.isOption) ? rxRouteParam.s1.isOption.desc : ['remote ch1', 'remote ch2'];
        const remote_friendly_name_opt = (rxRouteParam.s2.isOption) ? rxRouteParam.s2.isOption.option : ['1', '2'];
        const remote_friendly_name_desc = (rxRouteParam.s2.isOption) ? rxRouteParam.s2.isOption.desc : ['dante unknown1', 'dante unknown2'];
        const remote_opt: Array<string> = [];
        const remote_desc: Array<string> = [];
        const remote_stereo_opt: Array<string> = [];
        const remote_stereo_desc: Array<string> = [];
        for (let i = 0; i < remote_ch_opt.length; i++) {
            const remote_ch = remote_ch_opt[i];
            const ch_desc = (remote_ch_desc) ? remote_ch_desc[i] : undefined;
            for (let j = 0; j < remote_friendly_name_opt.length; j++) {
                const friendly_name = remote_friendly_name_opt[j];
                const friendly_desc = (remote_friendly_name_desc) ? remote_friendly_name_desc[j] : undefined;
                const opt_val = `${remote_ch}@${friendly_name}`;
                remote_opt.push(opt_val);
                if (ch_desc && friendly_desc) {
                    remote_desc.push(`${ch_desc}@${friendly_desc}`);
                } else {
                    remote_desc.push(opt_val);
                }
            }
        }
        for (let i = 0; i < stereo_remote_rx_opt.length; i++) {
            const remote_ch = stereo_remote_rx_opt[i];
            const ch_desc = (stereo_remote_rx_desc) ? stereo_remote_rx_desc[i] : undefined;
            for (let j = 0; j < stereo_remote_name_opt.length; j++) {
                const friendly_name = stereo_remote_name_opt[j];
                const friendly_desc = (stereo_remote_name_desc) ? stereo_remote_name_desc[j] : undefined;
                const opt_val = `${remote_ch}@${friendly_name}`;
                remote_stereo_opt.push(opt_val);
                if (ch_desc && friendly_desc) {
                    remote_stereo_desc.push(`${ch_desc}@${friendly_desc}`);
                } else {
                    remote_stereo_desc.push(opt_val);
                }
            }
        }
        const show_dante_name = (typeof dante_name === 'string') ? dante_name : this.instance.FriendlyName();
        const rx_ch_route_all = rx_opt.map(ch_id => this.instance.RxRoute(ch_id));
        const rx_ch_name_all = rx_opt.map(ch_id => this.instance.RxChannelName(ch_id));
        const rx_ch_stereo_route_all = stereo_local_rx_opt.map(ch_id => this.instance.RxStereoRoute(ch_id));
        const tx_ch_opt = (txChannelNameParam.n1.isOption) ? txChannelNameParam.n1.isOption.option : ['1', '2'];
        const tx_ch_desc = (txChannelNameParam.n1.isOption) ? txChannelNameParam.n1.isOption.desc : ['tx channel1', 'tx channel2'];
        const ip_mode_opt = this.ipmodeOption;
        const ip_mode_desc = this.ipmodeDesc;
        
        const tx_name = tx_ch_opt.map(opt => this.instance.TxChannelName(opt));
        const ipmode = this.instance.IPMode();
        const ipaddr = this.instance.IPAddress();
        const gateway = this.instance.Gateway();
        const netmask = this.instance.Netmask();
        const staticIpaddr = this.instance.StaticIPAddress();
        const staticGateway = this.instance.StaticGateway();
        const staticNetmask = this.instance.StaticNetmask();
        const static_ipconfig_support = this.instance.IsSupportStaticIPConfiguration();
        /* matrix variables */
        const header_h = 60;
        const sider_w = 120;
        const row_h = 60;

        /* create modal */
        const dante_form_title: {  [type in DanteViewModalType]: string } = {
            'none': '',
            'rxch-name': getText('DEVICE_FUNC_DANTE_EDIT_RX_CH_NAME'),
            'fac-default': getText('DEVICE_FUNC_DANTE_FAC_DEFAULT'),
        };
        const dante_form_modal: { [type in DanteViewModalType]: React.ReactNode } = {
            "none": undefined,
            "rxch-name": (
                <Form.Form labelStyle={{ width: '150px' }}>
                    <Form.Item label={getText('DEVICE_FUNC_DANTE_RX_CH_NAME')} error={rx_ch_name_err}>
                        <Input value={rx_ch_name} onChange={this.onChangeRxChannelName}/>
                    </Form.Item>
                    <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Button type='primary' onClick={this.onSaveRxChannelName}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
            ),
            "fac-default": (
                <Form.Form>
                    <div style={{ width: '100%', textIndent: '20px' }}>{getText('CONFIRM_FACTORY_DEFAULT')}</div>
                    <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Checkbox checked={(fac_reset_mode === 'all')} label={getText('DEVICE_FUNC_DANTE_FACDEF_IP_ALSO')} onChange={() => { this.setState({ fac_reset_mode: (fac_reset_mode === 'all') ? 'keep ip' : 'all' }) }}/>
                        <Button type='primary' onClick={this.onDanteFactoryDefault}>{getText('CONFIRM')}</Button>
                    </Form.Item>
                </Form.Form>
            ),
        }
        const general_control_allow = (window.CSX_CUR_AUTH) ? (window.CSX_CUR_AUTH.getPermission('dante_control') === CsxUserPermissionLevel.FullAccess) : false;
        const ipconfig_valid = (static_gw_err.length === 0 && static_nm_err.length === 0 && static_ip_err.length === 0)
        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_DANTE_BASIC_LABEL')} style={notHiddenIf(this.instance.IsSupportFriendlyName() || this.instance.IsSupportDanteDiscover() || this.instance.IsSupportDanteFactoryDefault())} defaultExtend>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_DANTE_BASIC_NAME')} error={dante_name_err} style={notHiddenIf(this.instance.IsSupportFriendlyName())}>
                            <Input value={show_dante_name} onChange={this.onChangeDanteName} />
                            <Button type='primary' disabled={!dante_name_changed} onClick={this.onSaveDanteName}>{getText('SAVE')}</Button>
                        </Form.Item>
                        <Form.Item label={getText('RESTORE')} style={notHiddenIf(this.instance.IsSupportDanteFactoryDefault())}>
                            <Button onClick={this.onOpenFactoryDefault}>{getText('SETTING_SYSTEM_SYSTEM_FAC_DEFAULT')}</Button>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_DANTE_ROUTE_LABEL')} style={notHiddenIf(this.instance.IsSupportRxRoute() || this.instance.IsSupportRxStereoRoute())}>
                    <Flat.Field title={getText('DEVICE_FUNC_DANTE_BASIC_ROUTE')} style={notHiddenIf(this.instance.IsSupportRxRoute())}>
                        <div
                            className='xy_axis_matrix dante_rx_route'
                            style={{
                                width: '100%',
                                height: `${rx_opt.length * row_h + header_h}px`, 
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-start',
                                alignContent: 'flex-start',
                            }}
                        >
                            <div className='dante-route-matrix-root' style={{ position: 'relative', height: `${header_h}px`, width: `${sider_w}px` }}></div>
                            <div className='dante-route-matrix-header' style={{ position: 'relative', height: `${header_h}px`, width: `calc(100% - ${sider_w}px)`, display: 'flex', flexDirection: 'row' }}>
                                {remote_desc.map((desc, desc_idx) => {
                                    const [ ch, name ] = desc.split('@');
                                    return (
                                        <div
                                            key={`dante-route-matrix-header${desc_idx}`}
                                            style={{ position: 'relative', flex: '1 1' }}
                                        >
                                            <div style={{ position: 'absolute', top: '5px', left: '5px', overflow: 'hidden', width: 'calc(100% - 10px)', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{ch}</div>
                                            <div style={{ position: 'absolute', bottom: '5px', left: '5px', overflow: 'hidden', width: 'calc(100% - 10px)', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{name}</div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className='dante-route-matrix-sider' style={{ position: 'relative', height: `calc(100% - ${header_h}px)`, width: `${sider_w}px`, display: 'flex', flexDirection: 'column' }}>
                                {rx_opt.map((opt, opt_idx) => {
                                    const prefer_name = rx_ch_name_all[opt_idx];
                                    const rx_ch_desc = (prefer_name) ? prefer_name : ((rx_desc) ? rx_desc[opt_idx] : opt);
                                    return (
                                        <div
                                            key={`dante-route-matrix-sider${opt_idx}`}
                                            style={{ position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flex: '1 1' }}
                                        >
                                            <div style={{ overflow: 'hidden', width: 'calc(100% - 10px)', whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'center' }}>{rx_ch_desc}</div>
                                            <Icon type='write' color='#008aab' style={{ position: 'absolute', bottom: '5px', right: '5px' }} onClick={() => { this.onOpenRxChannelNameEdit(opt); }}/>
                                        </div>
                                    );
                                })}
                            </div>
                            <CsxUI.StaticMatrix
                                style={{ width: `calc(100% - ${sider_w}px)`, height: `calc(100% - ${header_h}px)` }}
                                frameDescription={{
                                    row: remote_opt.length,
                                    column: rx_opt.length,
                                    render: (x, y) => {
                                        const rx_ch = rx_opt[y];
                                        const route_opt = remote_opt[x];
                                        const checked = (rx_ch_route_all[y] === route_opt);
                                        return (
                                            <div className={`dante_rx_routing_frame${(checked) ? ' checked' : ''}`} onClick={() => { this.instance.SetRxRoute(rx_ch, route_opt); }} >
                                                {checked ? <SwitchButton type='hexigon' checked={true} readOnly style={{ transform: 'scale(2)' }} /> : undefined}
                                            </div>
                                        );
                                    }
                                }}
                            />
                        </div>
                        <Form.Item style={{  justifyContent: 'flex-end', }} >
                            <Button onClick={this.instance.DanteDiscover} style={{ marginRight: '0px' }}>{getText('DEVICE_FUNC_DANTE_DISCOVER')}</Button>
                        </Form.Item>
                    </Flat.Field>
                    <Flat.Field title={getText('DEVICE_FUNC_DANTE_STEREO_ROUTE')} style={notHiddenIf(this.instance.IsSupportRxStereoRoute())}>
                        <div
                            className='xy_axis_matrix dante_rx_stereo_route'
                            style={{
                                width: '100%',
                                height: `${rx_opt.length * row_h + header_h}px`, 
                                position: 'relative',
                                display: 'flex',
                                flexDirection: 'row',
                                flexWrap: 'wrap',
                                justifyContent: 'flex-start',
                                alignContent: 'flex-start',
                            }}
                        >
                            <div className='dante-route-matrix-root' style={{ position: 'relative', height: `${header_h}px`, width: `${sider_w}px` }}></div>
                            <div className='dante-route-matrix-header' style={{ position: 'relative', height: `${header_h}px`, width: `calc(100% - ${sider_w}px)`, display: 'flex', flexDirection: 'row' }}>
                                {remote_stereo_desc.map((desc, desc_idx) => {
                                    const [ ch, name ] = desc.split('@');
                                    return (
                                        <div
                                            key={`dante-stereo-route-matrix-header${desc_idx}`}
                                            style={{ position: 'relative', flex: '1 1' }}
                                        >
                                            <div style={{ position: 'absolute', top: '5px', left: '5px', overflow: 'hidden', width: 'calc(100% - 10px)', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{ch}</div>
                                            <div style={{ position: 'absolute', bottom: '5px', left: '5px', overflow: 'hidden', width: 'calc(100% - 10px)', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>{name}</div>
                                        </div>
                                    );
                                })}
                            </div>
                            <div className='dante-route-matrix-sider' style={{ position: 'relative', height: `calc(100% - ${header_h}px)`, width: `${sider_w}px`, display: 'flex', flexDirection: 'column' }}>
                                {stereo_local_rx_opt.map((opt, opt_idx) => {
                                    const rx_ch_desc = (stereo_local_rx_desc) ? stereo_local_rx_desc[opt_idx] : opt;
                                    return (
                                        <div
                                            key={`dante-stereo-route-matrix-sider${opt_idx}`}
                                            style={{ position: 'relative', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', flex: '1 1' }}
                                        >
                                            <div style={{ overflow: 'hidden', width: 'calc(100% - 10px)', whiteSpace: 'nowrap', textOverflow: 'ellipsis', textAlign: 'center' }}>{rx_ch_desc}</div>
                                        </div>
                                    );
                                })}
                            </div>
                            <CsxUI.StaticMatrix
                                style={{ width: `calc(100% - ${sider_w}px)`, height: `calc(100% - ${header_h}px)` }}
                                frameDescription={{
                                    row: remote_stereo_opt.length,
                                    column: stereo_local_rx_opt.length,
                                    render: (x, y) => {
                                        const rx_ch = stereo_local_rx_opt[y];
                                        const route_opt = remote_stereo_opt[x];
                                        const checked = (rx_ch_stereo_route_all[y] === route_opt);
                                        return (
                                            <div className={`dante_rx_routing_frame${(checked) ? ' checked' : ''}`} onClick={() => { this.instance.SetRxStereoRoute(rx_ch, route_opt); }} >
                                                {checked ? <SwitchButton type='hexigon' checked={true} readOnly style={{ transform: 'scale(2)' }} /> : undefined}
                                            </div>
                                        );
                                    }
                                }}
                            />
                        </div>
                        <Form.Item style={{  justifyContent: 'flex-end', }} >
                            <Button onClick={this.instance.DanteDiscover} style={{ marginRight: '0px' }}>{getText('DEVICE_FUNC_DANTE_DISCOVER')}</Button>
                        </Form.Item>
                    </Flat.Field>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_DANTE_NETWORK_LABEL')} style={notHiddenIf(this.instance.IsSupportIPConfiguration())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_GEN_IP_CONF_DHCP')} style={CsxUI.getHiddenStyle(static_ipconfig_support)}><SwitchButton checked={((ip_mode_opt.length >= 2)?(ipmode === ip_mode_opt[1]):false)} onChange={this.onChangeIpMode} label={(ip_mode_desc?[ ip_mode_desc[1], ip_mode_desc[0] ]:undefined)} /></Form.Item>
                        <Form.Item error={static_ip_err} label={getText('DEVICE_FUNC_GEN_IP_CONF_IPADDR')} style={notHiddenIf(static_ipconfig_support)}><Input value={(ipmode === 'dhcp') ? ipaddr : staticIpaddr} disabled={ipmode === 'dhcp'} onChange={this.onChangeIpAddr} /></Form.Item>
                        <Form.Item error={static_gw_err} label={getText('DEVICE_FUNC_GEN_IP_CONF_GW')} style={notHiddenIf(static_ipconfig_support)}><Input value={(ipmode === 'dhcp') ? gateway : staticGateway} disabled={ipmode === 'dhcp'} onChange={this.onChangeGateway} /></Form.Item>
                        <Form.Item error={static_nm_err} label={getText('DEVICE_FUNC_GEN_IP_CONF_NM')} style={notHiddenIf(static_ipconfig_support)}><Input value={(ipmode === 'dhcp') ? netmask : staticNetmask} disabled={ipmode === 'dhcp'} onChange={this.onChangeNetmask} /></Form.Item>
                        {static_ipconfig_support ? <Form.Item style={{ justifyContent: 'flex-end' }}>
                            <Button disabled={(!ipconf_changed || !general_control_allow)} onClick={() => { this.setState({ ipconf_changed: false, static_nm_err: '', static_gw_err: '', static_ip_err: '' }); this.instance.cleanBuffer(); }}>{getText('RESTORE')}</Button>
                            <Button type='primary' disabled={(!ipconf_changed || !ipconfig_valid || !general_control_allow)} onClick={this.onSaveIPConfig}>{getText('SAVE')}</Button>
                        </Form.Item> : undefined}
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_DANTE_CH_NAMING_LABEL')} style={notHiddenIf(this.instance.IsSupportTxChannelName())}>
                    <Flat.Field title={getText('DEVICE_FUNC_DANTE_TX_LABEL')}> 
                        <Form.Form labelStyle={{ width: '150px' }}>
                            {tx_ch_opt.map((tx, idx) => {
                                let tx_showname = (tx_ch_desc) ? tx_ch_desc[idx] : `TX ${tx}`;
                                if (tx_name[idx].length > 0) { tx_showname = tx_name[idx]; }
                                let edit_buffer = tx_name_buffer[tx];
                                let edit_string = tx_showname;
                                let disable_save = !edit_buffer;
                                if (edit_buffer && typeof edit_buffer.name_string === 'string') {
                                    edit_string = edit_buffer.name_string;
                                    disable_save = (edit_buffer.name_string.length === 0);
                                }
                                return (
                                    <Form.Item label={tx_showname} key={`dante_txch_showname_${idx}`} error={(edit_buffer) ? edit_buffer.name_error : ''}>
                                        <Input onChange={(e) => { this.onChangeTxChannelName(tx, e.target.value); }} value={edit_string} />
                                        <Button type='primary' onClick={() => { this.onSaveTxChannelName(tx); }} disabled={disable_save}>{getText('SAVE')}</Button>
                                    </Form.Item>
                                );
                            })}
                        </Form.Form>
                    </Flat.Field>
                </Flat.Section>
                <Modal
                    bodyStyle={{ width: '360px' }}
                    title={dante_form_title[modal]}
                    visible={modal !== 'none'}
                    onClose={() => { this.setState({ modal: 'none' }); }}
                >
                    {dante_form_modal[modal]}
                </Modal>
            </Flat.Playground>
        );
    }
}

class TimeView extends CsxUI.IRQComponent<any> {
    state: {
        date?: Date; time?: Date; time_changed: boolean;
        ntp_url?: string; ntp_url_changed: boolean; ntp_url_err?: string;
    };
    instance: CsxFeature.CsxTimeManageDevice;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'DeviceRealTime';
        this.instance = new CsxFeature.CsxTimeManageDevice(window.FOCUS_DEVICE);
        this.state = {
            time_changed: false,
            ntp_url_changed: false,
        };
        this.instance.TimeFlowSimulateStart();
    }
    validateNTPUrl = (value: string) => {
        const ntpUrlParam = this.instance.NTPServerUrlParam();
        let ok = true;
        if (ntpUrlParam.s1.isString) {
            ok = this.validateString(value, ntpUrlParam.s1.isString);
        }
        this.setState({ ntp_url_err: (ok?undefined:getText('HINT_FORMAT_NOT_MATCH')) });
        return ok;
    }
    onChangeNTPSync = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (this.instance.IsSupportNTPTimeSyncDuration()) {
            this.instance.SetNTPTimeSyncDuration(((e.target.checked)?1:0));
        }
    }
    onChangeNTPUrl = (e: React.ChangeEvent<HTMLInputElement>) => { if (this.validateNTPUrl(e.target.value) || e.target.value.length === 0) this.setState({ ntp_url: e.target.value, ntp_url_changed: true }); }
    onDateChange = (value: Date | Date[]) => { this.instance.TimeFlowSimulateStop(); this.setState({ date: value, time_changed: true }); }
    onTimeChange = (value: Date | Date[]) => { this.instance.TimeFlowSimulateStop(); this.setState({ time: value, time_changed: true }); }
    onSaveNTPUrl = () => {
        const { ntp_url } = this.state;
        if (ntp_url) {
            this.instance.SetNTPServerUrl(ntp_url);
            this.setState({ ntp_url: undefined, ntp_url_changed: false });
        }
    }
    onSaveTime = () => {
        const { time, date } = this.state;
        if (time) {
            this.instance.SetTime(time.getHours(), time.getMinutes(), time.getSeconds());
        }
        if (date) {
            this.instance.SetDate(date.getFullYear(), (date.getMonth() + 1), date.getDate());
        }
        this.setState({ time: undefined, date: undefined, time_changed: false });
        this.instance.TimeFlowSimulateStart();
    }
    componentBeforeUmount() {
        this.instance.TimeFlowSimulateStop();
    }
    render() {
        const { date, time, time_changed, ntp_url, ntp_url_changed, ntp_url_err } = this.state;
        const timezoneParam = this.instance.TimezoneParam();

        const timezone_opt = (timezoneParam.n1.isOption) ? timezoneParam.n1.isOption.option : [];
        const timezone_desc = (timezoneParam.n1.isOption) ? timezoneParam.n1.isOption.desc : [];

        const parse_inst_date = DateTime.ParseDateTime(this.instance.Date(), 'YYYY.MM.DD');
        const parse_inst_time = DateTime.ParseDateTime(this.instance.Time(), 'HH:mm:ss');
        const website_timelapse = this.instance.WebsiteTimelapse();
        if (parse_inst_date)
            parse_inst_date.setSeconds(parse_inst_date.getSeconds() + website_timelapse);
        if (parse_inst_time)
            parse_inst_time.setSeconds(parse_inst_time.getSeconds() + website_timelapse);

        const show_ntp_url = (ntp_url) ? ntp_url : this.instance.NTPServerUrl();
        const show_date = (date) ? date : (parse_inst_date ? parse_inst_date : new Date(0));
        const show_time = (time) ? time : (parse_inst_time ? parse_inst_time : new Date(0));

        const timezone = this.instance.Timezone();
        const ntp_sync_duration = parseInt(this.instance.NTPTimeSyncDuration());
        const ntp_sync_enable = (!isNaN(ntp_sync_duration) && (ntp_sync_duration > 0));

        const ready = (window.FOCUS_DEVICE && CsxUtil.isCsxSystemStateSync(window.FOCUS_DEVICE.SYS_STA));
        return (
            <Flat.Playground hasExtendToggler style={{ height: 'auto', overflow: 'visible' }} loading={!ready}>
                <Flat.Section title={getText('DEVICE_FUNC_TIME_NTP_LABEL')} defaultExtend={true} style={notHiddenIf(this.instance.IsSupportNTPTimeSynchronize() || this.instance.IsSupportNTPServerUrl())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('SETTING_SYSTEM_TIME_NTP_SYNC')} style={notHiddenIf(this.instance.IsSupportNTPTimeSynchronize())}>
                            <SwitchButton checked={ntp_sync_enable} label={[ 'Enable', 'Disable' ]} onChange={this.onChangeNTPSync} />
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_TIME_NTP_SERVER')} error={ntp_url_err} style={notHiddenIf(this.instance.IsSupportNTPServerUrl())}>
                            <Input value={show_ntp_url} onChange={this.onChangeNTPUrl} />
                            <Button type='primary' disabled={!ntp_url_changed} onClick={this.onSaveNTPUrl}>{getText('SAVE')}</Button>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
                <Flat.Section title={getText('DEVICE_FUNC_TIME_LOCAL_LABEL')} style={notHiddenIf(this.instance.IsSupportTimezone() || this.instance.IsSupportDate() || this.instance.IsSupportTime())}>
                    <Form.Form labelStyle={{ width: '150px' }}>
                        <Form.Item label={getText('DEVICE_FUNC_TIME_LOCAL_TIMEZONE')} style={notHiddenIf(this.instance.IsSupportTimezone())}>
                            <Select
                                placeholder='Select Timezone'
                                value={timezone}
                                onChange={this.instance.SetTimezone}
                            >
                                {timezone_opt.map((opt, opt_idx) => {
                                    const zonename = (timezone_desc) ? timezone_desc[opt_idx] : opt;
                                    return <Option key={`timezone_opt_${opt_idx}`} value={opt}>{zonename}</Option>
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item label={getText('DEVICE_FUNC_TIME_LOCAL_DATE_TIME')} style={notHiddenIf(this.instance.IsSupportDate() || this.instance.IsSupportTime())}>
                            <DateTime.DatePicker value={show_date} disabled={ntp_sync_enable} onChange={this.onDateChange} style={notHiddenIf(this.instance.IsSupportDate())} />
                            <DateTime.TimePicker value={show_time} disabled={ntp_sync_enable} onChange={this.onTimeChange} style={notHiddenIf(this.instance.IsSupportTime())} />
                            <Button type='primary' disabled={!time_changed || ntp_sync_enable} onClick={this.onSaveTime}>{getText('SAVE')}</Button>
                        </Form.Item>
                    </Form.Form>
                </Flat.Section>
            </Flat.Playground>
        );
    }
}

export default class FunctionShow extends CsxUI.IRQComponent<any> {
    constructor(props: any) { super(props); this.IRQIndex = 'NoUpdate'; }
    render() {
        const focus_feature = this.getURLParameter().get('page');
        let table = <div />;
        // console.log('focus_feature :>> ', focus_feature);
        if (focus_feature === 'software')
            table = <SoftwareView />;
        else if (focus_feature === 'routing')
            table = <RouterView />;
        else if (focus_feature === 'edid')
            table = <EDIDView />;
        else if (focus_feature === 'hdcp')
            table = <HDCPView />;
        else if (focus_feature === 'general')
            table = <GeneralView />;
        else if (focus_feature === 'audio')
            table = <AudioView />;
        else if (focus_feature === 'scaler')
            table = <ScalerView />;
        else if (focus_feature === 'io')
            table = <IOView />;
        else if (focus_feature === 'videowall')
            table = <VideoWallView />;
        else if (focus_feature === 'osd')
            table = <OSDView />;
        else if (focus_feature === 'cabletest')
            table = <CableTestView />;
        else if (focus_feature === 'streaming')
            table = <StreamingView />;
        else if (focus_feature === 'auto')
            table = <AutomationView />;
        else if (focus_feature === 'record')
            table = <RecordView />;
        else if (focus_feature === 'pmonitor')
            table = <PowerMonitorView />;
        else if (focus_feature === 'cec')
            table = <CECView />;
        else if (focus_feature === 'debug')
            table = <DebugView />;
        else if (focus_feature === 'dante')
            table = <DanteView />;
        else if (focus_feature === 'time')
            table = <TimeView />;
        // debug view not like others, hard code it!!!
        return (
            <Flat.Playground contextStyle={(focus_feature === 'debug') ? { position: 'absolute', left: 0, right: 0, top: 0, bottom: 0 } : undefined}>
                {table}
            </Flat.Playground>
        );
    }
}
