import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter as Router, Switch, Route, withRouter } from 'react-router-dom';

import { CsxControlSystem, CsxUtil, CsxUI, CsxFeature } from './csx';

import { Layout, Spin, Modal, Button, Notify, Input, Icon, Tooltip, Dropdown } from 'cypd';
import {
    AutomationPage,
    DevicesPage,
    DashboardPage,
    SettingPage,
    ScenarioPage,
    RoomPage,
    FactoryPage,
    HomePage,
    IconPage
} from './route';
// import { CommandSetPage } from './route';

import './App.css';
import logo from './images/logo.svg';
// import usa_flag from './images/usa_flag.svg';
// import taiwan_flag from './images/taiwan_flag.svg';
// import japan_flag from './images/japan_flag.svg';
import signin from './images/signin.svg';
import productkey from './productkey.json'
import { IRQComponent } from './csx/ui';
import { getText, languagePackageLoad } from './csx/desc';
import { CsxUserPermissionLevel, csxUserPermissionSatisfy, CSX_NAME } from './csx/manager';

const user_confirm_type_array = CsxUtil.stringLitArray([ 'normal', 'warning', 'error', ]);
declare type UserConfirmType = (typeof user_confirm_type_array)[number]
declare type UserConfirmOptions = {
    type?: UserConfirmType;
    width?: number;
    bodyFooterMargin?: number;
    bodyAlignment?: 'left' | 'right' | 'center' | 'justify' | 'inherit';
}
declare global {
    interface Window {
        pushLock: boolean; // when pushLock is true, website's history push action should ask user before it really render switch
        userConfirm: (msg: React.ReactNode, cb: (ok: boolean) => void, options?: UserConfirmOptions) => void;
    }
}

declare type AppState = {
    lang: string | null;
    loading: boolean;
    ready: boolean;
    hint: string;
    loginVisible: boolean;
    username: string;
    password: string;
}

declare interface DialogProps {
    message?: React.ReactNode;
    onOK?: () => void;
    onCancel?: () => void;
    options?: UserConfirmOptions;
}

export class Dialog extends React.Component<DialogProps> {
    render() {
        const { message, onOK, onCancel, options } = this.props;
        const type: UserConfirmType | undefined = (options) ? options.type : undefined;
        const width: number | undefined = (options) ? options.width : undefined;
        const bodyFooterMargin: number | undefined = (options) ? options.bodyFooterMargin : undefined;
        const bodyAlignment = (options) ? options.bodyAlignment : undefined;
        return (
            <Modal
                className={`app_default_dialog ${type ? type : ''}`}
                visible={true}
                title={(type === 'warning' || type === 'error') ? `${type.toUpperCase()}!` : undefined}
                style={{ width: (typeof width === 'number') ? `${width}px` : undefined, maxWidth: (typeof width === 'number') ? `${width}px` : undefined }}
            >
                <div className='message' style={{ textAlign: bodyAlignment }}>{message}</div>
                <div className='footer' style={{ marginTop: bodyFooterMargin }}>
                    <Button type='primary' size='small' onClick={onOK}>{getText('APP_DIALOG_OK')}</Button>
                    <Button size='small' onClick={onCancel}>{getText('APP_DIALOG_CANCEL')}</Button>
                </div>
            </Modal>
        );
    }
}

window.userConfirm = (message, callback, options?: UserConfirmOptions) => {
    const container = document.createElement('div');
    container.classList.add('cypd-confirm-dialog-container');
    document.body.appendChild(container);
    const withCleanup = (answer: boolean) => {
        container.classList.add('before_unmount');
        setTimeout(() => {
            if (container) {
                ReactDOM.unmountComponentAtNode(container);
                document.body.removeChild(container);
            }
        }, 300);
        callback(answer); 
    };
    ReactDOM.render(
        <Dialog message={message} onOK={() => { withCleanup(true); }} onCancel={() => { withCleanup(false); }} options={options}/>
    , container);
};

// const LANGUAGE_CONFIGURATION: { [s in CsxUtil.APP_LANG_TYPE]: { label: string } } = {
//     'en': { label: 'English(US)' },
//     'ch': { label: '中文(繁體)' },
//     'jp': { label: '日本語' },
// }

const NotFound = () => (
    <div className='not_found'>
        <div className='title'>{getText('APP_OOPS')}</div>
        <div className='message'>{getText('APP_OOPS_MSG')}</div>
    </div>
);

const NotAuthorized = () => (
    <div className='not_found'>
        <div className='title'>{getText('NOTIFY_TITLE_UNAUTHORIZED')}</div>
        <div className='message'>{getText('NOTIFY_MSG_UNAUTHORIZED')}</div>
    </div>
);

const CsxProductKeyGrabber = new CsxUtil.CsxFileGrabber('/productkey.json');

class AppFooter extends CsxUI.IRQComponent<any> {
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'Dashboard';
        this.MIN_RENDER_INTERVAL = 1000;
    }
    render() {
        let context = '';
        if (window.FOCUS_GATEWAY) {
            if (window.FOCUS_GATEWAY.SYS_STA !== CsxUtil.SYS_STA_TYPE.Sync) {
                if (window.FOCUS_GATEWAY.SYS_STA === CsxUtil.SYS_STA_TYPE.Firmware_Update) {
                    context = getText('APP_FOOTER_FW_UPDATING');
                } else {
                    context = getText('APP_FOOTER_OFFLINE');
                }
            }
        } else {
            context = getText('APP_FOOTER_LINKING');
        }
        return (
            <div
                className='app_footer'
                style={{
                    position: 'fixed',
                    bottom: '0',
                    left: '0',
                    right: '0',
                    height: '50px',
                    backgroundColor: '#e84a45',
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                    fontWeight: 'bold',
                    zIndex: 1,
                    transform: (context.length > 0) ? 'translateY(0)' : 'translateY(50px)'
                }}
            >
                {context}
            </div>
        )
    }
}

class APPHeaderIPShower extends IRQComponent<any> {
    instance?: CsxFeature.CsxGeneralDevice;
    check_gw_interval?: NodeJS.Timeout;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'GatewayRealTime';
        this.check_gw_interval = setInterval(this.checkGatewayReady, 1000);
    }
    checkGatewayReady = () => {
        if (window.FOCUS_GATEWAY) {
            this.instance = new CsxFeature.CsxGeneralDevice(window.FOCUS_GATEWAY);
            if (this.check_gw_interval) {
                clearInterval(this.check_gw_interval);
                this.check_gw_interval = undefined;
            }
            this.forceUpdate();
        }
    }
    render() {
        const ip1 = (this.instance) ? this.instance.IPAddress(1) : '0.0.0.0';
        const ip2 = (this.instance) ? this.instance.IPAddress(2) : '0.0.0.0';

        return (
            <div
                style={{
                    position: 'relative',
                    display: 'flex',
                    height: '100%',
                    flexDirection: 'column',
                    alignItems: 'flex-start',
                    justifyContent: 'center',
                }}
            >
                <span style={{ color: 'gray' }}>{`LAN1: ${ip1}`}</span>
                <span style={{ color: 'gray' }}>{`LAN2: ${ip2}`}</span>
            </div>
        );
    }
}

class AppHeader extends React.Component {
    state: { loading: boolean; loginVisible: boolean; username: string; password: string; lang: string | null; hint: string } = {
        loading: false,
        loginVisible: false,
        username: '',
        password: '',
        lang: localStorage.getItem('APP_APP_LANGUAGE'),
        hint: '',
    }
    reloadPageWithLanguage = (value: string) => {
        localStorage.setItem('APP_LANGUAGE', value);
        this.setState({ lang: value }, () => { window.location.reload(true); });
    }
    onOpenLoginDialog = () => {
        /* open login UI */
        this.setState((prevState: AppState): Partial<AppState> => ({ loginVisible: !prevState.loginVisible, hint: '' }));
    }
    onSelectLang = (value: string) => {
        if (window.pushLock) {
            window.userConfirm('You have somthing unsaved. Are you sure leaving this page?', (ans) => { if (ans) this.reloadPageWithLanguage(value); });
        } else {
            this.reloadPageWithLanguage(value);
        }
    }
    onChangeUsername = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ username: e.target.value }); }
    onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ password: e.target.value }); }
    onLogin = async () => {
        const { username, password } = this.state;
        if (username.length > 0 && password.length > 0 && window.FOCUS_GATEWAY) {
            this.setState({ loading: true, hint: '' });
            try {
                window.localStorage.setItem('auth', btoa(username + ':' + password));
                // await window.CSX_MANAGER.initialize();
                await window.CSX_MANAGER.reconnect(window.FOCUS_GATEWAY);
                this.setState({ loading: false, loginVisible: false });
                Notify({ title: getText('NOTIFY_TITLE_SUCCESS'), context: getText('NOTIFY_MSG_LOGIN_SUCCESS'), type: 'success' });
                window.CSX_MANAGER.loginTimerStart(Number(window.FOCUS_GATEWAY.STA.A76));
            } catch (err) {
                this.setState({ loading: false, hint: `${err}` });
                window.localStorage.removeItem('auth');
            }
        }
    }
    onLogout = () => {
        window.CSX_MANAGER.loginTimerStop();
        CsxUtil.signout();
    }
    render() {
        const { loginVisible, hint, loading } = this.state;
        const Logo = withRouter(({ history }) => <img width={115} src={logo} style={{ userSelect: 'none' }} alt='logo' className='logo' onClick={() => { history.push('/'); }} />);
        const LanguageToggle = (
            <Dropdown
                style={{ marginTop: '2px' }}
                parent={<Tooltip text={getText('TOOLTIP_LANGUAGE')} direction='left-bottom' fixedWidth={160}>
                    <Icon type='earth' color='rgb(0,138,158)' style={{ transform: 'scale(1.3)' }} />
                </Tooltip>}
                children={window.APP_LANGUAGE_METADATA.map(metadata => {
                    return {
                        label: metadata.localDescription,
                        onClick: () => { this.onSelectLang(metadata.code); }
                    };
                }).filter(n => !!n)}
            />
        );
        const LoginToggle = (
            <Tooltip text={getText('TOOLTIP_USER_LOGIN')} direction='left-bottom' fixedWidth={80}>
                <img style={{ cursor: 'pointer', zIndex: 22 }} width={24} src={signin} alt='main' onClick={this.onOpenLoginDialog} />
            </Tooltip>
        );
        const LogoutToggle = (
            <Tooltip text={getText('TOOLTIP_USER_LOGOUT')} direction='left-bottom' fixedWidth={150}>
                <Icon type='exit' style={{ transform: ' scale(1.3)' }} color='#e84a45' onClick={this.onLogout} />
            </Tooltip>
        );
        const HomeToggle = withRouter(({ history }) => {
            const goto_home = () => { history.push('/'); }
            return (window.CSX_CUR_USER) ? (
                <Tooltip text={getText('TOOLTIP_HOME_PAGE')} direction='left-bottom'>
                    <Icon type='home' color='rgb(0,138,158)' style={{ transform: 'scale(1.3)' }} onClick={goto_home}/>
                </Tooltip>
            ) : <div style={{ display: 'none' }}/>;
        });
        const ManageToggle = withRouter(({ history }) => {
            const goto_manage = () => { history.push('/management/'); };
            return (window.CSX_CUR_USER) ? (
                <Tooltip text={getText('TOOLTIP_DASHBOARD_PAGE')} direction='left-bottom' fixedWidth={150}>
                    <Icon type='dashboard' color='rgb(0,138,158)' style={{ transform: 'scale(1.3)' }} onClick={goto_manage}/>
                </Tooltip>
            ) : <div style={{ display: 'none' }}/>;
        });
        return (
            <Layout.Header>
                <Modal
                    className='login_view'
                    visible={loginVisible}
                >
                    <div className='logo' />
                    <div className='form'>
                        <Spin visible={loading} type='linear' style={{ opacity: 0.7 }} />
                        <div className='label'>{getText('LOGIN')}</div>
                        <Input className='username' placeholder={getText('SETTING_USR_MANAGE_USERNAME')} onChange={this.onChangeUsername} onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => { if (e.key === 'Enter') this.onLogin() }} />
                        <Input className='password' placeholder={getText('SETTING_USR_MANAGE_PASSWORD')} type='password' onChange={this.onChangePassword} onKeyPress={(e: React.KeyboardEvent<HTMLInputElement>) => { if (e.key === 'Enter') this.onLogin() }} />
                        <Button className='submit' type='primary' onClick={this.onLogin}>{getText('CONTINUE')}</Button>
                        <div className={`hint${(hint.length > 0) ? ' visible' : ''}`}>{hint}</div>
                        <Button onClick={this.onOpenLoginDialog}>{getText('APP_DIALOG_CANCEL')}</Button>
                    </div>
                </Modal>
                <div className='header_context_wrapper'>
                    <Logo />
                    {(window.APP_ON_HDMI) ? <APPHeaderIPShower /> : undefined}
                    {LanguageToggle}
                    <HomeToggle />
                    <ManageToggle />
                    {(window.CSX_CUR_USER) ? LogoutToggle : LoginToggle}
                </div>
            </Layout.Header>
        );
    }
}

class Management extends CsxUI.IRQComponent<any> {
    render() {
        let is_support_dashboard = false;
        let is_support_device_control = false;
        let is_support_event_sys = false;
        let is_support_room = false;
        let is_support_automation = false;
        let is_support_scenario = false;
        let is_support_icon = false;
        let is_support_settings = false;
        let is_support_factory_test = false;
        let is_support_user_management = false;
        if (window.CSX_CUR_AUTH) {
            is_support_dashboard = csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('dashboard_permission'), CsxUserPermissionLevel.ViewOnly);
            is_support_device_control = csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('device_permission'), CsxUserPermissionLevel.ViewAssigned);
            is_support_room = csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('room_view'), CsxUserPermissionLevel.ViewAssigned);
            is_support_scenario = csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('scenario_view'), CsxUserPermissionLevel.ViewAssigned);
            is_support_automation = csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('automation_view'), CsxUserPermissionLevel.ViewAssigned);
            is_support_icon = csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('icon_view'), CsxUserPermissionLevel.ViewAssigned);
            is_support_event_sys = (is_support_room || is_support_automation || is_support_scenario || is_support_icon);
            is_support_settings = csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('system_permission'), CsxUserPermissionLevel.ViewOnly);
            is_support_factory_test = (window.CSX_CUR_AUTH.get('cs9_factory_test') === 'y');
            is_support_user_management = csxUserPermissionSatisfy(window.CSX_CUR_AUTH.getPermission('user_view'), CsxUserPermissionLevel.ViewOnly);
        }
        const DashboardNav = withRouter(({ history }) => (
            <Layout.NavigationItem tooltip={getText('APP_NAV_DASHBOARD')} label={getText('APP_NAV_DASHBOARD')} icon='dashboard' onClick={() => { history.push('/management/dashboard'); }} disabled={!is_support_dashboard} />
        ));
        const DeviceNav = withRouter(({ history }) => (
            <Layout.NavigationItem tooltip={getText('APP_NAV_DEVICES')} label={getText('APP_NAV_DEVICES')} icon='device' onClick={() => { history.push('/management/devices'); }} disabled={!is_support_device_control} />
        ));
        const EventSystemNav = withRouter(({ history }) => (
            <Layout.NavigationItem tooltip={getText('APP_NAV_EVENT_SYSTEM')} label={getText('APP_NAV_EVENT_SYSTEM')} icon='scenario' disabled={!is_support_event_sys} children={[
                { label: getText('APP_NAV_ROOM'), onClick: () => { history.push('/management/es/room'); }, disabled: !is_support_room },
                { label: getText('APP_NAV_SCENARIO'), onClick: () => { history.push('/management/es/scenario'); }, disabled: !is_support_scenario },
                { label: getText('APP_NAV_AUTOMATION'), onClick: () => { history.push('/management/es/automation'); }, disabled: !is_support_automation },
                { label: getText('APP_NAV_ICON'), onClick: () => { history.push('/management/es/icon'); }, disabled: !is_support_icon },
            ]} />
        ));
        const SettingsNav = withRouter(({ history }) => (
            <Layout.NavigationItem tooltip={getText('APP_NAV_SETTINGS')} label={getText('APP_NAV_SETTINGS')} icon='setting' disabled={!is_support_settings && !is_support_user_management} children={[
                { label: getText('APP_NAV_SYSTEM'), onClick: () => { history.push('/management/settings/system'); }, disabled: !is_support_settings },
                { label: getText('APP_NAV_USER_MANAGE'), onClick: () => { history.push('/management/settings/authentication'); }, disabled: !is_support_user_management },
            ]} />
        ));
        const FactoryNav = withRouter(({ history }) => (
            <Layout.NavigationItem label='Factory' icon='cyp-device' onClick={() => { history.push('/management/factory'); }} disabled={!is_support_factory_test} />
        ));
        return (
            <Layout.Body>
                <Layout.Sider toggleTooltip={getText('APP_NAV_TOGGLER')}>
                    <DashboardNav />
                    <DeviceNav />
                    <EventSystemNav />
                    <SettingsNav />
                    <FactoryNav />
                </Layout.Sider>
                <Layout.Center>
                    <Route path='/management/(dashboard|)/' render={() => ((is_support_dashboard) ? <DashboardPage /> : <NotAuthorized />)} />
                    <Route path='/management/es/room/' render={() => ((is_support_event_sys) ? <RoomPage /> : <NotAuthorized />)} />
                    <Route path='/management/es/scenario/' render={() => ((is_support_event_sys) ? <ScenarioPage /> : <NotAuthorized />)} />
                    <Route path='/management/es/automation/' render={() => ((is_support_event_sys) ? <AutomationPage /> : <NotAuthorized />)} />
                    <Route path='/management/es/icon/' render={() => ((is_support_event_sys) ? <IconPage /> : <NotAuthorized />)} />
                    <Route path='/management/devices/' render={() => ((is_support_device_control) ? <DevicesPage /> : <NotAuthorized />)} />
                    <Route path='/management/settings/system' render={() => ((is_support_settings) ? <SettingPage /> : <NotAuthorized />)} />
                    <Route path='/management/settings/authentication' render={() => ((is_support_user_management) ? <SettingPage /> : <NotAuthorized />)} />
                    <Route path='/management/factory/' render={() => ((is_support_factory_test) ? <FactoryPage /> : <NotAuthorized />)} />
                </Layout.Center>
            </Layout.Body>
        );
    }
}

export default class App extends CsxUI.IRQComponent<any> {
    state: { ready: boolean } = { ready: false };
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'AuthenticationUpdate';
        window.APP_SRC = (process.env.REACT_APP_MODE === CsxUtil.APP_SRC_TYPE.DYNAMODB) ? CsxUtil.APP_SRC_TYPE.DYNAMODB : CsxUtil.APP_SRC_TYPE.LOCAL;
        window.APP_DEV_MODE = (process.env.REACT_APP_DEV_MODE === 'yes');
        window.APP_DEBUG_MESSAGING = (process.env.REACT_APP_DEBUG_MESSAGE === 'yes');
        // window.APP_DEBUG_MESSAGING = (process.env.REACT_APP_DEBUG_MESSAGE === 'yes' || process.env.REACT_APP_DEV_MODE === 'yes');
        window.APP_CLOUD_MODE = false;
        window.APP_ON_HDMI = (window.location.href.indexOf('127.0.0.1') >= 0);
        document.title = (window.APP_SRC === CsxUtil.APP_SRC_TYPE.LOCAL) ? `${CSX_NAME}` : 'CYP Cloud';
        this.initialize();
    }

    initialize = async () => {
        if (window.APP_DEV_MODE) {
            // decrypt with 9ccb4bb85981939fcd25fe9fda0f0485
            window.APP_CORE_INDEX = CsxUtil.AESDecrypt(productkey.Key, '9ccb4bb85981939fcd25fe9fda0f0485');
        } else {
            await CsxProductKeyGrabber.load();
            try {
                // decrypt with 9ccb4bb85981939fcd25fe9fda0f0485
                const parseKey = JSON.parse(CsxProductKeyGrabber.text);
                window.APP_CORE_INDEX = CsxUtil.AESDecrypt(parseKey.Key, '9ccb4bb85981939fcd25fe9fda0f0485');
            } catch (error) {
                console.log('error :>> ', error);
                Notify({ title: getText('NOTIFY_TITLE_FAIL'), context: 'Product key unauthorized', type: 'error', timeout: 0 });
                return;
            }
        }
        await languagePackageLoad();
        if (window.CSX_MANAGER) { window.CSX_MANAGER.free(); }
        window.CSX_MANAGER = new CsxControlSystem.default();
        try {
            await window.CSX_MANAGER.initialize();
            this.setState({ ready: true });
            Notify({ title: getText('NOTIFY_TITLE_SUCCESS'), context: getText('NOTIFY_MSG_CONNECT_SUCCESS'), type: 'success' });
        } catch (err) {
            Notify({ title: getText('NOTIFY_TITLE_FAIL'), context: `${err}`, type: 'error', timeout: 0 });
            this.setState({ ready: true });
        }
    }
    render() {
        const { ready } = this.state;
        return (
            <Router getUserConfirmation={window.userConfirm}>
                <Spin visible={!ready} type='linear' />
                {(ready) ? <Layout.Layout>
                    <AppHeader />
                    <Layout.Navigation />
                    <Switch>
                        <Route path='/(|guest)/' render={() => <HomePage />} />
                        <Route path='/management/' render={() => <Management />} />
                        {/* <Route path='/management/' render={() => ((ready) ? <Management /> : <NotFound />)} /> */}
                        <Route render={() => <Layout.Body><NotFound /></Layout.Body>} />
                    </Switch>
                    {/* <Layout.Footer /> */}
                    <AppFooter />
                </Layout.Layout> : undefined}
            </Router>
        );
    }
}