import React from 'react';
import { withRouter } from 'react-router-dom';
import { Href, Modal, Form, Button, Select, Option, Input, Checkbox, SwitchButton, Notify, Icon, ProgressBar, RadioGroup, Flat } from 'cypd';

import { CsxUI, CsxFeature, CsxUtil, CsxEventSystem, CsxDesc, CsxControlSystem } from '../../csx';

import './page.css';
// import CryptoJS from 'crypto-js';
import { CsxUserPermissionLevel, csxUserPermissionSatisfy } from '../../csx/manager';

const ICON_TABLE_SORT_BASE = 'ICON_TABLE_SORT_BASE';
const ICON_TABLE_KEY_FILTER_LOCAL_KEY = 'ICON_TABLE_KEY_FILTER_LOCAL_KEY';
const IMAGE_ACCEPT_FILE_FORMAT = ['jpeg', 'jpg', 'bmp', 'gif', 'png', 'svg'];
const IMAGE_ACCEPT_HTML_STRING = IMAGE_ACCEPT_FILE_FORMAT.map(ext => '.' + ext).join(',');
const { getText } = CsxDesc;

const CsxIconSortTypeList = CsxUtil.stringLitArray(['Most Recently', 'A-Z', 'Z-A']);
type CsxIconSortType = (typeof CsxIconSortTypeList)[number];
const isCsxIconSortType = (x: any): x is CsxIconSortType => (x && CsxIconSortTypeList.indexOf(x) >= 0);
const ICON_SORTMAP: { [method in CsxIconSortType]: ((icon_id1: string, icon_id2: string, instance: CsxFeature.CsxEventSystemDevice) => number) } = {
    'A-Z': (icon_id1: string, icon_id2: string, instance: CsxFeature.CsxEventSystemDevice) => {
        const a = instance.Icon(icon_id1);
        const b = instance.Icon(icon_id2);
        if (!a || !b) { return 0; }
        if (a.LIKED && !b.LIKED) { return -1; }
        else if (!a.LIKED && b.LIKED) { return 1; }

        const a_name_lower = a.NAME.toLowerCase();
        const b_name_lower = b.NAME.toLowerCase();
        if (a_name_lower < b_name_lower) { return -1; } 
        else if (a_name_lower > b_name_lower) { return 1; }
        else { return 0; }
    },
    'Z-A': (icon_id1: string, icon_id2: string, instance: CsxFeature.CsxEventSystemDevice) => {
        const a = instance.Icon(icon_id1);
        const b = instance.Icon(icon_id2);
        if (!a || !b) { return 0; }
        if (a.LIKED && !b.LIKED) { return -1; }
        else if (!a.LIKED && b.LIKED) { return 1; }

        const a_name_lower = a.NAME.toLowerCase();
        const b_name_lower = b.NAME.toLowerCase();
        if (a_name_lower > b_name_lower) { return -1; } 
        else if (a_name_lower < b_name_lower) { return 1; }
        else { return 0; }
    },
    'Most Recently': (icon_id1: string, icon_id2: string, instance: CsxFeature.CsxEventSystemDevice) => {
        const scenario_list = Array.from(instance.ScenarioSet());
        const a = instance.Icon(icon_id1);
        const b = instance.Icon(icon_id2);
        let using_count1 = 0;
        let using_count2 = 0;
        if (!a || !b) { return 0; }
        if (a.LIKED && !b.LIKED) { return -1; }
        else if (!a.LIKED && b.LIKED) { return 1; }
        for (let i = 0; i < scenario_list.length; i++) {
            const sid = scenario_list[i];
            const scenario = instance.Scenario(sid);
            if (scenario) {
                if (scenario.IMAGE_ID === a.ID) { using_count1 ++; }
                if (scenario.IMAGE_ID === b.ID) { using_count2 ++; }
            }
        }
        if (using_count1 > using_count2) { return -1; } 
        else if (using_count1 < using_count2) { return 1; }
        else { return 0; }
    },
}

type IconFrameProperties = {
    defaultSelected?: boolean;
    framePadding: number;
    headerHeight: number;
    footerHeight: number;
    selectType?: 'single' | 'multiple';
    onChecked: (s: string, checked: boolean) => void;
    iconID: string;
}

declare global {
    interface Window {
        ICON_SELECTOR: {
            __iconSelectFunctionMap: { [icon_id: string]: (checked: boolean) => void };
            __prevIconUnselect?: () => void; // For multi-select type icon selector
            __enableOperationForm?: (enable: boolean) => void; // Prevent UI from rendering large number of icon frame
            __popModal?: (modal?: IconSelectorModalType, ...args: Array<any>) => void; // Prevent UI from rendering large number of icon frame
        }
    }
}

window.ICON_SELECTOR = {
    __iconSelectFunctionMap: {},
};

function selectIcon(icon_id: string, checked: boolean) {
    const selectFunc = window.ICON_SELECTOR.__iconSelectFunctionMap[icon_id];
    if (typeof selectFunc !== 'undefined') {
        selectFunc(checked);
    }
}

export class IconFrame extends CsxUI.IRQComponent<IconFrameProperties> {
    state: {
        selected: boolean;
    } = { selected: false };
    instance: CsxFeature.CsxEventSystemDevice = new CsxFeature.CsxEventSystemDevice();
    componentFinishMount = () => {
        const { defaultSelected, iconID, selectType } = this.props;
        let newState: any = {};
        if (defaultSelected) {
            newState.selected = true;
            if (selectType === 'single')
                window.ICON_SELECTOR.__prevIconUnselect = () => { selectIcon(iconID, false); }
        }
        // this.RenderID = `render_icon_${iconID}`;
        window.ICON_SELECTOR.__iconSelectFunctionMap[iconID] = this.onChecked;
        this.setState(newState);
    }
    componentBeforeUmount() {
        const { iconID } = this.props;
        delete window.ICON_SELECTOR.__iconSelectFunctionMap[iconID];
    }
    onChecked = (checked: boolean) => {
        const { iconID, onChecked, selectType } = this.props;

        if (selectType === 'single' && checked) {
            if (window.ICON_SELECTOR.__prevIconUnselect) {
                window.ICON_SELECTOR.__prevIconUnselect();
            }
            window.ICON_SELECTOR.__prevIconUnselect = () => { selectIcon(iconID, false); }
        }
        if (!checked) {
            window.ICON_SELECTOR.__prevIconUnselect = undefined;
        }
        onChecked(iconID, checked);
        this.setState({ selected: checked });
    }
    onPopEdit = (icon_id: string, name: string) => {
        if (window.ICON_SELECTOR.__popModal) {
            window.ICON_SELECTOR.__popModal('edit', icon_id, name);
        }
    };
    render() {
        const { selected } = this.state;
        const { iconID, framePadding, headerHeight, footerHeight } = this.props;
        const icon = this.instance.Icon(iconID);
        const liked = (icon) ? icon.LIKED : false;

        if (!icon)
            return undefined;
        return (
            <div
                className={`icon_frame ${selected ? 'selected' : ''} ${liked ? 'liked' : ''}`}
                style={{
                    position: 'relative',
                    width: '100%',
                    padding: `0 ${framePadding}px 0 ${framePadding}px`,
                }}
            >
                <div className='op_row' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center', position: 'relative', top: 0, width: '100%', height: `${headerHeight}px` }}>
                    <Checkbox checked={selected} onChange={(e) => { this.onChecked(e.target.checked); }} />
                    <SwitchButton checked={liked} onChange={(e) => {
                        const new_icon = icon;
                        // const new_icon = icon.clone;
                        new_icon.LIKED = e.target.checked;
                        this.instance.SetIcon(new_icon);
                    }} type='heart' />
                    <Icon type='setting' color="#088aab" onClick={() => { this.onPopEdit(icon.ID, icon.NAME); }} />
                </div>
                <CsxUI.FallbackImage
                    src={`${icon.URL}`}
                    height='auto'
                    width='100%'
                    onClick={() => { this.onChecked(!selected); }}
                />
                <div
                    className='name_row'
                    style={{
                        position: 'relative',
                        textAlign: 'center',
                        height: `${footerHeight}px`,
                        lineHeight: `${footerHeight}px`,
                        width: '100%',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                        textOverflow: 'ellipsis',
                        marginRight: '5px'
                    }}
                    onClick={() => { this.onChecked(!selected); }}
                >
                    {icon.NAME.replace(/_/g, ' ')}
                </div>
            </div>
        );
    }
}

type IconSelectorModalType = 'add' | 'edit' | 'upload';

type IconSelectorProperties = {
    width?: number;
    height?: number;
    framesInRow?: number;
    onChange?: (icons: Array<string>) => void;
    onDownload?: (icons: Array<string>) => void;
    onDelete?: (icons: Array<string>) => void;
    selectType?: 'single' | 'multiple';
    defaultSelected?: Array<string>;
}

type IconSelectorFormProperties = {
    // pop: (modal: IconSelectorModalType) => void;
    onDownload?: () => void;
    onDelete?: () => void;
}

class IconSelectorOperationForm extends React.Component<IconSelectorFormProperties> {
    ICON_IMPORT_OPTION_LABEL: { [s in CsxControlSystem.IconImportOptionType]: string } = {
        "auto-rename": getText('ICON_IMPORT_BEHAVIOR_DESC_AUTO_RENAME'),
        "overwrite-same-name": getText('ICON_IMPORT_BEHAVIOR_DESC_OVERWRITE'),
        "skip-same-name": getText('ICON_IMPORT_BEHAVIOR_DESC_SKIP'),
    }
    state: {
        popup?: IconSelectorModalType;
        downloadDisable: boolean;
        deleteDisable: boolean;
        loading: boolean;
        icon_edit_s: string;
        new_icon_name: string;
        tmp_filepath: string;
    } = {
        downloadDisable: true,
        deleteDisable: true,
        loading: false,
        icon_edit_s: '',
        new_icon_name: '',
        tmp_filepath: '',
    };
    instance: CsxFeature.CsxEventSystemDevice = new CsxFeature.CsxEventSystemDevice();
    imageFileTmp: File | undefined;
    imageFileSelector: HTMLInputElement | null | undefined;
    iconsFileTmp: File | undefined;
    iconsFileSelector: HTMLInputElement | null | undefined;
    // iconBatcher: CsxUtil.ArrayBatcher<CsxEventSystem.CsxIconJson>;
    /* icon upload flag */
    overwriteIconFlag: boolean = false;
    /* For icon upload policy */
    dialog_icon_upload_policy: CsxControlSystem.IconImportOptionType = 'auto-rename';
    dialog_icon_upload_policy_remember: boolean = false;
    constructor(props: IconSelectorFormProperties) {
        super(props);
        window.ICON_SELECTOR.__enableOperationForm = this.enableOperationForm;
        window.ICON_SELECTOR.__popModal = this.pop;
    }
    componentWillUnmount() {
        window.ICON_SELECTOR.__enableOperationForm = undefined;
        window.ICON_SELECTOR.__popModal = undefined;
    }
    enableOperationForm = (enable: boolean) => {
        this.setState({ downloadDisable: !enable, deleteDisable: !enable });
    }
    pop = (modal?: IconSelectorModalType, ...args: Array<any>) => {
        if (!modal) {
            this.setState({
                new_icon_name: '',
                tmp_filepath: '',
                icon_edit_s: '',
                icon_upload_left: 0,
                icon_upload_total: 0,
            });
            this.imageFileTmp = undefined;
            this.iconsFileTmp = undefined;
        }
        if (modal === 'add') {
            if (this.instance.IconSize() >= this.instance.IconSizeLimit()) {
                Notify({ title: getText('NOTIFY_TITLE_CREATE_ICON'), context: `${getText('NOTIFY_MSG_ICON_SIZE_LIMIT')} ${this.instance.IconSizeLimit()}.`, type: 'error', timeout: 10000 });
                return;
            }
        } else if (modal === 'edit') {
            const icon_id = args[0];
            const new_name = args[1];
            console.log('args :>> ', args);
            this.setState({
                icon_edit_s: (icon_id) ? icon_id : '',
                new_icon_name: (new_name) ? new_name : '',
            });
        }
        this.setState({ popup: modal });
    }
    checkIconUploadInvalid = (obj: any): 'stop' | 'continue' => {
        if (CsxEventSystem.isCsxIconJson(obj)) {
            if (this.instance.CheckIconNameExist(obj.name)) {
                this.setState({ icon_upload_stop_tmp_json: CsxUtil.nestedClone(obj), new_icon_name: obj.name, batch_uploader_status: 'stop' });
                return 'stop';
            } else {
                this.setState({ icon_upload_stop_tmp_json: CsxUtil.nestedClone(obj), new_icon_name: '', batch_uploader_status: 'continue' });
                return 'continue';
            }
        }
        return 'stop';
    }
    onChangeIconName = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ new_icon_name: e.target.value }); }
    onChangeIconImportOption = (v: string) => { if (CsxControlSystem.isIconImportOptionType(v)) this.dialog_icon_upload_policy = v; }
    onChangeIconImportHintRemember = (e: React.ChangeEvent<HTMLInputElement>) => { this.dialog_icon_upload_policy_remember = e.target.checked; }
    onCreateIcon = () => {
        const { new_icon_name } = this.state;
        if (this.imageFileTmp && new_icon_name.length > 0) {
            const ext = this.imageFileTmp.name.split('.').slice(-1).toString().toLowerCase();
            const new_icon = new CsxEventSystem.CsxIcon('0', {
                name: new_icon_name,
                id: '0',
                isLiked: false,
                format: ext,
            });
            this.instance.SetIcon(new_icon, this.imageFileTmp);
        }
    }
    onEditIcon = () => {
        const { new_icon_name, icon_edit_s } = this.state;
        const icon_edit = this.instance.Icon(icon_edit_s);

        if (icon_edit && new_icon_name.length > 0) {
            icon_edit.NAME = new_icon_name;
            if (this.imageFileTmp) {
                const ext = this.imageFileTmp.name.split('.').slice(-1).toString().toLowerCase();
                icon_edit.FORMAT = ext;
            }
            this.instance.SetIcon(icon_edit, this.imageFileTmp);
        }
        this.pop(undefined);
    }
    onUploadIcons = async () => {
        const cur_option = this.instance.UserIconImportOption();
        const cur_remember = this.instance.UserIconImportHintRemember();
        if (cur_remember === 'true') {
            /* Don't ask user again */
            if (this.iconsFileTmp && window.FOCUS_GATEWAY) {
                try {
                    await window.FOCUS_GATEWAY.iconPackageImport(this.iconsFileTmp, cur_option);
                } catch (error) {
                    console.log('error :>> ', error);
                }
            }
            this.iconsFileTmp = undefined;
            this.pop(undefined);
        } else {
            const import_option_form = (
                <div style={{ width: '100%', height: '100%' }}>
                    <p>{getText('CONFIRM_IMPORT_ICON_BACKUP')}</p>
                    <RadioGroup
                        defaultValue={(CsxControlSystem.isIconImportOptionType(cur_option) ? cur_option : 'auto-rename')}
                        layout='vertical'
                        options={CsxControlSystem.ICON_IMPORT_OPTION_TYPE_LIST.map(option_type => {
                            return { value: option_type, label: this.ICON_IMPORT_OPTION_LABEL[option_type] };
                        })}
                        onChange={this.onChangeIconImportOption}
                    />
                    <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Checkbox
                            label={getText('ICON_IMPORT_BEHAVIOR_REMEMBER_OPTION')}
                            defaultChecked={(cur_remember === 'true')}
                            onChange={this.onChangeIconImportHintRemember}
                        />
                    </Form.Item>
                </div>
            );
            if (CsxControlSystem.isIconImportOptionType(cur_option)) {
                this.dialog_icon_upload_policy = cur_option;
            }
            this.dialog_icon_upload_policy_remember = (cur_remember === 'true');
            window.userConfirm(import_option_form, async (ok) => {
                if (ok) {
                    if (this.iconsFileTmp && window.FOCUS_GATEWAY) {
                        this.instance.SetUserIconImportOption(this.dialog_icon_upload_policy);
                        this.instance.SetUserIconImportHintRemember(this.dialog_icon_upload_policy_remember ? 'true' : 'false');
                        try {
                            await window.FOCUS_GATEWAY.iconPackageImport(this.iconsFileTmp, this.dialog_icon_upload_policy);
                        } catch (error) {
                            console.log('error :>> ', error);
                        }
                    }
                    this.iconsFileTmp = undefined;
                    this.pop(undefined);
                }
            }, {
                type: 'warning',
                width: 500,
                bodyFooterMargin: 0,
                bodyAlignment: 'left',
            });
        }
    }
    onSelectImageFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const image = (event.target.files) ? event.target.files[0] : undefined;
        if (image) {
            const ext = image.name.split('.').slice(-1).toString().toLowerCase();
            let err = '';
            if (ext.length === 0)
                err = 'Image format unsupport';
            if (image.size > 4000000)
                err = 'Image size too large';
            if (err.length > 0) {
                Notify({ title: 'Error', context: err, type: 'error' });
                this.imageFileTmp = undefined;
                this.setState({ tmp_filepath: '' });
            } else {
                this.imageFileTmp = image;
                this.setState({ tmp_filepath: image.name });
            }
        }
        event.target.value = '';
    }
    onSelectIconsFile = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const config = (event.target.files) ? event.target.files[0] : undefined;
        if (config) {
            const ext = config.name.split('.').slice(-2).join('.').toString().toLowerCase();
            const filename_valid = (ext === 'icon.tgz');
            let err = '';
            if (!filename_valid) {
                err = `${getText('HINT_FILE_FORMAT_INCORRECT')}. Expect: icon.tgz`;
            }
            if (err.length > 0) {
                Notify({ title: getText('ERROR'), context: err, type: 'error' });
                this.iconsFileTmp = undefined;
                this.setState({ tmp_filepath: '' });
            } else {
                this.iconsFileTmp = config;
                this.setState({ tmp_filepath: config.name });
            }
        }
        event.target.value = '';
    }
    render() {
        const {
            popup,
            downloadDisable,
            deleteDisable,
            icon_edit_s,
            new_icon_name,
            tmp_filepath,
        } = this.state;
        const { onDownload, onDelete } = this.props;
        /* Invalid input */
        const icon_edit = this.instance.Icon(icon_edit_s);
        const file_selected = (tmp_filepath.length > 0);
        let name_valid = (new_icon_name.length > 0 && !CsxUtil.hasChinese(new_icon_name)) && !this.instance.CheckIconNameExist(new_icon_name);
        if (icon_edit && !name_valid) {
            if (new_icon_name === icon_edit.NAME) {
                name_valid = true;
            }
        }

        /* Modal Definition */
        const modal_title: { [type in IconSelectorModalType]: string } = {
            add: getText('ICON_MODAL_ADD_INFO_TITLE'),
            upload: getText('ICON_MODAL_UPLOAD_TITLE'),
            edit: getText('ICON_MODAL_EDIT_TITLE'),
        }
        const modal_render: { [type in IconSelectorModalType]: React.ReactElement } = {
            add: (
                <Form.Form labelStyle={{ width: '100px' }}>
                    <Form.Item label={getText('ICON_MODAL_ADD_NAME_LABEL')}>
                        <Input
                            placeholder='Icon Name'
                            value={new_icon_name}
                            onChange={this.onChangeIconName}
                        />
                    </Form.Item>
                    <Form.Item label={getText('ICON_MODAL_ADD_FILE_LABEL')}>
                        <Button onClick={() => { if (this.imageFileSelector) this.imageFileSelector.click(); }} type='default'>{getText('CHOOSE_FILE')}</Button>
                        {tmp_filepath}
                    </Form.Item>
                    <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Button type='primary' onClick={this.onCreateIcon} disabled={(!name_valid || !file_selected)}>{getText('CONFIRM')}</Button>
                    </Form.Item>
                </Form.Form>
            ),
            edit: (
                <Form.Form labelStyle={{ width: '100px' }}>
                    <Form.Item label={getText('ICON_MODAL_EDIT_NAME_LABEL')}>
                        <Input
                            placeholder='Icon Name'
                            value={new_icon_name}
                            onChange={this.onChangeIconName}
                        />
                    </Form.Item>
                    <Form.Item label={getText('ICON_MODAL_EDIT_FILE_LABEL')}>
                        <Button onClick={() => { if (this.imageFileSelector) this.imageFileSelector.click(); }} type='default'>{getText('CHOOSE_FILE')}</Button>
                        {tmp_filepath}
                    </Form.Item>
                    <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Button type='primary' onClick={this.onEditIcon} disabled={(!name_valid && !file_selected)}>{getText('CONFIRM')}</Button>
                    </Form.Item>
                </Form.Form>
            ),
            upload: (
                <Form.Form labelStyle={{ width: '100px' }}>
                    <Form.Item label={getText('ICON_MODAL_EDIT_FILE_LABEL')}>
                        <Button onClick={() => { if (this.iconsFileSelector) this.iconsFileSelector.click(); }} type='default'>{getText('CHOOSE_FILE')}</Button>
                        <span style={{
                            maxWidth: '200px',
                            overflow: 'hidden',
                            whiteSpace: 'nowrap',
                            textOverflow: 'ellipsis',
                        }}>{tmp_filepath}</span>
                    </Form.Item>
                    <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Button type='primary' onClick={this.onUploadIcons} disabled={!file_selected}>{getText('UPLOAD')}</Button>
                    </Form.Item>
                </Form.Form>
            )
        }
        const icon_edit_permision = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getPermission('icon_edit') : CsxUserPermissionLevel.NoAccess;
        return (
            <Form.Item className='icon_selector_form' style={{ justifyContent: 'flex-end', width: '100%', flexWrap: 'wrap', flexShrink: 0, height: 'auto' }}>
                {!window.APP_ON_HDMI ? <input type='file' style={{ display: 'none' }} ref={inst => { this.imageFileSelector = inst; }} onChange={this.onSelectImageFile} accept={IMAGE_ACCEPT_HTML_STRING} /> : undefined}
                {!window.APP_ON_HDMI ? <input type='file' style={{ display: 'none' }} ref={inst => { this.iconsFileSelector = inst; }} onChange={this.onSelectIconsFile} accept='.tgz' /> : undefined}
                {(csxUserPermissionSatisfy(icon_edit_permision, CsxUserPermissionLevel.FullAccess)) ? <Button style={{ marginBottom: '10px' }} icon='upload' onClick={() => { this.pop('upload'); }}>{getText('UPLOAD')}</Button> : undefined}
                {(onDownload) ? <Button style={{ marginBottom: '10px' }} disabled={downloadDisable} onClick={onDownload} icon='download'>{getText('DOWNLOAD')}</Button> : undefined}
                {(csxUserPermissionSatisfy(icon_edit_permision, CsxUserPermissionLevel.FullAccess)) ? <Button style={{ marginBottom: '10px' }} onClick={() => { this.pop('add'); }} icon='plus' type='primary'>{getText('ADD')}</Button> : undefined}
                {(onDelete && csxUserPermissionSatisfy(icon_edit_permision, CsxUserPermissionLevel.FullAccess)) ? <Button style={{ marginRight: '0', marginBottom: '10px' }} disabled={deleteDisable} onClick={onDelete} icon='trashcan' type='danger'>{getText('DELETE')}</Button> : undefined}
                <Modal
                    title={(popup) ? modal_title[popup] : ''}
                    visible={typeof popup !== 'undefined'}
                    onClose={() => { this.pop(undefined); }}
                >
                    {(popup) ? modal_render[popup] : undefined}
                </Modal>
                <span style={{ width: '100%', color: 'red', textAlign: 'right' }}>{getText('HINT_ICON_FORMAT')}</span>
                <span style={{ width: '100%', color: 'red', textAlign: 'right' }}>{getText('HINT_ICON_PREFERRED')}</span>
            </Form.Item>
        );
    }
}

export class IconSelector extends React.Component<IconSelectorProperties> {
    state: {
        sortby: CsxIconSortType,
        searchby: string,
    };
    icon_multi_s: Set<string> = new Set();
    instance: CsxFeature.CsxEventSystemDevice;
    constructor(props: IconSelectorProperties) {
        super(props);

        const local_store_sort_method = window.localStorage.getItem(ICON_TABLE_SORT_BASE);
        const local_store_key_filter = window.localStorage.getItem(ICON_TABLE_KEY_FILTER_LOCAL_KEY);

        this.instance = new CsxFeature.CsxEventSystemDevice();
        this.state = {
            sortby: isCsxIconSortType(local_store_sort_method) ? local_store_sort_method : 'A-Z',
            searchby: (local_store_key_filter) ? local_store_key_filter : '',
        };

        document.addEventListener('keydown', this.keydownHandler);
    }
    componentDidMount() {
        /* Render in correct sizing */
        this.forceUpdate();
    }
    componentDidUpdate() {
        /* This is to fix unsync issue when deleting icons */
        const icon_multi_s_array = Array.from(this.icon_multi_s);
        for (let i = 0; i < icon_multi_s_array.length; i++) {
            const icon_id = icon_multi_s_array[i];
            if (typeof window.ICON_SELECTOR.__iconSelectFunctionMap[icon_id] === 'undefined') {
                this.icon_multi_s.delete(icon_id);
            }
        }
        if (window.ICON_SELECTOR.__enableOperationForm) {
            window.ICON_SELECTOR.__enableOperationForm((this.icon_multi_s.size > 0));
        }
    }
    get show_icons() {
        const { searchby, sortby } = this.state;
        let arr: Array<string> = Array.from(this.instance.IconSet())
        arr = arr.filter((id) => {
            const icon = this.instance.Icon(id);
            if (searchby.length > 0)
                return (icon && icon.NAME.toLowerCase().indexOf(searchby.toLowerCase()) >= 0);
            else
                return (typeof icon !== 'undefined');
        });
        arr.sort((a, b) => ICON_SORTMAP[sortby](a, b, this.instance));
        return arr;
    }
    keydownHandler = (ev: KeyboardEvent) => {
        // const set_checked = (this.icon_multi_s.size === 0);
        // const all_icons = Object.keys(window.ICON_SELECTOR.__iconSelectFunctionMap);
        const all_icons = this.show_icons;
        if (ev.ctrlKey && ev.shiftKey) {
            if (ev.key === '<') {
                all_icons.forEach(icon_id => { selectIcon(icon_id, true); });
                this.icon_multi_s = new Set(all_icons);
            } else if (ev.key === '>') {
                all_icons.forEach(icon_id => { selectIcon(icon_id, false); });
                this.icon_multi_s = new Set();
            }
        }
    }
    checkIconUploadInvalid = (obj: any): 'stop' | 'continue' => {
        if (CsxEventSystem.isCsxIconJson(obj)) {
            if (this.instance.CheckIconNameExist(obj.name)) {
                this.setState({ icon_upload_stop_tmp_json: CsxUtil.nestedClone(obj), new_icon_name: obj.name, batch_uploader_status: 'stop' });
                return 'stop';
            } else {
                this.setState({ icon_upload_stop_tmp_json: CsxUtil.nestedClone(obj), new_icon_name: '', batch_uploader_status: 'continue' });
                return 'continue';
            }
        }
        return 'stop';
    }
    onChangeSortBy = (v: string) => { this.setState({ sortby: v }); window.localStorage.setItem(ICON_TABLE_SORT_BASE, v); }
    onChangeKeywordFilter = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ searchby: e.target.value }); window.localStorage.setItem(ICON_TABLE_KEY_FILTER_LOCAL_KEY, e.target.value); }
    onCheckedIcon = (iid: string, checked: boolean) => {
        const { selectType, onChange } = this.props;
        if (checked) {
            if (selectType === 'single') {
                this.icon_multi_s = new Set();
            }
            this.icon_multi_s.add(iid);
        } else {
            this.icon_multi_s.delete(iid);
        }
        if (onChange) {
            onChange(Array.from(this.icon_multi_s));
        }
        if (window.ICON_SELECTOR.__enableOperationForm) {
            window.ICON_SELECTOR.__enableOperationForm((this.icon_multi_s.size !== 0));
        }
    }
    onDeleteSelect = () => {
        if (this.props.onDelete && this.icon_multi_s.size > 0) {
            window.userConfirm(getText('CONFIRM_REMOVE_ICON'), (ok) => {
                if (this.props.onDelete && ok && this.icon_multi_s.size > 0) {
                    this.props.onDelete(Array.from(this.icon_multi_s));
                    this.icon_multi_s.forEach(icon_id => {
                        const selectFunc = window.ICON_SELECTOR.__iconSelectFunctionMap[icon_id];
                        if (typeof selectFunc !== 'undefined') {
                            selectFunc(false);
                        }
                    });
                    this.icon_multi_s = new Set();
                }
            });
        }
    }
    onDownloadSelect = () => {
        if (this.props.onDownload && this.icon_multi_s.size > 0) {
            this.props.onDownload(Array.from(this.icon_multi_s));
        }
    }
    render() {
        const { sortby, searchby, } = this.state;
        const { width, height, framesInRow, onDelete, onDownload, selectType, defaultSelected } = this.props;
        const icon_list = this.show_icons;
        const frame_header_h = 30; // px
        const frame_footer_h = 30; // px
        const frame_padding = 5; // px
        const max_section_size = (width) ? width : 600; // default
        const span_style: React.CSSProperties = {
            position: 'relative',
            // marginBottom: '10px',
            whiteSpace: 'nowrap',
            color: '#707070',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'stretch',
            alignItems: 'center',
            height: '100%',
            flexGrow: 2,
            maxWidth: `calc(50% - 10px)`,
        };

        const icon_view_permision = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getPermission('icon_view') : CsxUserPermissionLevel.NoAccess;
        return (
            <div style={{ maxWidth: `${max_section_size}px`, width: '100%' }}>
                <IconSelectorOperationForm onDelete={onDelete ? this.onDeleteSelect : undefined} onDownload={onDownload ? this.onDownloadSelect : undefined}/>
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', marginBottom: '10px' }}>
                    <span style={span_style}>
                        Sort by:
                        <Select value={sortby} size='small' style={{ margin: '0 5px' }} onChange={this.onChangeSortBy}>
                            {CsxIconSortTypeList.map(v => <Option key={`icon-sort-by-${v}`} value={v}>{v}</Option>)}
                        </Select>
                    </span>
                    <span style={span_style}>
                        Search:
                        <Input value={searchby} placeholder='Name' size='small' onChange={this.onChangeKeywordFilter} style={{ flexGrow: 2, marginLeft: '5px' }} />
                    </span>
                </div>
                {(icon_list.length > 0) ? <CsxUI.DynamicMatrix
                    className='app_icon_selector_container'
                    height={height}
                    width={width}
                    frameDescription={{
                        columnLimit: framesInRow,
                        indexArray: (csxUserPermissionSatisfy(icon_view_permision, CsxUserPermissionLevel.ViewAssigned)) ? icon_list : [],
                        array: (csxUserPermissionSatisfy(icon_view_permision, CsxUserPermissionLevel.ViewAssigned)) ? icon_list : [],
                        render: (iid: string) => {
                            return (
                                <IconFrame
                                    defaultSelected={(defaultSelected) ? (defaultSelected.indexOf(iid) >= 0) : false}
                                    iconID={iid}
                                    onChecked={this.onCheckedIcon}
                                    framePadding={frame_padding}
                                    headerHeight={frame_header_h}
                                    footerHeight={frame_footer_h}
                                    selectType={selectType}
                                />
                            );
                        }
                    }}
                /> : undefined}
            </div>
        );
    }
}

class Breadcrum extends React.Component<any> {
    render() {
        const Component = withRouter(({history}) => (
            <div className='breadcrum'>
                <Href className='breadcrum-item' to={() => { history.push(`/management/es/icon`); }}>{getText('APP_NAV_ICON')}</Href>
            </div>
        ));
        return <Component />;
    }
}

export default class IconPage extends CsxUI.IRQComponent<any> {
    state: {
        download_process: number;
        popup: string;
    };
    stopDownload: boolean = false;
    instance: CsxFeature.CsxEventSystemDevice;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'EventSystem';
        this.instance = new CsxFeature.CsxEventSystemDevice();
        this.state = {
            download_process: 0,
            popup: '',
        }
    }
    onDownloadCancel = () => {
        this.stopDownload = true;
    }
    onDeleteIcons = (icons: Array<string>) => {
        this.instance.DeleteIcon(icons);
    }
    onDownloadIcons = async (icons: Array<string>) => {
        // const icon_set = new CsxEventSystem.CsxIconSet();
        // this.setState({ popup: 'Download Icons', download_process: 0 });
        // for (let i = 0; i < icons.length; i++) {
        //     const icon_id = icons[i];
        //     const icon = this.instance.Icon(icon_id);
        //     if (icon) { icon_set.add(icon); }
        // }
        // const export_json = icon_set.toJson();
        // const export_size = export_json.list.length;

        // try {
        //     for (let i = 0; i < export_size && !this.stopDownload; i++) {
        //         const icon_json = export_json.list[i];
        //         const icon_obj = this.instance.Icon(icon_json.id);
        //         if (icon_obj) {
        //             if (icon_obj.FORMAT === 'svg') {
        //                 icon_json.textRaw = await icon_obj.rawText();
        //             } else {
        //                 icon_json.base64 = await icon_obj.base64Encode();
        //             }
        //             this.setState({ download_process: (((i + 1) / export_size) * 100) });
        //         }
        //     }
        //     if (export_size > 0 && !this.stopDownload) {
        //         CsxUtil.downloadAsJson(icon_set.FILENAME, JSON.stringify(export_json, null, 4));
        //     }
        // } catch (error) {
        //     console.log('error :>> ', error);            
        // }
        // this.stopDownload = false;
        // this.setState({ popup: '' });
        CsxUtil.downloadFromURL(
            this.instance.IconSetFilename(),
            "/cgi-bin/export-icon",
            null,
            [{ key: 'Content-Type', value: 'application/json;charset=UTF-8' }],
            JSON.stringify({ icons }));
    }
    render() {
        const { popup, download_process } = this.state;
        return <Flat.Playground style={{ height: '100%' }} contextStyle={{ height: 'auto' }}>
            <Breadcrum />
            <Flat.Section title={getText('ICON_OVERVIEW_ALL')} disableCollapsed style={{ width: 'calc(100% - 100px)', maxWidth: 'none' }} titleStyle={{ backgroundColor: 'white', color: '#707070', fontSize: '18px', padding: '10px' }}>
                <IconSelector selectType='multiple' onDelete={this.onDeleteIcons} onDownload={this.onDownloadIcons}/>
            </Flat.Section>
            <Modal
                title={popup}
                visible={(popup.length > 0)}
            >
                <ProgressBar percentage={download_process} hint={'Processing images...'}/>
                {/* <Form.Item style={{ justifyContent: 'flex-end' }}><Button type='danger' onClick={this.onDownloadCancel}>{getText('APP_DIALOG_CANCEL')}</Button></Form.Item> */}
            </Modal>
        </Flat.Playground>;
    }
}
