import React from 'react';
import { List, Button, Icon, Form, Option, Select, Modal, Book, Page, Notify, Empty, Flat } from 'cypd';

import { CsxUI, CsxFeature, CsxEventSystem, CsxDesc, CsxUtil } from '../../../csx';
import {
    CYPActionModalContent,
    TCPActionModalContent,
    UDPActionModalContent,
    RS232ActionModalContent,
} from '../../automation/automation_draw/modal';
import * as Topology from '../../automation/topology/node';

import '../page.css';
import { isCsxScenarioJson } from '../../../csx/event';
import { CsxUserPermissionLevel, csxUserPermissionSatisfy } from '../../../csx/manager';


declare global {
    interface Window {
        routeScenario: {
            FOCUS_SCENARIO: () => CsxEventSystem.CsxScenario | undefined;
        };
    }
}

declare type ScenarioPageState = {
    temp_action?: CsxEventSystem.CsxActionNode;
    form_page: number;
    script_reorder: boolean;
    new_script_order?: Array<string>;
    // edit_panel: boolean;
}

const { getText } = CsxDesc;

export default class ScenarioPage extends CsxUI.IRQComponent<any> {
    ACTION_OPTION: { [s in CsxEventSystem.CsxActionType]: string } = {
        NullAction : 'NullAction',
        // TCPDeviceAction : 'TCPDeviceAction',
        CYPAction : CsxDesc.getText('ACTION_LABEL_CYP'),
        TCPAction : CsxDesc.getText('ACTION_LABEL_TCP'),
        UDPAction : CsxDesc.getText('ACTION_LABEL_UDP'),
        RS232Action : CsxDesc.getText('ACTION_LABEL_RS232'),
    };
    DEFAULT_DATA_TABLE: { [s in CsxEventSystem.CsxActionType]: CsxEventSystem.CsxActionParameter } = {
        NullAction:                 { name: 'NULL ACTION', lockOnCanvas: false, priority: 0, delay: 0 },
        CYPAction:                  { name: CsxDesc.getText('ACTION_BOX_TITLE_CYP', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, priority: 0, delay: 0, CYPDevice: { command: '', device: '' } },
        TCPAction:                  { name: CsxDesc.getText('ACTION_BOX_TITLE_TCP', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, priority: 0, delay: 0, TCP: { ipaddr: '', port: 23, commandRef: '', hexCommand: '', command: '', endchar: CsxEventSystem.CsxTrailType.NA, nic: 'eth0' } },
        UDPAction:                  { name: CsxDesc.getText('ACTION_BOX_TITLE_UDP', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, priority: 0, delay: 0, UDP: { ipaddr: '', port: 9, commandRef: '', hexCommand: '', command: '', endchar: CsxEventSystem.CsxTrailType.NA, nic: 'eth0' } },
        RS232Action:                { name: CsxDesc.getText('ACTION_BOX_TITLE_RS232', CsxUtil.APP_LANG_TYPE.ENGLISH), lockOnCanvas: false, priority: 0, delay: 0, Control: { command: '', commandRef: '', hexCommand: '', endchar: CsxEventSystem.CsxTrailType.NA } },
        // TCPDeviceAction:            { name: 'TCPDeviceAction', lockOnCanvas: false, priority: 0, delay: 0, TCPDevice: { device: '', command: '', commandRef: '', hexCommand: '', endchar: CsxEventSystem.CsxTrailType.NA } },
    };
    ACTION_POP_COMPONENT: { [s in CsxEventSystem.CsxActionType]: React.ReactNode } = {
        CYPAction: <CYPActionModalContent />,
        TCPAction: <TCPActionModalContent />,
        UDPAction: <UDPActionModalContent />, // avoid form content reference to TCP because not s new mounted
        RS232Action: <RS232ActionModalContent />,
        // TCPDeviceAction: <div />,
        NullAction: <div />,
    }
    state: ScenarioPageState;
    instance: CsxFeature.CsxEventSystemDevice;
    fileSelector: HTMLInputElement | null | undefined;
    constructor(props: any) {
        super(props);
        this.IRQIndex = 'EventSystem';
        this.instance = new CsxFeature.CsxEventSystemDevice();
        this.state = {
            form_page: 0,
            script_reorder: false,
            // edit_panel: false,
        };
        window.routeScenario = {
            FOCUS_SCENARIO: () => {
                if (window.FOCUS_SCENARIO_ID) {
                    /**
                     *  For UI usage if no url available
                     */
                    return this.instance.Scenario(window.FOCUS_SCENARIO_ID);
                } else {
                    const curURLParameters = this.getURLParameter();
                    const id = curURLParameters.get('scenario_id');
                    return (id) ? this.instance.Scenario(id) : undefined;
                }
            }
        };
    }
    implementAdd = () => {
        // this function is just to satisfy window.canvas interface, leave it empty
    }
    implementSetPort = () => {
        // this function is just to satisfy window.canvas interface, leave it empty
    }
    implementGetNode = (nid: number): Topology.Node => {
        const { temp_action } = this.state;
        const return_action = (temp_action) ? temp_action : new CsxEventSystem.CsxActionNode(null);
        return {
            nid: nid, type: return_action.TYPE, x: 0, y: 0,
            fields: { in: [], out: [] },
            data: return_action.PARAM,
            parents: new Set(),
        };
    }
    implementSetNodeData = (_: number, data: any) => {
        const { temp_action } = this.state;
        const cur_scenario = window.routeScenario.FOCUS_SCENARIO();
        // const cur_scenario = this.instance.Scenario(this.scenario_id);
        if (cur_scenario && temp_action && CsxEventSystem.isCsxActionParameter(data)) {
            const data_json = CsxEventSystem.CsxActionParameterToJson(data);
            temp_action.setJsonParam(temp_action.TYPE, data_json);
            cur_scenario.setAction(temp_action);
            cur_scenario.ACTIVE = false;
            this.instance.SetScenario(cur_scenario);
        } else {
            Notify({ title: getText('NOTIFY_TITLE_WARNING'), context: getText('NOTIFY_MSG_UNEXPECT_ERROR'), timeout: 10000, type: 'warning' });
        }
        this.onCloseAction();
    }
    prepareCanvas = () => { window.canvas = { addNode: this.implementAdd, setNodePort: this.implementSetPort, curNode: -1, getNode: this.implementGetNode, setNodeData: this.implementSetNodeData, zoom: 1 }; }
    onPrevPage = () => {
        this.setState((prevState: ScenarioPageState) => {
            let dest_page = prevState.form_page - 1;
            if (dest_page < 0) dest_page = 0;
            return { form_page: dest_page };
        });
    }
    onNextPage = () => {
        this.setState((prevState: ScenarioPageState) => {
            const max_page = 1;
            let dest_page = prevState.form_page + 1;
            if (dest_page > max_page) dest_page = max_page;
            return { form_page: dest_page };
        });
    }
    onChangeActionType = (value: string) => {
        const { temp_action } = this.state;
        if (temp_action && CsxEventSystem.isCsxActionType(value)) {
            // const default_data = CsxUtil.nestedAssign(DEFAULT_DATA_TABLE[value]);
            const default_data = CsxUtil.nestedClone(this.DEFAULT_DATA_TABLE[value]);
            temp_action.TYPE = value;
            temp_action.setJsonParam(value, default_data); // initial param
            this.setState({ temp_action });
        }
    }
    onOpenAction = (temp_action?: CsxEventSystem.CsxActionNode) => {
        this.prepareCanvas();
        if (temp_action) { // edit exist action
            const clone = new CsxEventSystem.CsxActionNode(temp_action.toJson());
            this.setState({ temp_action: clone, form_page: 1 });
        } else { // create new action
            const cur_scenario = window.routeScenario.FOCUS_SCENARIO();
            if (cur_scenario)
                this.setState({ temp_action: cur_scenario.newAction() });
        }
    }
    onCloseAction = () => { this.setState({ temp_action: undefined, form_page: 0 }); }
    onDeleteScript = (action_id: string) => {
        window.userConfirm(getText('CONFIRM_REMOVE_SCRIPT_FROM_SCENARIO'), (ok) => {
            const cur_scenario = window.routeScenario.FOCUS_SCENARIO();
            if (ok && cur_scenario) {
                cur_scenario.removeAction(action_id);
                cur_scenario.ACTIVE = false;
                this.instance.SetScenario(cur_scenario);
            }
        })
    }
    onTestScript = (action_id: string) => {
        const cur_scenario = window.routeScenario.FOCUS_SCENARIO();
        // const cur_scenario = this.instance.Scenario(this.scenario_id);
        if (cur_scenario) {
            const action =  cur_scenario.getAction(action_id);
            if (action) {
                const action_object = {
                    id: action_id,
                    type: action.TYPE_NUMBER,
                    param: CsxEventSystem.CsxActionParameterToJson(action.PARAM),
                    coordinate: { x: 0, y: 0 },
                }
                this.instance.RunActionTest(action_object);
            }
            // cur_scenario.removeAction(action_id);
            // cur_scenario.ACTIVE = false;
            // this.instance.SetScenario(cur_scenario);
        }
    }
    // onOpenPanelConfiguration = () => { this.setState({ edit_panel: true }); }
    // onClosePanelConfiguration = () => { this.setState({ edit_panel: false }); }
    startOrder = () => { this.setState({ script_reorder: true }); }
    endOrder = () => { this.setState({ script_reorder: false, new_script_order: undefined }); }
    onAfterListChange = (new_action_list: Array<string>) => { this.setState({ new_script_order: new_action_list }); }
    onSaveReorder = () => {
        const { new_script_order } = this.state;
        const cur_scenario = window.routeScenario.FOCUS_SCENARIO();
        if (new_script_order && cur_scenario) {
            const new_scenario = this.instance.NewScenario();
            new_scenario.ID = cur_scenario.ID;
            new_scenario.NAME = cur_scenario.NAME;
            new_scenario.IMAGE_ID = cur_scenario.IMAGE_ID;
            new_script_order.forEach((action_id, idx) => {
                const action = cur_scenario.getAction(action_id);
                if (action) {
                    action.ID = idx.toString();
                    new_scenario.setAction(action);
                }
            });
            new_scenario.ACTIVE = false;
            this.instance.SetScenario(new_scenario);
            this.setState({ script_reorder: false });
        }
    }
    triggerImageSelector = () => {
        if (this.fileSelector) {
            this.fileSelector.click();
        } else {
            window.alert(getText('ALERT_OP_UNSUPPORT_NATIVE_OUT'));
        }
    }
    handleImport = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const json_file = (event.target.files) ? event.target.files[0] : undefined;
        if (json_file) {
            CsxUtil.importHandler({
                file: json_file,
                validateFilename: (fn) => (fn.split('.').slice(-2).join('.').toLowerCase() === 'scene.json'),
                userConfirm: getText('CONFIRM_IMPORT_SCENARIO'),
                onConfirm: (parseJson) => {
                    const cur_scenario = window.routeScenario.FOCUS_SCENARIO();
                    if (isCsxScenarioJson(parseJson)) {
                        if (cur_scenario) {
                            const new_scenario = new CsxEventSystem.CsxScenario(cur_scenario.ID, parseJson);
                            new_scenario.NAME = cur_scenario.NAME;
                            this.instance.SetScenario(new_scenario);
                        }
                    } else {
                        Notify({ type: 'error', title: getText('NOTIFY_TITLE_IMPORT_SCENARIO'), context: getText('NOTIFY_MSG_FILE_FORMAT_INVALID'), timeout: 10000 });
                    }
                }
            });
        }
        event.target.value = '';
    }
    render() {
        const { temp_action, form_page, script_reorder, new_script_order } = this.state;
        const cur_scenario = window.routeScenario.FOCUS_SCENARIO();
        const scenario_edit_permission = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getPermission('scenario_edit') : CsxUserPermissionLevel.NoAccess;
        // const cur_scenario = this.instance.Scenario(this.scenario_id);
        const cur_action_list = (cur_scenario) ? Array.from(cur_scenario.ACTION_SET) : [];
        const temp_action_type: CsxEventSystem.CsxActionType = (temp_action) ? temp_action.TYPE : 'NullAction';
        const action_select_form = (
            <div className='action_select_context'>
                <Form.Item label={getText('SCENARIO_CONF_SCRIPT_ACTION_TYPE_SELECT')}>
                    <Select value={temp_action_type} onChange={this.onChangeActionType} placeholder='Unknown Type'>
                        {CsxEventSystem.CsxActionTypeList.map((type, idx) => {
                            return (type !== 'NullAction') ? <Option key={`action_opt_${idx}`} value={type}>{this.ACTION_OPTION[type]}</Option> : null;
                        }).filter(opt => (opt !== null))}
                    </Select>
                </Form.Item>
                <Form.Item className='footer'>
                    <Button className='next_button' onClick={this.onNextPage} type='primary' disabled={temp_action_type === 'NullAction'}>{getText('SCENARIO_CONF_SCRIPT_NEXT_SET_ACTION')}</Button>
                </Form.Item>
            </div>
        );
        const action_configuration_form = (
            <div className='action_config_context'>
                <Form.Item className='float'>
                    <Button className='prev_button' icon='arrow-left' onClick={this.onPrevPage}>{getText('SCENARIO_CONF_SCRIPT_BACK')}</Button>
                </Form.Item>
                {this.ACTION_POP_COMPONENT[temp_action_type]}
            </div>
        );
        const page_list: Array<{ className: string; content: JSX.Element }> = [
            { className: 'action_select', content: action_select_form },
            { className: 'action_config', content: action_configuration_form },
        ];
        let action_modal_title = 'Create New Action';
        if (temp_action && cur_action_list.indexOf(temp_action.ID) >= 0)
            action_modal_title = 'Edit Action ' + temp_action.PARAM.name;
        return (
            <Flat.Playground hasExtendToggler contextStyle={{ height: 'auto' }}>
                <Flat.Section title={getText('SCENARIO_CONF_SCRIPT_LABEL')} defaultExtend>
                    {csxUserPermissionSatisfy(scenario_edit_permission, CsxUserPermissionLevel.EditAssigned) ? <Form.Item style={{ justifyContent: 'flex-end' }}>
                        {(script_reorder) ? <Button icon='cancel' shape='round' onClick={this.endOrder} /> : undefined}
                        {<Button icon={script_reorder?'save':'move'} onClick={(script_reorder) ? this.onSaveReorder : this.startOrder} disabled={script_reorder && !new_script_order}>{(script_reorder) ? getText('SAVE') : getText('ARRANGE')}</Button>}
                        {(script_reorder) ? undefined : <Button onClick={() => { this.onOpenAction(); }} icon='plus' type='primary' disabled={script_reorder}>{getText('ADD')}</Button>}
                        {!window.APP_ON_HDMI ? <input type='file' style={{ visibility: 'hidden', position: 'absolute' }} ref={ (inst) => { this.fileSelector = inst; } } onChange={this.handleImport}/> : undefined}
                    </Form.Item> : undefined}
                    <div className={(script_reorder) ? 'list_wrapper reordering' : 'list_wrapper'} style={{ width: '100%' }}>
                        {(cur_action_list.length > 0) ? <List draggable={script_reorder} items={cur_action_list.map(action_id => {
                            const action = (cur_scenario) ? cur_scenario.getAction(action_id) : undefined;
                            // const action_param_walk = (action) ? action.PARAM.split(',') : ['', ''];
                            const action_name = <span>{(action) ? action.PARAM.name : ''}</span>;
                            const action_deco = <div style={{ display: 'flex'}}>
                                {(csxUserPermissionSatisfy(scenario_edit_permission, CsxUserPermissionLevel.EditAssigned)) ? <Icon type='trashcan' color='#DA8175' onClick={() => { this.onDeleteScript(action_id); }} /> : undefined}
                                {(csxUserPermissionSatisfy(scenario_edit_permission, CsxUserPermissionLevel.EditAssigned)) ? <Icon type='write' style={{ marginRight: '3px' }} color='gray' onClick={() => { this.onOpenAction(action); }} /> : undefined}
                                <Icon type='solid-right' color='#888' onClick={() => { this.onTestScript(action_id); }} />
                            </div>;
                            return (cur_scenario) ? { label: action_name, decoration: ((script_reorder) ? undefined : action_deco), index: action_id } : { label: '' };
                        })} onAfterDrag={this.onAfterListChange} /> : <Empty text='No script'/>}
                    </div>
                </Flat.Section>
                <Flat.Section title={getText('SCENARIO_CONF_ADVANCED_LABEL')}>
                    <EditScenarioAutomationForm />
                </Flat.Section>
                <Modal
                    title={action_modal_title}
                    visible={(typeof temp_action !== 'undefined')}
                    onClose={this.onCloseAction}
                    className='edit_script_modal'
                >
                    <Book page={form_page}>
                        {page_list.map((page, idx) => <Page key={`form_page_${idx}`} className={page.className}>{page.content}</Page>)}
                    </Book>
                </Modal>
            </Flat.Playground>
        );
    }
}

declare type EditScenarioAutomationFormState = { data_changed: boolean; automation_select: string };

class EditScenarioAutomationForm extends CsxUI.IRQComponent<any> {
    state: EditScenarioAutomationFormState;
    instance: CsxFeature.CsxEventSystemDevice;
    constructor(props: any) {
        super(props);
        this.state = {
            data_changed: false,
            automation_select: '',
        };
        this.instance = new CsxFeature.CsxEventSystemDevice();
        this.IRQIndex = 'EventSystem';
    }
    get scenario() { return window.routeScenario.FOCUS_SCENARIO(); }
    onSelectAutomation = (value: string) => { this.setState({ automation_select: value }); }
    onTempAddAutomation = () => {
        const { automation_select } = this.state;
        const cur_scenario = this.scenario;
        
        if (cur_scenario) {
            cur_scenario.addAutomation(automation_select);
            this.setState({ data_changed: true });
        }
    }
    onTempRemoveAutomation = (automation_id: string) => {
        const cur_scenario = this.scenario;

        if (cur_scenario) {
            cur_scenario.removeAutomation(automation_id);
            this.setState({ data_changed: true });
        }
    }
    onSaveEditAutomation = () => {
        const cur_scenario = this.scenario;

        if (cur_scenario) {
            cur_scenario.ACTIVE = false;
            this.instance.SetScenario(cur_scenario);
            this.setState({ data_changed: false });
        }
    }
    render() {
        const { automation_select, data_changed } = this.state;
        const automation_set = this.instance.AutomationSet();
        const scenario_edit_permission = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getPermission('scenario_edit') : CsxUserPermissionLevel.NoAccess
        const cur_scenario = this.scenario;
        const temp_automation_list = (cur_scenario) ? Array.from(cur_scenario.AUTOMATION_SET) : [];
        return (
            <Form.Form>
                {csxUserPermissionSatisfy(scenario_edit_permission, CsxUserPermissionLevel.EditAssigned) ? <Form.Item className='footer' label={getText('SCENARIO_CONF_AUTOMATION_SELECT')}>
                    <Select value={automation_select} onChange={this.onSelectAutomation}>{
                        Array.from(automation_set).map(automation_id => {
                            const automation = this.instance.Automation(automation_id);
                            return (automation) ? <Option key={`edit_autogroup_aid_${automation_id}`} value={automation_id}>{automation.NAME}</Option> : undefined;
                        }).filter(option => !!option)
                    }</Select>
                    <Button icon='plus' type='primary' disabled={automation_select.length === 0} onClick={this.onTempAddAutomation}>{getText('ADD')}</Button>
                </Form.Item> : undefined}
                {(temp_automation_list.length > 0) ? <List items={temp_automation_list.map(automation_id => {
                    const automation = this.instance.Automation(automation_id);
                    const automation_name = <span>{(automation) ? automation.NAME : 'Unknown_Automation'}</span>;
                    const automation_deco = csxUserPermissionSatisfy(scenario_edit_permission, CsxUserPermissionLevel.EditAssigned) ? <div>
                        <Icon type='trashcan' color='#DA8175' onClick={() => { this.onTempRemoveAutomation(automation_id); }} />
                    </div> : undefined;
                    return { label: automation_name, decoration: automation_deco };
                })} /> : <Empty text='No automation'/>}
                {csxUserPermissionSatisfy(scenario_edit_permission, CsxUserPermissionLevel.EditAssigned) ? <Form.Item style={{ justifyContent: 'flex-end' }}>
                    <Button type='primary' disabled={!data_changed} onClick={this.onSaveEditAutomation}>{getText('SAVE')}</Button>
                </Form.Item> : undefined}
            </Form.Form>
        );
    }
}