import React from 'react';
import { Table, Href, Button, Modal, Form, Input, Notify, Icon, Select, Option, Tooltip, RadioGroup, Flat } from 'cypd';
import { withRouter } from 'react-router-dom';

import { CsxUI, CsxFeature, CsxUtil, CsxEventSystem, CsxDesc } from '../../../csx';
import { IconSelector } from '../../icon/page';
import { CsxUserPermissionLevel, csxUserPermissionSatisfy } from '../../../csx/manager';

type ScenarioEditModalType = 'add' | 'edit' | 'icon';

type ViewState = {
    popup?: ScenarioEditModalType;
    new_scenario_name: string;
    row_limit: number;
    page_index: number;
    keyword_filter: string;
    icon_select: Array<string>;
    create_mode: 'exist' | 'new';
    create_from: string;
}

const SCENARIO_TABLE_ROW_LIMITS_LOCAL_KEY = 'SCENARIO_TABLE_ROW_LIMITS_LOCAL_KEY';
const SCENARIO_TABLE_KEY_FILTER_LOCAL_KEY = 'SCENARIO_TABLE_KEY_FILTER_LOCAL_KEY';
const { getText } = CsxDesc;

export default class ScenarioOverview extends CsxUI.IRQComponent<any> {
    state: ViewState;
    importTmpScenario?: CsxEventSystem.CsxScenarioJson;
    instance: CsxFeature.CsxEventSystemDevice;
    scenarioFileSelector: HTMLInputElement | null | undefined;
    imageFileSelector: HTMLInputElement | null | undefined;
    fileFor = '';
    scenario_s = '';
    constructor(props: any) {
        super(props);
        const local_store_row_limit = window.localStorage.getItem(SCENARIO_TABLE_ROW_LIMITS_LOCAL_KEY);
        const local_store_key_filter = window.localStorage.getItem(SCENARIO_TABLE_KEY_FILTER_LOCAL_KEY);
        this.state = {
            new_scenario_name: '',
            row_limit: parseInt((local_store_row_limit) ? local_store_row_limit : '10'),
            page_index: 0,
            keyword_filter: (local_store_key_filter) ? local_store_key_filter : '',
            icon_select: [],
            create_from: '',
            create_mode: 'new',
        };
        this.IRQIndex = 'EventSystem';
        this.instance = new CsxFeature.CsxEventSystemDevice();
    }
    pop = (modal?: ScenarioEditModalType) => {
        const select_scenario = this.instance.Scenario(this.scenario_s);
        let default_name = '';
        if (modal === 'add') {
            if (this.instance.ScenarioSize() >= this.instance.ScenarioSizeLimit()) {
                Notify({ title: getText('NOTIFY_TITLE_CREATE_SCENARIO'), context: `${getText('NOTIFY_MSG_SCENARIO_SIZE_LIMIT')} ${this.instance.ScenarioSizeLimit()}.`, type: 'error', timeout: 10000 });
                return;
            }
            default_name = (this.importTmpScenario) ? this.importTmpScenario.name : '';
        } else if (modal === 'edit') {
            default_name = (select_scenario) ? select_scenario.NAME : '';
        } else if (!modal) {
            this.scenario_s = '';
            this.importTmpScenario = undefined;
        }
        this.setState({ popup: modal, new_scenario_name: default_name });
    }
    onChangeNewScenarioName = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ new_scenario_name: e.target.value }); }
    addScenario = async () => {
        let new_scenario = this.instance.NewScenario();

        if (this.importTmpScenario) {
            /* check referenced automation */
            await this.instance.ScenarioAutomationSnapshotProcess(this.importTmpScenario);
            new_scenario = new CsxEventSystem.CsxScenario('0', this.importTmpScenario);
        }
        new_scenario.NAME = this.state.new_scenario_name;
        new_scenario.ACTIVE = false;
        this.instance.CreateScenario(new_scenario);
        this.pop(undefined);
        this.setState({ page_index: -1 });
    }
    delScenario = (scenario_id: string) => {
        window.userConfirm(getText('CONFIRM_REMOVE_SCENARIO'), (ok: boolean) => {
            if (ok)
                this.instance.DeleteScenario([scenario_id]);
        });
    }

    triggerScenarioFileSelector = () => {
        if (this.scenarioFileSelector) {
            this.scenarioFileSelector.click();
        } else {
            window.alert(getText('ALERT_OP_UNSUPPORT_NATIVE_OUT'));
        }
    }
    scenarioUpdate = (event: React.ChangeEvent<HTMLInputElement>): void => {
        const notify_title = getText('NOTIFY_TITLE_IMPORT_SCENARIO');
        const json_file = (event.target.files) ? event.target.files[0] : undefined;
        if (json_file) {
            CsxUtil.importHandler({
                file: json_file,
                validateFilename: (fn) => (fn.split('.').slice(-2).join('.').toLowerCase() === 'scene.json'),
                onConfirm: (parseJson) => {
                    if (CsxEventSystem.isCsxScenarioJson(parseJson) || CsxEventSystem.Old_isCsxScenarioJson(parseJson)) {
                        if (!CsxEventSystem.isCsxScenarioJson(parseJson) && CsxEventSystem.Old_isCsxScenarioJson(parseJson))
                            Notify({ type: 'warning', title: notify_title, context: getText('NOTIFY_MSG_DEPRECATE_FORMAT'), timeout: 5000 });
                        // if (parseJson.imageSnapshot) {
                        //     this.instance.CheckIconNameExist(parseJson.imageSnapshot.name);
                        // }
                        this.importTmpScenario = parseJson;
                        this.pop('add');
                    } else {
                        Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_FILE_FORMAT_INVALID'), timeout: 10000 });
                    }
                },
                onError: (msg, timeout) => {
                    Notify({ type: 'error', title: notify_title, context: msg, timeout });
                }
            });
        }
        event.target.value = '';
    }
    onSaveScenarioName = (scenario_id: string) => {
        const { new_scenario_name } = this.state;
        const scenario = this.instance.Scenario(scenario_id);
        if (scenario) {
            scenario.NAME = new_scenario_name;
            this.instance.SetScenario(scenario);
            this.pop(undefined);
            this.setState({ new_scenario_name: '' });
        }
    }
    onClickEditName = (scenario_id: string) => {
        this.scenario_s = scenario_id;
        this.pop('edit');
    }
    onDownloadScenario = async (scenario_id: string) => {
        const scenario = this.instance.Scenario(scenario_id);
        if (scenario) {
            const scenario_json = scenario.toJson();
            // await scenario.loadJsonImage(scenario_json);
            scenario_json.automationSnapshot = scenario.takeAutomationSnapshot();
            scenario_json.cypDeviceSnapshot = scenario.takeDeviceSnapshot();
            CsxUtil.downloadAsJson(scenario.FILENAME, JSON.stringify(scenario_json, null, 4));
        } else {
            Notify({ type: 'error', title: getText('NOTIFY_TITLE_FAIL'), context: getText('NOTIFY_MSG_CONFIG_NOT_FOUND') });
        }
    }
    onChangeCreateMode = (value: string) => { this.importTmpScenario = undefined; this.setState({ create_mode: value, create_from: '' }); }
    onChangeCreateFrom = (value: string) => {
        const { create_mode } = this.state;
        const scenario = this.instance.Scenario(value);
        if (scenario && create_mode === 'exist') {
            this.importTmpScenario = scenario.toJson();
            this.setState({ create_from: value, new_scenario_name: scenario.NAME });
        }
    }
    onChangeRowLimit = (value: string) => { this.setState({ row_limit: parseInt(value) }); window.localStorage.setItem(SCENARIO_TABLE_ROW_LIMITS_LOCAL_KEY, value); }
    onChangePage = (value: number) => { this.setState({ page_index: value }); }
    onChangeKeywordFilter = (e: React.ChangeEvent<HTMLInputElement>) => { this.setState({ keyword_filter: e.target.value }); window.localStorage.setItem(SCENARIO_TABLE_KEY_FILTER_LOCAL_KEY, e.target.value); }
    onOpenIconSelector = (scenario_id: string) => {
        this.scenario_s = scenario_id;
        const scenario = this.instance.Scenario(scenario_id);
        if (scenario) {
            const image_id = scenario.IMAGE_ID;
            this.setState({ icon_select: (image_id ? [image_id] : [`Scenario_${scenario.ID}`]) });
        }
        this.pop('icon');
    }
    onChangeSelectIcon = (icons: Array<string>) => { this.setState({ icon_select: icons }); }
    onSaveScenarioIcon = () => {
        const { icon_select } = this.state;
        const scenario_select = this.instance.Scenario(this.scenario_s);
        if (scenario_select) {
            const new_scenario = scenario_select.clone();
            new_scenario.IMAGE_ID = (icon_select.length > 0) ? icon_select[0] : undefined;
            this.instance.SetScenario(new_scenario);
        }
        this.pop(undefined);
    }
    render() {
        const { popup, new_scenario_name, row_limit, page_index, keyword_filter, icon_select, create_mode, create_from } = this.state;
        const scenario_arr = Array.from(this.instance.ScenarioSet());
        const scenario_edit_permission = (window.CSX_CUR_AUTH) ? window.CSX_CUR_AUTH.getPermission('scenario_edit') : CsxUserPermissionLevel.NoAccess;
        let name_valid = true;
        let name_error = '';

        const modal_title: { [s in ScenarioEditModalType]: string } = {
            add: getText('NOTIFY_TITLE_CREATE_SCENARIO'),
            edit: getText('SCENARIO_TITLE_EDIT_SCENARIO'),
            icon: getText('SCENARIO_TITLE_SELECT_ICON'),
        }
        const span_style: React.CSSProperties = {
            position: 'relative',
            // marginBottom: '10px',
            whiteSpace: 'nowrap',
            color: '#707070',
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'stretch',
            alignItems: 'center',
            height: '100%',
            flexGrow: 2,
            maxWidth: `calc(50% - 10px)`,
        };
        const row_limit_opt = [10, 20, 40];
        /* Modal Definition */
        if (new_scenario_name.length === 0 ||
            CsxUtil.hasChinese(new_scenario_name) ||
            this.instance.CheckScenarioNameExist(new_scenario_name)) {
            name_valid = false;
            name_error = getText('HINT_DUPLICATE_NAME');
        }
        const modal_render: { [type in ScenarioEditModalType]: React.ReactElement } = {
            add: (
                <Form.Form>
                    <Form.Item>
                        <RadioGroup 
                            options={[
                                { label: getText('SCENARIO_OVERVIEW_CREATE_NEW'), value: 'new' },
                                { label: getText('SCENARIO_OVERVIEW_CREATE_FROM_EXIST'), value: 'exist' },
                            ]} 
                            value={create_mode}
                            onChange={this.onChangeCreateMode}
                        />
                    </Form.Item>
                    <Form.Item label={getText('FROM')} style={CsxUI.getHiddenStyle(create_mode === 'exist')}>
                        <Select value={create_from} placeholder="Existing scenario" onChange={this.onChangeCreateFrom}>
                            {scenario_arr.map(scenario_id => {
                                const scenario = this.instance.Scenario(scenario_id);
                                if (scenario) {
                                    return (
                                        <Option key={`create_from_exist_${scenario_id}`} value={scenario_id}>{scenario.NAME}</Option>
                                    );
                                } else {
                                    return null;
                                }
                            }).filter(node => !!node)}
                        </Select>
                    </Form.Item>
                    <Form.Item error={name_error}>
                        <Input
                            placeholder='Scenario Name'
                            className='automation_add_input'
                            value={new_scenario_name}
                            onChange={this.onChangeNewScenarioName}
                        />
                        <Button type='primary' onClick={this.addScenario} disabled={!name_valid}>{getText('ADD')}</Button>
                    </Form.Item>
                    {(this.importTmpScenario) ? <Form.Item style={{ wordBreak: 'break-word' }}>{`Contains ${this.importTmpScenario.actions.length} actions and ${this.importTmpScenario.automations.length} automations in this configuration`}</Form.Item> : undefined}
                </Form.Form>
            ),
            edit: (
                <Form.Form>
                    <Form.Item error={name_error}>
                        <Input
                            placeholder='Scenario Name'
                            className='automation_add_input'
                            value={new_scenario_name}
                            onChange={this.onChangeNewScenarioName}
                        />
                        <Button type='primary' onClick={() => { this.onSaveScenarioName(this.scenario_s); }} disabled={!name_valid}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
            ),
            icon: (
                <Form.Form labelStyle={{ width: '100px' }}>
                    <IconSelector
                        defaultSelected={icon_select}
                        selectType='single'
                        framesInRow={3}
                        width={340}
                        height={300}
                        onChange={this.onChangeSelectIcon}
                    />
                    <Form.Item style={{ justifyContent: 'flex-end' }}>
                        <Button type='primary' onClick={this.onSaveScenarioIcon}>{getText('SAVE')}</Button>
                    </Form.Item>
                </Form.Form>
            )
        };
        return (
            <Flat.Section title={getText('SCENARIO_OVERVIEW_ALL')} disableCollapsed style={{ width: 'calc(100% - 100px)', maxWidth: 'none' }} titleStyle={{ backgroundColor: 'white', color: '#707070', fontSize: '18px', padding: '10px' }}>
                {csxUserPermissionSatisfy(scenario_edit_permission, CsxUserPermissionLevel.FullAccess) ? <Form.Item style={{ justifyContent: 'flex-end', maxWidth: '600px', flexShrink: 0 }}>
                    <Button icon='upload' onClick={this.triggerScenarioFileSelector}>{getText('UPLOAD')}</Button>
                    <Button style={{ marginRight: '0' }} onClick={() => { this.pop('add') }} icon='plus' type='primary'>{getText('ADD')}</Button>
                </Form.Item> : undefined}
                <div style={{ position: 'relative', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%', maxWidth: '600px', marginBottom: '10px' }}>
                    <span style={span_style}>
                        Show items
                        <Select value={row_limit} size='small' style={{ margin: '0 5px' }} onChange={this.onChangeRowLimit}>{row_limit_opt.map(value => <Option key={`row_limit_${value}`} value={value}>{value}</Option>)}</Select>
                    </span>
                    <span style={span_style}>
                        Search
                        <Input value={keyword_filter} placeholder='Name' size='small' onChange={this.onChangeKeywordFilter} style={{ flexGrow: 2, marginLeft: '5px' }}/>
                    </span>
                </div>
                {!window.APP_ON_HDMI ? <input type='file' style={{ display: 'none' }} ref={inst => { this.scenarioFileSelector = inst; }} onChange={this.scenarioUpdate} accept='.json' /> : undefined}
                {/* {!window.APP_ON_HDMI ? <input type='file' style={{ display: 'none' }} ref={inst => { this.imageFileSelector = inst; }} onChange={this.imageUpdate} accept='.jpg,.jpeg' /> : undefined} */}
                <Table
                    headers={["ID", getText('SCENARIO_OVERVIEW_ICON'), getText('NAME'), getText('SCENARIO_OVERVIEW_OPERATION')]}
                    columnWidth={[60, 60, NaN, 120]}
                    rows={scenario_arr.map(scenario_id => {
                        const scenario = this.instance.Scenario(scenario_id);
                        const has_automation = (scenario) ? (scenario.AUTOMATION_CNT > 0) : false;
                        const icon_url = (scenario) ? scenario.getImageUrl() : '';
                        const Linker = withRouter(({ history }) => (
                            <Href to={() => { history.push(`/management/es/scenario/?scenario_id=${scenario_id}`); }}>{(scenario ? (`${scenario.NAME}${has_automation ? '*' : ''}`) : '')}</Href>
                        ));
                        if (scenario) {
                            return (keyword_filter.length > 0 && scenario.NAME.toLowerCase().indexOf(keyword_filter.toLowerCase()) < 0) ? [] : [
                                scenario_id,
                                <div
                                    style={{ position: 'relative', width: '30px', height: '30px', overflow: 'hidden', border: '1px solid transparent', borderRadius: '50%', cursor: 'pointer' }}
                                    onClick={csxUserPermissionSatisfy(scenario_edit_permission, CsxUserPermissionLevel.EditAssigned) ? () => { this.onOpenIconSelector(scenario_id); } : undefined}
                                >
                                    <CsxUI.FallbackImage src={icon_url} width='100%' height='100%' />
                                </div>,
                                <Linker />,
                                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    {(csxUserPermissionSatisfy(scenario_edit_permission, CsxUserPermissionLevel.EditAssigned)) ? <Tooltip text={getText('TOOLTIP_EDIT_NAME')}><Icon style={{ marginRight: '5px' }} type='write' color='rgb(0,138,158)' onClick={() => { this.onClickEditName(scenario_id); }} /></Tooltip> : undefined}
                                    <Tooltip text={getText('TOOLTIP_EXPORT_SCENARIO')}><Icon style={{ marginRight: '3px' }} color='rgb(0,138,158)' type='download' onClick={() => { this.onDownloadScenario(scenario_id); }} /></Tooltip>
                                    {(csxUserPermissionSatisfy(scenario_edit_permission, CsxUserPermissionLevel.EditAssigned)) ? <Tooltip text={getText('TOOLTIP_EXPORT_CHANGE_ICON')}><Icon style={{ marginRight: '3px', marginTop: '5px' }} color='rgb(0,138,158)' type='scene' onClick={() => { this.onOpenIconSelector(scenario_id); }} /></Tooltip> : undefined}
                                    {(csxUserPermissionSatisfy(scenario_edit_permission, CsxUserPermissionLevel.FullAccess)) ? <Tooltip text={getText('TOOLTIP_DELETE_SCENARIO')}><Icon type='trashcan' color='#d12b60' onClick={() => { this.delScenario(scenario_id); }} /></Tooltip> : undefined}
                                </div>
                            ];
                        } else {
                            return [];
                        }
                    }).filter(row => (row.length > 0))}
                    bodyStyle={{ width: '600px' }}
                    responsive='shorten'
                    shortenProps={{
                        layout: {
                            topLeft: -1,
                            bottomLeft: 0,
                            topRight: [],
                            bottomRight: [2],
                        }
                    }}
                    pagination={true}
                    rowLimit={row_limit}
                    page={page_index}
                    onPageChange={this.onChangePage}
                ></Table>
                <Modal
                    title={(popup) ? modal_title[popup] : ''}
                    visible={typeof popup !== 'undefined'}
                    onClose={() => { this.pop(undefined); }}
                >
                    {(popup) ? modal_render[popup] : undefined}
                </Modal>
            </Flat.Section>
        );
    }
}
