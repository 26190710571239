import CsxWs from './ws';
import * as CsxUtil from './util';
import * as CsxEventSystem from './event';
import { Notify } from 'cypd';
import { getText } from './desc';
import { CsxDesc } from '.';
const Database: any = null;
// try { Database = require('./db'); } catch (err) {}
export const CSX_NAME = 'Universal Device Controller';
export const DEV_TARGET_IP = '192.168.5.128';
const render_field_type = CsxUtil.stringLitArray([
    'NoUpdate',
    'EventSystem', 'Dashboard', 'DeviceSystemStatus', 'AuthenticationUpdate',
    'DeviceRealTime', 'GatewayRealTime',
    'MessageRealTime', 'GatewayMessageRealTime',
]);
const deprecated_user_function_type = CsxUtil.stringLitArray([
    'dashboard_view', 'dashboard_batch_update', 'dashboard_device_management',
    'device_view', 'device_control',
    'user_management_view', 'user_edit',
    'event_system_view', 'scenario_edit', 'automation_edit', 'room_edit', 'icon_edit',
    'gateway_view', 'gateway_control',
]);
const user_function_type = CsxUtil.stringLitArray([
    'cs9_factory_test', 'disable_signout_room_control'
]);
const user_attr_type = CsxUtil.stringLitArray([
    'username', 'password', 'assigned_room'
]);
const user_permission_type = CsxUtil.stringLitArray([
    'dashboard_permission', 'device_permission', 'routing_control', 'audio_control', 'osd_control',
    'scaler_control', 'videoWall_control', 'automation_control', 'streaming_control',
    'record_control', 'cec_control', 'edid_control', 'hdcp_control', 'general_control',
    'io_control', 'pm_control', 'fwup_control', 'ct_control', 'debug_control',
    'time_control', 'dante_control', 'room_view', 'scenario_view', 'automation_view', 'icon_view',
    'room_edit', 'scenario_edit', 'automation_edit', 'icon_edit', 'system_permission',
    'unit_fwup', 'user_view', 'user_edit', 'assign_room_permission',
]);
const icon_import_option_type_array = CsxUtil.stringLitArray([ 'auto-rename', 'overwrite-same-name', 'skip-same-name', ]);

export const USER_FUNCTION_LIST = user_function_type.slice();
export const USER_ATTRIBUTE_LIST = user_attr_type.slice();
export const USER_PERMISSION_LIST = user_permission_type.slice();
export const ICON_IMPORT_OPTION_TYPE_LIST = icon_import_option_type_array.slice();

export enum CsxUserPermissionLevel {
    NoAccess = 'no-access',
    ViewAssigned = 'view-assigned',
    ViewOnly = 'view-only',
    EditAssigned = 'edit-assigned',
    EditOnly = 'edit-only',
    FullAccess = 'full-access',
}
export function isCsxUserPermissionLevel(x: string): x is CsxUserPermissionLevel {
    const set: Set<string> = new Set(Object.values(CsxUserPermissionLevel));
    return set.has(x);
}

export function csxUserPermissionSatisfy(a: CsxUserPermissionLevel, b: CsxUserPermissionLevel): boolean {
    const priority = Object.values(CsxUserPermissionLevel);
    return priority.indexOf(a) >= priority.indexOf(b);
}

export declare type RenderFieldType = (typeof render_field_type)[number]
export const isRenderFieldType = (x: any): x is RenderFieldType => render_field_type.includes(x);
export declare type DeprecatedUserFunctionType = (typeof deprecated_user_function_type)[number]
export const isDeprecatedUserFunctionType = (x: any): x is DeprecatedUserFunctionType => deprecated_user_function_type.includes(x);
export declare type UserFunctionType = (typeof user_function_type)[number]
export const isUserFunctionType = (x: any): x is UserFunctionType => user_function_type.includes(x);
export declare type UserAttributeType = (typeof user_attr_type)[number]
export const isUserAttributeType = (x: any): x is UserAttributeType => user_attr_type.includes(x);
export declare type UserPermissionType = (typeof user_permission_type)[number]
export const isUserPermissionType = (x: any): x is UserPermissionType => user_permission_type.includes(x);
export declare type IconImportOptionType = (typeof icon_import_option_type_array)[number]
export const isIconImportOptionType = (x: any): x is IconImportOptionType => icon_import_option_type_array.includes(x);

declare global {
    interface Window {
        IDLE_STA: boolean;
        CSX_MANAGER_STA: string;
        APP_SRC: CsxUtil.APP_SRC_TYPE;
        APP_LANGUAGE: string;
        APP_LANGUAGE_METADATA: Array<CsxDesc.LanguageMetadata>;
        APP_DEV_MODE?: boolean;
        APP_CLOUD_MODE?: boolean;
        FW_UPDATE_HANDLER: { [s: string]: Function };
        REGISTER_HANDLER: { [s: string]: Function };
        HEALTH_HANDLER: { [s: string]: { input?: (data: CsxUtil.HDBTAnalyze) => void; output?: (data: CsxUtil.HDBTAnalyze) => void } };
        CSX_MANAGER: CsxManager;
        CSX_CUR_USER?: CsxUserProfile;
        CSX_CUR_AUTH?: CsxAuthorization;
        FOCUS_GATEWAY?: CsxGateway;
        FOCUS_DEVICE?: CsxDevice;
        FOCUS_SCENARIO_ID?: string;
        RENDER_LIST: { [s in RenderFieldType]: { [s: string]: ((...args: Array<any>) => void) } };
        APP_CORE_INDEX?: string; // AES KEY
        APP_DEBUG_MESSAGING: boolean;
        APP_ON_HDMI: boolean;
        HOME_CONFIGURATION?: CsxEventSystem.CsxRoom;
    }
}
window.RENDER_LIST = { EventSystem: {}, Dashboard: {}, NoUpdate: {}, DeviceRealTime: {}, GatewayRealTime: {}, MessageRealTime: {}, GatewayMessageRealTime: {}, DeviceSystemStatus: {}, AuthenticationUpdate: {} };

export declare type DiscoveryInformation = {
    ProductID: string;
    VendorID: string;
    ProductName: string;
    MacAddress: string;
    SerialNumber: string;
    FirmwareVersion: string;
    ProductDescription: string;
    IPAddress: string;
    SubnetMask: string;
    Gateway: string;
    DNSIPAddress: string;
    IPAddressSource: string;
    WebServerPortNumber: string;
    TelnetPortNumber: string;
    HardwareVersion: string;
    ProtocolRevisionCode: string;
    DeviceSync: string;
}

function parseDiscoveryInfo(info: string): DiscoveryInformation {
    const info_walk = info.split('\r');
    const d_info: DiscoveryInformation = {
        ProductID: '',
        VendorID: '',
        ProductName: '',
        MacAddress: '',
        SerialNumber: '',
        FirmwareVersion: '',
        ProductDescription: '',
        IPAddress: '',
        SubnetMask: '',
        Gateway: '',
        DNSIPAddress: '',
        IPAddressSource: '',
        WebServerPortNumber: '',
        TelnetPortNumber: '',
        HardwareVersion: '',
        ProtocolRevisionCode: '',
        DeviceSync: '',
    };
    info_walk.forEach(line => {
        const line_walk = line.split('=');
        if (line_walk[0] === 'Product ID')
            d_info.ProductID = line_walk[1];
        if (line_walk[0] === 'MAC address')
            d_info.MacAddress = line_walk[1];
        if (line_walk[0] === 'IP address')
            d_info.IPAddress = line_walk[1];
        if (line_walk[0] === 'Subnet mask')
            d_info.SubnetMask = line_walk[1];
        if (line_walk[0] === 'Gateway')
            d_info.Gateway = line_walk[1];
        if (line_walk[0] === 'DNS IP address')
            d_info.DNSIPAddress = line_walk[1];
        if (line_walk[0] === 'IP address source')
            d_info.IPAddressSource = line_walk[1];
        if (line_walk[0] === 'Web server TCP port number')
            d_info.WebServerPortNumber = line_walk[1];
        if (line_walk[0] === 'Telnet TCP port number')
            d_info.TelnetPortNumber = line_walk[1];
        if (line_walk[0] === 'Serial number')
            d_info.SerialNumber = line_walk[1];
        if (line_walk[0] === 'Firmware version')
            d_info.FirmwareVersion = line_walk[1];
        if (line_walk[0] === 'Hardware version')
            d_info.HardwareVersion = line_walk[1];
        if (line_walk[0] === 'Product name')
            d_info.ProductName = line_walk[1];
        if (line_walk[0] === 'Product descriptions')
            d_info.ProductDescription = line_walk[1];
        if (line_walk[0] === 'Vendor ID')
            d_info.VendorID = line_walk[1];
        if (line_walk[0] === 'Protocol revision code')
            d_info.ProtocolRevisionCode = line_walk[1];
        if (line_walk[0] === 'Device Sync')
            d_info.DeviceSync = line_walk[1];
    });
    return d_info;
}

function httpUploadFile(args: {
    fd: FormData;
    url: string;
    authorized: boolean;
    filenameValid: boolean;
    onSuccess?: () => void;
    onServerError?: (msg: string) => void;
    onNoResponse?: () => void;
    onNetworkError?: (error: any) => void;
    onFilenameError?: () => void;
    onNoAuthorized?: () => void;
}) {
    return new Promise(async (resolve, reject) => {
        if (window.APP_ON_HDMI) {
            Notify({ title: getText('NOTIFY_TITLE_FAIL'), context: getText('ALERT_OP_UNSUPPORT_NATIVE_OUT'), type: 'error' });
            resolve(0);
            return;
        }
        if (args.authorized) {
            if (args.filenameValid) {
                try {
                    const dest = (window.APP_DEV_MODE) ? `http://${DEV_TARGET_IP}${args.url}` : `${window.location.origin}${args.url}`;
                    const res = await window.fetch(dest, { method: 'POST', body: args.fd, mode: ((window.APP_DEV_MODE) ? 'no-cors' : 'cors') });
                    if (res.ok) {
                        const json = await res.json();
                        if (json.status === 'ok') {
                            if (args.onSuccess) { args.onSuccess(); }
                            resolve(200);
                        } else { if (args.onServerError) { args.onServerError(`${json.status}`); } reject(500); }
                    } else { if (args.onNoResponse) { args.onNoResponse(); } reject(300); }
                } catch (error) { if (args.onNetworkError) { args.onNetworkError(error); } reject(404); }
            } else { if (args.onFilenameError) { args.onFilenameError();} reject(0); }
        } else { if (args.onNoAuthorized) { args.onNoAuthorized();} reject(0); }
    });
}

export const irqRefresh = (fields: Array<RenderFieldType>, ...args: Array<any>): void => {
    fields.forEach(field => {
        const renderList = window.RENDER_LIST[field];
        if (field === 'NoUpdate') return;
        else if (field === 'MessageRealTime' || field === 'GatewayMessageRealTime') for (const id in renderList) { if (Object.prototype.hasOwnProperty.call(renderList, id)) renderList[id](args[0], args[1]); }
        else for (const id in renderList) { if (Object.prototype.hasOwnProperty.call(renderList, id)) renderList[id](); }
    });
}

export class CsxAuthorization {
    private _assigned_room: Set<string> | 'all' = 'all';
    private _assigned_scenario: Set<string> | 'all' = new Set(); // be generated from _assigned_room
    private _assigned_automation: Set<string> | 'all' = new Set(); // be generated from _assigned_room
    private _assigned_icon: Set<string> | 'all' = new Set(); // be generated from _assigned_room
    private _assigned_device: Set<string> | 'all' = new Set(); // be generated from _assigned_room
    private _support: { [fn in UserFunctionType]?: string }; // define user's support function
    private _attr: { [fn in UserAttributeType]?: string }; // define user's attributes
    private _permission: { [fn in UserPermissionType]?: CsxUserPermissionLevel };
    readonly set = (func_id: UserFunctionType, value: string): void => { this._support[func_id] = value; };
    readonly get = (func_id: UserFunctionType): string | undefined => { return this._support[func_id]; };
    readonly loadDocument = (document: string) => {
        const doc_walk = document.split('\r');
        let hasDeprecatedUserFunctionType = false;
        let noRecord_AssignedRoom = true;
        for (let i = 0; i < doc_walk.length; i++) {
            const line = doc_walk[i];
            const [func_id, value] = line.split('=');
            if (isDeprecatedUserFunctionType(func_id)) {
                hasDeprecatedUserFunctionType = true;
                /* Mapping old user authorization to modern format */
                if (func_id === 'dashboard_device_management' || func_id === 'dashboard_batch_update') {
                    if (value === 'y')
                        this.setPermission('dashboard_permission', CsxUserPermissionLevel.FullAccess);
                } else if (func_id === 'dashboard_view') {
                    if (value === 'y' && this.getPermission('dashboard_permission') === CsxUserPermissionLevel.NoAccess)
                        this.setPermission('dashboard_permission', CsxUserPermissionLevel.ViewOnly);
                } else if (func_id === 'event_system_view') {
                    if (value === 'y') {
                        if (this.getPermission('room_view') === CsxUserPermissionLevel.NoAccess)
                            this.setPermission('room_view', CsxUserPermissionLevel.FullAccess);
                        if (this.getPermission('scenario_view') === CsxUserPermissionLevel.NoAccess)
                            this.setPermission('scenario_view', CsxUserPermissionLevel.FullAccess);
                        if (this.getPermission('automation_view') === CsxUserPermissionLevel.NoAccess)
                            this.setPermission('automation_view', CsxUserPermissionLevel.FullAccess);
                        /* We didn't limit the access of icon view before, so we map to full access now */
                        this.setPermission('icon_view', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('icon_edit', CsxUserPermissionLevel.FullAccess);
                    }
                } else if (func_id === 'room_edit') {
                    if (value === 'y')
                        this.setPermission('room_edit', CsxUserPermissionLevel.FullAccess);
                } else if (func_id === 'scenario_edit') {
                    if (value === 'y')
                        this.setPermission('scenario_edit', CsxUserPermissionLevel.FullAccess);
                } else if (func_id === 'automation_edit') {
                    if (value === 'y')
                        this.setPermission('automation_edit', CsxUserPermissionLevel.FullAccess);
                } else if (func_id === 'device_view') {
                    if (value === 'y' && this.getPermission('device_permission') === CsxUserPermissionLevel.NoAccess)
                        this.setPermission('device_permission', CsxUserPermissionLevel.FullAccess);
                } else if (func_id === 'device_control') {
                    if (value === 'y') {
                        this.setPermission('routing_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('audio_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('osd_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('scaler_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('videoWall_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('automation_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('streaming_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('record_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('cec_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('edid_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('hdcp_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('general_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('io_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('pm_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('fwup_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('ct_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('debug_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('dante_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('time_control', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('device_permission', CsxUserPermissionLevel.FullAccess);
                    }
                } else if (func_id === 'gateway_view') {
                    if (value === 'y' && this.getPermission('system_permission') === CsxUserPermissionLevel.NoAccess)
                        this.setPermission('system_permission', CsxUserPermissionLevel.ViewOnly);
                } else if (func_id === 'gateway_control') {
                    if (value === 'y') {
                        this.setPermission('system_permission', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('unit_fwup', CsxUserPermissionLevel.FullAccess);
                    }
                } else if (func_id === 'user_management_view') {
                    if (value === 'y' && this.getPermission('user_view') === CsxUserPermissionLevel.NoAccess)
                        this.setPermission('user_view', CsxUserPermissionLevel.ViewOnly);
                } else if (func_id === 'user_edit') {
                    if (value === 'y') {
                        this.setPermission('user_edit', CsxUserPermissionLevel.FullAccess);
                        this.setPermission('user_view', CsxUserPermissionLevel.FullAccess);
                    }
                }
            }
            if (isUserFunctionType(func_id)) {
                this.set(func_id, value);
            }
            if (isUserAttributeType(func_id)) {
                this.setAttribute(func_id, value);
                if (func_id === 'assigned_room') {
                    noRecord_AssignedRoom = false;
                    if (value.toLowerCase() === 'all') {
                        this._assigned_room = 'all';
                    } else {
                        this._assigned_room = new Set((value.length > 0) ? value.split(',') : undefined);
                    }
                }
            }
            if (isUserPermissionType(func_id)) {
                if (isCsxUserPermissionLevel(value)) {
                    this.setPermission(func_id, value);
                }
            }
        }

        /* Compatible with old formats */
        if (hasDeprecatedUserFunctionType && noRecord_AssignedRoom) {
            this.setPermission('assign_room_permission', CsxUserPermissionLevel.FullAccess);
            this.setAttribute('assigned_room', 'all');
        }
    }
    readonly clone = (): CsxAuthorization => { const new_auth = new CsxAuthorization(); new_auth.loadDocument(this.toLines()); return new_auth; }
    readonly toLines = (): string => {
        const func_lines = this.KEYS.map(func_id => `${func_id}=${this.get(func_id)}`).join('\r');
        const attr_lines = this.ATTRS.map(func_id => `${func_id}=${this.getAttribute(func_id)}`).join('\r');
        const pms_lines = this.PERMISSIONS.map(func_id => `${func_id}=${this.getPermission(func_id)}`).join('\r');
        return func_lines + '\r' + attr_lines + '\r' + pms_lines;
    }
    readonly setDefaultUser = () => {
        this._permission = {};
        this.setPermission('dashboard_permission', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('device_permission', CsxUserPermissionLevel.ViewAssigned);
        this.setPermission('routing_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('audio_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('osd_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('scaler_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('videoWall_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('automation_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('streaming_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('record_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('dante_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('cec_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('edid_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('hdcp_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('general_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('io_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('pm_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('fwup_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('ct_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('debug_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('time_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('room_view', CsxUserPermissionLevel.ViewAssigned);
    }
    readonly setDefaultPowerUser = () => {
        this.setDefaultAdmin();
        this.setPermission('dashboard_permission', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('device_permission', CsxUserPermissionLevel.ViewAssigned);
        this.setPermission('fwup_control', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('room_view', CsxUserPermissionLevel.ViewAssigned);
        this.setPermission('room_edit', CsxUserPermissionLevel.EditOnly);
        this.setPermission('scenario_view', CsxUserPermissionLevel.ViewAssigned);
        this.setPermission('scenario_edit', CsxUserPermissionLevel.EditOnly);
        this.setPermission('automation_view', CsxUserPermissionLevel.ViewAssigned);
        this.setPermission('automation_edit', CsxUserPermissionLevel.EditOnly);
        this.setPermission('icon_view', CsxUserPermissionLevel.ViewAssigned);
        this.setPermission('icon_edit', CsxUserPermissionLevel.EditOnly);
        this.setPermission('system_permission', CsxUserPermissionLevel.ViewOnly);
        this.setPermission('unit_fwup', CsxUserPermissionLevel.NoAccess);
        this.setPermission('user_view', CsxUserPermissionLevel.NoAccess);
        this.setPermission('user_edit', CsxUserPermissionLevel.NoAccess);
        this.setPermission('assign_room_permission', CsxUserPermissionLevel.NoAccess);
    }
    readonly setDefaultAdmin = () => {
        this._permission = {};
        USER_PERMISSION_LIST.forEach(pms_id => {
            this.setPermission(pms_id, CsxUserPermissionLevel.FullAccess);
        });
    }
    private get ALLROOMS(): Array<string> { return (window.FOCUS_GATEWAY) ? Array.from(window.FOCUS_GATEWAY.ROOMS) : []; }
    get KEYS(): Array<UserFunctionType> {
        const buf: Array<UserFunctionType> = [];
        Object.keys(this._support).forEach(func_id => { if (isUserFunctionType(func_id)) buf.push(func_id); });
        return buf;
    }
    get ATTRS(): Array<UserAttributeType> {
        const buf: Array<UserAttributeType> = [];
        Object.keys(this._attr).forEach(attr_id => { if (isUserAttributeType(attr_id)) buf.push(attr_id); });
        return buf;
    }
    get PERMISSIONS(): Array<UserPermissionType> {
        const buf: Array<UserPermissionType> = [];
        Object.keys(this._permission).forEach(pms_id => { if (isUserPermissionType(pms_id)) buf.push(pms_id); });
        return buf;
    }
    readonly support = (func_id: UserFunctionType) => { return (this.get(func_id) === 'y'); }
    readonly getAttribute = (attr_id: UserAttributeType) => { const val = this._attr[attr_id]; return (val) ? val.slice() : ''; }
    readonly setAttribute = (attr_id: UserAttributeType, val: string) => {
        this._attr[attr_id] = val.slice();
        if (attr_id === 'assigned_room') {
            if (val.toLowerCase() === 'all') {
                this._assigned_room = 'all';
            } else {
                this._assigned_room = new Set(val.split(','));
            }
        }
    }
    readonly getPermission = (pms_id: UserPermissionType) => { const level = this._permission[pms_id]; return level ? level : CsxUserPermissionLevel.NoAccess; }
    readonly setPermission = (pms_id: UserPermissionType, val: CsxUserPermissionLevel) => { const level = val.slice(); if (isCsxUserPermissionLevel(level)) this._permission[pms_id] = level; }
    readonly getAssignedRoom = () => {
        let rooms: Set<string> = new Set();
        const assigned_room = this.getAttribute('assigned_room');
        if (assigned_room === 'all') {
            rooms = new Set(this.ALLROOMS);
        } else {
            const assigned_room_walk = assigned_room.split(',');
            for (let i = 0; i < assigned_room_walk.length; i++) {
                rooms.add(assigned_room_walk[i]);
            }
        }
        return rooms;
    }
    readonly isRoomAssigned = (room_id: string) => { return (this._assigned_room === 'all') ? true : this._assigned_room.has(room_id); }
    readonly isScenarioAssigned = (scenario_id: string) => { return (this._assigned_scenario === 'all') ? true : this._assigned_scenario.has(scenario_id); }
    readonly isAutomationAssigned = (automation_id: string) => { return (this._assigned_automation === 'all') ? true : this._assigned_automation.has(automation_id); }
    readonly isIconAssigned = (icon_id: string) => { return (this._assigned_icon === 'all') ? true : this._assigned_icon.has(icon_id); }
    readonly isDeviceAssigned = (device_id: string) => { return (this._assigned_device === 'all') ? true : this._assigned_device.has(device_id); }
    readonly refreshAssignDependency = () => {
        const allrooms = this.ALLROOMS;
        const scenario_set: Set<string> = new Set();
        const automation_set: Set<string> = new Set();
        const icon_set: Set<string> = new Set();
        const device_set: Set<string> = new Set();

        if (this._assigned_room === 'all') {
            this._assigned_scenario = 'all';
            this._assigned_automation = 'all';
            this._assigned_icon = 'all';
            this._assigned_device = 'all';
        } else {
            for (let i = 0; i < allrooms.length; i++) {
                const room_id = allrooms[i];
                if (window.FOCUS_GATEWAY) {
                    const room = window.FOCUS_GATEWAY.getRoom(room_id);
                    if (room) {
                        room.scenes.forEach(id => { scenario_set.add(id); });
                        room.getAllAutomations().forEach(id => { automation_set.add(id); });
                        room.getAllIcons().forEach(id => { icon_set.add(id); });
                        room.getAllDevices().forEach(id => { device_set.add(id); });
                    }
                }
            }
            this._assigned_scenario = scenario_set;
            this._assigned_automation = automation_set;
            this._assigned_icon = icon_set;
            this._assigned_device = device_set;
        }
    }
    constructor() {
        this._support = {};
        this._attr = {};
        this._permission = {};
        user_function_type.forEach(func_id => {
            this._support[func_id] = 'n'; 
        });
        user_attr_type.forEach(attr_id => {
            this._attr[attr_id] = '';
        });
    }
}

class CsxUserProfile {

    private _id: string;
    private _profile: {
        GroupSettings: any; // define user's devices group settings
        Active: boolean; // determine user is activated
    };
    private _config: {
        /* this attribute should be used by admin only */
        LocalUserDictionary: { [s: string]: CsxAuthorization }; // list all users created on device
    }

    constructor(id: string, prof: any) {
        this._id = id;
        this._profile = (prof) ? prof : { Active: true };
        this._config = {
            LocalUserDictionary: {},
        }
    }

    get ID() { return this._id.slice(); }
    set ID(v: string) { this._id = v.slice(); }
    get DOCUMENT() { return CsxUtil.nestedClone(this._profile); }
    get GRP_ARRAY() { return ((this._profile.GroupSettings) ? Object.keys(this._profile.GroupSettings) : []).concat(['All']); }
    get ACTIVE_STA() { return this._profile.Active; }
    get LOCAL_USER_LIST() { return Object.keys(this._config.LocalUserDictionary); }

    getGroup = (grp_id: string) => { return this._profile.GroupSettings[grp_id]; }
    getGroupGatewayArray = (grp_id: string): Array<string> => {
        let ret: Array<string> = [];
        if (grp_id === 'All') {
            ret = Array.from(window.CSX_MANAGER.GATEWAYS);
        } else {
            let hasNoGW = false;
            const grp = this.getGroup(grp_id);
            for (const dev_name in grp) {
                if (Object.prototype.hasOwnProperty.call(grp, dev_name)) {
                    const dev_info = grp[dev_name];
                    if (dev_info.Type === 'DEV') {
                        hasNoGW = true;
                    } else if (dev_info.Type === 'GW') {
                        if (ret.indexOf(dev_name) < 0)
                            ret.push(dev_name);
                    } else {
                        const gw_id = dev_info.Related.split('_')[1];
                        if (ret.indexOf(gw_id) < 0)
                            ret.push(gw_id);
                    }
                }
            }
            if (hasNoGW)
                ret.push('No gateway');
        }
        return ret;
    }
    getGroupGatewayDeviceArray = (grp_id: string, gw_id: string): Array<string> => {
        let ret: Array<string> = [];
        if (grp_id === 'All') {
            const inst = window.CSX_MANAGER.getGateway(gw_id);
            if (inst) { ret = Array.from(inst.DEVICES).map((dev_id: string) => `${gw_id}\r${dev_id}`); }
        } else {
            const grp_members = this.getGroup(grp_id);
            for (const dev_name in grp_members) {
                if (Object.prototype.hasOwnProperty.call(grp_members, dev_name)) {
                    const dev_info = grp_members[dev_name];
                    if (dev_info.Type === 'DEV' && gw_id === 'No gateway') {
                        ret.push(`${gw_id}\r${dev_name}`);
                    } else if (dev_info.Type === 'GW' && dev_name === gw_id) {
                        const inst = window.CSX_MANAGER.getGateway(gw_id);
                        if (inst) { ret = ret.concat(Array.from(inst.DEVICES).map((dev_id: string) => `${gw_id}\r${dev_id}`)); }
                    } else if (dev_info.Type === 'DUG' && dev_info.Related.split('_')[1] === gw_id) {
                        ret.push(`${gw_id}\r${dev_name}`);
                    }
                }
            }
        }
        return ret;
    }
    getLocalUserAuth = (id: string): CsxAuthorization | undefined => { return this._config.LocalUserDictionary[id]; }
    setLocalUserAuth = (id: string, document: string) => {
        this._config.LocalUserDictionary[id] = new CsxAuthorization();
        this._config.LocalUserDictionary[id].loadDocument(document);
    }
    deleteLocalUser = (id: string) => {
        delete this._config.LocalUserDictionary[id];
    }
}

export class CsxMessageBuffer {
    private MAX_BUFFER_SIZE?: number;
    private _last_timestamp = -0.00001;
    private _buf: { [timestamp: number]: string } = {};
    private _timestamp_sort: Array<number> = [];
    private _timestamp_overwrite_mode = false;
    private sorter(a: number, b: number): number {
        if (a < b) return -1;
        else if (a === b) return 0;
        else return 1;
    }
    get messages(): Array<string> {
        const msg_sort = this._timestamp_sort.map(key => this._buf[key]);
        // console.log('msg_sort :', msg_sort);
        const lines: Array<string> = [];
        let line_cnt = 0;
        for (let i = 0; i < msg_sort.length; i++) {
            const msg = msg_sort[i];
            // console.log('msg :', msg);
            lines[line_cnt] = (lines[line_cnt]) ? (lines[line_cnt] + msg) : msg;
            if (msg.slice(-1) === '\n') line_cnt++;
        }
        return lines;
    }
    get timestamps(): Array<number> { return this._timestamp_sort.slice(); }
    get laststamps(): number { return Number(this._last_timestamp); }
    get size(): number { return this._timestamp_sort.length; }
    set TIMESTAMP_OVERWRITE(mode: boolean) { this._timestamp_overwrite_mode = mode; }
    findByTimestamp = (timestamp: number): string | undefined => { return this._buf[timestamp]; }
    free = () => {
        this._last_timestamp = -0.00001;
        this._buf = {};
        this._timestamp_sort = [];
    }
    push = (timestamp: number, message: string) => {
        const revise_msg = message.replace(/\r/g, '\n');
        if (timestamp in this._buf) {
            this._buf[timestamp] = (this._timestamp_overwrite_mode) ? revise_msg : this._buf[timestamp].concat(revise_msg);
        } else { // if timestamp === -1, buffer should automatically choose a proper value
            const auto_tt = (timestamp === -1) ? this._last_timestamp + 0.00001 : timestamp;
            this._buf[auto_tt] = revise_msg;
            this._timestamp_sort.push(auto_tt);
            if (auto_tt > this._last_timestamp) {
                this._last_timestamp = auto_tt;
            } else {
                this._timestamp_sort.sort(this.sorter);
            }
            if (typeof this.MAX_BUFFER_SIZE === 'number' && this._timestamp_sort.length > this.MAX_BUFFER_SIZE) {
                const deprecated = this._timestamp_sort.pop();
                if (typeof deprecated === 'number') delete this._buf[deprecated];
            }
        }
    }
    constructor(max_size?: number) { this.MAX_BUFFER_SIZE = max_size; }
}

export class CsxDevice {
    protected _serial_no: string = Math.random().toString().slice(2);
    protected _id: string;
    protected _sys_sta: CsxUtil.SYS_STA_TYPE = CsxUtil.SYS_STA_TYPE.Wait_Add;
    protected _status_dict: CsxUtil.CSX_STD_STA;
    protected _param_dict: CsxUtil.CSX_STD_STA;
    protected _attr_set: Set<CsxUtil.CYP_STD_CMD_TYPE>;
    protected _owner: Set<string>;
    protected _visitor: Set<string>;
    protected _init_time: number;
    protected _subs: CsxWs | undefined;
    protected _gw_user_info: CsxUserProfile;
    protected _is_virtual_device = true; // if this flag is set, this instance is a temperarily created object. 
    protected _discovery_info: DiscoveryInformation;

    constructor(props: { id: string; info?: CsxUtil.CSX_STD_STA; owner?: Array<string>; visitor?: Array<string> }) {
        const { id, info, owner, visitor } = props;
        this._id = id;
        this._status_dict = (typeof info !== 'undefined') ? info : {};
        this._is_virtual_device = (typeof info !== 'undefined') ? false : true;
        this._param_dict = {};
        this._attr_set = new Set([]);
        this._owner = new Set((owner) ? owner : []);
        this._visitor = new Set((visitor) ? visitor : []);
        this._init_time = new Date().getTime();
        this._gw_user_info = new CsxUserProfile('', {});
        this._discovery_info = parseDiscoveryInfo('');
        if (info)
            Object.keys(info).forEach((attr) => { if (CsxUtil.isCypStdCmdType(attr)) { this._attr_set.add(attr); } });
        this.resetInitTime();
    }

    get SNO() { return this._serial_no.slice(); }
    get MQTT_RES_TOPIC() { return `CYP/${this._id.replace(/:/g, '')}/SERVER`; }
    get MQTT_REQ_TOPIC() { return `CYP/${this._id.replace(/:/g, '')}/CLIENT`; }
    get SYS_STA() { return this._sys_sta; }
    set SYS_STA(sta: CsxUtil.SYS_STA_TYPE) { this._sys_sta = sta; }
    get ID() { return this._id.slice(); }
    get ATTRS() { return new Set(this._attr_set); }
    get STA() { return this._status_dict; }
    get PARAM() { return this._param_dict; }
    get VISITORS() { return new Set(this._visitor); }
    get OWNERS() { return new Set(this._owner); }
    get LOCAL_USER() { return this._gw_user_info; }
    get NICKNAME(): string {
        if (typeof this.STA.A16 === 'string') {
            return this.STA.A16.slice();
        } else if (this.STA.A4 && this.STA.A6) {
            return `${this.STA.A6} (${this.STA.A4.slice(9)})`;
        // } else if (this.STA.A4 && !this.STA.A6) {
        //     return `${this.STA.A4}`;
        // } else if (!this.STA.A4 && this.STA.A6 && this.STA.A8) {
        //     return `${this.STA.A8}`;
        } else {
            const discovery_info = this.DISCOVERY_INFO;
            const show_name = (discovery_info.ProductName.length > 0) ? discovery_info.ProductName : 'Unknown';
            return (show_name !== 'Unknown') ? show_name : this.ID;
        }
    }
    get IS_VIRTUAL() { return Boolean(this._is_virtual_device); }
    set IS_VIRTUAL(v: boolean) { this._is_virtual_device = v; }
    get DISCOVERY_INFO() { return Object.assign({}, this._discovery_info); }
    set DISCOVERY_INFO(info: DiscoveryInformation) { this._discovery_info = info; }
    get ONLINE_TIME() { return ((new Date().getTime() - this._init_time) / 1000); }

    resetInitTime = () => { this._init_time = new Date().getTime(); }
    addVistor = (vid: string) => { this._visitor.add(vid); }
    removeVistor = (vid: string) => { this._visitor.delete(vid); }
    free = () => { if (this._subs) { this._subs.unsubscribe(); } }
    updateStatus = (key: string, value: any) => {
        const key_walk = key.split('.');
        if (CsxUtil.isCypStdCmdType(key_walk[0])) {
            this._attr_set.add(key_walk[0]);
            CsxUtil.nestedSet(this._status_dict, key_walk.join('.'), value);
            if (key_walk[0] === 'M77') this.resetInitTime();
            if (key_walk[0] === 'A124' && value === '100') {
                this.SYS_STA = CsxUtil.SYS_STA_TYPE.Firmware_Update;
            }
        }
    }
    updateParam = (key: string, value: any) => {
        const key_walk = key.split('.');
        if (CsxUtil.isCypStdCmdType(key_walk[0])) {
            this._attr_set.add(key_walk[0]);
            CsxUtil.nestedSet(this._param_dict, key_walk.join('.'), value);
        }
    }
}

export class CsxGateway extends CsxDevice {

    protected _device_dict: { [s: string]: CsxDevice };
    protected _device_id_set: Set<string>;
    protected _system_config: { [s: string]: any };
    protected _automation_set: CsxEventSystem.CsxAutomationSet;
    protected _scenario_set: CsxEventSystem.CsxScenarioSet;
    protected _homeconfig_set: CsxEventSystem.CsxRoomSet;
    protected _icon_set: CsxEventSystem.CsxIconSet;
    protected _tcpdev_set: CsxEventSystem.TcpDeviceSet;
    protected _cmd_set: CsxEventSystem.CsxCommandSet;
    protected _error_state: string | undefined;
    protected _setup_interval?: NodeJS.Timeout;
    protected _reconnect_interval?: NodeJS.Timeout;
    protected _user_connection_info: { [username: string]: Set<string> };

    constructor(props: any) {
        super(props);
        this._device_dict = {};
        this._device_id_set = new Set([]);
        this._system_config = {};
        this._user_connection_info = {};
        this._automation_set = new CsxEventSystem.CsxAutomationSet();
        this._scenario_set = new CsxEventSystem.CsxScenarioSet();
        this._homeconfig_set = new CsxEventSystem.CsxRoomSet();
        this._icon_set = new CsxEventSystem.CsxIconSet();
        this._tcpdev_set = new CsxEventSystem.TcpDeviceSet();
        this._cmd_set = new CsxEventSystem.CsxCommandSet();
        this._error_state = 'NOT_READY';
    }

    get MQTT_RES_TOPIC() { return `CYP/${this._id.replace(/:/g, '')}/GET`; }
    get MQTT_REQ_TOPIC() { return `CYP/${this._id.replace(/:/g, '')}/SET`; }
    get ERROR_STA() { return (this._error_state) ? this._error_state.slice() : undefined; }
    get DEVICES(): Set<string> {
        const own_device_list: Set<string> = new Set();
        this._device_id_set.forEach(device_id => {
            const device = this.getDevice(device_id);
            if (device && (device.SYS_STA !== CsxUtil.SYS_STA_TYPE.Wait_Add && device.SYS_STA !== CsxUtil.SYS_STA_TYPE.Wait_Discovery))
                own_device_list.add(device_id);
        })
        return own_device_list; 
    }
    get SCANS(): Set<string> {
        const scan_list: Set<string> = new Set();
        this._device_id_set.forEach(device_id => {
            const device = this.getDevice(device_id);
            if (device && device.SYS_STA === CsxUtil.SYS_STA_TYPE.Wait_Add)
                scan_list.add(device_id);
        })
        return scan_list; 
    }
    get AUTOMATIONS() { return this._automation_set.ID_SET; }
    get AUTOMATIONS_BACKUP_FILENAME() { return this._automation_set.FILENAME; }
    get AUTOMATION_SET_JSON() { return this._automation_set.toJson(); }
    get AUTOMATION_SIZE() { return this._automation_set.SIZE; }
    get AUTOMATION_SIZE_LIMIT() { return this._automation_set.SIZE_LIMIT; }
    get SCENARIOS() { return this._scenario_set.ID_SET; }
    get SCENARIOS_BACKUP_FILENAME() { return this._scenario_set.FILENAME; }
    get SCENARIO_SET_JSON() { return this._scenario_set.toJson(); }
    get SCENARIO_SIZE() { return this._scenario_set.SIZE; }
    get SCENARIO_SIZE_LIMIT() { return this._scenario_set.SIZE_LIMIT; }
    get ROOMS() { return this._homeconfig_set.ID_SET; }
    get ROOMS_BACKUP_FILENAME() { return this._homeconfig_set.FILENAME; }
    get ROOMS_SET_JSON() { return this._homeconfig_set.toJson(); }
    get ROOMS_SIZE() { return this._homeconfig_set.SIZE; }
    get ROOMS_SIZE_LIMIT() { return this._homeconfig_set.SIZE_LIMIT; }
    get ICONS() { return this._icon_set.ID_SET; }
    get ICONS_BACKUP_FILENAME() { return this._icon_set.FILENAME; }
    get ICONS_SET_JSON() { return this._icon_set.toJson(); }
    get ICONS_SIZE() { return this._icon_set.SIZE; }
    get ICONS_SIZE_LIMIT() { return this._icon_set.SIZE_LIMIT; }
    get TCPDEVS() { return this._tcpdev_set.ID_SET; }
    get TCPDEVS_BACKUP_FILENAME() { return this._tcpdev_set.FILENAME; }
    get TCPDEVS_SET_JSON() { return this._tcpdev_set.toJson(); }
    get TCPDEVS_SIZE() { return this._tcpdev_set.SIZE; }
    get TCPDEVS_SIZE_LIMIT() { return this._tcpdev_set.SIZE_LIMIT; }
    get CMDS() { return this._cmd_set.ID_SET; }
    get CMDS_BACKUP_FILENAME() { return this._cmd_set.FILENAME; }
    get CMDS_SET_JSON() { return this._cmd_set.toJson(); }
    get CMDS_SIZE() { return this._cmd_set.SIZE; }
    get CMDS_SIZE_LIMIT() { return this._cmd_set.SIZE_LIMIT; }
    // get ROOM_SET_JSON() { return this._scenario_set.toJson(); }
    get SYSCONFIG() { return this._system_config; }

    /* Event system function */
    getDevice = (dev_id: string): CsxDevice | undefined => { return (window.CSX_CUR_AUTH && window.CSX_CUR_AUTH.isDeviceAssigned(dev_id)) ? this._device_dict[dev_id] : undefined; }
    getAutomation = (automation_id: string): CsxEventSystem.CsxAutomation | undefined => { return (window.CSX_CUR_AUTH && window.CSX_CUR_AUTH.isAutomationAssigned(automation_id)) ? this._automation_set.get(automation_id) : undefined; }
    checkAutomationNameExist = (name: string) => { return this._automation_set.checkAutomationName(name); } 
    getScenario = (scenario_id: string): CsxEventSystem.CsxScenario | undefined => { return (window.CSX_CUR_AUTH && window.CSX_CUR_AUTH.isScenarioAssigned(scenario_id)) ? this._scenario_set.get(scenario_id) : undefined; }
    newScenario = (): CsxEventSystem.CsxScenario => { return this._scenario_set.newScenario(); }
    checkScenarioNameExist = (name: string) => { return this._scenario_set.checkScenarioName(name); } 
    getRoom = (room_id: string): CsxEventSystem.CsxRoom | undefined => {
        if (window.CSX_CUR_USER) {
            // sign-in user
            return (window.CSX_CUR_AUTH && window.CSX_CUR_AUTH.isRoomAssigned(room_id)) ? this._homeconfig_set.get(room_id) : undefined;
        } else {
            // guest
            return this._homeconfig_set.get(room_id);
        }
    }
    newRoom = (): CsxEventSystem.CsxRoom => { return this._homeconfig_set.newRoom(); }
    checkRoomNameExist = (name: string) => { return this._homeconfig_set.checkRoomName(name); } 
    getIcon = (icon_id: string): CsxEventSystem.CsxIcon | undefined => { return (window.CSX_CUR_AUTH && window.CSX_CUR_AUTH.isIconAssigned(icon_id)) ? this._icon_set.get(icon_id) : undefined; }
    newIcon = (): CsxEventSystem.CsxIcon => { return this._icon_set.newIcon(); }
    checkIconNameExist = (name: string) => { return this._icon_set.checkIconName(name); } 
    getTcpDevice = (tcpdev_id: string): CsxEventSystem.TcpDevice | undefined => { return (window.CSX_CUR_AUTH) ? this._tcpdev_set.get(tcpdev_id) : undefined; }
    newTcpDevice = (): CsxEventSystem.TcpDevice => { return this._tcpdev_set.newTcpDevice(); }
    checkTcpDeviceIPPortExist = (ip: string, port: number) => { return this._tcpdev_set.checkIPPort(ip, port); } 
    getCommand = (cmd_id: string): CsxEventSystem.CsxCommand | undefined => { return (window.CSX_CUR_AUTH) ? this._cmd_set.get(cmd_id) : undefined; }
    newCommand = (): CsxEventSystem.CsxCommand => { return this._cmd_set.newCommand(); }
    checkCommandNameExist = (name: string) => { return this._cmd_set.checkCommandName(name); } 
    getUserLogin = (username: string) => { return (this._user_connection_info[username]) ? new Set(this._user_connection_info[username]) : undefined; }

    free = () => {
        this._device_id_set.forEach(dev_id => { this._device_dict[dev_id].free(); });
        if (this._subs) {
            this._subs.unsubscribe();
        }
    }

    /* CS9 protocol function */
    setup = (timeout_ms?: number) => {
        this.free();
        return new Promise((resolve, reject) => {
            let retry_counter = 0;
            const CHECK_PERIOD = 500;
            const origin = (window.APP_DEV_MODE && DEV_TARGET_IP.length > 0) ? `ws://${DEV_TARGET_IP}` : window.location.origin;
            const check_sub = () => {
                if (this._subs) {
                    if (this._subs.CLIENT_READY && this.SYS_STA === CsxUtil.SYS_STA_TYPE.Sync) {
                        if (this._setup_interval)
                            global.clearInterval(this._setup_interval);
                        resolve(0);
                    } else {
                        retry_counter++;
                        if (this._subs.CLIENT_ERROR === 'Username or password error!') {
                            if (this._setup_interval)
                                global.clearInterval(this._setup_interval);
                            reject(this._subs.CLIENT_ERROR);
                        }
                        if (timeout_ms && retry_counter * CHECK_PERIOD > timeout_ms) {
                            if (this._setup_interval)
                                global.clearInterval(this._setup_interval);
                            reject(getText('NOTIFY_MSG_TIMEOUT'));
                        }
                    }
                }
            }
            if (window.APP_SRC === CsxUtil.APP_SRC_TYPE.LOCAL) {
                try {
                    this._subs = new CsxWs(7681, origin, this.handleResponse, this._handle_error);
                    this._setup_interval = setInterval(check_sub, CHECK_PERIOD);
                } catch (err) {
                    reject(err);
                }
            }
        });
    }

    /* Handle system configuration not existing in standard command set */
    updateSystemConfig = (key: string, value: any) => {
        const key_walk = key.split('.');
        if (key_walk.length > 0) {
            CsxUtil.nestedSet(this._system_config, key_walk.join('.'), value);
        }
    }

    handleRequest = (message: string) => {
        try {
            if (window.APP_SRC === CsxUtil.APP_SRC_TYPE.LOCAL) {
                if (this._subs) {
                    console.log('handleRequest :', message);
                    const send = CsxUtil.AESEncrypt(message);
                    const payload_header = this.__byte_padding((send.length/2).toString(16), 8);
                    this._subs.publish(payload_header + send);
                }
            }
        } catch (err) {
            Notify({ title: getText('NOTIFY_TITLE_CLIENT_ERROR'), context: `${err}`, type: 'error' });
        }
    }

    handleResponse = (message: string) => {
        try {
            const lines = CsxUtil.AESDecrypt(message).split('\n');
            const need_refresh_field: Set<RenderFieldType> = new Set();
            let need_refresh_assigned_dependency = false;
            if (window.APP_DEBUG_MESSAGING)
                console.log('handleResponse :', lines);
            for (let i = 0; i < lines.length; i++) {
                const line = lines[i];
                const code = line.slice(0, 4);
                if (code.charAt(1) === '0') { // gw/dev -> app
                    if (code === '!001') { // device system status
                        const dmac = line.slice(5, 22);
                        const info_walk = line.slice(23).split('=');
                        const sta_code = parseInt(info_walk[0]);
                        if (!this._device_id_set.has(dmac))
                            this.referenceDevice(dmac);
                        if (typeof CsxUtil.SYS_STA_TYPE[sta_code] === 'undefined') {
                            this._device_dict[dmac].SYS_STA = CsxUtil.SYS_STA_TYPE.Bad_Status;
                        } else {
                            this._device_dict[dmac].SYS_STA = sta_code;
                            this._device_dict[dmac].DISCOVERY_INFO = parseDiscoveryInfo(info_walk.slice(1).join('='));
                        }
                        if (window.FOCUS_DEVICE && window.FOCUS_DEVICE.ID === dmac) {
                            need_refresh_field.add('DeviceRealTime');
                            need_refresh_field.add('DeviceSystemStatus');
                        }
                        need_refresh_field.add('Dashboard');
                        need_refresh_assigned_dependency = true;
                    } else if (code === '!002') { // device remove
                        const dmac = line.slice(5);
                        if (this._device_id_set.has(dmac)) {
                            this._device_id_set.delete(dmac);
                            delete this._device_dict[dmac];
                            need_refresh_field.add('DeviceRealTime');
                            need_refresh_field.add('Dashboard');
                        }
                        need_refresh_assigned_dependency = true;
                    } else if (code === '!003') { // check app idle
                        if (!window.IDLE_STA) { this.handleRequest('?006\n'); }
                    } else if (code === '!004') { // inform gateway online
                        const info_walk = line.slice(5).split('=');
                        const sta_code = parseInt(info_walk[0]);
                        if (sta_code === 1) {
                            if (this.SYS_STA === CsxUtil.SYS_STA_TYPE.Firmware_Update) {
                                CsxUtil.signout();
                            }
                            this.SYS_STA = CsxUtil.SYS_STA_TYPE.Sync;
                        } else {
                            this.SYS_STA = CsxUtil.SYS_STA_TYPE.All_Connect;
                        }
                        // irqRefresh(['GatewayRealTime', 'AuthenticationUpdate']);
                        need_refresh_field.add('Dashboard');
                        need_refresh_field.add('GatewayRealTime');
                        need_refresh_field.add('AuthenticationUpdate');
                    } else if (code === '!005') { // device firmware update response
                        // const dmac = line.slice(5, 22);
                        // const msg_express = line.slice(23);
                        // const msg_walk = msg_express.split('=');
                        // const progress = parseInt(msg_walk[0]);
                        // if (window.FOCUS_DEVICE && window.FOCUS_DEVICE.ID === dmac)
                        //     irqRefresh(['MessageRealTime'], progress, msg_walk.slice(1).join('='));
                    } else if (code === '!006') { // gateway firmware update response
                        // const msg_express = line.slice(5);
                        // const msg_walk = msg_express.split('=');
                        // const progress = parseInt(msg_walk[0]);
                        // irqRefresh(['GatewayRealTime'], progress, msg_walk.slice(1).join('='));
                    } else if (code === '!007') { // gateway cert

                    } else if (code === '!008') { // gateway key

                    } else if (code === '!009') { // device debug message
                        const dmac = line.slice(5, 22);
                        const msg_express = line.slice(23);
                        const msg_walk = msg_express.split('=');
                        const timestamp = parseInt(msg_walk[0]);
                        if (window.FOCUS_DEVICE && window.FOCUS_DEVICE.ID === dmac)
                            irqRefresh(['MessageRealTime'], timestamp, msg_walk.slice(1).join('='));
                    } else if (code === '!010') { // gateway debug message
                        const msg_express = line.slice(5);
                        const msg_walk = msg_express.split('=');
                        const timestamp = parseInt(msg_walk[0]);
                        irqRefresh(['GatewayMessageRealTime'], timestamp, msg_walk.slice(1).join('='));

                    } else if (code === '!011') { // user information
                        if (window.APP_SRC === CsxUtil.APP_SRC_TYPE.LOCAL) {
                            const info_walk = line.slice(5).split('=');
                            const [operation, id] = info_walk[0].split('.');
                            const usr_document = info_walk.slice(1).join('=');
                            if (window.CSX_CUR_USER) {
                                if (id === window.CSX_CUR_USER.ID)
                                    CsxUtil.signout();
                                if (operation === 'info') {
                                    window.CSX_CUR_USER.setLocalUserAuth(id, usr_document);
                                    const local_user_auth = window.CSX_CUR_USER.getLocalUserAuth(id);
                                    if (local_user_auth && window.CSX_CUR_AUTH && local_user_auth.getAttribute('username') === window.CSX_CUR_AUTH.getAttribute('username')) {
                                        window.CSX_CUR_USER.ID = id;
                                    }
                                } else if (operation === 'delete') {
                                    window.CSX_CUR_USER.deleteLocalUser(id);
                                }
                                need_refresh_field.add('AuthenticationUpdate');
                            }
                        } else if (window.APP_SRC === CsxUtil.APP_SRC_TYPE.DYNAMODB) {

                        }
                    } else if (code === '!012') { // authorization information
                        if (window.APP_SRC === CsxUtil.APP_SRC_TYPE.LOCAL) {
                            const info_walk = line.slice(5).split('=');
                            const key = info_walk[0];
                            const value = info_walk.slice(1).join('=');
                            if (key === 'login') {
                                if (value === 'user') {
                                    window.CSX_CUR_USER = new CsxUserProfile('login', {});
                                }
                            } else if (key === 'auth') {
                                // console.log('line :>> ', line);
                                window.CSX_CUR_AUTH = new CsxAuthorization();
                                window.CSX_CUR_AUTH.loadDocument(value);
                                // console.log('permission :>> ', window.CSX_CUR_AUTH.toLines());
                                need_refresh_assigned_dependency = true;
                            }
                            need_refresh_field.add('AuthenticationUpdate');
                        } else if (window.APP_SRC === CsxUtil.APP_SRC_TYPE.DYNAMODB) {

                        }
                    } else if (code === '!013') { // login info
                        if (window.APP_SRC === CsxUtil.APP_SRC_TYPE.LOCAL) {
                            // console.log('line :>> ', line);
                            const login_infos = line.slice(5).split('\r');

                            this._user_connection_info = {};
                            login_infos.forEach(info => {
                                const info_walk = info.split("=");
                                const [ username, ipaddr ] = info_walk;
                                if (typeof username === 'string' && typeof ipaddr === 'string') {
                                    if (typeof this._user_connection_info[username] === 'undefined') {
                                        this._user_connection_info[username] = new Set();
                                    }
                                    this._user_connection_info[username].add(ipaddr);
                                }
                            });
                            need_refresh_field.add('AuthenticationUpdate');
                        } else if (window.APP_SRC === CsxUtil.APP_SRC_TYPE.DYNAMODB) {

                        }
                    }
                } else if (code.charAt(1) === '1') { // dug status set/get
                    if (code === '!101') { // device info update
                        const dmac = line.slice(5, 22);
                        const info_express = line.slice(23);
                        const info_exp_walk = info_express.split('=');
                        if (!this._device_id_set.has(dmac))
                            this.referenceDevice(dmac);
                        this._device_dict[dmac].updateStatus(info_exp_walk[0], info_exp_walk.slice(1).join('='));
                        if (window.FOCUS_DEVICE && window.FOCUS_DEVICE.ID === dmac) {
                            need_refresh_field.add('DeviceRealTime');
                        }
                        need_refresh_field.add('Dashboard');
                    } else if (code === '!102') { // device status delete

                    } else if (code === '!103') { // device param
                        const dmac = line.slice(5, 22);
                        const info_express = line.slice(23);
                        const info_exp_walk = info_express.split('=');
                        if (!this._device_id_set.has(dmac))
                            this.referenceDevice(dmac);
                        this._device_dict[dmac].updateParam(info_exp_walk[0].replace(/\[\d+\]/, ''), info_exp_walk.slice(1).join('='));
                        if (window.FOCUS_DEVICE && window.FOCUS_DEVICE.ID === dmac) {
                            need_refresh_field.add('DeviceRealTime');
                        }
                        need_refresh_field.add('Dashboard');
                    }
                } else if (code.charAt(1) === '2') { // gw status set/get 
                    if (code === '!201') { // gateway info update
                        const info_express = line.slice(5);
                        const info_exp_walk = info_express.split('=');
                        const keypath_walk = info_exp_walk[0].split('.');
                        const status_key = keypath_walk[0];
                        if (CsxUtil.isCypStdCmdType(status_key)) {
                            // if (status_key === 'A124') { // hard code disable fw update UI
                            //     const update_progress = parseInt(info_exp_walk[1]);
                            //     if (update_progress === 100)
                            //         this.SYS_STA = CsxUtil.SYS_STA_TYPE.Offline;
                            //     this.updateStatus(info_exp_walk[0], info_exp_walk.slice(1).join(','));
                            //     need_refresh_field.add('GatewayRealTime');
                            // } else {
                            //     this.updateStatus(info_exp_walk[0], info_exp_walk.slice(1).join(','));
                            //     need_refresh_field.add('GatewayRealTime');
                            // }
                            this.updateStatus(info_exp_walk[0], info_exp_walk.slice(1).join(','));
                            need_refresh_field.add('GatewayRealTime');
                            need_refresh_field.add('Dashboard');
                        } else {
                            if (status_key === 'AUTOMATION') { // hard code for automation
                                const automation_id = keypath_walk[1];
                                const parse = JSON.parse(info_exp_walk.slice(1).join('='));
                                this._automation_set.add(new CsxEventSystem.CsxAutomation(automation_id, parse));
                                need_refresh_assigned_dependency = true;
                                need_refresh_field.add('EventSystem');
                            } else if (status_key === 'ICON') { // hard code for icon
                                const icon_id = keypath_walk[1];
                                const parse = JSON.parse(info_exp_walk.slice(1).join('='));
                                this._icon_set.add(new CsxEventSystem.CsxIcon(icon_id, parse));
                                need_refresh_assigned_dependency = true;
                                need_refresh_field.add('EventSystem');
                            } else if (status_key === 'PROJECT') { // hard code for project
                                const scenario_id = keypath_walk[1];
                                const parse = JSON.parse(info_exp_walk.slice(1).join('='));
                                this._scenario_set.add(new CsxEventSystem.CsxScenario(scenario_id, parse));
                                need_refresh_assigned_dependency = true;
                                need_refresh_field.add('EventSystem');
                            } else if (status_key === 'ROOM') { // hard code for home page config list
                                const config_id = keypath_walk[1];
                                const parse = JSON.parse(info_exp_walk.slice(1).join('='));
                                this._homeconfig_set.add(new CsxEventSystem.CsxRoom(config_id, parse));
                                need_refresh_assigned_dependency = true;
                                need_refresh_field.add('EventSystem');
                            } else if (status_key === 'TCPDEV') { // hard code for TCP device list
                                const config_id = keypath_walk[1];
                                const parse = JSON.parse(info_exp_walk.slice(1).join('='));
                                this._tcpdev_set.add(new CsxEventSystem.TcpDevice(config_id, parse));
                                need_refresh_assigned_dependency = true;
                                need_refresh_field.add('EventSystem');
                            } else if (status_key === 'CMD') { // hard code for command list
                                const config_id = keypath_walk[1];
                                const parse = JSON.parse(info_exp_walk.slice(1).join('='));
                                this._cmd_set.add(new CsxEventSystem.CsxCommand(config_id, parse));
                                need_refresh_assigned_dependency = true;
                                need_refresh_field.add('EventSystem');
                            } else if (status_key === 'ACTION_TEST') { // hard code for action run test function
                                const action_runtest_feedback_info = info_exp_walk.slice(1).join('=');
                                if (action_runtest_feedback_info.toLowerCase().indexOf('done') < 0) {
                                    Notify({ type: 'error', context: action_runtest_feedback_info, title: getText('COMMAND'), timeout: 10000 });
                                } else {
                                    Notify({ context: getText('NOTIFY_MSG_SUCCESS'), title: getText('COMMAND'), type: 'success' });
                                }
                            } else if (status_key === 'ICON_IMPORT_OPTION') { // hard code for user icon-import prefering option
                                // console.log('line :>> ', line);
                                this.updateSystemConfig(info_exp_walk[0], info_exp_walk.slice(1).join(','));
                                need_refresh_field.add('EventSystem');
                            } else if (status_key === 'ICON_IMPORT_OPTION_REMEMBER') { // hard code for user icon-import prefering option
                                // console.log('line :>> ', line);
                                this.updateSystemConfig(info_exp_walk[0], info_exp_walk.slice(1).join(','));
                                need_refresh_field.add('EventSystem');
                            } else {
                                console.warn('[manager.ts line 581] Bad status code : ' + status_key);
                            }
                        }
                    } else if (code === '!202') { // gateway status delete
                        const del_cmd_map: any = {
                            'M59': 'M54',
                            'M52': 'M47',
                            'M40': 'M29',
                            'M41': 'M31',
                            'M27': 'M4',
                        }
                        const info_express = line.slice(5);
                        const info_exp_walk = info_express.split('.');
                        if (Object.prototype.hasOwnProperty.call(del_cmd_map, info_exp_walk[0]))
                            info_exp_walk[0] = del_cmd_map[info_exp_walk[0]];
                        if (info_exp_walk[0] === 'AUTOMATION') { // hard code for event system
                            this._automation_set.remove(info_exp_walk.slice(1).join('.'))
                            need_refresh_assigned_dependency = true;
                            need_refresh_field.add('EventSystem');
                        } else if (info_exp_walk[0] === 'PROJECT') { // hard code for event system
                            this._scenario_set.remove(info_exp_walk.slice(1).join('.'))
                            need_refresh_assigned_dependency = true;
                            need_refresh_field.add('EventSystem');
                        } else if (info_exp_walk[0] === 'ROOM') { // hard code for event system
                            this._homeconfig_set.remove(info_exp_walk.slice(1).join('.'))
                            need_refresh_assigned_dependency = true;
                            need_refresh_field.add('EventSystem');
                        } else if (info_exp_walk[0] === 'ICON') { // hard code for event system
                            this._icon_set.remove(info_exp_walk.slice(1).join('.'))
                            need_refresh_assigned_dependency = true;
                            need_refresh_field.add('EventSystem');
                        } else if (info_exp_walk[0] === 'TCPDEV') { // hard code for event system
                            this._tcpdev_set.remove(info_exp_walk.slice(1).join('.'))
                            need_refresh_assigned_dependency = true;
                            need_refresh_field.add('EventSystem');
                        } else if (info_exp_walk[0] === 'CMD') { // hard code for event system
                            this._cmd_set.remove(info_exp_walk.slice(1).join('.'))
                            need_refresh_assigned_dependency = true;
                            need_refresh_field.add('EventSystem');
                        } else {
                            CsxUtil.nestedDelete(this._status_dict, info_exp_walk.join('.'));
                            if (CsxUtil.isCypStdCmdType(info_express)) {
                                this._attr_set.delete(info_express);
                                need_refresh_field.add('GatewayRealTime');
                            }
                        }
                    } else if (code === '!203') { // gateway param
                        const info_express = line.slice(5);
                        const info_exp_walk = info_express.split('=');
                        this.updateParam(info_exp_walk[0].replace(/\[\d+\]/, ''), info_exp_walk.slice(1).join('='));
                        need_refresh_field.add('GatewayRealTime');
                    }
                    // irqRefresh(['GatewayRealTime']);
                }
                // irqRefresh(['Dashboard']);
            }
            if (need_refresh_assigned_dependency && window.CSX_CUR_AUTH) {
                window.CSX_CUR_AUTH.refreshAssignDependency();
            }
            irqRefresh(Array.from(need_refresh_field));
            this._error_state = undefined;
        } catch (error) {
            console.warn('error :', error);
            if (window.APP_DEV_MODE) {
                console.warn("message : ", message);
            }
            this._error_state = `${error}`;
        }
    }
    
    private _handle_error = (err: string) => {
        if (err === 'Connection closed: Abnormal Closure.' && this._subs) {
            if (!this._error_state)
                Notify({ title: getText('NOTIFY_TITLE_CONNECTION_ERROR'), context: getText('NOTIFY_MSG_TRY_RECONNECT'), type: 'error' });
            this._error_state = err;
            if (this.SYS_STA !== CsxUtil.SYS_STA_TYPE.Firmware_Update) {
                this.SYS_STA = CsxUtil.SYS_STA_TYPE.Offline;
            }
            // this._subs.unsubscribe();
            // this._handle_reconnection();
            irqRefresh(['Dashboard', 'GatewayRealTime']);
        }
    }

    referenceDevice = (dev_id: string): CsxDevice => {
        if (!this._device_id_set.has(dev_id)) {
            this._device_id_set.add(dev_id);
            if (window.FOCUS_DEVICE && window.FOCUS_DEVICE.ID === dev_id && window.FOCUS_DEVICE.IS_VIRTUAL)
                this._device_dict[dev_id] = window.FOCUS_DEVICE;
            else
                this._device_dict[dev_id] = new CsxDevice({ id: dev_id });
            this._device_dict[dev_id].IS_VIRTUAL = false;
        }
        if (typeof this._device_dict[dev_id] === 'undefined')
            console.warn(`Device ${dev_id} reference abnormal!`);
        return this._device_dict[dev_id];
    }

    iconUpdate = (file: File, icon: CsxEventSystem.CsxIcon, silent?: boolean) => {
        const notify_title = getText('NOTIFY_TITLE_IMPORT_ICON');
        const fd = new FormData();

        if (file.size > 1048576) {
            Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_FILE_SIZE_LIMIT'), });
            return;
        }
        fd.append('size', file.size.toString());
        fd.append('name', icon.NAME);
        fd.append('format', icon.FORMAT);
        fd.append('isLiked', (icon.LIKED ? "true" : "false"));
        fd.append('file', file);
        fd.append('id', icon.ID);
        return httpUploadFile({
            fd,
            url: '/cgi-bin/upload-icon',
            authorized: ((typeof window.CSX_CUR_AUTH !== 'undefined') && (window.CSX_CUR_AUTH.getPermission('icon_edit') === CsxUserPermissionLevel.FullAccess)),
            filenameValid: !CsxUtil.hasChinese(file.name),
            onSuccess: (silent) ? undefined : () => { Notify({ type: 'success', title: notify_title, context: getText('NOTIFY_MSG_SUCCESS'), }); },
            onServerError: (msg: string) => { Notify({ type: 'error', title: notify_title, context: `${getText('ERROR')}: ${msg}`, }); },
            onNoResponse: () => { Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_DEV_NO_RESPONSE'), }); },
            onNetworkError: (error: any) => { Notify({ type: 'error', title: notify_title, context: `${getText('NOTIFY_MSG_NETWORK_ERROR')} ${error}`, }); },
            onFilenameError: () => { Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_FILENAME_INVALID'), }); },
            onNoAuthorized: () => { Notify({ title: getText('NOTIFY_TITLE_SUCCESS'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' }); },
        });
    }

    iconPackageImport = (file: File, type: string) => {
        const notify_title = getText('NOTIFY_TITLE_IMPORT_ICON');
        const fd = new FormData();
        fd.append('file', file);
        fd.append('duplicate-name-handle', (isIconImportOptionType(type) ? type : icon_import_option_type_array[0]));
        return httpUploadFile({
            fd,
            url: '/cgi-bin/import-icon',
            authorized: ((typeof window.CSX_CUR_AUTH !== 'undefined') && (window.CSX_CUR_AUTH.getPermission('icon_edit') === CsxUserPermissionLevel.FullAccess)),
            filenameValid: !CsxUtil.hasChinese(file.name),
            onSuccess: () => { Notify({ type: 'success', title: notify_title, context: getText('NOTIFY_MSG_SUCCESS'), }); },
            onServerError: (msg: string) => { Notify({ type: 'error', title: notify_title, context: `${getText('ERROR')}: ${msg}`, }); },
            onNoResponse: () => { Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_DEV_NO_RESPONSE'), }); },
            onNetworkError: (error: any) => { Notify({ type: 'error', title: notify_title, context: `${getText('NOTIFY_MSG_NETWORK_ERROR')} ${error}`, }); },
            onFilenameError: () => { Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_FILENAME_INVALID'), }); },
            onNoAuthorized: () => { Notify({ title: getText('NOTIFY_TITLE_SUCCESS'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' }); },
        });
    }

    deviceFirmwareUpdate = (file: File, dev_list: Array<string>) => {
        const update_list = dev_list.filter(dev_id => {
            if (window.CSX_CUR_AUTH) {
                if (window.CSX_CUR_AUTH.getPermission('fwup_control') === CsxUserPermissionLevel.FullAccess) {
                    const device_permission = window.CSX_CUR_AUTH.getPermission('device_permission');
                    if (device_permission === CsxUserPermissionLevel.ViewAssigned) {
                        return window.CSX_CUR_AUTH.isDeviceAssigned(dev_id);
                    } else if (device_permission === CsxUserPermissionLevel.FullAccess) {
                        return true;
                    }
                }
            }
            return false;
        }).filter(dev_id => {
            const inst = this.getDevice(dev_id);
            return (inst && typeof inst.STA.A14 === 'string' && file.name.indexOf(inst.STA.A14) >= 0);
        });
        const notify_title = getText('NOTIFY_TITLE_FWUP');
        const fd = new FormData();
        fd.append('Size', file.size.toString());
        fd.append('DeviceNumber', update_list.length.toString());
        fd.append('DeviceArray', update_list.join(','));
        fd.append('file', file);
        return httpUploadFile({
            fd,
            url: '/cgi-bin/devicefirmware',
            authorized: ((typeof window.CSX_CUR_AUTH !== 'undefined') && (window.CSX_CUR_AUTH.getPermission('fwup_control') === CsxUserPermissionLevel.FullAccess)),
            filenameValid: ((update_list.length > 0) && !CsxUtil.hasChinese(file.name)),
            onSuccess: () => {
                Notify({ type: 'success', title: notify_title, context: getText('NOTIFY_MSG_SUCCESS'), });
                this.handleRequest(update_list.map(dev_id => `?101:${dev_id},A122.0=start`).join('\n') + '\n');
            },
            onServerError: (msg: string) => { Notify({ type: 'error', title: notify_title, context: `${getText('ERROR')}: ${msg}`, }); },
            onNoResponse: () => { Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_DEV_NO_RESPONSE'), }); },
            onNetworkError: (error: any) => { Notify({ type: 'error', title: notify_title, context: `${getText('NOTIFY_MSG_NETWORK_ERROR')} ${error}`, }); },
            onFilenameError: () => { Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_FILENAME_INVALID'), }); },
            onNoAuthorized: () => { Notify({ title: getText('NOTIFY_TITLE_SUCCESS'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' }); },
        });
    }

    firmwareUpdate = (file: File) => {
        const notify_title = `${this.NICKNAME} ${getText('NOTIFY_TITLE_FWUP')}`;
        const fd = new FormData();
        fd.append('file', file);
        return httpUploadFile({
            fd,
            url: '/cgi-bin/firmware',
            authorized: ((typeof window.CSX_CUR_AUTH !== 'undefined') && (window.CSX_CUR_AUTH.getPermission('unit_fwup') === CsxUserPermissionLevel.FullAccess)),
            filenameValid: (typeof this.STA.A14 === 'string' && file.name.indexOf(this.STA.A14) >=0 && !CsxUtil.hasChinese(file.name)),
            onSuccess: () => { Notify({ type: 'success', title: notify_title, context: getText('NOTIFY_MSG_SUCCESS'), }); },
            onServerError: (msg: string) => { Notify({ type: 'error', title: notify_title, context: `${getText('ERROR')}: ${msg}`, }); },
            onNoResponse: () => { Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_DEV_NO_RESPONSE'), }); },
            onNetworkError: (error: any) => { Notify({ type: 'error', title: notify_title, context: `${getText('NOTIFY_MSG_NETWORK_ERROR')} ${error}`, }); },
            onFilenameError: () => { Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_FILENAME_INVALID'), }); },
            onNoAuthorized: () => { Notify({ title: getText('NOTIFY_TITLE_SUCCESS'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' }); },
        });
    }

    languagePackUpdate = (file: File, lang_id: string) => {
        const notify_title = getText('NOTIFY_TITLE_LANG_PACK_UPDATE');
        const fd = new FormData();
        fd.append('file', file);
        fd.append('code', lang_id);
        return httpUploadFile({
            fd,
            url: '/cgi-bin/languagePack',
            authorized: ((typeof window.CSX_CUR_AUTH !== 'undefined') && (window.CSX_CUR_AUTH.getPermission('system_permission') === CsxUserPermissionLevel.FullAccess)),
            filenameValid: (typeof this.STA.A14 === 'string' && file.name.indexOf(this.STA.A14) >=0 && !CsxUtil.hasChinese(file.name)),
            onSuccess: () => { Notify({ type: 'success', title: notify_title, context: getText('NOTIFY_MSG_SUCCESS'), }); },
            onServerError: (msg: string) => { Notify({ type: 'error', title: notify_title, context: `${getText('ERROR')}: ${msg}`, }); },
            onNoResponse: () => { Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_DEV_NO_RESPONSE'), }); },
            onNetworkError: (error: any) => { Notify({ type: 'error', title: notify_title, context: `${getText('NOTIFY_MSG_NETWORK_ERROR')} ${error}`, }); },
            onFilenameError: () => { Notify({ type: 'error', title: notify_title, context: getText('NOTIFY_MSG_FILENAME_INVALID'), }); },
            onNoAuthorized: () => { Notify({ title: getText('NOTIFY_TITLE_SUCCESS'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' }); },
        });
    }

    private __byte_padding(str: string, size: number) { /* add 0 to satisfy string length */
        let s = str.slice();
        while (s.length < size) s = '0' + s;
        return s;
    }

    sendDiscoveryByte = (device_id: string, frames: Array<string>) => {
        if (window.CSX_CUR_USER && window.CSX_CUR_USER.ID === '999') {
        // if (window.CSX_CUR_AUTH && window.CSX_CUR_AUTH.get('device_control') === 'y') {
            const raw: Array<number> = [];
            frames.forEach(frame => { for (let i = 0; i < frame.length; i += 2) { raw.push(parseInt(frame.substr(i, 2), 16)); } });
            if (frames.length > 0 && raw.length > 0) {
                const crc16 = this.__byte_padding(CsxUtil.CRC16(0xffff, raw).toString(16), 4);
                let payload = '';
                payload += '03'; // Command
                payload += this.__byte_padding(raw.length.toString(16), 2); // Frame length
                payload += raw.map(v => this.__byte_padding(v.toString(16), 2)).join('');
                payload += crc16.substr(2, 2) + crc16.substr(0, 2);
                this.handleRequest(`?001:${device_id}=${payload}\n`);
            }
        } else {
            Notify({ title: getText('NOTIFY_TITLE_SUCCESS'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' });
            return;
        }
    }

    deviceSynchronize = (devices: Array<string>) => {
        if (window.CSX_CUR_AUTH && window.CSX_CUR_AUTH.getPermission('dashboard_permission') === CsxUserPermissionLevel.FullAccess) {
            this.handleRequest(devices.map(dev_id => `?001:${dev_id}\n`).join(''));
        } else {
            Notify({ title: getText('NOTIFY_TITLE_SUCCESS'), context: getText('NOTIFY_MSG_UNAUTHORIZED'), type: 'error' });
            return;
        }
    }
}

export default class CsxManager {

    private _gateway_id_set: Set<string>;
    private _gateway_dict: { [s: string]: CsxGateway };
    private _connection_interval?: NodeJS.Timeout;
    private _login_timeout?: NodeJS.Timeout;
    public USER_PROF: CsxUserProfile;

    constructor() {
        this._gateway_dict = {};
        this._gateway_id_set = new Set([]);
        this.USER_PROF = new CsxUserProfile('', {});
        window.CSX_MANAGER_STA = 'Loading...';
        if (Database) {

        }
    }

    get GATEWAYS() { return new Set(this._gateway_id_set); }
    get GW_DEV_ARRAY() {
        const ret: Array<string> = [];
        this.GATEWAYS.forEach(gw_id => {
            const inst = this.getGateway(gw_id);
            if (!inst) return;
            inst.DEVICES.forEach(dev_id => { ret.push(`${gw_id}\r${dev_id}`) })
        });
        return ret;
    }
    get ALLOWED_USER_FUNCTION() { return user_function_type.slice(); }

    getDug = (dev_id: string, gw_id: string): CsxDevice | undefined => { return (this._gateway_id_set.has(gw_id)) ? this._gateway_dict[gw_id].getDevice(dev_id) : undefined; }
    getGateway = (gw_id: string): CsxGateway | undefined => { return this._gateway_dict[gw_id]; }
    getUserProfile = () => { return this.USER_PROF; }

    updateDug = async (args: { gw_id: string; dev_id: string; status: any }) => {
        try {
            const { gw_id, dev_id } = args;
            let { status } = args;
            status = CsxUtil.keyCodeGetBecomeSet(status);
            if (gw_id !== 'No gateway') {
                const inst = this.getGateway(gw_id);
                const strs = CsxUtil.objectBecomeStringArray(status);
                const muts = strs.map(str => `?003:${dev_id},${str.split('\r').join(',')}\n`);
                if (inst) { inst.handleRequest(muts.join('')); }
            }
            return 0;
        } catch (err) {
            console.log('Update device error :', err);
            // throw new Error(err);
            throw err;
        }
    }

    addGateway = (gw: CsxGateway) => {
        if (!this._gateway_id_set.has(gw.ID)) {
            this._gateway_dict[gw.ID] = gw;
            this._gateway_id_set.add(gw.ID);
        }
    }

    updateGateway = async (args: { gw_id: string; status: any }) => {
        try {
            const { gw_id } = args;
            let { status } = args;
            status = CsxUtil.keyCodeGetBecomeSet(status);
            if (gw_id !== 'No gateway') {
                const inst = this.getGateway(gw_id);
                const strs = CsxUtil.objectBecomeStringArray(status);
                const muts = strs.map(str => `?005:${str.split('\r').join(',')}\n`);
                if (inst) { inst.handleRequest(muts.join('')); }
            }
            return 0;
        } catch (err) {
            console.log('Update gateway error :', err);
            // throw new Error(err);
            throw err;
        }
    }

    private _handle_connection = () => {
        window.CSX_MANAGER_STA = 'Initializing control system gateway in local mode...';

        const LOGIN_TIMEOUT = undefined;
        // const LOGIN_TIMEOUT = 10000;
        const gw_id = (window.APP_DEV_MODE) ? DEV_TARGET_IP : window.location.href.replace('https://', '').replace('http://', '').split('/')[0];
        const inst = new CsxGateway({ id: gw_id });
        return new Promise((resolve, _) => {
            const connect = async () => {
                if (window.APP_SRC === CsxUtil.APP_SRC_TYPE.LOCAL) {
                    try {
                        const error = await inst.setup(LOGIN_TIMEOUT);
                        if (!error) {
                            this.addGateway(inst);
                            if (window.APP_SRC === CsxUtil.APP_SRC_TYPE.LOCAL) {
                                if (window.FOCUS_GATEWAY)
                                    window.FOCUS_GATEWAY.free();
                                window.FOCUS_GATEWAY = inst;
                                if (window.CSX_CUR_AUTH) {
                                    window.CSX_CUR_AUTH.refreshAssignDependency();
                                }
                            }
                            if (this._connection_interval)
                                global.clearInterval(this._connection_interval);
                            resolve(1);
                        }
                    } catch (err) {
                        console.log('[_handle_connection:932] err :>> ', err);
                        window.CSX_MANAGER_STA = `${err}`;
                    }
                }
            }
            connect();
            // this._connection_interval = global.setInterval(connect, 10000);
        });
    }

    _test_connect = () => {
        return new Promise((resolve, reject) => {
            const origin = (window.APP_DEV_MODE && DEV_TARGET_IP.length > 0) ? `ws://${DEV_TARGET_IP}` : window.location.origin;
            const connection = new CsxWs(7681, origin, () => { connection.unsubscribe(); resolve(0) }, () => { const error = connection.CLIENT_ERROR; connection.unsubscribe(); reject(error); });
        });
    }

    reconnect = (gw: CsxGateway) => {
        const LOGIN_TIMEOUT = undefined;
        window.HEALTH_HANDLER = {};
        window.CSX_MANAGER_STA = 'Try reconnecting control system in local mode...';
        return new Promise(async (resolve, reject) => {
            try {
                if (window.APP_SRC === CsxUtil.APP_SRC_TYPE.LOCAL) {
                    await this._test_connect();
                    gw.setup(LOGIN_TIMEOUT);
                    resolve(this);
                }
            } catch (err) {
                window.CSX_MANAGER_STA = `${err}`;
                reject(err);
            }
        });
    }

    loginTimerStart = (timeout_m?: number) => {
        this.loginTimerStop();
        if (timeout_m && !isNaN(timeout_m) && timeout_m !== 0) {
            this._login_timeout = setTimeout(() => { CsxUtil.signout(); }, timeout_m * 60000);
        }
    }
    loginTimerStop = () => {
        if (this._login_timeout) {
            clearTimeout(this._login_timeout);
            this._login_timeout = undefined;
        }
    }

    initialize = () => {
        return new Promise(async (resolve, reject) => {
            window.HEALTH_HANDLER = {};
            if (typeof window.RENDER_LIST === 'undefined')
                window.RENDER_LIST = { EventSystem: {}, Dashboard: {}, NoUpdate: {}, DeviceRealTime: {}, GatewayRealTime: {}, MessageRealTime: {}, GatewayMessageRealTime: {}, DeviceSystemStatus: {}, AuthenticationUpdate: {} };
            try {
                await this._handle_connection();
                resolve(this);
            } catch (err) {
                window.CSX_MANAGER_STA = `${err}`;
                reject(err);
            }
        });
    }

    releaseGateway = () => {
        this._gateway_dict = {};
        this._gateway_id_set = new Set([]);
        this._gateway_id_set.forEach(gw_id => this._gateway_dict[gw_id].free());
    }

    free = () => {
        window.CSX_CUR_USER = undefined;
        window.CSX_CUR_AUTH = undefined;
        this.USER_PROF = new CsxUserProfile('', {});
        this.releaseGateway();
        irqRefresh(render_field_type);
    }
}