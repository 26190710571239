import React from 'react';
import { Doughnut } from 'react-chartjs-2';

import { CsxUI, CsxUtil, CsxDesc } from '../csx';

import './chart.css';

const { getText } = CsxDesc;

export default class DeviceStatusPie extends CsxUI.IRQComponent<any> {
    constructor(props: any) {
        super(props);
        this.MIN_RENDER_INTERVAL = 1000;
        this.IRQIndex = 'Dashboard';
    }
    render() {
        const device_list = (window.FOCUS_GATEWAY) ? Array.from(window.FOCUS_GATEWAY.DEVICES) : [];
        const status_count = [ 0, 0, 0, 0, 0 ];
        device_list.forEach(device_id => {
            const device = (window.FOCUS_GATEWAY) ? window.FOCUS_GATEWAY.getDevice(device_id) : undefined;
            if (device) {
                const status = device.SYS_STA
                switch (status) {
                    case CsxUtil.SYS_STA_TYPE.Sync:
                        status_count[1] ++;
                        break;
                    case CsxUtil.SYS_STA_TYPE.A17_Connect_Error:
                    case CsxUtil.SYS_STA_TYPE.A17_Connect:
                    case CsxUtil.SYS_STA_TYPE.A13_Connect:
                    case CsxUtil.SYS_STA_TYPE.A18_Connect:
                    case CsxUtil.SYS_STA_TYPE.All_Connect:
                        status_count[2] ++;
                        break;
                    case CsxUtil.SYS_STA_TYPE.TCP_Connect:
                    case CsxUtil.SYS_STA_TYPE.TCP_Connect_Error:
                        status_count[0] ++;
                        break;
                    case CsxUtil.SYS_STA_TYPE.Offline:
                        status_count[3] ++;
                        break;
                    case CsxUtil.SYS_STA_TYPE.Firmware_Update:
                        status_count[4] ++;
                        break;
                    default: // skip unadded device
                        break;
                }
            }
        });
        const chart_data = {
            labels: [
                `${getText('DASHBOARD_DEV_ERROR_STATUS')}(${status_count[0]})`,
                `${getText('DASHBOARD_DEV_READY_STATUS')}(${status_count[1]})`,
                `${getText('DASHBOARD_DEV_PREPARE_STATUS')}(${status_count[2]})`,
                `${getText('DASHBOARD_DEV_OFFLINE_STATUS')}(${status_count[3]})`,
                `${getText('DASHBOARD_DEV_UPDATING_STATUS')}(${status_count[4]})`
            ],
            datasets: [{
                data: status_count,
                backgroundColor: [ '#d53a0c', '#72d342', '#e2dd3b', '#ccc', '#16b5c2' ],
                hoverBackgroundColor: [ '#d53a0c', '#72d342', '#e2dd3b', '#ccc', '#16b5c2' ],
            }]
        };
        return (
            <div className='chart_container'>
                <Doughnut
                    data={chart_data}
                    options={{
                        legend: {
                            position: 'right',
                            align: 'center',
                        }
                    }} 
                />
                <div id='dev_count'>{`${getText('TOTAL')} ${device_list.length} ${getText('DEVICE')}${(device_list.length > 1 && window.APP_LANGUAGE === CsxUtil.APP_LANG_TYPE.ENGLISH) ? 's' : ''}`}</div>
            </div>
        );
    }
}
